import { DetailsList, DetailsListLayoutMode, IColumn, Icon, MessageBar,  MessageBarType,  Panel, Stack, Selection, CommandBar, ICommandBarItemProps, ProgressIndicator, themeRulesStandardCreator } from "@fluentui/react";
import React from "react";

import { SectionControl } from "../SectionControl";
import { SystemAppSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/SystemAppSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";

import { decode } from "base64-arraybuffer";
import { nanoid } from "nanoid";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { NPanelControl } from "../Controls/NControls/NPanel";
interface SolutionGridControlProps {
    Tenant: TenantInfo;
    OnOpenApp: (app: SystemApplicationInfo) => void;
    style?: React.CSSProperties | undefined;
}

interface SolutionGridControlState extends SolutionGridControlProps {
  StateKey : number;
  errorMessage? : string;
  notificationMessage? : string;
  allTenants : Array<TenantInfo>;
  allApps : Array<SystemApplicationInfo>;
  currentApplication? : SystemApplicationInfo;
  showAddAppPanel: boolean;
  newApp: SystemApplicationInfo | null;
  allColumns: Array<IColumn>;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  selectedItem?: SystemApplicationInfo;
  busyMessage: string;
}

export class SolutionGridControlControl extends React.Component<SolutionGridControlProps, SolutionGridControlState> {
    private _selection?: Selection;
    private mFileUploadId: string = nanoid();
    constructor(props: SolutionGridControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        allTenants : new Array<TenantInfo>(),
        allApps : new Array<SystemApplicationInfo>(),
        showAddAppPanel: false,
        newApp: null,
        Tenant: props.Tenant,
        allColumns: new Array<IColumn>(),
        cmdBarItems: new Array<ICommandBarItemProps>(),
        cmdBarFarItems: new Array<ICommandBarItemProps>(),
        OnOpenApp: props.OnOpenApp,
        busyMessage: ''
      }
      this._selection = new Selection({
        onSelectionChanged: () => this.setState({ selectedItem: this._getSelectionDetails() }),
      });
    }
  
    componentDidMount() {
      this.refreshColumns();
      this.refreshApps(this.props.Tenant.Id);
    }

    shouldComponentUpdate(nextProps: SolutionGridControlProps, nextState: SolutionGridControlState) {
      if (this.state.Tenant?.Id !== nextProps.Tenant?.Id){
        return true;
      }
      else{
        return false;
      }
    }
    componentDidUpdate(prevProps : SolutionGridControlProps, prevState: SolutionGridControlState){
        if (prevProps.Tenant?.Id !== this.props.Tenant?.Id){
        }
    }

    private refreshColumns = () : void => {
      let cols: IColumn[] = new Array<IColumn>();
      cols.push({
        key: 'colimage',
        name: '',
        fieldName: 'ImageCSSClass',
        minWidth: 32,
        maxWidth: 32,
        onRender: (item: any, index? : number |undefined, col? : IColumn | undefined) => {
          return (<Icon iconName={item.ImageCSSClass}></Icon> )
        },
      });
      cols.push({
        key: 'colname',
        name: 'Name',
        fieldName: 'DisplayName',
        minWidth: 150,
        maxWidth: 450,
      });
      cols.push({
        key: 'colappcode',
        name: 'AppCode',
        fieldName: 'AppCode',
        minWidth: 80,
        maxWidth: 150,
      });
      cols.push({
        key: 'colappversion',
        name: 'Version',
        fieldName: 'Version',
        minWidth: 80,
        maxWidth: 150,
      });
      cols.push({
        key: 'colisactive',
        name: 'Is Active',
        fieldName: 'IsActive',
        minWidth: 80,
        maxWidth: 120,
      });

      let menuItems: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
      menuItems.push({
        key: 'menuItemRefresh',
        text: 'Refresh',
        ariaLabel: 'Refresh',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: (ev?: any) => this.refreshApps(this.props.Tenant.Id)
      });
      menuItems.push({
        key: 'menuItemOpen',
        text: 'Open',
        ariaLabel: 'Open',
        iconOnly: true,
        iconProps: {iconName: 'PlayResume'},
        onClick: this.openApp
      });
      menuItems.push({
        key: 'menuItemAdd',
        text: 'Add',
        ariaLabel: 'Add',
        iconOnly: true,
        iconProps: {iconName: 'Add'},
        onClick: this.onCreateNewApp
      });
      menuItems.push({
        key: 'menuItemBuild',
        text: 'Build',
        ariaLabel: 'Build',
        iconOnly: true,
        iconProps: {iconName: 'Build'},
        onClick: this.onBuildApp
      });
      menuItems.push({
        key: 'menuItemImport',
        text: 'Import',
        ariaLabel: 'Import',
        iconOnly: true,
        iconProps: {iconName: 'CloudUpload'},
        onClick: this.onImportApp
      });
      this.setState({allColumns: cols, cmdBarFarItems: menuItems});
    }

    private onImportApp = (ev?: any) : void => {
      var it = document.getElementById(this.mFileUploadId);
      it?.click();
    }

    private onDoFileUpload = (ev?: any) : void => {
      debugger;
      var it = document.getElementById(this.mFileUploadId);
      let fnm: string = (it as any).value;
      var fileList = ev.target.files;
      this.readFileData(this, fileList);

    }

    private readFileData(ctrl : any, fileList : any) {
        var that = this;
        var reader = new FileReader();
        //var newFiles = new Array<any>();
        function readFile(index : number) {
            //debugger;
            if (index >= fileList.length) {

                //that.updateImage();
                return;
            }
            else {
                var file = fileList[index];
                var ft = file.type;
                var act: string[] = new Array<string>();
                act.push('application/x-zip-compressed');
                if (ft && act.length > 0 && !act.includes(ft)) {
                    var msg : string = 'Invalid file type. Only these file type(s) are supported :' + act.join(',');
                    IllerisNinthUI.NotificationManager.showError('Upload failed', msg);
                    ctrl.setState({errorMessage : msg})
                    return;
                }
                reader.onload = function (e : any) {
                    //debugger;
                    var fnm = fileList[index].name;
                    var fileType = fileList[index].type
                    var fileData = e.target.result;
                    //that.ImageData = fileData;
                    that.internalSaveFile(ctrl, fileData, fileType, fnm);
                    //that.updateImage();

                    readFile(index + 1);

                }
                reader.readAsDataURL(file);
            }
        }
        readFile(0);
    }

    private internalSaveFile(ctrl : any, fileData : any, fileType : string, fileName : string) {
      debugger;
      var that = this;


      that.setState({busyMessage: 'Importing Solution...'});
      that.forceUpdate();
      let dh : DesignTimeModelHelper = new DesignTimeModelHelper();
      dh.importApp(this.props.Tenant.Id, fileData, fileType, fileName)
        .then(function(res: any){
          that.setState({busyMessage: '', notificationMessage: 'Solution Imported'});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({busyMessage: '', errorMessage: err?.message});
          that.forceUpdate();
          IllerisNinthUI.NotificationManager.showError('Error importing solution', err.message);
        });
    }

    private refreshApps= (tenantId : string) : void => {
      var that = this;
      that.setState({busyMessage: 'Loading Apps...'});
      let eh: DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.loadSolutionsInTenant(this.props.Tenant.Id)
        .then(function(res: SystemApplicationInfo[]){
          res.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
          that.setState({allApps: res, busyMessage: ''});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message, busyMessage: ''});
        })
    }

    private openApp = (ev?: any) : void => {
      
      let it: any = this.state.selectedItem;
      if (it){
        this.props.OnOpenApp(it);
      }
    }

    private str2bytes = (str : string): Uint8Array =>  {
      var bytes = new Uint8Array(str.length);
      for (var i=0; i<str.length; i++) {
         bytes[i] = str.charCodeAt(i);
       }
       return bytes;
    }
    private _base64ToArrayBuffer = (base64 : string) : ArrayBufferLike => {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
  }
  

    private onBuildApp = (ev?: any) : void => {
      let that= this;
      let it: any = this.state.selectedItem;
      if (it){
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        that.setState({busyMessage: 'Building App...'});
        that.forceUpdate();
        mh.buildApp(this.props.Tenant.Id, it.Id)
        .then(function(res: any){
          if (res){
            debugger;
            if (res.FileMimeType === 'application/zip'){
              var ab = decode(res.Value);
              let blob = new Blob([ab], {type: "application/zip"});
              let fileName : string = res.FileName;
              let a: any  = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display:none";
              let url: string = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
              
            }
            that.setState({notificationMessage: 'Build Complete - file downloaded', busyMessage: ''});
            that.forceUpdate();
          }
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message, busyMessage: ''});
        })
    
      }
    }

    private onChangeApp = (evt : any) : void =>{
        // if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.appid){
        //     var ti : SystemApplicationInfo |undefined = this.state.allApps.find (z => z.Id === evt.currentTarget.dataset.appid)
        //     if (ti && this.state.currentTenant){
        //         this.setState({currentApplication : ti});
        //         if (this.props.setApplication){
        //             this.props.setApplication(ti, this.state.currentTenant!);
        //         }
        //     }
        // }
    }

    private onCreateNewApp = (evt: any) : void =>{
      this.setState({newApp: new SystemApplicationInfo(), showAddAppPanel: true});
      this.forceUpdate();
    }

    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private dismissPanel = (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) : void =>{
      this.setState({showAddAppPanel: false});
      this.forceUpdate();
    }
    private closeAndSave = (ev?: any) : void =>{
      debugger;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      eh.createNewAppIntenant(this.state.Tenant!.Id, this.state.newApp!).then(function (res: SystemApplicationInfo){
        that.setState({showAddAppPanel: false, newApp : res});
        that.refreshApps(that.state.Tenant!.Id);
      })
      .catch(function(err: any){
        that.setState({errorMessage : err.message});
      })
      
    }
    private resetError = (ev :any) : void =>{
      this.setState({errorMessage: ''});
    }

    private _getSelectionDetails(): SystemApplicationInfo | undefined {
      const selectionCount: number = this._selection?.getSelectedCount() ?? 0;
      if (selectionCount > 0 && this._selection){
        let it: SystemApplicationInfo = this._selection.getSelection()[0] as SystemApplicationInfo;
        return it;
      }
      return undefined;
    }

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='body-panel' style={that.props.style}>
              <input title='file' id={this.mFileUploadId} type='file' style={{visibility: 'hidden'}} onChange={this.onDoFileUpload} ></input>
              <SectionControl headerText={'Select Application in ' + this.state.Tenant?.DisplayName + ' tenant.'} >
                <CommandBar
                  items={this.state.cmdBarItems}
                  overflowButtonProps={{ ariaLabel: 'More commands' }}
                  farItems={this.state.cmdBarFarItems}
                  ariaLabel="Use left and right arrow keys to navigate between commands"
                />
                {that.state.busyMessage &&
                  <ProgressIndicator label={that.state.busyMessage} />
                }
                {this.state.errorMessage &&
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                    dismissButtonAriaLabel="Close"
                  >
                    {that.state.errorMessage}
                  </MessageBar>
                }
                {this.state.notificationMessage &&
                  <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={true}
                    onDismiss={(ev?: any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                    dismissButtonAriaLabel="Close"
                  >
                    {that.state.notificationMessage}
                  </MessageBar>
                }
                <DetailsList
                  items={this.state.allApps}
                  columns={this.state.allColumns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                  onItemInvoked={this._onItemInvoked}
                  
                />

                {/* <NPanelControl title="Hello World" ></NPanelControl> */}
                  {/* <Stack horizontal tokens={stackTokens}>
                      {this.state.allApps.map(function(item: SystemApplicationInfo, index: number){
                          return (
                          <CommandButton key={`appBtn${index}`} iconProps={{ iconName: IconConverter.convertToFluentUiIcon(item.ImageCSSClass) }}  text={item.DisplayName + ' (' + item.AppCode + ', ' + (item.IsActive ? 'Active' : 'Inactive')  + ')'} onClick={that.onChangeApp} data-appid={item.Id} >
                              
                          </CommandButton>)
                      })
                      }
                      <CommandButton key={`appBtn${999}`} iconProps={{ iconName: 'Add' }}  text='Add' onClick={that.onCreateNewApp} >
                              
                      </CommandButton>
                  </Stack> */}
              </SectionControl>
              <Panel
                isOpen={that.state.showAddAppPanel}
                onDismiss={that.dismissPanel}
                
                closeButtonAriaLabel="Close"
                headerText="Create a new App"
              >
                <SystemAppSurfaceEditorControl app={that.state.newApp!} isReadOnly={false} closeAndSave={that.closeAndSave} TenantInfo={that.state.Tenant} />
              </Panel>
              {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={that.resetError}
                  dismissButtonAriaLabel="Close"
                >
                  {that.state.errorMessage}
                </MessageBar>
              }
          </Stack>
      );
    }
    private _onItemInvoked = (item: any): void => {
      if (item){
        this.props.OnOpenApp(item as SystemApplicationInfo);
      }
    }
  }
  