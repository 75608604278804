import { Stack } from "@fluentui/react";
import React  from "react";
import {  RouteComponentProps } from "react-router-dom";
import { ApplicationDesignerPage } from "./ApplicationDesignerPage";
import { ItemDesignerPage } from "./ItemDesignerPage";



type TParams =  { tenantid: string, appname: string, entityname: string, surface: 'view' | 'form', id?: string };
///system/designer/:tenantid/:entityname/:surface/:id

export function DynamicDesignerPage({ match }: RouteComponentProps<TParams>) {
  
    return (<Stack className="designerapp" verticalFill horizontalAlign='stretch'>
        <ApplicationDesignerPage tenantid={match.params.tenantid} entityname={match.params.entityname} surface={match.params.surface} id={match.params.id} ></ApplicationDesignerPage>
    </Stack>)
}

export function DynamicItemDesignerPage({ match }: RouteComponentProps<TParams>) {
  
    return (<Stack className="designerapp" verticalFill horizontalAlign='stretch'>
        {/* <ItemDesignerPage appInfo={} tenantId={match.params.tenantid} entityName={match.params.entityname} surface={match.params.surface} appName={match.params.appname} id={match.params.id} ></ItemDesignerPage> */}
    </Stack>)
}