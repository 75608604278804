
export class ClientState{
    public UserProfile? : UserProfile;
    public IsAuthenticated : boolean;
    public IsExpanded : boolean = true;
    constructor(){
        this.IsAuthenticated = false;
    }

    static CurrentState() : ClientState
    {
        var st : string | null= window.sessionStorage.getItem('__TNCLIENTSTATE__');
        if (st)
            return JSON.parse(st);
        else{
            var xst : ClientState = new ClientState();
            window.sessionStorage.setItem('__TNCLIENTSTATE__', JSON.stringify(xst));
            return xst;
        }
    }
    static SaveState(st : ClientState) : ClientState{
        window.sessionStorage.setItem('__TNCLIENTSTATE__', JSON.stringify(st));
        return st;
    }
}

export class UserProfile{
    public LoginName : string;
    public DisplayName : string;
    public EmailAddress : string;
    public UserId : string;
    public MicrosoftTenantId : string;
    public TerraNovaTenantId : string;
    public TerraNovaTenantUniqueName : string;
    public ValidUntil : Date | null;
    public DefaultCurrency : string;
    constructor(){
        this.LoginName = '';
        this.DisplayName = '';
        this.EmailAddress = '';
        this.UserId = '';
        this.MicrosoftTenantId = '';
        this.TerraNovaTenantId = '';
        this.ValidUntil = null;
        this.DefaultCurrency = 'EUR';
        this.TerraNovaTenantUniqueName = 'core';
    }
}