import { ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import { IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { DynamicEntity } from "../DynamicEntity";
import { IconConverter } from "../IconConverter";
import { IllerisNinthAPI } from "../ServiceResult";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";

export class ButtonItem {
    public CommandName: string = '';
    public Order: number = 0;
    public VisibleOnStateCodes: string = '';
    public Button?: IllerisNinthAPI.Button | null;
    public ActionButton?: IllerisNinthUI.ActionButton | null;

    static CreateFromButton = (btn: IllerisNinthAPI.Button) : ButtonItem => {
        let b: ButtonItem = new ButtonItem();
        b.CommandName = btn.CommandName;
        b.Order = btn.Order ?? 0;
        b.Button = btn;
        b.VisibleOnStateCodes = btn.VisibleOnStateCodes;
        return b;
    }
    static CreateFromActionButton = (btn: IllerisNinthUI.ActionButton) : ButtonItem => {
        let b: ButtonItem = new ButtonItem();
        b.CommandName = btn.CommandName;
        b.Order = btn.Order;
        b.ActionButton = btn;
        b.VisibleOnStateCodes = btn.VisibleOnStateCodes;
        return b;
    }

    public setButton = (btn: IllerisNinthAPI.Button) : ButtonItem => {
        this.Button = btn;
        this.CommandName = btn.CommandName;
        delete this.ActionButton;
        return this;
    }
    public setActionButton = (btn: IllerisNinthUI.ActionButton) : ButtonItem => {
        this.ActionButton = btn;
        this.CommandName = btn.CommandName;
        delete this.Button;
        return this;
    }

}

export class ButtonItemList {
    private mButtons: Array<ButtonItem> = new Array<ButtonItem>();

    public clear = () : void => {
        this.mButtons = new Array<ButtonItem>();
    }
    public add = (btn : IllerisNinthUI.ActionButton | IllerisNinthAPI.Button) : ButtonItem => {
        let cmdName: string = btn.CommandName?.toLowerCase() ?? '';
        if (cmdName && cmdName.startsWith('cmd')){
            cmdName = cmdName.substring(3);
        }
        btn.CommandName = cmdName;
        let xe: ButtonItem | undefined = this.mButtons.find(z => z.CommandName?.toLowerCase() === cmdName?.toLowerCase());
        if (!xe){
            if (btn instanceof IllerisNinthUI.ActionButton){
                xe = ButtonItem.CreateFromActionButton(btn);
            }
            else {
                xe = ButtonItem.CreateFromButton(btn as IllerisNinthAPI.Button);
            }
            xe.CommandName = cmdName;
            this.mButtons = this.mButtons.concat(xe);
        }
        else{
            if (btn instanceof IllerisNinthUI.ActionButton){
                xe.setActionButton(btn);
            }
            else {
                xe.setButton(btn);
            }
            xe.CommandName = cmdName;
        }
        return xe;
    }

    public contains = (cmdName: string) : boolean => {
        return this.mButtons.some(z => z.CommandName?.toLowerCase() === cmdName?.toLowerCase());
    }
    public getItem = (cmdName?: string | null) : ButtonItem | null | undefined => {
        return this.mButtons.find(z => z.CommandName === cmdName?.toLowerCase());
    }

    public getItems = () : ButtonItem[] => {
        return this.mButtons.sort((a, b) => a.Order < b.Order ? -1 : 1);
    }
    private padNumberToString = (num : number , size :number) : string => {
        let res = num.toString();
        while (res.length < size) res = "0" + res;
        return res;
    }
    
    public buildCommandBarItemsList = (de: DynamicEntity | null) : ICommandBarItemProps[] => {
        let that = this;
        let res: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        let items: ButtonItem[] = this.getItems();
        let sch : IllerisNinthAPI.EntityStateCodeHelper = new IllerisNinthAPI.EntityStateCodeHelper();

        items.forEach(function(it: ButtonItem, idx: number){
            let bdoAdd: boolean = false;
            sch.clearStateCodes();
            sch.addStateCodeString(it.VisibleOnStateCodes);
            bdoAdd = sch.entityMatchesStateCodes(de);

            if (it && it.ActionButton && bdoAdd){
                if (it.ActionButton.IsVisible){
                    res.push({
                        iconOnly: false,
                        key: it.CommandName,
                        label: it.ActionButton.Label,
                        title : it.ActionButton.Label,
                        cacheKey : that.padNumberToString(it.Order,8) + it.ActionButton.Label,
                        iconProps: { iconName: ( IconConverter.convertToFluentUiIconBtn(it.ActionButton)) },
                        ariaLabel: it.ActionButton.Label,
                        ariaDescription: it.ActionButton.Label,
                        tooltipHostProps: { content : it.ActionButton.ToolTipText}
                    })
                }
            }
            else if (it && it.Button && bdoAdd){
                if (it.Button.IsVisible){
                    res.push({ 
                        iconOnly: false,
                        key: it.CommandName,
                        label: it.Button.CommandText,
                        cacheKey : that.padNumberToString(it.Button.Order? it.Button.Order : 1000 ,8) + it.Button.CommandText,
                        title : it.Button.CommandText,
                        iconProps: { iconName: ( IconConverter.convertToFluentUiIconBtn2(it.Button)) },
                        ariaLabel: it.Button.CommandText,
                        ariaDescription: it.Button.CommandText,
                        tooltipHostProps: { content : it.Button.CommandText}
                    })
                }
            }
        })

        return res;
    }
    public buildContextualMenuBarItemsList = (de: DynamicEntity | null) : IContextualMenuItem[]  => {
        let res: IContextualMenuItem[] = new Array<IContextualMenuItem>();
        let items: ICommandBarItemProps[] = this.buildCommandBarItemsList(de);
        items.forEach(function(item: ICommandBarItemProps, idx: number){
            res.push({
                key: item.key,
                title: item.title,
                text: item.text,
                iconProps: item.iconProps,
            })
        });
        return res;

    }
}