import { Dropdown, IDropdownOption, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";
import { ProductPriceMatrix } from "../../designers/Entities/ProductPricing";
import { DynamicEntity } from "../../DynamicEntity";
import { EntityPersistanceManager } from "../../Helpers/EntityPersistanceManager";
import { PageInfo } from "../../PageInfo";
import { IllerisNinthAPI } from "../../ServiceResult";

export interface TNCustomerAppLicCounterEditControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    customerAppLicId: string;
    getEntity : () => DynamicEntity | null | undefined;
}

export interface TNCustomerAppLicCounterEditControlState  {
    Product?: any;
    errorMessage?: string;
    notificationMessage?: string;
    appLicId: string;
    items: any[];
    busyMessage: string;
    options: IDropdownOption[];
    optionCounters?: any;
}
  
export class TNCustomerAppLicCounterEditControl extends React.Component<TNCustomerAppLicCounterEditControlProps, TNCustomerAppLicCounterEditControlState> {
    private mbIsItemsLoaded: boolean = false;
    private mbIsOptionsLoaded: boolean = false;
      constructor(props : TNCustomerAppLicCounterEditControlProps){
          super(props);
          
          this.state = {
            appLicId: this.props.getEntity()?.getId() ?? '',
            items: new Array<any>(),
            busyMessage: '',
            options: new Array<IDropdownOption>(),
          }
      }

      private loadOptions = async () : Promise<void> => {
          let that = this;
         let appId: string = this.props.getEntity()?.getValue('ApplicationId');
         if (appId){
            let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
            epm.getById('Application', appId)
                .then(function(res: IllerisNinthAPI.ServiceResult | Error ){
                    if (res instanceof Error){
                        that.setState({errorMessage: (res as Error).message, busyMessage: ''});
                    }
                    else{
                        let sr: IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                        if (sr && sr.Value){
                            if (sr.Value && sr.Value.DefaultCounters){
                                let cntrs : string[] = sr.Value.DefaultCounters.split(';');
                                if (cntrs){
                                    let res : Array<IDropdownOption> = new Array<IDropdownOption>();
                                    let oc: any = {};
                                    cntrs.forEach(function(it: string, idx: number){
                                        if (it.indexOf(':') !== -1){
                                            res.push({key: it.substring(0, it.indexOf(':')), text: it.substring(0, it.indexOf(':'))})
                                            oc[`${it.substring(0, it.indexOf(':'))}`] = it.substring(it.indexOf(':')+1);
                                        }
                                        else{
                                            res.push({key: it, text: it});
                                            oc[`${it}`] = '0';
                                        }
                                    })
                                    that.setState({options: res, optionCounters: oc});
                                    that.mbIsOptionsLoaded = true;
                                    that.forceUpdate();
                                    that.updateNewCounters();
                                }
                            }
                        }
                    }
                })
                .catch(function(err: any){
                    that.setState({errorMessage: err?.message});
                });
         }
      }

      private updateNewCounters = () : void => {
          let that = this;
        let missingItems: string[] = new Array<string>();
        if (this.mbIsItemsLoaded && this.mbIsOptionsLoaded && this.state.items.length !== this.state.options.length){
          this.state.options.forEach(function(it: IDropdownOption, idx: number){
              let rows: any = that.state.items.find(z => z.FunctionName === it.key.toString());
              if (rows && rows.length === 0){
                  missingItems.push(it.key.toString());
              }
          })
          let its: any[] = that.state.items;
          missingItems.forEach(function(it: string, idx: number){
              its.push({ FunctionName : it, MaxValue: (that.state.optionCounters ? that.state.optionCounters[`${it}`] : 1000), CurrentCounter: 0, CustomerAppLicenseId: that.props.getEntity()?.getId(), DisplayName: it });
          })
        }
        if (missingItems.length > 0){
            that.forceUpdate();
        }
       
      }

      private loadItems = async (appLicId: string) : Promise<void> => {
          let that = this;
          that.setState({busyMessage: 'Loading...'});
          let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
          //?$filter=CustomerAppLicenseId == "' + id + '"'
          epm.getMany('CustomerAppLicenseCounter', `CustomerAppLicenseId == "${appLicId ?? this.state.appLicId}"`)
            .then(function(res: IllerisNinthAPI.ServiceResult | Error){
                that.setState({busyMessage: ''});
                that.forceUpdate();
                if (res instanceof Error){
                    that.setState({errorMessage: (res as Error).message, busyMessage: ''});
                    return Promise.reject();
                }
                else{
                    let sr: IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                    if (sr && sr.Values){
                        if (sr.Values && sr.Values.length  >0){
                            that.setState({items: sr.Values, busyMessage: ''});
                            that.forceUpdate();    
                            that.mbIsItemsLoaded = true;
                            
                        }
                    }
                }
                that.updateNewCounters();
                return Promise.resolve();
            })
            .catch(function(err: any){
                that.setState({errorMessage: err?.message});
            });
      }

     

      public componentDidMount(){
          let that = this;
          this.loadOptions()
            .then(function(res: void){
                that.loadItems(that.props.getEntity()?.getId() ?? '');
            })
          
      }

      public shouldComponentUpdate(nextProps: TNCustomerAppLicCounterEditControlProps, nextState: TNCustomerAppLicCounterEditControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNCustomerAppLicCounterEditControlProps, prevState: TNCustomerAppLicCounterEditControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
            this.loadItems(this.props.getEntity()?.getId() ?? '');
        }
      }

      private updateRow = (row: any) : void => {
          let that = this;
          let items: any[] = that.state.items;
        let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
        that.setState({busyMessage: 'Saving...'});
        if (row.Id){
            epm.saveEntity(new DynamicEntity(row, 'CustomerAppLicenseCounter'), false)
                .then(function(res: IllerisNinthAPI.ServiceResult | Error){
                    if (res instanceof Error){
                        that.setState({errorMessage: (res as Error).message, busyMessage: ''});
                    }
                    else{
                        let sr: IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                        if (sr && sr.Value){
                            if (sr.Value){
                                let idx : number = items.indexOf(row);
                                if (idx !== -1){
                                    items.splice(idx, 1, sr.Value);
                                }
                                that.setState({items: items, busyMessage: ''});
                                that.forceUpdate();    
                            }
                        }
                    }
                })
        }
        else{

        }
      }

      public render(){
          var that = this;
          
          return (
              <Stack verticalFill horizontalAlign='stretch'>
                  {that.state.busyMessage &&
                    <ProgressIndicator label={that.state.busyMessage}></ProgressIndicator>
                    }
                    <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: 10}}>
                            <Stack.Item style={{width: '33%'}}>
                                Function
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                Max. Value
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                Current Counter
                            </Stack.Item>
                    </Stack>    
                  {that.state.items.map(function(it: any, idx: number){
                      return (
                        <Stack horizontal horizontalAlign="stretch" key={nanoid()} tokens={{childrenGap: 10}}>
                            <Stack.Item style={{width: '33%'}}>
                                <Dropdown
                                defaultSelectedKey={it.FunctionName}
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={(ev?: any, option?: IDropdownOption<any> | undefined, index?: number | undefined) => { it.FunctionName = option?.text; that.updateRow(it);}}
                                placeholder="Select an function"
                                disabled
                                options={that.state.options}
                                />
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                <TextField type="number" value={that.state.optionCounters ? that.state.optionCounters[`${it.FunctionName}`] : ''} placeholder='Maximum' onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => { it.MaxValue = isNaN(Number.parseInt(newValue ?? '0')) ? 0 :  Number.parseInt(newValue ?? '0') ; that.updateRow(it);}}  ></TextField>
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                <TextField type="number" value={it.CurrentCounter} placeholder='Current' disabled readOnly ></TextField>
                            </Stack.Item>
                        </Stack>    

                      );
                  })
                  }
                  
              </Stack>
          );
      }
  }