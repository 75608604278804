import React  from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
//import logo from './logo.svg';
import './App.css';
import {  HeaderCommandBar } from './MainNavBar';
import { Stack } from '@fluentui/react';
import { MSALHelper } from './Helpers/MSALHelper';
import { APIHelper } from './APIHelper';
import { AppTileGrid } from './AppTileGrid';
import { DynamicPage } from './DynamicPage';
import { DynamicPublicPage } from './DynamicPublicPage';
import { DynamicDesignerPage, DynamicItemDesignerPage } from './DynamicDesignerPage';
import { MainSolutionDesignerPage } from './designers/MainSolutionDesignerPage';
import { LoginPageControl } from './LoginPage';
import { DocumentTemplateDesignerControl } from './reporting/Designer/DocumentTemplateDesigner';

function App() {
  return (
    
    <Router>
      <Route exact path='/' >
        <div className="App">
          <header>
            <Stack horizontal horizontalAlign="stretch" className="header-navbar-panel" >
              <HeaderCommandBar />
            </Stack>
          </header>
          <body>
            <Stack horizontal horizontalAlign="stretch" className="body-panel" >
              {/* <NavBar className="tn-navBar" /> */}
              <AppTileGrid/>
            </Stack>
          </body>
          <footer>
            <Stack horizontal horizontalAlign="stretch" className="footer-panel" >
              <span className="footer-panel-text">Copyright &copy; 2022 Ninth.eu</span>
            </Stack>

          </footer>
        </div>
      </Route>
      {/* <Route path={['/cview/:appname/:entityname?/:actionname?/:id?', '/cview/ten:tenantUniqueName/:appname/:entityname?/:actionname?/:id?' ]} component={DynamicPage}>

      </Route> */}
      <Route path={['/app/:tenantUniqueName/login' ]} component={LoginPageControl}>

      </Route>
      <Route path={['/cview/:tenantUniqueName/:appname?/:entityname?/:actionname?/:id?' ]} component={DynamicPage}>

      </Route>
      {/* <Route path='/cview/:tenantUniqueName/:appname/:entityname?/:actionname?/:id?' component={DynamicPage}>

      </Route> */}
    
      <Route path='/public/:tenantname/:appname/:entityname' component={DynamicPublicPage}>

      </Route>
      <Route path='/public/:tenantname/:appname/:entityname/:actionname/:id?' component={DynamicPublicPage}>

      </Route>
      <Route path='/system/designer/:tenantid/:entityname/:surface/:id?' component={DynamicDesignerPage}>

      </Route>
      <Route path='/system/designer/:tenantid/:appname/:entityname/:surface/:id?' component={DynamicItemDesignerPage}>

      </Route>
      <Route path='/system/design' component={MainSolutionDesignerPage}>

      </Route>
      <Route path='/system/reportdesign' component={DocumentTemplateDesignerControl}>

      </Route>
      {/* <Route path='*'>
        <NoMatch />
      </Route> */}
    </Router>
    
  );

  
}

function _onLoginClicked(): void {
  //alert('Clicked');
  var mh : MSALHelper = new MSALHelper();
  mh.signInRedirect();
}

function _callGraph(): void {
  //alert('Clicked');
  var mh : APIHelper = new APIHelper();
  mh.testServiceCall();
}

function NoMatch() {
  window.location.href= '/';
  return <div></div>
}

export default App;
