/* eslint-disable no-loop-func */
import { VirtualizedComboBox } from "@fluentui/react/lib/components/ComboBox";
import { IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Logger } from "../../Logger";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

import { TNImageInfo, TNImageInputEditor } from "../../TNImageEditor";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

  
  export class TNImageEditor extends React.Component<TNEditorFactoryProps, TNEditorFactoryState> {
      private Editor : IllerisNinthUI.TNEditorBase | null= null;
      private InputEditor : IllerisNinthUI.TNInputBox | null;
      private mImageEditor : IllerisNinthUI.TNImageInput | null = null;
      private Entity : DynamicEntity | null | undefined;
      private LastValueString : string | undefined;
      private LastServiceResultLoadedTimeStamp : Date | undefined;

      private mPropertyName : string = '';
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            loadingMessage: ''
        }

        this.mImageEditor = this.props.getEditor() as IllerisNinthUI.TNImageInput;

        this.Editor = null;
        this.InputEditor = null;
        this.Entity = null;
        this.onTextInputChanged = this.onTextInputChanged.bind(this);
        this.onToggleInputChanged = this.onToggleInputChanged.bind(this);
    }
    
    componentDidMount(){
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNEditorFactoryState) {
        //Logger.logNotification('TNInputBoxView::shouldComponentUpdate');
        if (this.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
        ){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNEditorFactoryState){
        var prevEnt : DynamicEntity | null | undefined = prevProps.Entity;
        var thisEnt : DynamicEntity | null | undefined = this.props.Entity;
        if (this.props.getEditor()?.HSBindName !== prevProps.getEditor()?.HSBindName || prevEnt?.getValue(prevProps.getEditor()?.HSBindName ? prevProps.getEditor()?.HSBindName : '') !== thisEnt?.getValue(this.props.getEditor()?.HSBindName ? this.props.getEditor()?.HSBindName : '')
            || this.props.dataKey !== prevProps.dataKey
            || this.props.ServiceResult?.LoadedTimeStamp !== prevProps.ServiceResult?.LoadedTimeStamp
        ){
        }
    }

    private getImageInfo = () : IllerisNinthUI.TNImageInput |null =>{
        return this.mImageEditor;
    }

    
    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.mPropertyName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity){
            this.Entity.setValue(propName, val);
        }
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        Logger.logNotification('TNInputBoxView::Render - Entity ' + this.props.Entity?.LogicalEntityName + ', id ' + this.props.Entity?.getId() + ', hsBindName ' + this.props.getEditor()?.HSBindName + ', value ' + this.props.Entity?.getValue(this.props.getEditor()?.HSBindName));

        if (!this.Editor){
            this.Editor = this.props.getEditor();
            if (this.Editor instanceof IllerisNinthUI.TNInputBox){
                this.InputEditor = this.Editor as IllerisNinthUI.TNInputBox
                this.mPropertyName = this.InputEditor.HSBindName;
            }
            // else if (this.Editor instanceof IllerisNinthUI.TNSelect){
            //     this.SelectEditor = this.Editor as IllerisNinthUI.TNSelect;
            //     this.mPropertyName = this.SelectEditor.HSBindName;
            //     this.loadDropDownItems(false);
                
            // }
            if (this.Editor instanceof IllerisNinthUI.TNImageInput){
                this.mImageEditor = this.Editor as IllerisNinthUI.TNImageInput;
            }
            if (this.Editor){
                document.addEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
            }
        }

        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
          });
          //var valStr : string = (this.Entity && this.Entity.getValue(this.Editor!.HSBindName) ? this.Entity.getValue(this.Editor!.HSBindName) : '');

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };

        return (
                    <TNImageInputEditor 
                        getImage={this.getImageInfo} 
                        getImageData={this.getImage} 
                        valueStr={that.state.valueStr ?? ''}
                        updateValue={this.updateValue}
                        isReadOnly={this.mImageEditor?.IsReadOnly || this.props.isreadonly}
                    ></TNImageInputEditor>
        );
      }
      
      private getImage = () : TNImageInfo | null =>{
        if (this.mImageEditor){
              var ti : TNImageInfo = new TNImageInfo();
              if (this.Entity && this.mImageEditor){
                  ti.FileDataBase64 = (this.Entity && this.Entity.getValue(this.mImageEditor!.HSBindName) ? this.Entity.getValue(this.mImageEditor!.HSBindName): '');
                  if (ti && ti.FileDataBase64 && ti.FileDataBase64.length === 36){
                    ti.Id = ti.FileDataBase64;
                  }
              }
              return ti;
        }
        return null;
    }

      
      private onTextInputChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue : string | undefined) : void =>{
        console.log('input changed to value : ' + newValue);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, newValue);
        }
        this.setState({valueStr : newValue});
      }
      private onToggleInputChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) : void =>{
        console.log('toggle changed to value : ' + checked);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, checked);
        }
        this.setState({valueStr : checked ? 'true' : 'false'});
      }


  }