import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityFormDefinition2 } from "../../Entities/EntityForm";
import { EntityFormTypeEditorControl } from "./EntityFormTypeEditor";

interface EntityFormDefinitionSurfaceEditorProps {
    TenantId: string;
    ent: EntityDefinition;
    vw: EntityFormDefinition2;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface EntityFormDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ent: EntityDefinition;
  vw: EntityFormDefinition2;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class EntityFormDefinitionSurfaceEditorControl extends React.Component<EntityFormDefinitionSurfaceEditorProps, EntityFormDefinitionSurfaceEditorState> {
    constructor(props: EntityFormDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        ent: props.ent,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        vw: props.vw
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' tokens={{childrenGap: 10}} >
            <div className='tn-editor-views-title' >{this.state.vw.Id ? 'Edit Form' : 'Create New Form'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            
            <TextField label='Display Name' value={this.state.vw.DisplayName} readOnly={(this.state.isReadOnly)} onChange={that.onDisplayNameChanged} ></TextField>
            <EntityFormTypeEditorControl TenantId={that.props.TenantId} isReadOnly={that.props.isReadOnly || !StringHelper.isEmpty(this.state.vw.Id)} vw={that.state.vw} ></EntityFormTypeEditorControl>
            
            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={this.state.isReadOnly} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }
    private onDisplayNameChanged = (evt: any, newValue? : string) =>{
      var it : EntityFormDefinition2 = this.state.vw;
      it.DisplayName = StringHelper.displayNameOnly(newValue ?? '');
      it.ViewName = StringHelper.displayNameOnly(newValue ?? '');
      this.setState({vw : it});
      this.forceUpdate();
    }
    private closeAndSave = (evt: any) =>{
      if (!this.state.vw.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      this.props.closeAndSave();
    }
    
  }
