import { IContextualMenuItem, MessageBar, MessageBarType, Spinner, Stack } from "@fluentui/react";
import React from "react";
import { Logger } from "./Logger";
import { NavigationInfo, ViewType } from "./NavigationInfo";
import { ViewManager, XPublicView } from "./ViewManager";
import Config from "./config.json";

interface ApplicationPublicPageProps {
    tenantName : string;
    applicationName : string;
    entityName? : string;
    actionName? : string;
    itemId? : string;
}

interface ApplicationPublicPageState extends ApplicationPublicPageProps {
  isSideNavExpanded : boolean;
  CurrentView? : XPublicView | null;
  StateKey : number;
  errorMessage? : string;
  isloading : boolean;
}

export class ApplicationPublicPage extends React.Component<ApplicationPublicPageProps, ApplicationPublicPageState> {
  private ViewManager : ViewManager;
  private CurrentView? : XPublicView;
  constructor(props: ApplicationPublicPageProps) {
    super(props);
    this.state = {
      isSideNavExpanded : true,
      tenantName : props.tenantName,
      applicationName: props.applicationName,
      actionName : props.actionName,
      entityName: props.entityName,
      itemId : props.itemId,
      CurrentView : null,
      StateKey : 0,
      isloading: false
    }

    this.onToggleExpandClicked.bind(this);
    this.onPageRedirectRequested.bind(this);
    this.getView.bind(this);

    this.ViewManager = new ViewManager(props.applicationName,props.tenantName);
  }

  componentDidMount() {
    //var navUri = '/'
    var that = this;
    if (this.ViewManager){
      this.setState({isloading : true});
      this.ViewManager.loadPublic().then(function(res){
        that.setState({isloading : false});
        Logger.logNotification('Public Views reloaded from server. ' + that.ViewManager?.PublicViews?.length + ' views found.' );

        if (that.state.tenantName && that.state.applicationName  && that.state.entityName && that.state.actionName){
          that.onPageRedirectRequested({TenantName: that.state.tenantName, EntityName : that.state.entityName!, ViewType : that.state.actionName as ViewType, Id: that.state.itemId });
        }
        else if (that.state.tenantName && that.state.applicationName  && that.state.entityName){
          that.onPageRedirectRequested({TenantName : that.state.tenantName, EntityName : that.state.entityName!, ViewType: 'view' });
        }
      })
      .catch(function (err){
        that.setState({isloading : false, errorMessage: err.message});
      })
    }
  }
  private onToggleExpandClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) : boolean | void => {
    this.setState({ isSideNavExpanded : !this.state.isSideNavExpanded});
  }

  private onPageRedirectRequested = (info : NavigationInfo) : void =>{
    //debugger;
    var targetPath = this.props.tenantName + '/' +  this.props.applicationName + '/' + (info?.EntityName ? info?.EntityName + ( info?.ViewType? '/' + info?.ViewType + (info?.Id ? '/' + info?.Id : '') : '' ) : '');
    Logger.logNotification('Redirect to ' + targetPath);

    var bPathFound : boolean = false;
    if (info && this && this.ViewManager && this.ViewManager.PublicViews){
      for(var x = 0; x<this.ViewManager.PublicViews.length; x++){
        if (this.ViewManager.PublicViews[x].ViewName.toLowerCase() === info.EntityName?.toLowerCase())
        {
          bPathFound = true;
          this.CurrentView = this.ViewManager.PublicViews[x];
          this.setState({CurrentView : this.ViewManager.PublicViews[x], StateKey : Math.random() })          
        }
      }
    }
    if (!bPathFound){
      this.setState({ errorMessage : 'The target view for path \'' + targetPath + '\'  was not found.'})
    }
  }
  private getView = () : XPublicView | null | undefined =>{
    return this.CurrentView;
  }
  closeMessageBar = () => {
    this.setState({ errorMessage: '' });
  };
  render() {
    //var that = this;
    //var cs: ClientState = ClientState.CurrentState();
    var height : number = window.innerHeight - 25;
    var heightStr : string = height.toString() + 'px';

    (window as any)['__TN_SERVERROOTURI__'] = Config.SERVER_URI;
    (window as any)['__TN_INSTANCENAME__'] = this.props.tenantName;
    (window as any)['__TN_TENANTNAME__'] = 'core';

    return (
        <Stack verticalFill horizontalAlign='stretch' className='body-panel'>
          {this.state.isloading && 
            <div style={{marginTop: '50px'}}>
              <Spinner label="Please wait while we're loading content..." ariaLive="assertive" labelPosition="right" />
            </div>
          }
          {!this.state.isloading && !this.state.errorMessage &&
            <iframe title={this.CurrentView?.ViewName} srcDoc={this.CurrentView?.ViewHtmlData} style={{height: heightStr, width: '100%'}} sandbox='allow-scripts allow-same-origin allow-top-navigation allow-modals' scrolling='auto' frameBorder='no' ></iframe>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
            >
              {this.state.errorMessage}
            </MessageBar>
          }
        </Stack>
    );
  }
}
