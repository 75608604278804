import {  Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { AuthorProfile } from "../../Entities/AuthorProfile";

interface AuthorProfilesEditorProps  {
    isReadOnly : boolean;
    Entity: any;
    PropertyName: string;
    tenantId: string;
}

interface AuthorProfilesEditorState extends AuthorProfilesEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  items: IDropdownOption[];
}


export class AuthorProfilesEditorControl extends React.Component<AuthorProfilesEditorProps, AuthorProfilesEditorState> {
    constructor(props: AuthorProfilesEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly : props.isReadOnly,
        Entity: props.Entity,
        PropertyName: props.PropertyName,
        tenantId: props.tenantId,
        items: new Array<IDropdownOption>(),
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
        this.loadProfiles();
    }

    private loadProfiles = () : void => {
      let dh: DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      dh.loadProfilesInTenant(this.props.tenantId)
        .then(function(res: Array<AuthorProfile>){
          let items: IDropdownOption[] = new Array<IDropdownOption>();
          if (res){
            res.forEach(function(item: AuthorProfile, index: number){
              items.push({key: item.Id, text: item.DisplayName });
            })
          }
          let it: any = that.state.Entity;
          if (items.length > 0 && !that.state.Entity[that.state.PropertyName]){
            it[that.state.PropertyName] = items[0].key;
          }
          that.setState({items: items, errorMessage: '', Entity: it});
          that.forceUpdate();
        })
        .catch(function(err:any){
          that.setState({errorMessage: err.message});
        })
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
      
      return (
         <Dropdown label={'Author Profile'} disabled={that.props.isReadOnly} options={that.state.items} selectedKey={that.state.Entity[that.state.PropertyName]} style={{width: '100%'}} onChange={that.setAuthor} ></Dropdown>
      );
    }

    private setAuthor = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
      let that = this;
      that.state.Entity[that.state.PropertyName] = option?.key;
      that.forceUpdate();
    }
  }
  