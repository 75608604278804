import { CommandBar, DefaultButton, ICommandBarItemProps, Label, ProgressIndicator, Stack } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";
//import { ClientState } from "../ClientState";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";

export interface TNUserRolesEditProps {
    tenantId: string;
    isReadOnly: boolean;
}

export interface TNUserRolesEditState  {
    allRoles: any[];
    userRoles: any[];
    errorMessage?: string;
    notificationMessage?: string;
    cmdBarItems: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
    isloading: boolean;
    issaving: boolean;
}
  
  export class TNUserRolesEditControl extends React.Component<TNUserRolesEditProps, TNUserRolesEditState> {

      constructor(props : TNUserRolesEditProps){
          super(props);
          
          this.state = {
            allRoles: new Array<any>(),
            userRoles: new Array<any>(),
            cmdBarItems: new Array<ICommandBarItemProps>(),
            farItems: new Array<ICommandBarItemProps>(),
            isloading: false,
            issaving: false
          }

      }

      private innerSetRoles = (res: any) => {
          let that = this;
          let allRoles: any[] = new Array<any>();
          let userRoles : any[]= new Array<any>();
          if (res && res.AllRoles){
              allRoles = res.AllRoles as any[];
          }
          if (res && res.UserRoles){
              userRoles = res.UserRoles as any[];
          }
          allRoles.forEach(function(rit: any, index: number){
              rit.DisplayName = `${rit.ApplicationName} - ${rit.DisplayName}`
          })
          userRoles.forEach(function(rit: any, index: number){
             rit.DisplayName = `${rit.ApplicationName} - ${rit.DisplayName}`
          })

          let allRolesFiltered: any[] = new Array<any>();
          allRoles.forEach(function(it: any, index: number){
              if (it && it.DisplayName && !userRoles.some(z => z.DisplayName === it.DisplayName)){
                allRolesFiltered.push(it);
              }
          })
          userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
          allRolesFiltered.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);

          that.setState({allRoles: allRolesFiltered, userRoles: userRoles, isloading: false});
          that.forceUpdate();
      }

      private loadData = () : void =>{
        var that = this;
        var emp : EntityPersistanceManager = new EntityPersistanceManager();
        this.setState({isloading : true});
        emp.getUserRoles(this.props.tenantId)
          .then(function(res: any){
              that.innerSetRoles(res);
          })
        .catch(function(error: any){
          that.setState({errorMessage: error?.message, isloading: false});
        })
      }
      public componentDidMount(){
          var that = this;
          that.loadData();

          let barItems : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
          barItems.push({
              key: 'Refresh',
              text: 'Refresh',
              iconOnly: false,
              iconProps: {iconName: 'Refresh'},
              onClick: (ev?: any) => {that.loadData();}
          });
          barItems.push({
            key: 'Save',
            text: 'Save',
            iconOnly: false,
            iconProps: {iconName: 'Save'},
            onClick: (ev?: any) => {that.saveData();}
          });
          that.setState({cmdBarItems: barItems});
      }
      private saveData = () : void => {
          let that =this;
        let items: any[] = this.state.userRoles;
        let itemIds : string[] = new Array<string>();
        if (items){
            items.forEach(function(it: any, idx: number){
                itemIds.push(it.Id);
            })
            var emp : EntityPersistanceManager = new EntityPersistanceManager();
            this.setState({issaving : true});
            emp.setUserRoles(this.props.tenantId, itemIds)
                .then(function(res: any){
                    that.innerSetRoles(res);
                    that.setState({issaving : false, notificationMessage: 'User Roles Saved'});
                })
                .catch(function(err: any){
                    that.setState({errorMessage: err?.message });
                })
        }
      }

      private addRoleToUser = (roleName: string) : void => {
        let allRoles: any[] = this.state.allRoles;
        let userRoles : any[]= this.state.userRoles;
        let role : any= allRoles.find(z => z.DisplayName === roleName);
        userRoles.push(role);
        allRoles.splice(allRoles.indexOf(role), 1);
        userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        allRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        this.setState({allRoles: allRoles, userRoles: userRoles});
      }
      private removeRoleFromUser = (roleName: string) : void => {
        let allRoles: any[] = this.state.allRoles;
        let userRoles : any[]= this.state.userRoles;
        let role : any= userRoles.find(z => z.DisplayName === roleName);
        allRoles.push(role);
        userRoles.splice(userRoles.indexOf(role), 1);
        userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        allRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        this.setState({allRoles: allRoles, userRoles: userRoles});
      }
      public render(){
          var that = this;
          var idStr : string = 'tnOwerEditRow';

          return (
              <Stack verticalFill horizontalAlign="stretch">
                  <CommandBar
                    items={that.state.cmdBarItems}
                    overflowButtonProps={{ ariaLabel: "More commands" }}
                    farItems={that.state.farItems}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    />
                    {that.state.isloading &&
                         <ProgressIndicator label="Loading..." />
                    }
                    {that.state.issaving &&
                         <ProgressIndicator label="Saving..." />
                    }
                  <Stack horizontalAlign="stretch" horizontal>
                      <Stack.Item style={{width:'10%'}}>

                      </Stack.Item>
                      <Stack.Item style={{width:'30%'}}>
                          <Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: '15'}}>
                              <Label style={{marginBottom: '20px'}}>All Roles</Label>
                            {that.state.allRoles.map(function(item: any, index: number){
                                return (<DefaultButton key={nanoid()} iconProps={{iconName: 'Add'}} text={item.DisplayName} onClick={(ev?: any) => {that.addRoleToUser(item.DisplayName); }}  ></DefaultButton> );
                            })}
                          </Stack>
                      </Stack.Item>
                      <Stack.Item style={{width:'20%'}}>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                      </Stack.Item>
                      <Stack.Item style={{width:'30%'}}>
                            <Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: '15'}}>
                            <Label style={{marginBottom: '20px'}}>User Assigned Roles</Label>
                            {that.state.userRoles.map(function(item: any, index: number){
                                return (<DefaultButton key={nanoid()} iconProps={{iconName: 'Delete'}} text={item.DisplayName} onClick={(ev?: any) => {that.removeRoleFromUser(item.DisplayName); }} ></DefaultButton> );
                            })}
                          </Stack>
                      </Stack.Item>
                      <Stack.Item style={{width:'10%'}}>
                          
                      </Stack.Item>
                  </Stack>
              </Stack>
          );
      }
  }