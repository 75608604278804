import { nanoid } from "nanoid";
import { IllerisNinthAPI } from "./ServiceResult";

export class DynamicEntity{
    private InnerObject : any;
    public LogicalEntityName : string = '';
    public PrimaryKeyName : string = '';
    public Relations : Array<DynamicEntityRelation> = new Array<DynamicEntityRelation>();
    public LastModifiedDate : Date = new Date();
    // Unique key containing version info
    public VersionKey: string = nanoid();

    constructor(obj : any, logName : string){
        this.InnerObject = obj;
        this.LogicalEntityName = logName;
        this.PrimaryKeyName = 'Id';
        this.VersionKey = nanoid();
    }
    public getId = () : string | null =>{
        if (this.hasProperty(this.PrimaryKeyName)){
            return this.getValue(this.PrimaryKeyName)?.toString();
        }
        else
            return null;

    }
    public updateObject = (obj : any, bRaiseUpdateEvent : boolean = true) : void =>{
        this.InnerObject = obj;
        this.LastModifiedDate = new Date();
        var sr : IllerisNinthAPI.ServiceResult = obj as IllerisNinthAPI.ServiceResult;
        if (sr && sr.EntityName){
            this.LogicalEntityName = sr.EntityName;
        }
        this.VersionKey = nanoid();
        if (bRaiseUpdateEvent){
            document.dispatchEvent(new CustomEvent("EntityPropertyChanged", {detail : { PropertyName : "*" }, bubbles: true}));
        }
    }
    public hasProperty = (propertyName : string) : boolean =>{
        if (!this.InnerObject)
            return false;
        if (typeof this.InnerObject[propertyName] !== 'undefined'){
            return true;
        }
        else{
            return false;
        }
    }
    public getValue = (propertyName : string | null | undefined) : any | null =>{
        if (!propertyName){
            return null;
        }

        if (!this.hasProperty(propertyName))
            return null;
        return this.InnerObject[propertyName];
    }
    public getValueAsDate = (propertyName : string | null | undefined) : Date | null =>{
        if (!propertyName){
            return null;
        }

        if (!this.hasProperty(propertyName))
            return null;
        var dtStr : string | null = this.InnerObject[propertyName]?.toString();
        if (dtStr){
            return new Date(dtStr);
        }
        else{
            return null;
        }
    }
    public getValueAsDateString = (propertyName : string | null | undefined) : string =>{
        let dt: Date | null = this.getValueAsDate(propertyName);
        if (dt){
            return dt.toLocaleString()
        }
        else{
            return '';
        }
    }
    /*public getValueAsType<Type>(propertyName : string | null | undefined) : Type | null {
        if (!propertyName){
            return null;
        }

        if (!this.hasProperty(propertyName))
            return null;
        if (Type is Date){

        }
        return this.InnerObject[propertyName] as Type;
    }*/
    public setValue = (propertyName : string, val: any | null) : any |null =>{
        var bIsDirty : boolean = false;
        if (!this || !this.InnerObject){
            console.log(`ERROR: dynamic entity setvalue failed: object is null.`);
        }
        else{
            if (this.InnerObject[propertyName] !== val){
                bIsDirty = true;
                this.VersionKey = nanoid();
            }
            this.InnerObject[propertyName] = val;
            if (bIsDirty){
                document.dispatchEvent(new CustomEvent("EntityPropertyChanged", {detail : { PropertyName : propertyName }, bubbles: true}));
            }
        }

        return val;
    }
    public hasRelation = (relName : string) : boolean =>{
        if (!this.Relations || this.Relations.length === 0){
            return false;
        }
        for(var i = 0; i<this.Relations.length; i++){
            if (this.Relations[i].Name?.toLowerCase() === relName?.toLowerCase()){
                return true;
            }
        }
        return false;
    }
    public getRelation = (relName : string) : DynamicEntityRelation | null =>{
        if (!this.Relations || this.Relations.length === 0){
            return null;
        }
        for(var i = 0; i<this.Relations.length; i++){
            if (this.Relations[i].Name?.toLowerCase() === relName?.toLowerCase()){
                return this.Relations[i];
            }
        }
        return null;
    }
    public addRelation = (relName : string, foreignKeyName : string, items : Array<any>) : DynamicEntityRelation | undefined |null =>{
        var rel : DynamicEntityRelation | undefined |null = this.getRelation(relName);
        if (rel){
            rel.Children = items;
            return rel;
        }
        else{
            rel = new DynamicEntityRelation();
            rel.Name = relName;
            rel.ForeignKeyName = foreignKeyName;
            rel.Children = items;
            this.Relations.push(rel);
            this.VersionKey = nanoid();
            return rel;
        }
    }

    public isNew = () : boolean => {
        if (this.InnerObject === null)
            return false;
        else if (typeof this.InnerObject[this.PrimaryKeyName] !== 'undefined' && this.InnerObject[this.PrimaryKeyName] === '00000000-0000-0000-0000-000000000000')
            return true;
        else
            return false;
    }
    public isNewOrUndefined = () : boolean => {
        if (typeof this.InnerObject === 'undefined' || this.InnerObject === null)
            return false; // not a detail form
        else if (typeof this.InnerObject[this.PrimaryKeyName] !== 'undefined' && this.InnerObject[this.PrimaryKeyName] === '00000000-0000-0000-0000-000000000000')
            return true;
        else if (typeof this.InnerObject[this.PrimaryKeyName] === 'undefined')
            return true;
        else
            return false;
    }
    public isValid = () : boolean => {
        if (typeof this.InnerObject[this.PrimaryKeyName] !== 'undefined')
            return true;
        else
            return false;
    }

    public getEntity = () : any =>{
        return this.InnerObject;
    }
}

export class DynamicEntityRelation{
    public Name : string = '';
    public TargetEntity : string = '';
    public QueryURI : string = '';
    public Children : Array<any> = new Array<any>();
    public IsLoaded : boolean = false;
    public ForeignKeyName : string = '';
    constructor(){
        this.IsLoaded = false;
    }
}