import {  IButtonProps, ICommandBarItemProps, Stack, TextField, Toggle } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { SurfaceEditorBase } from "../SurfaceBase";

interface SectionSurfaceEditorProps extends SurfaceEditorBase {
    Form : IllerisNinthUI.Formlet;
    Section : IllerisNinthUI.TNSection;
    tabIndex : number;
    Tab?: IllerisNinthUI.TNTab | null;
    getEntity : () => EntityDefinition;
    isReadOnly : boolean;
}

interface TabItemSurfaceEditorState extends SectionSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class SectionSurfaceEditorControl extends React.Component<SectionSurfaceEditorProps, TabItemSurfaceEditorState> {
    constructor(props: SectionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        Form : props.Form,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Section : props.Section,
        getEntity : props.getEntity,
        isReadOnly : props.isReadOnly,
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >Section Properties</div>
            <TextField label='Item Id' value={this.state.Section.ItemID} readOnly={true} ></TextField>
            <TextField label='Title' value={this.state.Section.Title} readOnly={this.state.isReadOnly} onChange={this.onTitleChanged}  ></TextField>
            <Toggle label='Show Title' checked={this.state.Section.IsTitleVisible} disabled={this.state.isReadOnly} onChange={this.onIsTitleVisibleChanged} ></Toggle>
        </Stack>
      );
    }

    private onTitleChanged = (evt: any, newValue? : string) =>{
        var sn : IllerisNinthUI.TNSection = this.state.Section;
        sn.Title = newValue ?? '';
        this.setState({Section : sn});
    }

    private onIsTitleVisibleChanged = (evt: any, checked? : boolean) =>{
        var sn : IllerisNinthUI.TNSection = this.state.Section;
        sn.IsTitleVisible = checked ?? false;
        this.setState({Section : sn});
    }
  }
  