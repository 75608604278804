import { SilentRequest } from "@azure/msal-browser";
import { IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps, Stack, TextField } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "./MetaModel/UI/Formlet";
import { Guid } from "./Guid";
import { MSALHelper } from "./Helpers/MSALHelper";
import { IllerisNinthAPI } from "./ServiceResult";

export interface TNDocumentInputEditorProps {
    itemId? : string;
    isReadOnly: boolean;
    getFile : () => TNDocumentInfo | null;
    textWidth : number;
    labelText :string;
    maxFileSize : number | null;
    acceptedFiles : string;
    hsBindName : string;
    updateValue : (propName: string, val: any) => void;

}

export interface TNDocumentInputEditorState  {
    valueStr? : string;
    valObj? : any;
    isLoading : boolean;
    errorMessage : string;
    
}

export class TNDocumentInfo{
    public Id : string = '';
    public FileName : string = '';
    public MimeType : string = '';
    public FileExt : string = '';
    public FileSize : number = 0;
    public FileDataBase64 : string = '';
}

const documentIcon: IIconProps = { iconName: 'Document' };
  
  export class TNDocumentInputEditor extends React.Component<TNDocumentInputEditorProps, TNDocumentInputEditorState> {
      private mMenuProps : IContextualMenuProps;
      private mFile : TNDocumentInfo | null;
      private mItemId : string;
    constructor(props : TNDocumentInputEditorProps){
        super(props);
        this.state ={
            valueStr : '',
            isLoading : false,
            errorMessage : ''
        }
        this.mFile = null;
        this.mItemId = Guid.newGuid();

        this.mMenuProps = {
            items: [
              {
                key: 'upload',
                text: 'Upload',
                iconProps: { iconName: 'Upload' },
                disabled: this.props.isReadOnly,
                onClick: this.onMenuButtonClick
              },
              {
                key: 'download',
                text: 'Download',
                iconProps: { iconName: 'Download' },
                onClick: this.onMenuButtonClick
              },
            ],
          };
        this.onButtonClicked.bind(this);
        this.onMenuButtonClick.bind(this);
        this.onUploadFileButtonClicked.bind(this);
        this.internalSaveFile.bind(this);
    }

    private onMenuButtonClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : void =>{
        //debugger;
        
        if (item && item.key === 'upload'){
            this.onUploadFile();
        }
        else if (item && item.key === 'download'){
            if (!this.mFile || !this.mFile.FileName){
                return;
            }
            this.onDownloadFile();
        }
    }

    private onDownloadFile = () : void =>{
        var a = document.createElement("a"); //Create <a>
        var imgData = this.mFile?.FileDataBase64;
        a.setAttribute('href', 'data:' + this.mFile?.MimeType + ';base64,' + imgData);
        var fnm = this.mFile?.FileName;
        if (fnm)
            a.download = fnm;
        else
            a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
    }
    private onUploadFile = () : void =>{
        var it = document.getElementById(this.mItemId);
        setTimeout(function(){
            if(it && document.createEvent) {
                var evt = document.createEvent("MouseEvents");
                evt.initEvent("click", true, false);
                it.dispatchEvent(evt);
             }
          
            //it?.click();
        },200);
        

        // var i = document.createElement('input');
        // i.type = 'file';
        // i.setAttribute('style', 'visibility:hidden');
        // i.addEventListener('click', function(ev : any){
        //     var it = ev.currentTarget;
        //     if (it !== null) {
        //         var fileList = ev.target.files;
        //         var hasBigFiles = false;
        //         for (var x = 0; x < fileList.length; x++) {
        //             if (fileList[x].size > (that.props.maxFileSize ?? Number.MAX_VALUE)) {
        //                 hasBigFiles = true;
        //             }
        //         }
        //         if (hasBigFiles) {
        //             var msg = 'Your selection contains one or more files bigger then ' + ((that.props.maxFileSize ?? Number.MAX_VALUE) / (1024 * 1024)).toFixed(2) + 'Mb. Files of that size are not supported.';
        //             IllerisNinthUI.NotificationManager.showError('Upload failed', msg);
        //             return;
        //         }
        //         that.readFileData(fileList);

        //     }
        // })
        // i.click();

    }

    private onUploadFileButtonClicked(ev :any){
        var that = this;
        //debugger;
        var it = ev.currentTarget;
            if (it !== null) {
                var fileList = ev.target.files;
                var hasBigFiles = false;
                for (var x = 0; x < fileList.length; x++) {
                    if (fileList[x].size > (that.props.maxFileSize ?? Number.MAX_VALUE)) {
                        hasBigFiles = true;
                    }
                }
                if (hasBigFiles) {
                    var msg = 'Your selection contains one or more files bigger then ' + ((that.props.maxFileSize ?? Number.MAX_VALUE) / (1024 * 1024)).toFixed(2) + 'Mb. Files of that size are not supported.';
                    IllerisNinthUI.NotificationManager.showError('Upload failed', msg);
                    return;
                }
                that.readFileData(that, fileList);

            }
    }

    private readFileData(ctrl : TNDocumentInputEditor, fileList : any) {
        var that = this;
        var reader = new FileReader();
        //var newFiles = new Array<any>();
        function readFile(index : number) {
            //debugger;
            if (index >= fileList.length) {

                //that.updateImage();
                return;
            }
            else {
                var file = fileList[index];
                var ft = file.type;
                var act = (that.props.acceptedFiles ? that.props.acceptedFiles.split(';') : new Array<string>());
                if (ft && act.length > 0 && !act.includes(ft)) {
                    var msg : string = 'Invalid file type. Only these file type(s) are supported :' + that.props.acceptedFiles;
                    IllerisNinthUI.NotificationManager.showError('Upload failed', msg);
                    ctrl.setState({errorMessage : msg})
                    return;
                }
                reader.onload = function (e : any) {
                    //debugger;
                    var fnm = fileList[index].name;
                    var fileType = fileList[index].type
                    var fileData = e.target.result;
                    //that.ImageData = fileData;
                    that.internalSaveFile(ctrl, fileData, fileType, fnm);
                    //that.updateImage();

                    readFile(index + 1);

                }
                reader.readAsDataURL(file);
            }
        }
        readFile(0);
    }

    private internalSaveFile(ctrl : TNDocumentInputEditor, fileData : any, fileType : string, fileName : string) {
        debugger;
        var that = this;
        if (!that.mFile)
            that.mFile = new TNDocumentInfo();

        that.mFile.FileName = fileName;
        that.mFile.MimeType = fileType;
        that.mFile.FileDataBase64 = fileData;

        if (!that.mFile.Id || that.mFile.Id) {
            var postUrl = '/api/v2.0/terranova/xdata/core/runtime/document';
            var cr : SilentRequest = MSALHelper.getDefaultScope();
            var mh : MSALHelper = new MSALHelper();
            mh.execApiCallPost(cr.scopes, postUrl, JSON.stringify(that.mFile)).then(function(res : any){
                var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                if (sr && sr.ErrorMessages && sr.ErrorMessages.length > 0){
                    IllerisNinthUI.NotificationManager.showError('Failed to store document', 'Failed to store document : ' +sr.ErrorMessages.join('.'));
                }
                else if (sr && sr.Value){

                    if (!that.mFile){
                        that.mFile = new TNDocumentInfo();
                    }
                    that.mFile.Id = sr.Value.Id;
                    that.mFile!.FileName = sr.Value.FileName;
                    that.mFile!.FileExt = sr.Value.FileExt;
                    that.mFile!.MimeType = sr.Value.MimeType;
                    that.mFile!.FileDataBase64 = sr.Value.DataBase64;
                    if (sr.Value.Data){
                        that.mFile!.FileDataBase64 = sr.Value.Data;
                    }
                    that.mFile!.FileSize = sr.Value.Length;
                }else{
                    that.mFile = null;
                }
                that.props.updateValue(that.props.hsBindName, that.mFile);
                if (that.state.errorMessage){
                    that.setState({errorMessage : ''});
                }
                else{
                    that.forceUpdate();
                }
            })
            .catch(function(err: any){
                IllerisNinthUI.NotificationManager.showError('Error loading file', err.message);
            });
        }
        /*else {
            //var postUrl = '/api/v2.0/terranova/xdata/core/runtime/document/' + that.DocumentObject.Id;
            var postUrl = '/api/v2.0/terranova/xdata/core/runtime/document';
            axios.post(postUrl, that.DocumentObject)
                .then(function (response) {
                    that.setBusy(false);
                    if (response && response.data && response.data.ErrorMessages && response.data.ErrorMessages.length > 0) {
                        Illeris.Ninth.UI.NotificationManager.showError('Failed to store document : ' + response.data.ErrorMessages.join('.'));
                    }
                    else if (response && response.data && response.data.Value) {
                        that.DocumentObject = response.data.Value;
                        that.setValue(that.DocumentObject.Id);
                        //that.refresh();
                    }
                })
                .catch(function (error) {
                    that.setBusy(false);
                    Illeris.Ninth.UI.NotificationManager.showError('Error saving document : ' + error.message);
                });
        }*/


        

    }

    private loadFile = () : void =>{
        var that = this;
        if (this.mFile && this.mFile.FileDataBase64 && this.mFile.FileDataBase64.length === 36){
            var getUrl = '/api/v2.0/terranova/xdata/core/runtime/document/' + this.mFile.FileDataBase64;
            var cr : SilentRequest = MSALHelper.getDefaultScope();
            var mh : MSALHelper = new MSALHelper();
            mh.execApiCallGet(cr.scopes, getUrl).then(function(res : any){
                var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                if (sr && sr.Value){
                    if (!that.mFile){
                        that.mFile = new TNDocumentInfo();
                    }
                    that.mFile!.FileName = sr.Value.FileName;
                    that.mFile!.FileExt = sr.Value.FileExt;
                    that.mFile!.MimeType = sr.Value.MimeType;
                    that.mFile!.FileDataBase64 = sr.Value.DataBase64;
                    if (sr.Value.Data){
                        that.mFile!.FileDataBase64 = sr.Value.Data;
                    }
                    that.mFile!.FileSize = sr.Value.Length;
                }else{
                    that.mFile = null;
                }
                that.forceUpdate();
            })
            .catch(function(err: any){
                IllerisNinthUI.NotificationManager.showError('Error loading file', err.message);
            });

        }
    }
    
    componentDidMount(){
        var that = this;
        if (!this.mFile){
            this.mFile = this.props.getFile();
            this.loadFile();
        }

        var it = document.getElementById(this.mItemId);
        if (it){
            //it.addEventListener('change', this.onUploadFileButtonClicked);
            it.addEventListener('change', function(ev : any){
                debugger;
                var it = ev.currentTarget;
                if (it !== null) {
                    var fileList = ev.target.files;
                    var hasBigFiles = false;
                    var maxSz : number = (that.props && that.props.maxFileSize ? that.props.maxFileSize : Number.MAX_VALUE);
                    for (var x = 0; x < fileList.length; x++) {
                        if (fileList[x].size > (maxSz)) {
                            hasBigFiles = true;
                        }
                    }
                    if (hasBigFiles) {
                        var msg = 'Your selection contains one or more files bigger then ' + ((that.props.maxFileSize ?? Number.MAX_VALUE) / (1024 * 1024)).toFixed(2) + 'Mb. Files of that size are not supported.';
                        IllerisNinthUI.NotificationManager.showError('Upload failed', msg);
                        that.setState({errorMessage : msg});
                        return;
                    }
                    that.readFileData(that, fileList);
        
                }
            });
        }
    }

    componentDidUpdate(prevProps : TNDocumentInputEditorProps, prevState: TNDocumentInputEditorState){
        if (this.props.itemId !== prevProps.itemId || ( this.props.getFile && (this.mFile?.FileDataBase64 !== this.props.getFile()?.FileDataBase64))){
            var fl = this.props.getFile();
            if (fl?.FileName){
                this.mFile = fl;
            }
            else if (fl?.FileDataBase64 && !this.mFile?.FileDataBase64){
                this.mFile = fl;
            }
            this.loadFile();
        }
    }

    private onButtonClicked = (ev : any | undefined) : void => {
        debugger;
        if (ev){
            alert(ev.ctrlKey);
            debugger;
        }
    }


    render() {
        var that = this;

        that.mMenuProps.items[1].disabled = !that.mFile;
        
        return (
            <Stack verticalFill horizontalAlign='stretch'>
            <Stack horizontal horizontalAlign='stretch'>
                <Stack  style={{visibility: 'hidden', width:'0px'}}>
                    <input title="inputfile" type='file' id={this.mItemId} />
                </Stack>
                <TextField label={this.props.labelText} value={that.mFile?.FileName} readOnly={true} disabled={false} className="tndocument-input" selected={false} errorMessage={this.state.errorMessage} >
                    
                </TextField>
                <span className="tndocument-button">
                    <IconButton
                        iconProps={documentIcon}
                        menuProps={this.mMenuProps}
                        onClick={this.onButtonClicked}
                        width='125px'
                    />
                    </span>
                {/* <input type='file' id={this.mItemId} style={{visibility: 'hidden'}} /> */}
                {/* {this.props.isReadOnly &&
                    <span className="tndocument-button">
                    <DefaultButton
                        text="..."
                        split
                        disabled
                        splitButtonAriaLabel="Upload or download"
                        aria-roledescription="split button"
                        menuProps={this.mMenuProps}
                        onClick={this.onButtonClicked}
                        checked={false}
                        className="tndocument-button"
                    />
                    </span>
                }
                {!this.props.isReadOnly &&
                    <span className="tndocument-button">
                    <DefaultButton
                        text="..."
                        split
                        splitButtonAriaLabel="Upload or download"
                        aria-roledescription="split button"
                        menuProps={this.mMenuProps}
                        onClick={this.onButtonClicked}
                        checked={false}
                        
                    />
                    </span>
                } */}
            </Stack>
            
            </Stack>
        )
    }
}