import { Pivot, PivotItem, Stack } from "@fluentui/react";
import React from "react";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";
import { EntityFormsViewControl } from "./EntityFormsViewControl";
import { EntityPropertiesViewControl } from "./EntityPropertiesViewControl";
import { EntityRelationsViewControl } from "./EntityRelationsViewControl";
import { EntityViewsViewControl } from "./EntityViewsViewControl";

interface EntityDetailsViewProps {
    ent : EntityDefinition
    appName: string
    tenantInfo : TenantInfo;
    app: SystemApplicationInfo;
    //showEntityDetails?: (ent: EntityDefinition) => void;
}

interface EntityDetailsViewState extends EntityDetailsViewProps {
  StateKey : number;
  errorMessage? : string;
}

export class EntityDetailsViewControl extends React.Component<EntityDetailsViewProps, EntityDetailsViewState> {
    constructor(props: EntityDetailsViewProps) {
      super(props);
      this.state = {
        StateKey : 0,
        ent : props.ent,
        tenantInfo : props.tenantInfo,
        appName : props.appName,
        app: props.app,
      }
    }
  
    componentDidMount() {
    }

    public shouldComponentUpdate = (nextProps: EntityDetailsViewProps, nextState: EntityDetailsViewState) : boolean =>{
        if (nextProps.tenantInfo.Id !== this.props.tenantInfo.Id || (nextProps.ent && this.props.ent && nextProps.ent.Id !== this.props.ent.Id )){
            return true;
        }
        else{
            return false;
        }
    }
    // private onShowEntityDetails = (ev?: any) : void => {
    //     if (this.props.showEntityDetails){
    //         this.props.showEntityDetails(this.props.ent);
    //     }
    // }
    componentDidUpdate(prevProps : EntityDetailsViewProps, prevState: EntityDetailsViewState){
        var that = this;
        if (prevProps.ent && this.props.ent && prevProps.ent.Id !== this.props.ent.Id){
             that.setState({ent : this.props.ent});
             //that.setState({entityName : this.props.ent.DisplayName});
        }
    }
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };
    render() {
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-solution-contentcontrol'>
              <Stack horizontal horizontalAlign='stretch' style={{textAlign:'left'}}>
                  <Stack.Item><h2>Entity: {this.props.ent.DisplayName}</h2></Stack.Item>
                  {/* <Stack.Item align="center"><IconButton iconProps={{iconName: "Settings"}} style={{marginLeft: '80px'}} onClick={this.onShowEntityDetails} ></IconButton></Stack.Item> */}
                  
                  
              </Stack>
              
            <Pivot aria-label="Entity Properties">
                <PivotItem
                    headerText="Entity Properties"
                    headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'Entity Properties',
                    }}
                >
                    <EntityPropertiesViewControl ent={this.props.ent} app={this.props.app} tenantId={this.props.tenantInfo.Id} />
                </PivotItem>
                <PivotItem headerText="Entity Relations">
                    <EntityRelationsViewControl ent={this.props.ent} tenantId={this.props.tenantInfo.Id} app={this.props.app} />
                </PivotItem>
                <PivotItem headerText="Entity Views">
                    <EntityViewsViewControl app={this.props.app} ent={this.props.ent} tenantId={this.props.tenantInfo.Id} />
                </PivotItem>
                <PivotItem headerText="Entity Forms">
                    <EntityFormsViewControl app={this.props.app} ent={this.props.ent} appName={this.props.appName} tenantInfo={this.props.tenantInfo} />
                </PivotItem>
            </Pivot>
          </Stack>
      );
    }
  }
  