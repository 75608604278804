export class SystemApplicationInfo {
    public Id : string = '';
    public DisplayName : string = '';
    public PublisherId : string = '';
    public AppCode : string = '';
    public ApplicationId : string = '';
    public IsActive : boolean = true;
    public TileName : string = '';
    public Order : number = 0;
    public ImageCSSClass : string = '';
    public Version : string = '';
    public DependsOnAppIds: string[] = new Array<string>();
    public OwningTenantId : string = '';
}