import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityRelationDefinition } from "../../Entities/EntityRelation";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { EntityRelationTypeEditorControl } from "./EntityRelationTypeEditor";
import { EntitySelectorSurfaceEditorControl } from "./EntitySelectorSurfaceEditor";

interface EntityRelationDefinitionSurfaceEditorProps {
    TenantId: string;
    ent: EntityDefinition;
    rel: EntityRelationDefinition;
    app: SystemApplicationInfo;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface EntityRelationDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ent: EntityDefinition;
  app: SystemApplicationInfo;
  rel: EntityRelationDefinition;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class EntityRelationDefinitionSurfaceEditorControl extends React.Component<EntityRelationDefinitionSurfaceEditorProps, EntityRelationDefinitionSurfaceEditorState> {
    constructor(props: EntityRelationDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        ent: props.ent,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        rel: props.rel,
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' tokens={{childrenGap: 10}} >
            <div className='tn-editor-properties-title' >{this.state.app.Id ? 'Edit Property' : 'Create New Property'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <TextField label='Display Name' value={this.state.rel.DisplayName} readOnly={(this.state.isReadOnly)} onChange={that.onPropertyNameChanged} ></TextField>
            {/* <TextField label='Relation Type' value={this.state.rel.RelationType} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(this.state.rel.Id))} onChange={that.onRelationTypeChanged}  ></TextField> */}
            <EntityRelationTypeEditorControl TenantId={this.props.TenantId} rel={this.state.rel} isReadOnly={this.state.isReadOnly || !StringHelper.isEmpty(that.state.rel.Id)} ></EntityRelationTypeEditorControl>
            <EntitySelectorSurfaceEditorControl Relation={this.state.rel} TenantId={that.props.TenantId} AppId={that.props.app.Id} IsReadOnly={this.state.isReadOnly || !StringHelper.isEmpty(that.state.rel.Id)} Label='Target Entity' onChanged={() => this.forceUpdate()} />
            <TextField label='Parent Property Name' value={this.state.rel.ParentPropertyName} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(that.state.rel.Id))} onChange={this.onParentPropertyNameChanged} ></TextField>
            <TextField label='Child Property Name' value={this.state.rel.ChildPropertyName} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(that.state.rel.Id))} onChange={this.onChildPropertyNameChanged} ></TextField>

            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={this.state.isReadOnly} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.rel.TargetEntityId && !this.state.rel.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      if (this.state.rel && !this.state.rel.RelationType){
        this.setState({errorMessage : 'The Relation Type is required'});
        return;
      }
      if (this.state.rel && !this.state.rel.ParentPropertyName){
        this.setState({errorMessage : 'The Parent Property Name is required'});
        return;
      }
      if (this.state.rel && !this.state.rel.ChildPropertyName){
        this.setState({errorMessage : 'The Child Property Name is required'});
        return;
      }
      this.props.closeAndSave();
    }
    private onRelationTypeChanged = (evt: any, newValue? : string) =>{
      var it : EntityRelationDefinition = this.state.rel;
      it.RelationType = newValue ?? '1-N';
      if (it.RelationType !== '1-N' && it.RelationType !== 'N-1' && it.RelationType !== 'N-N' && it.RelationType !== '1-1' ){
        it.RelationType = '1-N';
      }
      this.setState({rel : it});
      this.forceUpdate();
    }
    private onPropertyNameChanged = (evt: any, newValue? : string) =>{
        var it : EntityRelationDefinition = this.state.rel;
        it.DisplayName = StringHelper.displayNameOnly(newValue ?? '');
        this.setState({rel : it});
        this.forceUpdate();
    }
    private onParentPropertyNameChanged = (evt: any, newValue? : string) =>{
      var it : EntityRelationDefinition = this.state.rel;
      it.ParentPropertyName = StringHelper.displayNameOnly(newValue ?? '');
      this.setState({rel : it});
      this.forceUpdate();
    }
    private onChildPropertyNameChanged = (evt: any, newValue? : string) =>{
      var it : EntityRelationDefinition = this.state.rel;
      it.ChildPropertyName = StringHelper.displayNameOnly(newValue ?? '');
      this.setState({rel : it});
      this.forceUpdate();
    }
  }
