import {  ComboBox, CommandBar, ContextualMenu, Dropdown, FontWeights, getTheme, IButtonStyles, IComboBox, IComboBoxOption, ICommandBarItemProps, IconButton, IDragOptions, IDropdownOption, IDropdownStyles, Label, mergeStyleSets, MessageBar, MessageBarType, Modal, Panel, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { nanoid } from "nanoid";
import Notiflix from "notiflix";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { AppResource } from "../../Entities/AppResource";
import { EntityFormDefinition2 } from "../../Entities/EntityForm";
import { EntityFormResourceDefinition } from "../../Entities/EntityFormResource";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { EntityFormScriptImportSurfaceEditorControl } from "./EntityFormScriptImportSurfaceEditor";
import { EntityFormScriptSourceSurfaceEditorControl } from "./EntityFormScriptSourceSurfaceEditor";


interface EntityFormScriptsSurfaceEditorProps {
    TenantId: string;
    AppInfo: SystemApplicationInfo;
    Frm: EntityFormDefinition2;
    isReadOnly : boolean;
    editScript?: (res: EntityFormResourceDefinition) => void;
}

interface EntityFormScriptsSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  isReadOnly : boolean;
  options: IDropdownOption[];
  scripts: EntityFormResourceDefinition[],
  barItems: ICommandBarItemProps[],
  showAddEditPanel: boolean,
  showImportPanel: boolean,
  showScriptEditor: boolean,
  currentEditResource?: EntityFormResourceDefinition 
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};


const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    
  },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export class EntityFormScriptsSurfaceEditorControl extends React.Component<EntityFormScriptsSurfaceEditorProps, EntityFormScriptsSurfaceEditorState> {
    constructor(props: EntityFormScriptsSurfaceEditorProps) {
      super(props);

      let opts: IDropdownOption[] = new Array<IDropdownOption>();
      opts.push({key: 0, text: 'List View'});
      opts.push({key: 1, text: 'Create/Edit'});
      //opts.push({key: '1-1', text: '1-1'});
      //opts.push({key: 'N-N', text: 'N-N'});


      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        options: opts,
        scripts: new Array<EntityFormResourceDefinition>(),
        barItems: new Array<ICommandBarItemProps>(),
        showAddEditPanel: false,
        showScriptEditor : false,
        showImportPanel: false,
      }
  
    }
    componentDidMount() {
      this.loadScripts();

      let mi: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
      mi.push({
        key: 'refresh',
        text: 'Refresh',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: (ev?: any) => {this.loadScripts();}
      });
      mi.push({
        key: 'add',
        text: 'Add',
        iconOnly: true,
        iconProps: {iconName: 'Add'},
        disabled: !this.props.Frm.Id,
        onClick: (ev?: any) => {this.addScript();}
      });
      mi.push({
        key: 'import',
        text: 'Import',
        iconOnly: true,
        iconProps: {iconName: 'Import'},
        disabled: !this.props.Frm.Id,
        onClick: (ev?: any) => {this.addScriptFromAppResource();}
      })
      this.setState({barItems: mi})
      
    }
    
    private addScriptFromAppResource = () : void => {
      this.setState({showImportPanel: true});
    }

    private addScript = () : void => {
      let s: EntityFormResourceDefinition = new EntityFormResourceDefinition();
      s.ApplicationId = this.props.Frm.ApplicationId;
      s.ApplicationName = this.props.Frm.ApplicationName;
      s.EntityId = this.props.Frm.EntityId;
      s.EntityName = this.props.Frm.EntityName;
      s.FormId = this.props.Frm.Id;
      
      this.setState({currentEditResource: s, showAddEditPanel: true});
    }

    private loadScripts = () : void => {
      let that = this;
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      mh.loadEntityFormResources(this.props.TenantId, this.props.AppInfo.Id, this.props.Frm.EntityId, this.props.Frm.Id)
        .then(function(res: EntityFormResourceDefinition[]){
          that.setState({scripts: res});
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message});
        })

      
    }

    private deleteScript = (idx: number) : void => {
      let that = this;
      Notiflix.Confirm.show('Delete', 
                    'Are you sure you want to remove this item?', 
                    'Yes', 
                    'No', 
                    function(){ // Yes button callback 
                      let s: EntityFormResourceDefinition = that.state.scripts[idx];
                      
                        let dmh : DesignTimeModelHelper = new DesignTimeModelHelper();
                        dmh.deleteEntityFormResource(that.props.TenantId, that.props.AppInfo.Id, that.props.Frm.EntityId,s.Id)
                          .then(function(res: boolean){
                            that.loadScripts();
                          })
                          .catch(function(err: any){
                            that.setState({errorMessage: err?.message});
                          })
                    }, 
                    function(){ // No button callback 
                        
                    } ); 
    }
    private editScript = (idx: number) : void => {
      let s: EntityFormResourceDefinition = this.state.scripts[idx];
      if (!s.AppResourceId){
        if (this.props.editScript){
          this.props.editScript(s);
        }
        else if (s){
          this.setState({currentEditResource: s, showScriptEditor: true})
        }
      }
      else{
        IllerisNinthUI.NotificationManager.showError('Error', 'Cannot edit this form resource as it is an imported app resource.');
      }
    }

    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign="stretch">
          <CommandBar
            items={this.state.barItems}
            overflowButtonProps={{ ariaLabel: 'More commands' }}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          {this.state.notificationMessage &&
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.notificationMessage}
            </MessageBar>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.errorMessage}
            </MessageBar>
          }
          {that.state.scripts && 
            that.state.scripts.map((item, idx) => {
              return (
                <Stack horizontal horizontalAlign="stretch" key={nanoid()}>
                  <IconButton iconProps={{iconName: item.AppResourceId ? 'Import' : 'PageEdit'}} style={{width: '35px'}} onClick={(ev?: any) => {that.editScript(idx);}} ></IconButton>
                  <Label style={{width: '100%'}}>{item.DisplayName}</Label>
                  <IconButton iconProps={{iconName: 'Delete'}} style={{width: '35px'}} onClick={(ev?: any) => {that.deleteScript(idx);}} ></IconButton>
                </Stack>
              )
            })
          }
          {this.state.showAddEditPanel && this.state.currentEditResource && 
            <Panel
              headerText="Edit Script"
              isOpen={this.state.showAddEditPanel}
              onDismiss={(ev?: any) => {this.setState({showAddEditPanel: false}); this.forceUpdate();}}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              <EntityFormScriptsSurfaceEditScriptEditorControl
                TenantId={this.props.TenantId}
                AppInfo={this.props.AppInfo}
                isReadOnly={this.props.isReadOnly}
                Script={this.state.currentEditResource!}
                saveOrUpdate={this.saveOrUpdate}
              >

              </EntityFormScriptsSurfaceEditScriptEditorControl>
            </Panel>
          }
          {this.state.showImportPanel && 
            <Panel
              headerText="Import App Resource"
              isOpen={this.state.showImportPanel}
              onDismiss={(ev?: any) => {this.setState({showImportPanel: false}); this.forceUpdate();}}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              <EntityFormScriptImportSurfaceEditorControl
                TenantId={this.props.TenantId}
                AppInfo={this.props.AppInfo}
                isReadOnly={this.props.isReadOnly}
                selectAppResource={this.selectAppResource}
              >
                
              </EntityFormScriptImportSurfaceEditorControl>
            </Panel>
          }
        </Stack>
      );
    }

    private selectAppResource = (appRes: AppResource) : void => {
      debugger;
      let that = this;
      that.setState({showImportPanel: false});
      that.forceUpdate();
      let s: EntityFormResourceDefinition = new EntityFormResourceDefinition();
      s.ApplicationId = this.props.Frm.ApplicationId;
      s.ApplicationName = this.props.Frm.ApplicationName;
      s.EntityId = this.props.Frm.EntityId;
      s.EntityName = this.props.Frm.EntityName;
      s.FormId = this.props.Frm.Id;
      s.DisplayName = appRes.DisplayName;
      s.AppResourceId = appRes.Id;
      if (appRes.ResourceType === 1){
        s.ResourceExtension = '.js';
      }
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      
      mh.updateEntityFormResource(this.props.TenantId, this.props.AppInfo.Id, this.props.Frm.EntityId, this.props.Frm, s)
          .then(function(saveRes: EntityFormResourceDefinition){
            that.loadScripts();
            that.forceUpdate();
          })
          .catch(function(err: any){
            that.setState({errorMessage: `Failed to save script: ${err?.message}`});
            that.forceUpdate();
          })
    }

    private saveOrUpdate = (res: EntityFormResourceDefinition) : void => {
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      that.setState({showScriptEditor: false, showAddEditPanel: false});
      if (res.Id){
        mh.updateEntityFormResource(this.props.TenantId, this.props.AppInfo.Id, this.props.Frm.EntityId, this.props.Frm, res)
          .then(function(saveRes: EntityFormResourceDefinition){
            that.loadScripts();
          })
          .catch(function(err: any){
            that.setState({errorMessage: `Failed to save script: ${err?.message}`});
          })
      }
      else{
        mh.createNewFormResource(this.props.TenantId, this.props.AppInfo.Id, this.props.Frm.EntityId, this.props.Frm, res)
        .then(function(saveRes: EntityFormResourceDefinition){
          that.loadScripts();
        })
        .catch(function(err: any){
          that.setState({errorMessage: `Failed to save script: ${err?.message}`});
        })
      }
    }

  }



interface EntityFormScriptsSurfaceEditScriptEditorProps {
    TenantId: string;
    AppInfo: SystemApplicationInfo;
    Script: EntityFormResourceDefinition;
    isReadOnly : boolean;
    saveOrUpdate: (res: EntityFormResourceDefinition) => void;
}

interface EntityFormScriptsSurfaceEditScriptEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  isReadOnly : boolean;
  options: IDropdownOption[];
  barItems: ICommandBarItemProps[]
  Script: EntityFormResourceDefinition;
}

export class EntityFormScriptsSurfaceEditScriptEditorControl extends React.Component<EntityFormScriptsSurfaceEditScriptEditorProps, EntityFormScriptsSurfaceEditScriptEditorState> {
    constructor(props: EntityFormScriptsSurfaceEditScriptEditorProps) {
      super(props);

      let opts: IDropdownOption[] = new Array<IDropdownOption>();
      opts.push({key: '.js', text: 'Javascript File'});
      //opts.push({key: 1, text: 'Create/Edit'});
      //opts.push({key: '1-1', text: '1-1'});
      //opts.push({key: 'N-N', text: 'N-N'});


      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        options: opts,
        barItems: new Array<ICommandBarItemProps>(),
        Script: props.Script
      }
  
    }
    componentDidMount() {


    }

    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign="stretch">
          {this.state.notificationMessage &&
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.notificationMessage}
            </MessageBar>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.errorMessage}
            </MessageBar>
          }
          <TextField label="Name" value={this.state.Script.DisplayName} onChange={this.onNameChanged}></TextField>
          <ComboBox label="Resource Type" options={this.state.options} selectedKey={this.state.Script.ResourceExtension} onChange={this.onExtensionChanged} ></ComboBox>
          <TextField label="File Name" value={this.state.Script.ResourceName} onChange={this.onFileNameChanged}></TextField>
          <PrimaryButton text="Save" style={{marginTop: '25px'}} onClick={this.onSaveClicked} ></PrimaryButton>
        </Stack>
      );
    }

    private onSaveClicked = (ev? :any) : void => {
      let it: EntityFormResourceDefinition  = this.state.Script;
      if (!it.DisplayName){
        this.setState({errorMessage: 'Name cannot be empty'});
        return;
      }
      if (!it.ResourceName){
        this.setState({errorMessage: 'File Name cannot be empty'});
        return;
      }
      if (!it.ResourceExtension){
        this.setState({errorMessage: 'File Extension cannot be empty'});
        return;
      }
      if (!it.ResourceName.endsWith(it.ResourceExtension)){
        it.ResourceName += it.ResourceExtension;
      }

      this.props.saveOrUpdate(it);

    }

    private onNameChanged = (ev?: any, newValue?: string) : void => {
      let it: EntityFormResourceDefinition  = this.state.Script;
      it.DisplayName = newValue ?? '';
      this.setState({Script: it});
    }
    private onFileNameChanged = (ev?: any, newValue?: string) : void => {
      let it: EntityFormResourceDefinition  = this.state.Script;
      it.ResourceName = newValue ?? '';
      this.setState({Script: it});
    }
    private onExtensionChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
      let it: EntityFormResourceDefinition  = this.state.Script;
      it.ResourceExtension = option?.key.toString() ?? '';
      this.setState({Script: it});
    }
    

  }