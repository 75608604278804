import { SilentRequest } from "@azure/msal-browser";
import { DynamicEntity, DynamicEntityRelation } from "../DynamicEntity";
import { Guid } from "../Guid";
import { MSALHelper } from "./MSALHelper";
import { IllerisNinthAPI } from "../ServiceResult";
//import  Config  from "./config.json";
import { WhoAmIResponse } from "../designers/Entities/WhoAmIResponse";

export class EntityPersistanceManager{
    private mTenantName : string | null;
    constructor(tenantName : string | null = null){
        this.mTenantName = tenantName;
        if (!tenantName){
            var dnsName : string = window.location.hostname;
            if (dnsName){
                var parts : string[] = dnsName.split('.');
                if (parts && parts.length > 0){
                    var tn : string = parts[0];
                    if (tn && (tn.toLowerCase() === 'localhost' || tn.toLowerCase() === 'www' || tn.toLowerCase() === 'portal')){
                        if (window.location.pathname?.toLowerCase().startsWith('/cview/')){
                            this.mTenantName = window.location.pathname?.toLowerCase().substr(7);
                            if (this.mTenantName.indexOf('/') !== -1){
                                this.mTenantName = this.mTenantName.substr(0, this.mTenantName.indexOf('/'));
                            }
                        }
                        else{
                            this.mTenantName = 'core';
                        }
                    }
                    else{
                        this.mTenantName = tn;
                    }
                }
            }
        }
    }

    static getTenantNameFromLocation = () : string =>{
        var dnsName : string = window.location.hostname;
        var rtn : string = 'core';
        if (dnsName){
            var parts : string[] = dnsName.split('.');
            if (parts && parts.length > 0){
                var tn : string = parts[0];
                if (tn && (tn.toLowerCase() === 'localhost' || tn.toLowerCase() === 'www' || tn.toLowerCase() === 'portal')){
                    if (window.location.pathname?.toLowerCase().startsWith('/cview/')){
                        rtn = window.location.pathname?.toLowerCase().substr(7);
                        if (rtn.indexOf('/') !== -1){
                            rtn = rtn.substr(0, rtn.indexOf('/'));
                        }
                    }
                    else{
                        rtn = 'core';
                    }
                }
                else{
                    rtn = tn;
                }
            }
        }
        return rtn;
    }

    private buildValidateURI = (de : DynamicEntity) : string =>{
        let idStr : string = '';
        let idVal = de.getId();
        if (idVal){
            idStr = idVal;
        }
        else{
            idStr = Guid.empty();
        }
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + idStr + '/validate';
        return uri;
    }
    private buildAssignURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + de.getId() + '/assign';
        return uri;
    }
    private buildActivateURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + de.getId() + '/activate';
        return uri;
    }
    private buildDeactivateURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + de.getId() + '/deactivate';
        return uri;
    }
    private buildDeleteURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + de.getId();
        return uri;
    }
    private buildDeleteURIByEntityName = (entName: string, entId: string, appName: string) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + entName + '/' + entId;
        return uri;
    }
    private buildCreateURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName ;
        return uri;
    }
    private buildPatchURI = (de : DynamicEntity) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + de.LogicalEntityName + '/' + de.getId() + '/validate';
        return uri;
    }
    private buildGetManyURI = (entName : string) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + entName ;
        return uri;
    }
    private buildGetByIdURI = (entName : string, idStr: string) : string =>{
        var uri = '/api/v2.0/terranova/xdata/' + (this.mTenantName? this.mTenantName : 'core') + '/' + entName + '/' + idStr ;
        return uri;
    }
    public executeAPI = (apiURI : string, de : DynamicEntity | null, verb: IllerisNinthAPI.HttpMethodType, includeBody : boolean): Promise<IllerisNinthAPI.ServiceResult> =>{
        var itemUri : string = apiURI;
        if (de){
            itemUri = apiURI.replace('{id}', de.getId() ?? Guid.empty());
            itemUri = itemUri.replace('{Id}', de.getId() ?? Guid.empty());
        }
        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        return capi2.invokeAPI(itemUri, verb, includeBody && de ? JSON.stringify(de.getEntity()) : null )
            .then(function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
        
    }
    public executeAPIAny = (apiURI : string, obj : any | null, verb: IllerisNinthAPI.HttpMethodType, includeBody : boolean): Promise<IllerisNinthAPI.ServiceResult> =>{
        var itemUri : string = apiURI;
        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        return capi2.invokeAPI(itemUri, verb, includeBody && obj ? JSON.stringify(obj) : null )
            .then(function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
        
    }

    public saveEntity = (de : DynamicEntity, bDoValidation: boolean = true) : Promise<IllerisNinthAPI.ServiceResult> =>{
        var that= this;
        if (!de){
            return Promise.reject(new Error('The input entity cannot be NULL'));
        }

        var valUri : string = this.buildValidateURI(de);
        var entData = de.getEntity();

        var objId : string | null = de.getId();

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        if (bDoValidation){
        return capi2.invokeAPI(valUri, 'POST', JSON.stringify(entData))
            .then(function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                var verb : string = 'POST';
                if (objId === '00000000-0000-0000-0000-000000000000' || objId || !objId){
                    verb = 'POST';
                }
                else{
                    verb = 'PUT';
                }
                var saveUri : string = (verb === 'PUT' ? that.buildPatchURI(de) : that.buildCreateURI(de));
                return capi2.invokeAPI(saveUri, verb as IllerisNinthAPI.HttpMethodType, JSON.stringify(data.Value))
                .then(async function(res : IllerisNinthAPI.ServiceResult){
                    if (res && res.ValidationResult && !res.ValidationResult.IsOk){
                        return Promise.reject(res);
                    }
                    if (res && res.ErrorMessages && res.ErrorMessages.length > 0){
                        return Promise.reject(res);
                    }

                    de.updateObject(res.Value);
                    if (de.Relations && de.Relations.length > 0){
                        for(var i = 0; i<de.Relations.length; i++){
                            var rel : DynamicEntityRelation = de.Relations[i];

                            if (rel.Children){
                                for(var j = 0; j<rel.Children.length; j++){
                                    var xde : DynamicEntity = new DynamicEntity(rel.Children[j], rel.TargetEntity);
                                    xde.setValue(rel.ForeignKeyName, de.getId());
                                    var cres : IllerisNinthAPI.ServiceResult |null | undefined  = null;
                                    if (rel.Children[j].IsDeleted !== true){
                                        cres = await that.saveEntity(xde);
                                    }
                                    else{
                                        cres = await that.deleteEntity(xde);
                                    }
                                    var cresx : IllerisNinthAPI.ServiceResult = cres as IllerisNinthAPI.ServiceResult;
                                    if (cresx){
                                        rel.Children[j] = res.Value;
                                    }
                                    else{
                                        return Promise.reject(cres);
                                    }
                                }
                            }
                        }
                    }

                    return Promise.resolve(res);
                })
                .catch(function(err: any){
                    return Promise.reject(err);
                })

                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
        }
        else {
            var verb : string = 'POST';
            if (objId === '00000000-0000-0000-0000-000000000000' || objId || !objId){
                verb = 'POST';
            }
            else{
                verb = 'PUT';
            }
            var saveUri : string = (verb === 'PUT' ? that.buildPatchURI(de) : that.buildCreateURI(de));
            return capi2.invokeAPI(saveUri, verb as IllerisNinthAPI.HttpMethodType, JSON.stringify(entData))
            .then(async function(res : IllerisNinthAPI.ServiceResult){
                if (res && res.ValidationResult && !res.ValidationResult.IsOk){
                    return Promise.reject(res);
                }
                if (res && res.ErrorMessages && res.ErrorMessages.length > 0){
                    return Promise.reject(res);
                }

                de.updateObject(res.Value);
                if (de.Relations && de.Relations.length > 0){
                    for(var i = 0; i<de.Relations.length; i++){
                        var rel : DynamicEntityRelation = de.Relations[i];

                        if (rel.Children){
                            for(var j = 0; i<rel.Children.length; j++){
                                var xde : DynamicEntity = new DynamicEntity(rel.Children[j], rel.TargetEntity);
                                xde.setValue(rel.ForeignKeyName, de.getId());
                                var cres : IllerisNinthAPI.ServiceResult |null | undefined  = null;
                                if (rel.Children[j].IsDeleted !== true){
                                    cres = await that.saveEntity(xde);
                                }
                                else{
                                    cres = await that.deleteEntity(xde);
                                }
                                var cresx : IllerisNinthAPI.ServiceResult = cres as IllerisNinthAPI.ServiceResult;
                                if (cresx){
                                    rel.Children[j] = res.Value;
                                }
                                else{
                                    return Promise.reject(cres);
                                }
                            }
                        }
                    }
                }

                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
        }
    }

    public getMany = (entLogicalName: string, odataQueryExpression : string | null = null)  : Promise<IllerisNinthAPI.ServiceResult> =>{
        var uri : string = this.buildGetManyURI(entLogicalName);
        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        if (odataQueryExpression){
            if (odataQueryExpression.startsWith('?')){
                uri += odataQueryExpression;
            }
            else{
                uri += ('?' + odataQueryExpression);
            }
        }
        //debugger;
        return mh.execApiCallGet(cr.scopes, uri).then(function(res : IllerisNinthAPI.ServiceResult){
            //debugger;
            if (res){
                res.LoadedTimeStamp = new Date();
            }
            return Promise.resolve(res);

        }).catch(function(error: Error){
            return Promise.reject(error);
        })
    }

    public activateDeactivateEntity = (de : DynamicEntity, bActivate : boolean) : Promise<IllerisNinthAPI.ServiceResult> =>{
        var that= this;
        if (!de){
            return Promise.reject(new Error('The input entity cannot be NULL'));
        }

        var itemUri : string = (bActivate ?  this.buildActivateURI(de) : this.buildDeactivateURI(de));

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'POST', null)
            .then(async function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                de.updateObject(data.Value);

                if (de.Relations && de.Relations.length > 0){
                    for(var i = 0; i<de.Relations.length; i++){
                        var rel : DynamicEntityRelation = de.Relations[i];

                        if (rel.Children){
                            for(var j = 0; i<rel.Children.length; j++){
                                var xde : DynamicEntity = new DynamicEntity(rel.Children[j], rel.TargetEntity);
                                var cres = await that.activateDeactivateEntity(xde, bActivate);
                                var cresx : IllerisNinthAPI.ServiceResult = cres as IllerisNinthAPI.ServiceResult;
                                if (cresx){
                                    rel.Children[j] = cresx.Value;
                                }
                                else{
                                    return Promise.reject(cres);
                                }
                            }
                        }
                    }
                }
                return Promise.resolve(data);
                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public assignEntity = (de : DynamicEntity, assignToInfo: AssignToInfo) : Promise<IllerisNinthAPI.ServiceResult> =>{
        var that= this;
        if (!de){
            return Promise.reject(new Error('The input entity cannot be NULL'));
        }

        var itemUri : string = this.buildAssignURI(de);

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(assignToInfo))
            .then(async function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                de.updateObject(data.Value);

                if (de.Relations && de.Relations.length > 0){
                    for(var i = 0; i<de.Relations.length; i++){
                        var rel : DynamicEntityRelation = de.Relations[i];

                        if (rel.Children){
                            for(var j = 0; i<rel.Children.length; j++){
                                var xde : DynamicEntity = new DynamicEntity(rel.Children[j], rel.TargetEntity);
                                var cres = await that.assignEntity(xde, assignToInfo);
                                var cresx : IllerisNinthAPI.ServiceResult = cres as IllerisNinthAPI.ServiceResult;
                                if (cresx){
                                    rel.Children[j] = cresx.Value;
                                }
                                else{
                                    return Promise.reject(cres);
                                }
                            }
                        }
                    }
                }
                return Promise.resolve(data);
                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public getById = (entityLogicalName: string, id: string) : Promise<IllerisNinthAPI.ServiceResult> => {
        var that= this;
        if (!entityLogicalName || !id){
            return Promise.reject(new Error('The input entity logicalname or id cannot be null'));
        }

        var itemUri : string = this.buildGetByIdURI(entityLogicalName, id);

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'GET', null)
            .then(async function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }

                return Promise.resolve(data);
                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public deleteEntity = (de : DynamicEntity) : Promise<IllerisNinthAPI.ServiceResult> =>{
        //var that= this;
        if (!de){
            return Promise.reject(new Error('The input entity cannot be NULL'));
        }

        var itemUri : string = this.buildDeleteURI(de);

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'DELETE', null)
            .then(async function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }
                
                return Promise.resolve(data);
                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public deleteEntityById = (entName: string, entId: string, appName: string) : Promise<IllerisNinthAPI.ServiceResult> =>{
        //var that= this;
        if (!entName || !entId){
            return Promise.reject(new Error('The input entity name and Id cannot be NULL'));
        }

        var itemUri : string = this.buildDeleteURIByEntityName(entName, entId, appName);

        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'DELETE', null)
            .then(async function(data : IllerisNinthAPI.ServiceResult){

                if (data && data.ValidationResult && !data.ValidationResult.IsOk){
                    return Promise.reject(data);
                }
                if (data && data.ErrorMessages && data.ErrorMessages.length > 0){
                    return Promise.reject(data);
                }
                
                return Promise.resolve(data);
                //return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }

    public whoAmI = async () : Promise<WhoAmIResponse> => {
        let itemUri: string = '/api/v2.0/terranova/xutil/whoami';
        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        //return capi2.invokeAPI(itemUri, 'POST', JSON.stringify(entData))
        return capi2.invokeAPI(itemUri, 'GET', null)
            .then(async function(data : WhoAmIResponse){
                return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }

    public getUserRoles = async (tenantId: string) : Promise<any> => {
        let itemUri: string = `/api/v2.0/terranova/xutil/tenant/${tenantId}/userroles`;
        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        return capi2.invokeAPI(itemUri, 'GET', null)
            .then(async function(data : any){
                return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public setUserRoles = async (tenantId: string, roleIds: string[]) : Promise<any> => {
        let itemUri: string = `/api/v2.0/terranova/xutil/tenant/${tenantId}/userroles`;
        var capi2 : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
        return capi2.invokeAPI(itemUri, 'POST', roleIds)
            .then(async function(data : any){
                return Promise.resolve(data);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
}

export class AssignToInfo{
    TenantId? : string;
    OwnerId? : string;
    OrganizationId? : string;
    TeamId? : string;
    constructor(){
        this.TenantId = '';
    }
}