/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import { CommandBar, ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps, MessageBar, MessageBarType, Stack, TextView } from "@fluentui/react";
import React  from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { SurfaceBase } from "./SurfaceBase";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { SectionControl } from "../../SectionControl";

interface WebXmlEditorProps extends SurfaceBase{
    XmlString: string;
    updateXml: (newXmlStr: string) => void;
    stopEditor: () => void;
}

interface WebXmlEditorState extends WebXmlEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
  isPanelOpen: boolean;
  currentBarItem: IllerisNinthUI.ActionButton | null;
  ctxButtonItems?: IContextualMenuProps;
  
}

export class WebXmlEditorControl extends React.Component<WebXmlEditorProps, WebXmlEditorState> {
    private mCurrentXml: string | undefined;
    constructor(props: WebXmlEditorProps) {
      super(props);
      
      this.mCurrentXml = props.XmlString;

      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        isPanelOpen: false,
        currentBarItem: null,
        ctxButtonItems: { items: new Array<IContextualMenuItem>()},
        XmlString: props.XmlString,
        updateXml: props.updateXml,
        stopEditor: props.stopEditor,
        appInfo: props.appInfo
      }
      this.handleEditorChange.bind(this);
    }
  
    componentDidMount() {
        //this.buildItems();
        this.buildMenuItems();
    }

    private buildMenuItems = () : void => {
      let that = this;
      let mi: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
      mi.push({
        key: 'save',
        text: 'Save',
        ariaLabel: 'Save',
        iconOnly: true,
        iconProps: {iconName: 'Save'},
        onClick: that.onSaveXml
      })
      mi.push({
        key: 'refresh',
        text: 'Reload',
        ariaLabel: 'Reload',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: that.onRefreshXml
      })
      mi.push({
        key: 'power',
        text: 'Off',
        ariaLabel: 'Off',
        iconOnly: true,
        iconProps: {iconName: 'PowerButton'},
        onClick: that.onQuitEdit
      })
      that.setState({cmdBarItems: mi});
    }
    private onRefreshXml = (evt?: any) : void => {
      this.setState({XmlString: this.props.XmlString});
    }
    private onQuitEdit = (evt?: any) : void => {
      this.props.stopEditor();
    }
    private onSaveXml = (evt?: any) : void => {
      if (!this.mCurrentXml){
        this.setState({errorMessage: 'Error parsing XML.'});
        return;
      }
      try{
        let domParser:DOMParser = new DOMParser();
        let dom : Document = domParser.parseFromString(this.mCurrentXml, 'text/xml'); 
        if (dom.documentElement.nodeName === 'parseerror'){
          this.setState({errorMessage: 'Error parsing XML.'});
          return;
        }
        this.props.updateXml(this.mCurrentXml);
      }
      catch(error: any){
        this.setState({errorMessage: 'ERROR: ' + error?.message});
        return;
      }
    }

    
    private formatXml = (xml: string) : string => {
      const PADDING = ' '.repeat(2);
      const reg = /(>)(<)(\/*)/g;
      let pad = 0;
    
      xml = xml.replace(reg, '$1\r\n$2$3');
    
      return xml.split('\r\n').map((node: any, index: number) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/) && pad > 0) {
          pad -= 1;
        } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
          indent = 1;
        } else {
          indent = 0;
        }
    
        pad += indent;
    
        return PADDING.repeat(pad - indent) + node;
      }).join('\r\n');
    }
    

    private updateParent = () : void =>{
      this.forceUpdate();
    }

    private handleEditorChange = (value: string | undefined, event: any) : void => {
      if (value){
        //this.props.updateXml(value);
        this.mCurrentXml = value;
      }
    }
  

    render() {
      let that = this;
      return (
        <Stack verticalFill  horizontalAlign="stretch" style={{marginLeft: '10px', marginRight: '10px'}}>
          
          
          <SectionControl headerText="View XML">
            <CommandBar
              items={this.state.cmdBarItems}
              overflowButtonProps={{ ariaLabel: 'More commands' }}
              farItems={this.state.cmdBarFarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <TextView style={{marginLeft: '25px'}}>Beware of editing the XML layout directly. It might corrupt your view.</TextView>
            <div style={{marginTop: '10px'}}></div>
            {this.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <Editor
              height="90vh"
              defaultLanguage="xml"
              defaultValue={this.formatXml(this.state.XmlString)}
              wrapperProps={{wordWrap: 'on', wordWrapColumn: 80}}
              onChange={this.handleEditorChange}

            />
          </SectionControl>
          
        </Stack>
      );
    }
   
  }
  

