import {  Checkbox, ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityPropertyDefinition, ItemOwnershipLevel } from "../../Entities/EntityProperty";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { EntityPropertyDataTypeSurfaceEditorControl } from "./EntityPropertyDataTypeSurfaceEditor";
import { EntityPropertyTextFormatSurfaceEditorControl } from "./EntityPropertyTextFormSurfaceEditor";

interface EntityPropertyDefinitionSurfaceEditorProps {
    ent: EntityDefinition;
    prop: EntityPropertyDefinition;
    app: SystemApplicationInfo;
    isReadOnly : boolean;
    closeAndSave : () => void;
    deleteHandler?: (propId: string) => void;
}

interface EntityPropertyDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ent: EntityDefinition;
  app: SystemApplicationInfo;
  prop: EntityPropertyDefinition;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class EntityPropertyDefinitionSurfaceEditorControl extends React.Component<EntityPropertyDefinitionSurfaceEditorProps, EntityPropertyDefinitionSurfaceEditorState> {
    constructor(props: EntityPropertyDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: (props.isReadOnly || EntityPropertyDefinition.getOwnershipLevel(props.prop) === ItemOwnershipLevel.System || EntityPropertyDefinition.getOwnershipLevel(props.prop) === ItemOwnershipLevel.Platform) ,
        app: props.app,
        ent: props.ent,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        prop: props.prop,
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' tokens={{childrenGap: 10}} >
            <div className='tn-editor-properties-title' >{this.state.app.Id ? 'Edit Property' : 'Create New Property'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <TextField label='Property Name' value={this.state.prop.DisplayName} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(this.state.prop.Id))} onChange={that.onPropertyNameChanged} ></TextField>
            <TextField label='Logical Name' value={this.state.prop.LogicalName} readOnly={true}  ></TextField>
            <EntityPropertyDataTypeSurfaceEditorControl Property={this.state.prop} IsReadOnly={this.state.isReadOnly || !StringHelper.isEmpty(this.state.prop.Id) } Label='Datatype' onChanged={() => this.forceUpdate()} />
            <Checkbox label='Is Required' checked={this.state.prop.IsRequired} disabled={this.state.isReadOnly}  ></Checkbox>
            {(this.state.prop.DataType === 6 || this.state.prop.DataType === 0) &&
              <>
              <TextField label='Maximum Length' value={this.state.prop.MaxLength?.toString() ?? ''} readOnly={this.state.isReadOnly || (this.state.prop.DataType !== 6 && this.state.prop.DataType !== 0)} onChange={that.onIsLengthChanged} type='number'   ></TextField>
              <EntityPropertyTextFormatSurfaceEditorControl Property={this.state.prop} Label='Text Format' onChanged={() => this.forceUpdate()} IsReadOnly={this.state.isReadOnly || (this.state.prop.DataType !== 6 && this.state.prop.DataType !== 0)}/>
              </>
            }
            
            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={this.state.isReadOnly} checked={false} style={{marginTop: '25px'}} />
            {this.props.deleteHandler && this.state.prop.Id && 
              <PrimaryButton text="Delete" iconProps={{iconName: 'Delete'}} onClick={(ev?: any) => { this.props.deleteHandler!(this.props.prop.Id); this.closeAndSave(ev); }} checked={false} style={{marginTop: '25px'}} />
            }
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.prop && !this.state.prop.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      if (this.state.prop && !this.state.prop.LogicalName){
        this.setState({errorMessage : 'The Logical Name is required'});
        return;
      }
      /*if (this.state.prop && !this.state.prop.DataType){
        this.setState({errorMessage : 'The Datatype is required'});
        return;
      }*/
      this.props.closeAndSave();
    }

    private onPropertyNameChanged = (evt: any, newValue? : string) =>{
        var it : EntityPropertyDefinition = this.state.prop;
        it.DisplayName = StringHelper.displayNameOnly(newValue ?? '');
        it.LogicalName = this.props.app.AppCode + '_' + StringHelper.alphaNumbericOnly(newValue ?? '');
        this.setState({prop : it});
        this.forceUpdate();
    }

    private onIsLengthChanged = (evt: any, newValue? : string) =>{
      var it : EntityPropertyDefinition = this.state.prop;
      it.MaxLength =   Number.parseInt(newValue ?? '0');;
      this.setState({prop : it});
      this.forceUpdate();
    }

    
  }
  