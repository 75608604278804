export class DragInfo{
    public SourceObjectType : String = '';
    public SourceObjectRowIndex : number = -1;
    public SourceObjectColumnIndex : number = -1;
    public SourceObjectString : string = '';
    public SourceObject? : any;
}


export class DesignSurfaceDragInfo{
    public DragItemType : 'control' | 'property' | 'relation' | 'unkown' = 'unkown';
    public ItemId : string = '';
    public ItemName : string = '';
    public SourceObjectString : string = '';
    public SourceObject? : any;
}