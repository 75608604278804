import React from "react";
import { DesignTimeModelHelper } from "../../../../Helpers/DesignTimeModelHelper";
import { EntityCustomPrivilegeDefinition } from "../../../Entities/EntityCustomPrivilegeDefinition";
import { EntityPrivilegeDefinition } from "../../../Entities/EntityPrivilegeDefinition";
import { EntityPrivilegePatch } from "../../../Entities/EntityPrivilegePatch";

export interface TNDesignerRolePrivilegeItemEditProps {
    roleid : string;
    rolename: string;
    entityName : string;
    entityId : string;
    applicationid : string;
    tenantUniqueName : string;
    priv? : EntityPrivilegeDefinition;
    customPriv? : EntityCustomPrivilegeDefinition;
    privtype : 'default' | 'custom';
    itemIndex : number;
    isreadonly : boolean;
    issystemadmin : boolean;
}

export interface TNDesignerRolePrivilegeItemState  {
    errorMessage : string;
}


export class TNRolePrivilegeItemEditControl extends React.Component<TNDesignerRolePrivilegeItemEditProps, TNDesignerRolePrivilegeItemState> {
    
    constructor(props : TNDesignerRolePrivilegeItemEditProps){
        super(props);
        
      
        this.state = {
            errorMessage : ''
        }

    }
    public componentDidMount(){
        //var that = this;
    }

    private getPrivLevelName = (val : number) : string => {
        if (val === -10)
            return 'No Access';
        else if (val === 0)
            return 'Undefined';
        else if (val === 10)
            return 'Own';
        else if (val === 20)
            return 'Team';
        else if (val === 30)
            return 'Team & Descendants';
        else if (val === 40)
            return 'Organization';
        else if (val === 50)
            return 'Tenant';
        else if (val === 100)
            return 'ALL records';
        return "Invalid Value";
    }
    private getNextPrivilegeValue = (val : number) : number =>{
        if (val === -10){
            return 0;
        }
        else if (val === 0){
            return 10;
        }
        else if (val === 10){
            return 20;
        }
        else if (val === 20){
            return 30;
        }
        else if (val === 30){
            return 40;
        }
        else if (val === 40){
            return 50;
        }
        else if (val === 50){
            if (this.props.issystemadmin){
                return 100;
            }
            else{
                return -10;
            }
        }
        else if (val === 100){
            return -10;
        }
        else{
            return 0;
        }
    }
    private getPreviousPrivilegeValue = (val : number) : number =>{
        if (val === -10){
            if (this.props.issystemadmin){
                return 100;
            }
            else{
                return 50;
            }
        }
        else if (val === 0){
            return -10;
        }
        else if (val === 10){
            return 0;
        }
        else if (val === 20){
            return 10;
        }
        else if (val === 30){
            return 20;
        }
        else if (val === 40){
            return 30;
        }
        else if (val === 50){
            return 40;
        }
        else if (val === 100){
            return 50;
        }
        else{
            return 0;
        }
    }

    public render(){
        //var that = this;
        //var idStr : string = 'tnOwerEditRow';

        return (
            <>
            {this.props.privtype === 'default' && this.props.priv &&
                <span onClick={this.onSpanClicked} onContextMenu={this.onSpanClicked}>
                    {/* {this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))} */}
                    <span dangerouslySetInnerHTML={{__html: this.renderCellValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex))}}></span>
                </span>
            }
            {this.props.privtype === 'custom' && this.props.customPriv &&
                <span onClick={this.onSpanClicked} onContextMenu={this.onSpanClicked}>
                    {/* {this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))} */}
                    <span dangerouslySetInnerHTML={{__html: this.renderCellValue(this.props.customPriv?.Level)}}></span>
                </span>
            }
            </>
        );
    }

    private onSpanClicked = (e : any) : void =>{
        let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
        if (!this.props.isreadonly){
            if (e.type === 'click') {
                e.preventDefault();
                if (this.props.priv && this.props.privtype === 'default'){
                    var newVal : number = this.getNextPrivilegeValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex));
                    this.props.priv.setPrivilegeIndex(this.props.itemIndex, newVal);

                    let patch: EntityPrivilegePatch = new EntityPrivilegePatch();
                    patch.ApplicationId = this.props.applicationid;
                    patch.EntityId = this.props.entityId;
                    patch.EntityName = this.props.entityName;
                    patch.Level = newVal;
                    patch.EventName = this.props.priv.getPrivilegeName(this.props.itemIndex);
                    patch.RoleId = this.props.roleid;

                    //mh.updateRoleDefinitionSecurity(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, this.props.priv!);
                    mh.updateRoleDefinitionSecurityWithPatch(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, patch);
                    //PrivilegeHelper.setRolePrivilegeLevel(this.props.priv, this.props.itemIndex,this.props.priv.getPrivilegeName(this.props.itemIndex),this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                else if (this.props.customPriv && this.props.privtype === 'custom'){
                    this.props.customPriv.Level = this.getNextPrivilegeValue(this.props.customPriv?.Level);

                    let patch: EntityPrivilegePatch = new EntityPrivilegePatch();
                    patch.ApplicationId = this.props.applicationid;
                    patch.EntityId = this.props.entityId;
                    patch.EntityName = this.props.entityName;
                    patch.Level = this.props.customPriv.Level;
                    patch.EventName = this.props.customPriv.EventName;
                    patch.RoleId = this.props.roleid;

                    //mh.updateRoleDefinitionSecurityWithCustomPrivilege(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, this.props.customPriv!);
                    mh.updateRoleDefinitionSecurityWithPatch(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, patch);
                   // PrivilegeHelper.setRoleCustomPrivilegeLevel(this.props.customPriv, this.props.customPriv.EventName,this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                this.forceUpdate();
              } else if (e.type === 'contextmenu') {
                e.preventDefault();
                if (this.props.priv && this.props.privtype === 'default'){
                    var newVal2 : number = this.getPreviousPrivilegeValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex));
                    this.props.priv.setPrivilegeIndex(this.props.itemIndex, newVal2);


                    let patch: EntityPrivilegePatch = new EntityPrivilegePatch();
                    patch.ApplicationId = this.props.applicationid;
                    patch.EntityId = this.props.entityId;
                    patch.EntityName = this.props.entityName;
                    patch.Level = newVal2;
                    patch.EventName = this.props.priv.getPrivilegeName(this.props.itemIndex);
                    patch.RoleId = this.props.roleid;

                    //mh.updateRoleDefinitionSecurity(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, this.props.priv!);
                    mh.updateRoleDefinitionSecurityWithPatch(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, patch);
                   //PrivilegeHelper.setRolePrivilegeLevel(this.props.priv, this.props.itemIndex,this.props.priv.getPrivilegeName(this.props.itemIndex),this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                else if (this.props.customPriv && this.props.privtype === 'custom'){
                    this.props.customPriv.Level = this.getPreviousPrivilegeValue(this.props.customPriv?.Level);

                    let patch: EntityPrivilegePatch = new EntityPrivilegePatch();
                    patch.ApplicationId = this.props.applicationid;
                    patch.EntityId = this.props.entityId;
                    patch.EntityName = this.props.entityName;
                    patch.Level = this.props.customPriv.Level;
                    patch.EventName = this.props.customPriv.EventName;
                    patch.RoleId = this.props.roleid;

                    //PrivilegeHelper.setRoleCustomPrivilegeLevel(this.props.customPriv, this.props.customPriv.EventName,this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                    //mh.updateRoleDefinitionSecurityWithCustomPrivilege(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, this.props.customPriv!);
                    mh.updateRoleDefinitionSecurityWithPatch(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, patch);
                }
                this.forceUpdate();
              }
            
        }
    }

    private getPrivLevelNameText = () : string =>{
        if (this.props.priv){
            return this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))
        }
        else if (this.props.customPriv){
            return this.props.customPriv.EventName;
        }
        else{
            return 'Unknown';
        }
    }
    private renderCellValue = (val: number) : string =>{
        if (val === -10){
            return '<span style="color:red !important; cursor: pointer"><i class="ms-Icon ms-Icon--SkypeCircleMinus" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 0){
            return '<i class="ms-Icon ms-Icon--CircleRing" style="cursor:pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 10){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--UserOptional" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 20){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--ReminderGroup" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 30){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Org" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 40){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Group" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 50){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Database" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 100){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Admin" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else{
            return "??";
        }
    }
}