import { IButtonProps, ICommandBarItemProps, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { DesignSurfaceDragInfo } from "../DragInfo";
import { DesignerHelper } from "./DesignerHelper";
import { SectionSurfaceControl } from "./SectionSurface";
import { SurfaceBase } from "./SurfaceBase";

interface TabItemSurfaceProps extends SurfaceBase{
    Tab : IllerisNinthUI.TNTab;
    tabIndex : number;
    Form: IllerisNinthUI.Formlet;
    renderKey: number;
}

interface TabItemSurfaceState extends TabItemSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  
}

//const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class TabItemSurfaceControl extends React.Component<TabItemSurfaceProps, TabItemSurfaceState> {
    constructor(props: TabItemSurfaceProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Form : props.Form,
        updateParent : props.updateParent,
        getEntity : props.getEntity,
        renderKey: props.renderKey,
        appInfo: props.appInfo
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : TabItemSurfaceProps, prevState: TabItemSurfaceState){
        if (prevProps.renderKey !== this.props.renderKey){
          return true;
        }
        else{
          return false;
        }
    }
    private removeTab = (evt : any) : void =>{
        var frm : IllerisNinthUI.Formlet = this.state.Form;
        var tabIdx :number = this.state.tabIndex;
        frm.Tabs.splice(tabIdx, 1);
        this.props.updateParent();
    }
    private addSection = (evt : any) : void =>{
        var tb : IllerisNinthUI.TNTab = this.state.Tab;
        var sect : IllerisNinthUI.TNSection = DesignerHelper.CreateNewSection('New Section');
        tb.Sections.push(sect);
        this.props.updateParent();
    }

    private buildCommandbar = () : void =>{
        let that = this;
        let items : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        items.push({
            key: 'removetab',
            text: 'Remove Tab',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Delete' },
            onClick: this.removeTab.bind(that)
          });
        items.push({
            key: 'addsection',
            text: 'Add Section',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: this.addSection.bind(that)
          });
        let farItems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();

        this.setState({cmdBarItems : items, cmdBarFarItems: farItems});
    }


    private onDragOver = (item?: any, event?: DragEvent) : void =>{
        if (item && item.dataTransfer){
            var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
            //var dataObj : DesignSurfaceDragInfo = (item.dataTransfer.getData('text/plain')) as DesignSurfaceDragInfo;
            if (dataObjStr){
                var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
                if (dataObj){
                    if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
                        item.dataTransfer.dropEffect = 'move';
                        return;
                    }
                }
                
            }
            item.dataTransfer.dropEffect = 'none';
        }
        //console.log('onDragOver');
    }
    private onDragDrop = (item?: any, event?: DragEvent) : void =>{
        if (item && item.dataTransfer){
            var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
            if (dataObjStr){
                var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
                if (dataObj){
                    if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
                        if (dataObj.ItemName === 'tabcontrol'){
                            let tc : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
                            var frm : IllerisNinthUI.Formlet = this.state.Form;
                            if (!frm.Tabs){
                                frm.Tabs = new Array<IllerisNinthUI.TNTab>();
                            }
                            frm.Tabs.push(tc);
                            this.forceUpdate();
                        }
                    }
                }
                
            }
            item.dataTransfer.dropEffect = 'none';
        }
    }
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            {/* <CommandBar
                items={this.state.cmdBarItems}
                overflowButtonProps={overflowProps}
                farItems={this.state.cmdBarFarItems}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            /> */}
            {this.state.Tab.Sections.map(function(item: IllerisNinthUI.TNSection, index :number){
                    return (<SectionSurfaceControl sectionIndex={index} 
                                                    Tab={that.state.Tab} 
                                                    Section={item} 
                                                    renderKey={that.props.renderKey}
                                                    updateParent={that.updateParent} 
                                                    getEntity={that.props.getEntity} 
                                                    showProperties={that.props.showProperties} 
                                                    appInfo={that.props.appInfo}
                                                    >

                                                    </SectionSurfaceControl>)
                })
            }
        </Stack>
        //    <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' onDragOver={this.onDragOver} onDrop={this.onDragDrop}>
        //    </Stack>
      );
    }
  }
  