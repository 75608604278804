import { ContextualMenuItemType, ICommandBarItemProps, IconButton, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { ColumnSurfaceControl } from "./ColumnSurface";
import { DesignerHelper } from "./DesignerHelper";
import { SurfaceBase } from "./SurfaceBase";

interface RowSurfaceProps extends SurfaceBase {
    Row : IllerisNinthUI.FormletRow;
    rowIndex : number;
    Section: IllerisNinthUI.TNSection;
    renderKey: number;    
}

interface RowSurfaceState extends RowSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
}

export class RowSurfaceControl extends React.Component<RowSurfaceProps, RowSurfaceState> {
    constructor(props: RowSurfaceProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Row : props.Row,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        rowIndex : props.rowIndex,
        Section : props.Section,
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        renderKey: props.renderKey,
        appInfo : props.appInfo
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : RowSurfaceProps, prevState: RowSurfaceState){
      if (prevProps.renderKey !== this.props.renderKey){
        return true;
      }
      else{
        return false;
      }
    }
    private removeRow = (evt : any) : void =>{
        var sect : IllerisNinthUI.TNSection = this.state.Section;
        var rowIndex :number = this.state.rowIndex;
        sect.Rows.splice(rowIndex, 1);
        this.props.updateParent();
    }
    private addRow = (evt : any) : void =>{
        var sect : IllerisNinthUI.TNSection = this.state.Section;
        var rowIdx :number = this.state.rowIndex;
        var newRow : IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
        sect.Rows.splice(rowIdx+1,0, newRow);
        this.props.updateParent();
    }
    private cloneRow = (evt : any) : void =>{
      var sect : IllerisNinthUI.TNSection = this.state.Section;
      var rowIdx :number = this.state.rowIndex;
      var row : IllerisNinthUI.FormletRow = this.state.Row;
      var newRow : IllerisNinthUI.FormletRow = DesignerHelper.cloneRow(row);
      sect.Rows.splice(rowIdx+1,0, newRow);
      this.props.updateParent();
    }
    private setColumns = (evt : any, item? : IContextualMenuItem) : void =>{
      //var rowIdx :number = this.state.rowIndex;
      var newCol : IllerisNinthUI.FormletColumn;
      var x : number = 0;
      var row : IllerisNinthUI.FormletRow = this.state.Row;
      var colCount : number = 12;
      if (item && item.data && item.data.toString().indexOf('-') !== -1){
        var colSizes : string[] = item.data.toString().split('-');
        for(x = 0; x<colSizes.length; x++){
          if (x <= row.Columns.length){
            row.Columns[x].Width = Number.parseInt(colSizes[x]);
          }
          else{
            newCol = DesignerHelper.CreateNewColumn(Number.parseInt(colSizes[x]));
            row.Columns.push(newCol);
          }
        }
      }
      else if (item){
        var colWidth : number = Number.parseInt(item.data.toString());
        colCount = 12 / colWidth;
        if (colCount > row.Columns.length){
          for(x= 0; x<row.Columns.length; x++){
            row.Columns[x].Width = colWidth;
          }
          for(x= row.Columns.length; x<colCount; x++){
            newCol = DesignerHelper.CreateNewColumn(colWidth);
            row.Columns.push(newCol);
          }
        }
        else{
          for(x= 0; x<row.Columns.length; x++){
            row.Columns[x].Width = colWidth;
          }
          row.Columns.splice(colCount, row.Columns.length - colCount);
        }
      }
      if (row && row.Columns && row.Columns.length > 0){
        row.Columns[row.Columns.length-1].Width -= 1;
      }
      this.setState({Row : row});
      this.forceUpdate();
      /*var sect : IllerisNinthUI.TNSection = this.state.Section;
      var rowIdx :number = this.state.rowIndex;
      var newRow : IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
      sect.Rows.splice(rowIdx+1,0, newRow);
      this.props.updateParent();*/
    }

    private buildCommandbar = () : void =>{
        let that = this;
        let items : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        
        let farItems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();

        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();
        cm.items.push({
          key: 'removerow',
          text : 'Remove This Row',
          iconProps : {iconName : 'Delete'},
          onClick: this.removeRow.bind(that)
        });
        cm.items.push({
          key: 'addrow',
          text: 'Add Row',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'Add'},
          onClick: this.addRow.bind(that)
        });

        cm.items.push({
            key: 'separator1',
            itemType: ContextualMenuItemType.Divider,
          });
        cm.items.push({
          key: 'clonecol',
          text: 'Clone',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'Copy'},
          onClick: this.cloneRow.bind(that),
        });
        cm.items.push({
          key: 'separator2',
          itemType: ContextualMenuItemType.Divider,
        });        
          //TripleColumnWide
        cm.items.push({
            key: 'set1col',
            text: 'Set 1 Column',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
            iconProps: { iconName: 'TripleColumnWide'},
            onClick: this.setColumns.bind(that),
            data: '12'
          });
        cm.items.push({
          key: 'set2col',
          text: 'Set 2 Column',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '6'
        });
        cm.items.push({
          key: 'set3col',
          text: 'Set 3 Column',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '4'
        });        
        cm.items.push({
          key: 'set4col',
          text: 'Set 4 Column',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '3'
        });
        cm.items.push({
          key: 'set6col',
          text: 'Set 6 Column',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '2'
        });
        cm.items.push({
          key: 'set12col',
          text: 'Set 12 Column',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '1'
        });
        cm.items.push({
          key: 'set3col1',
          text: 'Set 25% - 50% - 25%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '3-6-3'
        });
        cm.items.push({
          key: 'set4col1',
          text: 'Set 16% - 33% - 33% - 16%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '2-4-4-2'
        });        
        cm.items.push({
          key: 'set2col2',
          text: 'Set 75% - 25%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '9-3'
        });                                                        
        cm.items.push({
          key: 'set2col3',
          text: 'Set 25% - 75%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '3-9'
        });                                                                
        cm.items.push({
          key: 'set2col4',
          text: 'Set 66% - 33%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '8-4'
        });                                                        
        cm.items.push({
          key: 'set2col5',
          text: 'Set 66% - 33%',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'TripleColumnWide'},
          onClick: this.setColumns.bind(that),
          data: '4-8'
        });                                                                

        this.setState({cmdBarItems : items, cmdBarFarItems: farItems, ctxMenuItems : cm});
    }


    // private onDragOver = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         //var dataObj : DesignSurfaceDragInfo = (item.dataTransfer.getData('text/plain')) as DesignSurfaceDragInfo;
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     item.dataTransfer.dropEffect = 'move';
    //                     return;
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    //     //console.log('onDragOver');
    // }
    // private onDragDrop = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     if (dataObj.ItemName === 'tabcontrol'){
    //                         let tc : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
    //                         var frm : IllerisNinthUI.Formlet = this.state.Form;
    //                         if (!frm.Tabs){
    //                             frm.Tabs = new Array<IllerisNinthUI.TNTab>();
    //                         }
    //                         frm.Tabs.push(tc);
    //                         this.forceUpdate();
    //                     }
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    // }
    private updateParent = () : void =>{
      this.forceUpdate();
    }
    
    render() {
      var that = this;
      return (
        <>
        <div className='ms-Grid-row tn-designer-rowsurface-row' >
          {this.state.Row.Columns.map(function(item: IllerisNinthUI.FormletColumn, index : number){
            return (<ColumnSurfaceControl Row={that.state.Row} 
                                          key={nanoid()}
                                          Column={item} 
                                          colIdx={index} 
                                          renderKey={that.state.renderKey}
                                          updateParent={that.updateParent} 
                                          rowIndex={that.props.rowIndex} 
                                          getEntity={that.props.getEntity} 
                                          showProperties={that.props.showProperties}
                                          appInfo={that.props.appInfo}
                                          >
                                          </ColumnSurfaceControl>)
          })
          
          }
          <div className='ms-Grid-col col-ms-sm1' style={{textAlign: 'end', alignContent: 'end'}}>
            {/* <div style={{float: 'right'}}> */}
            <div style={{position: 'absolute', right: '-50px'}}>
                <IconButton
                    menuProps={that.state.ctxMenuItems}
                    iconProps={{iconName : 'Info'}}
                    title="Info"
                    ariaLabel="Info"
                    disabled={false}
                    checked={false}
                    data-itemidx={that.props.rowIndex}
                    data={that.props.rowIndex}
                    />    
            </div>
          </div>
          
        </div>
        
        </>
      );
    }
  }
  