import { EntityHelper } from "./EntityHelper";

export class RegExHelper{

    public static extractPatterns = (txt : string) : Array<string> =>{
        var res : Array<string> = new Array<string>();
        var matches : RegExpMatchArray | null  = txt.match('{[a-zA-Z0-1]*}');
        if (matches){
            for (var x = 0; x<matches.length; x++){
                var m = matches[x];
                res.push(m.replace('{','').replace('}',''));
            }
        }
        return res;
    }

    public static replacePatterns = (txt : string, obj : any) : string =>{
        var props : string[] = RegExHelper.extractPatterns(txt);
        var restxt : string = txt;
        for(var i = 0; i<props.length; i++){
            var val : any | null = EntityHelper.resolvePropertyValue(props[i], obj);
            if (val){
                restxt = restxt.replace('{' + props[i] + '}', val ?? '');
            }
        }
        return restxt;
    }
}

export class NumberHelper{
    static converToNumber = (str : any | undefined | null, defVal : number) : number =>{
        if (str){
            return Number.parseInt(str!.toString());
        }
        else{
            return defVal;
        }
    }
}