import { SilentRequest } from "@azure/msal-browser";
import { ComboBox, DatePicker, IComboBox, IComboBoxOption, IconButton, Label, ProgressIndicator, SpinButton, Stack, TextField, TooltipHost} from "@fluentui/react";
import Notiflix from "notiflix";


import React from "react";
import { DynamicEntity, DynamicEntityRelation } from "../DynamicEntity";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { Logger } from "../Logger";
import { MSALHelper } from "../Helpers/MSALHelper";
import { PageInfo } from "../PageInfo";
import dayjs from "dayjs";
import { ProductElement, RecurringPrice, ShopProductsInfo, Volume } from "./ProductPricing/ShopProductsInfo";



export interface TNShopLinesControlProps {
    itemId? : string;
    mainEntityLogicalName : string;
    mainEntityId : string;
    childEntityLogicalName : string;
    foreignKeyName : string;
    /// uri with {id} parameter
    baseServiceURI : string;
    isReadOnly: boolean;
    allowDecimalQuantities : boolean;
    totalNetPropertyId : string;
    totalVATPropertyId : string;
    totalDiscountPropertyId : string;
    totalTOTPropertyId : string;
    relationName : string;
    getEntity : () => DynamicEntity | null | undefined;
    enableDiscountEdit : boolean;
    enableUnitPriceEdit : boolean;
    isreadonly:boolean;
    PageInfo: PageInfo;
    orderDate?: Date;
}

export interface TNShopLinesControlState  {
    items : Array<any>;
    products : Array<IComboBoxOption>;

    productsInfo: ShopProductsInfo | null;
    allProducts : Array<IComboBoxOption>;
    isLoading: boolean;
}
  
  export class TNShopLinesControlView extends React.Component<TNShopLinesControlProps, TNShopLinesControlState> {
      private mProductList : Array<any> = new Array<any>();
    constructor(props : TNShopLinesControlProps){
        super(props);

        var its : Array<any> | undefined  = props.getEntity()?.getRelation(props.relationName)?.Children;
        this.state ={
            //items : new Array<any>(),
            items : its?? new Array<any>(),
            products : new Array<any>(),
            productsInfo: null,
            allProducts: new Array<IComboBoxOption>(),
            isLoading: false
        }
    }

    private loadProductInfo = () : void => {
        let that = this;
        let uri : string = `/api/v2.0/terranova/xservice/${this.props.PageInfo.TenantId}/${this.props.PageInfo.AppName}/Shop/GetProductsForShop`;
        var reqObj : any = {
            ShopInstance: this.props.PageInfo.TenantId,
            SearchQuery: '',
            RequestDate: dayjs(this.props.orderDate ?? Date.now()).toISOString()
        }
        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        //debugger;
        that.setState({isLoading: true});
        //that.forceUpdate();
        mh.execApiCallPost(cr.scopes, uri, JSON.stringify(reqObj)).then(function(res : any){
            if (res && res.Value){
                debugger;

                var ppi: ShopProductsInfo = res.Value as ShopProductsInfo;

                let prds : IComboBoxOption[] = new Array<IComboBoxOption>();
                if (ppi && ppi.Products){
                    ppi.Products.forEach(function(pit: ProductElement, idx :number){
                        if (pit.PricingScheme){
                            prds.push({key: pit.Product.Id, text: pit.Product.DisplayName});
                        }
                    })
                }

                that.setState({productsInfo : ppi, allProducts: prds, isLoading: false});

                // res.Value.Products.
                /*sessionStorage.setItem(that.props.mainEntityId + '_products', JSON.stringify(pds));
                that.mProductList = res.Values;
                that.setState({products : pds});
                that.forceUpdate();*/
            }
        }).catch(function(error: any){
            that.setState({isLoading: false});
            //Logger.logError('Failed to load data : ' + error.message);
            IllerisNinthUI.NotificationManager.showError('Failed to load data', error.message);
        });
    }
    
    componentDidMount(){
        debugger;
        Logger.logNotification('ShopLinesControlProps::componentDidMount');
        var prodList : Array<any> | null | undefined = null;
        var itemList : Array<any> | null | undefined = null;

        var ent : DynamicEntity |null | undefined = (this.props? this.props.getEntity() : null);
        if (ent){
            if (ent.hasRelation(this.props.relationName)){
                itemList = ent.getRelation(this.props.relationName)?.Children;
            }
        }

        //if (!this.state.products || this.state.products.length === 0){
        //var prds = sessionStorage.getItem(this.props.mainEntityId + '_products');
        //var prds: Array<any> = new Array<any> ();
        
        // if (!prds || prds.length === 0){
        //     this.loadProducts(null);
        // }
        // else{
        //     prodList = JSON.parse(prds);
        // }
        //var lines = sessionStorage.getItem(this.props.mainEntityId + '_lines');
        //if (!lines || lines.length === 0){
        if (!itemList || itemList.length === 0){
            this.getItemLines();
        }

        if (prodList && itemList){
            this.setState({ items : itemList, products: prodList});
            this.forceUpdate();
        }

        this.loadProductInfo();
    }

    shouldComponentUpdate(nextProps: TNShopLinesControlProps, nextState: TNShopLinesControlState) {
        Logger.logNotification('TNShopLinesControlView::shouldComponentUpdate');
        if (nextProps.mainEntityId !== this.props.mainEntityId || this.props.mainEntityLogicalName !== nextProps.mainEntityLogicalName){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNShopLinesControlProps, prevState: TNShopLinesControlState){
        Logger.logNotification('TNShopLinesControlView::componentDidUpdate');
        if (prevProps.mainEntityId !== this.props.mainEntityId || this.props.mainEntityLogicalName !== prevProps.mainEntityLogicalName){
            this.getItemLines();
        }
    }

    componentWillUnmount(){

    }


    // private loadProducts = (searchVal : string | null) : void =>{
    //     var that = this;
    //     var uri : string = '';
    //     if (searchVal){
    //         uri = '/api/v2.0/terranova/xdata/core/shop/product?$filter=DisplayName.contains("' + searchVal + '") AND ProductType != 4&$expand=ProductBookingGroup';
    //     }
    //     else{
    //         uri = '/api/v2.0/terranova/xdata/core/shop/product?$filter=ProductType != 4&$expand=ProductBookingGroup';
    //     }

    //     var cr : SilentRequest = MSALHelper.getDefaultScope();
    //     var mh : MSALHelper = new MSALHelper();
    //     //debugger;
    //     mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
    //         if (res && res.Values && Array.isArray(res.Values)){
    //             var pds : Array<IComboBoxOption> = new Array<IComboBoxOption>();
    //             for(var t = 0; t<res.Values.length; t++){
    //                 pds.push({key : res.Values[t].Id, text: res.Values[t].DisplayName});
    //             }
    //             sessionStorage.setItem(that.props.mainEntityId + '_products', JSON.stringify(pds));
    //             that.mProductList = res.Values;
    //             that.setState({products : pds});
    //             that.forceUpdate();
    //         }
    //     }).catch(function(error: any){
    //         //Logger.logError('Failed to load data : ' + error.message);
    //         IllerisNinthUI.NotificationManager.showError('Failed to load data', error.message);
    //     });

    // }

    private getItemAtRow = (rowIdx : number) : any =>{
        if (this.state.items && this.state.items.length > rowIdx){
            return this.state.items[rowIdx];
        }
        return null;
    }

    private recalcSummary = () : void =>{
        debugger;
        var items : Array<any> = this.state.items as Array<any>;
        if (items){
            var totNet : number = 0.0;
            var totDisc : number = 0.0;
            var totVat : number = 0.0;
            var totTot : number = 0.0;
            for(var i = 0; i<items.length; i++){
                totNet += items[i].Quantity * items[i].UnitPrice;
                totDisc += items[i].DiscountAmount;
                totVat += items[i].AmountVAT;
                totTot += items[i].AmountTOT;
                if (!items[i].DiscountPct){
                    items[i].DiscountPct = 0.0;
                }
            }

            this.props.getEntity()?.setValue(this.props.totalNetPropertyId,parseFloat(totNet.toFixed(2)));
            this.props.getEntity()?.setValue(this.props.totalDiscountPropertyId,parseFloat(totDisc.toFixed(2)));
            this.props.getEntity()?.setValue(this.props.totalVATPropertyId,parseFloat(totVat.toFixed(2)));
            this.props.getEntity()?.setValue(this.props.totalTOTPropertyId,parseFloat(totTot.toFixed(2)));

        }
        //window.sessionStorage.setItem(this.props.mainEntityId + '_lines', JSON.stringify(items));
    }

    private getAllProducts = () : Array<any> =>{
        return this.mProductList;
    }

    render() {
        var that = this;

        return (
            <Stack verticalFill horizontalAlign='stretch'>
                {that.state.isLoading &&
                    <ProgressIndicator label="Loading..." description="" />
                }
                    <div className="ms-Grid" dir="ltr">

                    <div className="ms-Grid-row shopLineHeaderRow">
                        <div className="ms-Grid-col ms-lg1 ms-hiddenLgDown">#</div>
                        <div className="ms-Grid-col ms-md3">Product
                        </div>
                        <div className="ms-Grid-col ms-sm2 ">Quantity
                        </div>
                        <div className="ms-Grid-col ms-sm2 ">Unit Price
                        </div>
                        <div className="ms-Grid-col ms-sm2">Discount
                        </div>
                        <div className="ms-Grid-col ms-sm2">Tot.</div>
                    </div>



                        {that.state.items.filter(z => z.IsDeleted !== true).map((item, itemidx) => {
                            return (
                                <TNShopLineControlView 
                                    key={itemidx} 
                                    isreadonly={this.props.isReadOnly}
                                    index={itemidx} 
                                    getItemAtRow={that.getItemAtRow} 
                                    isReadOnly={that.props.isReadOnly} 
                                    allowDecimalSteps={that.props.allowDecimalQuantities} 
                                    //products={that.state.products} 
                                    products={that.state.allProducts} 
                                    allProducts={that.getAllProducts}
                                    addNewLine={that.onAddNewLine}
                                    removeLineAt={that.removeLineAt}
                                    recalcSummaries={that.recalcSummary}
                                    enableDiscountEdit={that.props.enableDiscountEdit}
                                    enableUnitPriceEdit={that.props.enableUnitPriceEdit}
                                    ShopProductsInfo={that.state.productsInfo}
                                    >

                                </TNShopLineControlView>
                                );
                            })
                        }

                    <div className="ms-Grid-row shopLineFooterRow">
                        <div className="ms-Grid-col ms-lg1 ms-hiddenLgDown"></div>
                        <div className="ms-Grid-col ms-md3">
                        </div>
                        <div className="ms-Grid-col ms-sm2 ">
                        </div>
                        <div className="ms-Grid-col ms-sm2 ">
                        </div>
                        <div className="ms-Grid-col ms-sm2">
                        </div>
                        <div className="ms-Grid-col ms-sm2"></div>
                    </div>

                        <div className='ms-Grid-row'>
                            <div className="ms-Grid-col ms-sm6">
                            <TooltipHost
                                content="Add a new row"
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'Add' }} title="Add Row" ariaLabel="Add Row" onClick={that.onAddNewLine} disabled={this.props.isReadOnly} />
                            </TooltipHost>


                            </div>
                            <div className="ms-Grid-col ms-sm6"></div>
                        </div>
                    </div>
            </Stack>
        );
      }

    private onAddNewLine = () : void =>{
        var newItem : any = {};
        newItem[this.props.foreignKeyName] = this.props.getEntity()?.getId();
        newItem.Quantity = 0;
        newItem.ProductId = '';
        newItem.ProductName = '';
        newItem.ProductCode = '';
        newItem.ProductKey = '';
        newItem.StartDate = null;
        newItem.EndDate = null;
        newItem.Duration = '';
        newItem.UnitPrice = 0.0;
        newItem.AmountNET = 0.0;
        newItem.AmountVAT = 0.0;
        newItem.AmountTOT = 0.0;
        newItem.DiscountPct = 0.0;
        newItem.DiscountAmount = 0.0;
        newItem.Description = '';
        
        var items = this.state.items;
        items.push(newItem);
        //sessionStorage.setItem(this.props.mainEntityId + '_lines', JSON.stringify(items));
        this.setState({items : items});
        this.forceUpdate();

    }
      
    private getItemLines = () : void => {

        var that = this;
        var uri = that.props.baseServiceURI.replace('{Id}', that.props.mainEntityId).replace('{id}', that.props.mainEntityId);

        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        //debugger;
        that.setState({isLoading: true});
        mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
            if (res && res.Values && Array.isArray(res.Values)){

                res.Values.forEach(function(it: any, idx: number){
                    if (it.StartDate){
                        it.StartDate = new Date(it.StartDate);
                    }
                    if (it.EndDate){
                        it.EndDate = new Date(it.EndDate);
                    }
                })

                //sessionStorage.setItem(that.props.mainEntityId + '_lines', JSON.stringify(res.Values));
                var ent : DynamicEntity |null | undefined = (that.props? that.props.getEntity() : null);
                if (ent){
                    if (ent.hasRelation(that.props.relationName)){
                        ent.getRelation(that.props.relationName)!.Children = res.Values;
                    }
                    else{
                        var rel : DynamicEntityRelation | null | undefined = ent.addRelation(that.props.relationName, that.props.foreignKeyName, res.Values);
                        if (rel){
                            rel.TargetEntity = that.props.childEntityLogicalName;
                            rel.QueryURI = uri;
                            rel.IsLoaded = true;
                        }
                    }
                }

                that.setState({items : res.Values, isLoading: false});
                that.forceUpdate();
            }
        }).catch(function(error: any){
            that.setState({ isLoading: false});
            //Logger.logError('Failed to load data : ' + error.message);
            IllerisNinthUI.NotificationManager.showError('Failed to load data', error.message);
        });

    }

    private removeLineAt = (pos:number) : void =>{
        var that = this;
        Notiflix.Confirm.show('Delete', 
                    'Are you sure you want to remove this item?', 
                    'Yes', 
                    'No', 
                    function(){ // Yes button callback 
                        var items = that.state.items;
                        //items.splice(pos, 1);
                        items[pos].IsDeleted = true;
                        //sessionStorage.setItem(that.props.mainEntityId + '_lines', JSON.stringify(items));
                        that.setState({items : items});
                        that.forceUpdate();
                    }, 
                    function(){ // No button callback 
                        
                    } ); 

        
    }

  }



export interface TNShopLineControlProps {
    itemId? : string;
    index: number;
    isReadOnly : boolean;
    getItemAtRow : (idx : number) => any;
    products : IComboBoxOption[];
    allProducts : () => Array<any>;
    allowDecimalSteps : boolean;
    addNewLine : () => void;
    removeLineAt : (pos : number) => void;
    recalcSummaries : () => void;
    enableDiscountEdit : boolean;
    enableUnitPriceEdit : boolean;
    isreadonly: boolean;
    ShopProductsInfo: ShopProductsInfo | null;
}

export interface TNShopLineControlState  {
    isDurationProduct: boolean;
    periodItems: IComboBoxOption[];
}
  
  export class TNShopLineControlView extends React.Component<TNShopLineControlProps, TNShopLineControlState> {
    private mProduct : any | null = null;
    constructor(props : TNShopLineControlProps){
        super(props);
        this.state ={
            isDurationProduct: false,
            periodItems: new Array<IComboBoxOption>()
        }
    }
    
    componentDidMount(){
        this.refreshContents();
    }

    shouldComponentUpdate(nextProps: TNShopLineControlProps, nextState: TNShopLineControlState) {
        Logger.logNotification('TNShopLineControlView::shouldComponentUpdate');
        if (nextProps.index !== this.props.index || nextProps.products !== this.props.products){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNShopLineControlProps, prevState: TNShopLineControlState){
        Logger.logNotification('TNShopLinesControlView::componentDidUpdate');
        if (prevProps.index !== this.props.index || prevProps.products !== this.props.products ){
            this.refreshContents();
        }
    }

    private refreshContents = () : void => {
        let that = this;
        var item : any = that.props.getItemAtRow(that.props.index);
        if (item){
            this.setProductPeriods(item.ProductId);
        }
    }

    componentWillUnmount(){
    }

    // private recalcRowTotal = (row: any) : any => {
    //     row.AmountNET = row.UnitPrice ?? 0 * row.Quantity;
    //     if (row.DiscountPct){
    //         row.DiscountAmount = Math.round((10000  * row.DiscountPct * row.AmountNet / 100.0) / 10000.0);
    //     }
    //     if (row.DiscountAmount){
    //         row.AmountNET = row.AmountNET - row.DiscountAmount;
    //     }
        
    //     let proItem = this.props.ShopProductsInfo?.Products.find(z => z.Product.Id === row.ProductId);
    //     if (proItem){
    //         row.AmountVAT = Math.round(proItem.Product.ProductBookingGroup?.DefaultVATPct * row.AmountNET * 10000 / 100) / 10000.0;
    //     }
    //     row.AmountTOT = (row.AmountNET ?? 0) + (row.AmountVAT ?? 0);

    // }

    private recalcRowPricing = () : void => {
        let that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        let proItem = this.props.ShopProductsInfo?.Products.find(z => z.Product.Id === row.ProductId);
        if (proItem){
            if (proItem.PricingScheme.SchemeType === 0){ // subs
                let rpr: RecurringPrice | undefined = proItem.PricingScheme.RecurringPrices.find(z => z.UniqueCode === row.Duration);
                if (rpr){
                    if (proItem.PricingScheme.PriceModel === 0){ // Vol
                        var vp: Volume | undefined = rpr.Volumes?.find(z => z.MinVolume <= row.Quantity && (z.MaxVolume > row.Quantity || z.MaxVolume === null || z.MaxVolume === undefined));
                        if (vp){
                            row.UnitPrice = vp.Amount + rpr.FixedPricePart;
                            row.DiscountPct = vp.DiscountPercentage;
                            that.recalcRow(row);
                        }
                    }
                    else if (proItem.PricingScheme.PriceModel === 0){ // Tiered
                        let price: number = 0.0;
                        let disc: number = 0.0;
                        let remQuant : number = row.Quantity;
                        let idx : number = 0;
                        while(remQuant > 0){
                            if (rpr.Volumes.length > idx){
                                if (rpr.Volumes[idx].MaxVolume && remQuant > rpr.Volumes[idx].MaxVolume){
                                    let sectPrice: number = rpr.Volumes[idx].MaxVolume * rpr.Volumes[idx].Amount;
                                    disc += (sectPrice * rpr.Volumes[idx].DiscountPercentage / 100.0);
                                    remQuant -= rpr.Volumes[idx].MaxVolume;
                                    price += sectPrice;
                                }
                                else if (rpr.Volumes[idx].MaxVolume === null || rpr.Volumes[idx].MaxVolume === undefined){
                                    let sectPrice: number = remQuant * rpr.Volumes[idx].Amount;
                                    disc += (sectPrice * rpr.Volumes[idx].DiscountPercentage / 100.0);
                                    price += sectPrice;
                                    remQuant -= remQuant;
                                }
                                else if (rpr.Volumes[idx].MaxVolume && remQuant < rpr.Volumes[idx].MaxVolume){
                                    let sectPrice: number =remQuant * rpr.Volumes[idx].Amount;
                                    disc += (sectPrice * rpr.Volumes[idx].DiscountPercentage / 100.0);
                                    price += sectPrice;
                                    remQuant -= remQuant;
                                }
                                idx += 1;
                            }
                        }
                        row.UnitPrice = null;
                        row.AmountNET = price;
                        row.DiscountAmount = disc; 
                        that.recalcRow(row);
                    }
                }
            }
            else if (proItem.PricingScheme.SchemeType === 10){ // product
                if (proItem.PricingScheme.PriceModel === 0){ // Vol
                    var vp: Volume | undefined = proItem.PricingScheme.VolumePrices?.find(z => z.MinVolume <= row.Quantity && (z.MaxVolume > row.Quantity || z.MaxVolume === null || z.MaxVolume === undefined));
                    if (vp){
                        row.UnitPrice = vp.Amount;
                        row.DiscountPct = vp.DiscountPercentage;
                        that.recalcRow(row);
                    }
                }
                else{
                    let price: number = 0.0;
                    let disc: number = 0.0;
                    let remQuant : number = row.Quantity;
                    let idx : number = 0;
                    while(remQuant > 0){
                        if (proItem.PricingScheme.VolumePrices.length > idx){
                            if (proItem.PricingScheme.VolumePrices[idx].MaxVolume && remQuant > proItem.PricingScheme.VolumePrices[idx].MaxVolume){
                                let sectPrice: number = proItem.PricingScheme.VolumePrices[idx].MaxVolume * proItem.PricingScheme.VolumePrices[idx].Amount;
                                disc += (sectPrice * proItem.PricingScheme.VolumePrices[idx].DiscountPercentage / 100.0);
                                remQuant -= proItem.PricingScheme.VolumePrices[idx].MaxVolume;
                                price += sectPrice;
                            }
                            else if (proItem.PricingScheme.VolumePrices[idx].MaxVolume === null || proItem.PricingScheme.VolumePrices[idx].MaxVolume === undefined){
                                let sectPrice: number = remQuant * proItem.PricingScheme.VolumePrices[idx].Amount;
                                disc += (sectPrice * proItem.PricingScheme.VolumePrices[idx].DiscountPercentage / 100.0);
                                price += sectPrice;
                                remQuant -= remQuant;
                            }
                            else if (proItem.PricingScheme.VolumePrices[idx].MaxVolume && remQuant < proItem.PricingScheme.VolumePrices[idx].MaxVolume){
                                let sectPrice: number =remQuant * proItem.PricingScheme.VolumePrices[idx].Amount;
                                disc += (sectPrice * proItem.PricingScheme.VolumePrices[idx].DiscountPercentage / 100.0);
                                price += sectPrice;
                                remQuant -= remQuant;
                            }
                            idx += 1;
                        }
                    }
                    row.UnitPrice = null;
                    row.AmountNET = price;
                    row.DiscountAmount = disc; 
                    that.recalcRow(row);
                }
            }
        }
    }

    private onProductChanged =  (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        let proItem = this.props.ShopProductsInfo?.Products.find(z => z.Product.Id === option?.key.toString());
        if (row && proItem){

            row.ProductId = option?.key;
            //row.ProductName = option?.text;
            row.ProductName = proItem.Product.Name;
            //row.ProductCode = option?.text;
            row.ProductCode = proItem.Product.Code;
            row.VATPct = proItem.Product.ProductBookingGroup.DefaultVATPct;

            // if (option && option.key && this.props.allProducts){
            //     that.mProduct = that.props.allProducts().find( z => z.Id === option?.key.toString());
            //     if (that.mProduct){
            //         if (that.mProduct.ProductType === 1){
            //             row.UnitPrice = that.mProduct.UnitPrice;
            //         }
            //         else {
            //             row.UnitPrice = that.mProduct.ItemPrice;
            //         }

            //         if (that.mProduct && that.mProduct.ProductBookingGroup){
            //             row.VATPct = parseFloat(that.mProduct.ProductBookingGroup.DefaultVATPct);
            //         }
            //         else {
            //             row.VATPct = 21.0;
            //         }
            //     }
            // }
            // that.recalcRow(row);
            that.recalcRowPricing();

            that.forceUpdate();
        }
        that.setProductPeriods(option?.key.toString());
        
    }

    private setProductPeriods = (prodId: string | undefined | null) : void => {
        let that =this;
        var row : any = that.props.getItemAtRow(that.props.index);
        var prod: ProductElement | undefined = this.props.ShopProductsInfo?.Products.find( z=> z.Product?.Id === prodId);
        if (prod){
            if (prod.PricingScheme && prod.PricingScheme.RecurringPrices){
                let perOpts : IComboBoxOption[] = new Array<IComboBoxOption>();
                prod.PricingScheme.RecurringPrices.forEach(function(it: RecurringPrice, idx : number){
                    perOpts.push({key: it.UniqueCode, text: it.Name});
                })
                if (prod.PricingScheme.SchemeType === 0){
                    that.setState({periodItems: perOpts, isDurationProduct: true});
                    if (perOpts.length > 0 && !row.Duration){
                        row.Duration = perOpts[0].key?.toString() ?? '';
                        if (!row.StartDate){
                            row.StartDate = new Date();
                        }
                        row.EndDate = this.calcEndDate(row.StartDate, row.Duration);
                    }
                    that.forceUpdate();
                }
                else{
                    that.setState({ isDurationProduct: false});
                }
            }
            else{
                that.setState({ isDurationProduct: false});
            }
        }
        else{
            that.setState({ isDurationProduct: false});
        }
    }

    private recalcRow(row : any){
        if (row.Quantity && row.UnitPrice){
            row.AmountNET = parseFloat(row.Quantity) * parseFloat(row.UnitPrice);
        }
        if (row.DiscountPct){
            row.DiscountAmount = Math.round((parseFloat(row.DiscountPct) * row.AmountNET / 100.0) * 10000) /10000.0;
            row.AmountNET = row.AmountNET -row.DiscountAmount
        }
        else if (row.DiscountAmount){
            row.AmountNET = row.AmountNET - row.DiscountAmount
            row.DiscountPct = 0.0;
        }
        if (row.VATPct){
            row.AmountVAT = Math.round((parseFloat(row.VATPct) * row.AmountNET / 100.0) * 10000) / 10000.0;
        }

        if (row.AmountNET && row.AmountVAT){
            row.AmountTOT = parseFloat(row.AmountNET) + parseFloat(row.AmountVAT);
        }
        else if (row.AmountNET){
            row.AmountTOT = parseFloat(row.AmountNET);
        }

        this.props.recalcSummaries();
        this.forceUpdate();
    }

    private onQuantityChanged = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined) : void =>{
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        if (row){
            row.Quantity = parseFloat(newValue ?? '0');

            //this.recalcRow(row);
            that.recalcRowPricing();


            that.forceUpdate();
        }
    }
    private onUnitPriceChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        debugger;
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        if (row){
            row.UnitPrice = parseFloat(newValue ?? '0');
            this.recalcRow(row);
            that.forceUpdate();
        }
    }
    private onDiscountAmountChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        debugger;
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        if (row){
            row.DiscountAmount = parseFloat(newValue ?? '0');
            row.DiscountPct = 0.0;
            this.recalcRow(row);
            that.forceUpdate();
        }
    }
    private onDiscountPctChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        if (row){
            row.DiscountPct = parseFloat(newValue ?? '0');
            if (isNaN(row.DiscountPct)){
                row.DiscountPct = 0.0;
            }
            else if (row.DiscountPct > 100){
                row.DiscountPct = 100;
            }
            row.DiscountAmount = 0.0;
            this.recalcRow(row);
            that.forceUpdate();
        }
    }
    private onProductCodeChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        var that = this;
        var row : any = that.props.getItemAtRow(that.props.index);
        if (row){
            row.ProductCode = newValue ?? '';
            row.Description = row.ProductCode;
            
            that.forceUpdate();
        }
    }

    private onRemoveRowClicked = () : void =>{
        this.props.removeLineAt(this.props.index);
    }

    render() {
        var that = this;

        var item : any = that.props.getItemAtRow(that.props.index);

        return (
            <>
            <div className="ms-Grid-row showLineRowSpacing">
                <div className="ms-Grid-col ms-lg1 ms-hiddenLgDown">{that.props.index + 1}</div>
                <div className="ms-Grid-col ms-md3">
                    <ComboBox
                        label=""
                        selectedKey={item.ProductId}
                        allowFreeform={false}
                        autoComplete={'on'}
                        options={that.props.products}
                        placeholder='Product'
                        aria-label='Product'
                        disabled={that.props.isReadOnly}
                        styles={{ container : { minWidth: '150px'}}}
                        onChange={this.onProductChanged}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2 ">
                    <TooltipHost
                                content="Quantity"
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                    <SpinButton
                        value={item.Quantity}
                        placeholder='Quantity'
                        aria-label='Quantity'
                        min={0}
                        step={that.props.allowDecimalSteps ? 0.01 : 1}
                        incrementButtonAriaLabel={'Increase value by 1'}
                        decrementButtonAriaLabel={'Decrease value by 1'}
                        disabled={that.props.isReadOnly}
                        className='shopLineTextFieldQuantityEdit'
                        onChange={this.onQuantityChanged}
                    />
                    </TooltipHost>

                </div>
                <div className="ms-Grid-col ms-sm2 ">
                    <TooltipHost
                                content="Unit Price"
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                    {that.props.enableUnitPriceEdit &&
                    <TextField 
                        placeholder='UP'
                        value={item.UnitPrice}
                        disabled={that.props.isReadOnly || item.UnitPrice === null}
                        suffix='EUR'
                        className='shopLineTextFieldAmountEdit'
                        onChange={this.onUnitPriceChanged}
                    />
                    }
                    {!that.props.enableUnitPriceEdit &&
                    <Label>{item.UnitPrice?.toFixed(2) + ' EUR'}</Label>
                    }
                    </TooltipHost>
                </div>
                <div className="ms-Grid-col ms-sm2">
                    <TooltipHost
                                content="Discount"
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                    {that.props.enableDiscountEdit &&
                    <TextField 
                        placeholder='Discount'
                        value={item.DiscountAmount}
                        disabled={that.props.isReadOnly}
                        suffix='EUR'
                        className='shopLineTextFieldAmountEdit'
                        onChange={this.onDiscountAmountChanged}
                    />
                    }
                    </TooltipHost>
                </div>
                <div className="ms-Grid-col ms-sm2">{item?.AmountNET? '€  ' + item?.AmountNET.toFixed(2) : '' }</div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg1 ms-hiddenLgDown"></div>
                <div className="ms-Grid-col ms-md3">
                    <TextField 
                        placeholder='Product Code'
                        value={item.ProductCode}
                        disabled={that.props.isReadOnly}
                        className='shopLineTextFieldRow2'
                        onChange={this.onProductCodeChanged}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2 ">
                    

                </div>
                <div className="ms-Grid-col ms-sm2 ">
                    

                </div>
                <div className="ms-Grid-col ms-sm2">
                    <TooltipHost
                                content="Discount %"
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                    {that.props.enableDiscountEdit &&
                    <TextField 
                        placeholder='Discount %'
                        value={item.DiscountPct}
                        disabled={that.props.isReadOnly}
                        suffix='%'
                        className='shopLineTextFieldAmountEdit shopLineTextFieldRow2'
                        onChange={this.onDiscountPctChanged}
                    />
                    }   
                    </TooltipHost>
                </div>
                
                <div className="ms-Grid-col ms-sm2">
                    <TooltipHost
                                content="Remove Row"
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                            >
                                <IconButton iconProps={{ iconName: 'Delete' }} title="Delete" ariaLabel="Delete" onClick={this.onRemoveRowClicked} disabled={this.props.isReadOnly} />
                            </TooltipHost>
                </div>
            </div>
            {that.state.isDurationProduct &&
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg1 ms-hiddenLgDown"></div>
                    <div className="ms-Grid-col ms-md3">
                        <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: 15}}>
                            <Stack.Item>
                                <ComboBox
                                    label="Duration"
                                    selectedKey={item.Duration}
                                    allowFreeform={false}
                                    autoComplete={'on'}
                                    options={that.state.periodItems}
                                    placeholder='Period'
                                    aria-label='Period'
                                    disabled={that.props.isReadOnly}
                                    onChange={this.onDurationChanged}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <DatePicker
                                    label="Start Date"
                                    placeholder="Start Date"
                                    ariaLabel="Start Date"
                                    // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                    onSelectDate={this.onSelectedStartDate}
                                    value={item.StartDate}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <DatePicker
                                    label="End Date"
                                    placeholder="End Date"
                                    ariaLabel="End Date"
                                    disabled={true}
                                    // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                    value={item.EndDate}
                                />
                            </Stack.Item>
                        </Stack>
                        
                    </div>
                    <div className="ms-Grid-col ms-sm2 ">
                        

                    </div>
                    <div className="ms-Grid-col ms-sm2 ">
                        

                    </div>
                    <div className="ms-Grid-col ms-sm2">
                    
                    </div>
                    
                    <div className="ms-Grid-col ms-sm2">
                        
                    </div>
                </div>
            }
        </>
        );
      }
      
      private onDurationChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let that = this;
        var item : any = that.props.getItemAtRow(that.props.index);
        item.Duration = option?.key?.toString() ?? '';
        if (!item.StartDate){
            item.StartDate = new Date();
        }
        item.EndDate = this.calcEndDate(item.StartDate, item.Duration);
        this.forceUpdate();
      }
      private onSelectedStartDate = (date: Date | null | undefined) : void =>{
        let that = this;
        var item : any = that.props.getItemAtRow(that.props.index);
        item.StartDate = date;
        item.EndDate = this.calcEndDate(date, item.Duration);
        this.forceUpdate();
      }

      private calcEndDate = (dtStart: Date | null | undefined, duration: string) : Date | null | undefined=> {
        if (dtStart && duration){
            if (duration.toLowerCase().includes('d')){
                let num : number = Number.parseInt(duration.toLowerCase().substring(0, duration.toLowerCase().indexOf('d')));
                if (!isNaN(num)){
                    return dayjs(dtStart).add(num, 'day').toDate();
                }
            }
            else if (duration.toLowerCase().includes('w')){
                let num : number = Number.parseInt(duration.toLowerCase().substring(0, duration.toLowerCase().indexOf('w')));
                if (!isNaN(num)){
                    return dayjs(dtStart).add(num * 7, 'day').toDate();
                }
            }
            else if (duration.toLowerCase().includes('m')){
                let num : number = Number.parseInt(duration.toLowerCase().substring(0, duration.toLowerCase().indexOf('m')));
                if (!isNaN(num)){
                    return dayjs(dtStart).add(num, 'month').toDate();
                }
            }
            else if (duration.toLowerCase().includes('y')){
                let num : number = Number.parseInt(duration.toLowerCase().substring(0, duration.toLowerCase().indexOf('y')));
                if (!isNaN(num)){
                    return dayjs(dtStart).add(num, 'year').toDate();
                }
            }
            else{
                return dtStart;
            }
        }
        else{
            if (dtStart){
                return dtStart;
            }
            else{
                return undefined;
            }
        }

      }
  }

