import {  CommandBar, ICommandBarItemProps, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import { takeRight } from "lodash";
import { nanoid } from "nanoid";
import React from "react";
import { DesignTimeModelHelper } from "../../../../Helpers/DesignTimeModelHelper";
import { SecurityRoleDefinition } from "../../../Entities/SecrityRole";
import { SystemApplicationInfo } from "../../../Entities/SystemApplicationInfo";
import { SecurityRoleEntityPrivileges } from "./SecurityRoleEntityPrivileges";
import { TNDesignerRolePrivilegeEditControl } from "./TNDesignerRolePrivilegeEditControl";

interface SecurityRolePrivilegesSurfaceEditorProps {
    role: SecurityRoleDefinition;
    app: SystemApplicationInfo;
    tenantId: string;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface SecurityRolePrivilegesSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage?: string;
  isbusy : boolean;
  busyMessage: string;
  role: SecurityRoleDefinition;
  app: SystemApplicationInfo;
  tenantId: string;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  searchEntOrPrivilegeName: string;
  entityPrivs: SecurityRoleEntityPrivileges[];
}

export class SecurityRolePrivilegesSurfaceEditorControl extends React.Component<SecurityRolePrivilegesSurfaceEditorProps,SecurityRolePrivilegesSurfaceEditorState> {
    constructor(props:SecurityRolePrivilegesSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        role: props.role,
        tenantId: props.tenantId,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        busyMessage: '',
        searchEntOrPrivilegeName: '',
        entityPrivs: new Array<SecurityRoleEntityPrivileges>()
      }
  
    }
    componentDidMount() {
      //this.loadBarItems();
      //this.loadRolePrivileges();
    }

    // private loadBarItems = () : void => {
    //   let that = this;
    //   let cmdItems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
    //   cmdItems.push({
    //     iconOnly: true,
    //     key: 'add',
    //     iconProps: { iconName: 'Refresh' },
    //     ariaLabel: 'Refresh',
    //     ariaDescription: 'Refresh',
    //     onClick: (ev?: any) => {that.loadRolePrivileges(); that.forceUpdate();}
        
    //   });
    //   that.setState({cmdBarItems: cmdItems})
    // }

    // private setEntityPrivileges = (res: SecurityRoleDefinition[]) : void => {
    //   let that = this;
    //   let items : SecurityRoleEntityPrivileges[] = new Array<SecurityRoleEntityPrivileges>();
    //   res.forEach(function(item: SecurityRoleDefinition, idx: number){
    //     if (item.Privileges && item.Privileges.length > 0){
    //       item.Privileges.forEach(function (pdef: any, pdefidx: number){
    //         let xp : SecurityRoleEntityPrivileges | undefined = items.find(z => z.RoleDefinition?.Id === item.Id && z.EntityLogicalName === pdef.Privilege?.EntityName);
    //         if (xp){
    //           xp.Privileges.push(item);
    //         }
    //         else{
    //           xp = new SecurityRoleEntityPrivileges();
    //           xp.RoleDefinition = item;
    //           xp.EntityLogicalName = pdef.Privilege?.EntityName;
    //           xp.Privileges.push(item);
    //           items.push(xp);
    //         }
    //       })
          
    //     }
    //   })

    //   that.setState({entityPrivs: items, isbusy: false});
    //   that.forceUpdate();
    // }

    // private loadRolePrivileges = () : void => {
    //   let that = this;
    //   let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
    //   mh.loadSecurityRolesPrivileges(this.props.tenantId, this.props.app.Id, this.props.role.Id)
    //     .then(function(res: SecurityRoleDefinition[]){
    //       that.setEntityPrivileges(res);
    //       that.forceUpdate();
    //     })
    //     .catch(function(err: any){
    //       that.setState({errorMessage: err?.message});
    //     })
    // }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='' >
            <div className='' >Set the corresponding privileges for role '{this.state.role.DisplayName}' in the tabs below</div>
            {/* <CommandBar
                        items={this.state.cmdBarItems}
                        overflowButtonProps={{ ariaLabel: 'More commands' }}
                        //farItems={this.CmdBarFarItems}
                        farItems={this.state.cmdBarFarItems}
                        ariaLabel="Use left and right arrow keys to navigate between commands"
                        className='tn-flexgrid-commandbar'
                    /> */}
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            {that.state.notificationMessage &&
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={(ev?: any) => {that.setState({notificationMessage : ''})}}
                dismissButtonAriaLabel="Close"
              >
                {that.state.notificationMessage}
              </MessageBar>
            }
            {/* {that.state.isbusy && 
              <ProgressIndicator label={that.state.busyMessage} />
            } */}
            <Pivot aria-label="Security Pivot">
              <PivotItem
                headerText="Entity Privileges"
                headerButtonProps={{
                  'data-order': 1,
                  'data-title': 'Entity Privileges',
                }}
              >
                <TNDesignerRolePrivilegeEditControl 
                  isreadonly={false}
                  roleid={this.props.role.Id}
                  rolename={this.props.role.RoleName}
                  applicationid={this.props.app.Id}
                  applicationname={this.props.app.DisplayName}
                  tenantUniqueName={this.props.tenantId}
                  id={nanoid()}
                  issystemadmin={true}
                  privilegesType="default"
                >
                  
                </TNDesignerRolePrivilegeEditControl>
              </PivotItem>
              <PivotItem headerText="Custom Privileges">
                <TNDesignerRolePrivilegeEditControl 
                  isreadonly={false}
                  roleid={this.props.role.Id}
                  rolename={this.props.role.RoleName}
                  applicationid={this.props.app.Id}
                  applicationname={this.props.app.DisplayName}
                  tenantUniqueName={this.props.tenantId}
                  id={nanoid()}
                  issystemadmin={true}
                  privilegesType="custom"
                ></TNDesignerRolePrivilegeEditControl>
              </PivotItem>
            </Pivot>
            
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.role && !this.state.app.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      /*if (this.state.ent && this.state.app && this.state.ent.LogicalName){
        var len : number = this.state.app.en
        this.setState({errorMessage : 'The Tile Name class is required'});
        return;
      }*/
      this.props.closeAndSave();
    }
    
  }
  