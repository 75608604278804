import { Stack } from "@fluentui/react";
import React  from "react";
import {  RouteComponentProps } from "react-router-dom";
import { ApplicationPublicPage } from "./ApplicationPublicPage";


type TParams =  { tenantname: string, appname: string, entityname?: string, actionname?:string, id?: string };


export function DynamicPublicPage({ match }: RouteComponentProps<TParams>) {
  
    return (<Stack className="publicapp" verticalFill horizontalAlign='stretch'>
        <ApplicationPublicPage tenantName={match.params.tenantname} applicationName={match.params.appname} entityName={match.params.entityname} actionName={match.params.actionname} itemId={match.params.id} ></ApplicationPublicPage>
    </Stack>)
}

