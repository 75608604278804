import {  ComboBox, IComboBox, IComboBoxOption, ICommandBarItemProps, IDropdownOption, MessageBar, MessageBarType, PrimaryButton, Stack } from "@fluentui/react";
import { MgtTodo } from "@microsoft/mgt-react";
import React from "react";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";

interface EntityDefinitionImportSurfaceEditorProps {
    tenantId: string;
    app: SystemApplicationInfo;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface EntityDefinitionImportSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  app: SystemApplicationInfo;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  appItemList: IDropdownOption[],
  entItemList: IDropdownOption[],
  allApps?: SystemApplicationInfo[],
  allEnts?: EntityDefinition[],
  selectedAppId?: string,
  selectedEntLogicalName?: string
}

export class EntityDefinitionImportSurfaceEditorControl extends React.Component<EntityDefinitionImportSurfaceEditorProps, EntityDefinitionImportSurfaceEditorState> {
    constructor(props: EntityDefinitionImportSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        appItemList: new Array<IDropdownOption>(),
        entItemList: new Array<IDropdownOption>(),
      }
  
    }
    componentDidMount() {
      this.buildSolutionList();
    }

    private buildSolutionList() {
      let that = this;
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      mh.loadSolutionsInTenant(this.props.tenantId)
        .then(function(apps: SystemApplicationInfo[]){
          if (apps){
            let ai: Array<IDropdownOption> = new Array<IDropdownOption>();
            apps.forEach(function(app: SystemApplicationInfo, idx: number){
              if (app.Id !== that.props.app.Id){
                let bCanAdd: boolean = true;
                if (app.DependsOnAppIds && app.DependsOnAppIds.length > 0){
                  for(let x= 0; x<app.DependsOnAppIds.length; x++){
                    if (app.DependsOnAppIds[x] === that.state.app.Id){
                      bCanAdd = false;
                    }
                  }
                }
                if (bCanAdd){
                  ai.push({key: app.Id, text: app.DisplayName});
                }
              }
            })
            that.setState({allApps: apps, appItemList: ai});
          }
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message});
        })
    }
    private buildEntityList(appId: string) {
      let that = this;
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      mh.loadEntitiesInModel(this.props.tenantId, appId)
        .then(function(ents: EntityDefinition[]){
          if (ents){
            let ai: Array<IDropdownOption> = new Array<IDropdownOption>();
            ents.forEach(function(ent: EntityDefinition, idx: number){
              ai.push({key: ent.LogicalName, text: ent.DisplayName});
            })
            that.setState({allEnts: ents, entItemList: ai});
          }
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message});
        })
    }
    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >{this.state.app.Id ? 'Edit Entity' : 'Create New Entity'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <ComboBox label="Select Application" options={that.state.appItemList} selectedKey={that.state.selectedAppId} onChange={this.onAppChanged} ></ComboBox>
            <ComboBox label="Select Entity" options={that.state.entItemList} selectedKey={that.state.selectedEntLogicalName} onChange={this.onEntChanged} ></ComboBox>
            <PrimaryButton iconProps={{iconName: 'CloudDownload'}} text="Import" onClick={this.closeAndSave} allowDisabledFocus disabled={false} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }
    private onAppChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
      this.setState({selectedAppId: option?.key.toString() ?? ''});
      this.buildEntityList(option?.key.toString() ?? '');
    }
    private onEntChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
      this.setState({selectedEntLogicalName: option?.key.toString() ?? ''});
      //this.buildEntityList(option?.key.toString() ?? '');
    }
    private closeAndSave = (evt: any) =>{
      let that = this;
      if (!this.state.selectedAppId){
        this.setState({errorMessage : 'You must select an application'});
        return;
      }
      if (!this.state.selectedEntLogicalName){
        this.setState({errorMessage : 'You must select an entity'});
        return;
      }
      let ed: EntityDefinition | undefined = this.state.allEnts!.find(z=> z.LogicalName === this.state.selectedEntLogicalName);
      if (ed && that.state.selectedAppId){
        let ned: EntityDefinition = new EntityDefinition();
        ned.LogicalName = ed.LogicalName;
        ned.ApplicationId = this.props.app.Id;
        ned.DisplayName = ed.DisplayName;
        ned.ImageCSSClass = ed.ImageCSSClass;
        ned.IsActive = ed.IsActive;
        ned.SourceApplicationUniqueId = this.state.selectedAppId;
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        mh.createNewEntityInApp(this.props.tenantId, this.props.app.Id, ned)
          .then(function(nent: EntityDefinition){
            that.props.app.DependsOnAppIds.push(that.state.selectedAppId!);
            mh.updateAppIntenant(that.props.tenantId, that.props.app)
              .then(function(xres: SystemApplicationInfo){
                that.props.closeAndSave();    
              })
              .catch(function(xerr: any){
                that.setState({errorMessage: `FAILED to update application: ${xerr?.message}`});
              })
            
          })
          .catch(function(err: any){
            that.setState({errorMessage: `FAILED to import entity: ${err?.message}`});
          })
      }
    }

   

    
  }
  