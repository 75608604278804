import {  Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";
import { EntityPropertyDefinition, PropertyDataType } from "../../Entities/EntityProperty";

interface EntityPropertyDataTypeSurfaceEditorProps  {
    Label: string;
    Property : EntityPropertyDefinition;
    IsReadOnly : boolean;
    onChanged? : (newValue : number) => void;
}

interface EntityPropertyDataTypeSurfaceEditorState extends EntityPropertyDataTypeSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  Label: string;
  Property : EntityPropertyDefinition;
  IsReadOnly : boolean;
}

export class EntityPropertyDataTypeSurfaceEditorControl extends React.Component<EntityPropertyDataTypeSurfaceEditorProps, EntityPropertyDataTypeSurfaceEditorState> {
    private mOptions : IDropdownOption[];
    constructor(props: EntityPropertyDataTypeSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        IsReadOnly : props.IsReadOnly,
        Label : props.Label,
        Property : props.Property,
      }
      this.mOptions = new Array<IDropdownOption>();
      this.mOptions.push({key: 0, text : 'string'})
      this.mOptions.push({key: 1, text : 'number'})
      this.mOptions.push({key: 2, text : 'double'})
      this.mOptions.push({key: 3, text : 'datetime'})
      this.mOptions.push({key: 4, text : 'guid'})
      this.mOptions.push({key: 5, text : 'byte[]'})
      this.mOptions.push({key: 6, text : 'text'})
      this.mOptions.push({key: 7, text : 'choice'})
      this.mOptions.push({key: 8, text : 'relation'})
      this.mOptions.push({key: 100, text : 'timestamp'})
      this.mOptions.push({key: 9, text : 'image'})
      this.mOptions.push({key: 10, text : 'boolean'})

      this.mOptions = this.mOptions.sort((a, b) => a.text < b.text ? -1 : 1);
      //'string' | 'number' | 'datetime' | 'double' | 'byte[]' | 'guid' | 'text' | 'choice' | 'relation' | 'timestamp' | 'image' | 'boolean';
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }

    shouldComponentUpdate(newProps: EntityPropertyDataTypeSurfaceEditorProps, newState: EntityPropertyDataTypeSurfaceEditorState){
      if (this.props.Property !== newProps.Property){
        return true;
      }
      else{
        return false;
      }
    }

    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      return (
        <Dropdown
            placeholder="Data Type"
            label={this.props.Label}
            options={this.mOptions}
            style={{width : '100%'}}
            onChange={this.onChanged}
            selectedKey={this.state.Property.DataType}
            disabled={this.state.IsReadOnly}
        />
      );
    }
    private onChanged= (evt: any, option? : IDropdownOption, index? : number) =>{
        if (option && ! this.state.IsReadOnly){
            let it: EntityPropertyDefinition = this.state.Property;
            it.DataTypeString = option.text as PropertyDataType;
            it.DataType = option.key as number;
            this.setState({Property : it});
            if (this.props.onChanged){
              this.props.onChanged(option.key as number);
            }
            this.forceUpdate();
        }
      }
  }
  