import React, { CSSProperties } from "react";

export interface SectionControlProps {
    headerText : string;
    style?: CSSProperties | undefined;
}

export interface SectionControlState extends SectionControlProps {
  StateKey : number;
  errorMessage? : string;
}


export class SectionControl extends React.Component<SectionControlProps, SectionControlState> {
    constructor(props: SectionControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        errorMessage : '',
        headerText : ''
      }
  
    }
  
    componentDidMount() {
    }

    
    render() {
  
      return (
        <div className="tn-section-control" style={this.props.style}>
            <div className="tn-section-control-header">{this.props.headerText}</div>
            <div className="tn-section-control-body">
                {this.props.children}
            </div>
        </div>
                
      );
    }
  }