import internal from "stream";

export class EntityViewDefinition{
    public Id : string = '';
    public ApplicationId : string = '';
    public EntityId : string = '';
    public EntityName : string = '';
    public PublisherId : string = '';
    public DisplayName : string = '';
    public ViewName : string = '';
    public ViewData : string = '';
    public ViewXml : string = '';
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;
    public IsActive : boolean = true;
    public Order: number = 0;
    public Level: number = 0;
}