export class HtmlHelper{
    public static findElementAttributeValue(el: HTMLElement, attName: string) : string | null{
        if (!el)
            return null;
        return  (el.hasAttribute(attName) ? el.getAttribute(attName)! : null);

    }
    public static findElementAttributeValueAsString(el: HTMLElement, attName: string) : string {
        if (!el)
            return '';
        return  (el.hasAttribute(attName) ? el.getAttribute(attName)! : '');

    }
    public static findElementAttributeValueAsBoolean(el: HTMLElement, attName: string) : boolean {
        if (!el)
            return false;
        return  (el.hasAttribute(attName) ? (el.getAttribute(attName)!.toLowerCase() === 'true'? true : false) : false);

    }
    public static findElementAttributeValueAsNumber(el: HTMLElement, attName: string) : number {
        if (!el)
            return 0;
        return  (el.hasAttribute(attName) ? parseInt(el.getAttribute(attName)!) : 0);

    }
    public static findElementByNodeName(el: HTMLElement | null, nodeName: string): HTMLElement | null{
        if (!el)
            return null;

        for(var x = 0; x<el.childNodes.length; x++){
            if (el.childNodes[x].nodeName?.toLowerCase() === nodeName.toLowerCase())
                return el.childNodes[x] as HTMLElement;
            if (el.childNodes[x] !== null && el.childNodes[x].childNodes && el.childNodes[x].childNodes.length > 0){
                return this.findElementByNodeName(el.childNodes[x] as HTMLElement, nodeName);
            }    
        }
        return null;
    }

    public static findElementsByNodeName(el: HTMLElement | null, nodeName: string): Array<HTMLElement>{
        //debugger;
        var res : Array<HTMLElement> = new Array<HTMLElement>();

        if (!el)
            return res;

        HtmlHelper.findElementsByNodeNameInternal(el, nodeName, res);
        return res;
    }
    private static findElementsByNodeNameInternal(el: HTMLElement | null, nodeName: string, res : Array<HTMLElement>): Array<HTMLElement>{
        if (!el)
            return res;

        for(var x = 0; x<el.childNodes.length; x++){
            if (el.childNodes[x].nodeName?.toLowerCase() === nodeName.toLowerCase()){
                res.push(el.childNodes[x] as HTMLElement);
            }
            if (el.childNodes[x] !== null && el.childNodes[x].childNodes && el.childNodes[x].childNodes.length > 0){
                HtmlHelper.findElementsByNodeNameInternal(el.childNodes[x] as HTMLElement, nodeName, res);
            }    
        }
        return res;
    }
}