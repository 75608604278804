/* eslint-disable no-loop-func */
import { IComboBox, IComboBoxOption, VirtualizedComboBox } from "@fluentui/react/lib/components/ComboBox";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { IllerisNinthAPI } from "../../ServiceResult";
import { UIDataItemEditorProps } from "./FormletEditors";
import { Logger } from "../../Logger";
import { PageInfo } from "../../PageInfo";
import { SilentRequest } from "@azure/msal-browser";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack } from "@fluentui/react/lib/components/Stack";
import { TextField } from "@fluentui/react/lib/components/TextField";
import { IconButton } from "@fluentui/react/lib/components/Button";
import { DatePicker } from "@fluentui/react/lib/components/DatePicker";
import { DayOfWeek } from "@fluentui/react/lib/DateTimeUtilities";
import { SpinButton } from "@fluentui/react/lib/components/SpinButton";
import { Position } from "@fluentui/react/lib/utilities/positioning/positioning.types";
import { Toggle } from "@fluentui/react/lib/components/Toggle";
import { Spinner } from "@fluentui/react/lib/components/Spinner";
import { TNAdvandedLookup } from "../AdvancedLookup";
import { TNDocumentInfo, TNDocumentInputEditor } from "../../TNDocumentEditor";
import { TNImageInfo, TNImageInputEditor } from "../../TNImageEditor";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

  
  export class TNInputBoxView extends React.Component<TNEditorFactoryProps, TNEditorFactoryState> {

      private mInputEditor : IllerisNinthUI.TNInputBox | null;

      private ComboBoxOptions : Array<IComboBoxOption> = new Array<IComboBoxOption>();

      private mPropertyName : string = '';
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            loadingMessage: 'Loading...'
        }
        this.mInputEditor = props.getEditor() as IllerisNinthUI.TNInputBox;

        this.onTextInputChanged = this.onTextInputChanged.bind(this);
        this.onToggleInputChanged = this.onToggleInputChanged.bind(this);
        this.onOpenTextBoxURI = this.onOpenTextBoxURI.bind(this);
        this.onOpenTextBoxEmail = this.onOpenTextBoxEmail.bind(this);
        this.onOpenTextBoxPhone = this.onOpenTextBoxPhone.bind(this);
    }
    
    componentDidMount(){
        this.loadData();
    }

    private loadData = () : void => {
        this.mInputEditor = this.props.getEditor() as IllerisNinthUI.TNInputBox

        this.setState({valueStr: this.props.Entity?.getValue(this.props.getEditor()?.HSBindName)});
        //this.forceUpdate();
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNEditorFactoryState) {
        //Logger.logNotification('TNInputBoxView::shouldComponentUpdate');
        if (this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
            || this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
            || this.state.valueStr !== nextState.valueStr
        ){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNEditorFactoryState){
        if (this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== prevProps.Entity?.getValue(prevProps.getEditor()?.HSBindName)
            || this.props.dataKey !== prevProps.dataKey
            || this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== prevProps.Entity?.getValue(prevProps.getEditor()?.HSBindName)
            || this.props.ServiceResult?.LoadedTimeStamp !== prevProps.ServiceResult?.LoadedTimeStamp
            || this.state.valueStr !== prevState.valueStr
        ){
            this.loadData();
        }
        else{
            
        }
    }

    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.mPropertyName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        this.props.Entity?.setValue(propName, val);
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        Logger.logNotification('TNInputBoxView::Render - Entity ' + this.props.Entity?.LogicalEntityName + ', id ' + this.props.Entity?.getId() + ', hsBindName ' + this.props.getEditor()?.HSBindName + ', value ' + this.props.Entity?.getValue(this.props.getEditor()?.HSBindName));


        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
          });
          //var valStr : string = (this.Entity && this.Entity.getValue(this.Editor!.HSBindName) ? this.Entity.getValue(this.Editor!.HSBindName) : '');

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };

        return (
            <>
                {this && this.mInputEditor  &&
                    <>
                        <Stack horizontal horizontalAlign='stretch' className='tn-inputbox-container'>
                            <TextField label={this.mInputEditor.LabelText} 
                                    readOnly={this.mInputEditor.IsReadOnly} 
                                    disabled={this.mInputEditor.IsReadOnly || this.props.isreadonly} 
                                    prefix={this.mInputEditor.PrePendText ? this.mInputEditor.PrePendText : undefined}
                                    suffix={this.mInputEditor.PostPendText ? this.mInputEditor.PostPendText : undefined}
                                    value={that.state.valueStr  ?? ''}
                                    type={this.mInputEditor?.Type}  
                                    onChange={this.onTextInputChanged}
                                    placeholder={this.mInputEditor.PlaceHolder}
                                    className='tn-inputbox-textinput'
                                    multiline={this.mInputEditor.RowCount !== 1}
                                    rows={this.mInputEditor.RowCount}
                                    id={this.mInputEditor.ItemID}
                                    data-hsbind={this.mInputEditor.HSBindName}
                                    />
                            {this.mInputEditor && this.mInputEditor.Type === 'url' && that.state.valueStr &&
                                <div style={{alignContent: 'right', marginTop: '29px', marginLeft:'-1px', width: '29px', height: '30px', border: (this.mInputEditor.IsReadOnly? '0px': '1px solid black') }}>
                                    <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} title="Open URI" ariaLabel="Open URI" styles={{ icon :{color : (this.mInputEditor?.IsReadOnly? '#A8A7A5' : '#A8A7A5' )}}} style={{width: '25px', height: '25px', marginTop: '2px', marginLeft: '2px'}} onClick={this.onOpenTextBoxURI} />
                                </div>
                            }
                            {this.mInputEditor && this.mInputEditor.Type === 'email' && that.state.valueStr &&
                                <div style={{alignContent: 'right', marginTop: '29px', marginLeft:'-1px', width: '29px', height: '30px', border: (this.mInputEditor.IsReadOnly? '0px': '1px solid black') }}>
                                    <IconButton iconProps={{ iconName: 'Mail' }} title="Create Email" ariaLabel="Create Email" styles={{ icon :{color : (this.mInputEditor?.IsReadOnly? '#A8A7A5' : '#A8A7A5' )}}}  style={{width: '25px', height: '25px', marginTop: '2px', marginLeft: '2px'}} onClick={this.onOpenTextBoxEmail}  />
                                </div>
                            }
                            {this.mInputEditor && this.mInputEditor.Type === 'phone' && that.state.valueStr &&
                                <div style={{alignContent: 'right', marginTop: '29px', marginLeft:'-1px', width: '29px', height: '30px', border: (this.mInputEditor.IsReadOnly? '0px': '1px solid black') }}>
                                    <IconButton iconProps={{ iconName: 'Phone' }} title="Call" ariaLabel="Call" styles={{ icon :{color : (this.mInputEditor?.IsReadOnly? '#A8A7A5' : '#A8A7A5' )}}} style={{width: '25px', height: '25px', marginTop: '2px', marginLeft: '2px'}} onClick={this.onOpenTextBoxPhone}  />
                                </div>
                            }
                        </Stack>
                    </>
                }
                {this && this.mInputEditor && (this.mInputEditor.Type === 'date') &&
                    <DatePicker
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        //strings={DayPickerStrings}
                        placeholder= {this.mInputEditor.PlaceHolder}
                        ariaLabel={this.mInputEditor.LabelText}
                        label={this.mInputEditor.LabelText}
                        disabled={this.mInputEditor.IsReadOnly || this.props.isreadonly}
                        showWeekNumbers={true}
                        value={new Date(that.state.valueStr ?? '')}
                        id={this.mInputEditor.ItemID}
                        data-hsbind={this.mInputEditor.HSBindName}
                        onSelectDate={this.onSelectedDate}
                    />
                }
                {this && this.mInputEditor && (this.mInputEditor.Type === 'number') &&
                    <SpinButton
                        defaultValue="0"
                        label={this.mInputEditor.LabelText}
                        disabled={this.mInputEditor.IsReadOnly || this.props.isreadonly}
                        step={1}
                        labelPosition={Position.top}
                        incrementButtonAriaLabel={'Increase value'}
                        decrementButtonAriaLabel={'Decrease value'}
                        value={that.state.valueStr  ?? ''}
                        id={this.mInputEditor.ItemID}
                        data-hsbind={this.mInputEditor.HSBindName}
                        onChange={this.onSpinValueChanged}
                    />
                }
                {this && this.mInputEditor && (this.mInputEditor.Type === 'checkbox') &&
                    <Toggle label={this.mInputEditor.LabelText} 
                        onText={this.mInputEditor.ToggleOnText} 
                        offText={this.mInputEditor.ToggleOffText} 
                        checked={(that.state.valueStr && (that.state.valueStr!.toString() === 'true')) ? true : false}
                        id={this.mInputEditor.ItemID}
                        onChange={this.onToggleInputChanged}
                        role="checkbox"
                        disabled={this.mInputEditor.IsReadOnly || this.props.isreadonly}
                        data-hsbind={this.mInputEditor.HSBindName}
                        />
                }

            </>
        );
      }
      
      private onSpinValueChanged = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined) : void =>{
        this.props.Entity?.setValue(this.props.getEditor()!.HSBindName, newValue);
        this.setState({valueStr : newValue});
      }
      private onSelectedDate = (date: Date | null | undefined) : void =>{
        this.props.Entity?.setValue(this.props.getEditor()!.HSBindName, date);
        this.setState({valueStr : date?.toISOString()});
      }
      private onTextInputChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue : string | undefined) : void =>{
        this.props.Entity?.setValue(this.props.getEditor()!.HSBindName, newValue);
        this.setState({valueStr : newValue});
      }
      private onToggleInputChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) : void =>{
        this.props.Entity?.setValue(this.props.getEditor()!.HSBindName, checked);
        this.setState({valueStr : checked ? 'true' : 'false'});
      }

      private onOpenTextBoxURI = (event : React.MouseEvent<HTMLElement, MouseEvent>) : void => {
          if (this.mInputEditor && this.state.valueStr){
              window.open(this.state.valueStr, '_blanc');
          }
      }

    private onOpenTextBoxEmail = (event : React.MouseEvent<HTMLElement, MouseEvent>) : void => {
        if (this.mInputEditor && this.state.valueStr){
            window.open('mailto:' + this.state.valueStr,'_blanc');
        }
    }
    private onOpenTextBoxPhone = (event : React.MouseEvent<HTMLElement, MouseEvent>) : void => {
        if (this.mInputEditor && this.state.valueStr){
            window.open('tel:' + this.state.valueStr,'_blanc');
        }
    }

  }