// Generated by https://quicktype.io

export class WhoAmIResponse {
    UserDisplayName: string = '';
    UserEmail:       string = '';
    TenantId:        string = '';
    SystemUserId:    string = '';
    Roles:           string = '';
    IdentityId:      string = '';
    TeamId:          string = '';
    OrganizationId:  string = '';

    static getRoles = (it: WhoAmIResponse) : string[] => {
        let roles: string[] = (it && it.Roles) ? it.Roles.split(';') : new Array<string>();
        return roles;
    }
}
