export class EntityHelper {
    public static isPropertyPathValid= (propName : string, obj : any) : boolean =>{
        if (propName && propName.indexOf('.') !== -1){
            var props : Array<string> = propName.split('.');
            var val : any = obj;
            for(var x= 0; x<props.length; x++){
                if (val){
                    val = val[props[x]];
                }
                else{
                    return false;
                }
            }
            return true;
        }
        else if (propName){
            var val2 = obj[propName];
            if (val2){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    public static resolvePropertyValue = (propName : string, obj : any) : any =>{
        if (propName && propName.indexOf('.') !== -1){
            var props : Array<string> = propName.split('.');
            var val : any = obj;
            for(var x= 0; x<props.length; x++){
                if (val){
                    val = val[props[x]];
                }
            }
            return val;
        }
        else if (propName){
            var val2 = obj[propName];
            if (val2){
                return val2;
            }
            else{
                return null;
            }
        }
        else{
            return null;
        }
    }
}