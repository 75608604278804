/* eslint-disable no-loop-func */
import { CommandBar, DefaultButton, ICommandBarItemProps, IContextualMenuItem, Panel, Stack } from "@fluentui/react";
import React from "react";
//import { ClientState } from "../ClientState";
import { DynamicEntity } from "../DynamicEntity";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { Logger } from "../Logger";
import { PageInfo } from "../PageInfo";
import { IllerisNinthAPI } from "../ServiceResult";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { ButtonItem, ButtonItemList } from "./ButtonItemList";
import { DocumentGenerationPanelControl } from "./DocumentGenerationPanelControl";





export interface FormletActionBarProps {
    Formlet?: IllerisNinthUI.Formlet | null;
    isReadOnly: boolean;
    ServiceResult: IllerisNinthAPI.ServiceResult | null | undefined;
    Entity?: DynamicEntity | null;
    IsLoading: boolean;
    renderDocumentAction: (itemKey: string) => void;
    executeAction?: (act: ButtonItem) => void;
    PageInfo: PageInfo;
    RenderKey: string;
}

export interface FormletActionBarState  {
    //MainButtonList : Array<IllerisNinthAPI.Button | IllerisNinthUI.ActionButton>;
    MainButtonList: ButtonItemList;
    Buttons : Array<ICommandBarItemProps>;
    FarButtons : Array<ICommandBarItemProps>;
    ContextualMenuButtons:  IContextualMenuItem[];
    ServiceResult: IllerisNinthAPI.ServiceResult | null | undefined;
    Entity: DynamicEntity | null | undefined;
    Width: number;
    IsLoading: boolean;
    ShowDocumentTemplatePanel: boolean;
}



export class FormletActionBarControl extends React.Component<FormletActionBarProps, FormletActionBarState> {
      constructor(props : FormletActionBarProps){
          super(props);
          
          this.state = {
            //MainButtonList: new Array<IllerisNinthAPI.Button | IllerisNinthUI.ActionButton>(),
            MainButtonList: new ButtonItemList(),
            Buttons: new Array<ICommandBarItemProps>(),
            FarButtons: new Array<ICommandBarItemProps>(),
            ContextualMenuButtons: new Array<IContextualMenuItem>(),
            ServiceResult: props.ServiceResult,
            Entity: props.Entity,
            Width: window.innerWidth,
            IsLoading: props.IsLoading,
            ShowDocumentTemplatePanel: false
          }

      }
      private updateDimensions = () => {
        this.setState({ Width: window.innerWidth});
      };

      public componentDidMount(){
          window.addEventListener('resize', this.updateDimensions);
      }
      private padNumberToString = (num : number , size :number) : string => {
        let res = num.toString();
        while (res.length < size) res = "0" + res;
        return res;
      }

      private bindReportsDropDownButton = (menuItem: ICommandBarItemProps, de: DynamicEntity | null) : void => {
          if (!de){
              return;
          }
          let that =this;
          let entityName: string = de ? de.LogicalEntityName : '';
          if (entityName){
          var epm : EntityPersistanceManager = new EntityPersistanceManager();
          epm.getMany('documenttemplate','$filter=EntityName = "' + entityName + '"&$orderby=DisplayName')
            .then(function(res : IllerisNinthAPI.ServiceResult | Error){
                var err : Error = res as Error;
                if (err && typeof (err as any)['Value'] === 'undefined'){
                  IllerisNinthUI.NotificationManager.showError('Failed to load document templaes', err.message);
                }
                else{
                  var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                  if (sr  && sr.Values) {
                    
                    var smenuItems : Array<IContextualMenuItem> = new Array<IContextualMenuItem>();
                    for (var j = 0; j < sr.Values.length; j++) {
                      var itemId: string = (sr && sr.Values && sr.Values[j]) ? sr.Values[j].Id : '';
                        var sitem : IContextualMenuItem  = {
                          key : sr.Values[j].Id,
                          text: sr.Values[j].DisplayName,
                          title : sr.Values[j].DisplayName,
                          iconProps: { iconName : (sr.Values[j].DocumentType === 1?'WordDocument' : 'PDF') },
                          ariaLabel : sr.Values[j].DisplayName,
                          onClick:(ev?: any, item?: IContextualMenuItem | undefined) => { that.props.renderDocumentAction(itemId);}
                        }
                        smenuItems.push(sitem);
                    }
                    menuItem.subMenuProps = {
                      items : smenuItems  
                    }
                  }
                  var items : ICommandBarItemProps[] | undefined = that.state.FarButtons;
                  that.setState({FarButtons : items})
                  that.forceUpdate();
                }
            })
            .catch(function(err){
              IllerisNinthUI.NotificationManager.showError('Failed to load document templates', err.message);
            })
        }
      }

      private onContextMenuButtonClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        Logger.logNotification('Clicked ContextMenu button ' + item?.key)
        //debugger;

        if (item && item.key === 'report'){
          this.setState({ShowDocumentTemplatePanel: true});
          this.forceUpdate();
        }
        else {
          let buttonList: ButtonItemList = this.state.MainButtonList;
          let it: ButtonItem | null | undefined = buttonList.getItem(item?.key.toString());

          if (this.props && this.props.executeAction && it){
              this.props.executeAction(it);
          }
        }
      }
      private onCommandBarButtonClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: ICommandBarItemProps | undefined) : boolean | void =>{
        Logger.logNotification('Clicked CommandBar button ' + item?.key)
        //debugger;

        if (item && item.key === 'report'){
          this.setState({ShowDocumentTemplatePanel: true});
          this.forceUpdate();
        }
        else {
          let buttonList: ButtonItemList = this.state.MainButtonList;
          let it: ButtonItem | null | undefined = buttonList.getItem(item?.key.toString());

          if (this.props && this.props.executeAction && it){
              this.props.executeAction(it);
          }
        }
      }
      private onActionBarButtonClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        Logger.logNotification('Clicked ActionBar button ' + item?.key)
        debugger;

        let buttonList: ButtonItemList = this.state.MainButtonList;
        let it: ButtonItem | null | undefined = buttonList.getItem(item?.key.toString());

        if (this.props && this.props.executeAction && it){
            this.props.executeAction(it);
        }
      }
      private rebuildAllBars = (sr?: IllerisNinthAPI.ServiceResult |null | undefined) : void =>{
        //debugger;
        Logger.logNotification('Building formlet command bar...');
        let resList: ButtonItemList = this.state.MainButtonList;
        //resList.clear();

        if (this.props.Formlet && this.props.Formlet.ActionButtons && Array.isArray(this.props.Formlet.ActionButtons)){
          // Static buttons
          for(var i = 0; i<this.props.Formlet.ActionButtons.length; i++){
            var ab : IllerisNinthUI.ActionButton = this.props.Formlet.ActionButtons[i];
            resList.add(ab);
          }
          var csr : IllerisNinthAPI.ServiceResult | null | undefined = sr ?? this.state.ServiceResult;
          // dynamic buttons
          if (csr && csr.ActionBar){
            for(var q = 0; q<csr.ActionBar.Buttons.length; q++){
              var btn : IllerisNinthAPI.Button = csr.ActionBar.Buttons[q];
              resList.add(btn);
            }
          }
          let de: DynamicEntity | null = (sr && sr.Value ? new DynamicEntity(sr.Value, sr.EntityName) : null);

          let menuItems: ICommandBarItemProps[] = resList.buildContextualMenuBarItemsList(de);
          let ctxItems: IContextualMenuItem[] = resList.buildCommandBarItemsList(de);
          this.bindAllCommandBarMenus(menuItems, de);
          this.bindAllContextMenus(ctxItems, de);

          this.setState({FarButtons : menuItems, ContextualMenuButtons: ctxItems, MainButtonList: resList});
        }
      }

      private bindAllContextMenus = (ctxItems: IContextualMenuItem[], de : DynamicEntity | null) : void => {
          let that = this;
          if (ctxItems){
              ctxItems.forEach(function(item: IContextualMenuItem,idx: number){
                  item.onClick = that.onContextMenuButtonClicked.bind(that);
                  if (item.key === 'report'){
                    //that.bindReportsDropDownButton(item, de);
                  }
              })
          }
      }
      private bindAllCommandBarMenus = (barItems: ICommandBarItemProps[], de : DynamicEntity | null) : void => {
        let that = this;
        if (barItems){
            barItems.forEach(function(item: IContextualMenuItem,idx: number){
                item.onClick = that.onCommandBarButtonClicked.bind(that);
                if (item.key === 'report'){
                    //that.bindReportsDropDownButton(item, de);
                }
            })
        }
      }

    //   private rebuildCommandBar = (sr?: IllerisNinthAPI.ServiceResult |null | undefined) : void =>{
    //     debugger;
    //     var that = this;
    //     Logger.logNotification('Building formlet command bar...');
    //     let btnList: Array<IllerisNinthAPI.Button | IllerisNinthUI.ActionButton> = new Array<IllerisNinthAPI.Button | IllerisNinthUI.ActionButton>();

    //     if (this.props.Formlet && this.props.Formlet.ActionButtons && Array.isArray(this.props.Formlet.ActionButtons)){
    //       let actBtnList:Array<IllerisNinthUI.ActionButton> = new Array<IllerisNinthUI.ActionButton>();
    //       let allButtons : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
    //       let sch : IllerisNinthAPI.EntityStateCodeHelper = new IllerisNinthAPI.EntityStateCodeHelper();
    //       // Static buttons
    //       for(var i = 0; i<this.props.Formlet.ActionButtons.length; i++){
    //         var ab : IllerisNinthUI.ActionButton = this.props.Formlet.ActionButtons[i];
    //         sch.clearStateCodes();
    //         sch.addStateCodeString(ab.VisibleOnStateCodes);
  
    //         if (ab && ab.IsVisible && ab.IsEnabled && sch.entityMatchesStateCodes(this.state.Entity)){
  
    //           var xi : ICommandBarItemProps | undefined = allButtons.find(z => z.key === ab.CommandName);
    //           if (!xi){
    //             btnList.push(ab);
    //             allButtons.push({
    //                 iconOnly: false,
    //                 key: ab.CommandName,
    //                 label: ab.Label,
    //                 title : ab.Label,
    //                 cacheKey : this.padNumberToString(ab.Order,8) + ab.Label,
    //                 iconProps: { iconName: ( IconConverter.convertToFluentUiIconBtn(ab)) },
    //                 ariaLabel: ab.Label,
    //                 ariaDescription: ab.Label,
    //                 tooltipHostProps: { content : ab.ToolTipText}
    //             })

    //             if ((allButtons[allButtons.length - 1].key.toLowerCase() === 'cmdreport') || (allButtons[allButtons.length - 1].key.toLowerCase() === 'report')){
    //               that.bindReportsButton(allButtons[allButtons.length - 1], ab);
    //             }
    //             else{
    //               allButtons[allButtons.length - 1].onClick = that.onActionBarButtonClicked.bind;
    //             }
    //             actBtnList.push(ab);
    //           }
    //         }
    //       }
    //       var csr : IllerisNinthAPI.ServiceResult | null | undefined = sr ?? this.state.ServiceResult;
    //       // dynamic buttons
    //       if (csr && csr.ActionBar){
    //         for(var q = 0; q<csr.ActionBar.Buttons.length; q++){
    //           var btn : IllerisNinthAPI.Button = csr.ActionBar.Buttons[q];
    //           if (btn.CommandName && btn.CommandName.startsWith('cmd')){
    //             btn.CommandName = btn.CommandName.substr(3).toLowerCase();
    //           }
    //           var existingBtn : ICommandBarItemProps | undefined = allButtons.find( z=> z.key?.toLowerCase() === btn.CommandName.toLowerCase() );
    //           if (!existingBtn && btn.IsVisible){
    //             var bAdd : boolean = true;
    //             var fmab : IllerisNinthUI.ActionButton | undefined = this.props.Formlet?.ActionButtons.find((z: { CommandName: string; }) => z.CommandName?.toLowerCase() === btn.CommandName?.toLowerCase());
    //             if (fmab){
    //               if (!fmab.IsVisible || !fmab.IsEnabled){
    //                 bAdd = false;
    //               }
    //             }
  
    //             sch.clearStateCodes();
    //             sch.addStateCodeString(btn.VisibleOnStateCodes);
    //             if (bAdd && sch.entityMatchesStateCodes(this.state.Entity)){
    //               btnList.push(btn);
    //               allButtons.push({ 
    //                 iconOnly: false,
    //                 key: btn.CommandName,
    //                 label: btn.CommandText,
    //                 cacheKey : this.padNumberToString(btn.Order? btn.Order : 1000 ,8) + btn.CommandText,
    //                 title : btn.CommandText,
    //                 //iconProps: { iconName: ( btn.CommandName?.toLowerCase() === 'saveandclose' ? 'SaveAndClose' : IconConverter.convertToFluentUiIcon(btn.ImageCSSClass)) },
    //                 iconProps: { iconName: ( IconConverter.convertToFluentUiIconBtn2(btn)) },
    //                 ariaLabel: btn.CommandText,
    //                 ariaDescription: btn.CommandText,
    //                 tooltipHostProps: { content : btn.CommandText}
    //               });
    //               if (btn.CommandName.toLowerCase() === 'report' || btn.CommandName.toLowerCase() === 'cmdreport'){
    //                 that.bindReportsButton(allButtons[allButtons.length - 1], btn);
    //               }
                  
  
    //             }
    //             // ISSUE : not added when previous state was !visible TODO!!!
    //             var ab2 : IllerisNinthUI.ActionButton = new IllerisNinthUI.ActionButton();
    //             ab2.merge(btn);
    //             if (bAdd){
    //                 actBtnList.push(ab2);
    //             }
    //           }
    //           else if (existingBtn){
    //             var sch2 : IllerisNinthAPI.EntityStateCodeHelper = new IllerisNinthAPI.EntityStateCodeHelper();
    //             sch2.addStateCodeString(btn.VisibleOnStateCodes);
    //             var scOk = sch2.entityMatchesStateCodes(this.state.Entity);
    //             if (!scOk || !btn.IsVisible){
    //               var idx : number = allButtons.indexOf(existingBtn);
    //               if (idx && idx > 0){
    //                 allButtons = allButtons.splice(idx, 1);
    //               }
    //             }
    //             var xb : IllerisNinthUI.ActionButton | undefined = actBtnList.find ( z=> z.CommandName === existingBtn?.key);
    //             if (xb){
    //               xb.merge(btn);
    //             }
    //           }
    //         }
    //       }
  
    //       Logger.logNotification('Formlet command bar build with ' + allButtons?.length + ' buttons.');
    //       //this.FarButtons = allButtons.sort(()z => z.cacheKey);
    //       let farBtns: ICommandBarItemProps[] = allButtons.sort((a, b) => a.cacheKey!.localeCompare(b.cacheKey!)) ;
    //       for(var fb = 0; fb < farBtns.length; fb++){
    //         //this.FarButtons[fb].onClick = that.onActionBarButtonClicked.bind(this);
    //         //this.FarButtons[fb].onClick = () => console.log('item clicked');
    //         farBtns[fb].onClick = this.blabla;
    //         //() => console.log('Share')
    //       }
          
  
    //       // set contextual menu bar
    //       let ctxMenuBtns: IContextualMenuItem[] = new Array<IContextualMenuItem>();
    //       if (farBtns){
              
    //         for(var x = 0; x<farBtns.length; x++){
    //           var newItem : IContextualMenuItem = {
    //             key: farBtns[x].key,
    //             text: farBtns[x].title,
    //             iconProps : farBtns[x].iconProps,
    //             onClick : that.onActionBarButtonClicked.bind(this)
    //           };
    //           ctxMenuBtns.push(newItem);
    //           //this.ContextualMenuButtons[this.ContextualMenuButtons.length - 1].onClick = this.onActionBarButtonClicked.bind(this);
    //           //this.ContextualMenuButtons[this.ContextualMenuButtons.length - 1].onClick = that.onActionBarButtonClicked;
    //         }
    //       }
    //       this.setState({FarButtons : farBtns, ContextualMenuButtons: ctxMenuBtns});
    //       //this.forceUpdate();
    //     }
    //   }
      private disableAllItems = (bDisable: boolean) : void => {
        let citems: IContextualMenuItem[] = this.state.ContextualMenuButtons;
        if (citems){
            citems.forEach(function(item: IContextualMenuItem, idx: number){
                item.disabled = bDisable;
            })
        }

        let bitems: ICommandBarItemProps[] = this.state.FarButtons;
        if (bitems){
            bitems.forEach(function(item: ICommandBarItemProps, idx: number){
                item.disabled = bDisable;
            })
        }
        this.setState({FarButtons: bitems, ContextualMenuButtons: citems});
      }
      
      public shouldComponentUpdate(nextProps: FormletActionBarProps, nextState: FormletActionBarState){
          if (nextProps.Entity !== this.props.Entity ||nextProps.ServiceResult !== this.props.ServiceResult || nextProps.isReadOnly !== this.props.isReadOnly || nextProps.IsLoading !== this.props.IsLoading || nextProps.RenderKey !== this.props.RenderKey){
              return true;
          }
          return false;
      }
      public componentDidUpdate(prevProps: FormletActionBarProps, prevState: FormletActionBarState) {
        if (prevProps.Entity !== this.props.Entity ||prevProps.ServiceResult !== this.props.ServiceResult || prevProps.isReadOnly !== this.props.isReadOnly || prevProps.RenderKey !== this.props.RenderKey){
            this.rebuildAllBars(this.props.ServiceResult);
            this.setState({ServiceResult: this.props.ServiceResult, Entity: this.props.Entity});
            this.forceUpdate();
        }
        else if (prevProps.IsLoading !== this.props.IsLoading){
            this.disableAllItems(this.props.IsLoading)
        }
      }

      public render(){
          var that = this;
          var width : number = this.state.Width;

          let de: DynamicEntity | null = (this.props.ServiceResult && this.props.ServiceResult.Value ? new DynamicEntity(this.props.ServiceResult.Value, this.props.ServiceResult.EntityName) : null);


          return (
              <>
              {width > 1100 && 
                      <Stack horizontal horizontalAlign="stretch" className="tn-card-header-commandbar-panel" style={{width: '100%'}}>
                        <Stack.Item align="end" style={{width: '100%'}}>
                          <CommandBar
                            id='formletMainCommandBar'
                            //items={that.state.Buttons}
                            //overflowItems={over}
                            overflowButtonProps={{ ariaLabel: 'More commands' }}
                            //farItems={that.state.FarButtons}
                            items={that.state.FarButtons}
                            ariaLabel="Use left and right arrow keys to navigate between commands"
                            className='tn-card-header-commandbar'
                            //onClick={this.onMainCommandBarItemsClicked}
                          /> 
                          </Stack.Item>
                          </Stack>
                     
                      // <div className='tn-card-header-commandbar-panel'>
                        
                      // </div>
                }
                {width < 1100 && 
                      <div className='tn-card-header-commandbar-panel'>
                        <Stack horizontal horizontalAlign='end'>
                          <DefaultButton menuProps={{ shouldFocusOnMount : true, items : that.state.ContextualMenuButtons}} id='formletMainCommandButton' >
                            <i className='fa fa-bars fa-1.5x tn-card-header-commandbar-panel-button'></i>
                          </DefaultButton>
                        </Stack>
                      </div>
                }
                <Panel
                  headerText="Select Document Template"
                  isOpen={that.state.ShowDocumentTemplatePanel}
                  onDismiss={(ev?: any) => {that.setState({ShowDocumentTemplatePanel: false}); that.forceUpdate(); }}
                  // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                  closeButtonAriaLabel="Close"
                >
                  <DocumentGenerationPanelControl PageInfo={this.props.PageInfo} closePanel={() => {that.setState({ShowDocumentTemplatePanel: false}); that.forceUpdate(); }}  EntityId={de?.getId() ?? ''} EntityLogicalName={de?.LogicalEntityName ?? ''}  ></DocumentGenerationPanelControl>
                </Panel>
              </>
          );
      }
  }