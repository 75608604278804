import { EntityPropertyDefinition } from "./EntityProperty";
import { EntityRelationDefinition } from "./EntityRelation";

export class EntityDefinition{
    public Id : string = '';
    public ApplicationId : string = '';
    public DisplayName : string = '';
    public DisplayNamePlural: string = '';
    public LogicalName : string = '';
    public ImageCSSClass : string = '';
    public CreateDate : Date = new Date();
    public LastModifiedDate : Date = new Date();
    public IsActive : boolean = true;
    public IsVirtual : boolean = false;
    public SourceApplicationUniqueId? : string = '';
    public Properties? : Array<EntityPropertyDefinition>;
    public Relations? : Array<EntityRelationDefinition>;
}