import { SilentRequest } from "@azure/msal-browser";
import { Depths, Icon, IconButton, ProgressIndicator, Spinner, SpinnerSize } from "@fluentui/react";
import { Dropdown, IDropdownOption, IDropdownStyles, INavLink, INavLinkGroup, IRenderGroupHeaderProps, Nav, Stack } from "@fluentui/react";
import React from "react";
import nextId  from "react-id-generator";
import { ClientState } from "./ClientState";
import { Controller } from "./Controller";
import { IconConverter } from "./IconConverter";
import { SessionStorageManager } from "./StorageManager";
import { Logger } from "./Logger";
import { MSALHelper } from "./Helpers/MSALHelper";
import { NavigationInfo, ViewType } from "./NavigationInfo";
import { PageInfo } from "./PageInfo";


export interface SideBarItem {
  Name: string;
  ImageCSSClassName: string;
  EntityName: string;
  Action: string;
  URI: string;
  Key?: string;
}

export interface SideBarGroup {
  Name: string;
  ImageCSSClassName: string;
  Items: SideBarItem[];
}

export interface SideBarTab {
  Name: string;
  ImageCSSClassName: string;
  Groups: SideBarGroup[];
}

export interface SideBarData {
  Tabs: SideBarTab[];
}

interface SideBarProps {
    applicationName : string;
    isExpanded : boolean;
    onNavigationChanged? : (nav : NavigationInfo) => void;
    PageInfo: PageInfo;
}

interface SideBarState {
    tabIndex : number;
    isExpanded : boolean;
    width : number;
    isloading : boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};



export class SideBar extends React.Component<SideBarProps, SideBarState> {
  private mData : SideBarData | undefined;
  private mTabIndex : number = 0;
  private mNavData : Array<INavLinkGroup>;
  private mNavTabs : Array<IDropdownOption>;
  constructor(props: SideBarProps) {
    super(props);
    var cs: ClientState = ClientState.CurrentState();
    this.state = {
      tabIndex : 0,
      //isExpanded: cs.IsExpanded,
      isExpanded: false,
      width : window.innerWidth,
      isloading : false,
    }

    this.mNavData = new Array<INavLinkGroup>();
    this.mNavTabs = new Array<IDropdownOption>();

    this.onNavButtonMouseOver = this.onNavButtonMouseOver.bind(this);
    this._onRenderGroupHeader = this._onRenderGroupHeader.bind(this);
    this.onTabChanged = this.onTabChanged.bind(this);
  }

  private updateDimensions = () => {
    var cs : ClientState = ClientState.CurrentState();
    if (cs.IsExpanded && window.innerWidth < 1100){
      cs.IsExpanded = false;
      ClientState.SaveState(cs);
    }
    this.setState({ width: window.innerWidth, isExpanded : cs.IsExpanded });
  };

  private reloadSideBar = () : void => {
    var that = this;
    window.addEventListener('resize', this.updateDimensions);

    var uri = '/api/v2.0/terranova/xnav/sidebar/' + this.props.applicationName;
    var cs: ClientState = ClientState.CurrentState();
    if (!cs.IsAuthenticated)
        return;

    var cr : SilentRequest = MSALHelper.getDefaultScope();
    var mh : MSALHelper = new MSALHelper();
    //debugger;
    
    if (!SessionStorageManager.containsItem('__TN_SIDEBAR__' + that.props.applicationName + '__')){
      that.setState({isloading : true});
      mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
          //debugger;
          that.setState({isloading : false});
          console.log(JSON.stringify(res));
          that.mData = res as SideBarData;
          that.mTabIndex = 0;
          console.log(that.mData);
          SessionStorageManager.setItem('__TN_SIDEBAR__'  + that.props.applicationName + '__', JSON.stringify(res));
          that.refreshSideBar();
      }).catch(function(error: any){
        Logger.logError('Failed to load SideBar data : ' + error.message);
      })
    }
    else{
      that.mData = SessionStorageManager.getItemAsObject<SideBarData>('__TN_SIDEBAR__'  + that.props.applicationName + '__');
      that.mTabIndex = 0;
      that.refreshSideBar();
    }
  }
  private reloadSideBar2 = () : void => {
    var that = this;
    window.addEventListener('resize', this.updateDimensions);

    var uri = `/api/v2.0/terranova/xnav/${this.props.PageInfo.TenantId}/${this.props.PageInfo.AppName}/sidebar`;
    var cs: ClientState = ClientState.CurrentState();
    if (!cs.IsAuthenticated)
        return;

    var cr : SilentRequest = MSALHelper.getDefaultScope();
    var mh : MSALHelper = new MSALHelper();
    //debugger;
    
    if (!SessionStorageManager.containsItem('__TN_SIDEBAR__' + that.props.PageInfo.AppName + '__')){
      that.setState({isloading : true});
      mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
          //debugger;
          that.setState({isloading : false});
          console.log(JSON.stringify(res));
          that.mData = res as SideBarData;
          that.mTabIndex = 0;
          console.log(that.mData);
          SessionStorageManager.setItem('__TN_SIDEBAR__'  + that.props.PageInfo.AppName + '__', JSON.stringify(res));
          that.refreshSideBar();
      }).catch(function(error: any){
        Logger.logError('Failed to load SideBar data : ' + error.message);
      })
    }
    else{
      that.mData = SessionStorageManager.getItemAsObject<SideBarData>('__TN_SIDEBAR__'  + that.props.PageInfo.AppName + '__');
      that.mTabIndex = 0;
      that.refreshSideBar();
    }
  }


  componentDidMount() {
    this.reloadSideBar();
  }
  // private onSideNavLinkClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, item?: INavLink | undefined) : void => {
  //   //alert(item?.key);
  // }
  componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
  }

  private refreshSideBar = () : void =>{
    var that = this;
    var cs : ClientState = ClientState.CurrentState();
    if (this.mData && this.mData.Tabs  && this.mTabIndex < this.mData.Tabs.length && cs && cs.UserProfile){
      var tab : SideBarTab = this.mData.Tabs[this.mTabIndex];
      if (tab && tab.Groups){
        var grps : Array<INavLinkGroup> = new Array<INavLinkGroup>();
        for(var i = 0; i<tab.Groups.length; i++){
          var grp : INavLinkGroup = {
            name : tab.Groups[i].Name,
            links : new Array<INavLink>()
          }
          if (tab.Groups[i].Items){
            for(var j = 0; j<tab.Groups[i].Items.length; j++){
              tab.Groups[i].Items[j].Key = nextId('sidebar-nav-item-');
              var targetURI : string = '';
              if (tab.Groups[i].Items[j].URI){
                targetURI = tab.Groups[i].Items[j].URI.replace('/view/', '/cview/' + cs.UserProfile.TerraNovaTenantUniqueName + '/');
              }

              var lnk : INavLink = {
                name  : tab.Groups[i].Items[j].Name.replace(' ',''),
                ariaLabel : tab.Groups[i].Items[j].Name,
                //key : tab.Groups[i].Items[j].Action,
                key : tab.Groups[i].Items[j].Key,
                title : tab.Groups[i].Items[j].Name,
                //url : '#',
                url : targetURI ?? '~/' + tab.Groups[i].Items[j].Key!,
                ariaCurrent: 'step',
                //url : tab.Groups[i].Items[j].URI,
                forceAnchor: true,
                //expandAriaLabel: tab.Groups[i].Items[j].ImageCSSClassName,
                icon : IconConverter.convertToFluentUiIcon(tab.Groups[i].Items[j].ImageCSSClassName),
                isExpanded: true,
              }
              grp.links.push(lnk);
            }
          }
          grps.push(grp);
        }
        for(var q = 0; q<grps.length; q++){
          for(var s = 0; s<grps[q].links.length; s++){
            //grps[i].links[j].onClick = this.onSideNavLinkClicked.bind(this);
            //grps[i].links[j].forceAnchor = false;
          }
        }
        that.mNavData = grps;
      }
      that.mNavTabs = new Array<IDropdownOption>();
      for(var t= 0; t<that.mData!.Tabs.length; t++){
        that.mNavTabs.push({key: t, text: that.mData!.Tabs[t].Name})
      }

      that.forceUpdate();
    }
  }

  private renderNavBar = (bIsExpanded: boolean) : Array<INavLinkGroup> =>{
    let res :  Array<INavLinkGroup> = new  Array<INavLinkGroup>();

    var that = this;
    var cs : ClientState = ClientState.CurrentState();
    if (this.mData && this.mData.Tabs  && this.mTabIndex < this.mData.Tabs.length && cs && cs.UserProfile){
      var tab : SideBarTab = this.mData.Tabs[this.mTabIndex];
      if (tab && tab.Groups){
        var grps : Array<INavLinkGroup> = new Array<INavLinkGroup>();
        for(var i = 0; i<tab.Groups.length; i++){
          var grp : INavLinkGroup = {
            name : tab.Groups[i].Name,
            links : new Array<INavLink>()
          }
          if (tab.Groups[i].Items){
            for(var j = 0; j<tab.Groups[i].Items.length; j++){
              tab.Groups[i].Items[j].Key = nextId('sidebar-nav-item-');
              var targetURI : string = '';
              if (tab.Groups[i].Items[j].URI){
                targetURI = tab.Groups[i].Items[j].URI.replace('/view/', '/cview/' + cs.UserProfile.TerraNovaTenantUniqueName + '/');
              }

              var lnk : INavLink = {
                name  : tab.Groups[i].Items[j].Name.replace(' ',''),
                ariaLabel : tab.Groups[i].Items[j].Name,
                //key : tab.Groups[i].Items[j].Action,
                key : tab.Groups[i].Items[j].Key,
                title : tab.Groups[i].Items[j].Name,
                //url : '#',
                url : targetURI ?? '~/' + tab.Groups[i].Items[j].Key!,
                ariaCurrent: 'step',
                //url : tab.Groups[i].Items[j].URI,
                forceAnchor: true,
                //expandAriaLabel: tab.Groups[i].Items[j].ImageCSSClassName,
                icon : IconConverter.convertToFluentUiIcon(tab.Groups[i].Items[j].ImageCSSClassName),
                isExpanded: true,
              }
              grp.links.push(lnk);
            }
          }
          grps.push(grp);
        }
        for(var q = 0; q<grps.length; q++){
          for(var s = 0; s<grps[q].links.length; s++){
            //grps[i].links[j].onClick = this.onSideNavLinkClicked.bind(this);
            //grps[i].links[j].forceAnchor = false;
          }
        }
        //that.mNavData = grps;
        res = grps;
      }
      that.mNavTabs = new Array<IDropdownOption>();
      for(var t= 0; t<that.mData!.Tabs.length; t++){
        that.mNavTabs.push({key: t, text: that.mData!.Tabs[t].Name})
      }

      //that.forceUpdate();
    }
    return res;
  }

 
  private _onRenderGroupHeader = (props?: IRenderGroupHeaderProps,defaultRender?: (props?: IRenderGroupHeaderProps) => JSX.Element | null): JSX.Element | null => {
    var cs: ClientState = ClientState.CurrentState();
    //if (this.state.isExpanded){
    //if (cs.IsExpanded){
    if (this.state.isExpanded){
      return(
        <h3 className='tn-sidenav-groupheader'>{props?.name}</h3>
        );
    }
    else{
      return (<h3></h3>);
    }
  };

  private onNavButtonMouseOver = (event : any): void  => {
    this.setState({isExpanded : !this.state.isExpanded});
  }
  private _onRenderLink = (props?: INavLink,defaultRender?: (props?: INavLink) => JSX.Element | null): JSX.Element | null => {
    // var style : CSSProperties = {
    //   fontFamily : 'FabricMDL2Icons'
    // }
    var cs: ClientState = ClientState.CurrentState();
    if (cs.IsExpanded){
      //return (<div title={props?.name} className="ms-Nav-compositeLink "><button type="button" className="ms-Button ms-Button--action ms-Button--command ms-Nav-link link-108" title={props?.name} aria-label={props?.name} data-is-focusable="false" tabIndex={0}><span className="ms-Button-flexContainer flexContainer-109" data-automationid="splitbuttonprimary"><i data-icon-name={props?.icon} aria-hidden="true" className="ms-Icon root-37 css-73 ms-Button-icon icon-111" style={style}></i><div className="ms-Nav-linkText linkText-77">{props?.name}</div></span></button></div>);
      return (<div title={props?.name} className="ms-Nav-compositeLink "><span >{props?.name}- xx</span></div>);
    }
    else{
      return (<div>?</div>);
      //return (<div title={props?.name} onMouseOver={this.onNavButtonMouseOver} className="ms-Nav-compositeLink "><button type="button" className="ms-Button ms-Button--action ms-Button--command ms-Nav-link link-108" title={props?.name} aria-label={props?.name} data-is-focusable="false" tabIndex={0}><span className="ms-Button-flexContainer flexContainer-109" data-automationid="splitbuttonprimary"><i data-icon-name={props?.icon} aria-hidden="true" className="ms-Icon root-37 css-73 ms-Button-icon icon-111" style={style}></i></span></button></div>);
    }
  };

  private onExpandCollapseMenu = (ev?: any) : void =>{
    let isExpanded: boolean = this.state.isExpanded;
    isExpanded = !isExpanded;
    let cs: ClientState = ClientState.CurrentState();
    cs.IsExpanded = isExpanded;
    ClientState.SaveState(cs);
    this.setState({isExpanded: isExpanded});
    this.refreshSideBar();
    
  }

  private onLostPointerCapture = (ev? :any) : void =>{
    Logger.logNotification('SideBar lost pointer capture');
    this.setState({isExpanded: false});
    this.forceUpdate();
  }

  private onMouseEnter = (ev?: any): void =>{
    console.log("OnMouseEnter");
    this.setState({isExpanded: true});
  }
  private onMouseLeave = (ev?: any): void =>{
    console.log("OnMouseLeave");
    this.setState({isExpanded: false});
    
  }

  render() {
    var that = this;

    var menuHeight: number = window.innerHeight - 44;
    
    var cs: ClientState = ClientState.CurrentState();
    console.log('Rendering SideBar');
    return (
      <Stack verticalFill styles={{root: {zIndex: 1000, backgroundColor: '#F7F7F7'}}} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} >
        <div style={{ boxShadow: Depths.depth8, backgroundColor: '#F7F7F7' }} >
        {this.state.isloading && 
          <Stack horizontal horizontalAlign="stretch">
            <Spinner size={SpinnerSize.large} />
          </Stack>
        }
        {/* <IconButton style={{marginLeft:'0px', paddingLeft: '15px'}} iconProps={{iconName: 'CollapseMenu'}} onClick={that.onExpandCollapseMenu} ></IconButton> */}
        <Nav
          onRenderGroupHeader={this._onRenderGroupHeader}
          ariaLabel="Application Menu"
          //groups={this.mNavData}
          groups={this.renderNavBar(that.state.isExpanded)}
          className={this.state.isExpanded ? 'tn-sidenav-expanded nav' : 'tn-sidenav-collapsed nav'}
          //className={'tn-sidenav-expanded nav' }
          //className={'sidenav'}
          //onRenderLink={this._onRenderLink}
          onLinkClick={that.onNavLinkClicked}
          isOnTop={true}
          linkAs={(props : any) => {
            return <a className={props.className} style={{color: 'inherit', boxSizing: 'border-box'}} href={props.href}  data-key={props.key} onClick={that.onSideBarNavLinkClicked}>
              <span style={{display: 'flex'}}>
               { !!props.iconProps && <Icon style={{margin: '0 4px'}} {...props.iconProps} /> }
               {props.children}
               </span>
         
             </a> ;
           }}
           styles={
             {
               root: {
                 backgroundColor: '#F7F7F7', 
                 //height: '100vh', 
                 height: '95vh'
              },
              }
            }
          //styles={navStyles}
          
        />
        <Dropdown
          placeholder="Select an option"
          label=""
          options={this.mNavTabs}
          styles={dropdownStyles}
          //className={cs.IsExpanded ? 'tn-sidenav-tabpicker-expanded' : 'tn-sidenav-tabpicker-collapsed'}
          className={that.state.isExpanded ? 'tn-sidenav-tabpicker-expanded' : 'tn-sidenav-tabpicker-collapsed'}
          selectedKey={that.mTabIndex}
          onChange={that.onTabChanged}
        />
        </div>
      </Stack>
    );
  }
  private onTabChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
    var that = this;
    if (typeof index !== 'undefined' &&  index >= 0){
      that.mTabIndex = index;
      that.refreshSideBar();
    }
  }

  private onNavLinkClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, item?: INavLink | undefined) : void => {
    alert(item?.key);
    ev?.preventDefault();
  }

  private findItem = (itemId: string): SideBarItem | undefined => {
    if (this && this.mData){
      for(var t = 0; t<(this.mData.Tabs?this.mData.Tabs.length:0); t++){
        for(var g = 0; g<(this.mData.Tabs[t].Groups?this.mData.Tabs[t].Groups.length:0); g++){
          for(var i= 0; i<(this.mData.Tabs[t].Groups[g].Items?this.mData.Tabs[t].Groups[g].Items.length:0); i++){
            if (this.mData.Tabs[t].Groups[g].Items[i].Key === itemId){
              return this.mData.Tabs[t].Groups[g].Items[i];
            }
          }
        }
      }
    }
    //Controller.RedirectToURI(itemId);
  }

  private onSideBarNavLinkClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, item?: INavLink | any | undefined) : void => {
    if (ev)
      ev.preventDefault();
    var itemId : string = (ev?.currentTarget as any).href;
    if (itemId && this.mData){
      if (itemId.startsWith('http') || itemId.startsWith('https')){
        Controller.RedirectToURI(itemId, this.props.PageInfo);
        return;
      }
      else if (itemId.indexOf('~/') !== -1)
        itemId = itemId.substr(itemId.indexOf('~/') + 2);
        var it : SideBarItem | undefined = this.findItem(itemId);
        
        if (it && this.props.onNavigationChanged){
          var tp : ViewType = 'other';
          if (it!.Action && it!.Action.toLowerCase() === 'edit')
            tp = 'edit';
          else if (it!.Action && (it!.Action.toLowerCase() === 'view' || it!.Action.toLowerCase() === 'list'))
            tp = 'view';
          else if (it!.Action && (it!.Action.toLowerCase() === 'create' || it!.Action.toLowerCase() === 'add'))
            tp = 'add';
          this.props.onNavigationChanged({ EntityName : it!.EntityName, ViewType : tp});
        }
    }

    
  }
}
