import {  CommandBar, ICommandBarItemProps, IDropdownStyles, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React from "react";
import { EntityFormResourceDefinition } from "../../Entities/EntityFormResource";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { EntityFormDefinition2 } from "../../Entities/EntityForm";
import { SectionControl } from "../../../SectionControl";

interface EntityFormScriptSourceSurfaceEditorProps {
    Res: EntityFormResourceDefinition;
    isReadOnly : boolean;
    close: () => void;
    tenantId: string;
    appId: string;
    entityId: string;
    Form: EntityFormDefinition2;
}

interface EntityFormScriptSourceSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage?: string;
  isbusy : boolean;
  isReadOnly : boolean;
  barItems: ICommandBarItemProps[];
  jsString?: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};

export class EntityFormScriptSourceSurfaceEditorControl extends React.Component<EntityFormScriptSourceSurfaceEditorProps, EntityFormScriptSourceSurfaceEditorState> {
  private mCurrentJS: string = '';
    constructor(props: EntityFormScriptSourceSurfaceEditorProps) {
      super(props);
      this.mCurrentJS = props.Res?.ResourceData ?? '';
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        barItems: new Array<ICommandBarItemProps>(),
        jsString: this.mCurrentJS
      }
  
    }
    componentDidMount() {
      this.buildMenuItems();
    }

    private buildMenuItems = () : void => {
      let that = this;
      let mi: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
      mi.push({
        key: 'save',
        text: 'Save',
        ariaLabel: 'Save',
        iconOnly: true,
        iconProps: {iconName: 'Save'},
        onClick: that.onSaveJavascript
      })
      mi.push({
        key: 'refresh',
        text: 'Reload',
        ariaLabel: 'Reload',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: that.onRefreshJavascript
      })
      mi.push({
        key: 'power',
        text: 'Off',
        ariaLabel: 'Off',
        iconOnly: true,
        iconProps: {iconName: 'PowerButton'},
        onClick: that.onQuitEdit
      })
      that.setState({barItems: mi});
    }
    private onRefreshJavascript = (evt?: any) : void => {
      this.setState({jsString: this.props.Res.ResourceData});
    }
    private onQuitEdit = (evt?: any) : void => {
      this.props.close();
    }
    private onSaveJavascript = (evt?: any) : void => {
      if (!this.mCurrentJS){
        this.setState({errorMessage: 'Error parsing JavaScript.'});
        return;
      }
      try{
        this.props.Res.ResourceData = this.mCurrentJS;

        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        let that = this;
        if (this.props.Res.Id){
          mh.updateEntityFormResource(this.props.tenantId, this.props.appId, this.props.entityId, this.props.Form, this.props.Res)
            .then(function(saveRes: EntityFormResourceDefinition){
              that.setState({notificationMessage: 'Script Saved'});
            })
            .catch(function(err: any){
              that.setState({errorMessage: `Failed to save script: ${err?.message}`});
            })
        }
        else{
          mh.createNewFormResource(this.props.tenantId, this.props.appId, this.props.entityId, this.props.Form, this.props.Res)
          .then(function(saveRes: EntityFormResourceDefinition){
            that.setState({notificationMessage: 'Script Saved'});
          })
          .catch(function(err: any){
            that.setState({errorMessage: `Failed to save script: ${err?.message}`});
          })
        }
      }
      catch(error: any){
        this.setState({errorMessage: 'ERROR: ' + error?.message});
        return;
      }
    }
    private handleEditorChange = (value: string | undefined, event: any) : void => {
      if (value){
        //this.props.updateXml(value);
        this.mCurrentJS = value;
        this.setState({jsString: value});
      }
    }
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign="stretch" style={{minWidth: '1200px'}}>
          <SectionControl headerText={`Script Source - ${this.props.Res.DisplayName}` }>
            
          </SectionControl>
          <CommandBar
            items={this.state.barItems}
            overflowButtonProps={{ ariaLabel: 'More commands' }}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          {this.state.notificationMessage &&
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.notificationMessage}
            </MessageBar>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.errorMessage}
            </MessageBar>
          }
          <Editor
              height="90vh"
              defaultLanguage="javascript"
              defaultValue={this.state.jsString}
              //wrapperProps={{wordWrap: 'on', wordWrapColumn: 80}}
              onChange={this.handleEditorChange}
              
            />
        </Stack>
      );
    }


  }
