import { IContextualMenuItem, IContextualMenuProps, Stack, TextField } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";

interface DummyEditorProps {
    Prop? : EntityPropertyDefinition;
    Editor : IllerisNinthUI.TNEditorBase;
    updateParent : () => void;
    getEntity : () => EntityDefinition;
    showEditorProperties: (ed : IllerisNinthUI.TNEditorBase, prop? : EntityPropertyDefinition) => void;
}

interface DummyEditorState extends DummyEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ctxMenuItems : IContextualMenuProps;
}

export class DummyEditorControl extends React.Component<DummyEditorProps, DummyEditorState> {
    constructor(props: DummyEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        Prop : props.Prop,
        Editor: props.Editor,
        showEditorProperties : props.showEditorProperties
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }

    // private removeRow = (evt : any) : void =>{
    //     var sect : IllerisNinthUI.TNSection = this.state.Section;
    //     var rowIndex :number = this.state.colIdx;
    //     sect.Rows.splice(rowIndex, 1);
    //     this.props.updateParent();
    // }
    // private addRow = (evt : any) : void =>{
    //     var sect : IllerisNinthUI.TNSection = this.state.Section;
    //     var rowIdx :number = this.state.colIdx;
    //     var newRow : IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
    //     sect.Rows.splice(rowIdx+1,0, newRow);
    //     this.props.updateParent();
    // }
    private buildCommandbar = () : void =>{
        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();
        // cm.items.push({
        //   key: 'removerow',
        //   text : 'Remove This Row',
        //   iconProps : {iconName : 'Delete'},
        //   onClick: this.removeRow.bind(that)
        // });
        // cm.items.push({
        //   key: 'addrow',
        //   text: 'Add Row',
        //   cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
        //   iconProps: { iconName: 'Add'},
        //   onClick: this.addRow.bind(that)
        // });
        // cm.items.push({
        //     key: 'separator1',
        //     itemType: ContextualMenuItemType.Divider,
        //   });

        this.setState({ctxMenuItems : cm});
    }


    // private onDragOver = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         //var dataObj : DesignSurfaceDragInfo = (item.dataTransfer.getData('text/plain')) as DesignSurfaceDragInfo;
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     item.dataTransfer.dropEffect = 'move';
    //                     return;
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    //     //console.log('onDragOver');
    // }
    // private onDragDrop = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     if (dataObj.ItemName === 'tabcontrol'){
    //                         let tc : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
    //                         var frm : IllerisNinthUI.Formlet = this.state.Form;
    //                         if (!frm.Tabs){
    //                             frm.Tabs = new Array<IllerisNinthUI.TNTab>();
    //                         }
    //                         frm.Tabs.push(tc);
    //                         this.forceUpdate();
    //                     }
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    // }

    private onDoubleClickEditor = (evt : any ) : void =>{
        debugger;
        this.props.showEditorProperties(this.state.Editor, this.state.Prop);
    }
    
    render() {
      return (
        <Stack horizontalAlign='stretch' verticalFill className='tn-designer-dummyeditor'  style={{width: '100%'}}>
            <TextField label={this.props.Editor.LabelText} readOnly={true} onDoubleClick={this.onDoubleClickEditor} style={{width: '100%'}} ></TextField>
        </Stack>

      );
    }
  }
  