import { ICommandBarItemProps, IconButton, IContextualMenuItem, IContextualMenuProps, Stack } from "@fluentui/react";
import React from "react";
import { EntityHelper } from "../../Helpers/EntityHelper";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { DesignSurfaceDragInfo } from "../DragInfo";
import { EntityDefinition } from "../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";
import { EntityRelationDefinition } from "../Entities/EntityRelation";
import { DesignerHelper } from "./DesignerHelper";
import { DummyContainerControlControl } from "./DummyContainerControl";
import { DummyEditorControl } from "./DummyEditor";
import { DummyFlexGridControlControl } from "./DummyFlexGridControl";
import { SurfaceBase } from "./SurfaceBase";

interface ControlSurfaceProps extends SurfaceBase{
    Row : IllerisNinthUI.FormletRow;
    Column : IllerisNinthUI.FormletColumn;
    colIdx : number;
    rowIdx : number;
    updateParent : () => void;
    getEntity : () => EntityDefinition;
    renderKey: number;
}

interface ControlSurfaceState extends ControlSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
  ControlProperty? : EntityPropertyDefinition;
  ControlEditor? : IllerisNinthUI.ElementBase;
}

export class ControlSurfaceControl extends React.Component<ControlSurfaceProps, ControlSurfaceState> {
    constructor(props: ControlSurfaceProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Row : props.Row,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        colIdx : props.colIdx,
        rowIdx : props.rowIdx,
        Column : props.Column,
        updateParent : props.updateParent,
        showProperties : props.showProperties,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        renderKey: props.renderKey,
        appInfo : props.appInfo
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : ControlSurfaceProps, prevState: ControlSurfaceState){
        if (prevProps.renderKey !== this.props.renderKey){
          return true;
        }
        else{
          return false;
        }
    }
    private editProperties = (evt : any) : void =>{
        debugger;
        var eb : IllerisNinthUI.TNEditorBase | null = this.state.Column.Items.length === 0 ? null : this.state.Column.Items[0];
        //var ep : EntityPropertyDefinition = this.state.ControlProperty;
        if (eb && this.props.showProperties){
            this.props.showProperties(eb);
        }
    }
    // private addRow = (evt : any) : void =>{
    //     var sect : IllerisNinthUI.TNSection = this.state.Section;
    //     var rowIdx :number = this.state.colIdx;
    //     var newRow : IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
    //     sect.Rows.splice(rowIdx+1,0, newRow);
    //     this.props.updateParent();
    // }
    private buildCommandbar = () : void =>{
        let that = this;
        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();
          cm.items.push({
            key: 'editsection',
            text: 'Edit Properties',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
            iconProps: { iconName: 'Edit'},
            onClick: this.editProperties.bind(that)
          });
        this.setState({ctxMenuItems : cm});
      }

    private onDragOver = (item?: any, event?: DragEvent) : void =>{
        item.preventDefault();
    }

    private onDrop = (item?: any, event?: DragEvent) : void =>{
        if (item && item.dataTransfer){
            item.dataTransfer.dropEffect = 'move';
            var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();

            if (dataObjStr){
                var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
                if (dataObj){
                    if (dataObj.DragItemType && dataObj.DragItemType === 'property'){
                        let en : string = dataObj.ItemName;
                        let ed : EntityDefinition = this.props.getEntity();
                        let prop : EntityPropertyDefinition | undefined = ed.Properties?.find (z => z.DisplayName === en);
                        if (prop){
                            var eb : any = DesignerHelper.buildEditor(prop);
                            if (eb){
                                var col : IllerisNinthUI.FormletColumn = this.state.Column;
                                if (col.Items.length !== 0){
                                    col.Items = new Array<any>();
                                }
                                col.Items.push(eb);
                                this.setState({Column : col, ControlProperty : prop, ControlEditor: undefined});
                                this.forceUpdate();
                            }
                        }
                    }
                    else if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
                        let en : string = dataObj.ItemName;
                        var ctrl : IllerisNinthUI.ElementBase | null = DesignerHelper.buildControl(en);
                        if (ctrl){
                            var col2 : IllerisNinthUI.FormletColumn = this.state.Column;
                            if (col2.Items.length !== 0){
                                col2.Items = new Array<any>();
                            }
                            col2.Items.push(ctrl);
                            this.setState({Column : col2, ControlEditor: ctrl, ControlProperty: undefined});
                            this.forceUpdate();
                        }
                    }
                    else if (dataObj.DragItemType && dataObj.DragItemType === 'relation'){
                        let relName : string = dataObj.ItemName;
                        let erd : EntityRelationDefinition | undefined = this.props.getEntity()?.Relations?.find(z => z.LogicalName === relName);
                        let ctrl : IllerisNinthUI.FlexGrid | null = DesignerHelper.buildGrid(relName, this.props.getEntity(), erd, this.props.appInfo.AppCode);
                        if (ctrl){
                            ctrl.Name = relName;
                            let ent: EntityDefinition = this.props.getEntity()
                            if (ent.Relations){
                                let er: EntityRelationDefinition | undefined = ent.Relations.find(z => z.DisplayName === relName);
                                if (er){
                                    ctrl.EntityName = er.TargetEntityName;
                                    ctrl.FKFieldName = er.ChildPropertyLogicalName;
                                }

                            }
                            var col3 : IllerisNinthUI.FormletColumn = this.state.Column;
                            if (col3.Items.length !== 0){
                                col3.Items = new Array<any>();
                            }
                            col3.Items.push(ctrl);
                            this.setState({Column : col3, ControlEditor: ctrl, ControlProperty: undefined});
                            this.forceUpdate();
                        }
                        
                    }
                }
                
            }
        }
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private showEditorProperties = (ed : IllerisNinthUI.ElementBase, prop? : EntityPropertyDefinition) : void =>{
        if (this.props.showProperties){
            this.props.showProperties(ed, prop);
        }
    }
    
    render() {
        var that = this;
      return (
        <Stack horizontalAlign='stretch' verticalFill className='tn-designer-controlsurface' onDrop={this.onDrop} onDragOver={this.onDragOver} >
            <div style={{width: '100%', minHeight:'30px', backgroundColor: 'rgb(248, 248, 248)' }} onDrop={this.onDrop} onDragOver={this.onDragOver} >
                <Stack horizontal horizontalAlign='stretch'>
                    {this.state.Column.Items.map(function(item : any, index: number){
                        if (item.getElementType() === 'tneditorbase' ){
                            return(<DummyEditorControl key={index} Prop={that.state.ControlProperty} Editor={item as IllerisNinthUI.TNEditorBase} updateParent={that.updateParent} getEntity={that.props.getEntity} showEditorProperties={that.showEditorProperties}  ></DummyEditorControl>)
                        }
                        else if (item.getElementType() === 'tncontainer' ){
                            return (<DummyContainerControlControl key={index} Container={item as IllerisNinthUI.TNContainer} updateParent={that.updateParent} getEntity={that.props.getEntity} showEditorProperties={that.showEditorProperties}></DummyContainerControlControl>)
                        }
                        else if (item.getElementType() === 'tnflexgrid' ){
                            return (<DummyFlexGridControlControl key={index} Grid={item as IllerisNinthUI.FlexGrid} updateParent={that.updateParent} getEntity={that.props.getEntity} showEditorProperties={that.showEditorProperties}></DummyFlexGridControlControl>)
                        }
                        else{
                            return (<div key={index}></div>);
                        }
                    })
                        
                    }
                    {this.state.Column.Items.length > 0 && 
                        <IconButton
                            menuProps={this.state.ctxMenuItems}
                            iconProps={{iconName : 'Info'}}
                            title="Info"
                            ariaLabel="Info"
                            disabled={false}
                            checked={false}
                        />       
                    }
                </Stack>
            </div>
        </Stack>

      );
    }
  }
  