export class EntityRelationDefinition{
    public EntityId : string = '';
    public Id : string = '';
    public ApplicationId : string = '';
    public DisplayName : string = '';
    public LogicalName: string = '';
    public ParentPropertyName: string = '';
    public ParentPropertyLogicalName: string = '';
    public ChildPropertyLogicalName: string = '';
    public ChildPropertyName: string = '';
    public TargetEntityId : string = '';
    public TargetEntityName : string = '';
    public RelationType : string = '';
    public Level: number = 1000;
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;
}