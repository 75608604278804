import {  CommandBar, ContextualMenuItemType, DefaultButton, ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps, Label, MessageBar, MessageBarType, Panel, PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { SurfaceBase } from "./SurfaceBase";

interface ActionBarSurfaceProps extends SurfaceBase{
    Bar? : IllerisNinthUI.ActionButtonBar | null;
    refreshActionBar: (bUpdateState: boolean) => IllerisNinthUI.ActionButtonBar;
    renderKey: number;
}

interface ActionBarSurfaceState extends ActionBarSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
  isPanelOpen: boolean;
  currentBarItem: IllerisNinthUI.ActionButton | null;
  ctxButtonItems?: IContextualMenuProps;
  
}

export class ActionBarSurfaceControl extends React.Component<ActionBarSurfaceProps, ActionBarSurfaceState> {
    private mCurrentBar: IllerisNinthUI.ActionButtonBar | null;
    private mContextMenu?: IContextualMenuProps;
    constructor(props: ActionBarSurfaceProps) {
      super(props);
      this.mCurrentBar = props.Bar ?? null;
      
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Bar : props.Bar,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        isPanelOpen: false,
        currentBarItem: null,
        refreshActionBar: this.props.refreshActionBar,
        ctxButtonItems: { items: new Array<IContextualMenuItem>()},
        renderKey: props.renderKey,
        appInfo: props.appInfo
      }
  
    }
  
    componentDidMount() {
        this.buildItems();
    }
    componentDidUpdate(prevProps : ActionBarSurfaceProps, prevState: ActionBarSurfaceState){
      if (prevProps.renderKey !== this.props.renderKey){
        return true;
      }
      else{
        return false;
      }
    }
    
    private buildItems = (bar? : IllerisNinthUI.ActionButtonBar) : ICommandBarItemProps[] => {
      let that = this;
      let res: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
      if (bar){
        bar.Buttons.sort((a, b) => a.Order < b.Order? -1: 1).forEach(function(item: IllerisNinthUI.ActionButton, idx: number){
          if (item.IsVisible && item.IsEnabled){
            res.push({
              key: idx.toString(),
              text: item.Label,
              ariaLabel: item.Label,
              iconProps: {iconName: item.ImageCSSClassName},
              disabled: (!item.IsEnabled  || !item.IsVisible),
              iconOnly: true,
              onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, btnItem?: IContextualMenuItem | undefined)  => { that.onBtnClick(item);}
            })
          }
        })
        let cmi: IContextualMenuProps = { items: new Array<IContextualMenuItem>()};
        cmi.items.push({key: 'sysAddNew', text: 'Add Command', iconProps: {iconName: 'Add'}});
        cmi.items.push({key: 'sysSeparator', itemType: ContextualMenuItemType.Divider});
        bar.Buttons.sort((a, b) => a.Order < b.Order? -1: 1).forEach(function(item: IllerisNinthUI.ActionButton, idx: number){
          if (item.IsEnabled === false || item.IsVisible === false){
            cmi.items.push({key: item.CommandName, text: item.Label, iconProps: {iconName: item.ImageCSSClassName}});
          }
          cmi.onItemClick = that.onCtxBtnClickHandler
        })
        
        this.mContextMenu = cmi;
        this.setState({ctxMenuItems: cmi, cmdBarItems: res});
      }
      else if (this.props.Bar && this.props.Bar.Buttons){
        this.props.Bar.Buttons.sort((a, b) => a.Order < b.Order? -1: 1).forEach(function(item: IllerisNinthUI.ActionButton, idx: number){
          if (item.IsVisible && item.IsEnabled){
            res.push({
              key: idx.toString(),
              text: item.Label,
              ariaLabel: item.Label,
              iconProps: {iconName: item.ImageCSSClassName},
              disabled: (!item.IsEnabled  || !item.IsVisible),
              iconOnly: true,
              onClick: (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, btnItem?: IContextualMenuItem | undefined)  => { that.onBtnClick(item);}
            })
          }
        })
        let cmi: IContextualMenuProps = { items: new Array<IContextualMenuItem>()};
        cmi.items.push({key: 'sysAddNew', text: 'Add Command', iconProps: {iconName: 'Add'}});
        cmi.items.push({key: 'sysSeparator', itemType: ContextualMenuItemType.Divider});
        this.props.Bar.Buttons.sort((a, b) => a.Order < b.Order? -1: 1).forEach(function(item: IllerisNinthUI.ActionButton, idx: number){
          if (item.IsEnabled === false || item.IsVisible === false){
            cmi.items.push({key: item.CommandName, text: item.Label, iconProps: {iconName: item.ImageCSSClassName}});
          }
          cmi.onItemClick = that.onCtxBtnClickHandler
        })
        //this.setState({ctxMenuItems: cmi, cmdBarItems: res});
        this.mContextMenu = cmi;
        this.setState({ctxMenuItems: cmi});
        this.setState({cmdBarItems: res});
      }
      return res;
    }
    private refreshBar = (bar?: IllerisNinthUI.ActionButtonBar) : void => {
      this.buildItems(bar);
      this.forceUpdate();
    }
    private deepRefreshBar = () : void =>{
      let ab: IllerisNinthUI.ActionButtonBar = this.props.refreshActionBar(false);
      if (ab){
        //this.setState({c})
        if (this.mCurrentBar){
          this.mCurrentBar.mergeWith(ab);
        }
        else{
          this.mCurrentBar = ab;
        } 
        this.forceUpdate();
      }
      this.refreshBar(this.mCurrentBar ?? ab);
    }

    private onCtxBtnClickHandler = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : void =>{
      this.onCtxBtnClick(item);
    }
    private onCtxBtnClick = (item?: IContextualMenuItem | undefined) : void => {
      if (this.props && this.props.Bar){
        let it: IllerisNinthUI.ActionButton | undefined = this.props.Bar.Buttons.find(z => z.CommandName === item?.key.toString());
        if (it){
          this.setState({currentBarItem: it, isPanelOpen: true});
        }
        else{
          it = new IllerisNinthUI.ActionButton();
          it.Type = 1;
          it.IsEnabled = true;
          it.IsVisible = true;
          it.Label = 'New Command';
          it.ToolTipText = 'New Command';
          it.CommandName = 'cmdNewCommand_' + nanoid();
          it.ImageCSSClassName = 'SurveyQuestions';
          it.Order = 100;
          this.props.Bar.Buttons.push(it);
          this.setState({currentBarItem: it, isPanelOpen: true});
        }
      }
    }

    private onBtnClick = (btn: IllerisNinthUI.ActionButton) : void => {
      debugger;
      this.setState({currentBarItem: btn, isPanelOpen: true});
    }

    private updateParent = () : void =>{
      this.forceUpdate();
    }
    render() {
      return (
        <Stack verticalFill  horizontalAlign="stretch">
          <Stack horizontal horizontalAlign="stretch">
              <Label style={{width: '180px'}}>Custom Action Bar</Label>
              <CommandBar items={this.state.cmdBarItems} style={{marginLeft:'15px', width:'100%'}}></CommandBar>
              <Stack horizontalAlign="stretch" horizontal style={{width: '200px', height: '100%'}}>
                <DefaultButton 
                  label="Refresh Bar" 
                  text="Refresh Bar" 
                  split
                  splitButtonAriaLabel="See 2 options"
                  aria-roledescription="split button"
                  onClick={(ev?: any) => {this.deepRefreshBar()}} 
                  menuProps={this.mContextMenu}
                  style={{width: '100%'}}
                  >

              </DefaultButton>
              </Stack>
              
          </Stack>
            {this.state.currentBarItem &&
              <Panel
                headerText="Edit Button Properties"
                isOpen={this.state.isPanelOpen}
                onDismiss={(ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => { this.setState({isPanelOpen: false}); this.forceUpdate()}}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
              >
                <Stack verticalFill horizontalAlign='stretch'>
                  {this.state.errorMessage && 
                      <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      onDismiss={(ev?: any) => {this.setState({errorMessage: ''}); }}
                      dismissButtonAriaLabel="Close"
                    >
                      {this.state.errorMessage}
                    </MessageBar>
                  }
                  {this.state.currentBarItem.Type !== 0 &&
                    <TextField label="Command Name" value={this.state.currentBarItem.CommandName} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.CommandName = newVal ?? ''; this.forceUpdate()}}></TextField>
                  }
                  {this.state.currentBarItem.Type === 0 &&
                    <TextField label="Command Name" value={this.state.currentBarItem.CommandName} readOnly={true} disabled={true}></TextField>
                  }
                  <TextField label="Button Label" value={this.state.currentBarItem.Label} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.Label = newVal ?? ''; this.forceUpdate()}}></TextField>
                  <TextField label="Image CSS Class" value={this.state.currentBarItem.ImageCSSClassName} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.ImageCSSClassName = newVal ?? ''; this.forceUpdate()}}></TextField>
                  <TextField label="Tooltip Text" value={this.state.currentBarItem.ToolTipText} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.ToolTipText = newVal ?? ''; this.forceUpdate()}}></TextField>
                  <TextField label="Order" type="number" value={this.state.currentBarItem.Order.toString()} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.Order = newVal? Number.parseInt(newVal) : 0; this.forceUpdate()}}></TextField>
                  <Toggle label="Is Enabled" onText="Enabled" offText="Disabled" checked={this.state.currentBarItem.IsEnabled} onChange={(ev?: any, checked?: boolean) => {this.state.currentBarItem!.IsEnabled = checked ?? false; this.forceUpdate()}}></Toggle>
                  <Toggle label="Is Visible" onText="Visible" offText="Invisible" checked={this.state.currentBarItem.IsVisible} onChange={(ev?: any, checked?: boolean) => {this.state.currentBarItem!.IsVisible = checked ?? false; this.forceUpdate()}}></Toggle>
                  <TextField label="OnClick Handler" value={this.state.currentBarItem.OnClickHandler} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.OnClickHandler = newVal?? ''; this.forceUpdate()}}></TextField>
                  <TextField label="Redirect URI" value={this.state.currentBarItem.RedirectURI} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.RedirectURI = newVal?? ''; this.forceUpdate()}}></TextField>
                  <TextField label="Visible On Statecodes" value={this.state.currentBarItem.VisibleOnStateCodes} onChange={(ev?: any, newVal?: string) => {this.state.currentBarItem!.VisibleOnStateCodes = newVal?? ''; this.forceUpdate()}}></TextField>
                  <PrimaryButton text="Update" label="Update" ariaLabel="Update" iconProps={{iconName: 'Save'}} onClick={this.updateButtonDetails} style={{marginTop: '15px'}}  ></PrimaryButton>
                  {this.state.currentBarItem && this.state.currentBarItem.Type !== 0 && 
                    <PrimaryButton text="Delete" label="Delete" ariaLabel="Delete" iconProps={{iconName: 'Delete'}} onClick={this.deleteCustomButton} style={{marginTop: '15px'}}  ></PrimaryButton>
                  }
                </Stack>
              </Panel>
            }
        </Stack>
      );
    }
    private deleteCustomButton = (ev?: any) : void => {
      if (this.state.currentBarItem && this.mCurrentBar){
        let idx: number = this.mCurrentBar.Buttons.indexOf(this.state.currentBarItem);
        if (idx && idx > -1){
          this.mCurrentBar.Buttons.splice(idx, 1);
          this.refreshBar();
          this.setState({isPanelOpen: false});
        }
      }
    }
    private updateButtonDetails = (ev?: any) : void => {
      if (this.state.currentBarItem){
        let cmdName: string = this.state.currentBarItem.CommandName;
        if (!cmdName){
          this.setState({errorMessage: 'Command name cannot be empty'});
          return
        }
        if (cmdName && cmdName.toLowerCase().startsWith('sys')){
          this.setState({errorMessage: 'Command name cannot contain "sys" prefix'});
          return
        }
        if (cmdName && cmdName.indexOf(' ') !== -1){
          this.setState({errorMessage: 'Command name cannot contain spaces'});
          return
        }
        if (this.mCurrentBar){
          for(let x= 0; x<this.mCurrentBar?.Buttons.length; x++){
            if (this.mCurrentBar.Buttons[x].CommandName?.toLowerCase() === cmdName && this.mCurrentBar.Buttons[x].Id !== this.state.currentBarItem.Id && this.state.currentBarItem.Type !== 0){
              this.setState({errorMessage: 'This command name is already in use.'});
              return
            }
          }
        }
      }
      this.refreshBar();
      this.setState({isPanelOpen: false});
    }
  }
  

