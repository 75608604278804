import {  ComboBox, IButtonProps, IComboBox, IComboBoxOption, ICommandBarItemProps, IDropdownOption, Stack, TextField, Toggle } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { SurfaceEditorBase } from "../SurfaceBase";

interface ContainerControlSurfaceEditorProps extends SurfaceEditorBase {
    Form : IllerisNinthUI.Formlet;
    Tab?: IllerisNinthUI.TNTab | null;
    Section? : IllerisNinthUI.TNSection | null;
    Container : IllerisNinthUI.TNContainer;
    tabIndex : number;
    getEntity : () => EntityDefinition;
    isReadOnly : boolean;
}

interface ContainerControlSurfaceEditorState extends ContainerControlSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class ContainerControlSurfaceEditorControl extends React.Component<ContainerControlSurfaceEditorProps, ContainerControlSurfaceEditorState> {
    constructor(props: ContainerControlSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        Form : props.Form,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Section : props.Section,
        getEntity : props.getEntity,
        isReadOnly : props.isReadOnly,
        Container: props.Container,
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }

    private getTextInputEditorTypes = () : IDropdownOption[] => {
      //export type EditorType = 'text' | 'date' | 'email' | 'checkbox' | 'password' | 'color' | 'month' | 'number' | 'time' | 'url' | 'week'| 'phone' ;
      let res: IDropdownOption[] = new Array<IDropdownOption>();
      res.push({key: 'text', text: 'Text'});
      res.push({key: 'date', text: 'Date'});
      res.push({key: 'email', text: 'Email'});
      res.push({key: 'checkbox', text: 'Switch'});
      res.push({key: 'password', text: 'Password'});
      res.push({key: 'color', text: 'Color'});
      res.push({key: 'month', text: 'Month'});
      res.push({key: 'number', text: 'Number'});
      res.push({key: 'url', text: 'URL'});
      res.push({key: 'week', text: 'Week Number'});
      res.push({key: 'phone', text: 'Phone Number'});
      return res;
    }
    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  /*
  public ItemID : string = '';
        public CSSClass : string = '';
        public LabelText : string = '';
        public Name : string = '';
        public IsReadOnly : boolean = false;
        public HSBindName : string = '';
        public LabelCSSClass : string = '';
        public EditBlockCSSClass : string ='';
        public EditorCSSClass : string ='';
        public ErrorMessage : string | null = null;
  */
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >Editor Properties</div>
            {this.state.Container instanceof IllerisNinthUI.TNContainer &&
              <>
                <TextField label='Item Id' value={this.state.Container.ItemID} readOnly={true} ></TextField>
                <TextField label='Label CSS Class' value={this.state.Container.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='CSS Class' value={this.state.Container.CSSClass} readOnly={this.state.isReadOnly} onChange={this.onCSSClassChanged}  ></TextField>
              </>
            }

        </Stack>
      );
    }

    private onLabelCSSClassChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNContainer = this.state.Container;
      x.LabelCSSClass = newValue ?? '';
      this.setState({Container: x});
    }
    private onCSSClassChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNContainer = this.state.Container;
      x.CSSClass = newValue ?? '';
      this.setState({Container: x});
    }
  }
  