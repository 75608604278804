import {  ComboBox, Dropdown, IButtonProps, IComboBox, IComboBoxOption, ICommandBarItemProps, IDropdownOption, Label, MessageBar, MessageBarType, Stack, TextField, Toggle } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../../Entities/EntityProperty";
import { SurfaceEditorBase } from "../SurfaceBase";

interface FlexGridControlSurfaceEditorProps extends SurfaceEditorBase {
    Form : IllerisNinthUI.Formlet;
    Tab?: IllerisNinthUI.TNTab | null;
    Section? : IllerisNinthUI.TNSection | null;
    Grid : IllerisNinthUI.FlexGrid;
    tabIndex : number;
    getEntity : () => EntityDefinition;
    isReadOnly : boolean;
}

interface FlexGridControlSurfaceEditorState extends FlexGridControlSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  propList: Array<IDropdownOption>;
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class FlexGridControlSurfaceEditorControl extends React.Component<FlexGridControlSurfaceEditorProps, FlexGridControlSurfaceEditorState> {
    constructor(props: FlexGridControlSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        Form : props.Form,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Section : props.Section,
        getEntity : props.getEntity,
        isReadOnly : props.isReadOnly,
        Grid: props.Grid,
        propList: new Array<IDropdownOption>(),
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
        this.buildPropertiesList();
    }

    private buildPropertiesList = () : void => {
      let list: IDropdownOption[] = new Array<IDropdownOption>();
      let ent: EntityDefinition = this.props.getEntity();
      if (ent && ent.Properties){
        ent.Properties.forEach(function(prop: EntityPropertyDefinition, idx: number){
          if (prop.DataTypeString?.toLowerCase() === 'string'){
            list.push({key: prop.LogicalName, text: prop.DisplayName});
          }
        })
      }
      list.sort((a, b) => a.text < b.text ? -1 : 1);
      this.setState({propList: list});
    }
    private buildCommandbar = () : void =>{
       
    }

    private getTextInputEditorTypes = () : IDropdownOption[] => {
      //export type EditorType = 'text' | 'date' | 'email' | 'checkbox' | 'password' | 'color' | 'month' | 'number' | 'time' | 'url' | 'week'| 'phone' ;
      let res: IDropdownOption[] = new Array<IDropdownOption>();
      res.push({key: 'text', text: 'Text'});
      res.push({key: 'date', text: 'Date'});
      res.push({key: 'email', text: 'Email'});
      res.push({key: 'checkbox', text: 'Switch'});
      res.push({key: 'password', text: 'Password'});
      res.push({key: 'color', text: 'Color'});
      res.push({key: 'month', text: 'Month'});
      res.push({key: 'number', text: 'Number'});
      res.push({key: 'url', text: 'URL'});
      res.push({key: 'week', text: 'Week Number'});
      res.push({key: 'phone', text: 'Phone Number'});
      return res;
    }
    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
 
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >Editor Grid Properties</div>
            {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                  dismissButtonAriaLabel="Close"
                >
                 {that.state.errorMessage}
                </MessageBar>
              }
            {this.state.Grid instanceof IllerisNinthUI.FlexGrid &&
              <>
                <TextField label='Item Id' value={this.state.Grid.ItemID} readOnly={true} ></TextField>
                <TextField label='CSS Class' value={this.state.Grid.CSSClass} readOnly={this.state.isReadOnly} onChange={this.onCSSClassChanged}  ></TextField>
                <Toggle label='Auto Load' disabled={this.state.isReadOnly} checked={this.state.Grid.AutoLoad} onChange={this.onEnableAutoLoadChanged}  ></Toggle>
                <Toggle label='Show View Picker' disabled={this.state.isReadOnly} checked={this.state.Grid.ShowViewPicker} onChange={this.onShowViewPickerChanged}  ></Toggle>
                <TextField label='Min Rows' value={this.state.Grid.MinRows.toString()} type="number" readOnly={this.state.isReadOnly} onChange={this.onMinRowsChanged}  ></TextField>
                <Label>Search</Label>
                <Toggle label='Enable Search' disabled={this.state.isReadOnly} checked={this.state.Grid.Search.EnableSearch} onChange={this.onEnableSearchChanged}  ></Toggle>
                <Toggle label='Enable Quick Search' disabled={this.state.isReadOnly} checked={this.state.Grid.Search.ShowQuickSearch} onChange={this.onEnableQuickSearchChanged}  ></Toggle>
                <Dropdown
                  placeholder="Select fields"
                  label="Quick Search Fields"
                  selectedKeys={this.state.Grid.Search.QuickSearchFields?.split(';')}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={this.onQuickSearchFieldsChanged}
                  multiSelect
                  options={this.state.propList}
                />
                <Label>URI's</Label>
                <TextField label='Data Load URI' value={this.state.Grid.DataSource.LoadURI} readOnly={this.state.isReadOnly} onChange={this.onLoadURIChanged}  ></TextField>
                <Toggle label='Show Add Button' disabled={this.state.isReadOnly} checked={this.state.Grid.AddEvent.ShowAddButton} onChange={this.onShowAddButtonChanged}  ></Toggle>
                {this.state.Grid.AddEvent.ShowAddButton &&
                  <TextField label='Row Add URI' value={this.state.Grid.AddEvent.RowAddURI} readOnly={this.state.isReadOnly} onChange={this.onRowAddURIChanged}  ></TextField>
                }
                <Toggle label='Show Refresh Button' disabled={this.state.isReadOnly} checked={this.state.Grid.ShowRefreshButton} onChange={this.onShowRefreshButtonChanged}  ></Toggle>
                <Toggle label='Enable Paging' disabled={this.state.isReadOnly} checked={this.state.Grid.Paging?.EnablePaging} onChange={this.onEnablePagingChanged}  ></Toggle>
                {this.state.Grid.Paging?.EnablePaging &&
                  <TextField label='Page Size' type="number" value={this.state.Grid.Paging.PageSize?.toString()} readOnly={this.state.isReadOnly} onChange={this.onPageSizeChanged}  ></TextField>
                }
                <TextField label='Foreign Key Field Name' value={this.state.Grid.PKFieldName} readOnly={this.state.isReadOnly} onChange={this.onPKFieldNameChanged}  ></TextField>
                <Toggle label='Extract PK From last URI segment' disabled={this.state.isReadOnly} checked={this.state.Grid.EntityPKNameIsLastURISegment} onChange={this.onEntityPKNameIsLastURISegmentChanged}  ></Toggle>
                <TextField label='Data Source Property Name' value={this.state.Grid.DataSource.DataSourcePropertyName} readOnly={this.state.isReadOnly} onChange={this.onDSPropNameChanged}  ></TextField>
              </>
            }

        </Stack>
      );

    }
    
    private onDSPropNameChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.DataSource.DataSourcePropertyName = newValue ?? '';
      this.setState({Grid: x});
    }
    private onPKFieldNameChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.PKFieldName = newValue ?? '';
      this.setState({Grid: x});
    }
    private onPageSizeChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.Paging.PageSize = newValue ? Number.parseInt(newValue) : 25;
      this.setState({Grid: x});
    }
    
    private onEntityPKNameIsLastURISegmentChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.EntityPKNameIsLastURISegment = checked ?? false;
      this.setState({Grid: x});
    }
    private onEnablePagingChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.Paging.EnablePaging = checked ?? false;
      this.setState({Grid: x});
    }
    
    private onShowRefreshButtonChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.ShowRefreshButton = checked ?? false;
      this.setState({Grid: x});
    }
    private onShowAddButtonChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.AddEvent.ShowAddButton = checked ?? false;
      this.setState({Grid: x});
    }
    private onEnableQuickSearchChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.Search.ShowQuickSearch = checked ?? false;
      this.setState({Grid: x});
    }
    
    private onShowViewPickerChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.ShowViewPicker = checked ?? false;
      this.setState({Grid: x});
    }
    private onEnableAutoLoadChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.AutoLoad = checked ?? false;
      this.setState({Grid: x});
    }
    private onEnableSearchChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.Search.EnableSearch = checked ?? false;
      this.setState({Grid: x});
    }
    private onQuickSearchFieldsChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>{
      let that = this;
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      let props: string[] = x.Search.QuickSearchFields.split(';');
      if (props){
        if (option && option.selected){
          props.push(option.key.toString());
        }
        else if (option && !option.selected){
          let idx: number = props.indexOf(option.key.toString());
          if (idx > -1){
            props.splice(idx, 1);
          }
        }
        x.Search.QuickSearchFields = props.join(';');
      }
      //x.Search.QuickSearchFields = newValue ?? '';
      // let flds: string[] = x.Search.QuickSearchFields?.split(';');
      // if (flds){
      //   let ent: EntityDefinition = this.props.getEntity();
      //   let allProps: string[] = new Array<string>();
      //   flds.forEach(function(fld: string, index: number){
      //     if (ent.Properties && !ent.Properties.some(z => z.LogicalName === fld)){
      //       that.setState({errorMessage: `The property ${fld} does not exist in the entity, or is not a valid string.`});
      //       x.Search.QuickSearchFields = allProps.join(';');
      //       that.setState({Grid: x});
      //       that.forceUpdate();
      //       return;
      //     }
      //     allProps.push(fld);
      //   })
      // }
      this.setState({Grid: x});
      that.forceUpdate();
    }
    private onLabelCSSClassChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.CSSClass = newValue ?? '';
      this.setState({Grid: x});
    }
    private onCSSClassChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.CSSClass = newValue ?? '';
      this.setState({Grid: x});
    }
    private onMinRowsChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.MinRows = Number.parseInt(newValue ?? '10');
      this.setState({Grid: x});
    }
    
    private onRowAddURIChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.AddEvent.RowAddURI = newValue ?? '';
      this.setState({Grid: x});
    }
    private onLoadURIChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.FlexGrid = this.state.Grid;
      x.DataSource.LoadURI = newValue ?? '';
      this.setState({Grid: x});
    }
  }
  