import { Dropdown, IDropdownOption} from "@fluentui/react";
import React from "react";
import { ClientDataManager } from "../db/ClientDataManager";
import { EntityDefinition } from "../designers/Entities/EntityDefinition";
//import { ClientState } from "../ClientState";
import { DynamicEntity } from "../DynamicEntity";
import { FluentIcon } from "../FluentIcon";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { PageInfo } from "../PageInfo";

export interface TNFormletHeaderControlProps {
    EntityLogicalName: string;
    isReadOnly: boolean;
    PageInfo: PageInfo;
    Title: string;
}

export interface TNFormletHeaderControlState  {
    iconName: string;
}
  
export class TNFormletHeaderControl extends React.Component<TNFormletHeaderControlProps, TNFormletHeaderControlState> {
      constructor(props : TNFormletHeaderControlProps){
          super(props);
          
          this.state = {
            iconName : ''
          }
      }

      private getDefaultIconForEntity = (entName: string) : string => {
          let en: string = entName?.toLowerCase() ?? '';
          if (en === 'account'){
              return 'People';
          }
          else if (en === 'contact'){
            return 'Contact';
          }
          else if (en === 'product'){
            return 'Product';
          }
          else if (en === 'quote'){
            return 'Quotes';
          }
          else if (en === 'order'){
            return 'ActivateOrders';
          }
          else if (en === 'invoice'){
            return 'Invoice';
          }
          else{
            return 'ReceiptForward';
        }

      }

      private loadEntityDefinitions = () : void => {
        let cdm : ClientDataManager = new ClientDataManager(this.props.PageInfo.TenantId);
        let that = this;
        cdm.getMany('EntityDefinition', true)
            .then(function(edres: any[]){
                if (edres){
                    let ed: EntityDefinition | null | undefined = edres.find(z => z.LogicalName === that.props.EntityLogicalName);
                    if (ed && ed.ImageCSSClass){
                        that.setState({iconName: ed.ImageCSSClass});
                    }
                    else{
                        that.setState({iconName: that.getDefaultIconForEntity(that.props.EntityLogicalName)});
                    }
                    that.forceUpdate();
                }
            })
            .catch(function(err: any){
                console.log(err?.message);
            })
      }

      public componentDidMount(){
          this.loadEntityDefinitions();
      }

      public shouldComponentUpdate(nextProps: TNFormletHeaderControlProps, nextState: TNFormletHeaderControlState){
        if (nextProps.EntityLogicalName !== this.props.EntityLogicalName || nextProps.Title !== this.props.Title) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNFormletHeaderControlProps, prevState: TNFormletHeaderControlState){
        if (prevProps.EntityLogicalName !== this.props.EntityLogicalName  || prevProps.Title !== this.props.Title){
            this.loadEntityDefinitions();
        }
      }
      public render(){
          var that = this;
          var idStr : string = 'tnRecordSecurityEditRow';
         
          return (
              <>
                  <span className='tn-card-header-icon'>
                      <FluentIcon ariaLabel={this.props.Title} FAClassName={this.state.iconName} cssClassName='tn-2x' />
                  </span>
                  <span className='tn-card-header-title'>{this.props.Title}</span>
              </>
          );
      }
  }