import { EntityPersistanceManager } from "./EntityPersistanceManager";
import { EntityDefinition } from "../designers/Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../designers/Entities/EntityProperty";
import { EntityRelationDefinition } from "../designers/Entities/EntityRelation";
import { SystemApplicationInfo } from "../designers/Entities/SystemApplicationInfo";
import { AuthorProfile } from "../designers/Entities/AuthorProfile";
import { TenantInfo } from "../designers/Entities/TenantInfo";
import { EntityViewDefinition } from "../designers/Entities/EntityView";
import { EntityFormDefinition2 } from "../designers/Entities/EntityForm";
import { EntityFormResourceDefinition } from "../designers/Entities/EntityFormResource";
import { SecurityRoleDefinition } from "../designers/Entities/SecrityRole";
import { EntityPrivilegeDefinition } from "../designers/Entities/EntityPrivilegeDefinition";
import { EntityCustomPrivilegeDefinition } from "../designers/Entities/EntityCustomPrivilegeDefinition";
import { EntityPrivilegePatch } from "../designers/Entities/EntityPrivilegePatch";
import { NavigationBar } from "../designers/Entities/NavigationBar";
import { IllerisNinthAPI } from "../ServiceResult";
import { AppResource } from "../designers/Entities/AppResource";
import { promises } from "dns";

export class DesignTimeModelHelper {


    public loadTenants = async () : Promise<Array<TenantInfo>> => {
        let uri : string = 'api/v2.0/terranova/xdesigner/tenants';
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<TenantInfo> = new Array<TenantInfo>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        let ti : TenantInfo = new TenantInfo();
                        ti.Id = item.Id;
                        ti.DisplayName = item.DisplayName;
                        ti.UniqueName = item.UniqueName;
                        ti.IsActive = item.Status === 1;
                        titems.push(ti);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }

    public loadProfilesInTenant = async (tenantId? : string) : Promise<Array<AuthorProfile>> => {
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/profiles`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<AuthorProfile> = res as Array<AuthorProfile>;
                return titems;
            });
    }
    public loadProfile = async (tenantId : string, profileId: string) : Promise<AuthorProfile> => {
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/profiles/${profileId}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                if (res && res.Value){
                    return Promise.resolve(res.Value as AuthorProfile);
                }
                else if (res && res.value){
                    return Promise.resolve(res.value as AuthorProfile);
                }
                else{
                    return Promise.reject(new Error("Profile Not Found"));
                }
            });
    }
    public loadSolutionsInTenant = async (tenantId? : string) : Promise<Array<SystemApplicationInfo>> => {
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/apps`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<SystemApplicationInfo> = new Array<SystemApplicationInfo>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        let ti : SystemApplicationInfo = new SystemApplicationInfo();
                        ti.Id = item.Id;
                        ti.DisplayName = item.DisplayName;
                        ti.AppCode = item.AppCode;
                        ti.ImageCSSClass = item.ImageCSSClass;
                        ti.Order= item.Order;
                        ti.ApplicationId= item.ApplicationId;
                        ti.PublisherId = item.PublisherId;
                        ti.TileName= item.TileName;
                        ti.Version= item.Version;
                        ti.IsActive = item.Status === 1;
                        titems.push(ti);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public createNewAppIntenant = async (tenantId : string, app: SystemApplicationInfo) : Promise<SystemApplicationInfo> => {
        //let that = this;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/apps`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, (app), 'POST', true)
            .then(function(res : any){
                if (res && res.value){
                    let ti : SystemApplicationInfo = res.value as SystemApplicationInfo;
                    return ti;
                }
                else{
                    return app;
                }
            })
            
    }
    public updateAppIntenant = async (tenantId : string, app: SystemApplicationInfo) : Promise<SystemApplicationInfo> => {
        //let that = this;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/apps`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, (app), 'PUT', true)
            .then(function(res : any){
                if (res && res.value){
                    let ti : SystemApplicationInfo = res.value as SystemApplicationInfo;
                    return ti;
                }
                else{
                    return app;
                }
            })
            
    }
    public updateRoleInApp = async (tenantId : string, appid: string, role: SecurityRoleDefinition) : Promise<SecurityRoleDefinition> => {
        //let that = this;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appid}/roles/${role.Id}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, (role), 'PUT', true)
            .then(function(res : any){
                if (res && res.value){
                    let ti : SecurityRoleDefinition = res.value as SecurityRoleDefinition;
                    return ti;
                }
                else{
                    return role;
                }
            })
            
    }
    public loadEntitiesInModel = async (tenantId: string, appId : string) : Promise<Array<EntityDefinition>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/entities`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<EntityDefinition> = new Array<EntityDefinition>();
                if (res  && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        let it : EntityDefinition = new EntityDefinition();
                        it.Id = item.Id;
                        it.IsVirtual = item.IsVirtual;
                        it.DisplayName = item.DisplayName;
                        it.DisplayNamePlural = item.DisplayNamePlural;
                        it.ApplicationId= item.ApplicationId;
                        it.CreateDate= item.CreateDate;
                        it.ImageCSSClass= item.ImageCSSClass;
                        it.LastModifiedDate= item.LastModifiedDate;
                        it.LogicalName= item.LogicalName;
                        it.IsActive = item.Status === 1;
                        //if (it.ApplicationId === appId){
                            titems.push(it);
                        //}
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadNavBarsInModel = async (tenantId: string, appId : string) : Promise<Array<NavigationBar>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/navbars`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<NavigationBar> = new Array<NavigationBar>();
                if (res  && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        titems.push(item);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadAppResourceById = async (tenantId: string, appId : string, appResId: string) : Promise<AppResource> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/appresources/${appResId}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<NavigationBar> = new Array<NavigationBar>();
                if (res  && res.Value){
                    return Promise.resolve(res.Value as AppResource);
                }
                return Promise.reject(new Error('Not Found'));
            });
    }
    public loadTemplatesInApp = async (tenantId: string, appId : string) : Promise<Array<any>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/templates`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                return Promise.resolve(res);
            });
    }
    public loadResourcesInApp = async (tenantId: string, appId : string, flt: string | null = null) : Promise<Array<AppResource>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/appresources`;
        if (flt){
            uri = uri + '?name=' + flt;
        }
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                return Promise.resolve(res as AppResource[]);
            });
    }
    
    public loadSecurityRolesInModel = async (tenantId: string, appId : string) : Promise<Array<SecurityRoleDefinition>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<SecurityRoleDefinition> = new Array<SecurityRoleDefinition>();
                if (res  && Array.isArray(res)){
                    return Promise.resolve(res as SecurityRoleDefinition[]);
                }
                else{
                    return Promise.resolve(titems);
                }
            });
    }
    public loadSecurityRolesPrivileges = async (tenantId: string, appId : string, roleId: string) : Promise<Array<SecurityRoleDefinition>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles/${roleId}/privileges`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<SecurityRoleDefinition> = new Array<SecurityRoleDefinition>();
                if (res  && Array.isArray(res)){
                    return Promise.resolve(res as SecurityRoleDefinition[]);
                }
                else{
                    return Promise.resolve(titems);
                }
            });
    }
    public loadSecurityRolesCustomPrivileges = async (tenantId: string, appId : string, roleId: string) : Promise<Array<EntityCustomPrivilegeDefinition>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/customprivileges`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<EntityCustomPrivilegeDefinition> = new Array<EntityCustomPrivilegeDefinition>();
                if (res  && Array.isArray(res)){
                    return Promise.resolve(res as EntityCustomPrivilegeDefinition[]);
                }
                else{
                    return Promise.resolve(titems);
                }
            });
    }
    public loadAllTenantPrivileges = async (tenantId: string) : Promise<Array<any>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/privileges`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<any> = new Array<any>();
                if (res  && Array.isArray(res)){
                    return Promise.resolve(res as any[]);
                }
                else{
                    return Promise.resolve(titems);
                }
            });
    }
    public createNewEntityInApp = async (tenantId : string, appId: string, ent: EntityDefinition) : Promise<EntityDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/entities`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, ent, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityDefinition = res as EntityDefinition;
                    return ti;
                }
                else{
                    return ent;
                }
            })
            
    }
    public updateEntityInApp = async (tenantId : string, appId: string, ent: EntityDefinition) : Promise<EntityDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/entities/${ent.Id}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, ent, 'PUT', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityDefinition = res as EntityDefinition;
                    return ti;
                }
                else{
                    return ent;
                }
            })
            
    }
    public createNewRoleInApp = async (tenantId : string, appId: string, role: SecurityRoleDefinition) : Promise<SecurityRoleDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, role, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : SecurityRoleDefinition = res as SecurityRoleDefinition;
                    return ti;
                }
                else{
                    return role;
                }
            })
            
    }
    public createNewProperty = async (tenantId : string, appId: string, ent: EntityDefinition, prop: EntityPropertyDefinition) : Promise<EntityPropertyDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/properties`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, prop, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityPropertyDefinition = res as EntityPropertyDefinition;
                    return ti;
                }
                else{
                    return prop;
                }
            })
            
    }
    public deleteProperty = async (tenantId : string, appId: string, ent: EntityDefinition, prop: EntityPropertyDefinition) : Promise<boolean> => {
        let that = this;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/properties/${prop.Id}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, prop, 'DELETE', true)
            .then(function(res : any){
                if (res && !res.ErrorMessage ){
                    return Promise.resolve(true);
                }
                else{
                    return Promise.reject(new Error(res.ErrorMessage));
                }
            })
    }
    public createNewView = async (tenantId : string, appId: string, ent: EntityDefinition, vw: EntityViewDefinition) : Promise<EntityViewDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/views`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, vw, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityViewDefinition = res as EntityViewDefinition;
                    return ti;
                }
                else{
                    return vw;
                }
            })
            
    }
    public createNewForm = async (tenantId : string, appId: string, ent: EntityDefinition, vw: EntityFormDefinition2) : Promise<EntityFormDefinition2> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/forms`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, vw, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityFormDefinition2 = res as EntityFormDefinition2;
                    return ti;
                }
                else{
                    return vw;
                }
            })
            
    }
    public buildApp = async (tenantId : string, appId: string) : Promise<any> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/build`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'POST', false)
            .then(function(res : any){
                debugger;
                if (res ){
                    if (res.ErrorMessages && Array.isArray(res.ErrorMessages) && res.ErrorMessages.length  >0 ){
                        return Promise.reject(res.ErrorMessages.join('.'));
                    }
                    else{
                        return Promise.resolve(res);
                    }
                    /*let ti : boolean = res as boolean;
                    return Promise.resolve(ti);*/
                }
                else{
                    return Promise.resolve(new Error("No response"));
                }
            })
            .catch(function(err: any){
                debugger;
                return Promise.reject(new Error(err?.message));
            })
            
    }

    public importApp = async (tenantId : string, fileData : any, fileType : string, fileName : string) : Promise<any> => {
        let that = this;
        debugger;

        let fileObj: any = {};
        //fileData.Data = encode(fileData);
        fileObj.Data = (fileData as string).substring((fileData as string).lastIndexOf(',')+1);
        fileObj.FileName = fileName;
        fileObj.FileType = fileType;

        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/import`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, fileObj, 'POST', true)
            .then(function(res : any){
                debugger;
                if (res ){
                    if (res.ErrorMessages && Array.isArray(res.ErrorMessages) && res.ErrorMessages.length  >0 ){
                        return Promise.reject(res.ErrorMessages.join('.'));
                    }
                    else{
                        return Promise.resolve(res);
                    }
                    /*let ti : boolean = res as boolean;
                    return Promise.resolve(ti);*/
                }
                else{
                    return Promise.resolve(new Error("No response"));
                }
            })
            .catch(function(err: any){
                debugger;
                return Promise.reject(new Error(err?.message));
            })
            
    }
    public createNewFormResource = async (tenantId : string, appId: string, entId: string, frm: EntityFormDefinition2, res: EntityFormResourceDefinition) : Promise<EntityFormResourceDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entId}/formresources/${frm.Id}`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, res, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityFormResourceDefinition = res as EntityFormResourceDefinition;
                    return ti;
                }
                else{
                    return res;
                }
            })
            
    }
    public createNewRelation = async (tenantId : string, appId: string, ent: EntityDefinition, rel: EntityRelationDefinition) : Promise<EntityRelationDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/relations`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, rel, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityRelationDefinition = res as EntityRelationDefinition;
                    return ti;
                }
                else{
                    return rel;
                }
            })
            
    }
    public updateEntityProperty = async (tenantId : string, appId: string, ent: EntityDefinition, prop: EntityPropertyDefinition) : Promise<EntityPropertyDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/properties`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, prop, 'PUT', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityPropertyDefinition = res as EntityPropertyDefinition;
                    return ti;
                }
                else{
                    return prop;
                }
            })
            
    }
    public updateEntityView = async (tenantId : string, appId: string, ent: EntityDefinition, vw: EntityViewDefinition) : Promise<EntityViewDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/views`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, vw, 'PUT', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityViewDefinition = res as EntityViewDefinition;
                    return ti;
                }
                else{
                    return vw;
                }
            })
            
    }
    public updateEntityForm = async (tenantId : string, appId: string, ent: EntityDefinition, vw: EntityFormDefinition2) : Promise<EntityFormDefinition2> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/forms`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, vw, 'PUT', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityFormDefinition2 = res as EntityFormDefinition2;
                    return ti;
                }
                else{
                    return vw;
                }
            })
            
    }
    public updateNavBar = async (tenantId : string, appId: string, bar: NavigationBar) : Promise<NavigationBar> => {
        // let that = this;
        // debugger;
        let uri : string = '';
        let verb: IllerisNinthAPI.HttpMethodType = 'POST';
        if (bar && bar.Id){
            uri = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/navbars/${bar.Id}`;
            verb = 'PUT';
        }
        else{
            uri = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/navbars`;
            verb = 'POST';
        }
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, bar, verb, true)
            .then(function(res : any){
                if (res ){
                    return res as NavigationBar;
                }
                else{
                    return bar;
                }
            })
            
    }
    public updateAppResource = async (tenantId : string, appId: string, res: AppResource) : Promise<AppResource> => {
        // let that = this;
        // debugger;
        let uri : string = '';
        let verb: IllerisNinthAPI.HttpMethodType = 'POST';
        if (res && res.Id){
            uri = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/appresources/${res.Id}`;
            verb = 'PUT';
        }
        else{
            uri = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/appresources`;
            verb = 'POST';
        }
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, res, verb, true)
            .then(function(res : any){
                if (res ){
                    if (res.Value){
                        return res.Value as AppResource;
                    }
                    else{
                        return res as AppResource;
                    }
                    
                }
                else{
                    return res;
                }
            })
            
    }
    public updatePrivilege = async (tenantId : string, appId: string, ent: EntityCustomPrivilegeDefinition) : Promise<EntityCustomPrivilegeDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/customprivilege`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, ent, 'POST', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityCustomPrivilegeDefinition = res as EntityCustomPrivilegeDefinition;
                    return ti;
                }
                else{
                    return ent;
                }
            })
            
    }
    public updateEntityFormResource = async (tenantId : string, appId: string, entId: string, vw: EntityFormDefinition2, res: EntityFormResourceDefinition) : Promise<EntityFormResourceDefinition> => {
        // let that = this;
        // debugger;
        if (res.Id){
            let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entId}/formresources/${res.Id}`;
            let emp : EntityPersistanceManager = new EntityPersistanceManager();
            return emp.executeAPIAny(uri, res, 'PUT', true)
                .then(function(res : any){
                    if (res ){
                        let ti : EntityFormResourceDefinition = res as EntityFormResourceDefinition;
                        return ti;
                    }
                    else{
                        return res;
                    }
                })
        }
        else{
            let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entId}/formresources/${vw.Id}`;
            let emp : EntityPersistanceManager = new EntityPersistanceManager();
            return emp.executeAPIAny(uri, res, 'POST', true)
                .then(function(res : any){
                    if (res ){
                        let ti : EntityFormResourceDefinition = res as EntityFormResourceDefinition;
                        return ti;
                    }
                    else{
                        return res;
                    }
                })
        }
    }
    public deleteEntityFormResource = async (tenantId : string, appId: string, entId: string, resId: string) : Promise<boolean> => {
        debugger;
        if (resId){
            let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entId}/formresources/${resId}`;
            let emp : EntityPersistanceManager = new EntityPersistanceManager();
            return emp.executeAPIAny(uri, null, 'DELETE', false)
                .then(function(res : any){
                    return Promise.resolve(true);
                })
        }
        return Promise.reject(new Error('Not found'));
    }
    public updateEntityRelation = async (tenantId : string, appId: string, ent: EntityDefinition, rel: EntityRelationDefinition) : Promise<EntityRelationDefinition> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${ent.Id}/properties`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, rel, 'PUT', true)
            .then(function(res : any){
                if (res ){
                    let ti : EntityRelationDefinition = res as EntityRelationDefinition;
                    return ti;
                }
                else{
                    return rel;
                }
            })
            
    }
    public updateRoleDefinitionSecurity = async (tenantId : string, appId: string, roleId: string, priv: EntityPrivilegeDefinition) : Promise<boolean> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles/${roleId}/update`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, priv, 'PUT', true)
            .then(function(res : any){
                return Promise.resolve(res as boolean);
            })
            
    }
    public updateRoleDefinitionSecurityWithPatch = async (tenantId : string, appId: string, roleId: string, priv: EntityPrivilegePatch) : Promise<boolean> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles/${roleId}/updateprivilege`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, priv, 'PUT', true)
            .then(function(res : any){
                return Promise.resolve(res as boolean);
            })
            
    }
    //
    public updateRoleDefinitionSecurityWithCustomPrivilege = async (tenantId : string, appId: string, roleId: string, priv: EntityCustomPrivilegeDefinition) : Promise<boolean> => {
        // let that = this;
        // debugger;
        let uri : string = `api/v2.0/terranova/xdesigner/${tenantId}/${appId}/roles/${roleId}/updatecustom`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, priv, 'PUT', true)
            .then(function(res : any){
                return Promise.resolve(res as boolean);
            })
            
    }

    public loadEntityProperties = async (tenantId : string, appId : string, entityid : string) : Promise<Array<EntityPropertyDefinition>> =>{
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entityid}/properties`;
        var res : Array<EntityPropertyDefinition> = new Array<EntityPropertyDefinition>();
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        return epm.executeAPI(uri, null, 'GET', false)
            .then(function(result: any){
                if (result && result.ErrorMessage ) {
                    return Promise.reject(new Error(result.ErorrMessage));
                }
                else if (result && result.values ) {
                    result.values.forEach(function(item : EntityPropertyDefinition, index : number){
                        res.push(item);
                    });
                    
                }
                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public loadEntityViews = async (tenantId : string, appId : string, entityid : string) : Promise<Array<EntityViewDefinition>> =>{
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entityid}/views`;
        var res : Array<EntityViewDefinition> = new Array<EntityViewDefinition>();
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        return epm.executeAPI(uri, null, 'GET', false)
            .then(function(result: any){
                if (result ) {
                    if (typeof result === 'string'){
                        return Promise.reject(new Error(result));
                    }
                    result.forEach(function(item : EntityViewDefinition, index : number){
                        res.push(item);
                    });
                    
                }
                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public loadEntityForms = async (tenantId : string, appId : string, entityid : string) : Promise<Array<EntityFormDefinition2>> =>{
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entityid}/forms`;
        var res : Array<EntityFormDefinition2> = new Array<EntityFormDefinition2>();
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        return epm.executeAPI(uri, null, 'GET', false)
            .then(function(result: any){
                if (result ) {
                    if (typeof result === 'string' || typeof result?.Message === 'string'){
                        return Promise.reject(new Error(result?.Message ?? result));
                    }
                    result.forEach(function(item : EntityFormDefinition2, index : number){
                        res.push(item);
                    });
                    
                }
                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public loadEntityFormResources = async (tenantId : string, appId : string, entityid : string, formid: string) : Promise<Array<EntityFormResourceDefinition>> =>{
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entityid}/formresources/${formid}`;
        var res : Array<EntityFormResourceDefinition> = new Array<EntityFormResourceDefinition>();
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        return epm.executeAPI(uri, null, 'GET', false)
            .then(function(result: any){
                if (result ) {
                    if (typeof result === 'string' || typeof result?.Message === 'string'){
                        return Promise.reject(new Error(result?.Message ?? result));
                    }
                    result.forEach(function(item : EntityFormResourceDefinition, index : number){
                        res.push(item);
                    });
                    
                }
                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }
    public loadEntityRelations = async (tenantId : string, appId : string, entityid: string) : Promise<Array<EntityRelationDefinition>> => {
        let uri: string = `/api/v2.0/terranova/xdesigner/${tenantId}/${appId}/${entityid}/relations`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        let items : Array<EntityRelationDefinition> = new Array<EntityRelationDefinition>();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                if (res && res.ErrorMessage){
                    return Promise.reject(new Error(res.ErrorMessage));
                }
                else if (res && res.values){
                    res.values.forEach(function(item : any, idx: number){
                        let it : EntityRelationDefinition = item as EntityRelationDefinition;
                        items.push(it);    
                    });
                }
                return items;
            });
    }
}