export class LocalStorageManager{
    constructor(){

    }

    public static containsItem = (key : string) : boolean =>{
        let it : string | null = window.localStorage.getItem(key);
        if (it){
            return true;
        }
        else{
            return false;
        }
    }
    public static getItem = (key : string) : string | null => {
        return window.localStorage.getItem(key);
    }
    public static setItem = (key : string, val :string) : void=> {
         window.localStorage.setItem(key, val);
    }
    public static  getItemAsObject<Type>(key : string)  {
        let it : string | null = LocalStorageManager.getItem(key);
        if (it){
            return JSON.parse(it) as Type;
        }
    }
}

export class SessionStorageManager{
    constructor(){

    }

    public static containsItem = (key : string) : boolean =>{
        let it : string | null = window.sessionStorage.getItem(key);
        if (it){
            return true;
        }
        else{
            return false;
        }
    }
    public static getItem = (key : string) : string | null => {
        return window.sessionStorage.getItem(key);
    }
    public static setItem = (key : string, val :string) : void=> {
         window.sessionStorage.setItem(key, val);
    }
    public static  getItemAsObject<Type>(key : string)  {
        let it : string | null = SessionStorageManager.getItem(key);
        if (it){
            return JSON.parse(it) as Type;
        }
    }
    public static deleteItem = (key : string) : boolean =>{
        window.sessionStorage.removeItem(key);
        
        return true;
    }
    public static deleteStartingWith = (key : string) : boolean =>{
        let len: number = window.sessionStorage.length;
        let items: string[] = new Array<string>();
        for(var x = 0; x<len ; x++){
            if (window.sessionStorage.key(x)?.startsWith(key)){
                items.push(window.sessionStorage.key(x) ?? '');
            }
        }
        items.forEach(function(it: string, idx: number){
            if (it){
                window.sessionStorage.removeItem(it);
            }
        })
        return true;
    }
}


export class SessionStorageManagerKeyManager{
    static getTenantAppEntityListKey = (tenantId : string | null | undefined, appCode: string) : string =>{
        return ('__TN__' + (tenantId ?? 'core') + '__' + appCode + '__ENTITIES__');
    }
    static getTenantEntityListKey = (tenantId : string | null | undefined) : string =>{
        return ('__TN__' + (tenantId ?? 'core') +  '__ENTITIES__');
    }
}