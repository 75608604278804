export class Size{
    public Width: number = 0.0;
    public Heigth: number = 0.0;
    constructor(w: number, h: number){
        this.Width = w;
        this.Heigth = h;
    }
}

export enum DeviceScreenSize {
    XXS,
    XS,
    S,
    M, 
    L,
    XL,
    XXL
}

export class ClientManager {
    public static isMobile = () : boolean =>{
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true;
        }else{
            return false;
        }
    }

    public static getWindowSize = () : Size => {
        return new Size(window.innerWidth, window.innerHeight);
    }

    public static getWindowSizeType = () : DeviceScreenSize => {
        let w : number = window.innerWidth;
        console.log(`Screen Width: ${w}`);
        if (w >= 1281){
            return DeviceScreenSize.XXL;
        }
        else if (w >= 1025){
            return DeviceScreenSize.XL;
        }
        else if (w >= 961){
            return DeviceScreenSize.L;
        }
        else if (w >= 641){
            return DeviceScreenSize.M;
        }
        else if (w >= 481){
            return DeviceScreenSize.S;
        }
        else if (w >= 320){
            return DeviceScreenSize.XS;
        }
        else {
            return DeviceScreenSize.XXS;
        }
    }
}


// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
