import { CommandBar, DefaultButton, ICommandBarItemProps, ILabelStyles, IStyleSet, Label, Pivot, PivotItem, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";
import { DynamicEntity } from "../DynamicEntity";
//import { ClientState } from "../ClientState";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { PageInfo } from "../PageInfo";
import { TNRolePrivilegeEditControl } from "./RolePrivilegeEdit";

export interface TNUserRolesPrivilegesEditProps {
    tenantId: string;
    isReadOnly: boolean;
    getEntity : () => DynamicEntity | null | undefined;
    PageInfo: PageInfo;
    dataKey: string;
}

export interface TNUserRolesPrivilegesEditState  {
    allRoles: any[];
    userRoles: any[];
    errorMessage?: string;
    notificationMessage?: string;
    cmdBarItems: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
    isloading: boolean;
    issaving: boolean;
    RoleEntity?: DynamicEntity | null | undefined;   
}
  
  export class TNUserRolesPrivilegesEditControl extends React.Component<TNUserRolesPrivilegesEditProps, TNUserRolesPrivilegesEditState> {

      constructor(props : TNUserRolesPrivilegesEditProps){
          super(props);
          
          this.state = {
            allRoles: new Array<any>(),
            userRoles: new Array<any>(),
            cmdBarItems: new Array<ICommandBarItemProps>(),
            farItems: new Array<ICommandBarItemProps>(),
            isloading: false,
            issaving: false
          }

      }
      shouldComponentUpdate(nextProps: TNUserRolesPrivilegesEditProps, nextState: TNUserRolesPrivilegesEditState) {
        if (nextProps.dataKey !== this.props.dataKey || nextState.RoleEntity?.getId() !== this.state.RoleEntity?.getId()){
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNUserRolesPrivilegesEditProps, prevState: TNUserRolesPrivilegesEditState){
        if (prevProps.dataKey !== this.props.dataKey || prevState.RoleEntity?.getId() !== this.state.RoleEntity?.getId()){
            this.loadData();
            //this.forceUpdate();
        }
      }
      private innerSetRoles = (res: any) => {
          let that = this;
          let allRoles: any[] = new Array<any>();
          let userRoles : any[]= new Array<any>();
          if (res && res.AllRoles){
              allRoles = res.AllRoles as any[];
          }
          if (res && res.UserRoles){
              userRoles = res.UserRoles as any[];
          }
          allRoles.forEach(function(rit: any, index: number){
              rit.DisplayName = `${rit.ApplicationName} - ${rit.DisplayName}`
          })
          userRoles.forEach(function(rit: any, index: number){
             rit.DisplayName = `${rit.ApplicationName} - ${rit.DisplayName}`
          })

          let allRolesFiltered: any[] = new Array<any>();
          allRoles.forEach(function(it: any, index: number){
              if (it && it.DisplayName && !userRoles.some(z => z.DisplayName === it.DisplayName)){
                allRolesFiltered.push(it);
              }
          })
          userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
          allRolesFiltered.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);

          that.setState({allRoles: allRolesFiltered, userRoles: userRoles, isloading: false});
          that.forceUpdate();
      }

      private loadData = () : void =>{
        var that = this;

        let ent: DynamicEntity | null | undefined = that.props.getEntity();
        this.setState({RoleEntity: ent});

        
      }
      public componentDidMount(){
          var that = this;
          that.loadData();

          let barItems : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
          barItems.push({
              key: 'Refresh',
              text: 'Refresh',
              iconOnly: false,
              iconProps: {iconName: 'Refresh'},
              onClick: (ev?: any) => {that.loadData();}
          });
          barItems.push({
            key: 'Save',
            text: 'Save',
            iconOnly: false,
            iconProps: {iconName: 'Save'},
            onClick: (ev?: any) => {that.saveData();}
          });
          that.setState({cmdBarItems: barItems});
      }
      private saveData = () : void => {
          let that =this;
        let items: any[] = this.state.userRoles;
        let itemIds : string[] = new Array<string>();
        if (items){
            items.forEach(function(it: any, idx: number){
                itemIds.push(it.Id);
            })
            var emp : EntityPersistanceManager = new EntityPersistanceManager();
            this.setState({issaving : true});
            emp.setUserRoles(this.props.tenantId, itemIds)
                .then(function(res: any){
                    that.innerSetRoles(res);
                    that.setState({issaving : false, notificationMessage: 'User Roles Saved'});
                })
                .catch(function(err: any){
                    that.setState({errorMessage: err?.message });
                })
        }
      }

      private addRoleToUser = (roleName: string) : void => {
        let allRoles: any[] = this.state.allRoles;
        let userRoles : any[]= this.state.userRoles;
        let role : any= allRoles.find(z => z.DisplayName === roleName);
        userRoles.push(role);
        allRoles.splice(allRoles.indexOf(role), 1);
        userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        allRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        this.setState({allRoles: allRoles, userRoles: userRoles});
      }
      private removeRoleFromUser = (roleName: string) : void => {
        let allRoles: any[] = this.state.allRoles;
        let userRoles : any[]= this.state.userRoles;
        let role : any= userRoles.find(z => z.DisplayName === roleName);
        allRoles.push(role);
        userRoles.splice(userRoles.indexOf(role), 1);
        userRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        allRoles.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : 1);
        this.setState({allRoles: allRoles, userRoles: userRoles});
      }
      public render(){
          var that = this;
          var idStr : string = 'tnOwerEditRow';

          return (
              <Stack verticalFill horizontalAlign="stretch">
                  <Pivot aria-label="Basic Pivot Example">
                    <PivotItem
                        headerText="Details"
                    >
                        <Stack verticalFill horizontalAlign="stretch">
                            <TextField label="Role Name" value={this.state.RoleEntity?.getValue('DisplayName')} readOnly={true} disabled={true}></TextField>
                            <TextField label="Application Name" value={this.state.RoleEntity?.getValue('ApplicationName')} readOnly={true} disabled={true}></TextField>
                        </Stack>
                    </PivotItem>
                    <PivotItem headerText="Default Privileges">
                        <TNRolePrivilegeEditControl
                            isreadonly={this.props.isReadOnly}
                            issystemadmin={false}
                            roleid={this.state.RoleEntity?.getId() ?? ''}
                            rolename={this.state.RoleEntity?.getValue('DisplayName') ?? ''}
                            id={nanoid()}
                            dataKey={this.props.dataKey}
                            tenantUniqueName={this.props.PageInfo.TenantId}
                            privilegesType={'default'}
                            PageInfo={this.props.PageInfo}
                            getEntity={this.props.getEntity}
                        ></TNRolePrivilegeEditControl>
                    </PivotItem>
                    <PivotItem headerText="Custom Privileges">
                    <TNRolePrivilegeEditControl
                            isreadonly={this.props.isReadOnly}
                            issystemadmin={false}
                            roleid={this.state.RoleEntity?.getId() ?? ''}
                            rolename={this.state.RoleEntity?.getValue('DisplayName') ?? ''}
                            id={nanoid()}
                            dataKey={this.props.dataKey}
                            tenantUniqueName={this.props.PageInfo.TenantId}
                            privilegesType={'custom'}
                            PageInfo={this.props.PageInfo}
                            getEntity={this.props.getEntity}
                        ></TNRolePrivilegeEditControl>
                    </PivotItem>
                  </Pivot>

                  
                    {that.state.isloading &&
                         <ProgressIndicator label="Loading..." />
                    }
                    {that.state.issaving &&
                         <ProgressIndicator label="Saving..." />
                    }
                  
              </Stack>
          );
      }
  }

  const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
  };