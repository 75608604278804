import { ComboBox, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import Notiflix from "notiflix";
import React from "react";
import { ClientState } from "../../ClientState";
import { ProductRecurringPrice } from "../../designers/Entities/ProductPricing";
import { PageInfo } from "../../PageInfo";

export interface TNProductPriceSchemeRecurrenceSurfaceEditControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    Price: ProductRecurringPrice;
    saveItem: (it: ProductRecurringPrice) => void;
    deleteItem: (it: ProductRecurringPrice) => void;
    
}

export interface TNProductPriceSchemeRecurrenceSurfaceEditControlState  {
    errorMessage?: string;
    notificationMessage?: string;
    Price : ProductRecurringPrice;
    DurationOptions: IComboBoxOption[];
    currencySymbol: string;
}
  
export class TNProductPriceSchemeRecurrenceSurfaceEditControl extends React.Component<TNProductPriceSchemeRecurrenceSurfaceEditControlProps, TNProductPriceSchemeRecurrenceSurfaceEditControlState> {
      constructor(props : TNProductPriceSchemeRecurrenceSurfaceEditControlProps){
          super(props);

          let cs : ClientState = ClientState.CurrentState();
          
          let to: IComboBoxOption[] = new Array<IComboBoxOption>();
          to.push({key: 'D', text: 'Day(s)'});
          to.push({key: 'W', text: 'Week(s)'});
          //to.push({key: 'WD', text: 'Workdays(s)'});
          to.push({key: 'M', text: 'Months(s)'});
          to.push({key: 'Y', text: 'Years(s)'});

          this.state = {
            Price: this.props.Price,
            DurationOptions: to,
            currencySymbol: cs?.UserProfile?.DefaultCurrency ?? 'EUR'
          }
      }

      public componentDidMount(){
      }

      public shouldComponentUpdate(nextProps: TNProductPriceSchemeRecurrenceSurfaceEditControlProps, nextState: TNProductPriceSchemeRecurrenceSurfaceEditControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNProductPriceSchemeRecurrenceSurfaceEditControlProps, prevState: TNProductPriceSchemeRecurrenceSurfaceEditControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
            //this.loadSchemes();
        }
      }
      public render(){
          var that = this;
         
          return (
              <Stack verticalFill horizontalAlign='stretch' tokens={{childrenGap: '10'}}>
                  {this.state.errorMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.errorMessage}
                        </MessageBar>
                    }
                    {this.state.notificationMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.notificationMessage}
                        </MessageBar>
                    }
                  <Stack horizontalAlign="stretch" horizontal tokens={{childrenGap: 10}}>
                    <Stack.Item style={{width: '50%'}}>
                      <TextField label="Duration" placeholder="Enter a duration" readOnly={this.props.isReadOnly} value={this.state.Price.RecurrenceValue?.toString()} onChange={this.updateDurationValue} ></TextField>
                    </Stack.Item>
                    <Stack.Item style={{width: '50%'}}>
                      <ComboBox
                          selectedKey={this.state.Price.RecurrencePeriod}
                          label="Periodicity"
                          options={this.state.DurationOptions}
                          onChange={this.updateDurationPeriod}
                      />
                    </Stack.Item>
                  </Stack>
                  <TextField label="Name" placeholder="Name" readOnly={this.props.isReadOnly} value={this.state.Price.Name} onChange={this.updateName} ></TextField>
                  <TextField label="Description" placeholder="Description" readOnly={this.props.isReadOnly} value={this.state.Price.Description} onChange={this.updateDescription} rows={3} ></TextField>
                  <TextField label="Unique Code" placeholder="Unique ID" readOnly={this.props.isReadOnly} value={this.state.Price.UniqueCode} onChange={this.updateCode}  ></TextField>
                  <TextField label="Order" placeholder="" readOnly={this.props.isReadOnly} value={this.state.Price.Order.toString()} onChange={this.updateOrder} type="number" ></TextField>
                  <TextField label="Fixed Price Part" placeholder="" readOnly={this.props.isReadOnly} value={this.state.Price.FixedPricePart?.toString()} onChange={this.updatePrice} suffix={this.state.currencySymbol} ></TextField>

                  <PrimaryButton text="Save" style={{marginTop: '25px'}} onClick={this.onSave} ></PrimaryButton>
                  <PrimaryButton text="Delete" style={{marginTop: '5px'}} onClick={this.onDelete} ></PrimaryButton>
              </Stack>
          );
      }
      public updateDurationValue = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        if (!isNaN(Number.parseFloat(newValue ?? '0'))){
          it.RecurrenceValue = Number.parseFloat(newValue ?? '0');
        }
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updatePrice = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        if (!isNaN(Number.parseFloat(newValue ?? '0'))){
          it.FixedPricePart = Number.parseFloat(newValue ?? '0');
        }
        else{
          it.FixedPricePart = undefined;
        }
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updateDurationPeriod = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        if (option){
            it.RecurrencePeriod = option.key.toString();
        }
        if (!it.Name && it.RecurrencePeriod){
          it.Name = `${it.RecurrenceValue}${it.RecurrencePeriod}`;
          if (!it.UniqueCode){
            it.UniqueCode = it.Name.replace(' ', '');
          }
        }
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updateName = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        it.Name = newValue ?? '';
        if (!it.UniqueCode || it.Name.indexOf(it.UniqueCode) === 0){
          it.UniqueCode = it.Name.replace(' ', '');
        }
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updateDescription = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        it.Description = newValue ?? '';
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updateCode = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        it.UniqueCode = newValue ?? '';
        this.setState({Price: it});
        this.forceUpdate();
      }
      public updateOrder = (ev?: any, newValue? : string) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        it.Order = newValue ? (parseInt(newValue)) : 0;
        this.setState({Price: it});
        this.forceUpdate();
      }
  

      private onSave = (ev?: any) : void => {
        let it : ProductRecurringPrice = this.state.Price;
        if (it && !it.Name){
            this.setState({errorMessage : 'Name is mandatory.'});
            return;
        }
        if (it && (!it.RecurrencePeriod || !it.RecurrenceValue)){
          this.setState({errorMessage : 'Period is mandatory.'});
          return;
        }

        this.props.saveItem(it);

      }
      private onDelete = (ev?: any) : void => {
        let that = this;
        Notiflix.Confirm.init({ zindex: 10000});
        Notiflix.Confirm.show('Confirm delete',
                'Are you sure you want to remove this item?','Yes','No',
                function(){ 
                    that.props.deleteItem(that.state.Price);
                },function(){
                    
                });
      }
  }