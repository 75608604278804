import { IllerisNinthUI } from "./MetaModel/UI/Formlet";
import { IllerisNinthAPI } from "./ServiceResult";

export class IconConverter{

    static convertToFluentUiIconBtn2(ab : IllerisNinthAPI.Button) : string {
        if (!ab){
            return 'StatusCircleQuestionMark';
        }
        else{
            if (!ab.ImageCSSClass){
                var cmd = ab.CommandName?.toLowerCase();
                if (cmd === 'back' || cmd === 'cmdback'){
                    return 'Back'
                }
                else if (cmd === 'saveandnew' || cmd === 'cmdsaveandnew'){
                    return 'SaveToMobile'
                }
                else if (cmd === 'saveandclose' || cmd === 'cmdsaveandclose'){
                    return 'SaveAndClose'
                }
                else if (cmd === 'delete' || cmd === 'cmddelete'){
                    return 'Delete'
                }
                else if (cmd === 'activate' || cmd === 'cmdactivate'){
                    return 'ActivateOrders'
                }
                else if (cmd === 'deactivate' || cmd === 'cmddeactivate'){
                    return 'DeactivateOrders'
                }
                else if (cmd === 'assign' || cmd === 'cmdassign'){
                    return 'ReminderGroup'
                }
                else{
                    return 'StatusCircleQuestionMark';
                }
            }
            else{
                return this.convertToFluentUiIcon(ab.ImageCSSClass);
            }
        }
    }

    static convertToFluentUiIconBtn(ab : IllerisNinthUI.ActionButton) : string {
        if (!ab){
            return 'StatusCircleQuestionMark';
        }
        else{
            if (!ab.ImageCSSClassName){
                var cmd = ab.CommandName?.toLowerCase();
                if (cmd === 'back' || cmd === 'cmdback'){
                    return 'Back'
                }
                else if (cmd === 'saveandnew' || cmd === 'cmdsaveandnew'){
                    return 'SaveToMobile'
                }
                else if (cmd === 'saveandclose' || cmd === 'cmdsaveandclose'){
                    return 'SaveAndClose'
                }
                else if (cmd === 'delete' || cmd === 'cmddelete'){
                    return 'Delete'
                }
                else if (cmd === 'activate' || cmd === 'cmdactivate'){
                    return 'ActivateOrders'
                }
                else if (cmd === 'deactivate' || cmd === 'cmddeactivate'){
                    return 'DeactivateOrders'
                }
                else if (cmd === 'assign' || cmd === 'cmdassign'){
                    return 'ReminderGroup'
                }
                else{
                    return 'StatusCircleQuestionMark';
                }
            }
            else{
                return this.convertToFluentUiIcon(ab.ImageCSSClassName);
            }
        }
    }

    static convertToFluentUiIcon(faIconName: string) : string {
        var ic = faIconName;
        if (!ic){
            return 'StatusCircleQuestionMark';
        }
        ic = ic.toLowerCase();

        let iconName : string = '';
        let iconSize : string = '';
        
        if (ic.startsWith('fal') || ic.startsWith('far') || ic.startsWith('fas')){
            ic = ic.substr(3).trimStart();
        }
        if (ic.indexOf(' ')!== -1){
            iconSize = ic.substr(ic.indexOf(' ')).trim();
            /*if (iconSize){
                iconSize = iconSize.toLowerCase().trim();
                if (iconSize === 'fa-2x'){
                    iconSize = 'tn-2x';
                }
                else if (iconSize === 'fa-3x'){
                    iconSize = 'tn-3x';
                }
                else if (iconSize === 'fa-4x'){
                    iconSize = 'tn-4x';
                }
            }*/
            ic = ic.substr(0, ic.indexOf(' '))?.trim();
        }
        if (ic === 'fa-users'){
            iconName =  'Contact';
        }
        else if (ic === 'fa-user-plus'){
            iconName =  'AddFriend';
        }
        else if (ic === 'fa-cogs'){
            iconName =  'Settings';
        }
        else if (ic === 'fa-tablet'){
            iconName =  'Tablet';
        }
        else if (ic === 'fa-gift'){
            iconName =  'Giftbox';
        }
        else if (ic === 'fa-database'){
            iconName =  'Database';
        }
        else if (ic === 'fa-industry-alt'){
            iconName =  'Factory';
        }
        else if (ic === 'fa-list-alt'){
            iconName =  'PageList';
        }
        else if (ic === 'fa-shopping-cart'){
            iconName =  'ShoppingCart';
        }
        else if (ic === 'fa-usd-square'){
            iconName =  'ReservationOrders';
        }
        else if (ic === 'fa-save'){
            iconName =  'Save';
        }
        else if (ic === 'fa-trash'){
            iconName =  'Delete';
        }
        else if (ic === 'fa-ban'){
            iconName =  'DeactivateOrders';
        }
        else if (ic === 'fa-check'){
            iconName =  'ActivateOrders';
        }
        else if (ic === 'fa-plus'){
            iconName =  'Add';
        }
        else if (ic === 'fa-refresh'){
            iconName =  'Refresh';
        }
        else if (ic === 'fa-arrow-left'){
            iconName =  'Back';
        }
        else if (ic === 'fa-file-word'){
            iconName =  'WordDocument';
        }
        else if (ic === 'fa-envelope'){
            iconName = 'Mail'
        }
        else if (ic === 'fa-key'){
            iconName =  'AzureKeyVault';
        }
        else if (ic === 'fa-desktop-alt'){
            iconName =  'WhiteBoardApp32';
        }
        else if (ic === 'fa-building'){
            iconName =  'AccountManagement';
        }
        else if (ic === 'fa-ticket-alt'){
            iconName =  'Ticket';
        }
        else if (ic === 'fa-university'){
            iconName =  'TestUserSolid';
        }
        else if (ic === 'fa-sitemap'){
            iconName =  'Group';
        }
        else if (ic === 'fa-user'){
            iconName =  'UserOptional';
        }
        else if (ic === 'fa-universal-access'){
            iconName =  'HomeGroup';
        }
        else if (ic === 'fa-user-tag'){
            iconName =  'LaptopSecure';
        }
        else if (ic === 'fa-shield-alt'){
            iconName =  'ProHockey';
        }
        else if (ic === 'fa-bug'){
            iconName =  'M365InvoicingLogo';
        }
        else if (ic === 'fa-eye'){
            iconName =  'ComplianceAudit';
        }
        else if (ic === 'fa-tag'){
            iconName =  'Tag';
        }
        else if (ic === 'fa-tags'){
            iconName =  'Tag';
        }
        else if (ic === 'fa-book-user'){
            iconName =  'ChartTemplate';
        }
        else if (ic === 'fa-key-skeleton'){
            iconName =  'AzureKeyVault';
        }
        else if (ic === 'fa-envelope-square'){
            iconName =  'ChartTemplate';
        }
        else if (ic === 'fa-plus'){
            iconName =  'Add';
        }
        else if (ic === 'fa fa-plus'){
            iconName =  'Add';
        }
        else if (ic === 'fal fa-saveandclose'){
            iconName =  'SaveAndClose';
        }
        else if (ic === 'fa fa-saveandclose'){
            iconName =  'SaveAndClose';
        }
        else if (ic === 'fa-saveandclose'){
            iconName =  'SaveAndClose';
        }
        else if (ic === 'fa-box-usd'){
            iconName =  'ActivateOrders';
        }
        else if (ic === 'fa-box-full'){
            iconName =  'Package';
        }
        else if (ic === 'fa-file-invoice'){
            iconName =  'M365InvoicingLogo';
        }
        else if (ic === 'fa-money-check-edit'){
            iconName =  'Money';
        }
        else if (ic === 'fa-credit-card-front'){
            iconName =  'PaymentCard';
        }
        else if (ic === 'fa-credit-card'){
            iconName =  'PaymentCard';
        }
        else if (ic === 'fa-bags-shopping'){
            iconName =  'ReservationOrders'
        }
        else if (ic === 'fa-archive'){
            iconName =  'Archive';
        }
        else if (ic === 'fa-check-circle'){
            iconName =  'Completed';
        }
        else if (ic === 'fa-money-check-alt'){
            iconName =  'Money';
        }
        else if (ic === 'fa-book'){
            iconName =  'WaitlistConfirm';
        }
        else if (ic === 'fa-wrench'){
            iconName =  'Settings';
        }
        else if (ic === 'fa-stop-circle'){
            iconName =  'Stop';
        }
        else if (ic === 'fa-play'){
            iconName =  'Play';
        }
        else if (ic.startsWith('fa')){
            iconName =  ic;
        }
        else
        {
            console.log('ERROR - invalid icon conversion : ' + ic)
            iconName =  ic;
        }

        if (iconSize){
            //iconName += (' ' + iconSize);
        }
        return iconName;
    }
}