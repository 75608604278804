import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, Link, Panel, ProgressIndicator, SelectionMode, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import React from "react";
import { EntityRelationDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityRelationDefinitionSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityRelationDefinition } from "./Entities/EntityRelation";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";

interface EntityRelationsViewControlProps {
    ent: EntityDefinition;
    tenantId : string;
    app: SystemApplicationInfo;
}

interface EntityRelationsViewControlState extends EntityRelationsViewControlProps {
  StateKey : number;
  errorMessage? : string;
  allRelations : Array<EntityRelationDefinition>;
  columns : IColumn[];
  isbusy : boolean;
  showAddEditRelationPanel: boolean;
  newRel? : EntityRelationDefinition;
}


export class EntityRelationsViewControl extends React.Component<EntityRelationsViewControlProps, EntityRelationsViewControlState> {
    constructor(props: EntityRelationsViewControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        ent : props.ent,
        tenantId : props.tenantId,
        allRelations : new Array<EntityRelationDefinition>(),
        columns : new Array<IColumn>(),
        isbusy : false,
        app: props.app,
        showAddEditRelationPanel: false,
      }
  
    }
  
    componentDidMount() {
      var that = this;
      this.loadRelations().then(function(res: any){
        that.forceUpdate();
      });
      this.buildColumns();
    }

    private buildColumns = () : void =>{
        let cols : IColumn[] = new Array<IColumn>();
        cols.push(
        {
          key: 'coldisplayname',
          name: 'Display Name',
          ariaLabel: 'Display Name',
          isIconOnly: false,
          fieldName: 'DisplayName',
          minWidth: 80,
          maxWidth: 250,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          onRender: (item: EntityRelationDefinition) => {
            return <Link onClick={() => this.onEditRelation(item)}>{item.DisplayName}</Link>;
          },
        });
        cols.push(
        {
          key: 'coltarget',
          name: 'Target Entity',
          ariaLabel: 'Target Entity',
          isIconOnly: false,
          fieldName: 'TargetEntityName',
          minWidth: 80,
          maxWidth: 250,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
        });
        cols.push(
        {
          key: 'colcreatedate',
          name: 'Create Date',
          ariaLabel: 'Create Date',
          isIconOnly: false,
          fieldName: 'CreateDate',
          minWidth: 120,
          maxWidth: 180,
          isRowHeader: true,
          isResizable: true,
          data: 'datetime',
          isPadded: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onRender: (item: EntityRelationDefinition) => {
            return <span>{dayjs(item.CreateDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
          },
          onColumnClick: this._onColumnClick,
        }); 
        cols.push(
        {
          key: 'colmoddate',
          name: 'Modified Date',
          ariaLabel: 'Modified Date',
          isIconOnly: false,
          fieldName: 'LastModifiedDate',
          minWidth: 120,
          maxWidth: 180,
          isRowHeader: true,
          isResizable: true,
          data: 'datetime',
          isPadded: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onRender: (item: EntityRelationDefinition) => {
            return <span>{dayjs(item.LastModifiedDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
          },
          onColumnClick: this._onColumnClick,
        }); 
        this.setState({columns : cols});
    }
    private onEditRelation = (rel : EntityRelationDefinition) : void =>{
      this.setState({newRel: rel, showAddEditRelationPanel: true});
      this.forceUpdate();
    }

    private loadRelations = async () : Promise<void> => {
      let md : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      md.loadEntityRelations(this.props.tenantId, this.props.app.Id, this.props.ent.Id).then(function(res: EntityRelationDefinition[]){
        that.setState({allRelations : res});
        that.forceUpdate();
      })
      .catch(function(err : any){
        that.setState({errorMessage: err.message});
      });
    }
    private _getKey = (item: any, index?: number): string => {
        return item.key;
    }
    private _onItemInvoked(item: any): void {
        alert(`Item invoked: ${item.name}`);
    }
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.state.allRelations, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
          columns: newColumns,
          allRelations: newItems,
        });
      
    }
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }    
    
    public shouldComponentUpdate = (nextProps: EntityRelationsViewControlProps, nextState: EntityRelationsViewControlState) : boolean =>{
        if (nextProps.tenantId !== this.props.tenantId || (nextProps.ent && this.props.ent && nextProps.ent.Id !== this.props.ent.Id )){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : EntityRelationsViewControlProps, prevState: EntityRelationsViewControlState){
        var that = this;
        if (prevProps.ent && this.props.ent && prevProps.ent.Id !== this.props.ent.Id){
            this.loadRelations().then(function(res: any){
                that.forceUpdate();
              });
        }
    }
    private dismissRelEditSideBar = () : void =>{
      let that = this;
      that.setState({showAddEditRelationPanel : false});
    }
    private saveAndCloseAddEntityProperty = () : void => {
      let that = this;
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (this.state.newRel && !this.state.newRel.Id){
        let nr : EntityRelationDefinition = this.state.newRel!;
        nr.EntityId = this.state.ent.Id;
        nr.ApplicationId = this.state.app.Id;
        mh.createNewRelation(this.props.tenantId, this.props.app.Id, this.props.ent, nr).then(function(res: EntityRelationDefinition){
          let allRels : EntityRelationDefinition[] = that.state.allRelations;
          allRels.push(res);
          that.setState({errorMessage: '', newRel: res, showAddEditRelationPanel: false, allRelations: allRels});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
      else{
        let nr : EntityRelationDefinition = this.state.newRel!;
        nr.EntityId = this.state.ent.Id;
        nr.ApplicationId = this.state.app.Id;
        mh.updateEntityRelation(this.props.tenantId, this.props.app.Id, this.props.ent, nr).then(function(res: EntityRelationDefinition){
          let allRels : EntityRelationDefinition[] = that.state.allRelations;
          allRels.push(res);
          that.setState({errorMessage: '', newRel: res, showAddEditRelationPanel: false, allRelations: allRels});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
    }
    private onAddRelation = (evt: any) => {
      let rel : EntityRelationDefinition = new EntityRelationDefinition();
      rel.EntityId = this.props.ent.Id;
      this.setState({newRel: rel, showAddEditRelationPanel: true});
      this.forceUpdate();
    }

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-designer-propertiesview'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              <DetailsList
              items={this.state.allRelations}
              compact={true}
              columns={this.state.columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              //selection={this._selection}
              //selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
            />
            {this.state.showAddEditRelationPanel  &&
              <Panel
                isOpen={this.state.showAddEditRelationPanel}
                onDismiss={(ev: any) => {that.setState({showAddEditRelationPanel: false}); that.forceUpdate();}}
                
                closeButtonAriaLabel="Close"
                headerText="Add/Edit Relation"
              >
                <EntityRelationDefinitionSurfaceEditorControl TenantId={this.props.tenantId} app={this.props.app} ent={this.state.ent!} rel={this.state.newRel!} isReadOnly={false} closeAndSave={that.saveAndCloseAddEntityProperty}  />
              </Panel>
              }
              <DefaultButton label='Add Relation' text='Add Relation' iconProps={{iconName: 'Add'}} onClick={this.onAddRelation} style={{width: '180px', marginTop: '20px'}} ></DefaultButton>
          </Stack>
      );
    }
  }
  