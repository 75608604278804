import { CompoundButton, MessageBar,  MessageBarType,  Panel, Stack } from "@fluentui/react";
import React from "react";

import { SectionControl } from "../SectionControl";
import { SystemAppSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/SystemAppSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";
import { SolutionGridControlControl } from "./SolutionGridControl";

interface TenantSolutionPickerProps {
    setApplication? : (appInfo : SystemApplicationInfo, tenantInfo : TenantInfo) => void;
}

interface TenantSolutionPickerState extends TenantSolutionPickerProps {
  StateKey : number;
  errorMessage? : string;
  allTenants : Array<TenantInfo>;
  allApps : Array<SystemApplicationInfo>;
  currentTenant? : TenantInfo;
  currentApplication? : SystemApplicationInfo;
  showAddAppPanel: boolean;
  newApp: SystemApplicationInfo | null;
}


export class TenantSolutionPickerControl extends React.Component<TenantSolutionPickerProps, TenantSolutionPickerState> {
    constructor(props: TenantSolutionPickerProps) {
      super(props);
      this.state = {
        StateKey : 0,
        allTenants : new Array<TenantInfo>(),
        allApps : new Array<SystemApplicationInfo>(),
        showAddAppPanel: false,
        newApp: null
      }
  
    }
  
    componentDidMount() {
      var that = this;
      let eh: DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.loadTenants().then(function(res : Array<TenantInfo>){
        that.setState({allTenants : res});
        that.forceUpdate();
      })
    }

    private refreshApps= (tenantId : string) : void => {
      let that = this;
      let ti : TenantInfo |undefined = this.state.allTenants.find (z => z.Id === tenantId)
      if (ti){
          this.setState({currentTenant : ti});
          let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
          eh.loadSolutionsInTenant(ti?.Id).then(function(res: Array<SystemApplicationInfo>){
              that.setState({allApps : res});
          })
      }
    }

    private onChangeTenant = (evt : any) : void =>{
        var that = this;
        if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.tenantid){
            that.refreshApps(evt.currentTarget.dataset.tenantid);
        }
    }
    private onChangeApp = (evt : any) : void =>{
        if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.appid){
            var ti : SystemApplicationInfo |undefined = this.state.allApps.find (z => z.Id === evt.currentTarget.dataset.appid)
            if (ti && this.state.currentTenant){
                this.setState({currentApplication : ti});
                if (this.props.setApplication){
                    this.props.setApplication(ti, this.state.currentTenant!);
                }
            }
        }
    }

    private onCreateNewApp = (evt: any) : void =>{
      
      this.setState({newApp: new SystemApplicationInfo(), showAddAppPanel: true});
    }

    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private dismissPanel = (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) : void =>{
      this.setState({showAddAppPanel: false})  ;
    }
    private closeAndSave = (ev?: any) : void =>{
      debugger;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      eh.createNewAppIntenant(this.state.currentTenant!.Id, this.state.newApp!).then(function (res: SystemApplicationInfo){
        that.setState({showAddAppPanel: false, newApp : res});
        that.refreshApps(that.state.currentTenant!.Id);
      })
      .catch(function(err: any){
        that.setState({errorMessage : err.message});
      })
      
    }
    private resetError = (ev :any) : void =>{
      this.setState({errorMessage: ''});
    }
    render() {
      var that = this;
  
      return (
          <Stack horizontal horizontalAlign='stretch' className='body-panel'>
              <SectionControl headerText='Select Tenant.' style={{width: '250px'}}>
                <Stack verticalFill tokens={{ childrenGap: 15 }}>
                    {this.state.allTenants.map(function(item: TenantInfo, index: number){
                        return (
                        <CompoundButton key={`tenantBtn${index}`} text={item.DisplayName}  secondaryText={item.UniqueName + ', ' + (item.IsActive ? 'Active' : 'Inactive')} onClick={that.onChangeTenant} data-tenantid={item.Id} >
                            
                        </CompoundButton>)
                    })
                    }
                </Stack>
              </SectionControl>
              {this.state.currentTenant &&  that.props.setApplication &&
                <SolutionGridControlControl
                    Tenant={this.state.currentTenant}
                    style={{marginLeft: '25px'}}
                    OnOpenApp={(app: SystemApplicationInfo) => {that.props.setApplication!(app, this.state.currentTenant!);}}
                ></SolutionGridControlControl>
                // <SectionControl headerText={'Select Application in ' + this.state.currentTenant?.DisplayName + ' tenant.'} style={{ marginTop: '35px'}}>
                //     <Stack horizontal tokens={stackTokens}>
                //         {this.state.allApps.map(function(item: SystemApplicationInfo, index: number){
                //             return (
                //             <CommandButton key={`appBtn${index}`} iconProps={{ iconName: IconConverter.convertToFluentUiIcon(item.ImageCSSClass) }}  text={item.DisplayName + ' (' + item.AppCode + ', ' + (item.IsActive ? 'Active' : 'Inactive')  + ')'} onClick={that.onChangeApp} data-appid={item.Id} >
                                
                //             </CommandButton>)
                //         })
                //         }
                //         <CommandButton key={`appBtn${999}`} iconProps={{ iconName: 'Add' }}  text='Add' onClick={that.onCreateNewApp} >
                                
                //         </CommandButton>
                //     </Stack>
                // </SectionControl>
              }
              <Panel
                isOpen={that.state.showAddAppPanel && (that.state.currentTenant !== undefined) && (that.state.currentTenant !== null)}
                onDismiss={that.dismissPanel}
                
                closeButtonAriaLabel="Close"
                headerText="Create a new App"
              >
                <SystemAppSurfaceEditorControl app={that.state.newApp!} isReadOnly={false} closeAndSave={that.closeAndSave} TenantInfo={this.state.currentTenant!} />
              </Panel>
              {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={that.resetError}
                  dismissButtonAriaLabel="Close"
                >
                  {that.state.errorMessage}
                </MessageBar>
              }
          </Stack>
      );
    }
  }
  