import { IconButton, Label, MessageBar, MessageBarType, Panel, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import React from "react";
import { Link } from "react-router-dom";
import { ClientState } from "../../ClientState";
import { ProductPriceScheme, ProductRecurringPrice, ProductVolumePrice } from "../../designers/Entities/ProductPricing";
import { PageInfo } from "../../PageInfo";
import { TNProductPriceSchemeRecurrenceSurfaceEditControl } from "./TNProductPriceSchemeRecurrenceSurfaceEditControl";
import { TNProductVolumePriceSurfaceEditorControl } from "./TNProductVolumePriceSurfaceEdit";

export interface TNProductPriceSchemeViewControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    //getEntity : () => DynamicEntity | null;
    Scheme?: ProductPriceScheme;
    isHeader: boolean;
    key?: string;
    editItem: (it: ProductPriceScheme) => void;
    addPriceRow?: () => void;
    saveScheme: (it: ProductPriceScheme) => void;
    cloneScheme: (it: ProductPriceScheme) => void;
}

export interface TNProductPriceSchemeViewControlState  {
    Product?: any;
    errorMessage?: string;
    notificationMessage?: string;
    Scheme?: ProductPriceScheme;
    ShowRecurrencePriceEdit: boolean;
    EditRecurrencePrice?: ProductRecurringPrice;
    EditVolumePrice?: ProductVolumePrice;
    
}
  
export class TNProductPriceSchemeViewControl extends React.Component<TNProductPriceSchemeViewControlProps, TNProductPriceSchemeViewControlState> {
      constructor(props : TNProductPriceSchemeViewControlProps){
          super(props);
          
          this.state = {
            Scheme: props.Scheme,
            ShowRecurrencePriceEdit: false
          }
      }

      public componentDidMount(){
      }

      public shouldComponentUpdate(nextProps: TNProductPriceSchemeViewControlProps, nextState: TNProductPriceSchemeViewControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNProductPriceSchemeViewControlProps, prevState: TNProductPriceSchemeViewControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
        }
      }
      public render(){
          var that = this;

          if (this.props.isHeader){
            return (
                <Stack horizontal horizontalAlign="stretch" style={{borderBottom: '1px solid black'}}>
                  <Stack.Item style={{width: '80px'}}>
                    &nbsp;
                  </Stack.Item>
                  <Stack.Item style={{width: '120px'}}>
                    Order
                  </Stack.Item>
                  <Stack.Item style={{width: '180px'}}>
                    Type
                  </Stack.Item>
                  <Stack.Item style={{width: '350px'}}>
                    Name
                  </Stack.Item>
                  <Stack.Item style={{width: '240px'}}>
                    Valid From
                  </Stack.Item>
                  <Stack.Item style={{width: '240px'}}>
                    Valid To
                  </Stack.Item>
                  <Stack.Item style={{width: '350px', textAlign: 'end'}}>
                    <IconButton iconProps={{iconName: 'Add'}} label="Add" onClick={(ev?: any) => {if (this.props.addPriceRow)  { this.props.addPriceRow();}}} ></IconButton>
                  </Stack.Item>
                </Stack>);
          }
          else if (this.state.Scheme){
            return (
                <Stack verticalFill horizontalAlign='stretch' style={{borderBottom: '1px solid black'}}>
                    {this.state.errorMessage && 
                          <MessageBar
                                  messageBarType={MessageBarType.error}
                                  isMultiline={true}
                                  onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                  dismissButtonAriaLabel="Close"
                              >
                              {this.state.errorMessage}
                          </MessageBar>
                    }
                    {this.state.notificationMessage && 
                          <MessageBar
                                  messageBarType={MessageBarType.success}
                                  isMultiline={true}
                                  onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                  dismissButtonAriaLabel="Close"
                              >
                              {this.state.notificationMessage}
                          </MessageBar>
                    }
                      
                    <Stack horizontal horizontalAlign="stretch" key={nanoid()} style={{height: '25px', verticalAlign: 'middle' }}>
                      <Stack.Item style={{width: '80px'}}>
                        <IconButton iconProps={{iconName: 'Edit'}} width={16} height={16} styles={{icon: {width: '16px', height: '16px'}}}  onClick={(ev?: any) => {that.props.editItem(that.state.Scheme!);}} ></IconButton>
                      </Stack.Item>
                      <Stack.Item style={{width: '120px'}}>
                        {that.state.Scheme!.Order}
                      </Stack.Item>
                      <Stack.Item style={{width: '180px'}}>
                        {that.getTypeString(that.state.Scheme!.Type)}
                      </Stack.Item>
                      <Stack.Item style={{width: '350px'}}>
                          <Link onClick={(ev?: any) => { ev!.preventDefault(); that.editItem(that.state.Scheme!); } } to={""}>
                            {that.state.Scheme!.DisplayName}
                          </Link>
                      </Stack.Item>
                      <Stack.Item style={{width: '240px'}}>
                        {that.dateToString(that.state.Scheme!.ValidFrom)}  
                      </Stack.Item>
                      <Stack.Item style={{width: '240px'}}>
                      {that.dateToString(that.state.Scheme!.ValidUntil)}  
                      </Stack.Item>
                      <Stack.Item style={{width: '350px', textAlign: 'end'}}>
                        <IconButton iconProps={{iconName: 'Copy'}} onClick={(ev?: any) => {that.props.cloneScheme(that.state.Scheme!);}} ></IconButton>
                      </Stack.Item>
                    </Stack>
                    {that.props.Scheme && that.props.Scheme.SchemeType === 0 && 
                      <Stack horizontal horizontalAlign="stretch" key={nanoid()}>
                        <Stack.Item style={{width: '10%'}}>
                          &nbsp;
                        </Stack.Item>
                        <Stack.Item style={{width: '90%'}}>
                          <Stack verticalFill horizontalAlign="stretch" style={{marginTop: '15px'}}>
                            <Stack horizontal horizontalAlign="stretch" style={{borderBottom: '1px solid black', backgroundColor: '#C0DFE5'}}>
                              <Stack.Item align="center" style={{width: '100%', alignContent: 'center', textAlign: 'center'}}>
                                <Label>Periodic Pricing</Label>
                              </Stack.Item>
                            </Stack>
                            <Stack horizontal horizontalAlign="stretch" style={{borderBottom: '1px solid black'}}>
                              <Stack.Item style={{width: '10%'}}>
                                &nbsp;
                              </Stack.Item>
                              <Stack.Item style={{width: '35%'}}>
                                  Name
                              </Stack.Item>
                              <Stack.Item style={{width: '35%'}}>
                                  Fixed Fee
                              </Stack.Item>
                              <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                  <IconButton iconProps={{iconName: 'Add'}} onClick={this.onAddPriceRow}></IconButton>
                              </Stack.Item>
                            </Stack>
                            {this.state.Scheme.RecurringPrices && this.state.Scheme.RecurringPrices.sort((a, b) => (a.Order < b.Order) ? -1 : 1).map(function(it: ProductRecurringPrice, idx: number){
                              return (
                                <Stack verticalFill horizontalAlign="stretch" key={nanoid()}> 
                                  <Stack horizontal horizontalAlign="stretch">
                                    <Stack.Item style={{width: '10%'}}>
                                        &nbsp;
                                    </Stack.Item>
                                    <Stack.Item style={{width: '35%'}}>
                                        {it.Name}
                                    </Stack.Item>
                                    <Stack.Item style={{width: '35%'}}>
                                        {`${it.FixedPricePart?.toFixed(2)?? ''} ${it.FixedPricePart ? ClientState?.CurrentState()?.UserProfile?.DefaultCurrency : ''}`}
                                    </Stack.Item>
                                    <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                        <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => { that.editPriceRow(it) }}></IconButton>
                                    </Stack.Item>
                                  </Stack>

                                  <Stack horizontal horizontalAlign="stretch" style={{marginTop: '15px'}}>
                                    <Stack.Item style={{width: '20%', textAlign: 'center'}}>
                                        &nbsp;
                                    </Stack.Item>
                                    <Stack.Item style={{width: '80%'}}>
                                      <Stack verticalFill horizontalAlign="stretch">
                                        <Stack.Item style={{textAlign: 'center', backgroundColor: '#C0DFE5'}}>
                                          Volume Pricing
                                        </Stack.Item>
                                        <Stack.Item>
                                          <Stack horizontalAlign="stretch" horizontal style={{borderBottom: '1px solid black'}}>
                                            <Stack.Item style={{width: '20%'}}>
                                                Min. Amount
                                            </Stack.Item>
                                            <Stack.Item style={{width: '20%'}}>
                                                Max. Amount
                                            </Stack.Item>
                                            <Stack.Item style={{width: '20%'}}>
                                                Unit Price
                                            </Stack.Item>
                                            <Stack.Item style={{width: '20%'}}>
                                                Disc. Pct
                                            </Stack.Item>
                                            <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                                <IconButton iconProps={{iconName: 'Add'}} onClick={(ev?: any) => { that.onAddVolumeRow(it); }}  ></IconButton>
                                            </Stack.Item>
                                          </Stack>
                                        </Stack.Item>
                                        {it && it.Volumes && it.Volumes.sort((a, b) => a.MinVolume < b.MinVolume ? -1 : 1).map(function(vit: ProductVolumePrice, pix:number){
                                          return (
                                            <Stack.Item key={nanoid()}>
                                              <Stack horizontalAlign="stretch" horizontal>
                                                <Stack.Item style={{width: '20%'}}>
                                                    {vit.MinVolume}
                                                </Stack.Item>
                                                <Stack.Item style={{width: '20%'}}>
                                                    {vit.MaxVolume}
                                                </Stack.Item>
                                                <Stack.Item style={{width: '20%'}}>
                                                    {`${vit.Amount?.toFixed(2)} ${ClientState.CurrentState()?.UserProfile?.DefaultCurrency}`}
                                                </Stack.Item>
                                                <Stack.Item style={{width: '20%'}}>
                                                    {vit.DiscountPercentage ? vit.DiscountPercentage.toFixed(2) : ''}
                                                </Stack.Item>
                                                <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                                    <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => { that.editVolumePriceRow(vit, it); }}  ></IconButton>
                                                </Stack.Item>
                                              </Stack>
                                            </Stack.Item>

                                          );
                                        })

                                        }
                                      </Stack>
                                        
                                    </Stack.Item>
                                  </Stack>
                                </Stack>
                              );
                            })

                            }
                          </Stack>
                        </Stack.Item>
                      </Stack>
                    }
                    {that.props.Scheme && that.props.Scheme.SchemeType === 1 && 
                      <Stack horizontal horizontalAlign="stretch" key={nanoid()}>
                        <Stack.Item style={{width: '10%'}}>
                          &nbsp;
                        </Stack.Item>
                        <Stack.Item style={{width: '90%'}}>
                          <Stack horizontal horizontalAlign="stretch" style={{marginTop: '15px'}}>
                            <Stack.Item style={{width: '20%', textAlign: 'center'}}>
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item style={{width: '80%'}}>
                              <Stack verticalFill horizontalAlign="stretch">
                                <Stack.Item style={{textAlign: 'center', backgroundColor: '#C0DFE5'}}>
                                  Volume Pricing
                                </Stack.Item>
                                <Stack.Item>
                                  <Stack horizontalAlign="stretch" horizontal style={{borderBottom: '1px solid black'}}>
                                    <Stack.Item style={{width: '20%'}}>
                                        Min. Amount
                                    </Stack.Item>
                                    <Stack.Item style={{width: '20%'}}>
                                        Max. Amount
                                    </Stack.Item>
                                    <Stack.Item style={{width: '20%'}}>
                                        Unit Price
                                    </Stack.Item>
                                    <Stack.Item style={{width: '20%'}}>
                                        Disc. Pct
                                    </Stack.Item>
                                    <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                        <IconButton iconProps={{iconName: 'Add'}} onClick={(ev?: any) => { that.onAddVolumeRowtoScheme(that.state.Scheme!); }}  ></IconButton>
                                    </Stack.Item>
                                  </Stack>
                                </Stack.Item>
                                {that.state.Scheme && that.state.Scheme.VolumePrices && that.state.Scheme.VolumePrices.sort((a, b) => a.MinVolume < b.MinVolume ? -1 : 1).map(function(vit: ProductVolumePrice, pix:number){
                                  return (
                                    <Stack.Item key={nanoid()}>
                                      <Stack horizontalAlign="stretch" horizontal>
                                        <Stack.Item style={{width: '20%'}}>
                                            {vit.MinVolume}
                                        </Stack.Item>
                                        <Stack.Item style={{width: '20%'}}>
                                            {vit.MaxVolume}
                                        </Stack.Item>
                                        <Stack.Item style={{width: '20%'}}>
                                            {`${vit.Amount?.toFixed(2)} ${ClientState.CurrentState()?.UserProfile?.DefaultCurrency}`}
                                        </Stack.Item>
                                        <Stack.Item style={{width: '20%'}}>
                                            {vit.DiscountPercentage ? vit.DiscountPercentage.toFixed(2) : ''}
                                        </Stack.Item>
                                        <Stack.Item style={{width: '20%', textAlign: 'end'}}>
                                            <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => { that.editVolumePriceRowFromScheme(vit); }}  ></IconButton>
                                        </Stack.Item>
                                      </Stack>
                                    </Stack.Item>

                                  );
                                })

                                }
                              </Stack>
                                
                            </Stack.Item>
                                                    
                          </Stack>
                        </Stack.Item>
                      </Stack>
                    }
                    {that.state.ShowRecurrencePriceEdit &&
                    <Panel
                          headerText="Edit Recurrence Price"
                          isOpen={this.state.ShowRecurrencePriceEdit}
                          onDismiss={(ev?: any) => {that.setState({EditRecurrencePrice: undefined, ShowRecurrencePriceEdit: false}); that.forceUpdate(); }}
                          closeButtonAriaLabel="Close"
                          layerProps={{ styles: { root: { zIndex: 4000 }}}}
                      >
                          <TNProductPriceSchemeRecurrenceSurfaceEditControl 
                              Price={this.state.EditRecurrencePrice!}
                              isReadOnly={this.props.isReadOnly}
                              saveItem={this.saveRecurrencPrice}
                              deleteItem={this.deleteRecurrencPrice}
                              tenantId={this.props.tenantId}
                              PageInfo={this.props.PageInfo}
                          >

                          </TNProductPriceSchemeRecurrenceSurfaceEditControl>
                      </Panel>
                    }
                    {that.state.EditVolumePrice &&
                      <Panel
                            headerText="Edit Volume Price"
                            isOpen={that.state.EditVolumePrice !== undefined}
                            onDismiss={(ev?: any) => {that.setState({EditVolumePrice: undefined}); that.forceUpdate(); }}
                            closeButtonAriaLabel="Close"
                            layerProps={{ styles: { root: { zIndex: 4000 }}}}
                        >
                            <TNProductVolumePriceSurfaceEditorControl 
                                Price={this.state.EditVolumePrice!}
                                //PriceMatrix={this.state.PriceMatrix}
                                isReadOnly={this.props.isReadOnly}
                                updateAndClose={this.saveVolumePrice}
                                deleteItem={this.deleteVolumePrice}
                            >

                            </TNProductVolumePriceSurfaceEditorControl>
                      </Panel>
                    }  
                </Stack>
            );
          }
      }

      private saveScheme = () : void => {
        let sc: ProductPriceScheme |undefined = this.state.Scheme;
        if (sc){
          if (sc.RecurringPrices){
            sc.RecurringPriceData = JSON.stringify(sc.RecurringPrices);
          }
          if (sc.VolumePrices){
            sc.VolumePriceData = JSON.stringify(sc.VolumePrices);
          }
          this.props.saveScheme(sc);
        }
      }

      private saveVolumePrice = (price: ProductVolumePrice) : void => {
        if (this.state.EditRecurrencePrice){
          if (this.state.EditRecurrencePrice.Volumes && !this.state.EditRecurrencePrice.Volumes.includes(price)){
            this.state.EditRecurrencePrice.Volumes.push(price);
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
          else{
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
        }
        else{
          if (this.state.Scheme?.VolumePrices && !this.state.Scheme.VolumePrices.includes(price)){
            this.state.Scheme?.VolumePrices.push(price);
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
          else{
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
        }
        this.saveScheme();
      }
      private deleteVolumePrice = (price: ProductVolumePrice) : void => {
        if (this.state.EditRecurrencePrice){
          if (this.state.EditRecurrencePrice.Volumes && this.state.EditRecurrencePrice.Volumes.includes(price)){
            this.state.EditRecurrencePrice.Volumes.slice(this.state.EditRecurrencePrice.Volumes.indexOf(price), 1);
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
          else{
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
        }
        else if (this.state.Scheme){
          if (this.state.Scheme.VolumePrices && this.state.Scheme.VolumePrices.includes(price)){
            this.state.Scheme.VolumePrices.slice(this.state.Scheme.VolumePrices.indexOf(price), 1);
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
          else{
            this.setState({EditRecurrencePrice: undefined, EditVolumePrice: undefined});
            this.forceUpdate();
          }
        }
        this.saveScheme();
      }

      private onAddVolumeRow = (it: ProductRecurringPrice) : void => {
        let pvp : ProductVolumePrice = new ProductVolumePrice();
        this.setState({EditRecurrencePrice : it, EditVolumePrice: pvp});
        this.forceUpdate();
      }
      private onAddVolumeRowtoScheme = (it: ProductPriceScheme) : void => {
        let pvp : ProductVolumePrice = new ProductVolumePrice();
        this.setState({EditVolumePrice: pvp});
        this.forceUpdate();
      }

      private saveRecurrencPrice = (it: ProductRecurringPrice) : void => {
        if (!this.state.Scheme){
          return;
        }
        let pc : ProductPriceScheme = this.state.Scheme;
        let prices : ProductRecurringPrice[] = pc.RecurringPrices;
        if (!prices){
          pc.RecurringPrices = new Array<ProductRecurringPrice>();
          prices = pc.RecurringPrices;
        }
        if (!prices.includes(it)){
          prices.push(it);
        }
        this.setState({Scheme: pc, EditRecurrencePrice: undefined, ShowRecurrencePriceEdit: false});
        this.forceUpdate();
        this.saveScheme();
      }

      private deleteRecurrencPrice = (it: ProductRecurringPrice) : void => {
        if (!this.state.Scheme){
          return;
        }
        let pc : ProductPriceScheme = this.state.Scheme;
        let prices : ProductRecurringPrice[] = pc.RecurringPrices;
        if (!prices){
          pc.RecurringPrices = new Array<ProductRecurringPrice>();
          prices = pc.RecurringPrices;
        }
        if (prices.includes(it)){
          pc.RecurringPrices = pc.RecurringPrices.splice(prices.indexOf(it),1);
        }
        this.setState({Scheme: pc, EditRecurrencePrice: undefined, ShowRecurrencePriceEdit: false});
        this.forceUpdate();
        this.saveScheme();
      }

      private getTypeString = (val : number) : string => {
          if (val === 0){
              return 'Default';
          }
          return 'Default';
      }
      private editItem = (it: ProductPriceScheme) : void => {
        this.props.editItem(it);
      }

      private dateToString =  (val: any) : string => {
          if (!val){
              return '';
          }
          if (typeof val === 'string'){
              return dayjs(val).format('DD/MM/YYYY');
          }
          else {
            return dayjs(val).format('DD/MM/YYYY');
          }
      }
      private onAddPriceRow = (ev? :any) : void => {
        let pp: ProductRecurringPrice = new ProductRecurringPrice();
        this.setState({EditRecurrencePrice: pp, ShowRecurrencePriceEdit: true});
        this.forceUpdate();
      }
      private editPriceRow = (it: ProductRecurringPrice) : void => {
        this.setState({EditRecurrencePrice: it, ShowRecurrencePriceEdit: true});
        this.forceUpdate();
      }
      private editVolumePriceRow = (pvp: ProductVolumePrice, it: ProductRecurringPrice) : void => {
        this.setState({EditVolumePrice: pvp, ShowRecurrencePriceEdit: false, EditRecurrencePrice: it});
        this.forceUpdate();
      }
      private editVolumePriceRowFromScheme = (pvp: ProductVolumePrice) : void => {
        this.setState({EditVolumePrice: pvp, ShowRecurrencePriceEdit: false, EditRecurrencePrice: undefined});
        this.forceUpdate();
      }
  }