import { ComboBox, DatePicker, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import Notiflix from "notiflix";
import React from "react";
import { ProductPriceScheme } from "../../designers/Entities/ProductPricing";
import { PageInfo } from "../../PageInfo";

export interface TNProductPriceSchemeDetailsEditControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    Scheme?: ProductPriceScheme;
    saveItem: (it: ProductPriceScheme) => void;
    deleteItem: (it: ProductPriceScheme) => void;
    
}

export interface TNProductPriceSchemeDetailsEditControlState  {
    errorMessage?: string;
    notificationMessage?: string;
    Scheme? : ProductPriceScheme;
    TypeOptions: IComboBoxOption[];
}
  
export class TNProductPriceSchemeDetailsEditControl extends React.Component<TNProductPriceSchemeDetailsEditControlProps, TNProductPriceSchemeDetailsEditControlState> {
      constructor(props : TNProductPriceSchemeDetailsEditControlProps){
          super(props);
          
          let to: IComboBoxOption[] = new Array<IComboBoxOption>();
          to.push({key: 0, text: 'Default'});

          this.state = {
            Scheme: this.props.Scheme,
            TypeOptions: to
          }
      }

      public componentDidMount(){
      }

      public shouldComponentUpdate(nextProps: TNProductPriceSchemeDetailsEditControlProps, nextState: TNProductPriceSchemeDetailsEditControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNProductPriceSchemeDetailsEditControlProps, prevState: TNProductPriceSchemeDetailsEditControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
            //this.loadSchemes();
        }
      }
      public render(){
          var that = this;
         
          return (
              <Stack verticalFill horizontalAlign='stretch' tokens={{childrenGap: '10'}}>
                  {this.state.errorMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.errorMessage}
                        </MessageBar>
                    }
                    {this.state.notificationMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.notificationMessage}
                        </MessageBar>
                    }
                  <TextField label="Name" placeholder="Enter a name..." readOnly={this.props.isReadOnly} value={this.state.Scheme?.DisplayName} onChange={this.updateName} ></TextField>
                  <DatePicker
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        value={this.state.Scheme?.ValidFrom}
                        onSelectDate={this.updateValidFrom}
                  />
                  <DatePicker
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        allowTextInput
                        value={this.state.Scheme?.ValidUntil ?? undefined}
                        onSelectDate={this.updateValidUntil}
                  />
                  <TextField label="Order" placeholder="" readOnly={this.props.isReadOnly} value={this.state.Scheme?.Order.toString()} onChange={this.updateOrder} type="number" ></TextField>
                  <ComboBox
                        selectedKey={this.state.Scheme?.Type}
                        label="Price Scheme Type"
                        options={this.state.TypeOptions}
                        onChange={this.updateType}
                    />
                  <PrimaryButton text="Save" style={{marginTop: '25px'}} onClick={this.onSave} ></PrimaryButton>
                  {this.state.Scheme?.Id && 
                    <PrimaryButton text="Delete" style={{marginTop: '5px'}} onClick={this.onDelete} ></PrimaryButton>
                  }
              </Stack>
          );
      }
      public updateName = (ev?: any, newValue? : string) : void => {
        if (!this.state.Scheme){
          return;
        }
          let it : ProductPriceScheme = this.state.Scheme;
          it.DisplayName = newValue ?? '';
          this.setState({Scheme: it});
          this.forceUpdate();
      }
      public updateOrder = (ev?: any, newValue? : string) : void => {
        if (!this.state.Scheme){
          return;
        }
        let it : ProductPriceScheme = this.state.Scheme;
        it.Order = newValue ? (parseInt(newValue)) : 0;
        this.setState({Scheme: it});
        this.forceUpdate();
      }
      public updateType = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        if (!this.state.Scheme){
          return;
        }
        let it : ProductPriceScheme = this.state.Scheme;
        if (option){
            it.Type = option.key as number;
        }
        this.setState({Scheme: it});
        this.forceUpdate();
      }
      private updateValidFrom = (date: Date | null | undefined) : void =>{
        if (!this.state.Scheme){
          return;
        }
        let it : ProductPriceScheme = this.state.Scheme;
          it.ValidFrom = date ?? new Date();
          this.setState({Scheme: it});
          this.forceUpdate();
      }

      private updateValidUntil = (date: Date | null | undefined) : void =>{
        if (!this.state.Scheme){
          return;
        }
        let it : ProductPriceScheme = this.state.Scheme;
        it.ValidUntil = date ?? undefined;
        this.setState({Scheme: it});
        this.forceUpdate();
      }

      private onSave = (ev?: any) : void => {
        if (!this.state.Scheme){
          return;
        }
        let it : ProductPriceScheme = this.state.Scheme;
        if (it && !it.DisplayName){
            this.setState({errorMessage : 'Name is mandatory.'});
            return;
        }

        this.props.saveItem(it);

      }
      private onDelete = (ev?: any) : void => {
        if (!this.state.Scheme){
          return;
        }
        let that = this;
        Notiflix.Confirm.show('Confirm delete',
                'Are you sure you want to remove this item?','Yes','No',
                function(){ 
                    that.props.deleteItem(that.state.Scheme!);
                },function(){
                    
                });
      }
  }