import { DetailsList, DetailsListLayoutMode, IColumn, Icon, IContextualMenuItem, IContextualMenuProps, Label, SelectionMode, Stack, TextField } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";

interface DummyFlexGridControlProps {
    Grid : IllerisNinthUI.FlexGrid;
    updateParent : () => void;
    getEntity : () => EntityDefinition;
    showEditorProperties: (ed : IllerisNinthUI.ElementBase, prop? : EntityPropertyDefinition) => void;
}

interface DummyFlexGridControlState extends DummyFlexGridControlProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ctxMenuItems : IContextualMenuProps;
  Columns: IColumn[];
  Data: any[];
}

export class DummyFlexGridControlControl extends React.Component<DummyFlexGridControlProps, DummyFlexGridControlState> {
    constructor(props: DummyFlexGridControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        Grid: props.Grid,
        showEditorProperties : props.showEditorProperties,
        Columns: new Array<IColumn>(),
        Data: new Array<any>()
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
        this.buildColumns();
        this.buildData();
    }
    private buildData =  () : void => {
      let d: any[] = new Array<any>();
      d.push({iconName: 'Back' , name : 'line 1', dateModifiedValue : '2017-05-17', modifiedBy: 'John Doe', fileSizeRaw: 2048 });
      d.push({iconName: 'Save' ,name : 'line 2', dateModifiedValue : '2010-04-20', modifiedBy: 'Lisa Smith', fileSizeRaw: 5096 });
      d.push({iconName: 'Delete' ,name : 'line 3', dateModifiedValue : '2012-09-25', modifiedBy: 'Alphonse Reimacher', fileSizeRaw: 128 });
      this.setState({Data: d});
    }

    private buildColumns = () : void => {
      const columns: IColumn[] = [
        {
          key: 'column1',
          name: 'File Type',
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          iconName: 'Page',
          isIconOnly: true,
          fieldName: 'iconName',
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: any) => (
            <Icon iconName={item.iconName} ></Icon>
          ),
        },
        {
          key: 'column2',
          name: 'Name',
          fieldName: 'name',
          minWidth: 210,
          maxWidth: 350,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Date Modified',
          fieldName: 'dateModifiedValue',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          data: 'number',
          
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Modified By',
          fieldName: 'modifiedBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'string',
          
          isPadded: true,
        },
        {
          key: 'column5',
          name: 'File Size',
          fieldName: 'fileSizeRaw',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'number',
        },
      ];
      this.setState({Columns: columns});
    }
    private buildCommandbar = () : void =>{
        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();

        this.setState({ctxMenuItems : cm});
    }

    private onDoubleClickEditor = (evt : any ) : void =>{
        debugger;
        this.props.showEditorProperties(this.state.Grid, undefined);
    }
    
    render() {
      return (
        <Stack horizontalAlign='stretch' verticalFill className='tn-designer-dummyeditor'  style={{width: '100%', minHeight: '250px' }}>
          <Label>Relation : {this.state.Grid.Name}</Label>
          <DetailsList
              items={this.state.Data}
              compact={true}
              columns={this.state.Columns}
              selectionMode={SelectionMode.multiple}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
            />
        </Stack>
      );
    }

    private _onItemInvoked = (item: any): void => {
      this.props.showEditorProperties(this.state.Grid, undefined);
    }
}
  