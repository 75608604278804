/* eslint-disable no-loop-func */
import { ColumnActionsMode, CommandBar, ConstrainMode, ContextualMenu, ContextualMenuItemType, DefaultButton, DetailsList, DetailsListLayoutMode, DirectionalHint, Dropdown, IButtonProps, IColumn, IColumnReorderOptions, ICommandBarItemProps, IconButton, IContextualMenuItem, IContextualMenuProps, IDetailsListStyles, IDropdownOption, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, PrimaryButton, ProgressIndicator, SelectionMode, Stack, TextField } from "@fluentui/react";
import Notiflix from "notiflix";
import React from "react";
import { ClientState } from "../../ClientState";
import { EntityPersistanceManager } from "../../Helpers/EntityPersistanceManager";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Guid } from "../../Guid";
import { DesignTimeModelHelper } from "../../Helpers/DesignTimeModelHelper";
import { DragInfo } from "../DragInfo";
import { EntityDefinition } from "../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";
import { EntityPropertyDataType } from "../Entities/EntityPropertyDataType";
import { EntityRelationDefinition } from "../Entities/EntityRelation";
import { EntityViewDefinition } from "../Entities/EntityView";
import { SystemApplicationInfo } from "../Entities/SystemApplicationInfo";
import { QueryFilterBuilderControl } from "../QueryFilterBuilder";

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'scroll',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: '60vh',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'auto',
    },
  };

export interface TNDesignTimeEntityViewDesignerProps {
    entInfo: EntityDefinition;
    tenantId : string;
    viewItem?: EntityViewDefinition;
    app: SystemApplicationInfo;
}

export interface TNDesignTimeEntityViewDesignerState {
    errorMessage? : string;
    cmdBarItems : ICommandBarItemProps[];
    cmdBarFarItems : ICommandBarItemProps[];
    cmdBarOverFlowItems : ICommandBarItemProps[];
    allProperties : Array<EntityPropertyDefinition>;
    allRelations : Array<EntityRelationDefinition>;
    gridColumns : IColumn[];
    dataRows : Array<any>;
    currentView : IllerisNinthUI.GridView | null;
    gridHeaderContextMenu?: IContextualMenuProps;
    showPanel : boolean;
    currentColumn? : IllerisNinthUI.GridColumn;
    stateKey : number;
    relationOptions : Array<IDropdownOption>;
    currentRelationIndex : string;
    currentRelationPathPrefix : string;
    dragElement? : any | null;
    app: SystemApplicationInfo;
    enableViewSelection: boolean;
    isbusyMessage: string;
    availableSortColumns: string[];
}

export class TNDesignTimeEntityViewDesignerControl extends React.Component<TNDesignTimeEntityViewDesignerProps, TNDesignTimeEntityViewDesignerState> {
    constructor(props : TNDesignTimeEntityViewDesignerProps){
        super(props);

        let gv: IllerisNinthUI.GridView | null = null;
        if (this.props.viewItem && this.props.viewItem.ViewData){
            gv = IllerisNinthUI.GridView.ParseViewData(this.props.viewItem.ViewData);
        }
        else if (this.props.viewItem && this.props.viewItem.ViewXml){
            gv = IllerisNinthUI.GridView.ParseViewData(this.props.viewItem.ViewXml);
        }
        else {
            gv = new IllerisNinthUI.GridView();
        }
        gv.EntityName = this.props.entInfo.LogicalName;
        gv.ApplicationId = this.props.app.Id;
        gv.ApplicationIdDisplayName = this.props.app.DisplayName;
        gv.ApplicationIdName = this.props.app.AppCode;

        this.state ={
            errorMessage : '',
            cmdBarItems : new Array<ICommandBarItemProps>(),
            cmdBarFarItems : new Array<ICommandBarItemProps>(),
            cmdBarOverFlowItems : new Array<ICommandBarItemProps>(),
            allProperties : new Array<any>(),
            allRelations : new Array<any>(),
            gridColumns : new Array<IColumn>(),
            dataRows : new Array<any>(),
            currentView : gv,
            showPanel : false,
            stateKey : Math.random(),
            relationOptions : new Array<IDropdownOption>(),
            currentRelationIndex : '0',
            currentRelationPathPrefix : '',
            app: props.app,
            enableViewSelection: props.viewItem? false: true,
            isbusyMessage: '',
            availableSortColumns: new Array<string>()
        }
        this._onColumnHeaderClick.bind(this);
        this.onChangeSortOrderProperty.bind(this);

        if (this.state.currentView){
            this.renderViewColumns(this.state.currentView);
        }
    }

    private buildMenu = () : void =>{
        var cmdBar : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        let that = this;
        cmdBar.push({
            key: 'refresh',
            text: 'Refresh',
            // This needs an ariaLabel since it's icon-only
            ariaLabel: 'Refresh',
            label: 'Refresh',
            ariaDescription: 'Refresh the screen',
            iconOnly: false,
            iconProps: { iconName: 'Refresh' },
            onClick: () => that.loadEntity(),
          });
        //   cmdBar.push({
        //     key: 'add',
        //     text: 'Add',
        //     // This needs an ariaLabel since it's icon-only
        //     ariaLabel: 'Add',
        //     label: 'Add',
        //     ariaDescription: 'Create a new view',
        //     iconOnly: false,
        //     iconProps: { iconName: 'Add' },
        //     onClick: () => this.onCreateNewView(),
        //   });
        //   cmdBar.push({
        //     key: 'clone',
        //     text: 'Clone',
        //     // This needs an ariaLabel since it's icon-only
        //     ariaLabel: 'Clone',
        //     label: 'Clone',
        //     ariaDescription: 'Clone the current view',
        //     iconOnly: false,
        //     iconProps: { iconName: 'DuplicateRow' },
        //     onClick: () => this.onCloneCurrentView(),
        //   });
          cmdBar.push({
            key: 'save',
            text: 'Save',
            label: 'Save',
            // This needs an ariaLabel since it's icon-only
            ariaLabel: 'Save',
            ariaDescription: 'Save the current changes',
            iconOnly: false,
            iconProps: { iconName: 'Save' },
            onClick: () => this.onSaveViews(),
          })
        
        this.setState({cmdBarFarItems : cmdBar});
    }
    // private onCreateNewView = () : void =>{
    //     let newViewObj : IllerisNinthUI.GridView  = new IllerisNinthUI.GridView ();
    //     newViewObj.EntityName = this.props.entityName;
    //     newViewObj.Id = Guid.empty();
    //     newViewObj.Name = 'New View';
    //     newViewObj.addColumn('DisplayName', 'string', 'DisplayName').EntityName = this.props.entityName;
    //     newViewObj.addColumn('CreateDate', 'date', 'CreateDate').EntityName = this.props.entityName;
    //     let allViews : Array<IllerisNinthUI.GridView> =  this.state.allViews;
    //     allViews.push(newViewObj);
    //     this.setState({allViews : allViews});
    // }
    // private onCloneCurrentView = () : void =>{
    //     if (this.state.currentView){
    //         let  viewJson :string  = JSON.stringify(this.state.currentView);
    //         let viewObj : IllerisNinthUI.GridView = JSON.parse(viewJson) as IllerisNinthUI.GridView;
    //         let newViewObj : IllerisNinthUI.GridView  = new IllerisNinthUI.GridView ();
    //         newViewObj.EntityName = viewObj.EntityName;
    //         newViewObj.FilterClause = viewObj.FilterClause;
    //         newViewObj.Id = Guid.empty();
    //         newViewObj.Name = viewObj.Name + ' Clone';
    //         newViewObj.Columns = viewObj.Columns;
    //         let allViews : Array<IllerisNinthUI.GridView> =  this.state.allViews;
    //         allViews.push(newViewObj);
    //         this.setState({allViews : allViews});
    //     }
    // }

    private onSaveViews = () : void =>{
        this.saveEntityViews();
    }
    // private loadApplications = () : void =>{
    //     //var viewUri = '/api/v2.0/terranova/xdata/core/runtime/apps';
    //     var cs : ClientState = ClientState.CurrentState();
    //     var viewUri = 'api/v1.0/terranova/metamodel/' + cs.UserProfile?.TerraNovaTenantId + '/apps';
    //     var that = this;

    //     var epm : EntityPersistanceManager = new EntityPersistanceManager();
    //     epm.executeAPI(viewUri, null, 'GET', false)
    //         .then(function(result: any){
    //             if (result && result.Values) {
    //                 that.setState({allApps : result.Values});
    //             }
    //         })
    //         .catch(function(err : any){
    //             console.log('Failed to load applications : ' + err.message);
    //             IllerisNinthUI.NotificationManager.showError('Failed to load applications', err.message);
    //         });
    // }
    
    private saveEntityViews = async () : Promise<void> =>{
        debugger;
        var that = this;
        var appId : string = this.state.app.Id;
        if (!appId){
            IllerisNinthUI.NotificationManager.showError('Cannot save view', 'No application provided.');
            return;
        }
        let vw : IllerisNinthUI.GridView | undefined | null = this.state.currentView;
        if (this.props.viewItem && vw) {
            vw.ViewData = vw.toXmlString();
            let vi: EntityViewDefinition = this.props.viewItem;
            vi.ViewData = vw.ViewData;

            let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
            mh.updateEntityView(this.props.tenantId, this.props.app.Id, this.props.entInfo, vi)
                .then(function(res: any){

                })
                .catch(function(err: any){
                    that.setState({errorMessage: err.message});
                })
        }
        // for(var x = 0; x<this.state.allViews.length; x++){
        //     var vw : IllerisNinthUI.GridView = this.state.allViews[x];
        //     if (vw){
        //         vw.EntityName = this.props.entityName;
        //         vw.DisplayName = vw.Name ?? '';
        //         vw.ViewName = vw.Name ?? '';
        //         if (!vw.ApplicationId || vw.ApplicationId === Guid.empty()){
        //             vw.ApplicationId = appId;
        //         }
        //         vw.ViewData = vw.toXmlString();
        //         await epm.executeAPIAny(viewUri, vw, (vw.Id === Guid.empty() ? 'POST' : 'POST'),true)
        //             .then(function(res: any){
        //                 if (res && res.Value){
        //                     IllerisNinthUI.NotificationManager.showSuccess('Save views', 'The views have been saved.');
        //                     //allViews.push(res.Value as IllerisNinthUI.GridView);
        //                     var vw : IllerisNinthUI.GridView = IllerisNinthUI.GridView.ParseViewData(res.Value.ViewData);
        //                     vw.Id = res.Value.Id;
        //                     vw.EntityName = res.Value.EntityName;
        //                     vw.ApplicationId = res.Value.ApplicationId;
        //                     vw.ApplicationIdName = res.Value.ApplicationIdName ?? that.state.currentAppName;
        //                     vw.ViewName = res.Value.ViewName;
        //                     vw.DisplayName = res.Value.DisplayName;
        //                     allViews.push(vw);
        //                 }
        //             })
        //             .catch(function (err: any){
        //                 if (err.ErrorMessages){
        //                     console.log('Failed to load grid views : ' + err.ErrorMessages.join('. '));
        //                     IllerisNinthUI.NotificationManager.showError('Failed to save entity views', err.ErrorMessages.join('. '));
        //                 }
        //                 else{
        //                     console.log('Failed to load grid views : ' + err.message);
        //                     IllerisNinthUI.NotificationManager.showError('Failed to save entity views', err.message);
        //                 }
        //             });

        //     }
        // }
        // this.setState({allViews : allViews});
    }

    private loadEntity = (entId : string | null = null) : void =>{
        let cs: ClientState = ClientState.CurrentState();
        if (!cs.UserProfile || !cs.UserProfile.MicrosoftTenantId){
            IllerisNinthUI.NotificationManager.showError('Invalid State', 'The TenantID is NULL');
            return;
        }
        //api/v2.0/terranova/xdesigner/{tenantid}/{appid}/{entityid}/properties
        let viewUri: string = 'api/v2.0/terranova/xdesigner/' + cs.UserProfile?.MicrosoftTenantId + `/${this.props.app.Id}/` + (entId ?? this.props.entInfo.Id) + '/properties';
        //var viewUri = 'api/v1.0/terranova/metamodel/' + cs.UserProfile?.MicrosoftTenantId + '/' + (entName ? entName : this.props.entityName) + '/properties';
        let that = this;

        that.setState({isbusyMessage: 'Loading Entity...'});
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        epm.executeAPI(viewUri, null, 'GET', false)
            .then(function(result: any){
                if (result && result.Values) {
                    that.setState({allProperties : result.Values as EntityPropertyDefinition[], isbusyMessage: ''});
                }
                else if (result && result.values) {
                    that.setState({allProperties : result.values as EntityPropertyDefinition[], isbusyMessage: ''});
                }
            })
            .catch(function(err : any){
                console.log('Failed to load entity properties : ' + err.message);
                IllerisNinthUI.NotificationManager.showError('Failed to load entity properties', err.message);
            });

        if (this.state.currentView){
            that.renderViewColumns(this.state.currentView);
        }
        
    }
    private loadEntityRelations = () : void =>{
        let cs: ClientState = ClientState.CurrentState();
        if (!cs.UserProfile || !cs.UserProfile.MicrosoftTenantId){
            IllerisNinthUI.NotificationManager.showError('Invalid State', 'The TenantID is NULL');
            return;
        }

        let viewUri: string = 'api/v2.0/terranova/xdesigner/' + cs.UserProfile?.MicrosoftTenantId + `/${this.props.app.Id}/` + (this.props.entInfo.Id) + '/relations';
        //var viewUri = 'api/v1.0/terranova/metamodel/' + cs.UserProfile?.MicrosoftTenantId + '/' + this.props.entityName + '/relations';
        var that = this;
        that.setState({isbusyMessage: 'Loading Relations...'});
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        epm.executeAPI(viewUri, null, 'GET', false)
            .then(function(result: any){
                if (result && result.Values) {
                    that.setState({allRelations : result.Values as EntityRelationDefinition[], isbusyMessage: ''});
                    that.buildRelationsCombo(result.Values as EntityRelationDefinition[]);
                }
                else if (result && result.values) {
                    that.setState({allRelations : result.values as EntityRelationDefinition[], isbusyMessage: ''});
                    that.buildRelationsCombo(result.values as EntityRelationDefinition[]);
                }
            })
            .catch(function(err : any){
                console.log('Failed to load entity relations : ' + err.message);
                IllerisNinthUI.NotificationManager.showError('Failed to load entity relations', err.message);
            });
    }

    private buildRelationsCombo = (rels : Array<EntityRelationDefinition>) : void =>{
        let opts : Array<IDropdownOption> = new Array<IDropdownOption>();
        opts.push({key : 0, text : ''});
        for(var x = 0; x<rels.length; x++){
            let opt : IDropdownOption ={
                key : (x+1).toString(),
                text : rels[x].DisplayName,
            }
            opts.push(opt);
        }
        this.setState({relationOptions : opts});
    }

    componentDidMount(){
        //this.loadApplications();
        this.buildMenu();
        this.loadEntity();
        this.loadEntityRelations();
    }
    closeMessageBar = () => {
        this.setState({ errorMessage: '' });
    };

    private onRenderViewListItemCell = (item: any, index: number | undefined): JSX.Element => {
        return (
          <div className='tn-entitydesigner-listviewcell ms-Button ms-Button--default' data-is-focusable={true}>
            {item.Name}
          </div>
        );
      };
    private renderDummyData = (vw : IllerisNinthUI.GridView) : void =>{
        var gvData : Array<any> = new Array<any>();
        for(var i = 0; i<10; i++){
            var newIt : any = {};
            for(var x = 0; x<vw.Columns.length; x++){
                var col : IllerisNinthUI.GridColumn = vw.Columns[x];
                if (col){
                    if (col.DataType?.toLowerCase() === 'string'){
                        newIt[col.FieldName] = 'Item ' + (x+1).toString();
                    }
                    else if (col.DataType?.toLowerCase() === 'number'){
                        newIt[col.FieldName] = Math.random();
                    }
                    else if (col.DataType?.toLowerCase() === 'float' || col.DataType?.toLowerCase() === 'double') {
                        newIt[col.FieldName] = Math.random() / 100.0;
                    }
                    else if (col.DataType?.toLowerCase() === 'guid'){
                        newIt[col.FieldName] = Guid.newGuid().toString();
                    }
                    else if (col.DataType?.toLowerCase() === 'date') {
                        newIt[col.FieldName] = new Date().toDateString();
                    }
                    else if (col.DataType?.toLowerCase() === 'datetime') {
                        newIt[col.FieldName] = new Date().toString();
                    }
                }
            }
            gvData.push(newIt);
        }
        this.setState({dataRows : gvData});
    }
    private renderViewColumns = (vw : IllerisNinthUI.GridView) : void =>{
        var gvCols : Array<IColumn> = new Array<IColumn>();
        let colNames : Array<string> = new Array<string>();
        for(var x = 0; x<vw.Columns.length; x++){
            var col : IllerisNinthUI.GridColumn = vw.Columns[x];
            if (col){
                var newCol : IColumn ={
                    key: x.toString(),
                    name: col.HeaderText,
                    ariaLabel: col.HeaderText,
                    fieldName: col.FieldName,
                    isResizable : true,
                    isCollapsible: true,
                    isPadded : true,
                    isSorted : false,
                    //columnActionsMode: ColumnActionsMode.clickable,
                    minWidth: isNaN(parseInt(col.Width)) ? 100 : parseInt(col.Width),
                    maxWidth: isNaN(parseInt(col.Width)) ? 100 : 2* parseInt(col.Width),
                  }
                if (newCol.fieldName && colNames.indexOf(newCol.fieldName) === -1){
                    gvCols.push(newCol);
                    colNames.push(newCol.fieldName);
                }
            }
        }
        for (var i = 0; i < gvCols.length; i++) {
            gvCols[i].onColumnClick = this._onColumnHeaderClick.bind(this);
        }

        this.renderDummyData(vw);
        this.setState({gridColumns : gvCols});
    }


//     private setCurrentView = (viewIdx : number) : void =>{

//         if (viewIdx >= 0 && viewIdx < this.state.allViews.length){
//             var vw : IllerisNinthUI.GridView = this.state.allViews[viewIdx];
//             if (vw){
//                 this.renderViewColumns(vw);
//                 this.setState({currentView : this.state.allViews[viewIdx]});
//             }
//         }
// /*
//         for(var i=0; i<this.state.allViews.length; i++){
//             if (this.state.allViews[i].Id === viewId){
//                 var vw : IllerisNinthUI.GridView = this.state.allViews[i];
//                 this.renderViewColumns(vw);
//                 this.setState({currentView : this.state.allViews[i]});
//                 return;
//             }
//         }*/
//     }
    private onListViewSelectionChanged = (it : any) : void =>{
        alert('Hi - item selected');
    }
    // private onShowViewClicked = (btn: any) : void =>{
    //     //if (btn && btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.viewid){
    //     if (btn && btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.viewidx){
    //         this.setCurrentView(parseInt(btn.currentTarget.dataset.viewidx.toString()));
    //     }
    // }
    private _onColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            this.setState({
                gridHeaderContextMenu: this._getContextualMenuProps(ev, column),
            });
        }
    };

    private onDeleteColumn = (column : IColumn) : void =>{
        var that = this;
        Notiflix.Confirm.init({ zindex: 10000});
        Notiflix.Confirm.show('Confirm delete',
                'Are you sure you want to remove this column?','Yes','No',
                function(){ 
                    var gv : IllerisNinthUI.GridView | undefined | null = that.state.currentView;
                    if (gv){
                        var cols : Array<IllerisNinthUI.GridColumn> = gv.Columns.splice(parseInt(column.key), 1);
                        if (cols && cols.length > 0){
                            var propName : string = cols[0].FieldName;
                            if (gv.OrderByClause){
                                gv.OrderByClause = gv.OrderByClause?.replace(propName + ' ASC', '').replace(propName + ' DESC', '');
                                gv.OrderByClause = gv.OrderByClause?.replace(';;',';');
                            }
                        }
                        that.renderViewColumns(gv);
                    }
                },function(){
                    
                });
    }
    private onMoveColumn = (column : IColumn, dir :number) : void =>{
        var colIdx : number = parseInt(column.key);
        if (isNaN(colIdx)){
            return;
        }
        //var colIdx : number = this.state.gridColumns.indexOf(column);
        var vw : IllerisNinthUI.GridView | null = this.state.currentView;
        if (colIdx >= 0 && vw){
            if (dir < 0){
                if (colIdx > 0){
                    var cols1 = vw.Columns.splice(colIdx, 1);
                    vw.Columns.splice(colIdx-1, 0, ...cols1);
                    this.renderViewColumns(vw);
                }
            }
            else if (dir > 0){
                if (colIdx < vw.Columns.length - 1){
                    var cols2 = vw.Columns.splice(colIdx, 1);
                    vw.Columns.splice(colIdx+1, 0, ...cols2);
                    this.renderViewColumns(vw);
                }
            }
        }
    }

    private _getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
        var that = this;
        var items : IContextualMenuItem[] = [
            {
                key: 'delete',
                name: 'Delete Column',
                iconProps: { iconName: 'Delete' },
                //canCheck: true,
                //checked: column.isSorted && !column.isSortedDescending,
                onClick: () => { that.onDeleteColumn(column); },
            },
            {
                key: 'moveleft',
                name: 'Move Left',
                iconProps: { iconName: 'ChevronLeftSmall' },
                //canCheck: true,
                //checked: column.isSorted && !column.isSortedDescending,
                onClick: () => { that.onMoveColumn(column, -1); },
            },
            {
                key: 'moveright',
                name: 'Move Right',
                iconProps: { iconName: 'ChevronRightSmall' },
                //canCheck: true,
                //checked: column.isSorted && !column.isSortedDescending,
                onClick: () => { that.onMoveColumn(column, 1); },
            },
            {
                key: 'insertleft',
                name: 'Insert Left',
                iconProps: { iconName: 'DoubleChevronLeft' },
                subMenuProps: {
                    items : this._getColumnSubItems(column, -1)
                }
                //onClick: () => { that.on(column, 1); },
                
            },
            {
                key: 'insertright',
                name: 'Insert Right',
                iconProps: { iconName: 'DoubleChevronRight' },
                subMenuProps: {
                    items : this._getColumnSubItems(column, 1)
                }
            },
            {
                key: 'edit',
                name: 'Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => { that._editColumn(column); },
                
            },
            {
                key: 'divider',
                itemType: ContextualMenuItemType.Divider
            }
        ];
        
        return {
            items: items,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomLeftEdge,
            gapSpace: 10,
            isBeakVisible: true,
            onDismiss: this._onContextualMenuDismissed,
        };
    }
    private _editColumn = (column: IColumn) : void =>{
        var idx = parseInt(column.key);
        if (!isNaN(idx)){
            this.setState({showPanel : true, currentColumn : this.state.currentView?.Columns[idx]});
        }
    }
    private _getColumnSubItems = (column: IColumn, dir : number) : Array<IContextualMenuItem> => {
        var that = this;
        var res : Array<IContextualMenuItem> = new Array<IContextualMenuItem>();
        var curCols : Array<any> =  this.state.allProperties;
        if (curCols && Array.isArray(curCols) && curCols.length > 0){
            for(var i = 0; i<curCols.length; i++){
                //var idx : number = i;
                res.push({
                    text : curCols[i].DisplayName,
                    key : curCols[i].DisplayName,
                    data : i,
                    //(data as any)['datatype'] : curCols[i].DataType,
                    ariaLabel: curCols[i].DisplayName,
                    onClick: (ev? : any, item? : IContextualMenuItem) => { 
                        //that.onInsertColumn(column,curCols[idx],dir ); 
                        that.onInsertColumn(column,isNaN(parseInt(item?.data)) ? -1 : parseInt(item?.data) ,dir ); 
                    },
                })
            }
        }
        return res;
    }

    private onInsertColumn = (column : IColumn, colIdx : number, dir: number) : void =>{
        var that = this;
        if (colIdx < 0){
            return;
        }
        var gv : IllerisNinthUI.GridView | undefined | null = that.state.currentView;
        var propertyInfo : any = that.state.allProperties[colIdx];

        if (gv){
            //var col : IllerisNinthUI.GridColumn | null = gv.Columns[parseInt(column.key)];
            
            var newCol : IllerisNinthUI.GridColumn = {
                DataType : propertyInfo.DataType ?? 'string',
                EntityName : that.props.entInfo.LogicalName,
                FieldName : propertyInfo.DisplayName,
                HeaderText : propertyInfo.DisplayName,
                Width : '200',
                Format : '',
                EditLink : '',
                Style : '',
                EmptyValue : '',
                LinkCommand: '',
                IsVisible: true
            }
            if (dir < 0){
                gv.Columns.splice(parseInt(column.key),0, newCol);
            }
            else{
                if (parseInt(column.key) < gv.Columns.length-1){
                    gv.Columns.splice(parseInt(column.key)+1,0, newCol);
                }
                else{
                    gv.Columns.push(newCol);
                }
            }
            that.renderViewColumns(gv);
            that.setState({currentView : gv});
        }
    }

    private _onContextualMenuDismissed = (): void => {
        this.setState({
            gridHeaderContextMenu: undefined,
        });
    };

    
      private _handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
        const draggedItems = this.state.gridColumns[draggedIndex];
        const newColumns: IColumn[] = [...this.state.gridColumns];
    
        // insert before the dropped item
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);
        this.setState({ gridColumns: newColumns });

        var currentView : IllerisNinthUI.GridView | null | undefined= this.state.currentView;
        if (currentView){
            var delCol : IllerisNinthUI.GridColumn[] | undefined | null= currentView.Columns.splice(draggedIndex, 1);
            if (delCol){
                currentView.Columns.splice(targetIndex,0, ...delCol);
                this.setState({currentView : currentView});
            }
        }
      };
      private _getColumnReorderOptions(): IColumnReorderOptions {
        return {
          frozenColumnCountFromStart: 0,
          frozenColumnCountFromEnd: 0,
          handleColumnReorder: this._handleColumnReorder,
        };
      }
      private dismissPanel = () : void =>{
          var curEl : IllerisNinthUI.GridColumn | null | undefined = this.state.currentColumn;
          if (curEl){
              if (!curEl.DataType || !curEl.FieldName || !curEl.HeaderText || !curEl.Width){
                  return;
              }
          }
          var curView = this.state.currentView;
          if (curView){
            this.renderViewColumns(curView);
          }
          this.setState({showPanel : false});
      }

      private onAddPropertyClicked = (index : number) : void =>{
          var props = this.state.allProperties;
          var xvw = this.state.currentView;
          if (!xvw){
              return;
          }
          if (index >= 0 && index <= props.length){
              var prop = props[index];
              if (prop){
                  let col : IllerisNinthUI.GridColumn ={
                      DataType : EntityPropertyDataType.numberToDataType(prop.DataType).Name,
                      HeaderText : prop.DisplayName,
                      FieldName : this.state.currentRelationPathPrefix ? this.state.currentRelationPathPrefix + '.' + prop.DisplayName : prop.DisplayName,
                      EntityName : this.props.entInfo.LogicalName,
                      Width : '100',
                      EmptyValue : '',
                      Format : prop.DataType === 3 ? 'dd/MM/yyyy' : '',
                      EditLink : '',
                      Style : '',
                      LinkCommand: '',
                      IsVisible: true
                  }
                  var vw : IllerisNinthUI.GridView | null | undefined = this.state.currentView;
                  if (vw){
                      vw.Columns.push(col);
                      
                      this.renderViewColumns(vw);
                      this.setState({currentView : vw, stateKey : Math.random()});
                      this.forceUpdate();
                  }
              }
          }
      }

    render () {
        var that = this;
        const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
        var wndWidth : number = window.innerWidth;

        return (
            <Stack verticalFill horizontalAlign='stretch' className='tn-entity-view-designer'>
                <Stack horizontal horizontalAlign='stretch' >
                    <CommandBar
                        items={this.state.cmdBarItems}
                        overflowItems={this.state.cmdBarOverFlowItems}
                        overflowButtonProps={overflowProps}
                        farItems={this.state.cmdBarFarItems}
                        ariaLabel="Use left and right arrow keys to navigate between commands"
                        className='tn-flexgrid-commandbar'
                    />
                </Stack>
                {this.state.errorMessage && 
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        onDismiss={() => this.closeMessageBar()}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.errorMessage}
                </MessageBar>
                }
                {that.state.isbusyMessage && 
                    <ProgressIndicator label={that.state.isbusyMessage} description="" />
                }
                <Stack horizontal style={{width : '100%'}} >
                    
                    <Stack verticalFill style={{width : '250px', marginLeft: '20px'}}>
                        <label className='tn-section-header'>Relations &amp; Properties</label>
                        {this.props.viewItem && this.state.currentView &&
                            <>
                            <Dropdown
                                    placeholder="Select a relation"
                                    label='Relation'
                                    options={this.state.relationOptions}
                                    selectedKey={this.state.currentRelationIndex}
                                    onChange={this.onRelationChanged}
                                    style={{marginBottom : '15px'}}
                                />
                            <label className='ms-Label ms-Dropdown-label' style={{fontWeight: 600, marginBottom: '3px'}}>Properties</label>
                            </>
                        }
                        {this.props.viewItem && this.state.currentView && this.state.allProperties.map(function(item : any, index: number, allItems: any[]){
                                        return (<DefaultButton key={`propBtn_${index}`} className='tn-entity-view-designer-propertybutton' iconProps={{iconName : that.getFieldIconName(item)}} text={item.DisplayName ?? ''} onClick={() => that.onAddPropertyClicked(index)}  ></DefaultButton>)
                                    })}
                    </Stack>
                    <Stack verticalFill style={{width : (wndWidth - 500).toString() + 'px', marginLeft: '20px'}}>
                        <label className='tn-section-header'>View Details</label>
                        {this.state.currentView &&
                            <TextField value={this.state.currentView?.Name ?? ''} onChange={this.onViewNameEditChanged} ></TextField>
                        }
                        <Pivot aria-label="View Details">
                            <PivotItem headerText="Columns"  itemIcon="View">
                            <Stack verticalFill horizontalAlign='stretch'>
                                <div data-is-scrollable="true" style={{ overflow: 'auto', }}>
                                <DetailsList className='tn-entity-view-designer-view'
                                                items={this.state.dataRows}
                                                compact={true}
                                                columns={this.state.gridColumns}
                                                selectionMode={SelectionMode.none}
                                                columnReorderOptions={this._getColumnReorderOptions()}
                                                //getKey={this._getKey}
                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                setKey="none"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                styles={gridStyles}
                                                isHeaderVisible={true}
                                                isPlaceholderData={true}
                                                
                                                //onItemInvoked={this._onItemInvoked}
                                            />
                                </div>
                                {that.state.gridHeaderContextMenu && <ContextualMenu {...that.state.gridHeaderContextMenu} />}
                            </Stack>
                            </PivotItem>
                            <PivotItem headerText="Sort" itemIcon="Sort">
                                <Stack horizontal horizontalAlign='stretch'>
                                    <Stack horizontal style={{width: '50%'}}>
                                        <Stack verticalFill horizontalAlign='center' style={{width: '60%'}}>
                                            <label className='tn-section-header'>Sort By</label>
                                            {this.state.currentView && this.state.currentView.OrderByClause && this.state.currentView.OrderByClause.split(';').map(function(item : string, index: number, allItems: string[]){
                                                return (item && 
                                                    <Stack horizontal horizontalAlign="stretch">
                                                    <DefaultButton
                                                     className='tn-entity-view-designer-propertybutton' 
                                                     iconProps={{iconName : (item.indexOf(' DESC') === -1 ? 'Ascending' : 'Descending')}} 
                                                     style={{width:'200px'}} text={item.indexOf(' ') === -1 ? item : item.substring(0, item.indexOf(' '))} 
                                                     onClick={that.onChangeSortOrderProperty} 
                                                     key={index} 
                                                     data-index={index}
                                                     data-type='sortbutton'
                                                     data-propertyname={item} 
                                                     onDoubleClick={that.onRemoveSortProperty} 
                                                     onDragStart={that.onDragStartSortColumnButton} 
                                                     draggable={true} 
                                                     onDrop={that.onDragDropSortColumnButton} 
                                                     onDragOver={that.onDragOverSortColumnButton} ></DefaultButton>
                                                     <IconButton iconProps={{iconName: 'Delete'}} style={{marginLeft: '10px', width:'24px', height:'24px'}} onClick={(ev?: any) => { that.onRemoveSortPropertyByName(item);}} ></IconButton>
                                                     </Stack>
                                                     )
                                            })}
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal style={{width: '50%'}}>
                                        <Stack verticalFill horizontalAlign='center' style={{width: '60%'}}>
                                            <Stack horizontalAlign="stretch" horizontal>
                                                <label className='tn-section-header'>Remaining Columns</label>
                                                <IconButton iconProps={{iconName: 'Refresh'}} style={{marginLeft: '50px'}} onClick={(ev?: any) => { that.setState({availableSortColumns: that.getAvailableSortColumns()}); that.forceUpdate(); }} ></IconButton>
                                            </Stack>
                                            {this.state.currentView &&
                                             that.state.availableSortColumns.map(function(item : string, index: number, allItems: string[]){
                                                return (<DefaultButton 
                                                    className='tn-entity-view-designer-propertybutton' 
                                                    iconProps={{iconName : 'Sort'}} 
                                                    text={item} style={{width:'200px'}}  
                                                    onClick={that.onAddPropertyToSort} 
                                                    key={index}
                                                    data-index={index}
                                                    data-type='sortablepropertybutton'
                                                    onDragStart={that.onDragStartSortablePropertyColumnButton} 
                                                    draggable={true}
                                                    data-propertyname={item} 
                                                   // onDoubleClick={(ev?: any) => {that.onAddSortColumn(item);}}
                                                    
                                                    ></DefaultButton>)
                                            })}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </PivotItem>
                            <PivotItem headerText="Filter" itemIcon="Filter">
                                <QueryFilterBuilderControl allProps={this.state.allProperties} 
                                        entityName={this.props.entInfo.LogicalName} 
                                        tenantId={this.props.tenantId} 
                                        EntityView={this.state.currentView}
                                >
                                </QueryFilterBuilderControl>
                            </PivotItem>
                        </Pivot>
                       
                    </Stack>
                    
                </Stack>
                <Panel
                    isOpen={this.state.showPanel}
                    onDismiss={this.dismissPanel}
                    type={PanelType.medium}
                    closeButtonAriaLabel="Close"
                    headerText="Column Details"
                >
                    <Stack verticalFill horizontalAlign='stretch'>
                         <TextField label='Property Name' value={this.state.currentColumn?.FieldName} onChange={this.onPanelTextChanged} data-data='FieldName' required   ></TextField>
                         <TextField label='Header Text' value={this.state.currentColumn?.HeaderText} onChange={this.onPanelTextChanged}  data-data='HeaderText' required ></TextField>
                         <Dropdown
                                placeholder="Select an option"
                                label='Data Type'
                                options={colDataTypeOptions}
                                selectedKey={this.state.currentColumn?.DataType}
                                onChange={this.onPanelComboChanged}
                                required
                                //styles={dropdownStyles}
                            />
                         <TextField label='Empty Value' value={this.state.currentColumn?.EmptyValue}  onChange={this.onPanelTextChanged}  data-data='EmptyValue'></TextField>
                         <TextField label='Width' value={this.state.currentColumn?.Width}   onChange={this.onPanelTextChanged} data-data='Width'  required></TextField>
                         <TextField label='Format Expression' value={this.state.currentColumn?.Format}  onChange={this.onPanelTextChanged}  data-data='Format'></TextField>
                         <TextField label='Style' value={this.state.currentColumn?.Style}  onChange={this.onPanelTextChanged}  data-data='Style'></TextField>
                         <TextField label='Edit Link' value={this.state.currentColumn?.EditLink}  onChange={this.onPanelTextChanged}  data-data='EditLink'></TextField>
                         <TextField label='Command Name' value={this.state.currentColumn?.LinkCommand}  onChange={this.onPanelTextChanged}  data-data='LinkCommand'></TextField>
                         <p>&nbsp;</p>
                         <PrimaryButton text='Close' onClick={this.dismissPanel} style={{width : '120px'}} ></PrimaryButton>
                    </Stack>
                </Panel>
                
            </Stack>
        );
    }
    // private onAddSortColumn = (colName: string): void =>{
    //     let cv : IllerisNinthUI.GridView | null = this.state.currentView;
    //     let si : string[] = this.state.availableSortColumns;
    //     if (cv){
    //         if (!cv.OrderByClause){
    //             cv.OrderByClause = `${colName} ASC;`;
    //         }
    //         else if (cv.OrderByClause.indexOf(`${colName} `) === -1)  {
    //             if (cv.OrderByClause.endsWith(';')){
    //                 cv.OrderByClause = `${cv.OrderByClause}${colName} ASC;`;
    //             }
    //             else {
    //                 cv.OrderByClause = `${cv.OrderByClause};${colName} ASC;`;
    //             }
    //         }
    //         if (si.includes(colName)){
    //             si.splice(si.indexOf(colName), 1);
    //         }
    //         this.setState({currentView : cv, availableSortColumns: si});
    //     }
    // }
    
    private onDragStartSortColumnButton = (btn : any) : void =>{
        var di : DragInfo = new DragInfo();
        di.SourceObject = btn;
        di.SourceObjectRowIndex = parseInt(btn.currentTarget.dataset.index);
        di.SourceObjectColumnIndex = 0;
        di.SourceObjectType = 'sortbutton';
        this.setState({dragElement : di});
    }
    private onDragStartSortablePropertyColumnButton = (btn : any) : void =>{
        var di : DragInfo = new DragInfo();
        di.SourceObject = btn;
        di.SourceObjectRowIndex = parseInt(btn.currentTarget.dataset.index);
        di.SourceObjectColumnIndex = 1;
        di.SourceObjectString = btn.currentTarget.dataset.propertyname;
        di.SourceObjectType = btn.currentTarget.dataset.type;
        this.setState({dragElement : di});
    }
    private onDragDropSortColumnButton = (btn : any) : void =>{
        this.setState({dragElement : btn});
        if (btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.index && btn.currentTarget.dataset.type){
            var idx : number = parseInt(btn.currentTarget.dataset.index);
            var typeStr : string = btn.currentTarget.dataset.type;
            if (typeStr === 'sortbutton' && idx >= 0){
                var di : DragInfo = this.state.dragElement as DragInfo;
                var cv : IllerisNinthUI.GridView | null = this.state.currentView;
                if (di && cv){
                    var orderByParts = cv.OrderByClause?.split(';');
                    if (di.SourceObjectColumnIndex === 0 && di.SourceObjectType === 'sortbutton' && this.state.currentView){
                        if (orderByParts){
                            var delItem : any = orderByParts.splice(di.SourceObjectRowIndex,1);
                            orderByParts.splice(idx+1,0,...delItem);
                            cv.OrderByClause = orderByParts.join(';');
                            this.setState({currentView : cv});
                        }
                    }
                    else if (di.SourceObjectColumnIndex === 1 && di.SourceObjectType === 'sortablepropertybutton' && this.state.currentView){
                        if (!orderByParts){
                            cv.OrderByClause = '';
                            orderByParts = new Array<string>();
                        }
                        if (orderByParts){
                            orderByParts.splice(idx+1,0,di.SourceObjectString + ' ASC');
                            cv.OrderByClause = orderByParts.join(';');
                            this.setState({currentView : cv});
                        }
                    }
                }
            }
        }
    }
    private onDragOverSortColumnButton = (btn : any) : void =>{
        //console.log('OnDragOver');
        btn.preventDefault();
    }
    
    private onAddPropertyToSort = (btn : any) : void =>{
        if (btn && btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.propertyname){
            var item :string = btn.currentTarget.dataset.propertyname;
            var vw : IllerisNinthUI.GridView |null | undefined = this.state.currentView;
            if (vw && this.state.currentView && this.state.currentView.OrderByClause){
                if (vw.OrderByClause && vw.OrderByClause.endsWith(';')){
                    vw.OrderByClause = vw.OrderByClause.substring(0, vw.OrderByClause.length -1);
                }
                vw.OrderByClause = vw.OrderByClause ? vw.OrderByClause + ';' + item + ' ASC;' : vw.OrderByClause + ' ASC;';
            }
            else if (vw && this.state.currentView && !this.state.currentView.OrderByClause){
                vw.OrderByClause = item + ' ASC;';
            }
            let si : string[] = this.state.availableSortColumns;
            si.splice(si.indexOf(item), 1);
            this.setState({currentView : vw,availableSortColumns: si});
        }
    }
    private onRemoveSortPropertyByName = (item: string) : void => {
        let idx: number = -1;
        let vw : IllerisNinthUI.GridView |null | undefined = this.state.currentView;
            if (vw && this.state.currentView && this.state.currentView.OrderByClause){
                let sortCols : string[] = this.state.currentView.OrderByClause.split(';');
                for(var x =0; x<sortCols.length; x++){
                    if (sortCols[x].startsWith(item)){
                        idx = x;
                    }
                }
                if (idx > -1){
                    if (sortCols.length === 1){
                        sortCols = new Array<string>();
                    }
                    else{
                       sortCols = sortCols.splice(idx,1);
                    }
                }
                vw.OrderByClause = sortCols.join(';');
            }
            this.setState({currentView : vw, availableSortColumns: this.getAvailableSortColumns()});
    }
    private onRemoveSortProperty = (btn : any) : void =>{
        debugger;
        if (btn && btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.propertyname){
            var item :string = btn.currentTarget.dataset.propertyname;
            var idx : number = -1;
            var vw : IllerisNinthUI.GridView |null | undefined = this.state.currentView;
            if (vw && this.state.currentView && this.state.currentView.OrderByClause){
                var sortCols : string[] = this.state.currentView.OrderByClause.split(';');
                for(var x =0; x<sortCols.length; x++){
                    if (sortCols[x].startsWith(item)){
                        idx = x;
                    }
                }
                if (idx > -1){
                    sortCols = sortCols.splice(idx,1);
                }
                vw.OrderByClause = sortCols.join(';');
            }
            this.setState({currentView : vw});
        }
     
    }
    private onChangeSortOrderProperty = (btn : any) : void =>{
        debugger;
        if (btn && btn.currentTarget && btn.currentTarget.dataset && btn.currentTarget.dataset.propertyname){
            var item :string = btn.currentTarget.dataset.propertyname;
            var vw : IllerisNinthUI.GridView |null | undefined = this.state.currentView;
            if (vw && this.state.currentView && this.state.currentView.OrderByClause){
                var sortCols : string[] = this.state.currentView.OrderByClause.split(';');
                for(var x =0; x<sortCols.length; x++){
                    if (sortCols[x].startsWith(item)){
                        if (sortCols[x].endsWith('ASC')){
                            sortCols[x] = item.replace(' ASC', ' DESC');
                        }
                        else{
                            sortCols[x] = item.replace(' DESC', ' ASC');
                        }
                    }
                }
                vw.OrderByClause = sortCols.join(';');
            }
            this.setState({currentView : vw});
        }
        /*var vw : IllerisNinthUI.GridView |null | undefined = this.state.currentView;
        if (this.state.currentView && this.state.currentView.OrderByClause){
            var sortCols : string[] = this.state.currentView.OrderByClause.split(';');
            for(var x =0; x<sortCols.length; x++){
                if (sortCols[x].startsWith(item)){
                    if (sortCols[x].endsWith('ASC')){
                        sortCols[x] = item + ' DESC';
                    }
                    else{
                        sortCols[x] = item + ' DESC';
                    }
                }
            }
        }
        this.setState({currentView : vw});*/
    }

    private getAvailableSortColumns = () : Array<string> =>{
        let res : Array<string> = new Array<string>();
        

        if (this.state.currentView && this.state.currentView.Columns){
            let sortColumns : Array<string> | null | undefined = this.state.currentView.OrderByClause?.split(';');
            if (sortColumns){
                for(var x = 0; x<sortColumns.length; x++){
                    sortColumns[x] = sortColumns[x].replace(' ASC', '').replace(' DESC', '');
                }
            }
            else{
                sortColumns = new Array<string>();
            }
            for(var i = 0; i<this.state.currentView.Columns.length; i++){
                let nm : string = this.state.currentView.Columns[i].FieldName;
                if (nm && sortColumns.indexOf(nm) === -1){
                    res.push(nm);
                }
            }
        }
        return res;
    }

    private getFieldIconName = (fld : any) : string =>{
        if (!fld) {
            return 'View';
        }
        var dt : IllerisNinthUI.GridColumnDataType = (fld && fld.DataType ? fld.DataType as IllerisNinthUI.GridColumnDataType : 'string');
        if (dt === 'string'){
            return 'TextField';
        }
        else if (dt === 'date' || dt === 'datetime' ){
            return 'EventDate';
        }
        else if (dt === 'boolean'){
            return 'CheckBoxComposite';
        }
        else if (dt === 'number'){
            return 'NumberField';
        }
        else if (dt === 'decimal' ){
            return 'Decimals';
        }
        else if (dt && (dt as any).toString() === 'byte[]'){
            return 'Database';
        }
        return 'View';
    }
    
    private onRelationChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
        debugger;
        if (option){
            var optIndexStr : string = option.key.toString();
            let optIdx : number = parseInt(optIndexStr);
            if (!isNaN(optIdx)){
                if (optIdx === 0){
                    this.loadEntity();
                    this.setState({currentRelationIndex : '0', currentRelationPathPrefix : ''});
                }
                else{
                    let rel : EntityRelationDefinition = this.state.allRelations[optIdx-1];
                    let newPath : string = this.state.currentRelationPathPrefix;
                    if (newPath){
                        newPath += '.' + rel.ParentPropertyLogicalName;
                    }
                    else{
                        newPath = rel.ParentPropertyLogicalName;
                    }
                    
                    this.loadEntity(rel.TargetEntityId.toString());
                    this.setState({currentRelationIndex : optIndexStr, currentRelationPathPrefix : newPath});
                }
            }
        }
    }
    private onPanelComboChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
        debugger;
        var curEl = this.state.currentColumn;
        if (curEl && option){
            curEl.DataType = option.key as IllerisNinthUI.GridColumnDataType;
            if ((curEl.DataType === 'date' || curEl.DataType === 'datetime'  )  && !curEl.Format){
                curEl.Format = 'dd/MM/yyyy';
            }
            this.setState({currentColumn : curEl});
        }
    }
    private onPanelTextChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        debugger;
        var curEl = this.state.currentColumn;
        if (curEl && event && event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.data){
            var propName : string = event.currentTarget.dataset.data;
            (curEl as any)[propName] = newValue;
            this.setState({currentColumn : curEl});
        }
        
    }
    private onViewNameEditChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
        debugger;
        var curEl = this.state.currentView;
        if (curEl && event){
            let vw : IllerisNinthUI.GridView | null = this.state.currentView;
            if (vw){
               vw.Name = newValue ?? '';
                this.setState({currentView : vw});
            }
        }
        
    }
}

const colDataTypeOptions: IDropdownOption[] = [
    { key: 'string', text: 'String'},
    { key: 'datetime', text: 'DateTime' },
    { key: 'number', text: 'Number' },
    { key: 'double', text: 'Decimal' },
    { key: 'boolean', text: 'Yes / No' },
  ];