/* eslint-disable no-loop-func */
import { IComboBoxOption } from "@fluentui/react/lib/components/ComboBox";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { IllerisNinthAPI } from "../../ServiceResult";
import { Logger } from "../../Logger";
import { SilentRequest } from "@azure/msal-browser";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

export interface TNSelectEditorState extends TNEditorFactoryState {
    DropdownOptions : Array<IDropdownOption>;
}

  
  export class TNSelectEditor extends React.Component<TNEditorFactoryProps, TNSelectEditorState> {
      private Editor : IllerisNinthUI.TNEditorBase | null= null;
      
      private mEditor : IllerisNinthUI.TNSelect | null = null;
      private Entity : DynamicEntity | null | undefined;
      
      private ComboBoxOptions : Array<IComboBoxOption> = new Array<IComboBoxOption>();
      private LastValueString : string | undefined;
      private LastServiceResultLoadedTimeStamp : Date | undefined;

      private mPropertyName : string = '';
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            DropdownOptions: new Array<IDropdownOption>()
        }
        this.Editor = null;
        this.Entity = null;
    }
    
    componentDidMount(){
        //if (this.SelectEditor && this.DropdownOptions && this.DropdownOptions.length === 0){
        //if (this.mEditor){
        this.loadDropDownItems(true);
        //}
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNSelectEditorState) {
        //Logger.logNotification('TNInputBoxView::shouldComponentUpdate');
        if (this.Entity?.getValue(this.Editor?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.isreadonly !== nextProps.isreadonly
            || this.props.getEditor()?.HSBindName !== nextProps.getEditor()?.HSBindName
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
            || this.props.dataKey !== nextProps.dataKey
        ){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNSelectEditorState){
        var prevEnt : DynamicEntity | null | undefined = prevProps.Entity;
        var thisEnt : DynamicEntity | null | undefined = this.props.Entity;
        if (this.props.getEditor()?.HSBindName !== prevProps.getEditor()?.HSBindName 
            || prevEnt?.getValue(prevProps.getEditor()?.HSBindName ? prevProps.getEditor()?.HSBindName : '') !== thisEnt?.getValue(this.props.getEditor()?.HSBindName ? this.props.getEditor()?.HSBindName : '')
            || this.props.ServiceResult?.LoadedTimeStamp !== prevProps.ServiceResult?.LoadedTimeStamp
            || this.props.dataKey !== prevProps.dataKey
            ){
            if (this.mEditor){
                this.loadDropDownItems(true);
            }
        }
    }        

    private loadDropDownItems =  (forceUpdate : boolean) :void => {
        let that = this;
        let allItems : Array<IDropdownOption> = new Array<IDropdownOption>();
        let items : Array<IDropdownOption> = new Array<IDropdownOption>();
        
        this.mEditor = this.props.getEditor() as IllerisNinthUI.TNSelect;

        if (this.mEditor){
            //debugger;
            var i : number = 0;
            
            if (this.mEditor.Options){
                for( i = 0; i<this.mEditor.Options.length; i++){
                    var it : IDropdownOption = { key : this.mEditor.Options[i].Value, text: this.mEditor.Options[i].Text }
                    allItems.push(it);
                    items.push(it);
                }
                that.setState({DropdownOptions: items});
                that.forceUpdate();
            }
            var sr : IllerisNinthAPI.ServiceResult | null | undefined = this.props.ServiceResult;
            if (sr && sr.OptionSets && sr.OptionSets.Pairs){
                if ((typeof sr.OptionSets.Pairs[this.mEditor.DataSource.HSDataSourceProperty] !== 'undefined') && (Array.isArray(sr.OptionSets.Pairs[this.mEditor.DataSource.HSDataSourceProperty])))
                {
                    for(i = 0; i<sr.OptionSets.Pairs[this.mEditor.DataSource.HSDataSourceProperty].length; i++){
                        var optItem : any = sr.OptionSets.Pairs[this.mEditor.DataSource.HSDataSourceProperty][i];
                        if ((typeof optItem[this.mEditor.DataSource.HSDataSourceTextProperty] !== 'undefined') && (typeof optItem[this.mEditor.DataSource.HSDataSourceValueProperty] !== 'undefined')){
                            var newIt : IDropdownOption = { key: optItem[this.mEditor.DataSource.HSDataSourceValueProperty], text : optItem[this.mEditor.DataSource.HSDataSourceTextProperty]};
                            if (newIt){
                                var existingItem : IDropdownOption | undefined | null =  (newIt? allItems.find(z => z.key === newIt?.key) : null);
                                if (!existingItem){
                                    allItems.push(newIt);
                                }
                            }
                        }
                    }
                    that.setState({DropdownOptions: items});
                    that.forceUpdate();
                }
            }
            if (allItems.length === 0 && this.mEditor.DataSource && this.mEditor.DataSource.HSDataSourceURI){
                this.setState({loadingMessage: 'Loading...'});
                var cr : SilentRequest = MSALHelper.getDefaultScope();
                var mh : MSALHelper = new MSALHelper();
                let uri : string = this.mEditor.DataSource.HSDataSourceURI;
                
                mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
                    //debugger;
                    if (res && res.Values && Array.isArray(res.Values)){
                        for(var i = 0; i<res.Values.length; i++){
                            var valStr : string = (that.mEditor && that.mEditor!.DataSource!.HSDataSourceTextProperty && typeof res.Values[i][that.mEditor!.DataSource!.HSDataSourceTextProperty.toString()] != 'undefined'? (res.Values[i][that.mEditor!.DataSource!.HSDataSourceTextProperty.toString()] ?res.Values[i][that.mEditor!.DataSource!.HSDataSourceTextProperty.toString()] : '') : '');
                            var keyStr : string = (that.mEditor && that.mEditor!.DataSource!.HSDataSourceValueProperty && typeof res.Values[i][that.mEditor!.DataSource!.HSDataSourceValueProperty.toString()] != 'undefined'? (res.Values[i][that.mEditor!.DataSource!.HSDataSourceValueProperty.toString()] ? res.Values[i][that.mEditor!.DataSource!.HSDataSourceValueProperty.toString()] : '') : '');
                            var opt : IDropdownOption ={key : keyStr, text : valStr};
                            allItems.push(opt);
                        }
                    }
                    that.setState({loadingMessage: ''});
                    items = allItems;
                    
                    that.setState({DropdownOptions: items});
                    that.forceUpdate();

                }).catch(function(error: any){
                    that.setState({loadingMessage: ''});
                    Logger.logError('Failed to load lookup data : ' + error.message);
                    })
//                }
            }
            else{
                // this.DropdownOptions = allItems;
                // if (forceUpdate){
                //     this.forceUpdate();
                // }
                this.setState({DropdownOptions: allItems});
                that.forceUpdate();
            }
        }

        this.mEditor = this.props.getEditor() as IllerisNinthUI.TNSelect;
        if (this.mEditor && this.props.Entity){
            let valStr : string | null | undefined = this.props.Entity.getValue(this.mEditor.HSBindName);
            this.setState({valueStr: valStr});
            this.forceUpdate();
        }
        else{
            this.setState({valueStr: undefined});
            this.forceUpdate();
        }
    }
    
    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.mPropertyName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity){
            this.Entity.setValue(propName, val);
        }
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
          });

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };

        return (
            <Dropdown
                placeholder="Select an option"
                label={this.mEditor?.LabelText ?? 'xxx'}
                options={this.state.DropdownOptions}
                styles={dropdownStyles}
                disabled={(this.mEditor?.IsReadOnly ?? true) || this.props.isreadonly}
                selectedKey={this.state.valueStr}
                onChange={this.onSelectInputChanged}
                id={this.mEditor?.ItemID}
                data-hsbind={this.mEditor?.HSBindName}
            />
        );
      }

      private onSelectInputChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
        console.log('select changed to value : ' + option?.key);
        if (this.props.Entity && this.mEditor){
            this.props.Entity.setValue(this.mEditor!.HSBindName, option?.key);
        }
        this.setState({valueStr : option?.key.toString() ? option?.key.toString() : ''});
        this.forceUpdate();
      }
      
  }