import { CommandBar, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, ICommandBarItemProps, IconButton, IDropdownOption, Label, Link, MessageBar, MessageBarType, Panel, Pivot, PivotItem, PrimaryButton, ProgressIndicator, SelectionMode, Stack, TextField } from "@fluentui/react";
import Editor from "@monaco-editor/react";
import dayjs from "dayjs";
import { promises } from "fs";
import { takeRightWhile } from "lodash";
import React, { TableHTMLAttributes } from "react";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { StringHelper } from "../Helpers/StringHelper";
import { AppResource } from "./Entities/AppResource";
import { AuthorProfile } from "./Entities/AuthorProfile";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";
import { EntityFormsViewControl } from "./EntityFormsViewControl";
import { EntityPropertiesViewControl } from "./EntityPropertiesViewControl";
import { EntityRelationsViewControl } from "./EntityRelationsViewControl";
import { EntityViewsViewControl } from "./EntityViewsViewControl";

interface AppResourcesEditProps {
    tenantId: string;
    app: SystemApplicationInfo;
    AppResourceId: string;
}

interface AppResourcesEditState extends AppResourcesEditProps {
  StateKey : number;
  errorMessage? : string;
  notificationMessage? : string;
  columns: IColumn[];
  allResources : AppResource[];
  isbusy : boolean;
  AppResource?: AppResource;


  barItems: ICommandBarItemProps[];
  barFarItems: ICommandBarItemProps[];

  resourceTypes: IDropdownOption[];
  profile?: AuthorProfile;
}

export class AppResourcesEditControl extends React.Component<AppResourcesEditProps, AppResourcesEditState> {
    constructor(props: AppResourcesEditProps) {
      super(props);

      let resOpts: IDropdownOption[] = new Array<IDropdownOption>();
      resOpts.push({key: 0, text: "Unknown"});
      resOpts.push({key: 1, text: "Javascript File"});
      resOpts.push({key: 2, text: "PNG File"});
      resOpts.push({key: 3, text: "SVG File"});

      this.state = {
        StateKey : 0,
        tenantId: props.tenantId,
        app: props.app,
        columns: new Array<IColumn>(),
        allResources: new Array<AppResource>(),
        isbusy : false,
        barItems: new Array<ICommandBarItemProps>(),
        barFarItems: new Array<ICommandBarItemProps>(),
        resourceTypes: resOpts,
        AppResource: undefined,
        AppResourceId: props.AppResourceId
      }
    }

    private loadData = () : void => {
      let that = this;
      debugger;
      let dmh: DesignTimeModelHelper = new DesignTimeModelHelper();
      dmh.loadAppResourceById(this.props.tenantId, this.props.app.Id, this.props.AppResourceId)
        .then(function(res: AppResource){
          that.setState({AppResource: res});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err?.message});
          that.forceUpdate();
        })
    }
  
    componentDidMount() {
        this.loadData();
    }

    public shouldComponentUpdate = (nextProps: AppResourcesEditProps, nextState: AppResourcesEditState) : boolean =>{
        if (nextProps.tenantId !== this.props.tenantId || nextProps.app?.Id && this.props.app?.Id || nextProps.AppResourceId !== this.props.AppResourceId){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : AppResourcesEditProps, prevState: AppResourcesEditState){
        var that = this;
        if (prevProps.tenantId && this.props.tenantId || prevProps.app?.Id && this.props.app?.Id || prevProps.AppResourceId !== this.props.AppResourceId) {
        }
    }
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private _buildNavBar = () : ICommandBarItemProps[] => {
        let menuItems: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
        menuItems.push({
          key: 'menuItemRefresh',
          text: 'Refresh',
          ariaLabel: 'Refresh',
          iconOnly: true,
          iconProps: {iconName: 'Refresh'},
          onClick: (ev?: any) => {this.loadData()}
        });
        menuItems.push({
          key: 'menuItemSave',
          text: 'Save',
          ariaLabel: 'Save',
          iconOnly: true,
          iconProps: {iconName: 'Save'},
          onClick: this.onSaveAppResource
        });
        
        return menuItems;
      }

    private onSaveAppResource = (ev?: any) : void => {
      let that = this;
      let dmh: DesignTimeModelHelper = new DesignTimeModelHelper();
      if (this.state.AppResource){
        dmh.updateAppResource(this.props.tenantId, this.props.app.Id, this.state.AppResource)
          .then(function(res: AppResource){
            that.setState({AppResource: res, notificationMessage: 'Resource Saved'});
            that.forceUpdate();
          })
          .catch(function(err: any){
            that.setState({errorMessage: err?.message});
            that.forceUpdate();
          })
      }
    } 

    render() {
        let that = this;
        return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-solution-contentcontrol'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                  dismissButtonAriaLabel="Close"
                >
                 {that.state.errorMessage}
                </MessageBar>
              }
              {that.state.notificationMessage &&
                <MessageBar
                  messageBarType={MessageBarType.info}
                  isMultiline={false}
                  onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
                  dismissButtonAriaLabel="Close"
                >
                 {that.state.notificationMessage}
                </MessageBar>
              }
              <CommandBar
                items={that.state.barItems}
                farItems={that._buildNavBar()}
                ariaLabel="Actions"
                primaryGroupAriaLabel="Actions"
                farItemsGroupAriaLabel="More actions"
              />
              <TextField label="Name" value={that.state.AppResource?.DisplayName} onChange={that.onResourceDisplayNameChanged}></TextField>
              <Dropdown label="Resource Type" 
                        options={that.state.resourceTypes} 
                        selectedKey={that.state.AppResource?.ResourceType} 
                        onChange={that.onResourceTypeChanged}
                        disabled={!that.state.AppResource || (that.state.AppResource.Id != undefined && that.state.AppResource.Id != '')}
              ></Dropdown>
              <TextField label="Logical name"  value={that.state.AppResource?.LogicalName} readOnly={true} disabled={true}></TextField>
              {(that.state.AppResource && that.state.AppResource.ResourceType === 1) &&
                <Stack verticalFill horizontalAlign="stretch">
                  <Label>Resource Script</Label>
                  <Editor
                    height="80vh"
                    defaultLanguage="javascript"
                    defaultValue={this.state.AppResource?.ResourceData}
                    wrapperProps={{wordWrap: 'on', wordWrapColumn: 80}}
                    onChange={this.handleEditorChange}
                    
                    />
                </Stack>
              
              }
          </Stack>
      );
    }
    private handleEditorChange = (value: string | undefined, event: any) : void => {
      if (value){
        //this.props.updateXml(value);
        let res: AppResource | undefined = this.state.AppResource;
        if (res){
          res.ResourceData = value;
          this.setState({AppResource: res});
        }
      }
    }

    private onResourceDisplayNameChanged = (ev?: any, newValue?: string) => {
        let it : AppResource | undefined  = this.state.AppResource;  
        if (it){
          it.DisplayName = newValue ?? '';
          it.LogicalName = this.state.profile?.AuthorPrefix ?? '';
          if (it.LogicalName){
            it.LogicalName += '_';
          }
          it.LogicalName += StringHelper.logicalNameOnly(it.DisplayName);
          this.setState({AppResource : it});
        }
      }
      private onResourceTypeChanged = (ev?: any, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        let it : AppResource | undefined  = this.state.AppResource;  
        if (it){
          it.ResourceType = option?.key as number || 0;
          this.setState({AppResource : it});
        }
      }
}
