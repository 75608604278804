import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import Notiflix from "notiflix";
import React from "react";
import { ProductPeriod, ProductPriceMatrix } from "../../designers/Entities/ProductPricing";

interface TNProductPeriodSurfaceEditorProps {
    PriceMatrix: ProductPriceMatrix;
    Period: ProductPeriod;
    isReadOnly: boolean;
    updateAndClose: (price: ProductPeriod) => void;
    deleteItem: (price: ProductPeriod) => void;
}

interface TNProductPeriodSurfaceEditorState extends TNProductPeriodSurfaceEditorProps {
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  
}

export class TNProductPeriodSurfaceEditorControl extends React.Component<TNProductPeriodSurfaceEditorProps, TNProductPeriodSurfaceEditorState> {
    constructor(props: TNProductPeriodSurfaceEditorProps) {
      super(props);
      this.state = {
        isbusy : false,
        errorMessage : '',
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        isReadOnly : props.isReadOnly,
        notificationMessage: '',
        PriceMatrix: props.PriceMatrix,
        Period: props.Period,
        updateAndClose: props.updateAndClose,
        deleteItem: props.deleteItem
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            {this.state.errorMessage && 
                <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.errorMessage}
                </MessageBar>
            }
            {this.state.notificationMessage && 
                <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={true}
                        onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.notificationMessage}
                </MessageBar>
            }
            <div className='tn-editor-properties-title' >Period Details</div>
            <TextField label='Minimum Duration (months)' value={this.state.Period.MinMonths.toString()} readOnly={this.props.isReadOnly} type="number" onChange={this.onMinDurationChanged} ></TextField>
            <TextField label='Maximum Duration (months' value={this.state.Period.MaxMonths.toString()} readOnly={this.state.isReadOnly} type="number" onChange={this.onMaxDurationChanged}  ></TextField>
            <PrimaryButton text="Update" iconProps={{iconName: 'Save'}} onClick={this.onUpdate} style={{marginTop: '25px'}}></PrimaryButton>
            <PrimaryButton text="Delete" iconProps={{iconName: 'Delete'}} onClick={this.onDelete} style={{marginTop: '25px'}}></PrimaryButton>
        </Stack>
      );
    }

    private onMinDurationChanged = (evt: any, newValue? : string) =>{
        var it: ProductPeriod = this.state.Period;
        it.MinMonths = newValue ? Number.parseFloat(newValue) : 1;
        this.setState({Period: it});
        this.forceUpdate();
    }

    private onMaxDurationChanged = (evt: any, newValue? : string) =>{
        var it: ProductPeriod = this.state.Period;
        it.MaxMonths = newValue ? Number.parseFloat(newValue) : 1;
        this.setState({Period: it});
        this.forceUpdate();
    }
    private onUpdate = (ev? : any) : void => {
      var it: ProductPeriod = this.state.Period;
      if (it.MaxMonths < it.MinMonths || it.MaxMonths === 0){
        this.setState({errorMessage: 'The maximum duration must be larger then the minimum duration, and larger then 0.'});
        return;
      }
      this.props.updateAndClose(it);
    }
    private onDelete = (ev? : any) : void => {
      let that = this;
      Notiflix.Confirm.show('Confirm delete',
                'Are you sure you want to remove this period?','Yes','No',
                function(){ 
                    that.props.deleteItem(that.state.Period);
                },function(){
                    
                });
    }
  }
  