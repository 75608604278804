import { ComboBox, IComboBox, IComboBoxOption, MessageBar, MessageBarType, Panel, ProgressIndicator, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import React from "react";
import { ProductPriceData, ProductPriceMatrix, ProductPriceScheme, ProductRecurringPrice, ProductVolumePrice } from "../../designers/Entities/ProductPricing";
import { DynamicEntity } from "../../DynamicEntity";
import { EntityPersistanceManager } from "../../Helpers/EntityPersistanceManager";
import { PageInfo } from "../../PageInfo";
import { SectionControl } from "../../SectionControl";
import { IllerisNinthAPI } from "../../ServiceResult";
import { TNProductPriceSchemeDetailsEditControl } from "./TNProductPriceSchemeDetailsEditControl";
import { TNProductPriceSchemeViewControl } from "./TNProductPriceSchemeViewControl";

export interface TNProductPriceSchemeEditControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    getEntity : () => DynamicEntity | null | undefined;
}

export interface TNProductPriceSchemeEditControlState  {
    Product?: any;
    errorMessage?: string;
    notificationMessage?: string;
    PriceMatrix?: ProductPriceMatrix;
    productId: string;
    Schemes : ProductPriceScheme[];
    ShowPriceSchemeEditControl: boolean;
    EditPriceScheme?: ProductPriceScheme;
    ProductPriceData?: ProductPriceData;
    OptionsSchemeType: IComboBoxOption[];
    OptionsPriceModel: IComboBoxOption[];
    OptionsLicensingType: IComboBoxOption[];
    isbusy: boolean;
}
  
export class TNProductPriceSchemeEditControl extends React.Component<TNProductPriceSchemeEditControlProps, TNProductPriceSchemeEditControlState> {
      constructor(props : TNProductPriceSchemeEditControlProps){
          super(props);
          
          let osc: IComboBoxOption[] = new Array<IComboBoxOption>();
          osc.push({key: 0, text: 'Subscription'});
          osc.push({key: 10, text: 'Product'});

          let opt: IComboBoxOption[] = new Array<IComboBoxOption>();
          opt.push({key: 0, text: 'Volume Tiered'});
          opt.push({key: 10, text: 'Graduate Tiered'});

          let lt: IComboBoxOption[] = new Array<IComboBoxOption>();
          lt.push({key: 0, text: 'Licensed'});
          lt.push({key: 10, text: 'Metered'});

          this.state = {
            productId: this.props.getEntity()?.getId() ?? '',
            Schemes: new Array<ProductPriceScheme>(),
            ShowPriceSchemeEditControl: false,
            OptionsSchemeType: osc,
            OptionsPriceModel: opt,
            OptionsLicensingType: lt,
            isbusy: false

          }
      }

      private loadSchemes = async (productId?: string) : Promise<void> => {
          let that = this;
          let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
          that.setState({isbusy: true});
          that.forceUpdate();
          epm.getMany('ProductPriceData', `ProductId == "${productId ?? this.state.productId}"`)
            .then(function(res: IllerisNinthAPI.ServiceResult | Error){
                if (res instanceof Error){
                    that.setState({errorMessage: (res as Error).message});
                }
                else{
                    let sr: IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                    if (sr && sr.Values){
                        if (sr.Values && sr.Values.length  >0){
                            that.setState({ProductPriceData: sr.Values[0] as ProductPriceData});
                            that.forceUpdate();    
                        }
                        else{
                            let pd: ProductPriceData = new ProductPriceData();
                            pd.ProductId = that.props.getEntity()?.getId() ?? '';
                            that.setState({ProductPriceData: pd});
                            that.forceUpdate();  
                        }
                    }
                    else {
                        let pd: ProductPriceData = new ProductPriceData();
                        pd.ProductId = that.props.getEntity()?.getId() ?? '';
                        that.setState({ProductPriceData: pd});
                        that.forceUpdate();    
                    }
                }
            })
            .catch(function(err: any){
                that.setState({errorMessage: err?.message});
            })

          epm.getMany('ProductPricingScheme', `ProductId == "${productId ?? this.state.productId}"`)
            .then(function(res: IllerisNinthAPI.ServiceResult | Error){
                if (res instanceof Error){
                    that.setState({errorMessage: (res as Error).message});
                }
                else{
                    let sr: IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                    if (sr && sr.Values){
                        if (sr.Values){
                            sr.Values.forEach(function(it: any, index: number){
                                if (it.ValidFrom && typeof it.ValidFrom === 'string'){
                                    it.ValidFrom = dayjs(it.ValidFrom).toDate();
                                }
                                if (it.ValidUntil && typeof it.ValidUntil === 'string'){
                                    it.ValidUntil = dayjs(it.ValidUntil).toDate();
                                }
                                if (it && it.PriceData){
                                  it.RecurringPrices = JSON.parse(it.PriceData);
                                }
                            })
                        }
                        that.setState({Schemes: sr.Values as ProductPriceScheme[], isbusy: false});
                        that.forceUpdate();
                    }
                }
            })
            .catch(function(err: any){
                that.setState({errorMessage: err?.message, isbusy: false});
            })
      }

      public componentDidMount(){
          this.loadSchemes();
      }

      public shouldComponentUpdate(nextProps: TNProductPriceSchemeEditControlProps, nextState: TNProductPriceSchemeEditControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }

      public componentDidUpdate(prevProps: TNProductPriceSchemeEditControlProps, prevState: TNProductPriceSchemeEditControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
            this.loadSchemes();
        }
      }

      public render(){
          var that = this;
         
          return (
              <Stack verticalFill horizontalAlign='stretch'>
                   {this.state.isbusy &&
                        <ProgressIndicator label="Loading..." description="" />
                   }
                  {this.state.errorMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.errorMessage}
                        </MessageBar>
                    }
                    {this.state.notificationMessage && 
                        <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                                onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                dismissButtonAriaLabel="Close"
                            >
                            {this.state.notificationMessage}
                        </MessageBar>
                    }
                    <SectionControl headerText='Pricing Options'>
                        <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: 10}}>
                            <Stack.Item style={{width: '33%'}}>
                                <ComboBox label="Pricing Type" 
                                        options={this.state.OptionsSchemeType} 
                                        selectedKey={this.state.ProductPriceData?.SchemeType} 
                                        onChange={this.onChangePriceType} 
                                        disabled={this.state.Schemes && this.state.Schemes.length > 0}
                                >
                                        
                                </ComboBox>
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                <ComboBox label="Price Model" 
                                        options={this.state.OptionsPriceModel} 
                                        selectedKey={this.state.ProductPriceData?.PriceModel} 
                                        onChange={this.onChangePriceModel} 
                                        disabled={this.state.Schemes && this.state.Schemes.length > 0}
                                >

                                </ComboBox>
                            </Stack.Item>
                            <Stack.Item style={{width: '33%'}}>
                                <ComboBox label="Licensing Type" 
                                    options={this.state.OptionsLicensingType} 
                                    selectedKey={this.state.ProductPriceData?.LicensingType} 
                                    onChange={this.onChangeLicensingType} 
                                    disabled={this.state.Schemes && this.state.Schemes.length > 0}
                                >

                                </ComboBox>
                            </Stack.Item>
                        </Stack>   
                    </SectionControl>
                    <SectionControl headerText='Pricing Schemes' style={{marginTop: '15px'}}>
                        <Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: 10}}>
                        <TNProductPriceSchemeViewControl
                            isReadOnly={false}
                            isHeader={true}
                            PageInfo={this.props.PageInfo}
                            tenantId={this.props.tenantId}
                            Scheme={undefined}
                            editItem={that.editItem}
                            addPriceRow={this.onAddPriceRow}
                            saveScheme={this.saveScheme}
                            cloneScheme={that.cloneScheme}
                            //deleteItem={that.de}
                            //saveItem={that.saveScheme}
                        >
                        </TNProductPriceSchemeViewControl>
                        {that.state.Schemes.sort((a, b) => a.Order < b.Order ? -1 : 1).map(function(it: ProductPriceScheme, idx: number){
                            return(
                                <TNProductPriceSchemeViewControl
                                    isReadOnly={false}
                                    isHeader={false}
                                    PageInfo={that.props.PageInfo}
                                    tenantId={that.props.tenantId}
                                    Scheme={it}
                                    editItem={that.editItem}
                                    saveScheme={that.saveScheme}
                                    cloneScheme={that.cloneScheme}
                                    key={nanoid()}
                                >
                                </TNProductPriceSchemeViewControl>
                            );
                        })

                        }
                        </Stack>
                    </SectionControl>
                    
                  {that.state.ShowPriceSchemeEditControl && that.state.EditPriceScheme &&
                    <Panel
                        headerText="Edit Scheme"
                        isOpen={this.state.ShowPriceSchemeEditControl}
                        onDismiss={(ev?: any) => {that.setState({ShowPriceSchemeEditControl: false}); that.forceUpdate(); }}
                        closeButtonAriaLabel="Close"
                    >
                        <TNProductPriceSchemeDetailsEditControl 
                            Scheme={this.state.EditPriceScheme!}
                            tenantId={this.props.tenantId}
                            PageInfo={this.props.PageInfo}
                            isReadOnly={this.props.isReadOnly}
                            saveItem={this.saveScheme}
                            deleteItem={this.deleteScheme}
                        >
                        </TNProductPriceSchemeDetailsEditControl>
                    </Panel>
          }  
              </Stack>
          );
      }

      private onChangePriceType = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let pd : ProductPriceData | undefined = this.state.ProductPriceData;
        if (pd){
            pd.SchemeType = option?.key as number ?? 0;
            this.setState({ProductPriceData : pd});
            this.forceUpdate();
            this.savePriceData(pd);
        }
      }
      private onChangeLicensingType = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let pd : ProductPriceData | undefined = this.state.ProductPriceData;
        if (pd){
            pd.LicensingType = option?.key as number ?? 0;
            this.setState({ProductPriceData : pd});
            this.forceUpdate();
            this.savePriceData(pd);
        }
      }
      private onChangePriceModel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let pd : ProductPriceData | undefined = this.state.ProductPriceData;
        if (pd){
            pd.PriceModel = option?.key as number ?? 0;
            this.setState({ProductPriceData : pd});
            this.forceUpdate();
            this.savePriceData(pd);
        }
      }
    //   private getTypeString = (val : number) : string => {
    //       if (val === 0){
    //           return 'Default';
    //       }
    //       return 'Default';
    //   }

      private cloneScheme = (it: ProductPriceScheme) : void => {
        let that = this;
        that.saveScheme(it)
            .then(function(res : void){
                let ns: ProductPriceScheme = new ProductPriceScheme();
                ns.ProductId = it.ProductId;
                ns.ValidFrom = new Date(dayjs().date());
                ns.DisplayName = it.DisplayName;
                ns.LicensingType = it.LicensingType;
                ns.Order = it.Order + 10;
                ns.PriceModel = it.PriceModel;
                if (it.RecurringPrices){
                    ns.RecurringPriceData = JSON.stringify(it.RecurringPrices);
                    ns.RecurringPrices = JSON.parse(ns.RecurringPriceData) as ProductRecurringPrice[];
                }
                if (it.VolumePrices){
                    ns.VolumePriceData = JSON.stringify(it.VolumePrices);
                    ns.VolumePrices = JSON.parse(ns.VolumePriceData) as ProductVolumePrice[];
                }
                ns.SchemeType = it.SchemeType;
                ns.Type = it.Type;
                that.saveScheme(ns).then(function(res){
                    that.loadSchemes();
                })
                .catch(function(err: any){
                    that.setState({errorMessage: err?.message});
                })
            })
            .catch(function(err: any){
                that.setState({errorMessage: err?.message});
            })
      }

      private editItem = (it: ProductPriceScheme) : void => {
        this.setState({ShowPriceSchemeEditControl: true, EditPriceScheme: it});
        this.forceUpdate();
      }

      private savePriceData = (it: ProductPriceData) : void => {
        let that = this;
        let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
        
        let de: DynamicEntity = new DynamicEntity(it, 'ProductPriceData');
        epm.saveEntity(de,false).then(function(res: IllerisNinthAPI.ServiceResult | null | undefined){
        })
        .catch(function(err: any){
            that.setState({errorMessage: err?.message});
        })
      }

      private saveScheme = async (it: ProductPriceScheme) : Promise<void> => {
        let that = this;
        let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
        
        let de: DynamicEntity = new DynamicEntity(it, 'ProductPricingScheme');
        return epm.saveEntity(de,false).then(function(res: IllerisNinthAPI.ServiceResult | null | undefined){
            that.loadSchemes();
            that.setState({ShowPriceSchemeEditControl: false, EditPriceScheme: undefined});

            if (that.state.ProductPriceData){
                that.savePriceData(that.state.ProductPriceData);
            }
        })
        .catch(function(err: any){
            that.setState({errorMessage: err?.message});
        })
      }
      private deleteScheme = (it: ProductPriceScheme) : void => {
        let that = this;
        let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
        
        let de: DynamicEntity = new DynamicEntity(it, 'ProductPricingScheme');
        epm.deleteEntity(de).then(function(res: IllerisNinthAPI.ServiceResult | null | undefined){
            that.loadSchemes();
            that.setState({ShowPriceSchemeEditControl: false, EditPriceScheme: undefined});
        })
        .catch(function(err: any){
            that.setState({errorMessage: err?.message});
        })          
      }

      private onAddPriceRow = (ev?: any) : void => {
          let pp: ProductPriceScheme = new ProductPriceScheme();
          pp.ProductId = this.state.productId;
          if (this.state.ProductPriceData){
            pp.PriceModel = this.state.ProductPriceData?.PriceModel;
            pp.SchemeType = this.state.ProductPriceData?.SchemeType;
            pp.LicensingType = this.state.ProductPriceData?.LicensingType;
          }
          this.setState({ShowPriceSchemeEditControl: true, EditPriceScheme: pp});
          this.forceUpdate();
      }

    //   private dateToString =  (val: any) : string => {
    //       if (!val){
    //           return '';
    //       }
    //       if (typeof val === 'string'){
    //           return dayjs(val).format('DD/MM/YYYY');
    //           /*let dj : Dayjs = Dayjs()
    //         let dt : Date = new Date(Date.parse(val));
    //         return dt.toString();*/
    //       }
    //       else {
    //         return dayjs(val).format('DD/MM/YYYY');
    //           /*let dt : Date = val as Date;
    //           return dt.toISOString();*/
    //       }
    //   }
  }