import { ICommandBarItemProps, IconButton, Panel, Stack } from "@fluentui/react";
import React from "react";
import { ProductPeriod, ProductPriceMatrix, ProductVolumePrice } from "../../designers/Entities/ProductPricing";
import { TNProductVolumePriceSurfaceEditorControl } from "./TNProductVolumePriceSurfaceEdit";
import { TNProductVolumePriceViewControl } from "./TNProductVolumePriceViewControl";

interface TNProductDurationPriceViewControlProps {
    PriceMatrix: ProductPriceMatrix;
    Period?: ProductPeriod;
    isReadOnly: boolean;
    isEmpty: boolean;
    updatePriceData: () => void;
}

interface TNProductDurationPriceViewControlState extends TNProductDurationPriceViewControlProps {
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  editProductVolumePrice?: ProductVolumePrice;
  showProductVolumePriceEdit: boolean;
  
}

//const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class TNProductDurationPriceViewControl extends React.Component<TNProductDurationPriceViewControlProps, TNProductDurationPriceViewControlState> {
    constructor(props: TNProductDurationPriceViewControlProps) {
      super(props);
      this.state = {
        isbusy : false,
        errorMessage : '',
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        isReadOnly : props.isReadOnly,
        notificationMessage: '',
        PriceMatrix: props.PriceMatrix,
        Period: props.Period,
        isEmpty: props.isEmpty,
        showProductVolumePriceEdit: false,
        updatePriceData: props.updatePriceData
      }
  
    }
    componentDidMount() {
        this.buildCommandBar();
    }

    private buildCommandBar = () : void => {
      let mitems: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
      mitems.push({
          key: 'refresh',
          title: 'Refresh',
          iconOnly: true,
          iconProps: {iconName: 'Refresh'},
          onClick: this.onRefreshClicked,
      });
      mitems.push({
        key: 'add',
        title: 'Add',
        iconOnly: true,
        iconProps: {iconName: 'Add'},
        onClick: this.onAddClicked,
      });
      this.setState({cmdBarItems: mitems});
    }
    private onRefreshClicked = (ev?: any) : void => {
      this.forceUpdate();
    }
    private onAddClicked = (ev?: any) : void => {
        // let vp: ProductVolumePrice = new ProductVolumePrice();
        // this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
        // this.forceUpdate();
    }
      
    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private addVolumePrice = () : void => {
      let vp : ProductVolumePrice = new ProductVolumePrice();
      this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
    }
    private updateAndCloseVolumePriceEdit = (price: ProductVolumePrice) : void => {
      let pr : ProductPeriod | undefined = this.state.Period;
      if (pr && pr.VolumePrices && !pr.VolumePrices.includes(price)){
        pr.VolumePrices.push(price);
      }
      this.props.updatePriceData();
      this.setState({Period: pr, showProductVolumePriceEdit: false});
      this.forceUpdate();
    }
    private deleteVolumePriceItem = (price: ProductVolumePrice) : void => {
      let pr : ProductPeriod | undefined = this.state.Period;
      if (pr){
        if (pr.VolumePrices.includes(price)){
           pr.VolumePrices.splice(pr.VolumePrices.indexOf(price), 1);
        }
      }
      this.props.updatePriceData();
      this.setState({Period: pr, showProductVolumePriceEdit: false});
      this.forceUpdate();
    }
    private editVolumePrice = (price:  ProductVolumePrice) : void => {
      let vp: ProductVolumePrice = price;
      this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
      this.forceUpdate();
    }
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' style={{width: '100%'}}>
          {that.props.isEmpty &&
            <Stack horizontalAlign="stretch" horizontal style={{width: '100%', borderBottom: '1px solid black', marginBottom: '8px'}}>
              <Stack.Item align="start" style={{width: '70%'}}>
                Duration
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                
              </Stack.Item>
            </Stack>
          }
          {!that.props.isEmpty && that.state.Period &&
           <Stack verticalFill horizontalAlign='stretch' style={{width: '100%'}}>
            <Stack horizontalAlign="stretch" horizontal style={{width: '100%'}}>
              <Stack.Item align="start" style={{width: '70%'}}>
                From {that.state.Period?.MinMonths} months to {that.state.Period.MaxMonths} months
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                
              </Stack.Item>
              {/* <Stack.Item align="end" style={{width: '10%'}}>
                <IconButton iconProps={{iconName: 'Add'}} onClick={(ev?: any) => {that.addVolumePrice();}} />
              </Stack.Item> */}
            </Stack>
            {this.state.Period && this.state.Period.VolumePrices &&
              <Stack verticalFill horizontalAlign='stretch' style={{width: '90%', marginLeft: '15%', marginTop: '15px'}}>
                <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                    <Stack.Item style={{width: '90%'}}>
                        <TNProductVolumePriceViewControl isEmpty={true} key={-1} isReadOnly={that.props.isReadOnly} PriceMatrix={that.state.PriceMatrix} ></TNProductVolumePriceViewControl>
                    </Stack.Item>
                    <Stack.Item style={{width: '10%'}}>
                      <IconButton iconProps={{iconName: 'Add'}} onClick={(ev?: any) => {that.addVolumePrice();}} />
                    </Stack.Item>
                </Stack>
              
                {this.state.Period.VolumePrices.map(function(item: ProductVolumePrice, index: number){
                  return (
                      <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                          <Stack.Item style={{width:'90%'}}>
                              <TNProductVolumePriceViewControl isEmpty={false} key={index} isReadOnly={that.props.isReadOnly} PriceMatrix={that.state.PriceMatrix} Price={item}
                              ></TNProductVolumePriceViewControl>
                          </Stack.Item>
                          <Stack.Item style={{width:'10%'}}>
                              <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => {that.editVolumePrice(item);}} />
                          </Stack.Item>
                      </Stack>);
                })
                }
              </Stack>
            }
          </Stack>
          }
          {that.state.showProductVolumePriceEdit && that.state.editProductVolumePrice &&
            <Panel
                  headerText="Edit Volume Price"
                  isOpen={this.state.showProductVolumePriceEdit}
                  onDismiss={(ev?: any) => {that.setState({showProductVolumePriceEdit: false}); that.forceUpdate(); }}
                  closeButtonAriaLabel="Close"
              >
                  <TNProductVolumePriceSurfaceEditorControl 
                      Price={this.state.editProductVolumePrice!}
                      PriceMatrix={this.state.PriceMatrix}
                      isReadOnly={this.props.isReadOnly}
                      updateAndClose={this.updateAndCloseVolumePriceEdit}
                      deleteItem={this.deleteVolumePriceItem}
                  >

                  </TNProductVolumePriceSurfaceEditorControl>
              </Panel>
          }  
        </Stack>
      );
    }

    
  }
  