import { EntityCustomPrivilegeDefinition } from "../designers/Entities/EntityCustomPrivilegeDefinition";
import { EntityPrivilegeDefinition } from "../designers/Entities/EntityPrivilegeDefinition";
import { EntityPersistanceManager } from "./EntityPersistanceManager";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";

export class PrivilegeHelper {

    public static setRolePrivilegeLevel = async (priv: EntityPrivilegeDefinition, privIdx : number, privName : string, entityName : string, entityId: string, roleid : string, roleName: string, tenantUniqueName : string ) : Promise<void> => {
        //debugger;
        var uri = "/odata/core/Runtime/EntityPrivilegeDefinition?$filter=EntityName eq '" + entityName + "' and EventName eq '" + privName + "'";
        var epm : EntityPersistanceManager = new EntityPersistanceManager(tenantUniqueName);
        epm.executeAPIAny(uri, null, 'GET', false)
            .then(function (response: any) {
                //debugger;
                if (response && response.values && response.values.length > 0 && response.values[0].Id) {
                    let privId : string = response.values[0].Id;
                    let privDisplayName : string = response.values[0].DisplayName;
                    privName = priv.getPrivilegeName(privIdx);
    
                    epm.executeAPIAny("/odata/core/Runtime/SecurityRolePrivilegeDefinition?$filter=RoleId eq " + roleid + " and PrivilegeId eq " + privId + "", null, 'GET', false)
                        .then(function (response: any) {
                            //debugger;
                            if (response && response.values && response.values.length > 0 && response.values[0].Id) {
                                var rolePrivId = response.values[0].Id;
    
                                var obj : any = {};
                                //User - Runtime_ep_Document_Assign
                                obj.DisplayName = `${roleName} - ${privDisplayName}`;
                                obj.Id = rolePrivId;
                                obj.RoleId = roleid;
                                obj.PrivilegeId = privId;
                                var privVal = priv.getPrivilegeIndex(privIdx);
                                obj.Level = (isNaN(privVal) ? 0 : privVal);
    
                                epm.executeAPIAny('/odata/core/Runtime/SecurityRolePrivilegeDefinition/' + rolePrivId,(obj), 'PATCH', true)
                                    .then(function (response  : any) {
                                        //debugger;
                                        IllerisNinthUI.NotificationManager.showSuccess('Privilege set', 'Role has been assigned the "' + privName + '" privilege on the "' + entityName + '" entity. Level "' + priv.getPrivilegeName(privVal) + '"');
                                    })
                                    .catch(function (error) {
                                        IllerisNinthUI.NotificationManager.showError('Privilege set FAILED', error.message);
                                    });
    
                            }
                            else {
                                var obj2 : any = {};
                                obj2.DisplayName = `${roleName} - ${privDisplayName}`;
                                obj2.RoleId = roleid;
                                obj2.PrivilegeId = privId;
                                var privVal2 = priv.getPrivilegeIndex(privIdx);
                                obj2.Level = (isNaN(privVal2) ? 0 : privVal2);
    
                                epm.executeAPIAny('/odata/core/Runtime/SecurityRolePrivilegeDefinition', obj2, 'POST', true)
                                    .then(function (response : any) {
                                        //debugger;
                                        IllerisNinthUI.NotificationManager.showSuccess('Privilege set', 'Role has been assigned the "' + privName + '" privilege on the "' + entityName + '" entity. Level "' + priv.getPrivilegeName(privVal) + '"');
                                    })
                                    .catch(function (error) {
                                        IllerisNinthUI.NotificationManager.showError('Privilege set FAILED', error.message);
                                    });
    
                            }
    
                        })
                        .catch(function (error) {
                            IllerisNinthUI.NotificationManager.showError('Error retrieving privilege', error.message)
                        })
    
                }
            })
            .catch(function (error) {
                IllerisNinthUI.NotificationManager.showError('Error retrieving privilege', error.message)
            })
    }

    public static setRoleCustomPrivilegeLevel = async (priv: EntityCustomPrivilegeDefinition, privName : string, entityName : string, entityId: string, roleid : string, roleName: string, tenantUniqueName : string ) : Promise<void> => {
        //debugger;
        var uri = "/odata/core/Runtime/EntityPrivilegeDefinition?$filter=EntityName eq '" + entityName + "' and EventName eq '" + privName + "'";
        var epm : EntityPersistanceManager = new EntityPersistanceManager(tenantUniqueName);
        epm.executeAPIAny(uri, null, 'GET', false)
            .then(function (response: any) {
                debugger;
                if (response && response.values && response.values.length > 0 && response.values[0].Id) {
                    let privId : string = response.values[0].Id;
                    let privDisplayName : string = response.values[0].DisplayName;
    
                    epm.executeAPIAny("/odata/core/Runtime/SecurityRolePrivilegeDefinition?$filter=RoleId eq " + roleid + " and PrivilegeId eq " + privId + "", null, 'GET', false)
                        .then(function (response: any) {
                            debugger;
                            if (response && response.values && response.values.length > 0 && response.values[0].Id) {
                                var rolePrivId = response.values[0].Id;
    
                                var obj : any = {};
                                //User - Runtime_ep_Document_Assign
                                obj.DisplayName = `${roleName} - ${privDisplayName}`;
                                obj.Id = rolePrivId;
                                obj.RoleId = roleid;
                                obj.PrivilegeId = privId;
                                var privVal = priv.Level;
                                obj.Level = (isNaN(privVal) ? 0 : privVal);
    
                                epm.executeAPIAny('/odata/core/Runtime/SecurityRolePrivilegeDefinition/' + rolePrivId,(obj), 'PATCH', true)
                                    .then(function (response  : any) {
                                        //debugger;
                                        IllerisNinthUI.NotificationManager.showSuccess('Privilege set', 'Role has been assigned the "' + privName + '" privilege on the "' + entityName + '" entity. Level "' + priv.Level + '"');
                                    })
                                    .catch(function (error) {
                                        IllerisNinthUI.NotificationManager.showError('Privilege set FAILED', error.message);
                                    });
    
                            }
                            else {
                                var obj2 : any = {};
                                obj2.DisplayName = `${roleName} - ${privDisplayName}`;
                                obj2.RoleId = roleid;
                                obj2.PrivilegeId = privId;
                                var privVal2 = priv.Level
                                obj2.Level = (isNaN(privVal2) ? 0 : privVal2);
    
                                epm.executeAPIAny('/odata/core/Runtime/SecurityRolePrivilegeDefinition', obj2, 'POST', true)
                                    .then(function (response : any) {
                                        //debugger;
                                        IllerisNinthUI.NotificationManager.showSuccess('Privilege set', 'User has been assigned the "' + privName + '" privilege on the "' + entityName + '" entity. Level "' + priv.Level + '"');
                                    })
                                    .catch(function (error) {
                                        IllerisNinthUI.NotificationManager.showError('Privilege set FAILED', error.message);
                                    });
    
                            }
    
                        })
                        .catch(function (error) {
                            IllerisNinthUI.NotificationManager.showError('Error retrieving privilege', error.message)
                        })
    
                }
            })
            .catch(function (error) {
                IllerisNinthUI.NotificationManager.showError('Error retrieving privilege', error.message)
            })
    }
}