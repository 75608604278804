import { IContextualMenuItem, Stack } from "@fluentui/react";
import React from "react";
import { Logger } from "./Logger";
import { NavigationInfo} from "./NavigationInfo";
import { TNEntityViewDesignerControl } from "./designers/EntityViewDesigner";

interface ApplicationDesignerPageProps {
    tenantid : string;
    entityname : string;
    surface : string;
    id? : string;
}

interface ApplicationDesignerPageState extends ApplicationDesignerPageProps {
  StateKey : number;
  errorMessage? : string;
}

export class ApplicationDesignerPage extends React.Component<ApplicationDesignerPageProps, ApplicationDesignerPageState> {
  constructor(props: ApplicationDesignerPageProps) {
    super(props);
    this.state = {
      tenantid : props.tenantid,
      surface: props.surface,
      entityname: props.entityname,
      id : props.id,
      StateKey : 0
    }

    this.onToggleExpandClicked.bind(this);
    this.onPageRedirectRequested.bind(this);
  }

  componentDidMount() {
    
  }
  private onToggleExpandClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) : boolean | void => {
  }

  private onPageRedirectRequested = (info : NavigationInfo) : void =>{
   
  }
  closeMessageBar = () => {
    this.setState({ errorMessage: '' });
  };
  render() {
    

    return (
        <Stack verticalFill horizontalAlign='stretch' className='body-panel'>
            {this.state.surface?.toLowerCase() === 'view' &&
              <TNEntityViewDesignerControl entityName={this.props.entityname} tenantId={this.props.tenantid} viewId={this.props.id} ></TNEntityViewDesignerControl>
            }
        </Stack>
    );
  }
}
