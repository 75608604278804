import {  Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";
import { EntityPropertyDefinition, PropertyDataType } from "../../Entities/EntityProperty";

interface EntityPropertyTextFormatSurfaceEditorProps  {
    Label: string;
    Property : EntityPropertyDefinition;
    IsReadOnly : boolean;
    onChanged? : (newValue : number) => void;
}

interface EntityPropertyTextFormatSurfaceEditorState extends EntityPropertyTextFormatSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  Label: string;
  Property : EntityPropertyDefinition;
  IsReadOnly : boolean;
}

export class EntityPropertyTextFormatSurfaceEditorControl extends React.Component<EntityPropertyTextFormatSurfaceEditorProps, EntityPropertyTextFormatSurfaceEditorState> {
    private mOptions : IDropdownOption[];
    constructor(props: EntityPropertyTextFormatSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        IsReadOnly : props.IsReadOnly,
        Label : props.Label,
        Property : props.Property,
      }
      this.mOptions = new Array<IDropdownOption>();
      this.mOptions.push({key: 0, text : 'Text'})
      this.mOptions.push({key: 1, text : 'Email'})
      this.mOptions.push({key: 2, text : 'Url'})
      this.mOptions.push({key: 3, text : 'Phone'})
      this.mOptions.push({key: 4, text : 'Multiline Text'})
      this.mOptions.push({key: 5, text : 'HTML'})
      this.mOptions.push({key: 6, text : 'JSON'})

      this.mOptions = this.mOptions.sort((a, b) => a.text < b.text ? 1 : -1);
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Dropdown
            placeholder="Data Type"
            label={this.props.Label}
            options={this.mOptions}
            style={{width : '100%'}}
            onChange={this.onChanged}
            selectedKey={this.state.Property.TextFormat}
            disabled={this.state.IsReadOnly}
        />
      );
    }
    private onChanged= (evt: any, option? : IDropdownOption, index? : number) =>{
        if (option){
            let it: EntityPropertyDefinition = this.state.Property;
            it.DataTypeString = option.text as PropertyDataType;
            it.DataType = option.key as number;
            this.setState({Property : it});
            if (this.props.onChanged){
              this.props.onChanged(it.DataType as number);
            }
        }
      }
  }
  