import { CommandBar, DefaultButton, ICommandBarItemProps, IStackTokens, MessageBar, MessageBarType, Panel, Pivot, PivotItem, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { SectionControl } from "../../SectionControl";
import { DesignerHelper } from "./DesignerHelper";
import { FormSurfaceControl } from "./FormSurface";
import { SectionSurfaceEditorControl } from "./SurfaceEditors/SectionSurfaceEditor";
import { TabItemSurfaceEditorControl } from "./SurfaceEditors/TabItemSurfaceEditor";
import { DesignSurfaceDragInfo } from "../DragInfo";
import { EntityDefinition } from "../Entities/EntityDefinition";
import {  EntityFormDefinition2 } from "../Entities/EntityForm";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";
import { EntityRelationDefinition } from "../Entities/EntityRelation";
import { SystemApplicationInfo } from "../Entities/SystemApplicationInfo";
import { TenantInfo } from "../Entities/TenantInfo";
import { DesignTimeModelHelper } from "../../Helpers/DesignTimeModelHelper";
import { EditorControlSurfaceEditorControl } from "./SurfaceEditors/EditorControlSurfaceEditor";
import { ActionBarSurfaceControl } from "./ActionBarSurfaceEditor";
import { WebXmlEditorControl } from "./WebXmlEditor";
import { ContainerControlSurfaceEditorControl } from "./SurfaceEditors/ContainerControlSurfaceEditor";
import { FlexGridControlSurfaceEditorControl } from "./SurfaceEditors/FlexGridControlSurfaceEditor";
import { EntityFormScriptsSurfaceEditorControl } from "./SurfaceEditors/EntityFormScriptsSurfaceEditor";
import { nanoid } from "nanoid";
import { EntityFormScriptSourceSurfaceEditorControl } from "./SurfaceEditors/EntityFormScriptSourceSurfaceEditor";
import { EntityFormResourceDefinition } from "../Entities/EntityFormResource";

export interface TNDesignTimeEntityFormDesignerProps {
    tenantInfo : TenantInfo;
    appInfo : SystemApplicationInfo;
    entInfo : EntityDefinition;
    formInfo? : EntityFormDefinition2;
    close: () => void;
}

export interface TNDesignTimeEntityFormDesignerState  extends TNDesignTimeEntityFormDesignerProps
{
    StateKey : number;
    errorMessage : string;
    notificationMessage: string;
    isloading : boolean;
    allProperties : Array<EntityPropertyDefinition>;
    allRelations : Array<EntityRelationDefinition>;
    Form : IllerisNinthUI.Formlet;
    menuItems : ICommandBarItemProps[];
    farMenuItems : ICommandBarItemProps[];
    editObject? : any;
    editProp? : EntityPropertyDefinition | null;
    actionBar? : IllerisNinthUI.ActionButtonBar;
    viewMode: 'designer' | 'xml' | 'script';
    renderKey: number;
    editResource?: EntityFormResourceDefinition;
}

const stackTokens: IStackTokens = { childrenGap: 5 };

export class TNDesignTimeEntityFormDesignerControl extends React.Component<TNDesignTimeEntityFormDesignerProps, TNDesignTimeEntityFormDesignerState> {
    private mPage: IllerisNinthUI.TNPage | null = null;
    constructor(props: TNDesignTimeEntityFormDesignerProps) {
      super(props);

      let frm: IllerisNinthUI.Formlet | null = null;

      // if (props.formInfo){
      //   let vd: string = props.formInfo.ViewData;
      //   if (vd){
      //     let pg: IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
      //     this.mPage = pg;
      //     if (pg.parseText(vd)){
      //       if (pg && pg.Forms && pg.Forms.length > 0){
      //         frm = pg.Forms[0];
      //       }
      //     }
      //   }
      // }
      // if (!frm){
      //   frm = DesignerHelper.CreateNewForm(props.entInfo.DisplayName);
      //   let pg: IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
      //   pg.Forms.push(frm);
      //   this.mPage = pg;
      // }
      // if (frm){
        
      // }

      frm = this.setFormContent(props.formInfo?.ViewData);

      this.state = {
        StateKey : 0,
        entInfo : props.entInfo,
        tenantInfo : props.tenantInfo,
        appInfo : props.appInfo,
        errorMessage : '',
        isloading : false,
        formInfo: props.formInfo ?? new EntityFormDefinition2(),
        allProperties : new Array<EntityPropertyDefinition>(),
        allRelations : new Array<EntityRelationDefinition>(),
        Form : frm!,
        menuItems : new Array<ICommandBarItemProps>(),
        farMenuItems : new Array<ICommandBarItemProps>(),
        actionBar: IllerisNinthUI.ActionButtonBar.fromFormlet(frm),
        notificationMessage: '',
        close: props.close,
        viewMode: 'designer',
        renderKey: Math.random()
      }
  
    }
    private setFormContent = (xmlStr: string | null | undefined) : IllerisNinthUI.Formlet   => {
      let frm: IllerisNinthUI.Formlet | null = null;
      let vd: string = xmlStr ?? '';
      if (vd){
        let pg: IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
        this.mPage = pg;
        if (pg.parseText(vd)){
          if (pg && pg.Forms && pg.Forms.length > 0){
            frm = pg.Forms[0];
          }
        }
      }
      if (!frm){
        frm = DesignerHelper.CreateNewForm(this.props.entInfo.DisplayName);
        let pg: IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
        pg.Forms.push(frm);
        this.mPage = pg;
      }
      return frm;
    }
    componentDidMount() {
      this.buildMainMenu();
      let that= this;
      let dh: DesignTimeModelHelper = new DesignTimeModelHelper();
      dh.loadEntityProperties(this.state.tenantInfo.Id, this.state.appInfo.Id, this.state.entInfo.Id).then(function (res: Array<EntityPropertyDefinition>){
        that.setState({allProperties : res});
      });
      dh.loadEntityRelations(this.state.tenantInfo.Id, this.state.appInfo.Id, this.state.entInfo.Id).then(function (res: Array<EntityRelationDefinition>){
        that.setState({allRelations : res});
      })
    }
    private forceReRender = () : void => {
      this.setState({renderKey: Math.random()});
    }
    private buildMainMenu = () : void =>{
      let that = this;
      var mi : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
      mi.push({
        key: 'menuitemback',
        text: 'Back',
        label: 'Back',
        iconOnly: false,
        iconProps : {iconName: 'Back'},
        onClick: this.onBackClicked.bind(this) 
      });
      mi.push({
        key: 'menuitemsave',
        text: 'Save',
        label: 'Save',
        iconOnly: false,
        iconProps : {iconName: 'Save'},
        onClick: this.onSaveClicked.bind(this) 
      });
      mi.push({
        key: 'menuitemsaveandclose',
        text: 'Save and Close',
        label: 'Save and Close',
        iconOnly: false,
        iconProps : {iconName: 'SaveAndClose'},
        onClick: this.onSaveAndCloseClicked.bind(this) 
      });
      mi.push({
        key: 'menuitemshowviewxml',
        text: 'Show View Xml',
        label: 'Show View Xml',
        iconOnly: false,
        iconProps : {iconName: 'FileCode'},
        onClick: this.onShowViewXmlClicked.bind(this) 
      });
      mi.push({
        key: 'rerender',
        text: 'Refresh View',
        label: 'Refresh View',
        iconOnly: false,
        iconProps : {iconName: 'Refresh'},
        onClick: (evt?: any) => {that.forceReRender()}
      });
      // mi.push({
      //   key: 'menuitemnew',
      //   text: 'New',
      //   label: 'New',
      //   iconOnly: false,
      //   iconProps : {iconName: 'NewFolder'},
      //   onClick: this.onNewClicked.bind(this) 
      // })
      this.setState({menuItems : mi});
    }
    private onBackClicked = (evt: any, item? : ICommandBarItemProps) : void =>{
      //window.location.href = '/system/design';
      this.props.close();
    }
    private onShowViewXmlClicked = (evt: any, item? : ICommandBarItemProps) : void =>{
      this.setState({viewMode: 'xml'});
    }

    private innerSaveForm = () : void => {
      let that =this;
      let frm: IllerisNinthUI.Formlet = this.state.Form;
      //let str: string = frm.toXmlString();
      if (this.mPage){
        if (this.state.actionBar){
          frm.ActionButtons = this.state.actionBar?.Buttons;
        }
        if (this.mPage.Forms && this.mPage.Forms.length > 0){
          this.mPage.Forms[0] = frm;
        }
        else{
          this.mPage.Forms.push(frm);
        }
      }
      if (this.props.formInfo && this.mPage){
        this.props.formInfo.ViewData = this.mPage.toXmlString();
      }

      let eh: DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.updateEntityForm(this.props.tenantInfo.Id, this.props.appInfo.Id, this.props.entInfo, this.props.formInfo!)
        .then(function(res: EntityFormDefinition2){
          that.setState({notificationMessage: 'Form saved.', errorMessage: ''});
        })
        .catch(function(err: any){
          that.setState({notificationMessage: '', errorMessage: 'Failed to save form: '+ err?.message});
        })
    }
    private onSaveClicked = (evt: any, item? : ICommandBarItemProps) : void =>{
      this.innerSaveForm();
    }

    private onSaveAndCloseClicked = (evt: any, item? : ICommandBarItemProps) : void =>{
      debugger;
      this.innerSaveForm();
      this.props.close();
    }

    // private onNewClicked = (evt: any, item? : ICommandBarItemProps) : void =>{

    // }
    private onDragStartFormItem = (item : any) : void =>{
        var di : DesignSurfaceDragInfo = new DesignSurfaceDragInfo();
        //di.SourceObject = item;
        if (item.currentTarget && item.currentTarget.dataset && item.currentTarget.dataset.type){
          let tp : string = item.currentTarget.dataset.type;
          if (tp === 'property'){
            di.DragItemType = 'property';
          }
          else if (tp === 'control'){
            di.DragItemType = 'control';
          }
          else if (tp === 'relationship'){
            di.DragItemType = 'relation';
          }
          di.ItemName = item.currentTarget.dataset.item;
          di.ItemId = item.currentTarget.dataset.itemid;
        }
        var itemstr : string = JSON.stringify(di);
        item.dataTransfer.setData('application/json', itemstr);
        item.dataTransfer.dropEffect = 'move';

    }

    private getEntity = () : EntityDefinition =>{
      let ed : EntityDefinition = this.props.entInfo;
      if (!ed.Properties){
        ed.Properties = this.state.allProperties;
      }
      if (!ed.Relations){
        ed.Relations = this.state.allRelations;
      }
      return ed;
    }
    private updateParent = () : void =>{
      this.forceUpdate();
    }
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private closePropertiesPanel = (evt : any) : void =>{
      this.setState({editObject : null});
    }

    private showProperties = (obj : any, prop?: EntityPropertyDefinition | null) : void =>{
      debugger;
      this.setState({editObject : obj, editProp: prop});
    }

    private refreshActionBar = (bSetState: boolean) : IllerisNinthUI.ActionButtonBar => {
      let ab: IllerisNinthUI.ActionButtonBar = IllerisNinthUI.ActionButtonBar.fromFormlet(this.state.Form);
      if (bSetState){
        this.setState({actionBar: ab});
        this.forceUpdate();
      }
      return ab;
    }

    private getViewXmlString = () : string => {
      if (this.mPage){
        return this.mPage.toXmlString();
      }
      else{
        return '';
      }
    }
    private updateViewXml = (xmlStr: string) : void => {
      let frm: IllerisNinthUI.Formlet =  this.setFormContent(xmlStr);
      this.setState({Form: frm, renderKey: Math.random()});
    }

    private editScript = (res: EntityFormResourceDefinition) : void => {
      this.setState({editResource: res, viewMode: 'script'});
    }

    render() {
      var that = this;
      let width: number = window.innerWidth - 80;
      return (
        <Stack className='' verticalFill horizontalAlign='stretch' style={{width: `${width}px`, paddingRight:'25px'}} >
          <CommandBar
            items={this.state.menuItems}
            overflowButtonProps={{ ariaLabel: 'More commands' }}
            farItems={this.state.farMenuItems}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          {this.state.notificationMessage &&
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
              onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.notificationMessage}
            </MessageBar>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.errorMessage}
            </MessageBar>
          }
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm2">
                <Pivot aria-label="Form Elements" >
                  <PivotItem headerText="Properties">
                    <Stack verticalFill horizontalAlign='stretch' tokens={stackTokens} style={{padding: '15px'}}>
                      {this.state.allProperties.map(function (item: EntityPropertyDefinition, index : number){
                        return <DefaultButton key={nanoid()} iconProps={{iconName : 'FieldEmpty'}} text={item.DisplayName} data-type='property' data-item={item.DisplayName} data-itemid={item.Id}  draggable='true' onDragStart={that.onDragStartFormItem}/>
                      })
                        
                      }
                    </Stack>
                  </PivotItem>
                  <PivotItem headerText="Relations">
                  <Stack verticalFill horizontalAlign='stretch' tokens={stackTokens} style={{padding: '15px'}}>
                      {this.state.allRelations.map(function (item: EntityRelationDefinition, index : number){
                        return <DefaultButton key={nanoid()} iconProps={{iconName : 'Relationship'}} text={item.DisplayName} data-type='relationship' data-item={item.DisplayName} data-itemid={item.Id}  draggable='true' onDragStart={that.onDragStartFormItem}/>
                      })
                        
                      }
                    </Stack>
                  </PivotItem>
                  <PivotItem headerText="Form Elements">
                    <Stack verticalFill horizontalAlign='stretch' tokens={stackTokens} style={{padding: '15px'}}>
                      <DefaultButton iconProps={{iconName : 'BrowserTab'}} text="Tab Control" data-type='control' data-item='tabcontrol' draggable='true' onDragStart={that.onDragStartFormItem} />
                      <DefaultButton iconProps={{iconName : 'Tablet'}} text="Section" data-type='control' data-item='section'  draggable='true' onDragStart={that.onDragStartFormItem}/>
                      <DefaultButton iconProps={{iconName : 'ContactCard'}} text="Container" data-type='control' data-item='container'  draggable='true' onDragStart={that.onDragStartFormItem}/>
                    </Stack>
                  </PivotItem>
                </Pivot>
              </div>
              <div className="ms-Grid-col ms-sm8">
                {this.state.viewMode === 'designer' &&
                  <Stack verticalFill horizontalAlign="stretch">
                        <SectionControl headerText="Form Action Bar">
                          <ActionBarSurfaceControl renderKey={this.state.renderKey} appInfo={this.props.appInfo} Bar={this.state.actionBar} getEntity={that.getEntity} updateParent={that.updateParent} refreshActionBar={this.refreshActionBar} ></ActionBarSurfaceControl>
                        </SectionControl>
                        <SectionControl headerText='Form Details'>
                          <FormSurfaceControl renderKey={this.state.renderKey} appInfo={this.props.appInfo} Form={this.state.Form} getEntity={that.getEntity} updateParent={that.updateParent} showProperties={this.showProperties} ></FormSurfaceControl>
                        </SectionControl>
                  </Stack>
                }
                {this.state.viewMode === 'xml' &&
                  <Stack verticalFill horizontalAlign="stretch">
                        <WebXmlEditorControl XmlString={that.getViewXmlString()} appInfo={this.props.appInfo} stopEditor={() => {that.setState({viewMode: 'designer'}); that.forceUpdate();}} updateXml={(xmlStr: string) => {that.updateViewXml(xmlStr);}}  getEntity={that.getEntity} updateParent={that.updateParent}></WebXmlEditorControl>
                  </Stack>
                }
                {this.state.viewMode === 'script' &&
                  <Stack verticalFill horizontalAlign="stretch">
                      <EntityFormScriptSourceSurfaceEditorControl 
                            Res={this.state.editResource!} 
                            isReadOnly={false} 
                            close={() => {this.setState({viewMode: 'designer'})}} 
                            tenantId={this.props.tenantInfo.Id}
                            appId={this.props.appInfo.Id}
                            Form={this.props.formInfo!}
                            entityId={this.props.entInfo.Id}
                      >

                      </EntityFormScriptSourceSurfaceEditorControl>
                  </Stack>
                }

              </div>
              <div className="ms-Grid-col ms-sm2">
                <Pivot aria-label="Form">
                  <PivotItem headerText="Scripts">
                    <EntityFormScriptsSurfaceEditorControl 
                          TenantId={this.props.tenantInfo.Id} 
                          AppInfo={this.props.appInfo} 
                          Frm={this.props.formInfo!} 
                          editScript={this.editScript}
                          isReadOnly={false} >

                    </EntityFormScriptsSurfaceEditorControl>
                  </PivotItem>
                  <PivotItem headerText="Events">
                    
                  </PivotItem>
                </Pivot>


              </div>
            </div>
          </div>
          {this.state.editObject && 
            <Panel
              headerText="Properties"
              isOpen={this.state.editObject}
              onDismiss={this.closePropertiesPanel}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              {this.state.editObject instanceof IllerisNinthUI.TNTab &&
                <TabItemSurfaceEditorControl Tab={this.state.editObject as IllerisNinthUI.TNTab} tabIndex={0} Form={this.state.Form} isReadOnly={false} getEntity={this.getEntity} ></TabItemSurfaceEditorControl>
              }
              {this.state.editObject instanceof IllerisNinthUI.TNSection &&
                <SectionSurfaceEditorControl Section={this.state.editObject as IllerisNinthUI.TNSection} tabIndex={0} Tab={null}  Form={this.state.Form} isReadOnly={false} getEntity={this.getEntity} ></SectionSurfaceEditorControl>
              }
              {this.state.editObject instanceof IllerisNinthUI.TNContainer &&
                <ContainerControlSurfaceEditorControl Container={this.state.editObject as IllerisNinthUI.TNContainer} Section={null} tabIndex={0} Tab={null}  Form={this.state.Form} isReadOnly={false} getEntity={this.getEntity} ></ContainerControlSurfaceEditorControl>
              }
              {this.state.editObject instanceof IllerisNinthUI.FlexGrid &&
                <FlexGridControlSurfaceEditorControl Grid={this.state.editObject as IllerisNinthUI.FlexGrid} Section={null} tabIndex={0} Tab={null}  Form={this.state.Form} isReadOnly={false} getEntity={this.getEntity} ></FlexGridControlSurfaceEditorControl>
              }
              {this.state.editObject instanceof IllerisNinthUI.TNEditorBase && !(this.state.editObject instanceof IllerisNinthUI.TNContainer) &&
                <EditorControlSurfaceEditorControl Editor={this.state.editObject as IllerisNinthUI.TNEditorBase} Property={this.state.editProp} Section={null} tabIndex={0} Tab={null}  Form={this.state.Form} isReadOnly={false} getEntity={this.getEntity} ></EditorControlSurfaceEditorControl>
              }
              
              <DefaultButton text='Close' onClick={this.closePropertiesPanel} style={{marginTop:'45px'}} />
            </Panel>
          }
        </Stack>
      );
    }
  }
  