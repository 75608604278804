import { Dropdown, IDropdownOption} from "@fluentui/react";
import React from "react";
import { ClientDataManager } from "../db/ClientDataManager";
//import { ClientState } from "../ClientState";
import { DynamicEntity } from "../DynamicEntity";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";

export interface TNRecordSecurityInfoEditProps {
    Entity : DynamicEntity | null | undefined;
    isReadOnly: boolean;
    tenantId: string;
    Formlet: IllerisNinthUI.Formlet | null;
}

export interface TNRecordSecurityInfoEditState  {
    ownerId : string| null;
    owningTeamId : string| null;
    owningOrganizationId : string| null;
    owningTenantId : string| null;
    userOptions: IDropdownOption[];
    organizationOptions: IDropdownOption[];
    tenantOptions: IDropdownOption[];
    teamOptions: IDropdownOption[];
    isEditForm: boolean;
}
  
export class TNRecordSecurityInfoEditControl extends React.Component<TNRecordSecurityInfoEditProps, TNRecordSecurityInfoEditState> {
      private mUserList : Array<any> = new Array<any>();
      private mUserDropDownItems : Array<IDropdownOption> = new Array<IDropdownOption>();
      constructor(props : TNRecordSecurityInfoEditProps){
          super(props);
          
          this.state = {
            ownerId: null,
            owningTeamId: null,
            owningOrganizationId: null,
            owningTenantId: null,
            userOptions: new Array<IDropdownOption>(),
            organizationOptions: new Array<IDropdownOption>(),
            tenantOptions: new Array<IDropdownOption>(),
            teamOptions: new Array<IDropdownOption>(),
            isEditForm: false
          }
      }

      private buildOptionList = (items: any[]) : IDropdownOption[]  => {
          let res: IDropdownOption[] = new Array<IDropdownOption>();
          if (items){
              items.forEach(function(item: any, idx: number){
                res.push({key: item.Id, text: item.DisplayName});
              })
          }
          return res;
      }

      private loadCombos = () : void => {
        let cdm : ClientDataManager = new ClientDataManager(this.props.tenantId);
        let that = this;
        cdm.getMany('identity', true)
            .then(function(idres: any[]){
                that.setState({userOptions: that.buildOptionList(idres)});
                cdm.getMany('tenant', true)
                    .then(function (tenres: any[]){
                        that.setState({tenantOptions: that.buildOptionList(tenres)});
                        cdm.getMany('organization', true)
                            .then(function (orgres: any[]){
                                that.setState({organizationOptions: that.buildOptionList(orgres)});
                                that.forceUpdate();
                            })
                            .catch(function(err: any){
                                console.log(err?.message);
                            })
                    })
                    .catch(function(err: any){
                        console.log(err?.message);
                    })
            })
            .catch(function(err: any){
                console.log(err?.message);
            })
      }

      private updateData = (ent: DynamicEntity | null | undefined) : void => {
        if (!ent){
            this.setState({ownerId : null, owningOrganizationId: null, owningTeamId: null, owningTenantId: null});
        }
        else{
            this.setState({ownerId : ent.getValue('OwnerId'), owningTenantId: ent.getValue('OwningTenantId'), owningTeamId: ent.getValue('OwningTeamId'), owningOrganizationId: ent.getValue('OwningOrganizationId')});
        }
        this.forceUpdate();
      }

      public componentDidMount(){
          this.loadCombos();
          this.updateData(this.props.Entity);
          if (this.props.Formlet && this.props.Formlet.findEditors()?.length > 0){
              this.setState({isEditForm: true});
          }
      }

      public shouldComponentUpdate(nextProps: TNRecordSecurityInfoEditProps, nextState: TNRecordSecurityInfoEditState){
        if (nextProps.Entity !== this.props.Entity){
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNRecordSecurityInfoEditProps, prevState: TNRecordSecurityInfoEditState){
        if (prevProps.Entity !== this.props.Entity){
            this.updateData(this.props.Entity);
        }
      }
      public render(){
          var that = this;
          var idStr : string = 'tnRecordSecurityEditRow';
          //var sizeStr = 'ms-Grid-col ms-sm3 ms-md3 ms-lg ms-xl3 ms-xxl3 ms-xxl3 tn-column';
          var sizeStr = 'ms-Grid-col ms-sm6 ms-md6 ms-lg6  ms-xl3';
          var sizeStrB = 'ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl3 ms-hiddenLgDown';
          

          return (
              <>
              {that.props && that.props.Entity && that.state.isEditForm &&
                <div className="ms-Grid tn-ownergrid">
                    <div className="ms-Grid-row tn-row" id={idStr}>
                        <div className={sizeStrB} >
                            <Dropdown
                                placeholder="Owner"
                                label="Owner"
                                options={this.state.userOptions}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.ownerId}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                            
                        </div>
                        <div className={sizeStrB} >
                            <Dropdown
                                placeholder="Owning Team"
                                label="Owning Team"
                                options={this.state.teamOptions}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.owningTeamId}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                        </div>
                        <div className={sizeStr} >
                            <Dropdown
                                placeholder="Owning Organization"
                                label="Owning Organization"
                                options={this.state.organizationOptions}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.owningOrganizationId}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                        </div>
                        <div className={sizeStr} >
                            <Dropdown
                                placeholder="Owning Tenant"
                                label="Owning Tenant"
                                options={this.state.tenantOptions}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.owningTenantId}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                        </div>
                    </div>
                </div>
              }
              {this && (!this.props.Entity || !this.state.isEditForm) &&
                <></>
              }
              </>
          );
      }
  }