
import {
  CommandBar,
  ContextualMenu,
  FontWeights,
  getTheme,
  IButtonProps,
  IButtonStyles,
  ICommandBarItemProps,
  IconButton,
  IContextualMenuItem,
  initializeIcons,
  IStackProps,
  mergeStyleSets,
  Modal,
} from "@fluentui/react";
import { takeRightWhile } from "lodash";
import * as React from "react";
import { ClientState } from "./ClientState";
import { WhoAmIResponse } from "./designers/Entities/WhoAmIResponse";
import { EntityPersistanceManager } from "./Helpers/EntityPersistanceManager";
import { Logger } from "./Logger";
import { MSALHelper } from "./Helpers/MSALHelper";
import { SessionStorageManager } from "./StorageManager";
import { useId } from "react-id-generator";
import { nanoid } from "nanoid";
import { TNUserProfileEditControl } from "./Controls/TNUserProfileEdit";
//import { IllerisNinthAPI } from "./ServiceResult";
//import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
//import { IButtonProps } from 'office-ui-fabric-react/lib/Button';

initializeIcons();

const overflowProps: IButtonProps = { ariaLabel: "More commands" };
const titleId = nanoid();

interface HeaderCommandBarProps {
  onToggleExpandHandler? : (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => boolean | void;
}

interface HeaderCommandBarState {
  currentOrgId : string;
  currentOrgName : string;
  selectedCompany : any;
  FarItems: ICommandBarItemProps[];
  MenuItems: ICommandBarItemProps[];
  showProfileEdit: boolean;
}

export class HeaderCommandBar extends React.Component<
  HeaderCommandBarProps,
  HeaderCommandBarState
> {
  
  private mOverflowItems: ICommandBarItemProps[];
  private mCompanyItems : IContextualMenuItem[];
  private mCompanies : Array<any>;
  private mbIsAdmin: boolean = false;
  constructor(props: HeaderCommandBarProps) {
    super(props);
    
    this.mOverflowItems = new Array<ICommandBarItemProps>();
    this.state = {
      currentOrgId : '',
      currentOrgName : 'Loading...',
      FarItems : new Array<ICommandBarItemProps>(),
      MenuItems : new Array<ICommandBarItemProps>(),
      selectedCompany : null,
      showProfileEdit: false
    };
    
    this.mCompanyItems = new Array<IContextualMenuItem>();
    this.mCompanies = new Array<any>();

    this.onSetCompanyClicked.bind(this);
    this.loadCompanies.bind(this);
    this.onGoHomeClicked.bind(this);
  }

  componentDidMount(){
    let that = this;
    let xs: ClientState = ClientState.CurrentState();

    this.calcMenu(xs);
    this.loadCompanies();

    let args : URLSearchParams = new URLSearchParams(window.location.search);
    if (args.get('login') === 'true' ){
      var cs: ClientState = ClientState.CurrentState();
      if (!cs.IsAuthenticated){
        this.onLoginClicked();
      }
      else{
        let uri : string = window.location.href;
        if (uri.indexOf('?') !== -1){
          uri = uri.substring(0, uri.indexOf('?'));
          window.location.href = uri;
        }
      }
      
    }
    
    if (xs && xs.IsAuthenticated){
      let epm : EntityPersistanceManager = new EntityPersistanceManager();
      epm.whoAmI()
        .then(function(res: WhoAmIResponse){
          if (WhoAmIResponse.getRoles(res).includes('Administrator')){
            that.mbIsAdmin = true;
            that.calcFarMenu(xs);
            //that.forceUpdate();
          }
        })
    }
    else if (xs && !xs.IsAuthenticated){
      that.calcFarMenu(xs);
    }
    
  }

  private loadCompanies = () : void =>{
    var that = this;
    var cs : ClientState =  ClientState.CurrentState();
    if (cs && cs.UserProfile){
      let bHasComps: boolean = SessionStorageManager.containsItem('__TN_COMPANIES__');
      var res : Array<any> | undefined;
      if (bHasComps){ 
        res = SessionStorageManager.getItemAsObject<Array<any>>('__TN_COMPANIES__');
      }
      if (bHasComps && res && res.length > 0){
        
        if (res){
            that.mCompanies = new Array<any>();
            that.mCompanyItems = new Array<IContextualMenuItem>();

            for(var z = 0; z<res.length; z++){
              var ui : any = {};
              ui.OrganizationId = res[z].OrganizationId;
              ui.OrganizationDisplayName = res[z].OrganizationDisplayName;
              ui.TenantId = res[z].TenantId;
              ui.TenantDisplayName = res[z].TenantDisplayName;
              ui.UniqueName = res[z].UniqueName;
              that.mCompanies.push(ui);
            }
            for(var t = 0; t<that.mCompanies.length; t++){
              that.mCompanyItems.push({
                key : that.mCompanies[t].OrganizationId,
                text: that.mCompanies[t].OrganizationDisplayName + ' (' + that.mCompanies[t].TenantDisplayName + ')' ,
                ariaLabel: that.mCompanies[t].OrganizationDisplayName+ ' (' + that.mCompanies[t].TenantDisplayName + ')',
                ariaDescription: that.mCompanies[t].OrganizationDisplayName+ ' (' + that.mCompanies[t].TenantDisplayName + ')',
                iconProps: { iconName: "CityNext" },
                onclick: that.onSetCompanyClicked.bind(that)
              })
            }
            that.mCompanyItems.push({
              key : 'itemRefresh',
              text: 'Refresh',
              ariaLabel: 'Refresh',
              ariaDescription: 'Refresh',
              iconProps: {iconName : 'Refresh'},
              onclick: that.loadCompanies.bind(that)
            })

            if (that.state.FarItems.length > 1 && that.state.FarItems[1].subMenuProps){
              that.state.FarItems[1].subMenuProps!.items = that.mCompanyItems;
              let fi: ICommandBarItemProps[] = that.state.FarItems;
              if (that.mCompanyItems.length > 0){
                
                fi[1].text = that.mCompanyItems[0].text;
                fi[1].ariaLabel = that.mCompanyItems[0].ariaLabel;
                fi[1].ariaDescription = that.mCompanyItems[0].ariaDescription;

                var cs : ClientState = ClientState.CurrentState();
                if (cs && cs.UserProfile && that.mCompanies && that.mCompanies.length > 0){
                  cs.UserProfile.TerraNovaTenantId = that.mCompanies[0].TenantId;
                  ClientState.SaveState(cs);
                }

              }
              //that.forceUpdate();
              that.setState({FarItems : fi, selectedCompany: (that.mCompanyItems.length > 0 ?that.mCompanyItems[0] : null) });
            }
        }
      }
      else{
        var uri : string = '/api/v2.0/terranova/xutil/FetchOrganizations?userEmail=' + cs?.UserProfile?.EmailAddress;
        var cm : EntityPersistanceManager = new EntityPersistanceManager();
        cm.executeAPI(uri, null, 'GET', false)
          .then(function(res: any){
            if (res && Array.isArray(res)){
              that.mCompanies = new Array<any>();
              that.mCompanyItems = new Array<IContextualMenuItem>();
              SessionStorageManager.setItem('__TN_COMPANIES__', JSON.stringify(res));

              for(var z = 0; z<res.length; z++){
                var ui : any = {};
                ui.OrganizationId = res[z].OrganizationId;
                ui.OrganizationDisplayName = res[z].OrganizationDisplayName;
                ui.TenantId = res[z].TenantId;
                ui.TenantDisplayName = res[z].TenantDisplayName;
                ui.UniqueName = res[z].UniqueName;
                that.mCompanies.push(ui);
              }
              for(var t = 0; t<that.mCompanies.length; t++){
                that.mCompanyItems.push({
                  key : that.mCompanies[t].OrganizationId,
                  text: that.mCompanies[t].OrganizationDisplayName + ' (' + that.mCompanies[t].TenantDisplayName + ')' ,
                  ariaLabel: that.mCompanies[t].OrganizationDisplayName+ ' (' + that.mCompanies[t].TenantDisplayName + ')',
                  ariaDescription: that.mCompanies[t].OrganizationDisplayName+ ' (' + that.mCompanies[t].TenantDisplayName + ')',
                  iconProps: { iconName: "CityNext" },
                  onclick: that.onSetCompanyClicked.bind(that)
                })
              }
              that.mCompanyItems.push({
                key : 'itemRefresh',
                text: 'Refresh',
                ariaLabel: 'Refresh',
                ariaDescription: 'Refresh',
                iconProps: {iconName : 'Refresh'},
                onclick: that.loadCompanies.bind(that)
              })
  
              if (that.state.FarItems.length > 1 && that.state.FarItems[1].subMenuProps){
                that.state.FarItems[1].subMenuProps!.items = that.mCompanyItems;
                let fi: ICommandBarItemProps[] = that.state.FarItems;
                if (that.mCompanyItems.length > 0){
                  
                  fi[1].text = that.mCompanyItems[0].text;
                  fi[1].ariaLabel = that.mCompanyItems[0].ariaLabel;
                  fi[1].ariaDescription = that.mCompanyItems[0].ariaDescription;
  
                  var cs : ClientState = ClientState.CurrentState();
                  if (cs && cs.UserProfile && that.mCompanies && that.mCompanies.length > 0){
                    cs.UserProfile.TerraNovaTenantId = that.mCompanies[0].TenantId;
                    ClientState.SaveState(cs);
                  }
  
                }
                //that.forceUpdate();
                that.setState({FarItems : fi, selectedCompany: (that.mCompanyItems.length > 0 ?that.mCompanyItems[0] : null)});
              }
            }
          })
          .catch(function(err){
            Logger.logError('FAILED to fetch user organizations: ' + err.message);
          })  
      }

      
    }
  }


  private calcMenu = (cs : ClientState) : void => {
    let mitems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
    if (!cs || !cs.IsAuthenticated){

    }
    else{
      // mitems.push({
      //   key: "menu",
      //   text: 'menu',
      //   // This needs an ariaLabel since it's icon-only
      //   ariaLabel: 'menu',
      //   iconOnly: true,
      //   iconProps: { iconName: "CollapseMenu" },
      //   //onClick: () => console.log('Tiles'),
      // });
    }
    if (mitems.length > 0){
      mitems[0].onClick = this.onToggleExpandClicked.bind(this);
    }
    this.setState({MenuItems: mitems});
    
  }
  private onToggleExpandClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) : boolean | void => {
    var cs : ClientState= ClientState.CurrentState();
    cs.IsExpanded  =!cs.IsExpanded;
    ClientState.SaveState(cs);

    if (this.props && this.props.onToggleExpandHandler)
      this.props.onToggleExpandHandler(ev, item);
  }

  private calcFarMenu = (cs: ClientState): void => {
    let fi : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
    let that = this;

    if (cs && cs.IsAuthenticated) {
      fi.push({
        key: "home",
        text: "Home",
        // This needs an ariaLabel since it's icon-only
        ariaLabel: "Home",
        iconOnly: false,
        iconProps: { iconName: "Home" },
        onClick: this.onGoHomeClicked.bind(this)
       // onClick: () => window.location.href = '/',
      });
      fi.push({
        key: "company",
        text: (that.state.selectedCompany ? that.state.selectedCompany.text :  ( that.mCompanies && that.mCompanies.length > 0 ? that.mCompanies[0].OrganizationDisplayName : "Loading...")),
        // This needs an ariaLabel since it's icon-only
        ariaLabel: "Company",
        ariaDescription: "Change the company to connect to another company",
        iconOnly: false,
        iconProps: { iconName: "CityNext" },
        subMenuProps: {
          items : this.mCompanyItems
        },
        //onclick: that.onSetCompanyClicked
       // onClick: () => window.location.href = '/',
      });
      fi.push({
        key: "profile",
        text: cs.UserProfile?.DisplayName,
        // This needs an ariaLabel since it's icon-only
        ariaLabel: cs.UserProfile?.DisplayName,
        iconOnly: false,
        iconProps: { iconName: "Contact" },
        //onClick: () => console.log('Tiles'),
        subMenuProps: {
          items: this.getFarProfileItems(this.mbIsAdmin),
        },
      });

      if (fi[fi.length-1].subMenuProps && fi[fi.length-1].subMenuProps!.items &&  fi[fi.length-1].subMenuProps!.items.length > 2){
        fi[fi.length - 1].subMenuProps!.items[0].onClick = this.onGoToDesignerClicked.bind(this);  
      }
      
      fi[
        fi.length - 2
      ].onClick = this.onGoHomeClicked.bind(this);
      fi[fi.length - 1].subMenuProps!.items[fi[fi.length - 1].subMenuProps!.items.length - 2].onClick = this.onUserProfileClicked.bind(this);
      fi[fi.length - 1].subMenuProps!.items[fi[fi.length - 1].subMenuProps!.items.length - 1].onClick = this.onUserLogoffClicked.bind(this);

    } else {
      fi.push({
        key: "login",
        text: "Login",
        // This needs an ariaLabel since it's icon-only
        ariaLabel: "Login",
        iconOnly: false,
        iconProps: { iconName: "Contact" },
      });
      fi[
        fi.length - 1
      ].onClick = this.onUserLoginClicked.bind(this);
    }

    fi.push({
      key: "info",
      text: "Info",
      // This needs an ariaLabel since it's icon-only
      ariaLabel: "Info",
      iconOnly: true,
      iconProps: { iconName: "Info" },
      onClick: () => {window.open('https://www.ninth.eu', '_blanc')},
    });
    this.setState({FarItems: fi});
  };

  private getFarProfileItems = (bIsAdmin: boolean) : IContextualMenuItem[] =>{
    let that = this;
    let res: IContextualMenuItem[] = new Array<IContextualMenuItem>();
    if (bIsAdmin){
      res.push({
        key: "design",
            text: "Designer",
            ariaLabel: "Designer",
            iconOnly: false,
            iconProps: { iconName: "Design" },
          });
    }
    res.push({
      key: "profile",
      text: "User Profile",
      ariaLabel: "User Profile",
      iconOnly: false,
      iconProps: { iconName: "ContactInfo" },
      onClick: (ev?: any) => {that.setState({showProfileEdit: true}); that.forceUpdate();}
    });
    res.push(
    {
      key: "logoff",
      text: "Log Off",
      ariaLabel: "Log Off",
      iconOnly: false,
      iconProps: { iconName: "PowerButton" },
    });
    return res;
  }

  render() {
    var that = this;
    var cs: ClientState = ClientState.CurrentState();
    if (cs.UserProfile && cs.UserProfile.ValidUntil)
    {
      if (cs.UserProfile.ValidUntil < new Date()){
        cs.IsAuthenticated = false;
      }
    }
    if (this.state.FarItems && this.state.FarItems.length > 1){
      if (this.state.FarItems[1].subMenuProps){
        for(var i = 0; i<this.state.FarItems[1].subMenuProps.items.length - 1; i++){
          this.state.FarItems[1].subMenuProps.items[i].onClick = this.onSetCompanyClicked.bind(this);
        }
        if (this.state.FarItems[1].subMenuProps.items[this.state.FarItems[1].subMenuProps.items.length-1]){
          this.state.FarItems[1].subMenuProps.items[this.state.FarItems[1].subMenuProps.items.length-1].onClick = this.onRefreshCompaniesClicked.bind(this);
        }
      }
    }

    //that.calcFarMenu(cs);
    //that.calcMenu(cs);
    return (
      <div className="header-navbar-panel-navbar-div">
        <CommandBar
          items={that.state.MenuItems}
          overflowItems={this.mOverflowItems}
          overflowButtonProps={overflowProps}
          //farItems={that.mFarItems}
          farItems={that.state.FarItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          className="header-navbar-panel-navbar"
        />
        <Modal
          titleAriaId={titleId}
          isOpen={this.state.showProfileEdit}
          onDismiss={(ev?: any) => {that.setState({showProfileEdit: false})}}
          isBlocking={false}
          containerClassName={contentStyles.container}
          dragOptions={{
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            keepInBounds: true,
          }}
        >
          <div className={contentStyles.header}>
            <span id={titleId}>User Settings</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={(ev?: any) => {that.setState({showProfileEdit: false})}}
            />
          </div>
          <div className={contentStyles.body}>
            <TNUserProfileEditControl tenantId={cs.UserProfile?.TerraNovaTenantUniqueName ?? ''} appName={''} ></TNUserProfileEditControl>
          </div>
        </Modal>
      </div>
    );
  }
  public onRefreshCompaniesClicked(ev?: any, item?: any): void {
    this.loadCompanies();
  }
  public onSetCompanyClicked(ev?: any, item?: any): void {
    var that = this;
    if (item && item.key){
      for(var i = 0; i<this.mCompanies.length; i++){
        if (this.mCompanies[i].OrganizationId === item.key){
          var cs : ClientState = ClientState.CurrentState();
          if (cs && cs.UserProfile){
            cs.UserProfile.TerraNovaTenantId = this.mCompanies[i].TenantId;
            cs.UserProfile.TerraNovaTenantUniqueName = this.mCompanies[i].UniqueName;
            ClientState.SaveState(cs);
            let fi: ICommandBarItemProps[] = this.state.FarItems;
            fi[1].text = that.mCompanyItems[i].text;
            fi[1].ariaLabel = that.mCompanyItems[i].ariaLabel;
            fi[1].ariaDescription = that.mCompanyItems[i].ariaDescription;

            that.setState({selectedCompany : this.mCompanies[i], FarItems: fi});

            var newUri : string = window.location.hostname.toLowerCase();
            if (newUri.startsWith('localhost')){
              newUri = this.mCompanies[i].UniqueName + '.' + newUri;
            }
            else{
              var pos : number = newUri.indexOf('.');
              if (pos){
                newUri = newUri.substring(pos+1);
              }
              newUri = this.mCompanies[i].UniqueName + '.' + newUri;
            }
            if (window.sessionStorage){
              window.sessionStorage.setItem('__TENANTUNIQUENAME__',this.mCompanies[i].UniqueName);
            }
            //window.location.href = window.location.protocol.replace(':','') + '://' +  newUri + (window.location.port ? ':' + window.location.port : '');            
          }
        }
      }
    }
  }
  public onGoHomeClicked(ev?: any, item?: any): void {
    window.location.href = '/';
  }
  public onGoToDesignerClicked(ev?: any, item?: any): void {
    window.location.href = '/system/design';
  }
  public onUserProfileClicked(ev?: any, item?: any): void {
    this.setState({showProfileEdit: true});
  }
  public onUserLogoffClicked(ev?: any, item?: any): void {
    //debugger;
    var that = this;
    var mh = new MSALHelper();
    var cs: ClientState = ClientState.CurrentState();
    cs.IsAuthenticated = false;
    ClientState.SaveState(cs);
    that.forceUpdate();
    mh.signOut().then(function () {});
  }

  private onLoginClicked = () : void =>{
    //debugger;
    var that = this;
    var mh = new MSALHelper();
    mh.signInRedirect().then(function () {
      that.forceUpdate();
    });
  }

  public onUserLoginClicked(ev?: any, item?: any): void {
    this.onLoginClicked();
    // mh.signInRedirect().then(function () {
    //   that.forceUpdate();
    // });
  }
}



const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const stackProps: Partial<IStackProps> = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};