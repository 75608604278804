/* eslint-disable no-loop-func */
import { SilentRequest } from "@azure/msal-browser";
import { ChoiceGroup, ColumnActionsMode, CommandBar, ContextualMenu, ContextualMenuItemType, DefaultButton, DetailsRow, DetailsRowCheck, Dialog, DialogFooter, DialogType, DirectionalHint, IChoiceGroupOption, ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps, IDetailsColumnRenderTooltipProps, IDetailsFooterProps, IDetailsHeaderProps, IDetailsListStyles, IDetailsRowBaseProps, IDetailsRowCheckProps, IDetailsRowCheckStyles, IRenderFunction, MessageBar, MessageBarType, ProgressIndicator, SearchBox, SelectionMode, Stack, Sticky, StickyPositionType, TooltipHost, Text, IDropdownOption, Dropdown, TextField, PrimaryButton, IconButton} from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, Selection, IColumn, IColumnReorderOptions } from '@fluentui/react/lib/DetailsList';
import dayjs from "dayjs";

import React from "react";
import { Controller } from "../../Controller";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Logger } from "../../Logger";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { IllerisNinthAPI } from "../../ServiceResult";
import { EntityHelper } from "../../Helpers/EntityHelper";
import { NumberHelper, RegExHelper } from "../../Helpers/ItemHelpers";
import { EntityPersistanceManager } from "../../Helpers/EntityPersistanceManager";
import { Guid } from "../../Guid";
import { ClientState } from "../../ClientState";
import { GridViewColumnEditorPanelControl } from "../../GridViewColumnEditorPanel";
import { PageInfo } from "../../PageInfo";
import { ClientManager, DeviceScreenSize } from "../../Helpers/ClientManager";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver"
import { IFormletContext } from "../FormletContext";


const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'scroll',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: '60vh',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  };






export interface TNFlexGridViewProps {
    //Page? : IllerisNinthUI.TNPage | null;
    itemId? : string;
    getGrid : () => IllerisNinthUI.FlexGrid | null;
    updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
    addRowEventHandler : () => void;
    isreadonly : boolean;
    invokeViewDesigner : () => void;
    setSelection : (sel : Array<any>) => void;
    applicationName?: string;
    PageInfo: PageInfo;
    dataKey: string;
    FormletContext: IFormletContext;
}

export interface TNFlexGridViewState {
    errorMessage? : string;
    gridViewsMenuItems : Array<IContextualMenuItem>;
    gridColumns : Array<IColumn>;
    gridItems : Array<any>;
    //activeView : IllerisNinthUI.GridView | undefined | null;
    cmdBarItems : Array<ICommandBarItemProps> ;
    cmdBarFarItems : Array<ICommandBarItemProps> ;
    cmdBarOverflowItems : Array<ICommandBarItemProps> ;
    searchText? : string;
    gridHeaderContextMenu?: IContextualMenuProps;
    showColumnEditor : boolean;
    isLoading : boolean;
    selectedRows : Array<any>;
    showExportOptionsDialog: boolean;
    exportOption: number;
    
    GridViews : Array<IllerisNinthUI.GridView>;
    ViewsList : Array<IContextualMenuItem>;
    ActiveView: IllerisNinthUI.GridView | null | undefined;

    FilterObject: any;
    FilterOption: string;
    FilterValue: string;

    Width: number;
    Height: number;
}

export class TNFlexGridView extends React.Component<TNFlexGridViewProps, TNFlexGridViewState> {
    private Grid : IllerisNinthUI.FlexGrid | null= null;
    //private GridViews : Array<IllerisNinthUI.GridView> = new Array<IllerisNinthUI.GridView>();
    //private ActiveView : IllerisNinthUI.GridView | null | undefined;
    //private Columns : Array<IColumn> = new Array<IColumn>();
    private Items : Array<any> = new Array<any>();
    //private CmdBarItems : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
    //private CmdBarFarItems : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
    //private CmdBarOverflowItems : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
    private PagingInfo : { PageSize : number, CurrentPageIndex : number} = { PageSize: 25, CurrentPageIndex: 0};
    //private ViewsList : Array<IContextualMenuItem> = new Array<IContextualMenuItem>();
    private _selection?: Selection;
    private ServiceResult? : IllerisNinthAPI.ServiceResult;
    private BtnViews : ICommandBarItemProps | undefined;
    //private BtnRefresh : ICommandBarItemProps | undefined;
    //private BtnAddRow : ICommandBarItemProps | undefined;
    private mSearchText : string | null | undefined;
    private mGridFarButtons : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>() ;
    constructor(props : TNFlexGridViewProps){
        super(props);
        var that= this;

        this.getGridFromProps();
        if (this.Grid && !this.Grid.ShowViewPicker){
            this.buildColumns();
        }

        this.state ={
            errorMessage : '',
            //gridViews : this.ViewsList,
            gridColumns : this.buildColumns(false),
            gridItems : new Array<any>(),
            cmdBarItems : new Array<ICommandBarItemProps> (),
            cmdBarFarItems : new Array<ICommandBarItemProps> (),
            cmdBarOverflowItems : new Array<ICommandBarItemProps> (),
            ViewsList: new Array<IContextualMenuItem>(),
            gridViewsMenuItems: new Array<IContextualMenuItem>(),
            showColumnEditor : false,
            isLoading: false,
            selectedRows: new Array<any>(),
            showExportOptionsDialog: false,
            exportOption: 0,
            ActiveView: null,
            GridViews: new Array<IllerisNinthUI.GridView>(),
            FilterObject: {},
            FilterOption :'',
            FilterValue: '',
            Width: 0,
            Height: 0,
        }

        this._selection = new Selection({
            onSelectionChanged: () => { that.props.setSelection(this._getSelection()); this.setState({ selectedRows: this._getSelection() })},
          });
        

        this._getKey = this._getKey.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onSearchClicked = this._onSearchClicked.bind(this);
        this._onColumnClick = this._onColumnClick.bind(this);
        this._onColumnHeaderClick = this._onColumnHeaderClick.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.initializeFarButtons.bind(this);

        
    }

    shouldComponentUpdate(nextProps: TNFlexGridViewProps, nextState: TNFlexGridViewState) {
        if (this.state.ActiveView?.Id !== nextState.ActiveView?.Id 
            || this.state.gridColumns !== nextState.gridColumns
            || this.props.dataKey !== nextProps.dataKey
            || this.state.ActiveView?.Id !== nextState.ActiveView?.Id
            || this.state.isLoading !== nextState.isLoading
            || this.state.Width !== nextState.Width
            || this.state.Height !== nextState.Height
        )
        {
                return true;
        }
        else{
            return false;
        }
        /*if (this.s.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
        ){
            return true;
        }
        else{
            return false;
        }*/
    }

    public getSelectedRows = () : Array<any> =>{
        return this.state.selectedRows;
    }

    private _getSelectedRowCount(): number {
        let selectionCount : number = this._selection?.getSelectedCount() ?? 0;

        return selectionCount;
    }
    private _getSelection(): Array<any> {
        let selectionCount : number = this._selection?.getSelectedCount() ?? 0;
        switch (selectionCount) {
            case 0:
              return new Array<any>();
            default:
              return (this._selection?.getSelection() ?? new Array<any>());
          }
        //return new Array<any>();
    }
    private invokeViewDesigner = () : void =>{
        this.props.invokeViewDesigner();
    }
    private getGridFromProps = () : void => {
        this.Grid = this.props.getGrid();
        if (this.Grid && this.Grid.Paging){
            this.PagingInfo.PageSize = this.Grid.Paging.PageSize;
            this.PagingInfo.CurrentPageIndex = 0;
        }
        this.initializeFarButtons();
    }

    private _onColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            this.setState({
                gridHeaderContextMenu: this._getContextualMenuProps(ev, column),
            });
            this.forceUpdate();
        }
    };

    private getFarButtons = () : ICommandBarItemProps[] => {
        let that = this;
        //let farItems: Array<ICommandBarItemProps> = this.state.cmdBarFarItems;
        let farItems: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
        for(let x= 0; x<this.state.cmdBarFarItems.length; x++){
            if (this.state.cmdBarFarItems[x].key !== 'add' &&
                this.state.cmdBarFarItems[x].key !== 'refresh' &&
                this.state.cmdBarFarItems[x].key !== 'export' &&
                this.state.cmdBarFarItems[x].key !== 'search' &&
                this.state.cmdBarFarItems[x].key !== 'views'
            ){
                farItems.push(this.state.cmdBarFarItems[x]);
            }
        }

        if (this.Grid){
            let deviceSize: DeviceScreenSize = ClientManager.getWindowSizeType();
            //this.CmdBarFarItems = new Array<ICommandBarItemProps>();
            if (this.Grid.AddEvent && this.Grid.AddEvent.ShowAddButton){
                farItems.push({
                    iconOnly: true,
                    key: 'add',
                    iconProps: { iconName: 'Add' },
                    ariaLabel: 'Add Row',
                    ariaDescription: 'Add a new row'
                });
                //this.BtnAddRow = farItems[farItems.length-1];
                farItems[farItems.length-1].onClick = this.onAddRowClicked.bind(this);
            }
            if (this.Grid.ShowRefreshButton){
                farItems.push({
                    iconOnly: true,
                    key: 'refresh',
                    iconProps: { iconName: 'Refresh' },
                    ariaLabel: 'Refresh',
                    ariaDescription: 'Refresh the current view'
                });
                //this.BtnRefresh = farItems[farItems.length-1];
                farItems[farItems.length-1].onClick = this.onRefreshGrid.bind(this);
            }
            if (this.Grid.ShowExportButton){
                farItems.push({
                    iconOnly: true,
                    key: 'export',
                    iconProps: { iconName: 'ExcelLogo' },
                    ariaLabel: 'Export',
                    ariaDescription: 'Export to Excel'
                });
                //this.BtnRefresh = farItems[farItems.length-1];
                farItems[farItems.length-1].onClick = this.onExportGrid.bind(this);
            }
            if (deviceSize === DeviceScreenSize.L || deviceSize === DeviceScreenSize.XL || deviceSize === DeviceScreenSize.XXL){
                if (this.Grid.ShowViewPicker){
                    //debugger;
                    farItems.push({
                        iconOnly: false,
                        key: 'views',
                        text: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        label: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        iconProps: { iconName: 'View' },
                        ariaLabel: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        ariaDescription: (this.state && this.state.ActiveView && this.state.ActiveView.Name? this.state.ActiveView.Name :  'Show the Available Views'),
                        subMenuProps: {
                            items : (that.state && that.state && that.state.gridViewsMenuItems ? that.state.gridViewsMenuItems : (that.state?.ViewsList ?? new Array<IContextualMenuItem>()))
                        } 
                    });
                    this.BtnViews = farItems[farItems.length-1];
                    //this.CmdBarFarItems[this.CmdBarFarItems.length-1].onClick = this.onRefreshGrid.bind(this);
                }
                if (this.Grid.Search && (this.Grid.Search.ShowQuickSearch)){
                    //  <SearchBox placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} />
                    farItems.push({
                        iconOnly: false,
                        key: 'search',
                        onRender: () => <SearchBox showIcon={true} placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} styles={{root: { height: '25px !important', marginTop: '10px !important', visibility: (deviceSize !== DeviceScreenSize.XS && deviceSize!== DeviceScreenSize.S && deviceSize !== DeviceScreenSize.M ? 'visible': 'hidden') }, field: {height: '23px'}}}   />
                    });
                }
            }
        }
        return farItems;
    }

    private initializeFarButtons = (existingFarItems: Array<ICommandBarItemProps> | null = null, existingMoreItems: Array<ICommandBarItemProps> | null = null) : void =>{
        let that = this;
        let items: Array<ICommandBarItemProps> = existingFarItems ?? new Array<ICommandBarItemProps>();
        let moreItems: Array<ICommandBarItemProps> = existingMoreItems  ?? new Array<ICommandBarItemProps>();

        /*
        if (this.Grid){
            let deviceSize: DeviceScreenSize = ClientManager.getWindowSizeType();
            //this.CmdBarFarItems = new Array<ICommandBarItemProps>();
            if (this.Grid.AddEvent && this.Grid.AddEvent.ShowAddButton){
                items.push({
                    iconOnly: true,
                    key: 'add',
                    iconProps: { iconName: 'Add' },
                    ariaLabel: 'Add Row',
                    ariaDescription: 'Add a new row'
                });
                this.BtnAddRow = items[items.length-1];
                items[items.length-1].onClick = this.onAddRowClicked.bind(this);
            }
            if (this.Grid.ShowRefreshButton){
                items.push({
                    iconOnly: true,
                    key: 'refresh',
                    iconProps: { iconName: 'Refresh' },
                    ariaLabel: 'Refresh',
                    ariaDescription: 'Refresh the current view'
                });
                this.BtnRefresh = items[items.length-1];
                items[items.length-1].onClick = this.onRefreshGrid.bind(this);
            }
            if (this.Grid.ShowExportButton){
                items.push({
                    iconOnly: true,
                    key: 'export',
                    iconProps: { iconName: 'ExcelLogo' },
                    ariaLabel: 'Export',
                    ariaDescription: 'Export to Excel'
                });
                this.BtnRefresh = items[items.length-1];
                items[items.length-1].onClick = this.onExportGrid.bind(this);
            }
            if (deviceSize === DeviceScreenSize.L || deviceSize === DeviceScreenSize.XL || deviceSize === DeviceScreenSize.XXL){
                if (this.Grid.ShowViewPicker){
                    //debugger;
                    items.push({
                        iconOnly: false,
                        key: 'views',
                        text: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        label: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        iconProps: { iconName: 'View' },
                        ariaLabel: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        ariaDescription: (this.state && this.state.ActiveView && this.state.ActiveView.Name? this.state.ActiveView.Name :  'Show the Available Views'),
                        subMenuProps: {
                            items : (that.state && that.state && that.state.gridViewsMenuItems ? that.state.gridViewsMenuItems : (that.state?.ViewsList ?? new Array<IContextualMenuItem>()))
                        } 
                    });
                    this.BtnViews = items[items.length-1];
                    //this.CmdBarFarItems[this.CmdBarFarItems.length-1].onClick = this.onRefreshGrid.bind(this);
                }
                if (this.Grid.Search && (this.Grid.Search.ShowQuickSearch)){
                    //  <SearchBox placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} />
                    items.push({
                        iconOnly: false,
                        key: 'search',
                        onRender: () => <SearchBox showIcon={true} placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} styles={{root: { height: '25px !important', marginTop: '10px !important', visibility: (deviceSize !== DeviceScreenSize.XS && deviceSize!== DeviceScreenSize.S && deviceSize !== DeviceScreenSize.M ? 'visible': 'hidden') }, field: {height: '23px'}}}   />
                    });
                }
            }
            else{
                if (this.Grid.ShowViewPicker){
                    moreItems.push({
                        iconOnly: false,
                        key: 'views',
                        text: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        label: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        iconProps: { iconName: 'View' },
                        ariaLabel: (this.state && this.state.ActiveView && this.state.ActiveView.Name ? this.state.ActiveView.Name :  'Views'),
                        ariaDescription: (this.state && this.state.ActiveView && this.state.ActiveView.Name? this.state.ActiveView.Name :  'Show the Available Views'),
                        subMenuProps: {
                            items : (this.state && this.state.gridViewsMenuItems ? this.state.gridViewsMenuItems : (this.state && this.state.ViewsList ? this.state.ViewsList : []) )
                        } 
                    });
                    this.BtnViews = items[items.length-1];
                    //this.CmdBarFarItems[this.CmdBarFarItems.length-1].onClick = this.onRefreshGrid.bind(this);
                }
                if (deviceSize !== DeviceScreenSize.S && deviceSize !== DeviceScreenSize.M && this.Grid.Search && this.Grid.Search.ShowQuickSearch){
                    //  <SearchBox placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} />
                    moreItems.push({
                        iconOnly: false,
                        key: 'search',
                        onRender: () => <SearchBox showIcon={true} placeholder="Search" onSearch={this._onSearchClicked} onClear={this._onSearchClicked} styles={{root: { height: '25px !important', marginTop: '10px !important' }, field: {height: '23px'}}} />
                    });
                }
            }
            if (!existingMoreItems || !existingFarItems){
                that.setState({cmdBarFarItems : items, cmdBarOverflowItems: moreItems});
                that.mGridFarButtons = items;
                //that.forceUpdate();
            }
        }*/
        if (!existingMoreItems || !existingFarItems){
            that.setState({cmdBarFarItems : items, cmdBarOverflowItems: moreItems});
            that.mGridFarButtons = items;
            //that.forceUpdate();
        }
    }

    private onExportGrid = (ev?: any) : void => {
        this.setState({showExportOptionsDialog: true});
    }

    private onSelectedViewClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        debugger;
        let that = this;
        if (item && ev){
            var viewId : string = item?.key;        

            if (viewId === Guid.empty()){
                this.invokeViewDesigner();
            }
            else{
                for(var i = 0; i<that.state.GridViews.length; i++){
                    if (that.state.GridViews[i].Id === viewId){
                        this.setView(that.state.GridViews[i]);
                        return;
                    }
                }
            }
        }
    }


    private setView = (vw : IllerisNinthUI.GridView | null | undefined) : void =>{
        var that = this;
        //debugger;
        if (vw){
            if (vw && vw.Columns){
                let cols :IColumn[] = new Array<IColumn>();
                for(var i= 0; i<vw.Columns.length; i++){
                    var col : IColumn = {
                        key : i.toString(),
                        minWidth : Math.max(NumberHelper.converToNumber(vw.Columns[i].Width, 80),80),
                        name : vw.Columns[i].HeaderText != null ? vw.Columns[i].HeaderText! : '',
                        ariaLabel : vw.Columns[i].HeaderText != null ? vw.Columns[i].HeaderText! : '',
                        isIconOnly : false,
                        isResizable : true,
                        isCollapsible : true,
                        fieldName : vw.Columns[i].FieldName != null ? vw.Columns[i].FieldName! : '',
                        columnActionsMode: ColumnActionsMode.hasDropdown,
                        
                        onRender: (item: any, index? : number |undefined, col? : IColumn | undefined) => {
                            //return (<span>{typeof item[that.Grid!.Columns[i].ColField!] !== 'undefined' ? item[that.Grid!.Columns[i].ColField!] :that.Grid!.Columns[i].EmptyValue}</span>);
                            //return (<div dangerouslySetInnerHTML={{__html: that.formatColumnAsHtml(item, index, col)}}></div>)
                            return that.formatColumnAsJXX(item, index, col);
                        },
                        
                    }
                    if (vw.Columns[i].IsVisible){
                        cols.push(col);
                    }
                }
                if (that.BtnViews){
                    that.BtnViews!.text = vw.Name ?? '';
                    that.BtnViews!.ariaDescription = vw.Name ?? '';
                    that.BtnViews!.ariaLabel = vw.Name ?? '';
                }
                //that.ActiveView = vw;
                //that.setState({gridColumns : this.Columns, activeView : vw});
                for(let t = 0; t<cols.length; t++){
                    cols[t].onColumnClick =that._onColumnHeaderClick.bind(that);
                }
                that.setState({gridColumns: cols, ActiveView: vw});
            }
        }
    }

    private onRefreshGrid = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        this.setState({FilterObject: {}});
        this.refreshData();
    }
    private onAddRowClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        debugger;
        this.props.addRowEventHandler();
    }

    private loadViews = () : void =>{
        var viewUri = '/api/v2.0/terranova/xdata/core/runtime/entityviewdefinition?$filter=EntityName == "' + this.Grid?.EntityName + '"&$orderby=Order ASC, DisplayName ASC';
        var that = this;

        //debugger;

        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        epm.executeAPI(viewUri, null, 'GET', false)
            .then(function(result: any){
                if (result && result.Values) {
                    var allViews : Array<IllerisNinthUI.GridView> = new Array<IllerisNinthUI.GridView>();
                    for (var i = 0; i < result.Values.length; i++) {
                        var vwItem = result.Values[i];
                        if (vwItem.ViewData && (vwItem.ApplicationName?.toLowerCase() === that.props.applicationName?.toLowerCase() || !vwItem.ApplicationName)) {
                            var vw : IllerisNinthUI.GridView = IllerisNinthUI.GridView.ParseViewData(vwItem.ViewData);
                            allViews.push(vw);
                        }
                    }
                    //that.GridViews = allViews;
                    //that.setState({GridViews: allViews});
                    var gvl : Array<IContextualMenuItem> = new Array<IContextualMenuItem>();
                    for(var z = 0; z<allViews.length; z++){
                        gvl.push({
                            key : allViews[z].Id!,
                            text : allViews[z].Name!,
                            ariaLabel : allViews[z].Name!,
                            ariaDescription : allViews[z].Name!,
                            iconProps : {iconName : 'View'}
                        })
                    }
                    if (gvl){
                        gvl.push({
                            key : 'separator',
                            itemType :  ContextualMenuItemType.Divider,
                        })
                        gvl.push({
                            key : Guid.empty(),
                            text : 'Create New View',
                            ariaLabel : 'Create New View',
                            ariaDescription : 'Create New View',
                            iconProps : {iconName : 'Add'}
                        })
                    }

                    if (that.BtnViews && that.BtnViews.subMenuProps){
                        that.BtnViews!.subMenuProps!.items = gvl;

                        if (allViews && allViews.length > 0){
                            that.setView(allViews[0]);
                        }

                        that.setState({gridViewsMenuItems : gvl, GridViews: allViews});

                        for(var t = 0; t<that.BtnViews!.subMenuProps!.items.length; t++){
                            that.BtnViews!.subMenuProps!.items[t].onClick= that.onSelectedViewClicked.bind(that);
                        }
                        that.forceUpdate();
                    }
                    else{
                        debugger;
                        that.setState({GridViews: allViews});
                        if (allViews && allViews.length > 0){
                            that.setView(allViews[0]);
                        }
                        that.forceUpdate();
                    }
                    
                }
            })
            .catch(function(err : any){
                console.log('Failed to load grid views : ' + err.message);
                IllerisNinthUI.NotificationManager.showError('Failed to load grid views', err.message);
            });
    }

    updateDimensions() {
        this.setState({ Width: window.innerWidth, Height: window.innerHeight });
        console.log(`Width: ${window.innerWidth}, Height: ${window.innerHeight}`);
    }
    
    componentWillMount() {
        this.updateDimensions();
    }
    
    componentDidMount(){
        if (this.Grid && this.Grid.AutoLoad){
            this.refreshData();
        }
        if (this.Grid && this.Grid.ShowViewPicker){
            this.loadViews();
        }
        window.addEventListener('resize', this.updateDimensions);


    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    private buildPagingQueryString = () : string => {
        if (!this.Grid || this.Grid.Paging.EnablePaging === false)
            return '';
        var ps : string = '';
        if (this.Grid.DataSource.IsODATA === true) {
            ps += '$top=' + this.PagingInfo.PageSize;
            ps += '&$skip=' + (this.PagingInfo.CurrentPageIndex === 0 ? 0 : this.PagingInfo.CurrentPageIndex * this.PagingInfo.PageSize) ;
            return ps;
        }
        else {
            ps += '$pagesize=' + (isNaN(this.PagingInfo.PageSize) ? 25 : this.PagingInfo.PageSize);
            ps += '&$page=' + (isNaN(this.PagingInfo.CurrentPageIndex) ? 0:  this.PagingInfo.CurrentPageIndex);
            return ps;
        }
    }
    private buildFilterQueryQtring = () : string => {
        let resStr: string = '';
        let that = this;
        if (this.state.FilterObject){
            let props: string[] = Object.getOwnPropertyNames(this.state.FilterObject);
            if (props){
                props.forEach(function(propName: string, idx: number){
                    let obj: any = that.state.FilterObject[propName];
                    if (resStr){
                        resStr += '&';
                    }
                    if (obj){
                        if (obj.operator === 'eq'){
                            resStr += (`${obj.fieldname} == '${obj.value}'`);
                        }
                        else if (obj.operator === 'le'){
                            resStr += (`${obj.fieldname} < '${obj.value}'`);
                        }
                        else if (obj.operator === 'leq'){
                            resStr += (`${obj.fieldname} <= '${obj.value}'`);
                        }
                        else if (obj.operator === 'ge'){
                            resStr += (`${obj.fieldname} > '${obj.value}'`);
                        }
                        else if (obj.operator === 'geq'){
                            resStr += (`${obj.fieldname} >= '${obj.value}'`);
                        }
                        else if (obj.operator === 'contains'){
                            resStr += (`${obj.fieldname}.Contains("${obj.value}")`);
                        }
                        else if (obj.operator === 'true'){
                            resStr += (`${obj.fieldname} == true'`);
                        }
                        else if (obj.operator === 'false'){
                            resStr += (`${obj.fieldname} == true'`);
                        }

                    }
                })
            }
        }
        return resStr;
    }

    buildSearchQueryString() {
        //var that = this;
        var qs = '';
        if (this.Grid && this.Grid.Search.EnableSearch) {
            if (this.Grid.Search.Filters) {
                for (var i = 0; i < this.Grid.Search.Filters.length; i++) {
                    if (this.Grid.Search.Filters[i].SearchField) {
                        //var item =  document.getElementById(this.Grid.ItemID + this.Grid.Search.Filters[i].SearchField + 'search');
                        var item : string | null | undefined = this.mSearchText;
                        if (item) {
                            qs += (qs ? '&' + this.Grid.Search.Filters[i].SearchField + '=' + encodeURIComponent(item) : this.Grid.Search.Filters[i].SearchField + '=' + encodeURIComponent(item))
                            // if (item.hasAttribute('type') && item.getAttribute('type') === 'checkbox')
                            // {
                            //     if (item.hasAttribute('checked') && item.getAttribute('checked') === 'true'){
                            //         qs += (qs ? '&' + this.Grid.Search.Filters[i].SearchField + '=true'  : this.Grid.Search.Filters[i].SearchField + '=true');
                            //     }
                            //     else{
                            //         qs += (qs ? '&' + this.Grid.Search.Filters[i].SearchField + '=false'  : this.Grid.Search.Filters[i].SearchField + '=false');
                            //     }
                            // }
                            // else{
                            //     var v = item.hasAttribute('value') ? item.getAttribute('value') : '';
                            //     qs += (qs ? '&' + this.Grid.Search.Filters[i].SearchField + '=' + v : this.Grid.Search.Filters[i].SearchField + '=' + v)
                            // }
                        }
                    }
                }
            }
        }
        if (this.Grid && this.Grid.Search.ShowQuickSearch) {
            //debugger;
            //var qsInputEl = document.getElementById(this.Grid.ItemID + 'QuickSearch');
            var searchItem : string | null | undefined = this.mSearchText;
            if (searchItem) {
                var searchTxt = searchItem ?? '';
                var searchFields = this.Grid.Search.QuickSearchFields;
                var qsStr = '( ';
                if (searchFields && searchTxt) {
                    var sfs = searchFields.split(';');
                    for (var z = 0; z < sfs.length; z++) {
                        qsStr += sfs[z] + '.Contains("' + searchTxt + '") ';
                        if (z !== sfs.length - 1)
                            qsStr += ' OR ';
                    }
                    qsStr += ') ';
                }

                if (qs && qsStr) {
                        qs += ' AND ' + qsStr;
                }
                else if (qsStr) {
                    qs = qsStr;
                }
            }
        }
        let that=this;
        if (this.state.FilterObject && Object.getOwnPropertyNames(this.state.FilterObject)?.length > 0){
            let props: string[] = Object.getOwnPropertyNames(this.state.FilterObject);
            if (props.length > 0){
                props.forEach(function(val: string, idx: number){
                    let po: any = that.state.FilterObject.val;
                    if (po && po.fieldname && po.operator && po.value){
                        if (qs){
                            if (po.operator === 'contains'){

                            }
                            else if (po.operator === 'true' ||  po.operator === 'false'){
                                qs += ` AND ${po.fieldname} eq ${po.operator}`;    
                            }
                            else {
                                qs += ` AND ${po.fieldname} ${po.operator} '${po.value}'`;
                            }
                            
                        }
                    }
                })
            }
        }
        if (qs.indexOf('$filter') === -1){
            qs = '$filter=' + qs;
        }

        return qs;
    }

    private innerLoadData = async (bWithPaging: boolean = true) : Promise<IllerisNinthAPI.ServiceResult> =>{
        if (!this.Grid || !this.Grid.DataSource){
            return Promise.reject(new Error('No Grid or Grid Datasource defined.'));
        }

        var uri = this.Grid.DataSource.LoadURI;
        if (this.Grid.EntityPKNameIsLastURISegment && this.props.itemId){
            uri = uri.replace('{' + this.Grid.FKFieldName + '}', this.props.itemId!);
        }
        var ps : string = '';
        if (uri.indexOf('?') !== -1) {
            ps = this.buildPagingQueryString();
            if (ps)
                uri += '&' + ps;

            if (this.Grid.Search.EnableSearch || this.Grid.Search.ShowQuickSearch) {
                uri += '&' + this.buildSearchQueryString();
            }
        }
        else {
            ps = this.buildPagingQueryString();
            if (ps) {
                uri += '?' + ps;
                if (this.Grid.Search.EnableSearch || this.Grid.Search.ShowQuickSearch) {
                    uri += '&' + this.buildSearchQueryString();
                }
            }
            else {
                uri += '?' + this.buildSearchQueryString();
            }
        }

        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        return mh.execApiCallGet(cr.scopes, uri).then(function(res : any){

            return Promise.resolve(res as IllerisNinthAPI.ServiceResult)

        }).catch(function(error: any){
            Logger.logError('Failed to load FlexGrid data : ' + error.message);
            return Promise.reject(new Error(error?.message));
        })

    }

    private refreshData = () : void =>{
        //debugger;
        if (!this.Grid || !this.Grid.DataSource){
            return;
        }

        var uri = this.Grid.DataSource.LoadURI;
        if (this.Grid.EntityPKNameIsLastURISegment && this.props.itemId){
            uri = uri.replace('{' + this.Grid.FKFieldName + '}', this.props.itemId!);
        }
        var ps : string = '';
        if (uri.indexOf('?') !== -1) {
            ps = this.buildPagingQueryString();
            if (ps)
                uri += '&' + ps;

            if (this.Grid.Search.EnableSearch || this.Grid.Search.ShowQuickSearch) {
                uri += '&' + this.buildSearchQueryString();
            }
        }
        else {
            ps = this.buildPagingQueryString();
            if (ps) {
                uri += '?' + ps;
                if (this.Grid.Search.EnableSearch || this.Grid.Search.ShowQuickSearch) {
                    uri += '&' + this.buildSearchQueryString();
                }
            }
            else {
                uri += '?' + this.buildSearchQueryString();
            }
        }
        // TODO : Special Filter
        if (this.state.FilterObject){
            let fq : string = this.buildFilterQueryQtring();
            if (uri.includes('filter') && fq){
                if (uri.endsWith('=')){
                    uri += `${fq}`;
                }
                else{
                    uri += `&${fq}`;
                }
                
            }
            else if (fq){
                uri += `&filter=${fq}`;
            }
        }

        var that = this;

        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        that.setState({isLoading : true});
        //debugger;
        mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
            //debugger;
            that.setState({isLoading: false});
            if (res && res.Values){
                var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                that.ServiceResult = sr;
                if (sr && sr.ErrorMessages && Array.isArray(sr.ErrorMessages) && sr.ErrorMessages.length > 0){
                    that.setErrorMessage(sr.ErrorMessages);
                }
                if (sr){
                    sr.LoadedTimeStamp = new Date();
                    that.setActionBar(sr?.ActionBar);
                }

                if (sr && sr.Values){
                    that.Items = sr.Values;
                }
                if (sr && that.props.updateParenFormlet){
                    that.props.updateParenFormlet(sr);
                }
            }
            else if (res && res.values && Array.isArray(res.values)){
                if (that.ServiceResult){
                    delete that.ServiceResult;
                }
                that.Items = res.values;
            }
            that.setState({gridItems : that.Items, isLoading: false});
            that.forceUpdate();

        }).catch(function(error: any){
            Logger.logError('Failed to load FlexGrid data : ' + error.message);
            that.setErrorMessage('Failed to refresh grid data: ' + error.message);
            that.setState({isLoading : false});
        })

    }
    private setActionBar = (bar? : IllerisNinthAPI.ActionBar) : void =>{
        //debugger;
        let that = this;
        let items: ICommandBarItemProps[] = this.state.cmdBarFarItems;
        let farItems: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        let moreItems: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();

        if (bar && bar.Buttons && Array.isArray(bar.Buttons)){
            this.initializeFarButtons(farItems, moreItems);
            //var allButtons : Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
            //var dsh : IllerisNinthAPI.SecurityDescriptorHelper = new   IllerisNinthAPI.SecurityDescriptorHelper(this.ServiceResult);

            /*
            if (that.Grid?.AddEvent.ShowAddButton){
                if (farItems.some(z => z.key === 'add')){
                    let it:ICommandBarItemProps | undefined = farItems.find(z => z.key === 'add');
                    if (it){
                        it.onClick = this.onActionBarButtonClicked.bind(this);
                    }
                }
                else{
                    farItems.push({
                        iconOnly: false,
                        key: 'add',
                        label: 'Add Row',
                        title : 'Add row',
                        //iconProps: { iconName: 'View' },
                        iconProps: { iconName: ( 'Add') },
                        ariaLabel: 'Add Row',
                        ariaDescription: 'Add Row',
                        itemType: ContextualMenuItemType.Normal,
                        tooltipHostProps: { content : 'Add Row'}
                    });
                    farItems[farItems.length-1].onClick = this.onActionBarButtonClicked.bind(this);
                }
                that.setState({cmdBarFarItems: farItems, cmdBarOverflowItems: moreItems});
                that.forceUpdate();
            }*/
        }
    }

    private onActionBarButtonClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
        Logger.logNotification('Clicked ActionBar button ' + item?.key)
        if (item && item.key && (item.key.toLowerCase() === 'create' || item.key.toLowerCase() === 'add') && this.Grid && this.Grid.AddEvent && this.Grid.AddEvent.RowAddURI){
            //window.location.href = this.Grid.AddEvent.RowAddURI;
            let uri: string = this.Grid.AddEvent.RowAddURI ?? '';
            uri = uri.replace('{' + this.Grid.FKFieldName + '}', this.props.itemId!);
            Controller.RedirectToURI(uri, this.props.PageInfo);
            
        }
    }

    private setErrorMessage = (msg: any) : void =>{
        if (msg && Array.isArray(msg)){
            var ms: string = '';
            for(var i = 0; i<msg.length; i++){
                ms += msg;
            }
            this.setState({errorMessage : ms});
        }
        else{
            this.setState({errorMessage : msg?.toString()});
        }
    }

    private formatColumnAsHtml = (item : any, rowidx? : number |undefined,col?: IColumn |undefined) : string => {
        var val : string = '';
        var linkuri : string = '';
        
        //debugger;
        if (item !== null && col  && col.key && this.Grid){
            //var colIdx : number = parseInt(col.key);
            //var itemCol : IllerisNinthUI.FlexGridColumn = this.Grid?.Columns[colIdx];
            
            if (this.state.ActiveView){
                var itemColGv : IllerisNinthUI.GridColumn | undefined |null = this.state.ActiveView?.Columns.find(z => z.FieldName === col.fieldName);
                if (itemColGv && itemColGv.FieldName){
                    //if (typeof item[itemCol.ColField!] !== 'undefined'){
                    if (EntityHelper.isPropertyPathValid(itemColGv.FieldName, item)){
                        // todo: filter datatype
                        if (itemColGv.DataType?.toLowerCase() === 'string'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item);
    
                            if (itemColGv.EditLink){
                                var cs : ClientState = ClientState.CurrentState();
                                var targetUri = itemColGv.EditLink.replace('/view/', '/cview/' + (cs.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
                                targetUri = targetUri.replace('{Id}', item[this.Grid.PKFieldName] ?? item['Id']);
                                linkuri = targetUri;
                            }
    
                        }
                        else if ((itemColGv.DataType?.toLowerCase() === 'date') || (itemColGv.DataType?.toLowerCase() === 'datetime')){
                            //var dtVal =  item[itemCol.ColField!];
                            var dtVal = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item);
                            if (dtVal){
                                try{
                                    val = dayjs(dtVal).format(itemColGv.Format ? itemColGv.Format : 'DD/MM/YYYY');
                                }
                                catch(error){
    
                                }
                            }
                        }
                        else if (itemColGv.DataType?.toLowerCase() === 'number'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item)?.toString();
                        }
                        else {
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item)?.toString();
                        }
                    }
                    else{
                        val = itemColGv.EmptyValue? itemColGv.EmptyValue : '';
                    }
                }
            } 
            else {
                var itemCol : IllerisNinthUI.FlexGridColumn | undefined |null;
                itemCol = this.Grid?.Columns.find(z => z.ColField === col.fieldName);

                if (itemCol && itemCol.ColField){
                    //if (typeof item[itemCol.ColField!] !== 'undefined'){
                    if (EntityHelper.isPropertyPathValid(itemCol.ColField, item)){
                        // todo: filter datatype
                        if (itemCol.DataType?.toLowerCase() === 'string'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item);
    
                            if (itemCol.EditLink){
                                var csx : ClientState = ClientState.CurrentState();
                                var targetUri2 = itemCol.EditLink.replace('/view/', '/cview/' + (csx.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
                                targetUri2 = targetUri2.replace('{Id}', item[this.Grid.PKFieldName] ?? item['Id']);
                                linkuri = targetUri2;
                            }
    
                        }
                        else if (itemCol.DataType?.toLowerCase() === 'date'){
                            //var dtVal =  item[itemCol.ColField!];
                            var dtValx = EntityHelper.resolvePropertyValue(itemCol.ColField!, item);
                            if (dtValx){
                                try{
                                    val = dayjs(dtValx).format(itemCol.DataFormat ? itemCol.DataFormat : 'DD/MM/YYYY');
                                }
                                catch(error){
    
                                }
                            }
                        }
                        else if (itemCol.DataType?.toLowerCase() === 'number'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item)?.toString();
                        }
                        else {
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item)?.toString();
                        }
                    }
                    else{
                        val = itemCol.EmptyValue? itemCol.EmptyValue : '';
                    }
                }
            }
            
        }
        if (!linkuri){
            return '<span>' + val + '</span>';
        }
        else{
            var patterns : string[] = RegExHelper.extractPatterns(linkuri);
            if (patterns && patterns.length > 0){
                linkuri = RegExHelper.replacePatterns(linkuri, item);
            }
            return '<a href=\'' + linkuri + '\' class=\'tn-flexgrid-link\'>' + val + '</a></span>';
        }
    }

    private onDeleteSelectedEntity = async (item: any, rowidx: number) : Promise<void> => {
        debugger;
        if (this.Grid && this.Grid.EntityName){
            var xRes: any = await this.props.FormletContext.removeItem(this.Grid?.EntityName ?? '', item[this.Grid?.PKFieldName ?? 'Id'] ?? item['Id'], this.props.PageInfo.AppName);
            this.refreshData();
        }
    }
    private onEditSelectedEntity = (item: any, rowidx: number) : void => {
        debugger;
        /*if (itemColGv.EditLink){
            var cs : ClientState = ClientState.CurrentState();
            var targetUri = itemColGv.EditLink.replace('/view/', '/cview/' + (cs.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
            targetUri = targetUri.replace('{Id}', item[this.Grid.PKFieldName] ?? item['Id']);
            linkuri = targetUri;
        }*/
        var displayNameCol : IllerisNinthUI.FlexGridColumn | undefined = this.Grid?.Columns.find(z => z.ColField?.toLowerCase() === 'displayname');
        if (displayNameCol && displayNameCol.EditLink){
            var cs : ClientState = ClientState.CurrentState();
            var targetUri = displayNameCol.EditLink.replace('/view/', '/cview/' + (cs.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
            targetUri = targetUri.replace('{Id}', item[this.Grid?.PKFieldName ?? 'Id'] ?? item['Id']);
            Controller.RedirectToURI(targetUri, this.props.PageInfo);
        }
        //this.props.FormletContext.editItem(this.props.PageInfo.EntityName, this.props.PageInfo.EntityId, this.props.PageInfo.AppName);
    }

    private formatColumnAsJXX = (item : any, rowidx? : number |undefined,col?: IColumn |undefined) : JSX.Element => {
        var val : string = '';
        var linkuri : string = '';
        let that = this;
        //debugger;
        if (item !== null && col  && col.key && this.Grid){
            //var colIdx : number = parseInt(col.key);
            //var itemCol : IllerisNinthUI.FlexGridColumn = this.Grid?.Columns[colIdx];
            
            if (this.state.ActiveView){
                var itemColGv : IllerisNinthUI.GridColumn | undefined |null = this.state.ActiveView?.Columns.find(z => z.FieldName === col.fieldName);
                if (itemColGv && itemColGv.FieldName){
                    //if (typeof item[itemCol.ColField!] !== 'undefined'){
                    if (EntityHelper.isPropertyPathValid(itemColGv.FieldName, item)){
                        // todo: filter datatype
                        if (itemColGv.DataType?.toLowerCase() === 'string'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item);
    
                            if (itemColGv.EditLink){
                                var cs : ClientState = ClientState.CurrentState();
                                var targetUri = itemColGv.EditLink.replace('/view/', '/cview/' + (cs.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
                                targetUri = targetUri.replace('{Id}', item[this.Grid.PKFieldName] ?? item['Id']);
                                linkuri = targetUri;
                            }
    
                        }
                        else if ((itemColGv.DataType?.toLowerCase() === 'date') || (itemColGv.DataType?.toLowerCase() === 'datetime')){
                            //var dtVal =  item[itemCol.ColField!];
                            var dtVal = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item);
                            if (dtVal){
                                try{
                                    val = dayjs(dtVal).format(itemColGv.Format ? itemColGv.Format : 'DD/MM/YYYY');
                                }
                                catch(error){
    
                                }
                            }
                        }
                        else if (itemColGv.DataType?.toLowerCase() === 'number'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item)?.toString();
                        }
                        else {
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemColGv.FieldName!, item)?.toString();
                        }
                    }
                    else{
                        val = itemColGv.EmptyValue? itemColGv.EmptyValue : '';
                    }
                }
            } 
            else {
                var itemCol : IllerisNinthUI.FlexGridColumn | undefined |null;
                itemCol = this.Grid?.Columns.find(z => z.ColField === col.fieldName);

                if (itemCol && itemCol.ColField){
                    //if (typeof item[itemCol.ColField!] !== 'undefined'){
                    if (EntityHelper.isPropertyPathValid(itemCol.ColField, item)){
                        // todo: filter datatype
                        if (itemCol.DataType?.toLowerCase() === 'string'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item);
    
                            if (itemCol.EditLink){
                                var csx : ClientState = ClientState.CurrentState();
                                var targetUri2 = itemCol.EditLink.replace('/view/', '/cview/' + (csx.UserProfile?.TerraNovaTenantUniqueName ?? 'core') + '/');
                                targetUri2 = targetUri2.replace('{Id}', item[this.Grid.PKFieldName] ?? item['Id']);
                                linkuri = targetUri2;
                            }
    
                        }
                        else if (itemCol.DataType?.toLowerCase() === 'date'){
                            //var dtVal =  item[itemCol.ColField!];
                            var dtValx = EntityHelper.resolvePropertyValue(itemCol.ColField!, item);
                            if (dtValx){
                                try{
                                    val = dayjs(dtValx).format(itemCol.DataFormat ? itemCol.DataFormat : 'DD/MM/YYYY');
                                }
                                catch(error){
    
                                }
                            }
                        }
                        else if (itemCol.DataType?.toLowerCase() === 'number'){
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item)?.toString();
                        }
                        else {
                            //val = item[itemCol.ColField!]?.toString();
                            val = EntityHelper.resolvePropertyValue(itemCol.ColField!, item)?.toString();
                        }
                    }
                    else{
                        val = itemCol.EmptyValue? itemCol.EmptyValue : '';
                    }
                }
            }
            
        }
        if (col && col.fieldName?.toLowerCase() === 'displayname' && val){

            const menuProps: IContextualMenuProps = {
                shouldFocusOnMount: true,
                items: [
                  {
                    key: 'edititem',
                    text: 'Edit',
                    iconProps: { iconName: 'Edit' },
                    onClick: (ev?: any) => {that.onEditSelectedEntity(item, rowidx ?? -1); }
                  },
                  {
                    key: 'removeitem',
                    text: 'Remove Item',
                    iconProps: { iconName: 'Delete' },
                    onClick: (ev?: any) => {that.onDeleteSelectedEntity(item, rowidx ?? -1); }
                  },
                ],
              };

            if (!linkuri){
                //return (<span>{val}</span>);
                return (
                    <Stack horizontal horizontalAlign="stretch">
                        <Stack.Item align="start" style={{width: '95%'}}>
                            <span>{val}</span>
                        </Stack.Item>
                        <Stack.Item align="end" style={{width: '5%'}}>
                            <IconButton iconProps={{iconName: 'MoreVertical'}}
                                menuProps={menuProps}
                                onRenderMenuIcon={() => <div />} 
                            ></IconButton>
                        </Stack.Item>
                    </Stack>
                );
            }
            else{
                var patterns : string[] = RegExHelper.extractPatterns(linkuri);
                if (patterns && patterns.length > 0){
                    linkuri = RegExHelper.replacePatterns(linkuri, item);
                }
                return (
                    <Stack horizontal horizontalAlign="stretch">
                        <Stack.Item align="start" style={{width: '95%'}}>
                            <span><a href={linkuri} className='tn-flexgrid-link'>{val}</a></span>
                        </Stack.Item>
                        <Stack.Item align="end" style={{width: '5%'}}>
                            <IconButton iconProps={{iconName: 'MoreVertical'}}
                                menuProps={menuProps}
                                onRenderMenuIcon={() => <div />} 
                                data-linkuri={linkuri}
                            ></IconButton>
                        </Stack.Item>
                    </Stack>
                );
            }
        }
        else{
            if (!linkuri){
                return (<span>{val}</span>);
                //return '<span>' + val + '</span>';
            }
            else{
                var patterns : string[] = RegExHelper.extractPatterns(linkuri);
                if (patterns && patterns.length > 0){
                    linkuri = RegExHelper.replacePatterns(linkuri, item);
                }
                //return '<a href=\'' + linkuri + '\' class=\'tn-flexgrid-link\'>' + val + '</a></span>';
                return (<span><a href={linkuri} className='tn-flexgrid-link'>{val}</a></span>);
            }
        }
        
    }

    private buildColumns = (bSetState: boolean = false) : Array<IColumn> => {
        var that = this;
        let cols: IColumn[] = new Array<IColumn>();

        if (this.Grid && this.Grid.Columns){
            
            for(var i= 0; i<this.Grid.Columns.length; i++){
                var col : IColumn = {
                    key : i.toString(),
                    minWidth : Math.max(this.Grid.Columns[i].Width,80),
                    name : this.Grid.Columns[i].ColName != null ? this.Grid.Columns[i].ColName! : '',
                    ariaLabel : this.Grid.Columns[i].ColName != null ? this.Grid.Columns[i].ColName! : '',
                    isIconOnly : false,
                    fieldName : this.Grid.Columns[i].ColField != null ? this.Grid.Columns[i].ColField! : '',
                    columnActionsMode: ColumnActionsMode.hasDropdown,
                    //onColumnClick: that._onColumnClick,
                    onRender: (item: any, index? : number |undefined, col? : IColumn | undefined) => {
                        //return (<span>{typeof item[that.Grid!.Columns[i].ColField!] !== 'undefined' ? item[that.Grid!.Columns[i].ColField!] :that.Grid!.Columns[i].EmptyValue}</span>);
                        //return (<div dangerouslySetInnerHTML={{__html: that.formatColumnAsHtml(item, index, col)}}></div>)
                        return that.formatColumnAsJXX(item, index, col);
                      },
                    
                }
                cols.push(col);
            }
            //debugger;
            for (var ti = 0; ti < cols.length; ti++) {
                cols[ti].onColumnClick = this._onColumnHeaderClick.bind(this);
            }

            if (bSetState){
                this.setState({gridColumns : cols});
            }
            //
            
        }
        return cols;
    }
    private onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
          return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
          <TooltipHost {...tooltipHostProps} />
        );
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
              ...props,
              onRenderColumnHeaderTooltip,
            })}
          </Sticky>
        );
      };

    private _getKey(item: any, index?: number): string {
        if (item && this.Grid && this.Grid.PKFieldName){
            if (typeof item[this.Grid.PKFieldName] !== 'undefined')
                return item[this.Grid.PKFieldName]?.toString();
        }
        return '';
    }
    private _onItemInvoked(item: any): void {
        debugger;
        if (item && this.Grid && this.Grid.PKFieldName && this.state.ActiveView && this.state.ActiveView.Columns){
            for(let x= 0; x<this.state.ActiveView.Columns.length; x++){
                if (this.state.ActiveView.Columns[x].EditLink){
                    let pkField: any = item[this.Grid.PKFieldName];
                    let uri: string = this.state.ActiveView.Columns[x].EditLink.replace(`{${this.Grid.PKFieldName}}`, pkField).replace('/view/', '/cview/core/');
                    window.location.href = uri;
                }
            }
          //var uri = '/main.aspx?pagetype=kbarticle&navbar=off&forceUCI=1&articleId=' + item.knowledgearticleid;
          //window.location.href = uri;
        }
    }
    private _onSearchClicked = (newvalue : any) : void =>{
        //alert('Search Clicked');
        this.mSearchText = newvalue?.toString();
        if (this.mSearchText === '[object Object]'){
            this.mSearchText = '';
        }
        this.setState({searchText : this.mSearchText ?? ''});
        this.refreshData();
    }
    closeMessageBar = () => {
        this.setState({ errorMessage: '' });
      };
    render() {
        console.log(`Rendering Grid`);
            var that = this;

            const exportOptions: IChoiceGroupOption[] = [
                { key: '0', text: 'Export Current Page' },
                { key: '1', text: 'Export All Records' },
              ];

            this._getKey = this._getKey.bind(this);

        return (
            <Stack horizontalAlign='stretch' verticalFill className='tn-flexgrid-container' id={this.props.itemId}>
                <Stack horizontal horizontalAlign='stretch' className='tn-flexgrid-commandbar-stack'>
                    <Stack.Item className="tn-flexgrid-commandbar-whitespace">
                        &nbsp;
                    </Stack.Item>
                    <Stack.Item className="tn-flexgrid-commandbar-menu" align="end">
                        <div style={{alignContent: 'end'}}>
                            <CommandBar
                                items={new Array<ICommandBarItemProps>()}
                                overflowItems={this.state.cmdBarOverflowItems}
                                overflowButtonProps={{ ariaLabel: 'More commands' }}
                                //farItems={this.CmdBarFarItems}
                                farItems={that.getFarButtons()}
                                ariaLabel="Use left and right arrow keys to navigate between commands"
                                //className='tn-flexgrid-commandbar'
                                style={{width: '100%'}}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                {this.state.errorMessage && 
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        onDismiss={() => this.closeMessageBar()}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.errorMessage}
                </MessageBar>
                }
                <div data-is-scrollable="true">
                    <DetailsList
                        //items={that.Items}
                        items={that.state.gridItems}
                        compact={false}
                        //columns={that.Columns}
                        columns={that.state.gridColumns}
                        selectionMode={SelectionMode.single}
                        getKey={this._getKey}
                        layoutMode={DetailsListLayoutMode.justified}
                        onRenderDetailsHeader={this.onRenderDetailsHeader}
                        isHeaderVisible={true}
                        onItemInvoked={this._onItemInvoked}
                        selection={this._selection}
                        //constrainMode={ConstrainMode.horizontalConstrained}
                        className='tn-flexgrid-detaillist'
                        onRenderDetailsFooter={this._onRenderDetailsFooter} 
                        columnReorderOptions={this.getColumnReorderOptions()}
                        //onRenderCheck={this._onRenderCheckForFooterRow}
                        styles={gridStyles}
                    />
                    {that.state.gridHeaderContextMenu && <ContextualMenu {...that.state.gridHeaderContextMenu} />}                        
                </div>
                {that.state.isLoading && 
                    <ProgressIndicator label="" description="Loading..." />
                }
                {that.state.showColumnEditor &&
                    <GridViewColumnEditorPanelControl GridView={that.state.ActiveView} IsOpen={that.state.showColumnEditor} setIsOpen={this.setIsOpen} ></GridViewColumnEditorPanelControl>
                }
                <Dialog
                    hidden={!that.state.showExportOptionsDialog}
                    onDismiss={(ev?: any) => {that.setState({showExportOptionsDialog: false}); that.forceUpdate();}}
                    dialogContentProps={{type: DialogType.normal,title: 'Export Options',closeButtonAriaLabel: 'Close',subText: 'Please set the export options',}}
                    //modalProps={{}}
                >
                    <Stack verticalFill horizontalAlign="stretch">
                        <ChoiceGroup defaultSelectedKey="0" options={exportOptions} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => { that.setState({exportOption: option? Number.parseInt(option.key) : 0}) }} label="Export Options" required={true} />
                    </Stack>
                    <DialogFooter>
                        <DefaultButton onClick={(ev?: any) =>  { that.doExport();}} text="Confirm" />
                    </DialogFooter>
                </Dialog>
            </Stack>
        );
    }

    private doExport = () : void => {
        debugger;
        let that = this;
        that.setState({showExportOptionsDialog: false}); 
        that.forceUpdate();

        let workbook: Workbook = new Workbook();

        workbook.creator = 'Ninth TerraNova';
        workbook.lastModifiedBy = 'Ninth TerraNova';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        
        var worksheet = workbook.addWorksheet("Export");
        worksheet.views = [
        {state: 'frozen', xSplit: 0, ySplit: 1}
        ];

        if (this.state.gridColumns){
            let headers : any[] = new Array<any>();
            this.state.gridColumns.forEach(function(col: IColumn, idx: number){
                headers.push({
                    header: col.name, 
                    key: col.key
                })
            })
            worksheet.columns = headers;
        }
        
        var firstRow = worksheet.getRow(1);
        firstRow.font = { name: 'New Times Roman', family: 4, size: 10, bold: true, color: {argb:'80EF1C1C'} };
        firstRow.alignment = { vertical: 'middle', horizontal: 'center'};
        firstRow.height = 20;
        
        if (this.state.exportOption === 0){
            if (this.state.gridItems){
                this.state.gridItems.forEach(function(it: any, idx: number){
                    if (it){
                        const rowValues: any[] = [];
                        Object.keys(it).forEach(function(key: string, keyIdx: number){
                            rowValues[keyIdx] = it[key];
                        })
                        worksheet.addRow(rowValues);
                    }
                })
            }

            let buff = workbook.xlsx.writeBuffer().then(function (data) {
                let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                saveAs(blob, "export.xlsx");
            });
        }
        else if (this.state.exportOption === 1){
            this.innerLoadData().then(function(res: IllerisNinthAPI.ServiceResult){
                if (res && res.Values){
                    res.Values.forEach(function(it: any, idx: number){
                        if (it){
                            const rowValues: any[] = [];
                            Object.keys(it).forEach(function(key: string, keyIdx: number){
                                rowValues[keyIdx] = it[key];
                            })
                            worksheet.addRow(rowValues);
                        }
                    })

                    let buff = workbook.xlsx.writeBuffer().then(function (data) {
                        let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                        saveAs(blob, "export.xlsx");
                    });
                }
                else{
                    that.setState({errorMessage: "Failed to export."});
                }
            })
        }

        
    
    }

    private onSortDescending = (column : IColumn) : void =>{
        //var that = this;
        const newColumns: IColumn[] = this.state.gridColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = true;
            currColumn.isSorted = true;
            /*this.setState({
              announcedMessage: `${currColumn.name} is sorted ${
                currColumn.isSortedDescending ? 'descending' : 'ascending'
              }`,
            });*/
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.Items, currColumn.fieldName!, currColumn.isSortedDescending);
        //this.Columns = newColumns;
        this.Items = newItems;
        //this.forceUpdate();
        this.setState({gridColumns: newColumns, gridItems : this.Items});
    }
    private onSortAscending = (column : IColumn) : void =>{
        //var that = this;
        const newColumns: IColumn[] = this.state.gridColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = false;
            currColumn.isSorted = true;
            /*this.setState({
              announcedMessage: `${currColumn.name} is sorted ${
                currColumn.isSortedDescending ? 'descending' : 'ascending'
              }`,
            });*/
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.Items, currColumn.fieldName!, currColumn.isSortedDescending);
        //this.Columns = newColumns;
        this.Items = newItems;
        //this.forceUpdate();
        this.setState({gridColumns: newColumns, gridItems : this.Items});
    }
    private onHideColumn = (column : IColumn) : void =>{
        if (this.state.ActiveView && this.state.ActiveView.Columns.find(z => z.FieldName === column.fieldName)){
            var itemCol : IColumn | undefined = this.state.gridColumns.find(z => z.fieldName === column.fieldName);
            if (itemCol){
                let cols: IColumn[] = this.state.gridColumns;
                cols.splice(this.state.gridColumns.indexOf(itemCol),1);
                this.setState({gridColumns: cols});
                this.forceUpdate();
                this.refreshData();
            }
            
        }
    }
    private onEditColumns = (column : IColumn) : void =>{
        this.setState({showColumnEditor: true});
    }
    private setIsOpen = (bShowGridColumnEditor : boolean) : void =>{
        this.setState({showColumnEditor : false});
        this.setView(this.state.ActiveView);
    }

    private onSetFilterText = (val: string, col: IColumn) : void => {
        let so : any = this.state.FilterObject;
        if (col.fieldName){
            if (typeof so[col.fieldName] === 'undefined'){
                so[col.fieldName] = {};
            }
            so[col.fieldName].fieldname= col.fieldName!;
            //so[col.fieldName].operator = opt?.key;
            so[col.fieldName].value = val;
        }
        else{
            //so[col.fieldName!] = null;
        }
        if (val && col){
            this.setState({FilterValue : val ?? ''});
            this.forceUpdate();
        }
    }

    private onSetSelectedFilter = (opt: IDropdownOption<any> | undefined, col: IColumn) : void => {
        let so : any = this.state.FilterObject;
        if (col.fieldName){
            if (typeof so[col.fieldName] === 'undefined'){
                so[col.fieldName] = {};
            }
            so[col.fieldName].fieldname= col.fieldName!;
            so[col.fieldName].operator = opt?.key;
            so[col.fieldName].value = this.state.FilterValue;
        }
        else{
            //so[col.fieldName!] = null;
        }
        this.setState({FilterOption : opt?.key?.toString() ?? 'eq'});
        this.forceUpdate();
    }

    private getSelectedFilter = (col: IColumn) : string => {
        let so : any = this.state.FilterObject;
        if (so && col.fieldName && so[col.fieldName!]){
            return so[col.fieldName!]?.operator;
        }
        else {
            return this.getDefaultColumnOperators(col);
        }
    }
    
    private onDoFilter = (col: IColumn) : void => {
        this.refreshData();
    }
    private onClearFilter = (col: IColumn) : void => {
        this.setState({FilterObject: {}});
        this.refreshData();
    }


    private _getContextualMenuProps(ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps {
        var that = this;
        var items : IContextualMenuItem[] = new Array<IContextualMenuItem>();
        //if (column.isSorted && !column.isSortedDescending){
            items.push({
                key: 'sortascending',
                name: 'Sort Ascending',
                iconProps: { iconName: 'SortUp' },
                onClick: () => { that.onSortAscending(column); }
            });
            items.push({
                key: 'sortdescending',
                name: 'Sort Descending',
                iconProps: { iconName: 'SortDown' },
                onClick: () => { that.onSortDescending(column); }
            });
            items.push({
                key: 'hide',
                name: 'Hide',
                iconProps: { iconName: 'Hide3' },
                onClick: () => { that.onHideColumn(column); }
            });
            items.push({
                key: 'edit',
                name: 'Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => { that.onEditColumns(column); }
            });
            items.push({
                key: 'filter',
                name: 'Filter',
                iconProps: { iconName: 'Filter' },
                subMenuProps: {
                    items: [
                        {
                            key: 'filterItem',
                            name: 'filterItem',
                            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean | undefined) => void) =>  {
                                return (<Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: 10}} style={{padding: '10px'}}>
                                    <Stack.Item align="center" style={{height: '3Opx'}}>
                                        <Text>{column.name}</Text>
                                    </Stack.Item>
                                    <Stack.Item style={{height: '3Opx'}}>
                                        <Dropdown
                                            options={this.getColumnOperators(column)}
                                            defaultSelectedKey={this.getDefaultColumnOperators(column)}
                                            selectedKey={this.getSelectedFilter(column)}
                                            //onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {this.onSetSelectedFilter(option, column)}}
                                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {this.onSetSelectedFilter(option, column)}}
                                        >
                                        </Dropdown>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField onChange={(ev?:any, newValue?: string) => {this.onSetFilterText(newValue ?? '', column)}} value={this.state.FilterValue} >

                                        </TextField>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal horizontalAlign="stretch">
                                        <Stack.Item style={{width: '50%'}} align='center'>
                                        <IconButton iconProps={{iconName: 'Filter'}} label="Apply" text="Apply" onClick={(ev?: any) => { this.onDoFilter(column); }} ></IconButton>
                                        </Stack.Item>
                                        <Stack.Item style={{width: '50%'}} align='center'>
                                        <IconButton iconProps={{iconName: 'ClearFilter'}} label="Clear" text="Clear" onClick={(ev?: any) => { this.onClearFilter(column); }}></IconButton>
                                        </Stack.Item>
                                        </Stack>
                                        
                                        
                                    </Stack.Item>
                                </Stack>)
                            }
                        }
                    ]
                }
            });
        
        return {
            items: items,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomLeftEdge,
            gapSpace: 10,
            isBeakVisible: true,
            onDismiss: this._onContextualMenuDismissed,
        };
    }

    private getColumnOperators = (col: IColumn) : IDropdownOption[] => {
        let fn: string = col.fieldName ?? '';
        let gcol : IllerisNinthUI.FlexGridColumn | undefined = this.Grid?.Columns?.find(z => z.ColField === fn);
        if (col){
            if (gcol?.DataType?.toLowerCase() === 'string'){
                let res: IDropdownOption[] = new Array<IDropdownOption>();
                res.push({key: 'eq', text: 'Equal To'});
                res.push({key: 'neq', text: 'Not Equal To'});
                res.push({key: 'contains', text: 'Contains'});
                return res;
            }
            else if (gcol?.DataType?.toLowerCase() === 'date' || gcol?.DataType?.toLowerCase() === 'datetime'){
                let res: IDropdownOption[] = new Array<IDropdownOption>();
                res.push({key: 'eq', text: 'Equal To'});
                res.push({key: 'neq', text: 'Not equal To'});
                res.push({key: 'le', text: 'Before'});
                res.push({key: 'ge', text: 'After'});
                return res;
            }
            else if (gcol?.DataType?.toLowerCase() === 'boolean' || gcol?.DataType?.toLowerCase() === 'bool'){
                let res: IDropdownOption[] = new Array<IDropdownOption>();
                res.push({key: 'true', text: 'True'});
                res.push({key: 'false', text: 'False'});
                return res;
            }
            else if (gcol?.DataType?.toLowerCase() === 'number' || gcol?.DataType?.toLowerCase() === 'int'|| gcol?.DataType?.toLowerCase() === 'double'){
                let res: IDropdownOption[] = new Array<IDropdownOption>();
                res.push({key: 'eq', text: 'Equal To'});
                res.push({key: 'neq', text: 'Not equal To'});
                res.push({key: 'le', text: 'Less then'});
                res.push({key: 'leq', text: 'Less then or Equal To'});
                res.push({key: 'ge', text: 'Greater Then'});
                res.push({key: 'geq', text: 'Greater Then or Equal To'});
                return res;
            }
            else{
                let res: IDropdownOption[] = new Array<IDropdownOption>();
                res.push({key: 'eq', text: 'Equal To'});
                res.push({key: 'neq', text: 'Not Equal To'});
                return res;
            }
        }
        let res: IDropdownOption[] = new Array<IDropdownOption>();
        return res;
    }
    private getDefaultColumnOperators = (col: IColumn) : string => {
        let fn: string = col.fieldName ?? '';
        let gcol : IllerisNinthUI.FlexGridColumn | undefined = this.Grid?.Columns?.find(z => z.ColField === fn);
        if (col){
            if (gcol?.DataType?.toLowerCase() === 'string'){
                return 'eq';
            }
            else if (gcol?.DataType?.toLowerCase() === 'date' || gcol?.DataType?.toLowerCase() === 'datetime'){
                return 'eq';
            }
            else if (gcol?.DataType?.toLowerCase() === 'boolean' || gcol?.DataType?.toLowerCase() === 'bool'){
                return 'true';
            }
            else if (gcol?.DataType?.toLowerCase() === 'number' || gcol?.DataType?.toLowerCase() === 'int'|| gcol?.DataType?.toLowerCase() === 'double'){
                return 'eq';
            }
            else{
                return 'eq';
            }
        }
        return '';
    }

    private _onContextualMenuDismissed = (): void => {
        this.setState({
            gridHeaderContextMenu: undefined,
        });
        this.forceUpdate();
    };
    private handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
        const draggedItems = this.state.gridColumns[draggedIndex];
        const newColumns: IColumn[] = [...this.state.gridColumns];
    
        // insert before the dropped item
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);
        //setColumns(newColumns);

        if (this.Grid && this.Grid.Columns){
            let draggedColumnItem : IllerisNinthUI.FlexGridColumn = this.Grid.Columns[draggedIndex];
            const newGridColumns : IllerisNinthUI.FlexGridColumn[] = [...this.Grid.Columns];
            newGridColumns.splice(draggedIndex, 1);
            newGridColumns.splice(targetIndex, 0, draggedColumnItem);
            this.Grid.Columns = newGridColumns;
        }


        this.setState({gridColumns: newColumns});
        this.forceUpdate();
    };

    private getColumnReorderOptions = () : IColumnReorderOptions => {
        return {
          frozenColumnCountFromStart: 0,
          frozenColumnCountFromEnd: 0,
          handleColumnReorder: this.handleColumnReorder,
        };
      };
    

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        //const { columns, items } = this.state;
        const newColumns: IColumn[] = this.state.gridColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
            /*this.setState({
              announcedMessage: `${currColumn.name} is sorted ${
                currColumn.isSortedDescending ? 'descending' : 'ascending'
              }`,
            });*/
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.Items, currColumn.fieldName!, currColumn.isSortedDescending);
        //this.Columns = newColumns;
        this.Items = newItems;
        //this.forceUpdate();
        this.setState({gridItems : this.Items, gridColumns: newColumns});
        
      };
    
    
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
      const key = columnKey as keyof T;
      return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
    private _onRenderDetailsFooter = (detailsFooterProps?: IDetailsFooterProps): JSX.Element =>{
        return (
          <DetailsRow
            {...detailsFooterProps}
            columns={detailsFooterProps?.columns}
            item={{}}
            itemIndex={-1}
            groupNestingDepth={detailsFooterProps?.groupNestingDepth}
            selectionMode={SelectionMode.single}
            selection={detailsFooterProps?.selection}
            onRenderItemColumn={_renderDetailsFooterItemColumn}
            onRenderCheck={_onRenderCheckForFooterRow}
           
          />
        );
      }
    private _innerRenderColumnFooter = (props?: IDetailsFooterProps, defaultRender?: (props?: IDetailsFooterProps) => JSX.Element | null): JSX.Element => {
        const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };
        return (<DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={false} canSelect={false} />);
    }

    private _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
        if (column) {
          return (
            <div>
              <b>{column.name}</b>
            </div>
          );
        }
        return '??';
      };
      
      

    private _onRenderCheckForFooterRow =  (props: IDetailsRowCheckProps): JSX.Element => {
        const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };
        return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true} />;
      };
      
}
const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
    debugger;
    if (column) {
      return (
        <div>
          <b>{column.key}</b>
        </div>
      );
    }
    return '??';
  };
  
  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };
  
  const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    //debugger;
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true} />;
  };




  