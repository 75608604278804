/* eslint-disable no-loop-func */
import { IComboBox, IComboBoxOption, VirtualizedComboBox } from "@fluentui/react/lib/components/ComboBox";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Logger } from "../../Logger";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

import { TNDocumentInfo, TNDocumentInputEditor } from "../../TNDocumentEditor";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

 
  export class TNDocumentEditor extends React.Component<TNEditorFactoryProps, TNEditorFactoryState> {
      private Editor : IllerisNinthUI.TNEditorBase | null= null;
      private InputEditor : IllerisNinthUI.TNInputBox | null;
      private mDocumentEditor? : IllerisNinthUI.TNDocumentInput | null = null;
      private Entity : DynamicEntity | null | undefined;

      private mPropertyName : string = '';
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            loadingMessage: ''
        }

        this.mDocumentEditor = props.getEditor() as IllerisNinthUI.TNDocumentInput;

        this.Editor = null;
        this.InputEditor = null;
        this.Entity = null;
        this.onTextInputChanged = this.onTextInputChanged.bind(this);
        this.onToggleInputChanged = this.onToggleInputChanged.bind(this);
    }
    
    componentDidMount(){
        this.loadData();
        document.addEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNEditorFactoryState) {
        //Logger.logNotification('TNInputBoxView::shouldComponentUpdate');
        if (this.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
        ){
            return true;
        }
        else{
            return false;
        }
    }

    private loadData = () : void => {
        this.mDocumentEditor = this.props.getEditor() as IllerisNinthUI.TNDocumentInput;
        
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNEditorFactoryState){
        var prevEnt : DynamicEntity | null | undefined = prevProps.Entity;
        var thisEnt : DynamicEntity | null | undefined = this.props.Entity;
        if (this.props.getEditor()?.HSBindName !== prevProps.getEditor()?.HSBindName || prevEnt?.getValue(prevProps.getEditor()?.HSBindName ? prevProps.getEditor()?.HSBindName : '') !== thisEnt?.getValue(this.props.getEditor()?.HSBindName ? this.props.getEditor()?.HSBindName : '')
            || this.props.dataKey !== prevProps.dataKey
            || this.props.ServiceResult?.LoadedTimeStamp !== prevProps.ServiceResult?.LoadedTimeStamp
        ){
            this.loadData();
        }
    }

    private getDocumentInfo = () : IllerisNinthUI.TNDocumentInput | null | undefined =>{
        return this.mDocumentEditor;
    }

    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.mPropertyName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity){
            this.Entity.setValue(propName, val);
        }
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        Logger.logNotification('TNInputBoxView::Render - Entity ' + this.props.Entity?.LogicalEntityName + ', id ' + this.props.Entity?.getId() + ', hsBindName ' + this.props.getEditor()?.HSBindName + ', value ' + this.props.Entity?.getValue(this.props.getEditor()?.HSBindName));

        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
          });
          //var valStr : string = (this.Entity && this.Entity.getValue(this.Editor!.HSBindName) ? this.Entity.getValue(this.Editor!.HSBindName) : '');

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };


        return (
                    <TNDocumentInputEditor 
                        labelText={this.mDocumentEditor?.LabelText ?? ''} 
                        isReadOnly={this.mDocumentEditor?.IsReadOnly || this.props.isreadonly} 
                        textWidth={this.mDocumentEditor?.Width ?? 250} 
                        getFile={this.getFile} 
                        maxFileSize={this.mDocumentEditor?.MaxFileSize ?? 100000}
                        acceptedFiles={this.mDocumentEditor?.AcceptedFiles ?? ''}
                        hsBindName={this.mDocumentEditor?.HSBindName ?? ''}
                        updateValue={this.updateValue}
                    ></TNDocumentInputEditor>
        );
      }
      private getFile = () : TNDocumentInfo | null =>{
          if (this.mDocumentEditor){
                var ti : TNDocumentInfo = new TNDocumentInfo();
                if (this.Entity && this.mDocumentEditor){
                    ti.FileDataBase64 = (this.Entity && this.Entity.getValue(this.mDocumentEditor!.HSBindName) ? this.Entity.getValue(this.mDocumentEditor!.HSBindName): '');
                }
                return ti;
          }
          return null;
      }

      private onTextInputChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue : string | undefined) : void =>{
        console.log('input changed to value : ' + newValue);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, newValue);
        }
        this.setState({valueStr : newValue});
      }
      private onToggleInputChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) : void =>{
        console.log('toggle changed to value : ' + checked);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, checked);
        }
        this.setState({valueStr : checked ? 'true' : 'false'});
      }


  }