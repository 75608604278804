import { CommandBar, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, ICommandBarItemProps, IconButton, IDropdownOption, Link, MessageBar, MessageBarType, Panel, Pivot, PivotItem, PrimaryButton, ProgressIndicator, SelectionMode, Stack, TextField } from "@fluentui/react";
import dayjs from "dayjs";
import { promises } from "fs";
import { takeRightWhile } from "lodash";
import { nanoid } from "nanoid";
import React, { TableHTMLAttributes } from "react";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { StringHelper } from "../Helpers/StringHelper";
import { AppResourcesEditControl } from "./AppResourcesEdit";
import { AppResource } from "./Entities/AppResource";
import { AuthorProfile } from "./Entities/AuthorProfile";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";
import { EntityFormsViewControl } from "./EntityFormsViewControl";
import { EntityPropertiesViewControl } from "./EntityPropertiesViewControl";
import { EntityRelationsViewControl } from "./EntityRelationsViewControl";
import { EntityViewsViewControl } from "./EntityViewsViewControl";

interface AppResourcesViewProps {
    tenantInfo: TenantInfo;
    app: SystemApplicationInfo;
    
}

interface AppResourcesViewState extends AppResourcesViewProps {
  StateKey : number;
  errorMessage? : string;
  columns: IColumn[];
  allResources : AppResource[];
  isbusy : boolean;
  newResource?: AppResource;
  showEditResource? :boolean;

  barItems: ICommandBarItemProps[];
  barFarItems: ICommandBarItemProps[];
  viewMode: 'view' | 'edit';
  showAddPanel: boolean;
  editItem?: AppResource;

  resourceTypes: IDropdownOption[];
  profile?: AuthorProfile;
}

export class AppResourcesViewControl extends React.Component<AppResourcesViewProps, AppResourcesViewState> {
    constructor(props: AppResourcesViewProps) {
      super(props);

      let resOpts: IDropdownOption[] = new Array<IDropdownOption>();
      resOpts.push({key: 0, text: "Unknown"});
      resOpts.push({key: 1, text: "Javascript File"});
      resOpts.push({key: 2, text: "PNG File"});
      resOpts.push({key: 3, text: "SVG File"});

      this.state = {
        StateKey : 0,
        tenantInfo: props.tenantInfo,
        app: props.app,
        columns: new Array<IColumn>(),
        allResources: new Array<AppResource>(),
        isbusy : false,
        barItems: new Array<ICommandBarItemProps>(),
        barFarItems: new Array<ICommandBarItemProps>(),
        viewMode: 'view',
        showAddPanel: false,
        resourceTypes: resOpts
      }
    }
  
    componentDidMount() {
        this.buildColumns();
        this.loadResources();
    }

    public shouldComponentUpdate = (nextProps: AppResourcesViewProps, nextState: AppResourcesViewState) : boolean =>{
        if (nextProps.tenantInfo?.Id !== this.props.tenantInfo?.Id || nextProps.app?.Id && this.props.app?.Id || nextState.viewMode !== this.state.viewMode){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : AppResourcesViewProps, prevState: AppResourcesViewState){
        var that = this;
        if (prevProps.tenantInfo?.Id && this.props.tenantInfo?.Id || prevProps.app?.Id && this.props.app?.Id || prevState.viewMode !== this.state.viewMode){
        }
    }
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private getResourceName = (resType: number) : string =>{
        if (resType === 0){
            return 'file';
        }
        else if (resType === 10){
            return 'JS';
        }
        else{
            return 'file';
        }
    }

    private onEditResource = (item: AppResource) : void =>{
        this.setState({newResource: item, showEditResource: true, viewMode: 'edit'});
        this.forceUpdate();
    }
    private getResourceTypeName = (res: AppResource) : string => {
      if (res.ResourceType === 0){
        return 'unknown';
      }
      else if (res.ResourceType === 1){
        return 'jscript';
      }
      else {
        return 'unknown';
      }
    }

    private buildColumns = () : void =>{
        let that = this;
        let cols : IColumn[] = new Array<IColumn>();
        cols.push(
          {
            key: 'icon',
            name: 'icon',
            ariaLabel: 'icon',
            isIconOnly: true,
            fieldName: 'Id',
            minWidth: 32,
            maxWidth: 32,
            isRowHeader: true,
            isResizable: false,
            data: 'string',
            isPadded: true,
            onRender: (item: AppResource) => {
              return <IconButton iconProps={{iconName: that.getResourceName(item.ResourceType)}} ></IconButton>;
            },
          });
        cols.push(
            {
              key: 'coldisplayname',
              name: 'Display Name',
              ariaLabel: 'Display Name',
              isIconOnly: false,
              fieldName: 'DisplayName',
              minWidth: 80,
              maxWidth: 500,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              onRender: (item: AppResource) => {
                return <Link onClick={() => this.onEditResource(item)}>{item.DisplayName}</Link>;
              },
            });
            cols.push(
              {
                key: 'resourcetype',
                name: 'Resource Type',
                ariaLabel: 'Resource Type',
                fieldName: 'ResourceType',
                minWidth: 90,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: false,
                data: 'string',
                isPadded: true,
                onRender: (item: AppResource) => {
                  return <span>{that.getResourceTypeName(item)}</span>
                },
              });
          cols.push(
            {
              key: 'colcreatedate',
              name: 'Create Date',
              ariaLabel: 'Create Date',
              isIconOnly: false,
              fieldName: 'CreateDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: AppResource) => {
                return <span>{dayjs(item.CreateDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        cols.push(
            {
              key: 'colmoddate',
              name: 'Modified Date',
              ariaLabel: 'Modified Date',
              isIconOnly: false,
              fieldName: 'LastModifiedDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: AppResource) => {
                return <span>{dayjs(item.LastModifiedDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        
        this.setState({columns : cols});
    }
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.state.allResources, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
          columns: newColumns,
          allResources: newItems,
        });
      
    }
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }    
    
    private loadResources = async () : Promise<AppResource[]> => {
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        var that = this;
        that.setState({isbusy : true});
        return mh.loadResourcesInApp(that.props.tenantInfo?.Id, that.props.app.Id)
            .then(function(res : AppResource[]){
                that.setState({isbusy : false, allResources: res, errorMessage: ''});
                that.forceUpdate();

                return mh.loadProfile(that.props.tenantInfo?.Id, that.props.app.PublisherId)
                  .then(function(pres: AuthorProfile){
                    that.setState({profile: pres});
                    that.forceUpdate();
                    return Promise.resolve(res);
                  })
                  .finally(function(){
                    return Promise.resolve(res);
                  });
            });
    }

    
    private _getKey = (item: any, index?: number): string => {
        return item.key;
    }

    private _onItemInvoked(item: AppResource): void {
        //alert(`Item invoked: ${item.name}`);
        this.onEditResource(item);
    }

    private _buildNavBar = () : ICommandBarItemProps[] => {
      let menuItems: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
      menuItems.push({
        key: 'menuItemRefresh',
        text: 'Refresh',
        ariaLabel: 'Refresh',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: (ev?: any) => {this.loadResources()}
      });
      menuItems.push({
        key: 'menuItemAdd',
        text: 'Add',
        ariaLabel: 'Add',
        iconOnly: true,
        iconProps: {iconName: 'Add'},
        onClick: this.onAddAppResource
      });
      
      return menuItems;
    }

    private onAddAppResource = (evt?: any) : void => {
      this.setState({newResource: new AppResource(), showAddPanel: true});
    }

    render() {
        let that = this;
        return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-solution-contentcontrol'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                  dismissButtonAriaLabel="Close"
                >
                 {that.state.errorMessage}
                </MessageBar>
              }
              {that.state.viewMode === 'view' && 
                <CommandBar
                  items={that.state.barItems}
                  farItems={that._buildNavBar()}
                  ariaLabel="Actions"
                  primaryGroupAriaLabel="Actions"
                  farItemsGroupAriaLabel="More actions"
                />
              }
              {that.state.viewMode === 'view' && 
                <DetailsList
                  items={this.state.allResources}
                  compact={true}
                  columns={this.state.columns}
                  selectionMode={SelectionMode.none}
                  getKey={this._getKey}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                />
              }
              {that.state.viewMode === 'edit' && 
                <AppResourcesEditControl
                  AppResourceId={that.state.newResource?.Id ?? ''}
                  tenantId={that.props.tenantInfo?.Id}
                  app={that.props.app}
                  key={nanoid()}
                />
              }
              {this.state.showAddPanel && 
                <Panel
                  headerText="Add New Resource"
                  isOpen={this.state.showAddPanel}
                  onDismiss={(ev?: any) => {that.setState({showAddPanel: false}); that.forceUpdate();}}
                  
                  closeButtonAriaLabel="Close"
                >
                  <TextField label="Name" value={that.state.newResource?.DisplayName} onChange={that.onResourceDisplayNameChanged}></TextField>
                  <Dropdown label="Resource Type" options={that.state.resourceTypes} selectedKey={that.state.newResource?.ResourceType} onChange={that.onResourceTypeChanged}></Dropdown>
                  <TextField label="Logical name"  value={that.state.newResource?.LogicalName} readOnly={true} disabled={true}></TextField>

                  <PrimaryButton label="Save" text="Save" style={{marginTop: '25px'}} onClick={that.onSave} ></PrimaryButton>
                </Panel>
              }
          </Stack>
      );
    }

    private onResourceDisplayNameChanged = (ev?: any, newValue?: string) => {
      let it : AppResource | undefined  = this.state.newResource;  
      if (it){
        it.DisplayName = newValue ?? '';
        it.LogicalName = this.state.profile?.AuthorPrefix ?? '';
        if (it.LogicalName){
          it.LogicalName += '_';
        }
        it.LogicalName += StringHelper.logicalNameOnly(it.DisplayName);
        this.setState({newResource : it});
      }
    }
    private onResourceTypeChanged = (ev?: any, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
      let it : AppResource | undefined  = this.state.newResource;  
      if (it){
        it.ResourceType = option?.key as number || 0;
        this.setState({newResource : it});
      }
    }
    private onSave = (ev?: any) => {
      let it : AppResource | undefined  = this.state.newResource;  
      let that = this;
      if (it?.ResourceType && it.DisplayName && it.LogicalName){
        let dmh: DesignTimeModelHelper = new DesignTimeModelHelper();
        dmh.updateAppResource(this.props.tenantInfo?.Id, this.props.app.Id, it)
          .then(function(res: AppResource){
            that.setState({newResource: undefined, showAddPanel: false, showEditResource: false});
            that.loadResources();
          })
          .catch(function(err: any){
            that.setState({errorMessage: err?.message});
            that.forceUpdate();
          })
      }
    }

  }
  