import { Stack } from "@fluentui/react";
import React from "react";
import { ProductPriceMatrix } from "../../designers/Entities/ProductPricing";
import { DynamicEntity } from "../../DynamicEntity";
import { PageInfo } from "../../PageInfo";
import { TNProductPriceMatrixEditControl } from "./TNProductPriceMatrixEditControl";

export interface TNProductPriceEditControlProps {
    tenantId: string;
    PageInfo: PageInfo;
    isReadOnly: boolean;
    getEntity : () => DynamicEntity | null | undefined;
}

export interface TNProductPriceEditControlState  {
    Product?: any;
    errorMessage?: string;
    notificationMessage?: string;
    PriceMatrix?: ProductPriceMatrix;
    PriceType: 'product' | 'subscription';
    productId: string;
}
  
export class TNProductPriceEditControl extends React.Component<TNProductPriceEditControlProps, TNProductPriceEditControlState> {
      constructor(props : TNProductPriceEditControlProps){
          super(props);
          
          this.state = {
            PriceType: 'product',
            productId: this.props.getEntity()?.getId() ?? ''
          }
      }

      private loadProduct = async (productId?: string) : Promise<void> => {
          let prodEnt : DynamicEntity | null | undefined = this.props.getEntity();
          if (prodEnt){
              let pm: ProductPriceMatrix = new ProductPriceMatrix();
              let pd: string = prodEnt.getValue('PriceData') ?? '';
              if (prodEnt && pd){
                 pm = JSON.parse(pd) as ProductPriceMatrix;
              }
              this.setState({Product: prodEnt.getEntity(), PriceMatrix: pm, PriceType: (prodEnt.getValue('UnitOfMeasure') === 2 ? 'subscription' : 'product')});
              this.forceUpdate();
          }
      }

      public updatePriceData = () : void => {
          let pm: ProductPriceMatrix | undefined = this.state.PriceMatrix;
          let prod: any =this.state.Product;
          if (prod && pm){
              prod.PriceData = JSON.stringify(pm);
          }
      }


      public componentDidMount(){
          this.loadProduct();
      }

      public shouldComponentUpdate(nextProps: TNProductPriceEditControlProps, nextState: TNProductPriceEditControlState){
        if (nextProps.isReadOnly !== this.props.isReadOnly) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNProductPriceEditControlProps, prevState: TNProductPriceEditControlState){
        if (prevProps.isReadOnly !== this.props.isReadOnly){
            this.loadProduct();
        }
      }
      public render(){
          var that = this;
         
          return (
              <Stack verticalFill horizontalAlign='stretch'>
                  {that.state.PriceMatrix && that.state.Product &&
                    <TNProductPriceMatrixEditControl 
                            Product={that.state.Product} 
                            PriceMatrix={that.state.PriceMatrix} 
                            tenantId={that.props.tenantId} 
                            productId={that.state.productId} 
                            PageInfo={that.props.PageInfo}
                            isReadOnly={that.props.isReadOnly}
                            getEntity={that.props.getEntity}
                            updatePriceData={that.updatePriceData}
                    >

                    </TNProductPriceMatrixEditControl>
                  }
                  
              </Stack>
          );
      }
  }