/* eslint-disable no-loop-func */
import { ProgressIndicator, SearchBox, Stack } from "@fluentui/react";
//import { takeRightWhile } from "lodash";
import React from "react";
import { EntityCustomPrivilegeDefinition } from "../designers/Entities/EntityCustomPrivilegeDefinition";
import { EntityPrivilegeDefinition } from "../designers/Entities/EntityPrivilegeDefinition";
import { DynamicEntity } from "../DynamicEntity";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { Guid } from "../Guid";
import { PrivilegeHelper } from "../Helpers/PrivilegeHelper";
import { PageInfo } from "../PageInfo";
import { nanoid } from "nanoid";
//import { IllerisNinthAPI } from "../ServiceResult";

export interface TNRolePrivilegeEditProps {
    id : string;
    roleid : string;
    rolename : string;
    //applicationid : string;
    //applicationname : string;
    tenantUniqueName : string;
    issystemadmin : boolean;
    isreadonly: boolean;
    privilegesType : 'default' | 'custom';
    getEntity: () => DynamicEntity | null | undefined;
    PageInfo: PageInfo;
    dataKey: string;
}

export interface TNRolePrivilegeEditState  {
    errorMessage : string;
    issystemadmin : boolean;
    isloading : boolean;
    searchValue : string;
}





export class TNRolePrivilegeEditControl extends React.Component<TNRolePrivilegeEditProps, TNRolePrivilegeEditState> {
    private Privileges : Array<EntityPrivilegeDefinition> = new Array<EntityPrivilegeDefinition>();
    private CustomPrivileges : Array<EntityCustomPrivilegeDefinition> = new Array<EntityCustomPrivilegeDefinition>();
    private appId : string = '';
    private appName : string = '';
    constructor(props : TNRolePrivilegeEditProps){
        super(props);
        if (this.props && this.props.getEntity){
            var de : DynamicEntity | null | undefined = this.props.getEntity();
            if (de && de.getValue('ApplicationId')){
                this.appId = (de.getValue('ApplicationId'));    
                this.appName = (de.getValue('ApplicationName'));    
            }
        }
        //this.appId = (this.props.getEntity() as any)?.ApplicationId;
      
        this.state = {
            errorMessage : '',
            issystemadmin : this.props.issystemadmin,
            isloading : false,
            searchValue : ''
        }

    }
    shouldComponentUpdate(nextProps: TNRolePrivilegeEditProps, nextState: TNRolePrivilegeEditState) {
        if (nextProps.dataKey !== this.props.dataKey){
            return true;
        }
        else{
            return false;
        }
    }

    public componentDidMount(){
        var that = this;
        if (that.props.privilegesType === 'default'){
            that.loadDefaultRolePrivileges().then(function(res: any){
                that.forceUpdate();
            })
        }
        else if (that.props.privilegesType === 'custom'){
            that.loadCustomRolePrivileges().then(function(res: any){
                that.forceUpdate();
            })
        }
    }

    private loadDefaultRolePrivileges = () : Promise<void> =>{
        var that = this;
        if (!this.appId){
            return Promise.resolve();
        }
        //var uri : string = "/odata/Runtime/EntityDefinition?$filter=ApplicationId eq " + this.appId + "&$orderby=DisplayName"
        var uri : string = `/odata/${this.props.PageInfo.TenantId}/Runtime/EntityDefinition?$orderby=DisplayName&$expand=Application`;
        var epm : EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantUniqueName);
        that.setState({isloading : true});
        that.forceUpdate();
        that.Privileges = new Array<EntityPrivilegeDefinition>();

        if (!that.props.roleid || that.props.roleid === Guid.empty()){
            return Promise.resolve();
        }
        return epm.executeAPIAny(uri, null, 'GET',false)
            .then(function (res: any){
                if (res && res.values) {
                    for (var x = 0; x < res.values.length; x++) {
                        if (res.values[x].DisplayName) {
                            that.Privileges.push( new EntityPrivilegeDefinition(res.values[x].Id,res.values[x].DisplayName, res.values[x]['Application'] ? res.values[x]['Application']['DisplayName']?.toString() : that.appName , res.values[x].ApplicationId));
                        }
                    }
                }

                return epm.executeAPIAny(`/odata/${that.props.PageInfo.TenantId}/Runtime/SecurityRolePrivilegeDefinition?$filter=RoleId eq `  + that.props.roleid + "&$expand=Privilege", null, 'GET', false)
                .then(function (response : any) {
                    if (response && response.values) {
                        for (var x = 0; x < response.values.length; x++) {
                            var it = response.values[x];
                            if (it.Privilege.EntityName) {
                                var xp : EntityPrivilegeDefinition |null | undefined = that.Privileges.find(z => z.EntityName === it.Privilege.EntityName);
                                if (xp){
                                    var propName = it.Privilege.EventName + 'Level';
                                    (xp as any)[propName] = isNaN(parseInt(it.Level)) ? 0 : parseInt(it.Level);
                                }
                            }
                        }
                    }

                    that.Privileges.sort((a, b) => a.ApplicationName >= b.ApplicationName ? ( a.ApplicationName > b.ApplicationName || (a.ApplicationName === b.ApplicationName && a.EntityName >= b.EntityName) ? 1 : -1) : -1)
                    that.setState({isloading : false});

                })
                .catch(function (error) {
                    IllerisNinthUI.NotificationManager.showError('Error loading role privileges', error.message);
                    that.setState({errorMessage : error.message, isloading: false});
                })
                .then(function () {
                });

            })
            .catch(function(err: any){
                IllerisNinthUI.NotificationManager.showError('Error loading role privileges', err.message);
                that.setState({errorMessage : err.message, isloading: false});
            })
    }

    private loadCustomRolePrivileges = () : Promise<void> =>{
        var that = this;
        if (!this.appId){
            return Promise.resolve();
        }
        //var uri : string = "/odata/Runtime/EntityDefinition?$filter=ApplicationId eq " + this.appId + "&$orderby=DisplayName"
        var uri : string = `/odata/${that.props.PageInfo.TenantId}/Runtime/EntityPrivilegeDefinition?$filter=IsDefault eq false&$orderby=ApplicationName,EntityName,EventName&$expand=Application `;
        var epm : EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantUniqueName);
        that.setState({isloading : true});
        that.forceUpdate();
        that.CustomPrivileges = new Array<EntityCustomPrivilegeDefinition>();

        if (!that.props.roleid || that.props.roleid === Guid.empty()){
            return Promise.resolve();
        }
        return epm.executeAPIAny(uri, null, 'GET',false)
            .then(function (response: any){
                response.values.forEach(function (item: any, index : number) {
                    var cp : EntityCustomPrivilegeDefinition = new EntityCustomPrivilegeDefinition();
                    cp.PrivilegeId = item.Id;
                    cp.ApplicationName = item.Application?.DisplayName ?? item.ApplicationName;
                    cp.ApplicationId = item.ApplicationId;
                    cp.EntityName = item.EntityName;
                    cp.EventName = item.EventName;
                    cp.Level = item.Level ?? 0;
                    that.CustomPrivileges.push(cp);
                });

                return epm.executeAPIAny(`/odata/${that.props.PageInfo.TenantId}/Runtime/SecurityRolePrivilegeDefinition?$filter=RoleId eq ` + that.props.roleid + "&$expand=Privilege", null, 'GET', false)
                .then(function (response : any) {
                    if (response && response.values) {
                        for (var x = 0; x < response.values.length; x++) {
                            var it = response.values[x];
                            if (it.Privilege.IsDefault === false) {
                                for (var y = 0; y < that.CustomPrivileges.length; y++) {
                                    if (that.CustomPrivileges[y].EntityName === it.Privilege.EntityName && that.CustomPrivileges[y].PrivilegeId === it.Privilege.Id) {
                                        that.CustomPrivileges[y].Level = isNaN(parseInt(it.Level)) ? 0 : parseInt(it.Level);
                                    }
                                }
                            }
                        }
                        that.setState({isloading : false});
                        that.forceUpdate();
                    }
                })
                .catch(function (error) {
                    IllerisNinthUI.NotificationManager.showError('Error loading role custom privileges', error.message);
                    that.setState({errorMessage : error.message, isloading: false});
                })
                .then(function () {
                });

            })
            .catch(function(err: any){
                IllerisNinthUI.NotificationManager.showError('Error loading role custom privileges', err.message);
                that.setState({errorMessage : err.message, isloading: false});
            })
    }

    private onClickEntityName = (e : any) : void =>{
        var targ: any = e.currentTarget;
        if (!this.props.isreadonly && targ && targ.dataset && targ.dataset.entityname && targ.dataset.entityid){
            var en: string = targ.dataset.entityname;
            var enid: string = targ.dataset.entityid;
            var ep : EntityPrivilegeDefinition |null | undefined = this.Privileges.find(z => z.EntityName === en);
            if (e.type === 'click') {
                e.preventDefault();
                if (ep){
                    ep.levelNext(this.props.issystemadmin);
                    this.updatePrivileges(ep, en, enid);
                    this.forceUpdate();
                }
              } else if (e.type === 'contextmenu') {
                e.preventDefault();
                if (ep){
                    ep.levelPrevious(this.props.issystemadmin);
                    this.updatePrivileges(ep, en, enid);
                    this.forceUpdate();
                }
              }
        }
    }
    private updatePrivileges = (ep : EntityPrivilegeDefinition, entityName: string, entityId : string) : void =>{
        for(var i = 0; i< 10; i++){
            PrivilegeHelper.setRolePrivilegeLevel(ep, i,ep.getPrivilegeName(i),entityName, entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
        }
        //
    }

    public render(){
        //var that = this;
        //var idStr : string = 'tnOwerEditRow';
        var groupedList : any = {};
        var itemList : Array<string> = new Array<string>();
        if (this.props.privilegesType === 'default'){
            for(var z = 0; z<this.Privileges.length; z++){
                var ep : EntityPrivilegeDefinition = this.Privileges[z];
                if (!groupedList[ep.ApplicationName]){
                    groupedList[ep.ApplicationName] = new Array<EntityPrivilegeDefinition>();
                    itemList.push(ep.ApplicationName);
                }
                if (!this.state.searchValue){
                    groupedList[ep.ApplicationName].push(ep);
                }
                else if (ep.EntityName?.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1){
                    groupedList[ep.ApplicationName].push(ep);
                }
            }
        }
        else {
            for(var tz = 0; tz<this.CustomPrivileges.length; tz++){
                var ecp : EntityCustomPrivilegeDefinition = this.CustomPrivileges[tz];
                if (!groupedList[ecp.ApplicationName]){
                    groupedList[ecp.ApplicationName] = new Array<EntityCustomPrivilegeDefinition>();
                    itemList.push(ecp.ApplicationName);
                }
                //groupedList[ecp.ApplicationName].push(ecp);
                if (!this.state.searchValue){
                    groupedList[ecp.ApplicationName].push(ecp);
                }
                else if (ecp.EntityName?.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1){
                    groupedList[ecp.ApplicationName].push(ecp);
                }
            }
        }

        

        return (
            <Stack verticalFill horizontalAlign='stretch'>
            {this.state.isloading &&
                <ProgressIndicator label="Loading..." description="" />
            }
            <SearchBox placeholder="Search" onSearch={newValue => this.setState({searchValue : newValue?.toString() ?? ''}) } />
            {this.props.privilegesType === 'default' &&
            
            <div className="ms-Grid" dir="ltr" style={{marginTop: '15px'}} id={this.props.id} >
                <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm2">Entity Name</div>
                            <div className="ms-Grid-col ms-sm1">Read</div>
                            <div className="ms-Grid-col ms-sm1">Create</div>
                            <div className="ms-Grid-col ms-sm1">Update</div>
                            <div className="ms-Grid-col ms-sm1">Delete</div>
                            <div className="ms-Grid-col ms-sm1">Assign</div>
                            <div className="ms-Grid-col ms-sm1">Share</div>
                            <div className="ms-Grid-col ms-sm1">Export</div>
                            <div className="ms-Grid-col ms-sm1">Import</div>
                            <div className="ms-Grid-col ms-sm1">Activate</div>
                            <div className="ms-Grid-col ms-sm1">Deactivate</div>
                            </div>

                {
                itemList.map((item: string, index : number) => {
                    return (
                        <>
                            <div className="ms-Grid-row" key={nanoid()}>
                                <div className="ms-Grid-col ms-sm12" style={{textAlign: 'center', borderTop: '1px solid black', borderBottom: '1px solid black'}}>{item}</div>
                            </div>
                            
                            {
                                groupedList[item].map((groupitem: EntityPrivilegeDefinition, idx: number) => {
                                    return (<div className="ms-Grid-row" key={nanoid()}>
                                        <div className="ms-Grid-col ms-sm2" style={{cursor: 'pointer'}} data-entityname={groupitem.EntityName} data-entityid={groupitem.Id} onClick={this.onClickEntityName}>{groupitem.EntityName}</div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={0}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={1}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={2}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName}
                                                privtype={'default'}
                                                entityId={groupitem.Id}  
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={3}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={4}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={5}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={6}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={7}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName}
                                                privtype={'default'} 
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={8}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={9}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                    </div>)
                                })
                            }
                        </>
                    )
                })

                }
                {/* <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">A</div>
                    <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">B</div>
                </div> */}
            </div>
            }
            {this.props.privilegesType === 'custom' &&
                <div className="ms-Grid" dir="ltr" style={{marginTop: '15px'}}>
                    <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4">Entity Name</div>
                            <div className="ms-Grid-col ms-sm4">Privilege Name</div>
                            <div className="ms-Grid-col ms-sm4">Privilege Level</div>
                    </div>
                    {
                       itemList.map((item: string, index : number) => {
                        return (
                            <>
                                <div className="ms-Grid-row" key={nanoid()}>
                                    <div className="ms-Grid-col ms-sm12" style={{textAlign: 'center', borderTop: '1px solid black', borderBottom: '1px solid black'}}>{item}</div>
                                </div>
                                {
                                    groupedList[item].map((groupitem: EntityCustomPrivilegeDefinition, idx: number) => {
                                        return (<div className="ms-Grid-row" key={nanoid()}>
                                        <div className="ms-Grid-col ms-sm4" style={{cursor: 'pointer'}} data-entityname={groupitem.EntityName} data-entityid={groupitem.PrivilegeId} onClick={this.onClickEntityName}>{groupitem.EntityName}</div>
                                        <div className="ms-Grid-col ms-sm4">
                                        {groupitem.EventName}
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'custom'}
                                                entityId={groupitem.PrivilegeId} 
                                                applicationid={this.appId} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                customPriv={groupitem}
                                                itemIndex={0}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        </div>)
                                    })
                                }
                            </>

                        );
                       })
                    }
                </div>
            }
            </Stack>
        );
    }
}



export interface TNRolePrivilegeItemEditProps {
    roleid : string;
    rolename: string;
    entityName : string;
    entityId : string;
    applicationid : string;
    tenantUniqueName : string;
    priv? : EntityPrivilegeDefinition;
    customPriv? : EntityCustomPrivilegeDefinition;
    privtype : 'default' | 'custom';
    itemIndex : number;
    isreadonly : boolean;
    issystemadmin : boolean;
}

export interface TNRolePrivilegeItemState  {
    errorMessage : string;
}


export class TNRolePrivilegeItemEditControl extends React.Component<TNRolePrivilegeItemEditProps, TNRolePrivilegeItemState> {
    
    constructor(props : TNRolePrivilegeItemEditProps){
        super(props);
        
      
        this.state = {
            errorMessage : ''
        }

    }
    public componentDidMount(){
        //var that = this;
    }

    private getPrivLevelName = (val : number) : string => {
        if (val === -10)
            return 'No Access';
        else if (val === 0)
            return 'Undefined';
        else if (val === 10)
            return 'Own';
        else if (val === 20)
            return 'Team';
        else if (val === 30)
            return 'Team & Descendants';
        else if (val === 40)
            return 'Organization';
        else if (val === 50)
            return 'Tenant';
        else if (val === 100)
            return 'ALL records';
        return "Invalid Value";
    }
    private getNextPrivilegeValue = (val : number) : number =>{
        if (val === -10){
            return 0;
        }
        else if (val === 0){
            return 10;
        }
        else if (val === 10){
            return 20;
        }
        else if (val === 20){
            return 30;
        }
        else if (val === 30){
            return 40;
        }
        else if (val === 40){
            return 50;
        }
        else if (val === 50){
            if (this.props.issystemadmin){
                return 100;
            }
            else{
                return -10;
            }
        }
        else if (val === 100){
            return -10;
        }
        else{
            return 0;
        }
    }
    private getPreviousPrivilegeValue = (val : number) : number =>{
        if (val === -10){
            if (this.props.issystemadmin){
                return 100;
            }
            else{
                return 50;
            }
        }
        else if (val === 0){
            return -10;
        }
        else if (val === 10){
            return 0;
        }
        else if (val === 20){
            return 10;
        }
        else if (val === 30){
            return 20;
        }
        else if (val === 40){
            return 30;
        }
        else if (val === 50){
            return 40;
        }
        else if (val === 100){
            return 50;
        }
        else{
            return 0;
        }
    }

    public render(){
        //var that = this;
        //var idStr : string = 'tnOwerEditRow';

        return (
            <>
            {this.props.privtype === 'default' && this.props.priv &&
                <span onClick={this.onSpanClicked} onContextMenu={this.onSpanClicked}>
                    {/* {this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))} */}
                    <span dangerouslySetInnerHTML={{__html: this.renderCellValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex))}}></span>
                </span>
            }
            {this.props.privtype === 'custom' && this.props.customPriv &&
                <span onClick={this.onSpanClicked} onContextMenu={this.onSpanClicked}>
                    {/* {this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))} */}
                    <span dangerouslySetInnerHTML={{__html: this.renderCellValue(this.props.customPriv?.Level)}}></span>
                </span>
            }
            </>
        );
    }

    private onSpanClicked = (e : any) : void =>{
        if (!this.props.isreadonly){
            if (e.type === 'click') {
                e.preventDefault();
                if (this.props.priv && this.props.privtype === 'default'){
                    var newVal : number = this.getNextPrivilegeValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex));
                    this.props.priv.setPrivilegeIndex(this.props.itemIndex, newVal);

                    PrivilegeHelper.setRolePrivilegeLevel(this.props.priv, this.props.itemIndex,this.props.priv.getPrivilegeName(this.props.itemIndex),this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                else if (this.props.customPriv && this.props.privtype === 'custom'){
                    this.props.customPriv.Level = this.getNextPrivilegeValue(this.props.customPriv?.Level);

                    PrivilegeHelper.setRoleCustomPrivilegeLevel(this.props.customPriv, this.props.customPriv.EventName,this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                this.forceUpdate();
              } else if (e.type === 'contextmenu') {
                e.preventDefault();
                if (this.props.priv && this.props.privtype === 'default'){
                    var newVal2 : number = this.getPreviousPrivilegeValue(this.props.priv.getPrivilegeIndex(this.props.itemIndex));
                    this.props.priv.setPrivilegeIndex(this.props.itemIndex, newVal2);

                    PrivilegeHelper.setRolePrivilegeLevel(this.props.priv, this.props.itemIndex,this.props.priv.getPrivilegeName(this.props.itemIndex),this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                else if (this.props.customPriv && this.props.privtype === 'custom'){
                    this.props.customPriv.Level = this.getPreviousPrivilegeValue(this.props.customPriv?.Level);
                    PrivilegeHelper.setRoleCustomPrivilegeLevel(this.props.customPriv, this.props.customPriv.EventName,this.props.entityName,this.props.entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
                }
                this.forceUpdate();
              }
            
        }
    }

    private getPrivLevelNameText = () : string =>{
        if (this.props.priv){
            return this.getPrivLevelName(this.props.priv.getPrivilegeIndex(this.props.itemIndex))
        }
        else if (this.props.customPriv){
            return this.props.customPriv.EventName;
        }
        else{
            return 'Unknown';
        }
    }
    private renderCellValue = (val: number) : string =>{
        if (val === -10){
            return '<span style="color:red !important; cursor: pointer"><i class="ms-Icon ms-Icon--SkypeCircleMinus" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 0){
            return '<i class="ms-Icon ms-Icon--CircleRing" style="cursor:pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 10){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--UserOptional" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 20){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--ReminderGroup" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 30){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Org" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 40){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Group" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 50){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Database" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else if (val === 100){
            return '<span style="color:green !important; cursor: pointer"><i class="ms-Icon ms-Icon--Admin" style="color:green !important; cursor: pointer" title="' + this.getPrivLevelNameText() + '" ></i></span>'
        }
        else{
            return "??";
        }
    }
}


