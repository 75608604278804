//Navigation/AppDataV2

import { Label, MessageBar, MessageBarType, ProgressIndicator, Stack } from "@fluentui/react";
import React from "react";

  

  interface DocumentTemplateDesignerProps {
  }
  
  interface DocumentTemplateDesignerState {
    errorMessage? : string;
    isloading : boolean;
    notificationMessage? : string;
  }
  
  export class DocumentTemplateDesignerControl extends React.Component<DocumentTemplateDesignerProps, DocumentTemplateDesignerState> {
    
    constructor(props: DocumentTemplateDesignerProps) {
      super(props);
      this.state = {
        errorMessage : '',
        notificationMessage: '',
        isloading : false,
      }
    }
  
    componentDidMount() {
      var that = this;
      
    }

    render() {
      var that = this;
  
      return (
          
          <Stack horizontalAlign="stretch" verticalAlign="stretch" horizontal className="" wrap={true}>
            {this.state.errorMessage &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => {that.setState({errorMessage: ''});}}
                    dismissButtonAriaLabel="Close"
                    >
                    {that.state.errorMessage}
                </MessageBar>
            }
            {this.state.notificationMessage &&
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => {that.setState({notificationMessage: ''});}}
                    dismissButtonAriaLabel="Close"
                    >
                    {that.state.notificationMessage}
                </MessageBar>
            }
            {this.state.isloading &&
                <ProgressIndicator label="Loading..." description="" />
            }
            <Stack horizontal horizontalAlign="stretch">
                <Stack verticalFill horizontalAlign="stretch" style={{width: '60%'}}>

                </Stack>
                <Stack verticalFill horizontalAlign="stretch" style={{width: '40%'}}>

                </Stack>
            </Stack>
          </Stack>
        
      );
    }
  }
  
