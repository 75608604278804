import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { SecurityRoleDefinition } from "../../Entities/SecrityRole";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";

interface SecurityRoleDefinitionSurfaceEditorProps {
    role: SecurityRoleDefinition;
    app: SystemApplicationInfo;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface SecurityRoleDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  role:SecurityRoleDefinition;
  app: SystemApplicationInfo;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class SecurityRoleDefinitionSurfaceEditorControl extends React.Component<SecurityRoleDefinitionSurfaceEditorProps,SecurityRoleDefinitionSurfaceEditorState> {
    constructor(props:SecurityRoleDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        role: props.role,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >{this.state.role.Id ? 'Edit Role' : 'Create New Role'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <TextField label='Role Name' value={this.state.role.DisplayName} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(this.state.role.Id))} onChange={that.onEntityNameChanged} ></TextField>
            
            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={false} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.role && !this.state.app.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      /*if (this.state.ent && this.state.app && this.state.ent.LogicalName){
        var len : number = this.state.app.en
        this.setState({errorMessage : 'The Tile Name class is required'});
        return;
      }*/
      this.props.closeAndSave();
    }

    private onEntityNameChanged = (evt: any, newValue? : string) =>{
        var it :SecurityRoleDefinition = this.state.role;
        it.DisplayName = StringHelper.displayNameOnly(newValue ?? '');
        this.setState({role : it});
        this.forceUpdate();
    }
    
  }
  