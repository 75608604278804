import { ContextualMenu, DefaultButton, DetailsList, DetailsListLayoutMode, FontWeights, getTheme, IColumn, IComboBox, IComboBoxOption, IconButton, IDragOptions, IDropdownOption, IIconProps, mergeStyleSets, MessageBar, MessageBarType, Modal, Panel, PanelType, ProgressIndicator, SearchBox, SelectionMode, Stack, VirtualizedComboBox } from "@fluentui/react";
import React from "react";
import { DynamicEntity } from "../DynamicEntity";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { IllerisNinthAPI } from "../ServiceResult";

export interface TNAdvancedLookupProps {
    Entity : DynamicEntity | null | undefined;
    hsbindPropertyName : string;
    isReadOnly: boolean;
    labelText : string;
    //odataFilter : string;
    targetEntity : string;
    textProperty: string;
    valueProperty: string;
    searchHeaderText :string;
    searchColumns : string;
    displayColumns : string;
    displayColumnNames : string;
    usePanel? : boolean;
    valueFieldDataType : string;
}

export interface TNAdvancedLookupState  {
    searchPanelVisible : boolean;
    searchResult : Array<any>;
    searchColumns : Array<IColumn>;
    usePanel : boolean;
    isLoading : boolean;
    errorMessage : string;
    selItemValue : string;
    selItemTxt : string;
    searchText : string;
    dropDownItems : IDropdownOption[];
}

export class TNAdvandedLookup extends React.Component<TNAdvancedLookupProps, TNAdvancedLookupState> {
    private mItemList : Array<any> = new Array<any>();
    //private mDropDownItems : Array<IDropdownOption> = new Array<IDropdownOption>();
    private mbIsLoading : boolean = false;
    constructor(props : TNAdvancedLookupProps){
        super(props);
        this.state = {
            searchPanelVisible : false,
            searchResult: new Array<any>(),
            searchColumns : new Array<IColumn>(),
            usePanel : this.props.usePanel ? true : false,
            isLoading : false,
            errorMessage: '',
            selItemValue : props.Entity?.getValue(props.hsbindPropertyName),
            selItemTxt :'',
            searchText: '',
            dropDownItems: new Array<IDropdownOption>(),
        }

        this._onItemInvoked.bind(this);
    }

    private bindCombo = () : void =>{
        var that = this;
        if (!this.state.selItemValue){
            let newVal : string | null |undefined  = that.props.Entity?.getValue(that.props.hsbindPropertyName);
            if (!newVal){
                return;
            }
        }
        let val: String = this.state.selItemValue ?? that.props.Entity?.getValue(that.props.hsbindPropertyName);
        this.mbIsLoading = true;
        var emp : EntityPersistanceManager = new EntityPersistanceManager();
        //var cs : ClientState = ClientState.CurrentState();
        let fltExp : string = '';
        if (val){
            if (this.props.valueFieldDataType === 'guid'){
                //fltExp = '?$filter=' + this.props.valueProperty + ' eq ' + val;
                fltExp = '?$filter=' + this.props.valueProperty + '.Equals(Guid("' + val + '"))';
            }
            else if (this.props.valueFieldDataType === 'string'){
                fltExp = '?$filter=' + this.props.valueProperty + ' eq \'' + val + '\'';
            }
        }
        
        emp.getMany(this.props.targetEntity,fltExp).then(function (res : any) {
          var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
          if (sr && sr.Values){
              that.mItemList = new Array<any>();
              let items: Array<IDropdownOption> = new Array<IDropdownOption>();
              //that.mDropDownItems = new Array<IDropdownOption>();
              for(var x = 0; x<sr.Values.length; x++){
                  that.mItemList.push(sr.Values[x]);
                  let key : string | undefined = sr.Values[x][that.props.valueProperty];
                  if (typeof key !== 'undefined'){
                    items.push({key : key, text :sr.Values[x][that.props.textProperty]});
                  }
              }
              items = items.sort((a, b) => (a.text < b.text ? -1 : 1));
              that.mbIsLoading = false;
              that.setState({dropDownItems: items});
              that.forceUpdate();
          }
          else if (res instanceof Error){
              console.log('Error in advancedLookup:' + res?.message)
          }
        })
        .catch(function(error: any){
            that.mbIsLoading = false;
          console.log('Error in advancedLookup:' + error?.message)
        })
      
        
    }
    public componentDidMount(){
        if (this.props.targetEntity){
            this.bindCombo();
        }
        this.buildSearchColumns();
    }

    private updateTargetProperty  = (propVal : any, propText : any ) : void =>{
        debugger;
        if (!propVal || !propText){
            return;
        }
        if (this.props.Entity){
            this.props.Entity.setValue(this.props.hsbindPropertyName, propVal);
        }
        var xe : IDropdownOption | null | undefined = this.state.dropDownItems.find(z => z.key === propVal);
        if (!xe){
            let items: IDropdownOption[] = new Array<IDropdownOption>();
            items.push({key: propVal, text: propText});
            this.setState({dropDownItems: items});
            this.forceUpdate();
        }
        else{
            this.forceUpdate();
        }
        
    }

    

    private buildSearchColumns = () : void =>{
        var colNames : string[] = this.props.displayColumns.split(';');
        var disColNames : string[] = this.props.displayColumnNames.split(';');
        var sc : Array<IColumn> = new Array<IColumn>();
        for(var i= 0; i<colNames.length; i++){
            sc.push({
                key: colNames[i],
                name: disColNames[i],
                fieldName: colNames[i],
                minWidth: 70,
                //maxWidth: 90,
                isResizable: true,
                //onColumnClick: this._onColumnClick,
                //data: 'number',
                // onRender: (item: IDocument) => {
                //   return <span>{item.dateModified}</span>;
                // },
                //isPadded: true,
              },)
        }
        this.setState({searchColumns : sc});
    }

    public shouldComponentUpdate(nextProps: TNAdvancedLookupProps, nextState: TNAdvancedLookupState){
       if (nextProps.Entity !== this.props.Entity || 
            this.props.Entity?.getValue(this.props.hsbindPropertyName) !== nextProps.Entity?.getValue(nextProps.hsbindPropertyName) ||
            nextState.searchResult !== this.state.searchResult || nextState.isLoading !== this.state.isLoading  || nextState.errorMessage !== this.state.errorMessage ){
           return true;
       }
       else{
           return false;
       }
    
    }
    public componentDidUpdate(prevProps : TNAdvancedLookupProps, prevState: TNAdvancedLookupState){
        if (prevProps.Entity !== this.props.Entity || 
            this.props.Entity?.getValue(this.props.hsbindPropertyName) !== prevProps.Entity?.getValue(prevProps.hsbindPropertyName) ||
            prevState.searchResult !== this.state.searchResult   || prevState.isLoading !== this.state.isLoading || prevState.errorMessage !== this.state.errorMessage){
                if (!this.mbIsLoading){
                    this.bindCombo();
                }
        }
        return false;
    }

    private toggleSearchButtonClick = () : void =>{
        this.setState({searchPanelVisible : true, searchResult : new Array<any>()});
        this.forceUpdate();
    }
    private dismissSearchPanelClick = () : void =>{
        this.setState({searchPanelVisible : false});
        this.forceUpdate();
    }
    private searchClicked = (newValue? : any) : void =>{
        debugger;
        var that = this;
        debugger;
        this.setState({isLoading : true, errorMessage: '', searchText : newValue});
        this.forceUpdate();
        if (true){
            var searchStr = newValue?.toString() ?? '';

            var sCols : string[] = this.props.searchColumns.split(';');
            var sUri : string = '?$filter=';
            for(var i = 0; i<sCols.length; i++){
                sUri = sUri + sCols[i] + '.contains("' + searchStr + '")'; 
                if (i< sCols.length-1){
                    sUri += ' or ';
                }
            }

            var esm : EntityPersistanceManager = new EntityPersistanceManager();
            esm.getMany(this.props.targetEntity, newValue ? sUri : '').then(function(res: IllerisNinthAPI.ServiceResult | Error){
                that.setState({isLoading : false});
                var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                if (sr && sr.Values){
                    that.setState({searchResult : sr.Values});
                    that.forceUpdate();
                }
            }).catch(function (error : any){
                that.setState({isLoading : false, errorMessage : error.message});

            })
        }
    }

    private _onItemInvoked = (item: any): void =>{
        debugger;
        //alert(`Item invoked: ${item.name}`);
        if (item && item.Id){
            this.updateTargetProperty(item.Id, item[this.props.textProperty]);
            this.setState({searchPanelVisible : false, selItemValue: item.Id});
            this.forceUpdate();
        }
    }

    private onItemChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        debugger;
        if (option){
            this.updateTargetProperty(option?.key, option?.text);
        }
    }
    closeMessageBar = () => {
        this.setState({ errorMessage: '' });
      };
    public render(){
        var that = this;
        
        //let selTxt : string = '';
        var selpropId : string | undefined = that.props.Entity?.getValue(that.props.hsbindPropertyName);
        if (selpropId){
            var selIt : IDropdownOption | undefined = this.state.dropDownItems.find(z => z.key === selpropId);
            if (selIt){
                //selTxt = selIt.text;
            }
        }

        return (
            <Stack horizontal horizontalAlign='stretch' className='advanced-lookup-container'>
            <Stack horizontal horizontalAlign='stretch' className='advanced-lookup-container'>
                <Stack.Item align='end' className='advanced-lookup-container' >
                <VirtualizedComboBox
                    className='advanced-lookup-combobox'
                    label={this.props.labelText}
                    allowFreeform={false}
                    autoComplete="on"
                    options={this.state.dropDownItems}
                    dropdownMaxWidth={200}
                    useComboBoxAsMenuWidth
                    selectedKey={this.props.Entity?.getValue(this.props.hsbindPropertyName)}
                    onChange={this.onItemChanged}
                    ></VirtualizedComboBox>
                </Stack.Item>
                <Stack.Item align='end'>
                            <DefaultButton className='advanced-lookup-search-button'
                                    iconProps={{iconName: 'Search'}}
                                    onClick={this.toggleSearchButtonClick}
                                />
                </Stack.Item>
            </Stack>
            {this.state.usePanel &&
                <Panel
                    headerText={this.props.searchHeaderText}
                    isOpen={this.state.searchPanelVisible}
                    onDismiss={this.dismissSearchPanelClick}
                    type={PanelType.medium}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close Search Panel"
                >
                    <Stack verticalFill horizontalAlign='stretch'>
                        <p>Please provide search criteria</p>
                        <SearchBox placeholder="Search"  onSearch={(newVal?: string) => {this.searchClicked(newVal);}} onClear={(ev?: any) => {that.setState({searchText: ''}); that.forceUpdate();}} />
                        {this.state.isLoading &&
                            <ProgressIndicator label="Loading" description=""  />
                        }
                        {this.state.errorMessage && 
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={() => this.closeMessageBar()}
                                dismissButtonAriaLabel="Close"
                            >
                                {this.state.errorMessage}
                            </MessageBar>
                        }
                        <DetailsList
                            items={this.state.searchResult}
                            compact={true}
                            columns={this.state.searchColumns}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onItemInvoked={this._onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />

                    </Stack>
                </Panel>
            }
            {!this.state.usePanel &&
                <Modal
                    titleAriaId={this.props.searchHeaderText}
                    isOpen={this.state.searchPanelVisible}
                    onDismiss={this.dismissSearchPanelClick}
                    isBlocking={true}
                    containerClassName={contentStyles.container}
                    dragOptions={dragOptions}
                    
                >
                    <div className={contentStyles.header}>
                            <span id={this.props.searchHeaderText}></span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={this.dismissSearchPanelClick}
                            />
                    </div>
                    <div className={contentStyles.body}>
                    <Stack verticalFill horizontalAlign='stretch' styles={{root: {width: '800px', height: '600px'}}}>
                        <p>Please provide search criteria</p>
                        <SearchBox placeholder="Search" onSearch={(newValue?: string) => {that.searchClicked(newValue);} } />
                        {this.state.isLoading &&
                            <ProgressIndicator label="Loading" description=""  />
                        }
                        {this.state.errorMessage && 
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={() => this.closeMessageBar()}
                                dismissButtonAriaLabel="Close"
                            >
                                {this.state.errorMessage}
                            </MessageBar>
                        }
                        <DetailsList
                            items={this.state.searchResult}
                            compact={true}
                            columns={this.state.searchColumns}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            onItemInvoked={this._onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />

                    </Stack>
                    </div>

                </Modal>
            }
            </Stack>
        );
    }
}
const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      //// eslint-disable-next-line deprecation/deprecation
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

//const toggleStyles = { root: { marginBottom: '20px' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};