//<i class="fab fa-500px"></i>

import React, { CSSProperties } from "react";


interface FAIconProps {
    iconClassName? : string;
    iconColorScheme? : string;
    iconSize? : 'normal' | 'lg' | 'xl' | 'xxl'| 'xxxl';
}

interface FAIconState {}

export class FAIcon extends React.Component<
  FAIconProps,
  FAIconState
> {

  render() {
    var className : string = '';
    if (this.props.iconClassName){
      className = className + this.props.iconClassName!.replace('fal', 'fas') + ' ';
    }
    if (this.props.iconColorScheme){
      className = className + this.props.iconColorScheme!.replace('fal', 'fas') + ' ';
    }
    if (this.props.iconSize === 'normal'){
      className = className + 'icon-size-normal ';
    }
    if (this.props.iconSize === 'lg'){
      className = className + 'icon-size-lg ';
    }
    if (this.props.iconSize === 'xl'){
      className = className + 'icon-size-xl ';
    }
    if (this.props.iconSize === 'xxl'){
      className = className + 'icon-size-xxl ';
    }
    if (this.props.iconSize === 'xxxl'){
      className = className + 'icon-size-xxxl ';
    }
    if (!className)
      className = 'fas question';
    
      var fontStyle : CSSProperties = {
        color: this.props.iconColorScheme ? this.props.iconColorScheme : 'inherit'
      }
    
    return (
      <i className={className} style={fontStyle}> </i>
    );
  }

}