export class EntityPropertyDataType {
    public Id: string = '';
    public DataTypeId: number = 0;
    public Name: string = '';

    static AllDataTypes = () : EntityPropertyDataType[] => {
        let res: Array<EntityPropertyDataType> = new Array<EntityPropertyDataType>();
        res.push({ Id : "{26267A0B-1A57-41D7-A088-7C04064BD888}", DataTypeId : 0, Name : "String" });
        res.push({ Id : "{D04671CD-CBD7-49C0-BC9E-408161883A1E}", DataTypeId : 1, Name : "Number" });
        res.push({ Id : "{DD5FF301-21C7-4DEF-A973-DBF074C268F5}", DataTypeId : 2, Name : "Double" });
        res.push({ Id : "{B6E8AFA0-1624-432F-B4F3-FA61BF214332}", DataTypeId : 3, Name : "DateTime" });
        res.push({ Id : "{CEBC9A7B-E45B-4F4E-B5E2-4C89C06AB66E}", DataTypeId : 4, Name : "Guid" });
        res.push({ Id : "{02467C24-85B6-42E8-81CF-55F30197920F}", DataTypeId : 5, Name : "Byte[]" });
        res.push({ Id : "{7754F6FD-30EA-4084-97EF-509F5AA5093A}", DataTypeId : 6, Name : "Text" });
        res.push({ Id : "{8E1EAC5B-3BBB-442A-8503-A2EB557450E3}", DataTypeId : 7, Name : "Choice" });
        res.push({ Id : "{0E6AD8E4-0B0B-4430-A358-ECC006D8883C}", DataTypeId : 8, Name : "Relation" });
        res.push({ Id : "{3A1C9CA1-4CF5-4CD6-98ED-0396CF796552}", DataTypeId : 9, Name : "Image" });
        res.push({ Id : "{DA48A343-859F-40EE-A5F3-74B5938ED102}", DataTypeId : 10, Name : "Boolean" });
        res.push({ Id : "{7E23CFA7-9CEA-4A9C-BB2D-0D9FE22E3F37}", DataTypeId : 100, Name : "TimeStamp" });
        return res;
    }
    static Default = () : EntityPropertyDataType  =>{
        return { Id : "{26267A0B-1A57-41D7-A088-7C04064BD888}", DataTypeId : 0, Name : "String" };
    }

    static numberToDataType = (dtNumVal: number) : EntityPropertyDataType =>{
        let allTypes = this.AllDataTypes();
        let tp: EntityPropertyDataType | undefined = allTypes.find(z => z.DataTypeId === dtNumVal);
        if (tp){
            return tp;
        }
        else{
            return allTypes.find(z => z.Name === 'String')!;
        }
    }
    static isValidDataType = (str: string) : boolean =>{
        let allTypes = this.AllDataTypes();
        let tp: EntityPropertyDataType | undefined = allTypes.find(z => z.Name === str);
        if (tp){
            return true;
        }
        else{
            return false;
        }
    }   
}