export class EntityPrivilegeDefinition{
    public Id : string = '';
    public ApplicationName : string = '';
    public ApplicationId : string = '';
    public EntityName : string = '';
    public ReadLevel : number = 0; 
    public CreateLevel : number = 0; 
    public UpdateLevel : number = 0; 
    public DeleteLevel : number = 0; 
    public AssignLevel : number = 0; 
    public ShareLevel : number = 0; 
    public ExportLevel : number = 0; 
    public ImportLevel : number = 0; 
    public ActivateLevel : number = 0; 
    public DeactivateLevel : number = 0;

    
    constructor(entId : string, entName : string, appName : string, appId : string){
        this.Id = entId;
        this.EntityName = entName;
        this.ApplicationName = appName;
        this.ApplicationId = appId;
    }

    public getPrivilegeIndex = (idx : number) : number => {
        if (idx === 0){
            return this.ReadLevel;
        }
        else if (idx === 1){
            return this.CreateLevel;
        }
        else if (idx === 2){
            return this.UpdateLevel;
        }
        else if (idx === 3){
            return this.DeleteLevel;
        }
        else if (idx === 4){
            return this.AssignLevel;
        }
        else if (idx === 5){
            return this.ShareLevel;
        }
        else if (idx === 6){
            return this.ExportLevel;
        }
        else if (idx === 7){
            return this.ImportLevel;
        }
        else if (idx === 8){
            return this.ActivateLevel;
        }
        else if (idx === 9){
            return this.DeactivateLevel;
        }
        else{
            return 0;
        }
    }
    public getPrivilegeName = (idx : number) : string => {
        if (idx === 0){
            return 'Read';
        }
        else if (idx === 1){
            return 'Create';
        }
        else if (idx === 2){
            return 'Update';
        }
        else if (idx === 3){
            return 'Delete';
        }
        else if (idx === 4){
            return 'Assign';
        }
        else if (idx === 5){
            return 'Share';
        }
        else if (idx === 6){
            return 'Export';
        }
        else if (idx === 7){
            return 'Import';
        }
        else if (idx === 8){
            return 'Activate';
        }
        else if (idx === 9){
            return 'Deactivate';
        }
        else{
            return 'Custom';
        }
    }
    public setPrivilegeIndex = (idx : number, val : number) : void => {
        if (idx === 0){
            this.ReadLevel = val;
        }
        else if (idx === 1){
            this.CreateLevel = val;
        }
        else if (idx === 2){
            this.UpdateLevel = val;
        }
        else if (idx === 3){
            this.DeleteLevel = val;
        }
        else if (idx === 4){
            this.AssignLevel = val;
        }
        else if (idx === 5){
            this.ShareLevel = val;
        }
        else if (idx === 6){
            this.ExportLevel = val;
        }
        else if (idx === 7){
            this.ImportLevel = val;
        }
        else if (idx === 8){
            this.ActivateLevel = val;
        }
        else if (idx === 9){
            this.DeactivateLevel = val;
        }
        
    }

    private getPrivLevelName = (val : number) : string => {
        if (val === -10)
            return 'No Access';
        else if (val === 0)
            return 'Undefined';
        else if (val === 10)
            return 'Own';
        else if (val === 20)
            return 'Team';
        else if (val === 30)
            return 'Team & Descendants';
        else if (val === 40)
            return 'Organization';
        else if (val === 50)
            return 'Tenant';
        else if (val === 100)
            return 'ALL records';
        return "Invalid Value";
    }
    private getNextPrivilegeValue = (val : number, bIsSysAdmin: boolean) : number =>{
        if (val === -10){
            return 0;
        }
        else if (val === 0){
            return 10;
        }
        else if (val === 10){
            return 20;
        }
        else if (val === 20){
            return 30;
        }
        else if (val === 30){
            return 40;
        }
        else if (val === 40){
            return 50;
        }
        else if (val === 50){
            if (bIsSysAdmin){
                return 100;
            }
            else{
                return -10;
            }
        }
        else if (val === 100){
            return -10;
        }
        else{
            return 0;
        }
    }
    private getPreviousPrivilegeValue = (val : number, bIsSysAdmin: boolean) : number =>{
        if (val === -10){
            if (bIsSysAdmin){
                return 100;
            }
            else{
                return 50;
            }
        }
        else if (val === 0){
            return -10;
        }
        else if (val === 10){
            return 0;
        }
        else if (val === 20){
            return 10;
        }
        else if (val === 30){
            return 20;
        }
        else if (val === 40){
            return 30;
        }
        else if (val === 50){
            return 40;
        }
        else if (val === 100){
            return 50;
        }
        else{
            return 0;
        }
    }

    public levelNext = (bIsAdmin : boolean) : void =>{
        let priv : number = this.getPrivilegeIndex(0);
        let privNext : number = this.getNextPrivilegeValue(priv, bIsAdmin);
        for(var i= 0; i<10; i++){
            this.setPrivilegeIndex(i, privNext);
        }
    }
    public levelPrevious = (bIsAdmin : boolean) : void =>{
        let priv : number = this.getPrivilegeIndex(0);
        let privNext : number = this.getPreviousPrivilegeValue(priv, bIsAdmin);
        for(var i= 0; i<10; i++){
            this.setPrivilegeIndex(i, privNext);
        }
    }
}