import { Logger } from "./Logger";
import { PageInfo } from "./PageInfo";

export class Controller{
    private static URIHistory : Array<any> = new Array<any>();

    public static RedirectToURI = (uriStr : string, pi: PageInfo) : void =>{
        if (uriStr){
            let str: string = '';
            if (pi && pi.TenantId){
                str = uriStr.replace('/view/', '/cview/' + pi.TenantId + '/');
            }
            else{
                str = uriStr.replace('/view/', '/cview/');
            }

            Controller.URIHistory.push(window.location.href);
            Logger.logNotification('Redirecting to ' + str);
            window.location.href = str;
        }
    }

    public static GoBack() : void {
        window.history.back();
    }

    public static showPopup(uri: string, width: number, height: number){
        window.open(uri, '_blanc', 'width=' + width.toString() + ', height=' + height.toString());
    }
}