import { DefaultButton, ILabelStyles, IStyleSet, Label, Pivot, PivotItem, Stack, TextField } from "@fluentui/react";
import React from "react";
import { ClientState } from "../ClientState";
import { ClientDataManager } from "../db/ClientDataManager";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { ViewManager } from "../ViewManager";

export interface TNUserProfileEditProps {
    tenantId: string;
    appName: string;
}

export interface TNUserProfileEditState  {
    isAuthenticated: boolean;
    currentTenantName: string;
    userName: string;
    userLoginName: string;
    userEmail: string;
}

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
  };
  
  export class TNUserProfileEditControl extends React.Component<TNUserProfileEditProps, TNUserProfileEditState> {
      constructor(props : TNUserProfileEditProps){
          super(props);
          

          this.state = {
              isAuthenticated: false,
              currentTenantName: '',
              userLoginName: '',
              userName: '',
              userEmail: '',
          }

      }
      public componentDidMount(){
          var that = this;
          let cs: ClientState = ClientState.CurrentState();
          if (cs.IsAuthenticated){
              this.setState({isAuthenticated: true, 
                    currentTenantName: cs.UserProfile?.TerraNovaTenantUniqueName ?? '', 
                    userName: cs.UserProfile?.DisplayName ?? '',
                    userLoginName: cs.UserProfile?.LoginName ?? '',
                    userEmail: cs.UserProfile?.EmailAddress ?? '',
                });
          }
      }

      public render(){
          var that = this;
          //var sizeStr = 'ms-Grid-col ms-sm3 ms-md3 ms-lg ms-xl3 ms-xxl3 ms-xxl3 tn-column';
          

          return (
              <Stack verticalFill horizontalAlign="stretch" style={{minWidth: '800px', width: '800px', height: '640px'}}>
                  <Pivot aria-label="Count and Icon Pivot Example">
                    <PivotItem headerText="My Profile" itemIcon="ProfileSearch">
                        <Stack verticalFill horizontalAlign="stretch">
                            <Stack horizontal horizontalAlign="stretch">
                                <Stack.Item style={{width: '33%'}}>
                                    <TextField label="User Name" readOnly={true} disabled={true} value={this.state.userName} ></TextField>
                                    <TextField label="User Login" readOnly={true} disabled={true} value={this.state.userLoginName} ></TextField>
                                    <TextField label="User Email" readOnly={true} disabled={true} value={this.state.userEmail} ></TextField>
                                    <TextField label="Current Tenant" readOnly={true} disabled={true} value={this.state.currentTenantName} ></TextField>
                                    
                                </Stack.Item>
                                <Stack.Item style={{width: '33%'}}>
                                    
                                </Stack.Item>
                                <Stack.Item style={{width: '33%'}}>
                                    
                                </Stack.Item>
                            </Stack>    
                        </Stack>    
                    </PivotItem>
                    <PivotItem headerText="Data Management" itemIcon="DatabaseSync">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row" style={{height: '35px'}}>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Local Cache</div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                        </div>
                        <div className="ms-Grid-row" style={{height: '35px'}}>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"><DefaultButton text="Clear View Cache" iconProps={{iconName: 'Database'}} onClick={(ev?: any) => {that.clearViewCache();}}></DefaultButton></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                        </div>
                        <div className="ms-Grid-row" style={{height: '35px'}}>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"><DefaultButton text="Clear Data Cache" iconProps={{iconName: 'EntryView'}} onClick={(ev?: any) => {that.clearLocalDB();}}></DefaultButton></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                        </div>
                        <div className="ms-Grid-row" style={{height: '35px'}}>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3"></div>
                        </div>
                    </div>
                    </PivotItem>
                    <PivotItem headerText="Placeholder" itemIcon="Globe">
                    
                    </PivotItem>
                    <PivotItem headerText="Shared with me" itemIcon="Ringer" itemCount={1}>
                    
                    </PivotItem>
                  
                </Pivot>
                  
              </Stack>
          );
      }

      public clearViewCache = () : void => {
        let vm : ViewManager = new ViewManager(this.props.appName, this.props.tenantId);
        vm.clear();
        IllerisNinthUI.NotificationManager.showSuccess('Success', 'Local view cache cleared.')
      }
      public clearLocalDB = () : void => {
        if (this.state.currentTenantName){
            let cdm : ClientDataManager = new ClientDataManager(this.state.currentTenantName);
            
            cdm.clearDb(this.state.currentTenantName).then(function(res: void){
                
            })
            .catch(function(err){
                IllerisNinthUI.NotificationManager.showSuccess('Failed', err?.message);
            })
            IllerisNinthUI.NotificationManager.showSuccess('Success', 'Local data cleared.')
        }
      }
  }