/* eslint-disable no-debugger */
/* eslint-disable quotes */
/* eslint-disable no-array-constructor */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-const */
/* eslint-disable key-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable eol-last */
/* eslint-disable no-var */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-trailing-spaces */
/* eslint-disable space-infix-ops */
/* eslint-disable space-before-blocks */
/* eslint-disable semi */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable brace-style */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-useless-constructor */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-spacing */
/* eslint-disable padded-blocks */
/* eslint-disable no-undef */

import { getAlpha2Codes, getName, getNames, langs, LocalizedLanguageNames, registerLocale } from "@cospired/i18n-iso-languages";
import { ComboBox, CommandBar, Dropdown, IComboBox, IComboBoxOption, ICommandBarItemProps, IDropdownOption, MessageBar, MessageBarType, optionProperties, Stack } from "@fluentui/react";
import { nanoid } from "nanoid";
import React from "react";

//import { languages } from "@cospired/i18n-iso-languages";
//import {lcid} from "lcid";
//const lcid = require('lcid');
//const all = require('./lcid.json');
import all from '../lcid.json'

export namespace Illeris{
    export namespace Ninth {
        export namespace UX {
            export interface LanguageEditControlprops { 
                isReadOnly: boolean;
                value: string;
                limitToLanguages?: string;
                onChange: (selKey: string) => void;
                currentLCID?: number;
                labelText: string;
            }

            export interface LanguageEditControlState {
                isReadOnly: boolean;
            
                cmdItems : ICommandBarItemProps[],
                cmdFarItems : ICommandBarItemProps[];
                errorMessage: string;
                busyMessage: string;
            
                options: IComboBoxOption[];
                value: string;

                currentLCID: number; // Defaults 1043
            }

            export class LanguageEditControl extends React.Component<LanguageEditControlprops, LanguageEditControlState> {

                private getLanguageFromLCID = (lcid: number) : string => {
                    return (all as any)[lcid.toString()];
                }
            
                constructor (props: LanguageEditControlprops) {
                    super(props);
                    debugger;
                    let limitLangs: string[] = this.props.limitToLanguages?.trim()?.split(';') ?? new Array<string>();
                    if (limitLangs && limitLangs.length === 1 && limitLangs[0] === ''){
                        limitLangs = new Array<string>();
                    }
                    let currLang: string = this.getLanguageFromLCID(props.currentLCID ?? 1043);
                    if (currLang){
                        let ls: string[] = langs();
                        if (currLang.indexOf('-') !== -1){
                            currLang = currLang.substring(0, currLang.indexOf('-'));
                        }
                        else if (currLang.indexOf('_') !== -1){
                            currLang = currLang.substring(0, currLang.indexOf('_'));
                        }
                        if (!ls.indexOf(currLang)){
                            currLang = 'en';
                        }
                    }
            
                    try {
                        registerLocale(require(`@cospired/i18n-iso-languages/langs/${currLang}.json`))
                    }
                    catch (er: any){
                        registerLocale(require(`@cospired/i18n-iso-languages/langs/en.json`))
                    }
            
                    let nms: LocalizedLanguageNames = getNames(currLang);
                    let opts: IDropdownOption[] = new Array<IComboBoxOption>();
                    //let codes : string[] = getAlpha2Codes() as string[];
                    let ac: any = getAlpha2Codes();
                    Object.keys(ac).forEach(function(it: string, idx: number){
                        if (limitLangs!== null && limitLangs.length !== 0){
                            if (limitLangs.includes(it)){
                                opts.push({key: it, text: getName(it, currLang)??it});
                            }
                        }
                        else {
                            opts.push({key: it, text: getName(it, currLang)??it});
                        }
                    })
                    opts = opts.sort((a, b) => a.text < b.text ? -1 : 1);
            
                    this.state = {
                        isReadOnly: props.isReadOnly,
                        cmdFarItems: new Array<ICommandBarItemProps>(),
                        cmdItems: new Array<ICommandBarItemProps>(),
                        errorMessage: '',
                        busyMessage: '',
                        options: opts,
                        value: props.value,
                        currentLCID: props.currentLCID ?? 1043
                    };
                    
                }
                shouldComponentUpdate(nextProps: LanguageEditControlprops, nextState: LanguageEditControlState) {
                    let bRes : boolean = false;
                    if (nextProps.value !== this.props.value || nextProps.isReadOnly !== this.props.isReadOnly || nextProps.limitToLanguages !== this.props.limitToLanguages){
                        bRes= true;
                    }
                    else {
                      bRes =false;
                    }
                    return bRes;
                }
                componentDidUpdate(prevProps: LanguageEditControlprops, prevState: LanguageEditControlState) {
                    if (prevProps.value !== this.props.value || prevProps.isReadOnly !== this.props.isReadOnly || prevProps.limitToLanguages !== this.props.limitToLanguages){
                        this.refresh();
                    }
                }
                
                private refresh = () : void => {
                    let that = this;
                    debugger;
                    let limitLangs: string[] = this.props.limitToLanguages?.trim()?.split(';') ?? new Array<string>();
                    if (limitLangs && limitLangs.length === 1 && limitLangs[0] === ''){
                        limitLangs = new Array<string>();
                    }
                    let currLang: string = this.getLanguageFromLCID(this.props.currentLCID ?? 1043);
                    if (currLang){
                        let ls: string[] = langs();
                        if (currLang.indexOf('-') !== -1){
                            currLang = currLang.substring(0, currLang.indexOf('-'));
                        }
                        else if (currLang.indexOf('_') !== -1){
                            currLang = currLang.substring(0, currLang.indexOf('_'));
                        }
                        if (ls.indexOf(currLang) === -1){
                            currLang = 'en';
                        }
                    }
            
                    try {
                        registerLocale(require(`@cospired/i18n-iso-languages/langs/${currLang}.json`))
                    }
                    catch (er: any){
                        registerLocale(require(`@cospired/i18n-iso-languages/langs/en.json`))
                    }
            
                    let nms: LocalizedLanguageNames = getNames(currLang);
                    let opts: IDropdownOption[] = new Array<IComboBoxOption>();
                    //let codes : string[] = getAlpha2Codes() as string[];
                    let ac: any = getAlpha2Codes();
                    Object.keys(ac).forEach(function(it: string, idx: number){
                        if (limitLangs!== null && limitLangs.length !== 0){
                            if (limitLangs.includes(it)){
                                opts.push({key: it, text: getName(it, currLang)??it});
                            }
                        }
                        else {
                            opts.push({key: it, text: getName(it, currLang)??it});
                        }
                    })
                    opts = opts.sort((a, b) => a.text < b.text ? -1 : 1);
            
                    that.setState({options: opts, value: this.props.value});
                    that.forceUpdate();
                }
            
                public componentDidMount () {
                    let that = this;
                    that.refresh();
                }
            
                private onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined): void => {
                    if (option){
                        this.props.onChange(option.key.toString());
                        this.setState({value: option.key.toString()})
                    }
                    else {
                        this.props.onChange('');
                        this.setState({value: ''})
                    }
                }
            
                private onKeyDown = (event: any) : void => {
                    debugger;
                }
            
                public render (): JSX.Element {
                    let that = this;
            
                    return (
                        <ComboBox
                            //multiSelect
                            selectedKey={that.state.value}
                            label={this.props.labelText}
                            allowFreeform={false}
                            options={this.state.options}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            autoComplete={'on'}
                        />
                    );
                }
            }
        }
    }
}






