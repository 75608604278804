export class EntityFormDefinition{
    public Id : string = '';
    public ApplicationId : string = '';
    public DisplayName : string = '';
    public ViewName : string = '';
    public ActionName : string = '';
    public ViewHtml : string = '';
    public ViewJS : string = '';
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;
    public IsActive : boolean = true;
    //Type of view: 0 = List, 1: Form, 3: Report, 4: Dashboard
    public ViewDataType: number = 0;
    public ViewData : string = '';
    public ViewN : string = '';
}

// New: 11.24.2021
export class EntityFormDefinition2{
    public Id : string = '';
    public ApplicationId : string = '';
    public ApplicationName : string = '';
    public DisplayName : string = '';
    public EntityId : string = '';
    public ViewName : string = '';
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;
    public IsActive : boolean = true;
    //Type of view: 0 = List, 1: Form, 3: Report, 4: Dashboard
    public ViewDataType: number = 0;
    public ViewDataTypeName: string = '';
    public ViewData : string = '';
    public ViewDataScript : string = '';
    public EntityName: string = '';
}