import { IContextualMenuItem, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React from "react";
import { AppTileGrid } from "./AppTileGrid";
import { ClientState } from "./ClientState";
import { EntityDefinition } from "./designers/Entities/EntityDefinition";
import { EntityModelHelper } from "./designers/EntityModelHelper";
//import { ClientState } from "./ClientState";
import { EntityView } from "./EntityView";
import { Logger } from "./Logger";
import { HeaderCommandBar } from "./MainNavBar";
import { NavigationInfo, ViewType } from "./NavigationInfo";
import { PageInfo } from "./PageInfo";
import { ScriptItem } from "./ScriptItem";
import { SideBar } from "./SideBar";
import { SessionStorageManager, SessionStorageManagerKeyManager } from "./StorageManager";
import { ViewManager, XView } from "./ViewManager";

interface ApplicationPageProps {
    applicationName : string;
    entityName? : string;
    actionName? : string;
    tenantUniqueName? : string;
    itemId? : string;
    PageInfo: PageInfo;
}

interface ApplicationPageState extends ApplicationPageProps {
  isSideNavExpanded : boolean;
  CurrentView? : XView | null;
  StateKey : number;
  errorMessage? : string;
}

export class ApplicationPage extends React.Component<ApplicationPageProps, ApplicationPageState> {
  private ViewManager : ViewManager;
  private CurrentView? : XView;
  constructor(props: ApplicationPageProps) {
    super(props);
    this.state = {
      isSideNavExpanded : true,
      applicationName: props.applicationName ?? '',
      tenantUniqueName : props.tenantUniqueName,
      actionName : props.actionName,
      entityName: props.entityName,
      itemId : props.itemId,
      CurrentView : null,
      StateKey : 0,
      PageInfo: props.PageInfo
    }

    this.onToggleExpandClicked.bind(this);
    this.onPageRedirectRequested.bind(this);
    this.getView.bind(this);

    this.ViewManager = new ViewManager(props.applicationName, props.tenantUniqueName);
  }

  componentDidMount() {
    //var navUri = '/'
    var that = this;
    if (this.ViewManager){
      this.ViewManager.load().then(function(res){
        Logger.logNotification('Views reloaded from server. ' + that.ViewManager?.Views?.length + ' views found.' );

        if (that.state.applicationName  && that.state.entityName && that.state.actionName){
          that.onPageRedirectRequested({ EntityName : that.state.entityName!, ViewType : that.state.actionName as ViewType, Id: that.state.itemId });
        }
        else if (that.state.applicationName  && that.state.entityName){
          that.onPageRedirectRequested({ EntityName : that.state.entityName!, ViewType: 'view' });
        }

      })
    }
    let appEntKey : string = SessionStorageManagerKeyManager.getTenantEntityListKey(this.props.tenantUniqueName);
    if (!SessionStorageManager.containsItem(appEntKey)){
      let emh : EntityModelHelper = new EntityModelHelper();
      emh.loadEntitiesInModel(this.props.tenantUniqueName ?? 'core', this.props.applicationName).then(function(entRes: Array<EntityDefinition>){
        SessionStorageManager.setItem(SessionStorageManagerKeyManager.getTenantEntityListKey(that.props.tenantUniqueName), JSON.stringify(entRes));
      })
    }
  }
  private onToggleExpandClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) : boolean | void => {
    this.setState({ isSideNavExpanded : !this.state.isSideNavExpanded});
  }

  private onPageRedirectRequested = (info : NavigationInfo) : void =>{
    var targetPath = this.props.applicationName + '/' + (info?.EntityName ? info?.EntityName + ( info?.ViewType? '/' + info?.ViewType + (info?.Id ? '/' + info?.Id : '') : '' ) : '');
    Logger.logNotification('Redirect to ' + targetPath);

    var bPathFound : boolean = false;
    if (info && this && this.ViewManager && this.ViewManager.Views){
      for(var x = 0; x<this.ViewManager.Views.length; x++){
        if (this.ViewManager.Views[x].EntityName.toLowerCase() === info.EntityName?.toLowerCase() && this.ViewManager.Views[x].ActionName?.toLowerCase() === info.ViewType?.toLowerCase())
        {
          bPathFound = true;
          this.CurrentView = this.ViewManager.Views[x];
          this.setState({CurrentView : this.ViewManager.Views[x], StateKey : Math.random() })          
        }
      }
    }
    if (!bPathFound){
      this.setState({ errorMessage : 'The target view for path \'' + targetPath + '\'  was not found.'})
    }
  }
  private getView = () : XView | null | undefined =>{
    return this.CurrentView;
  }
  closeMessageBar = () => {
    this.setState({ errorMessage: '' });
  };
  render() {
    var that = this;

    return (
      <>
        <header>
            <Stack horizontal horizontalAlign="stretch" className="header-navbar-panel" >
            <HeaderCommandBar onToggleExpandHandler={this.onToggleExpandClicked} />
            </Stack>
        </header>
        <div>
          <Stack verticalFill horizontalAlign='stretch' className='body-panel'>
            {this.state.errorMessage && 
                      <MessageBar
                          messageBarType={MessageBarType.error}
                          isMultiline={true}
                          onDismiss={() => this.closeMessageBar()}
                          dismissButtonAriaLabel="Close"
                      >
                      {this.state.errorMessage}
                  </MessageBar>
            }
            {this.props.applicationName &&
            <Stack horizontal horizontalAlign="stretch" className="body-panel" >
              
              <SideBar PageInfo={this.props.PageInfo} 
                       applicationName={that.props.applicationName} 
                       isExpanded={this.state.isSideNavExpanded} 
                       onNavigationChanged={this.onPageRedirectRequested}  
              />
              <ScriptItem scriptSource={this.CurrentView?.ViewJSData} ></ScriptItem>
              <div className="ms-Grid tn-main-grid" dir="ltr">
                  <EntityView PageInfo={this.props.PageInfo}  getViewHandler={this.getView} entityName={this.CurrentView?.EntityName} action={this.CurrentView?.ActionName!} itemId={this.props.itemId} applicationName={this.props.applicationName} />
              </div>
            </Stack>
            }
            {!this.props.applicationName &&
            <Stack horizontal horizontalAlign="stretch" className="body-panel" >
              <AppTileGrid/>
            </Stack>
            }            
        </Stack>
        </div>
        <footer>
            <Stack horizontal horizontalAlign="stretch" className="footer-panel" >
            <span className="footer-panel-text">Copyright &copy; {new Date().getFullYear()} Ninth.eu</span>
            </Stack>

        </footer>
      </>
    );
  }
}
