import { CommandBar, ContextualMenuItemType, IButtonProps, ICommandBarItemProps, IconButton, IContextualMenuItem, IContextualMenuProps, IPivotItemProps, Pivot, PivotItem, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { DesignSurfaceDragInfo } from "../DragInfo";
import { DesignerHelper } from "./DesignerHelper";
import { SurfaceBase } from "./SurfaceBase";
import { TabItemSurfaceControl } from "./TabItemSurface";

interface FormSurfaceControlProps extends SurfaceBase {
    Form : IllerisNinthUI.Formlet;
    renderKey: number;
}

interface FormSurfaceControlState extends FormSurfaceControlProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  tabContextMenuItems : IContextualMenuProps;
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class FormSurfaceControl extends React.Component<FormSurfaceControlProps, FormSurfaceControlState> {
    constructor(props: FormSurfaceControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Form : props.Form,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabContextMenuItems : { items : new Array<IContextualMenuItem>()},
        getEntity : props.getEntity,
        updateParent: props.updateParent,
        renderKey: props.renderKey,
        appInfo: props.appInfo

      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : FormSurfaceControlProps, prevState: FormSurfaceControlState){
        if (prevProps.renderKey !== this.props.renderKey){
          return true;
        }
        else{
          return false;
        }
    }
    private addTab = (evt : any) : void =>{
        var frm : IllerisNinthUI.Formlet = this.state.Form;
        var tnTab : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
        tnTab.Title = 'New';
        frm.Tabs.push(tnTab);
        this.setState({Form : frm});
    }
    private clearTabs = (evt : any) : void =>{
        var frm : IllerisNinthUI.Formlet = this.state.Form;
        frm.Tabs = new Array<IllerisNinthUI.TNTab>();
        this.setState({Form : frm});
    }
    private findParent = (el: any) : string =>{
        if (el && el.dataset && el.dataset.itemidx){
            return el.dataset.itemidx;
        }
        if (el && el.parentNode){
            return this.findParent(el.parentNode);
        }
        if (el && el.parentElement){
            return this.findParent(el.parentElement);
        }
        return '';
        //itemidx
    }

    private addSectionToCurrentTab = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
        var tabIdxStr : string = item?.data?.toString() ?? '';
        if (tabIdxStr){
            var tabIdx : number  = Number.parseInt(tabIdxStr);
            if (!isNaN(tabIdx)){
                var sect: IllerisNinthUI.TNSection = DesignerHelper.CreateNewSection('New section');
                var frm : IllerisNinthUI.Formlet = this.state.Form;
                var tnTab : IllerisNinthUI.TNTab = frm.Tabs[tabIdx];
                if (tnTab){
                    tnTab.Sections.push(sect);
                    this.forceUpdate();
                }
            }
            debugger;
        }
        debugger;
    }
    private removeCurrentTab = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
        var tabIdxStr : string = item?.data?.toString() ?? '';
        if (tabIdxStr){
            var tabIdx : number  = Number.parseInt(tabIdxStr);
            if (!isNaN(tabIdx)){
                var frm : IllerisNinthUI.Formlet = this.state.Form;
                frm.Tabs.splice(tabIdx, 1);
                this.forceUpdate();
            }
            debugger;
        }
        debugger;
    }
    private editCurrentTab = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
        var tabIdxStr : string = item?.data?.toString() ?? '';
        if (tabIdxStr){
            var tabIdx : number  = Number.parseInt(tabIdxStr);
            if (!isNaN(tabIdx)){
                var frm : IllerisNinthUI.Formlet = this.state.Form;
                var tb : IllerisNinthUI.TNTab = frm.Tabs[tabIdx];
                if (tb && this.props.showProperties){
                    this.props.showProperties(tb);
                }
            }
            debugger;
        }
        debugger;
    }

    private buildCommandbar = () : void =>{
        let that = this;
        let items : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        items.push({
            key: 'addtab',
            text: 'Add Tab',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: this.addTab.bind(that)
          });
        items.push({
          key: 'cleartabs',
          text: 'Clear',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'Delete'},
          onClick: this.clearTabs.bind(that)
        });
        


        let farItems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();


        let cm : IContextualMenuProps = this.state.tabContextMenuItems;
        cm.items = new Array<IContextualMenuItem>();
        cm.items.push({
          key: 'addtab',
          text : 'Add Tab',
          iconProps : {iconName : 'Add'},
          onClick: this.addTab.bind(that)
        });
        cm.items.push({
          key: 'cleartabs',
          text: 'Clear',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'Delete'},
          onClick: this.clearTabs.bind(that)
        });
        cm.items.push({
            key: 'separator1',
            itemType: ContextualMenuItemType.Divider,
          });
          cm.items.push({
            key: 'addsection',
            text: 'Add Section',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: this.addSectionToCurrentTab.bind(that)
          });
          cm.items.push({
            key: 'removetab',
            text: 'Remove This Tab',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
            iconProps: { iconName: 'Delete'},
            onClick: this.removeCurrentTab.bind(that)
          });
          cm.items.push({
            key: 'edittabproperties',
            text: 'Edit Properties',
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
            iconProps: { iconName: 'Edit'},
            onClick: this.editCurrentTab.bind(that)
          });
        this.setState({cmdBarItems : items, cmdBarFarItems: farItems, tabContextMenuItems : cm});
    }

    private cloneContextMenu = (itemKey : string | undefined |null) : IContextualMenuProps =>{
        let nm : IContextualMenuProps = { items : new Array<IContextualMenuItem>()};
        this.state.tabContextMenuItems.items.forEach(function(item : IContextualMenuItem, idx : number){
            nm.items.push({
                key : item.key,
                text: item.text,
                itemType : item.itemType,
                cacheKey: item.cacheKey,
                iconProps : item.iconProps,
                onClick : item.onClick,
                data: itemKey
            })
        })
        return nm;
    }

    private onDragOver = (item?: any, event?: DragEvent) : void =>{
        if (item && item.dataTransfer){
            var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
            //var dataObj : DesignSurfaceDragInfo = (item.dataTransfer.getData('text/plain')) as DesignSurfaceDragInfo;
            if (dataObjStr){
                var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
                if (dataObj){
                    if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
                        item.dataTransfer.dropEffect = 'move';
                        return;
                    }
                }
                
            }
            item.dataTransfer.dropEffect = 'none';
        }
        //console.log('onDragOver');
    }
    private onDragDrop = (item?: any, event?: DragEvent) : void =>{
        if (item && item.dataTransfer){
            var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
            if (dataObjStr){
                var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
                if (dataObj){
                    if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
                        if (dataObj.ItemName === 'tabcontrol'){
                            let tc : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
                            var frm : IllerisNinthUI.Formlet = this.state.Form;
                            if (!frm.Tabs){
                                frm.Tabs = new Array<IllerisNinthUI.TNTab>();
                            }
                            frm.Tabs.push(tc);
                            this.forceUpdate();
                        }
                    }
                }
            }
            item.dataTransfer.dropEffect = 'none';
        }
    }
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    private onRenderTabHeader = (props?: IPivotItemProps, defaultRender?: (props?: IPivotItemProps) => JSX.Element | null) : JSX.Element | null =>{
        return (
            <div data-itemidx={props?.itemKey}>
                <span className='ms-Pivot-text'>{props?.headerText}</span>
                <IconButton
                    menuProps={this.cloneContextMenu(props?.itemKey)}
                    iconProps={{iconName : 'Info'}}
                    title="Info"
                    ariaLabel="Info"
                    disabled={false}
                    checked={false}
                    data-itemidx={props?.itemKey}
                    data={props?.itemKey}
                    />    
            </div>);
    }
        
    render() {
        var that = this;
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            {this.state.Form.Tabs.length === 0 && 
             <CommandBar
                items={this.state.cmdBarItems}
                overflowButtonProps={overflowProps}
                farItems={this.state.cmdBarFarItems}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            /> }
            <Pivot aria-label="Tabs">
                {this.state.Form.Tabs.map(function(item: IllerisNinthUI.TNTab, index :number){
                        return (<PivotItem headerText={item.Title} onRenderItemLink={that.onRenderTabHeader} itemKey={index.toString()} >
                            <TabItemSurfaceControl appInfo={that.props.appInfo} renderKey={that.state.renderKey} Form={that.props.Form} tabIndex={index} updateParent={that.updateParent} Tab={item} getEntity={that.props.getEntity} showProperties={that.props.showProperties}  ></TabItemSurfaceControl>
                        </PivotItem>)
                    })
                }
            </Pivot>
        </Stack>
        //    <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' onDragOver={this.onDragOver} onDrop={this.onDragDrop}>
        //    </Stack>
      );
    }
  }
  