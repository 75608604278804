import { CommandBar, ICommandBarItemProps, Icon, IconButton, Label, ProgressIndicator, Stack} from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { PageInfo } from "../PageInfo";
import { IllerisNinthAPI } from "../ServiceResult";

import { getFileTypeIconProps } from "@fluentui/react-file-type-icons/lib/getFileTypeIconProps";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons/lib/initializeFileTypeIcons";
import { ProgressBar } from "react-toastify/dist/components";

// Register icons and pull the fonts from the default SharePoint cdn.
initializeFileTypeIcons();

export interface DocumentGenerationPanelControlProps {
    EntityLogicalName: string;
    EntityId: string;
    PageInfo: PageInfo;
    closePanel: () => void;
}

export interface DocumentGenerationPanelControlState  {
    documentTemplates: any[];
    barItems: ICommandBarItemProps[];
    barFarItems: ICommandBarItemProps[];
    isBusy: boolean;
}
  
export class DocumentGenerationPanelControl extends React.Component<DocumentGenerationPanelControlProps, DocumentGenerationPanelControlState> {
      constructor(props : DocumentGenerationPanelControlProps){
          super(props);

          let that = this;
          let fi: ICommandBarItemProps[] = new Array<ICommandBarItemProps>()
          fi.push({
            key: 'refresh',
            text: 'refresh',
            iconOnly: true,
            iconProps: {iconName: 'Refresh'},
            onClick: (ev?: any) => { that.loadDocumentTemplates();}
          })
          
          this.state = {
            documentTemplates: new Array<any>(),
            barItems: new Array<ICommandBarItemProps>(),
            barFarItems: new Array<ICommandBarItemProps>(),
            isBusy: false
          }
      }

      private loadDocumentTemplates = () : void => {
        if (!this.props.EntityId || !this.props.EntityLogicalName){
          return;
        }
        let that =this;
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        this.setState({isBusy: true});
        this.forceUpdate();
        epm.getMany('documenttemplate','$filter=EntityName = "' + that.props.EntityLogicalName + '"&$orderby=DisplayName')
            .then(function(res : IllerisNinthAPI.ServiceResult){
                var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
                if (sr  && sr.Values) {
                  that.setState({documentTemplates: sr.Values, isBusy: false});
                  that.forceUpdate();
                }
            })
            .catch(function(err){
              IllerisNinthUI.NotificationManager.showError('Failed to load document templates', err.message);
            })
      }
      

      private getDefaultIconForEntity = (entName: string) : string => {
          let en: string = entName?.toLowerCase() ?? '';
          if (en === 'account'){
              return 'People';
          }
          else if (en === 'contact'){
            return 'Contact';
          }
          else if (en === 'product'){
            return 'Product';
          }
          else if (en === 'quote'){
            return 'Quotes';
          }
          else if (en === 'order'){
            return 'ActivateOrders';
          }
          else if (en === 'invoice'){
            return 'Invoice';
          }
          else{
            return 'ReceiptForward';
        }

      }

      public componentDidMount(){
          this.loadDocumentTemplates();
      }

      public shouldComponentUpdate(nextProps: DocumentGenerationPanelControlProps, nextState: DocumentGenerationPanelControlState){
        if (nextProps.EntityLogicalName !== this.props.EntityLogicalName || nextProps.EntityId !== this.props.EntityId) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: DocumentGenerationPanelControlProps, prevState: DocumentGenerationPanelControlState){
        if (prevProps.EntityLogicalName !== this.props.EntityLogicalName  || prevProps.EntityId !== this.props.EntityId){
            this.loadDocumentTemplates();
        }
      }
      private renderDocument = (it: any) : void => {
        let that = this;
        var epm : EntityPersistanceManager = new EntityPersistanceManager();

        //let xuri = `/odata/core/shop/${this.props.EntityLogicalName}(${this.props.EntityId})?$expand=Lines($expand=Product),Reseller,Account,Contact`;
        // let xuri = `/odata/core/shop/${this.props.EntityLogicalName}?$filter=Id eq ${that.props.EntityId}&$expand=Lines($expand=Product),Reseller,Account,Contact`;
        // epm.executeAPI(xuri, null,'GET', false)
        // .then(function(res : IllerisNinthAPI.ServiceResult | Error){
        //     debugger;
        //   })
        //   .catch(function(err){
        //     debugger;
        //     IllerisNinthUI.NotificationManager.showError('Failed to render document', err.message);
            
        //   })
        //   return;

        var uri = `/api/v2.0/terranova/xdocgen/generate/${that.props.PageInfo.TenantId}/` + this.props.EntityLogicalName + '/' + this.props.EntityId + '/' + it.Id;
        
        this.setState({isBusy: true});
        this.forceUpdate();
        epm.executeAPI(uri, null,'GET', false)
          .then(function(res : IllerisNinthAPI.ServiceResult | Error){
            var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
            if (sr && sr.Type && sr.Type === 'WebServiceResult'){
              if (sr && ((sr.ErrorMessages && sr.ErrorMessages.length === 0) || (!sr.ErrorMessages))){
                var dres : any = sr;
                var fnm : string = '';
                var mimeType : string = '';
                if (dres.FileName)
                    fnm = dres.FileName;
                if (dres.FileMimeType)
                    mimeType = dres.FileMimeType;
                var element = document.createElement('a');
                element.setAttribute('href', 'data:' + mimeType + ';base64,' + dres.Value);
                element.setAttribute('download', fnm);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                that.props.closePanel();
              }
              else if (sr && sr.ErrorMessages && sr.ErrorMessages.length !== 0){
                var str : string = sr.ErrorMessages.join('.');
                IllerisNinthUI.NotificationManager.showError('Failed to render document', str);
                that.props.closePanel();
              }
            }
            else{
              IllerisNinthUI.NotificationManager.showError('Failed to render document', 'Invalid Return Type');
              that.props.closePanel();
            }
          })
          .catch(function(err){
            IllerisNinthUI.NotificationManager.showError('Failed to render document', err.message);
            that.props.closePanel();
          })

        
      }

      public render(){
          var that = this;
         
          return (
            <Stack verticalFill horizontalAlign="stretch">
              <CommandBar
                items={that.state.barItems}
                farItems={that.state.barFarItems}
                ariaLabel="Actions"
                primaryGroupAriaLabel="Actions"
                farItemsGroupAriaLabel="More actions"
              />
              <Label>Templates</Label>
              {that.state.isBusy &&
                <ProgressIndicator label={'Loading data...'}></ProgressIndicator>
              }
              {that.state.documentTemplates.map(function(it: any, idx: number){
                return (<Stack horizontal horizontalAlign="stretch" style={{height: '32px'}}>
                  <Stack.Item style={{width: '35px'}} align='end'>
                    <Icon {...getFileTypeIconProps({ extension: it.DocumentType, size: 16 })} />
                  </Stack.Item>
                  <Stack.Item align="center" style={{width: '100%', marginLeft:'15px'}}>
                    {it.DisplayName}  
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton iconProps={{iconName: 'Build'}} onClick={(ev?: any) => { that.renderDocument(it); }}  ></IconButton>
                  </Stack.Item>
                </Stack>)
              })
              }
            </Stack>
             
          );
      }
  }