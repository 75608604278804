var xmlescape = require('xml-escape');

export class ItemFilterBase{
    public Parent? : ItemFilterBase;
    public toString = () : string => {
        return 'base';
    }
    public toXmlString = () : string => {
        return '';
    }
}
export enum ComplexFilterType {
    And,
    Or
}
export class PropertyFilterItem extends ItemFilterBase{
    public PropertyName: string = '';
    public Operator: string = '';
    public ValueString: string = '';

    public toXmlString = () : string => {
        let str: string = `<propertyfilteritem propertyname='${xmlescape(this.PropertyName)}' operator='${this.Operator}' valuestring='${xmlescape(this.ValueString)}' />`;
        return str;
    }

    public static parseXmlNode = (cn: HTMLElement) : PropertyFilterItem => {
        let res: PropertyFilterItem = new PropertyFilterItem();
        if (cn){
            res.PropertyName = cn.getAttribute('propertyname') ?? '';
            res.Operator = cn.getAttribute('operator') ?? '';
            res.ValueString = cn.getAttribute('valuestring') ?? '';
        }
        return res;
    }
}

export class ComplexFilterItem extends ItemFilterBase{
    public Expression1: ItemFilterBase | null | undefined;
    public Expression2: ItemFilterBase | null | undefined;
    public Operator: ComplexFilterType = ComplexFilterType.And;

    public setLeftExpression = (expr: ItemFilterBase) : void => {
        this.Expression1 = expr;
        expr.Parent = this;
    }
    public setRightExpression = (expr: ItemFilterBase) : void => {
        this.Expression2 = expr;
        expr.Parent = this;
    }
    constructor(operator: ComplexFilterType = ComplexFilterType.And){
        super();
        this.Operator = operator;
        this.Expression1 = new PropertyFilterItem();
        this.Expression2 = new PropertyFilterItem();
    }

    public toXmlString = () : string => {
        let str1: string = this.Expression1 ? this.Expression1.toXmlString() : '';
        let str2: string = this.Expression2 ? this.Expression2.toXmlString() : '';
        let str: string = `<complexfilteritem operator='${this.Operator}'>`;
        str = str + `<left>${str1}</left>`;
        str = str + `<right>${str2}</right>`;
        str += `</complexfilteritem>`;
        return str;
    }

    public static parseXmlNode = (cn: HTMLElement) : ComplexFilterItem => {
        let res: ComplexFilterItem = new ComplexFilterItem();
        if (cn){
            res.Operator = Number.parseInt(cn.getAttribute('operator') ?? '0') as (ComplexFilterType);
            var ln: HTMLCollectionOf<Element>  = cn.getElementsByTagName('left');
            if (ln && ln.length > 0){
                if (ln.item(0)?.nodeName === 'propertyfilteritem'){
                    res.Expression1 = PropertyFilterItem.parseXmlNode(ln.item(0) as HTMLElement);
                }
                else if (ln.item(0)?.nodeName === 'complexfilteritem'){
                    res.Expression1 = ComplexFilterItem.parseXmlNode(ln.item(0) as HTMLElement);
                }
            }
            var rn: HTMLCollectionOf<Element>  = cn.getElementsByTagName('right');
            if (rn && rn.length > 0){
                if (rn.item(0)?.nodeName === 'propertyfilteritem'){
                    res.Expression1 = PropertyFilterItem.parseXmlNode(ln.item(0) as HTMLElement);
                }
                else if (rn.item(0)?.nodeName === 'complexfilteritem'){
                    res.Expression1 = ComplexFilterItem.parseXmlNode(ln.item(0) as HTMLElement);
                }
            }
        }
        return res;
    }
}

export class ItemFilter{
    public Filters : ItemFilterBase[];
    constructor(){
        this.Filters = new Array<ItemFilterBase>();
    }
    public clear = () : void =>{
        this.Filters = new Array<ItemFilterBase>();
    }
    public removeAt = (idx: number) : ItemFilter  => {
        this.Filters.splice(idx, 1);
        return this;
    }
    public toXmlString = () : string => {
        let str: string = '<filter>';
        if (this.Filters){
            this.Filters.forEach(function(item: ItemFilterBase, index: number){
                str = str + item.toXmlString();
            });
        }
        str = str + '</filter>';
        return str;
    }

    static parseXml = (xmlStr: string) : ItemFilter => {
        const parser = new DOMParser();
        let res: ItemFilter = new ItemFilter();
        if (xmlStr){
            const xmlDoc = parser.parseFromString(xmlStr,"text/xml");
            let xroot : HTMLCollectionOf<SVGFilterElement> = xmlDoc.getElementsByTagName('filter');
            if (xroot && xroot.length > 0){
                let rxn : SVGFilterElement | null = xroot.item(0);
                if (rxn){
                    for(let x= 0; x<rxn.childNodes.length; x++){
                        let cn: HTMLElement = rxn.childNodes[x] as HTMLElement;
                        if (cn.nodeName === 'complexfilteritem'){
                            let fn: ComplexFilterItem = ComplexFilterItem.parseXmlNode(cn);
                            res.Filters.push(fn);
                        }
                        else if (cn.nodeName === 'propertyfilteritem'){
                            let pn: PropertyFilterItem = PropertyFilterItem.parseXmlNode(cn);
                            res.Filters.push(pn);
                        }
                    }
                }
            }
        }
        return res;

    }
}


