/* eslint-disable no-loop-func */
import { IComboBox, IComboBoxOption, VirtualizedComboBox } from "@fluentui/react/lib/components/ComboBox";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { IllerisNinthAPI } from "../../ServiceResult";
import { UIDataItemEditorProps } from "./FormletEditors";
import { Logger } from "../../Logger";
import { PageInfo } from "../../PageInfo";
import { SilentRequest } from "@azure/msal-browser";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack } from "@fluentui/react/lib/components/Stack";
import { TextField } from "@fluentui/react/lib/components/TextField";
import { IconButton } from "@fluentui/react/lib/components/Button";
import { DatePicker } from "@fluentui/react/lib/components/DatePicker";
import { DayOfWeek } from "@fluentui/react/lib/DateTimeUtilities";
import { SpinButton } from "@fluentui/react/lib/components/SpinButton";
import { Position } from "@fluentui/react/lib/utilities/positioning/positioning.types";
import { Toggle } from "@fluentui/react/lib/components/Toggle";
import { Spinner } from "@fluentui/react/lib/components/Spinner";
import { TNAdvandedLookup } from "../AdvancedLookup";
import { TNDocumentInfo, TNDocumentInputEditor } from "../../TNDocumentEditor";
import { TNImageInfo, TNImageInputEditor } from "../../TNImageEditor";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

export interface TNLookupEditorState extends TNEditorFactoryState {
    DropdownOptions : Array<IDropdownOption>;
}

  
  export class TNLookupEditor extends React.Component<TNEditorFactoryProps, TNLookupEditorState> {
      private mLookupEditor : IllerisNinthUI.TNLookup | null = null;
      private ComboBoxOptions : Array<IComboBoxOption> = new Array<IComboBoxOption>();


    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            loadingMessage: 'Loading...',
            DropdownOptions: new Array<IDropdownOption>()
        }

        this.mLookupEditor = this.props.getEditor() as IllerisNinthUI.TNLookup;
    }
    
    componentDidMount(){
        this.refresh();
    }

    private refresh = () : void => {
        this.mLookupEditor = this.props.getEditor() as IllerisNinthUI.TNLookup;
        this.loadComboboxOptions('', '', true);
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNLookupEditorState) {
        if (this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
            || this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.ServiceResult?.LoadedTimeStamp !== nextProps.ServiceResult?.LoadedTimeStamp
            || this.state.valueStr !== nextState.valueStr
        ){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNLookupEditorState){
        if (this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== prevProps.Entity?.getValue(prevProps.getEditor()?.HSBindName)
            || this.props.dataKey !== prevProps.dataKey
            || this.props.Entity?.getValue(this.props.getEditor()?.HSBindName) !== prevProps.Entity?.getValue(prevProps.getEditor()?.HSBindName)
            || this.props.ServiceResult?.LoadedTimeStamp !== prevProps.ServiceResult?.LoadedTimeStamp
            || this.state.valueStr !== prevState.valueStr
        ){
            this.refresh();
        }
        else{
            
        }
        
    }


    private loadComboboxOptions =  (searchValue : string, selectedValue: string, forceUpdate : boolean) :void => {
        var that = this;
        if (this.mLookupEditor){
            var uri = '/odata/'   + this.props.PageInfo.TenantId +  '/' + this.mLookupEditor.TargetApplication + '/' + this.mLookupEditor.TargetEntity;
            
        if (searchValue) {
            uri += '?$filter=';
            var flds = this.mLookupEditor.SearchFields.split(',');
            for (var x = 0; x < flds.length; x++) {
                flds[x] = flds[x].trim();

                uri += 'contains(' + flds[x] + ', \'' + searchValue + '\') ';
                if (x !== flds.length - 1)
                uri += ' OR ';
            }
            if (this.mLookupEditor && this.mLookupEditor.MaxResults){
                if (this.mLookupEditor.MaxResults === '*'){

                }
                else{
                    uri += '&$top=' + this.mLookupEditor.MaxResults;
                }
            }
            else{
                uri += '&$top=25';
            }
        }
        else if (selectedValue && !searchValue) {
            if (this.mLookupEditor.ValueFieldDataType.toLowerCase() === 'guid') {
                uri += '?$filter=' + this.mLookupEditor.ValueField + ' eq ' + selectedValue;
            }
            else if (this.mLookupEditor.ValueFieldDataType.toLowerCase() === 'string') {
                uri += '?$filter=' + this.mLookupEditor.ValueField + ' eq \'' + selectedValue + '\'';
            }
            else {
                Logger.logError('ERROR in TNLookup : Invalid parameter "ValueFieldDataType" : ' + this.mLookupEditor.ValueFieldDataType);
            }

            
        }
        // Dependency Property
        if (this.mLookupEditor.Depends.DependsOn  && this.mLookupEditor.Depends.DependencyProperty && this.mLookupEditor.Depends.DependencyPropertyDataType) {
            //debugger;
            var ent : DynamicEntity | null | undefined = this.props.Entity;
            var prop = ent?.getValue(this.mLookupEditor.Depends.DependsOn);
            if (prop && uri.indexOf('?$filter=') === -1) {
                if (this.mLookupEditor.Depends.DependencyPropertyDataType.toLowerCase() === 'guid') {
                    //this.ControllerURI += '?$filter=' + this.DependencyProperty + ' eq ' + ent.getValue();
                    if (this.mLookupEditor.Depends.DependsOn !== 'Id')
                        uri += '?$filter=' + this.mLookupEditor.Depends.DependencyProperty + ' eq ' + prop + '';
                    else
                        uri += '?$filter=' + this.mLookupEditor.Depends.DependencyProperty + ' eq ' + ent?.getValue(ent?.PrimaryKeyName) + '';
                }
                else if (this.mLookupEditor.Depends.DependencyPropertyDataType.toLowerCase() === 'string') {
                    uri += '?$filter=' + this.mLookupEditor.Depends.DependencyProperty + ' eq \'' + prop + '\'';
                }
            }
            else if (prop) {
                if (this.mLookupEditor.Depends.DependencyPropertyDataType.toLowerCase() === 'guid') {
                    //this.ControllerURI += '&' + this.DependencyProperty + ' eq ' + ent.getValue();
                    if (this.mLookupEditor.Depends.DependsOn !== 'Id')
                        uri += '&' + this.mLookupEditor.Depends.DependencyProperty + ' eq ' + prop + '';
                    else
                    uri += '&' + this.mLookupEditor.Depends.DependencyProperty + ' eq ' + ent?.getValue(ent?.PrimaryKeyName) + '';
                }
                else if (this.mLookupEditor.Depends.DependencyPropertyDataType.toLowerCase() === 'string') {
                    uri += '&' + this.mLookupEditor.Depends.DependencyProperty + ' eq \'' + ent?.getValue(ent?.PrimaryKeyName) + '\'';
                }
            }
        }

        if (!searchValue)
        {
            if (uri.indexOf('?') === -1){
                //uri += '?$top=25';
                if (this.mLookupEditor && this.mLookupEditor.MaxResults){
                    if (this.mLookupEditor.MaxResults === '*'){
    
                    }
                    else{
                        if (uri.indexOf('&') !== -1){
                            uri += '&$top=' + this.mLookupEditor.MaxResults;
                        }
                        else{
                            uri += '?$top=' + this.mLookupEditor.MaxResults;
                        }
                    }
                }
                else{
                    uri += '?$top=25';
                }
            }
            else{
                if (uri.indexOf('$top') === -1){
                    uri += '&$top=25';
                }
            }
        }
        if (uri.indexOf('$orderby') === -1){
            if (uri.indexOf('?') === -1){
                uri += '?';
            }
            if (uri.indexOf('&')){
                uri += '&$orderby=' + this.mLookupEditor.DisplayField;
            }
            else{
                uri += '$orderby=' + this.mLookupEditor.DisplayField;
            }
        }

        this.setState({loadingMessage: 'Loading...'});
        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        //debugger;
        mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
            //debugger;
            that.ComboBoxOptions = new Array<IComboBoxOption>();
            if (res && res.values && Array.isArray(res.values)){
                for(var i = 0; i<res.values.length; i++){
                    var keyStr : string = (that.mLookupEditor && that.mLookupEditor!.ValueField && typeof res.values[i][that.mLookupEditor!.ValueField.toString()] != 'undefined'? (res.values[i][that.mLookupEditor!.ValueField.toString()] ?res.values[i][that.mLookupEditor!.ValueField.toString()] : '') : '');
                    var valStr : string = (that.mLookupEditor && that.mLookupEditor!.DisplayField && typeof res.values[i][that.mLookupEditor!.DisplayField.toString()] != 'undefined'? (res.values[i][that.mLookupEditor!.DisplayField.toString()] ? res.values[i][that.mLookupEditor!.DisplayField.toString()] : '') : '');
                    var opt : IComboBoxOption ={key : keyStr, text : valStr};
                    that.ComboBoxOptions.push(opt);
                }
            }
            that.setState({loadingMessage: ''});
            if (forceUpdate){
                that.forceUpdate();
            }

        }).catch(function(error: any){
            that.setState({loadingMessage: ''});
            Logger.logError('Failed to load lookup data : ' + error.message);
            // var staticData : string = '{"Tabs":[{"Name":"General","ImageCSSClassName":"","Groups":[{"Name":"Relations","ImageCSSClassName":"fas fa-users","Items":[{"Name":"Customers","ImageCSSClassName":"fal fa-users","EntityName":"customer","Action":"view","URI":null},{"Name":"Add Customer","ImageCSSClassName":"fal fa-user-plus","EntityName":"customer","Action":"add","URI":null}]},{"Name":"Settings","ImageCSSClassName":"fal fa-cogs","Items":[{"Name":"Tenants","ImageCSSClassName":"","EntityName":"tenant","Action":"list","URI":null},{"Name":"Applications","ImageCSSClassName":"fal fa-tablet","EntityName":"application","Action":"list","URI":null},{"Name":"Products","ImageCSSClassName":"fal fa-gift","EntityName":"product","Action":"list","URI":null},{"Name":"CDS Solutions","ImageCSSClassName":"fal fa-database","EntityName":"cdssolution","Action":"list","URI":null}]},{"Name":"My","ImageCSSClassName":"","Items":[{"Name":"My Customers","ImageCSSClassName":"fal fa-users","EntityName":"contact","Action":"list","URI":null},{"Name":"Subscriptions","ImageCSSClassName":"fal fa-list-alt","EntityName":"subscription","Action":"list","URI":null},{"Name":"My Quotes","ImageCSSClassName":"fal fa-shopping-cart","EntityName":null,"Action":null,"URI":"/apps/hssms/quote/myquotes"},{"Name":"My Orders","ImageCSSClassName":"fal fa-usd-square","EntityName":null,"Action":null,"URI":"/apps/hssms/quote/myorders"}]}]}]}';
            // that.mData = JSON.parse(staticData);
            // that.mTabIndex = 0;
            // that.refreshSideBar();
            })
        }
       
    }
    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.props.getEditor()?.HSBindName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        this.props.Entity?.setValue(propName, val);
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
        });
          //var valStr : string = (this.Entity && this.Entity.getValue(this.Editor!.HSBindName) ? this.Entity.getValue(this.Editor!.HSBindName) : '');

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };

        //   if (this && this.InputEditor && this.InputEditor.Type === 'checkbox'){
        //       debugger;
        //   }

        return (
            <Stack horizontalAlign='stretch' horizontal>
                
                <VirtualizedComboBox
                    style={{width: '100%'}}
                    selectedKey={that.state.valueStr}
                    label={this.mLookupEditor?.LabelText}
                    allowFreeform={false}
                    autoComplete="on"
                    options={this.ComboBoxOptions}
                    disabled={that.mLookupEditor?.IsReadOnly || this.props.isreadonly}
                    id={that.mLookupEditor?.ItemID}
                    data-hsbind={that.mLookupEditor?.HSBindName}
                    onChange={that.onComboBoxChanged}
                    //onChange={onChange}
                />
                {this.state.loadingMessage &&
                    <Spinner label="" style={{ marginTop: '25px', marginRight:'10px', marginLeft: '10px'}} />
                }
            </Stack>
        );
      }
     
      private onComboBoxChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        console.log('combobox changed to value : ' + value);
        if (this.mLookupEditor && this.props.Entity){
            this.props.Entity.setValue(this.mLookupEditor!.HSBindName, option?.key);
        }
        this.setState({valueStr : option?.key.toString()});
      }
      
    

      

  }