import React  from "react";
import {  RouteComponentProps } from "react-router-dom";
import { ApplicationPage } from "./ApplicationPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type TParams =  { tenantUniqueName? : string,  appname: string, entityname?: string, actionname?:string, id?: string };


export function DynamicPage({ match }: RouteComponentProps<TParams>) {
  
    return (<div className="App ">
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            <ApplicationPage 
                tenantUniqueName={match.params.tenantUniqueName} 
                applicationName={match.params.appname} 
                entityName={match.params.entityname} 
                actionName={match.params.actionname} 
                itemId={match.params.id} 
                PageInfo={{TenantId: match.params.tenantUniqueName ?? '', AppName: match.params.appname ?? '', EntityName: match.params.entityname ?? '', EntityId: match.params.id ?? '', PageType: 'Unknown' }}
            ></ApplicationPage>
        <ToastContainer />
        
    </div>)
}


export function DynamicScript({ match }: RouteComponentProps<TParams>) {
  
    return (<h1>Script</h1>)
}