// Generated by https://quicktype.io

export class SecurityRoleDefinition {
    public DisplayName:               string = '';
    public RoleName:                  string = '';
    public ApplicationName:           string = '';
    public ApplicationId:             string = '';
    public Application:               any | null;
    public Level:                     number = 0;
    public SourceApplicationUniqueId: string = '';
    public PublisherId:               string = '';
    public Publisher:                 any | null;
    public Id:                        string = '';
    public CreateDate:                Date = new Date();
    public LastModifiedDate:          Date = new Date();
    public CreatedBy:                 String  = '';
    public LastModifiedBy:            string  = '';
    public DeleteState:               number = 0;
    public Status:                    number = 1;
    public StatusReason:              number = 1;
    public StatusName:                string = '';
    public StatusReasonName:          string  = '';
    public RecordHash:                string = '';
    public RowVersion:                any | null;
    public SourceId:                  string = '';
    public OwnerId:                   string  = '';
    public OwningTeamId:              string  = '';
    public OwningOrganizationId:      string  = '';
    public OwningTenantId:            string  = '';
    public Privileges?: any[];
}
