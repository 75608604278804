import { DefaultButton, IContextualMenuProps, IStackTokens, Label, Panel, SearchBox, Stack} from "@fluentui/react";
import React from "react";
import { EntityDefinitionImportSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityDefinitionImportSurfaceEditor";
import { EntityDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityDefinitionSurfaceEditor";
import { SecurityRoleDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/SecurityRoleDefinitionSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { SecurityRoleDefinition } from "./Entities/SecrityRole";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { NavigationBarDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/NavigationBarDefinitionSurfaceEditor";
import { NavigationBar } from "./Entities/NavigationBar";
import { entries } from "lodash";
import { Guid } from "../Guid";

interface SolutionContentControlExProps {
    app: SystemApplicationInfo;
    tenantId : string;
    showEntityDetails? : (ent: EntityDefinition) => void;
    showRoleDetails : (role: SecurityRoleDefinition) => void;
    showNavBarDetails : (bar: NavigationBar) => void;
    showAppResources : () =>  void;
    clear: () => void;
}

interface SolutionContentControlExState extends SolutionContentControlExProps {
  StateKey : number;
  errorMessage? : string;
  allEntities : Array<EntityDefinition>;
  allRoles: Array<SecurityRoleDefinition>;
  allNavBars: Array<any>;
  depth : number;
  view : string;
  searchStr : string;
  showAddEntityPanel: boolean;
  showAddRolePanel: boolean;
  showAddNavBarPanel: boolean;
  newNavBar: NavigationBar | null;
  showImportEntityPanel: boolean;
  newEnt: EntityDefinition |null;
  newRole: SecurityRoleDefinition |null;
}
const stackTokens: IStackTokens = { childrenGap: 5 };

export class SolutionContentControlEx extends React.Component<SolutionContentControlExProps, SolutionContentControlExState> {
    constructor(props: SolutionContentControlExProps) {
      super(props);
      this.state = {
        StateKey : 0,
        app : props.app,
        tenantId : props.tenantId,
        allEntities : new Array<EntityDefinition>(),
        allRoles : new Array<SecurityRoleDefinition>(),
        allNavBars : new Array<any>(),
        depth : 0,
        view : '',
        searchStr : '',
        showAddEntityPanel: false,
        showAddNavBarPanel: false,
        newNavBar: null,
        newEnt: null,
        showImportEntityPanel: false,
        showAddRolePanel: false,
        newRole : null,
        showRoleDetails: props.showRoleDetails,
        showNavBarDetails: props.showNavBarDetails,
        showAppResources: props.showAppResources,
        clear: props.clear,
      }
    
    }

    private buildContextMenu = (logicalName: string) : IContextualMenuProps => {
      let menu: IContextualMenuProps = {
        items: [
          {
            key: 'openDetails',
            text: 'Open',
            iconProps: { iconName: 'OpenPaneMirrored' },
            onClick: (ev?: any) => {this.onOpenEntityDetails(logicalName)},
          },
          {
            key: 'openSettings',
            text: 'Settings',
            'data-entitylogicalname': logicalName,
            iconProps: { iconName: 'Settings' },
            onClick: (ev?: any) => {this.onOpenEntitySettings(logicalName)},
          },
        ],
      };
      return menu;
    }
    private onOpenEntityDetails = (entLogName: string) : void => {
      debugger;
      let ent : EntityDefinition | undefined = this.state.allEntities.find(z => z.LogicalName === entLogName);
      let that = this;
      if (ent && this.props.showEntityDetails){
          this.props.showEntityDetails(ent);
          that.forceUpdate();
      }
    }

    private onOpenEntitySettings = (entLogName: string) : void => {
      let that = this;
      let ent : EntityDefinition | undefined = this.state.allEntities.find(z => z.LogicalName === entLogName);
      if (ent){
        that.setState({newEnt : ent, showAddEntityPanel: true});
        
      }
    }
  
    componentDidMount() {
      var that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.loadEntitiesInModel(this.props.tenantId, this.props.app.Id).then(function (res: Array<EntityDefinition>){
        that.setState({allEntities : res});
      });
      eh.loadSecurityRolesInModel(this.props.tenantId, this.props.app.Id).then(function (res: Array<SecurityRoleDefinition>){
        that.setState({allRoles : res});
      });
      eh.loadNavBarsInModel(this.props.tenantId, this.props.app.Id).then(function (res: Array<NavigationBar>){
        that.setState({allNavBars : res});
      });
    }

    private onListItemClicked = (evt: any) : void =>{
        //tn-solutioncontent-listitem-active
        //tn-solutioncontent-listitem-nested
        if (evt && evt.currentTarget){
            evt.currentTarget.parentElement.querySelector(".tn-solutioncontent-listitem-nested")?.classList.toggle("tn-solutioncontent-listitem-active");
            evt.currentTarget.classList.toggle("tn-solutioncontent-listitem-down");
        }
    }
    private onShowEntityDetails = (evt: any) : void =>{
        if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.entityname){
            evt.preventDefault();
            let en: string = evt.currentTarget.dataset.entityname;
            let ent : EntityDefinition | undefined = this.state.allEntities.find(z => z.DisplayName === en);
            if (ent && this.props.showEntityDetails){
                this.props.showEntityDetails(ent);
            }
        }
    }
    private onShowRoleDetails = (evt: any) : void =>{
      if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.roleid){
          evt.preventDefault();
          let rid: string = evt.currentTarget.dataset.roleid;
          let role : SecurityRoleDefinition | undefined = this.state.allRoles.find(z => z.Id === rid);
          if (role && this.props.showRoleDetails){
              this.props.showRoleDetails(role);
          }
      }
  }
  private onShowNavBarDetails = (evt: any) : void =>{
    if (evt && evt.currentTarget && evt.currentTarget.dataset && evt.currentTarget.dataset.navbarid){
        evt.preventDefault();
        let rid: string = evt.currentTarget.dataset.navbarid;
        let role : NavigationBar | undefined = this.state.allNavBars.find(z => z.Id === rid);
        if (role && this.props.showRoleDetails){
            this.props.showNavBarDetails(role);
        }
    }
  }
  

    private onShowAppDetails = (evt : any) : void =>{
      this.setState({depth : 1});
    }
    private onShowEntities = (evt : any) : void =>{
      this.setState({depth : 2, view : 'entities'});
    }
    private onShowRoles = (evt : any) : void =>{
      this.setState({depth : 2, view : 'roles'});
    }
    private onShowTemplates = (evt : any) : void =>{
      this.setState({depth : 2, view : 'templates'});
    }
    private onShowNavBars = (ev: any) : void => {
      this.setState({depth : 2, view : 'navbars'});
    }
    private onShowAppResources = (ev: any) : void => {
      this.setState({depth : 2, view : 'appresources'});
      this.props.showAppResources();
    }
    private onNavigateBack = (evt : any) : void =>{
      this.props.clear();
      this.setState({depth : this.state.depth - 1});
    }
    
    private filterEntities = () : Array<EntityDefinition> =>{
      if (this.state.searchStr){
        let nr : Array<EntityDefinition> = new Array<EntityDefinition>();
        for(var x= 0; x<this.state.allEntities.length; x++){
          if (this.state.allEntities[x].DisplayName?.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) !== -1){
            nr.push(this.state.allEntities[x]);
          }
        }
        return nr;
      }
      else{
        return this.state.allEntities;
      }
    }
    private filterNavBars = () : Array<any> =>{
      if (this.state.searchStr){
        let nr : Array<any> = new Array<any>();
        for(var x= 0; x<this.state.allNavBars.length; x++){
          if (this.state.allNavBars[x].DisplayName?.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) !== -1){
            nr.push(this.state.allNavBars[x]);
          }
        }
        return nr;
      }
      else{
        return this.state.allNavBars;
      }
    }
    private filterAppResources = () : Array<any> =>{
      if (this.state.searchStr){
        let nr : Array<any> = new Array<any>();
        for(var x= 0; x<this.state.allNavBars.length; x++){
          if (this.state.allNavBars[x].DisplayName?.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) !== -1){
            nr.push(this.state.allNavBars[x]);
          }
        }
        return nr;
      }
      else{
        return this.state.allNavBars;
      }
    }
    private filterRoles = () : Array<SecurityRoleDefinition> =>{
      if (this.state.searchStr){
        let nr : Array<SecurityRoleDefinition> = new Array<SecurityRoleDefinition>();
        for(var x= 0; x<this.state.allRoles.length; x++){
          if (this.state.allRoles[x].DisplayName?.toLowerCase().indexOf(this.state.searchStr.toLowerCase()) !== -1){
            nr.push(this.state.allRoles[x]);
          }
        }
        return nr;
      }
      else{
        return this.state.allRoles;
      }
    }
    private onAddRoleClicked = () : void => {
      var that = this;
      let ed: SecurityRoleDefinition = new SecurityRoleDefinition();
      ed.ApplicationId = that.state.app.Id;
      ed.OwningTenantId = that.state.tenantId;
      that.setState({newRole : ed, showAddRolePanel: true});
    }
    private onAddNavBarClicked = () : void => {
      var that = this;
      let nb: any = {};
      nb.ApplicationId = that.state.app.Id;
      nb.OwningTenantId = that.state.tenantId;
      that.setState({newNavBar : nb, showAddNavBarPanel: true});
    }
    
    private onAddEntityClicked = () : void =>{
      var that = this;
      let ed: EntityDefinition = new EntityDefinition();
      ed.ApplicationId = that.state.app.Id;
      that.setState({newEnt : ed, showAddEntityPanel: true});
    }
    private onImportEntityClicked = () : void =>{
      var that = this;
      this.setState({showImportEntityPanel: true});
      // let ed: EntityDefinition = new EntityDefinition();
      // ed.ApplicationId = that.state.app.Id;
      // that.setState({newEnt : ed, showAddEntityPanel: true});
    }
    private saveAndCloseImportEntity = () : void => {
      this.setState({showImportEntityPanel : false});
    }
    
    private saveAndCloseAddRole = () : void => {
      if (!this.state.newRole){
        return;
      }
      let that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (!this.state.newRole.Id){
        eh.createNewRoleInApp(this.props.tenantId, this.props.app.Id, this.state.newRole!).then(function(res: SecurityRoleDefinition){
          eh.loadSecurityRolesInModel(that.props.tenantId, that.props.app.Id).then(function (res: Array<SecurityRoleDefinition>){
            that.setState({allRoles : res, showAddRolePanel: false});
          });
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddRolePanel: false});
        })
      }
      else{
        eh.updateRoleInApp(this.props.tenantId, this.props.app.Id, this.state.newRole!).then(function(res: SecurityRoleDefinition){
          eh.loadSecurityRolesInModel(that.props.tenantId, that.props.app.Id).then(function (res: Array<SecurityRoleDefinition>){
            that.setState({allRoles : res, showAddRolePanel: false});
          });
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddRolePanel: false});
        })
      }
    }

    private saveAndCloseAddNavigationBar = () : void => {
      if (!this.state.newNavBar){
        return;
      }
      let that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (!this.state.newNavBar.Id){
        eh.updateNavBar(this.props.tenantId, this.props.app.Id, this.state.newNavBar!).then(function(res: NavigationBar){
          that.setState({showAddNavBarPanel: false});
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddNavBarPanel: false});
        })
      }
      else{
        eh.updateNavBar(this.props.tenantId, this.props.app.Id, this.state.newNavBar!).then(function(res: NavigationBar){
          that.setState({showAddNavBarPanel: false});
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddNavBarPanel: false});
        })
      }
    }
    private saveAndCloseAddEntity = () : void => {
      if (!this.state.newEnt){
        return;
      }
      let that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (!that.state.newEnt?.Id || that.state.newEnt.Id === Guid.empty()){
        eh.createNewEntityInApp(this.props.tenantId, this.props.app.Id, this.state.newEnt!).then(function(res: EntityDefinition){
          eh.loadEntitiesInModel(that.props.tenantId, that.props.app.Id).then(function (res: Array<EntityDefinition>){
            that.setState({allEntities : res, showAddEntityPanel: false});
          });
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddEntityPanel: false});
        })
      }
      else {
        eh.updateEntityInApp(this.props.tenantId, this.props.app.Id, this.state.newEnt!).then(function(res: EntityDefinition){
          eh.loadEntitiesInModel(that.props.tenantId, that.props.app.Id).then(function (res: Array<EntityDefinition>){
            that.setState({allEntities : res, showAddEntityPanel: false});
          });
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message, showAddEntityPanel: false});
        })
      }
    }
    private dismissEntEditSideBar = () : void =>{
      this.setState({showAddEntityPanel : false});
    }

    private onGoToRoot = () : void => {
      window.location.href = '/system/design';
    }

    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };
    
    private onRefreshEntitiesClicked = (ev?: any) : void => {
      let that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.loadEntitiesInModel(this.props.tenantId, this.props.app.Id).then(function (res: Array<EntityDefinition>){
        that.setState({allEntities : res});
      });
    }
    private onRefreshNavBarsClicked = (ev?: any) : void => {
      let that = this;
      let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
      eh.loadNavBarsInModel(this.props.tenantId, this.props.app.Id).then(function (res: Array<NavigationBar>){
        that.setState({allNavBars : res});
      });
    }

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-solution-contentcontrol' tokens={stackTokens} style={{width: '70%'}}>
            <Label>Please select the option from the button(s) below to either change the navigate back and forward.</Label>
            {this.state.depth > 1 &&
              <SearchBox placeholder="Search" onSearch={newValue => this.setState({searchStr : newValue?.toString() ?? ''})} />
            }
            {this.state.depth === 0 &&
              <>
                <DefaultButton text={this.state.app.DisplayName} onClick={this.onShowAppDetails}  />
                <DefaultButton text={'Back'} iconProps={{iconName: 'Back'}} onClick={this.onGoToRoot}  />
              </>
            }
            {this.state.depth === 1 &&
              <>
                <DefaultButton iconProps={{iconName : 'Database'}}  text='Entities' onClick={this.onShowEntities}  />
                <DefaultButton iconProps={{iconName : 'Badge'}} text='Roles' onClick={this.onShowRoles}  />
                <DefaultButton iconProps={{iconName : 'DocumentManagement'}} text='Templates' onClick={this.onShowTemplates}  />
                <DefaultButton iconProps={{iconName : 'ContextMenu'}} text='Navigation Bars' onClick={this.onShowNavBars}  />
                <DefaultButton iconProps={{iconName : 'FileBug'}} text='Application Resources' onClick={this.onShowAppResources}  />
              </>
            }
            {this.state.depth === 2 && this.state.view === 'entities' &&
              
              <>
              <Label>Entities</Label>
              {
                this.filterEntities().map(function(item: EntityDefinition, index :number){
                  return (<DefaultButton key={index} data-entitylogicalname={item.LogicalName}   iconProps={{iconName : item.IsVirtual ? 'CertifiedDatabase' : 'Database'}}  text={item.DisplayName} data-entityname={item.DisplayName} data-entityid={item.Id} onClick={that.onShowEntityDetails} menuProps={that.buildContextMenu(item.LogicalName)}  />);
                })
              }
              <DefaultButton iconProps={{iconName : 'Refresh'}}  text='Refresh' onClick={this.onRefreshEntitiesClicked} style={{marginTop: '45px'}} />
              <DefaultButton iconProps={{iconName : 'Add'}}  text='Add' onClick={this.onAddEntityClicked} />
              <DefaultButton iconProps={{iconName : 'CloudDownload'}}  text='Import' onClick={this.onImportEntityClicked}  />
              </>

            }
            {this.state.depth === 2 && this.state.view === 'roles' &&
              <>
              <Label>Roles</Label>
              {
                this.filterRoles().map(function(item: SecurityRoleDefinition, index :number){
                  return (<DefaultButton key={index} iconProps={{iconName : 'LaptopSecure'}}  text={item.DisplayName} data-rolename={item.DisplayName} data-roleid={item.Id} onClick={that.onShowRoleDetails}  />);
                })
              }
              <DefaultButton iconProps={{iconName : 'Add'}}  text='Add' onClick={this.onAddRoleClicked} style={{marginTop: '45px'}} />
              </>

            }
            {this.state.depth === 2 && this.state.view === 'navbars' &&
              <>
              <Label>Navigation Bars</Label>
              {
                this.filterNavBars().map(function(item: any, index :number){
                  return (<DefaultButton key={index} iconProps={{iconName : 'ContextMenu'}}  text={item.DisplayName} data-rolename={item.DisplayName} data-navbarid={item.Id} onClick={that.onShowNavBarDetails}  />);
                })
              }
              <DefaultButton iconProps={{iconName : 'Add'}}  text='Add' onClick={this.onAddNavBarClicked} style={{marginTop: '45px'}} />
              <DefaultButton iconProps={{iconName : 'Refresh'}}  text='Refresh' onClick={this.onRefreshNavBarsClicked} />
              </>

            }
            {this.state.depth === 2 && this.state.view === 'appresources' &&
              <>
              <Label>Application Resources</Label>
              
              <DefaultButton iconProps={{iconName : 'Refresh'}}  text='Refresh' onClick={this.onShowAppResources} />
              </>

            }
            
            {this.state.depth !== 0 &&
              <>
                <DefaultButton iconProps={{iconName : 'Back'}}  text='Back' onClick={this.onNavigateBack}  />
              </>
            }
            
            {this.state.showAddEntityPanel && 
              <Panel
                isOpen={this.state.showAddEntityPanel}
                onDismiss={that.dismissEntEditSideBar}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Entity"
              >
                <EntityDefinitionSurfaceEditorControl app={this.state.app!} ent={this.state.newEnt!} isReadOnly={false} closeAndSave={that.saveAndCloseAddEntity} />
              </Panel>
              }
              {this.state.showAddRolePanel && 
              <Panel
                isOpen={this.state.showAddRolePanel}
                onDismiss={(ev?: any) => {that.setState({showAddRolePanel: false}); that.forceUpdate();}}
                
                closeButtonAriaLabel="Close"
                headerText="Add/Edit Security Role"
              >
                <SecurityRoleDefinitionSurfaceEditorControl app={this.state.app!} role={this.state.newRole!} isReadOnly={false} closeAndSave={that.saveAndCloseAddRole} />
              </Panel>
              }
              {this.state.showAddNavBarPanel && 
              <Panel
                isOpen={this.state.showAddNavBarPanel}
                onDismiss={(ev?: any) => {that.setState({showAddNavBarPanel: false}); that.forceUpdate();}}
                closeButtonAriaLabel="Close"
                headerText="Add/Edit Navigation Bar"
              >
                <NavigationBarDefinitionSurfaceEditorControl app={this.state.app!} navBar={this.state.newNavBar!} isReadOnly={false} closeAndSave={that.saveAndCloseAddNavigationBar} />
              </Panel>
              }
              {this.state.showImportEntityPanel && 
              <Panel
                isOpen={this.state.showImportEntityPanel}
                onDismiss={(ev?: any) => {that.setState({showImportEntityPanel: false})}}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Entity"
              >
                <EntityDefinitionImportSurfaceEditorControl app={this.state.app!} tenantId={this.props.tenantId}  isReadOnly={false} closeAndSave={that.saveAndCloseImportEntity} />
              </Panel>
              }
          </Stack>
      );
    }
  }
  

  