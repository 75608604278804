import {  ComboBox, CommandBar, ContextualMenu, Dropdown, FontWeights, getTheme, IButtonStyles, IComboBox, IComboBoxOption, ICommandBarItemProps, IconButton, IDragOptions, IDropdownOption, IDropdownStyles, Label, mergeStyleSets, MessageBar, MessageBarType, Modal, Panel, PrimaryButton, SearchBox, Stack, TextField } from "@fluentui/react";
import { takeRightWhile } from "lodash";
import { nanoid } from "nanoid";
import React from "react";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { AppResource } from "../../Entities/AppResource";
import { EntityFormDefinition2 } from "../../Entities/EntityForm";
import { EntityFormResourceDefinition } from "../../Entities/EntityFormResource";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { EntityFormScriptSourceSurfaceEditorControl } from "./EntityFormScriptSourceSurfaceEditor";


interface EntityFormScriptImportSurfaceEditorProps {
    TenantId: string;
    AppInfo: SystemApplicationInfo;
    isReadOnly : boolean;
    selectAppResource?: (res: AppResource) => void;
}

interface EntityFormScriptImportSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  isReadOnly : boolean;
  options: IDropdownOption[];
  scripts: EntityFormResourceDefinition[],
  barItems: ICommandBarItemProps[],
  showAddEditPanel: boolean,
  showImportPanel: boolean,
  showScriptEditor: boolean,
  resources: AppResource[]
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};


const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    
  },
});

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
};

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export class EntityFormScriptImportSurfaceEditorControl extends React.Component<EntityFormScriptImportSurfaceEditorProps, EntityFormScriptImportSurfaceEditorState> {
    constructor(props: EntityFormScriptImportSurfaceEditorProps) {
      super(props);

      let opts: IDropdownOption[] = new Array<IDropdownOption>();
      opts.push({key: 0, text: 'List View'});
      opts.push({key: 1, text: 'Create/Edit'});
      //opts.push({key: '1-1', text: '1-1'});
      //opts.push({key: 'N-N', text: 'N-N'});


      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        options: opts,
        scripts: new Array<EntityFormResourceDefinition>(),
        barItems: new Array<ICommandBarItemProps>(),
        showAddEditPanel: false,
        showScriptEditor : false,
        showImportPanel: false,
        resources: new Array<AppResource>()
      }
  
    }
    componentDidMount() {

      let mi: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
      mi.push({
        key: 'refresh',
        text: 'Refresh',
        iconOnly: true,
        iconProps: {iconName: 'Refresh'},
        onClick: (ev?: any) => {this.onSearchScript('');}
      });
      mi.push({
        key: 'import',
        text: 'Import',
        iconOnly: true,
        iconProps: {iconName: 'Import'},
        onClick: (ev?: any) => {this.addScriptFromAppResource();}
      })
      this.setState({barItems: mi})
      
    }
    
    private addScriptFromAppResource = () : void => {
      this.setState({showImportPanel: true});
    }

    
    private deleteScript = (idx: number) : void => {

    }
    private editScript = (idx: number) : void => {
      let s: AppResource = this.state.resources[idx];
      if (this.props.selectAppResource){
        this.props.selectAppResource(s);
      }
    }

    private onSearchScript = (searchTxt: string) : void => {
      let dmh : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      dmh.loadResourcesInApp(this.props.TenantId, this.props.AppInfo.Id, searchTxt)
        .then(function(res: AppResource[]){
          if (res && res.length > 0){
            let xr: AppResource[] = new Array<AppResource>();
            res.forEach(function(it: AppResource, idx : number){
              if (it.ResourceType === 1){
                xr.push(it);
              }
            })
            that.setState({resources: xr});
            that.forceUpdate();
          }
        })
    }

    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign="stretch">
          <CommandBar
            items={this.state.barItems}
            overflowButtonProps={{ ariaLabel: 'More commands' }}
            ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          {this.state.notificationMessage &&
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({notificationMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.notificationMessage}
            </MessageBar>
          }
          {this.state.errorMessage &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
              dismissButtonAriaLabel="Close"
            >
              {that.state.errorMessage}
            </MessageBar>
          }
          <SearchBox placeholder="Script Name" onSearch={newValue => that.onSearchScript(newValue)} />
          {that.state.resources && 
            that.state.resources.map((item, idx) => {
              return (
                <Stack horizontal horizontalAlign="stretch" key={nanoid()}>
                  <IconButton iconProps={{iconName: 'JavaScriptLanguage'}} style={{width: '35px'}} onClick={(ev?: any) => {that.editScript(idx);}} ></IconButton>
                  <Label style={{width: '100%'}}><a href='#' onClick={(ev?: any) => {that.editScript(idx);}} >{item.DisplayName}</a></Label>
                </Stack>
              )
            })
          }
          
        </Stack>
      );
    }
  }

