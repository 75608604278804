import { ColumnActionsMode, DefaultButton, DetailsList, DetailsListLayoutMode, DirectionalHint, IColumn, Icon, IContextualMenuProps, Link, Panel, ProgressIndicator, SelectionMode, Stack, ThemeGenerator } from "@fluentui/react";
import dayjs from "dayjs";
import React from "react";
import { EntityPropertyDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityPropertyDefinitionSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityPropertyDefinition } from "./Entities/EntityProperty";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";

interface EntityPropertiesViewControlProps {
    ent: EntityDefinition;
    tenantId : string;
    app: SystemApplicationInfo;
}

interface EntityPropertiesViewControlState extends EntityPropertiesViewControlProps {
  StateKey : number;
  errorMessage? : string;
  allProperties : Array<EntityPropertyDefinition>;
  columns : IColumn[];
  isbusy : boolean;
  showAddEditPropertyPanel: boolean;
  newProp?: EntityPropertyDefinition;
  isSortedDescending: boolean;
  sortedColumnKey: string;
  contextualMenuProps?: IContextualMenuProps;
  
}


export class EntityPropertiesViewControl extends React.Component<EntityPropertiesViewControlProps, EntityPropertiesViewControlState> {
    constructor(props: EntityPropertiesViewControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        ent : props.ent,
        tenantId : props.tenantId,
        allProperties : new Array<EntityPropertyDefinition>(),
        columns : new Array<IColumn>(),
        isbusy : false,
        showAddEditPropertyPanel: false,
        app: props.app,
        isSortedDescending: false,
        sortedColumnKey: ''
      }
    }
  
    componentDidMount() {
      var that = this;
      this.loadProperties().then(function(res: any){
        that.forceUpdate();
      });
      this.buildColumns();
    }

    private refresh = () : void => {
      var that = this;
      this.loadProperties().then(function(res: any){
        that.forceUpdate();
      });
    }
    private getEntityPropertyImage = (item: EntityPropertyDefinition) : string =>{
      if (item.DisplayName?.toLowerCase() === 'id'){
        return 'AzureKeyVault';
      }
      else if (item.DataTypeString === 'string' || item.DataTypeString === 'text') {
        return 'TextField';
      }
      else if (item.DataTypeString === 'datetime'){
        return 'DateTime';
      }
      else if (item.DataTypeString === 'boolean'){
        return 'BooleanData';
      }
      else if (item.DataTypeString === 'double'){
        return 'Decimals';
      }
      else if (item.DataTypeString === 'number'){
        return 'NumberField';
      }
      else if (item.DataTypeString === 'choice'){
        return 'NumberedList';
      }
      else if (item.DataTypeString === 'image'){
        return 'PictureCenter';
      }
      else if (item.DataTypeString === 'byte[]'){
        return 'Document';
      }
      else if (item.DataTypeString === 'guid'){
        return 'Tag';
      }
      else if (item.DataTypeString === 'timestamp'){
        return 'TimePicker';
      }
      else if (item.DataTypeString === 'relation'){
        return 'Relationship';
      }

      return '';
    }

    private buildColumns = (sortKey?: string, sortDescending: boolean= false, bSetState: boolean= true ) : IColumn[] =>{
        let that = this;
        let cols : IColumn[] = new Array<IColumn>();
        cols.push(
          {
            key: 'colicon',
            name: 'Icon',
            ariaLabel: 'Icon',
            isIconOnly: true,
            fieldName: 'icon',
            minWidth: 35,
            maxWidth: 35,
            isRowHeader: true,
            isResizable: false,
            isSorted: (sortKey === 'colicon'),
            isSortedDescending: (sortKey === 'colicon' && sortDescending),
            data: 'string',
            isPadded: true,
            
            onRender: (item: EntityPropertyDefinition) => {
              let imgStr = that.getEntityPropertyImage(item);
              if (imgStr){
                return (<Icon iconName={imgStr} style={{width: '32px', height: '32px'}} ></Icon>);
              }
              else{
                return (<div></div>);
              }
            },
          });
        cols.push(
            {
              key: 'coldisplayname',
              name: 'Display Name',
              ariaLabel: 'Display Name',
              isIconOnly: false,
              fieldName: 'DisplayName',
              minWidth: 80,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              isSorted: (sortKey === 'coldisplayname'),
              isSortedDescending: (sortKey === 'coldisplayname' && sortDescending),
              onColumnContextMenu: that._onColumnContextMenu,
              onColumnClick: this._onColumnClick.bind(this),
              onRender: (item: EntityPropertyDefinition) => {
                return <Link onClick={() => this.onEditProperty(item)}>{item.DisplayName}</Link>;
              },
            });
            cols.push(
                {
                  key: 'coldatatype',
                  name: 'Data Type',
                  ariaLabel: 'Data Type',
                  isIconOnly: false,
                  fieldName: 'DataTypeText',
                  minWidth: 80,
                  maxWidth: 250,
                  isRowHeader: true,
                  isResizable: true,
                  data: 'string',
                  isPadded: true,
                  sortAscendingAriaLabel: 'Sorted A to Z',
                  sortDescendingAriaLabel: 'Sorted Z to A',
                  isSorted: (sortKey === 'coldatatype'),
                  isSortedDescending: (sortKey === 'coldatatype' && sortDescending),
                  onColumnContextMenu: that._onColumnContextMenu,
                  onColumnClick: this._onColumnClick.bind(this),
                });
                cols.push(
                    {
                      key: 'colmaxlength',
                      name: 'Max. Length',
                      ariaLabel: 'Max. Length',
                      isIconOnly: false,
                      fieldName: 'MaxLength',
                      minWidth: 80,
                      maxWidth: 150,
                      isRowHeader: true,
                      isResizable: true,
                      data: 'string',
                      isPadded: true,
                      sortAscendingAriaLabel: 'Sorted A to Z',
                      sortDescendingAriaLabel: 'Sorted Z to A',
                      isSorted: (sortKey === 'colmaxlength'),
                      isSortedDescending: (sortKey === 'colmaxlength' && sortDescending),
                      onColumnContextMenu: that._onColumnContextMenu,
                      onColumnClick: this._onColumnClick.bind(this),
                    });
        cols.push(
            {
              key: 'colisrequired',
              name: 'Is Required',
              ariaLabel: 'Is Required',
              isIconOnly: false,
              fieldName: 'IsRequired',
              minWidth: 80,
              maxWidth: 150,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              isSorted: (sortKey === 'colisrequired'),
              isSortedDescending: (sortKey === 'colisrequired' && sortDescending),
              onColumnContextMenu: that._onColumnContextMenu,
              onRender: (item: EntityPropertyDefinition) => {
                return <span>{item.IsRequired ? 'Yes' : 'No'}</span>;
              },
              onColumnClick: this._onColumnClick.bind(this),
            }); 
        cols.push(
            {
              key: 'colcreatedate',
              name: 'Create Date',
              ariaLabel: 'Create Date',
              isIconOnly: false,
              fieldName: 'CreateDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              isSorted: (sortKey === 'colcreatedate'),
              isSortedDescending: (sortKey === 'colcreatedate' && sortDescending),
              onColumnContextMenu: that._onColumnContextMenu,
              onRender: (item: EntityPropertyDefinition) => {
                return <span>{dayjs(item.CreateDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick.bind(this),
            }); 
        cols.push(
            {
              key: 'colmoddate',
              name: 'Modified Date',
              ariaLabel: 'Modified Date',
              isIconOnly: false,
              fieldName: 'LastModifiedDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              isSorted: (sortKey === 'colmoddate'),
              isSortedDescending: (sortKey === 'colmoddate' && sortDescending),
              onColumnContextMenu: that._onColumnContextMenu,
              onRender: (item: EntityPropertyDefinition) => {
                return <span>{dayjs(item.LastModifiedDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick.bind(this),
            }); 
            cols.push(
              {
                key: 'level',
                name: 'Level',
                ariaLabel: 'Level',
                isIconOnly: false,
                fieldName: 'Level',
                minWidth: 120,
                maxWidth: 180,
                isRowHeader: true,
                isResizable: true,
                data: 'datetime',
                isPadded: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                isSorted: (sortKey === 'level'),
                isSortedDescending: (sortKey === 'level' && sortDescending),
                onColumnContextMenu: that._onColumnContextMenu,
                onRender: (item: EntityPropertyDefinition) => {
                  return (<span>{this.itemToLevel(item)}</span>);
                },
                onColumnClick: this._onColumnClick.bind(this),
              }); 
        for(var i=0;i<cols.length; i++){
          //cols[i].conco
        }
        if (bSetState){
          this.setState({columns : cols});
        }
        return cols;
    }

    private onEditProperty = (item: EntityPropertyDefinition) : void =>{
      this.setState({newProp: item, showAddEditPropertyPanel: true});
      this.forceUpdate();
    }

    private itemToLevel = (it: EntityPropertyDefinition) : string =>{
      if (!it){
        return 'Unknown';
      }
      if (it.Level === 0) {
        return 'System';
      }
      else if (it.Level === 10) {
        return 'Platform';
      }
      else if (it.Level === 100) {
        return 'ISV';
      }
      else if (it.Level === 110) {
        return 'Extension';
      }
      else if (it.Level === 1000) {
        return 'End User';
      }
      else{
        return 'Unknown';
      }
      
    }

    private createNewProperty = () : void => {
      let prop : EntityPropertyDefinition = new EntityPropertyDefinition();
      prop.EntityId = this.props.ent.Id;
      prop.DataType = 0;
      prop.DataTypeString = 'string';
      this.setState({newProp : prop, showAddEditPropertyPanel: true});
    }
    
    private _getContextualMenuProps = (ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps => {
      const items = [
        {
          key: 'aToZ',
          name: 'A to Z',
          iconProps: { iconName: 'SortUp' },
          canCheck: true,
          checked: column.isSorted && !column.isSortedDescending,
          onClick: () => this._onSortColumn(column.key, false),
        },
        {
          key: 'zToA',
          name: 'Z to A',
          iconProps: { iconName: 'SortDown' },
          canCheck: true,
          checked: column.isSorted && column.isSortedDescending,
          onClick: () => this._onSortColumn(column.key, true),
        },
      ];
      return {
        items: items,
        target: ev.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 10,
        isBeakVisible: true,
        onDismiss: this._onContextualMenuDismissed,
      };
    }
    private _onContextualMenuDismissed = (): void => {
      this.setState({
        contextualMenuProps: undefined,
      });
    };

    private _onColumnContextMenu = (column?: IColumn, ev?: React.MouseEvent<HTMLElement>): void => {
      if (column && ev){
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
          this.setState({
            contextualMenuProps: this._getContextualMenuProps(ev, column),
          });
        }
      }
    };
    private _onSortColumn = (columnKey: string, isSortedDescending: boolean): void => {
      const sortedItems = this._copyAndSort(this.state.allProperties, columnKey, isSortedDescending);
  
      this.setState({
        allProperties: sortedItems,
        columns: this.buildColumns(columnKey, isSortedDescending, false),
        isSortedDescending: isSortedDescending,
        sortedColumnKey: columnKey,
      });
    };

    private loadProperties = async () : Promise<void> => {
        let that = this;
        let dh : DesignTimeModelHelper = new DesignTimeModelHelper();
        this.setState({isbusy: true});
        dh.loadEntityProperties(this.props.tenantId, this.props.ent.ApplicationId, this.props.ent?.Id).then(function (props: Array<EntityPropertyDefinition>){
          if (props){
            props.forEach(function (item: EntityPropertyDefinition, index : number){
              item.DataTypeString =EntityPropertyDefinition.parseDataType(item.DataType);
              item.TextFormatText =EntityPropertyDefinition.parseTextFormat(item.TextFormat);
            })
          }
          that.setState({isbusy : false});
          that.setState({allProperties : props});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage : err.message});
        })
        // let uri: string = `/api/v1.0/terranova/metamodel/${this.props.tenantId}/${this.props.ent.Id}/properties`;
        // let emp : EntityPersistanceManager = new EntityPersistanceManager();
        // var that = this;
        // that.setState({isbusy : true});
        // emp.executeAPIAny(uri, null, 'GET', false)
        //     .then(function(res : any){
        //         let items : Array<EntityPropertyDefinition> = new Array<EntityPropertyDefinition>();
        //         if (res && res.Values && Array.isArray(res.Values)){
        //             res.Values.forEach(function(item : any, idx: number){
        //                 let it : EntityPropertyDefinition = new EntityPropertyDefinition();
        //                 it.Id = item.Id;
        //                 it.DisplayName = item.DisplayName;
        //                 it.EntityId= item.EntityId;
        //                 it.DeleteState= item.DeleteState;
        //                 it.Status= item.Status;
        //                 it.StatusReason= item.StatusReason;
        //                 it.DataType= item.DataType;
        //                 it.MaxLength = item.MaxLength;
        //                 it.CreateDate = item.CreateDate;
        //                 it.LastModifiedDate = item.LastModifiedDate;
        //                 items.push(it);    
        //             });
        //             that.setState({isbusy : false});
        //             that.setState({allProperties : items});
        //             that.forceUpdate();
        //         }
        //     });
    }
    private _getKey = (item: any, index?: number): string => {
        return item.key;
    }
    
    private onExItemInvoked(parent: EntityPropertiesViewControl, item: any): void {
      //alert(`Item invoked: ${item.name}`);
      //parent.editItem(item as EntityPropertyDefinition);
  }


    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.state.allProperties, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
          columns: newColumns,
          allProperties: newItems,
        });
        this.forceUpdate();
      
    }

    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }    
    
    public shouldComponentUpdate = (nextProps: EntityPropertiesViewControlProps, nextState: EntityPropertiesViewControlState) : boolean =>{
        if (nextProps.tenantId !== this.props.tenantId || (nextProps.ent && this.props.ent && nextProps.ent.Id !== this.props.ent.Id )){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : EntityPropertiesViewControlProps, prevState: EntityPropertiesViewControlState){
        var that = this;
        if (prevProps.ent && this.props.ent && prevProps.ent.Id !== this.props.ent.Id){
            this.loadProperties().then(function(res: any){
                that.forceUpdate();
              });
        }
    }
    private dismissEntEditSideBar = (ev: any) : void => {
      this.setState({showAddEditPropertyPanel : false});
      this.forceUpdate();
    }

    private deleteProperty = (propId: string) : void => {
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      if (this.state.newProp && this.state.newProp.Id){
        mh.deleteProperty(this.props.tenantId, this.props.app.Id, this.props.ent, this.state.newProp!).then(function(res: boolean){
          that.refresh();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
    }

    private saveAndCloseAddEntityProperty = () : void => {
      let that = this;
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (this.state.newProp && !this.state.newProp.Id){
        mh.createNewProperty(this.props.tenantId, this.props.app.Id, this.props.ent, this.state.newProp!).then(function(res: EntityPropertyDefinition){
          let allProps : EntityPropertyDefinition[] = that.state.allProperties;
          allProps.push(res);
          that.setState({errorMessage: '', newProp: res, showAddEditPropertyPanel: false, allProperties: allProps});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
      else{
        mh.updateEntityProperty(this.props.tenantId, this.props.app.Id, this.props.ent, this.state.newProp!).then(function(res: EntityPropertyDefinition){
          let allProps : EntityPropertyDefinition[] = that.state.allProperties;
          allProps.push(res);
          that.setState({errorMessage: '', newProp: res, showAddEditPropertyPanel: false, allProperties: allProps});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
    }
    private onAddProperty = (evt: any) => {
      let prop : EntityPropertyDefinition = new EntityPropertyDefinition();
      prop.EntityId = this.props.ent.Id;
      this.setState({newProp: prop, showAddEditPropertyPanel: true});
      this.forceUpdate();
    }
    private _onItemContextMenu = (item?: any, index?: number, ev?: Event): boolean => {
      if (item && index && ev){
        const contextualMenuProps: IContextualMenuProps = {
          target: ev.target as HTMLElement,
          items: [
            {
              key: 'text',
              name: `items selected`,
            },
          ],
          onDismiss: () => {
            this.setState({
              contextualMenuProps: undefined,
            });
          },
        };
    
        if (index > -1) {
          this.setState({
            contextualMenuProps: contextualMenuProps,
          });
        }
      }
  
      return false;
    };

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-designer-propertiesview'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              <DetailsList
              items={this.state.allProperties}
              compact={true}
              columns={this.state.columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              //selection={this._selection}
              //selectionPreservedOnEmptyClick={true}
              onItemContextMenu={this._onItemContextMenu}
              onItemInvoked={(item: any) => that.onExItemInvoked(that,item)}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
            />
            {this.state.showAddEditPropertyPanel  &&
              <Panel
                isOpen={this.state.showAddEditPropertyPanel}
                onDismiss={that.dismissEntEditSideBar}
                
                closeButtonAriaLabel="Close"
                headerText="Add/Edit Property"
              >
                <EntityPropertyDefinitionSurfaceEditorControl app={this.props.app} ent={this.state.ent!} prop={this.state.newProp!} isReadOnly={false} closeAndSave={that.saveAndCloseAddEntityProperty} deleteHandler={this.deleteProperty}  />
              </Panel>
              }
              <Stack horizontal horizontalAlign='stretch'>
                <DefaultButton label='Add Column' text='Add Property' iconProps={{iconName: 'Add'}} onClick={this.onAddProperty} style={{width: '180px', marginTop: '20px'}} ></DefaultButton>
                <DefaultButton label='Refresh' text='Refresh' iconProps={{iconName: 'Refresh'}} onClick={(event: any) => that.refresh()} style={{width: '180px', marginTop: '20px', marginLeft: '25px'}} ></DefaultButton>
              </Stack>
          </Stack>
      );
    }
  }
  