import React from "react";
import { ClientState } from "../../ClientState";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Logger } from "../../Logger";
import { PageInfo } from "../../PageInfo";
import { TNCustomerAppLicCounterEditControl } from "../Licensing/TNCustomerAppLicCounterEdit";
import { TNProductPriceEditControl } from "../ProductPricing/TNProductPriceEditControl";
import { TNProductPriceSchemeEditControl } from "../ProductPricing/TNProductPriceSchemeEditControl";
import { TNRolePrivilegeEditControl } from "../RolePrivilegeEdit";
import { TNShopLinesControlView } from "../TNShopLinesControlView";
import { TNUserRolesPrivilegesEditControl } from "../TNUserRolePrivilegesEdit";
import { TNUserRolesEditControl } from "../TNUserRolesEdit";
import { IFormletContext } from "../FormletContext";


export interface TNContainerViewProps {
    itemId?: string;
    getContainer : () => IllerisNinthUI.TNContainer | null;
    notifyContainerRendered : (containerId: string) => void;
    isReadOnly : boolean;
    entityId : string;
    getEntity: () => DynamicEntity | null | undefined;
    PageInfo: PageInfo;
    dataKey: string;

    FormletContext: IFormletContext;
}

export interface TNContainerViewState extends TNContainerViewProps {

}

export class TNContainerView extends React.Component<TNContainerViewProps, TNContainerViewState> {
    private Container : IllerisNinthUI.TNContainer | null= null;
    constructor(props : TNContainerViewProps){
        super(props);

        this.Container = props.getContainer();
    }

    componentDidMount(){
        if (this.Container){
            this.props.notifyContainerRendered(this.Container.ItemID)
        }
    }

    shouldComponentUpdate(nextProps: TNContainerViewProps, nextState: TNContainerViewState) {
        Logger.logNotification('TNContainerView::shouldComponentUpdate');
        if (nextProps.entityId !== this.props.entityId || nextProps.dataKey !== this.props.dataKey){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNContainerViewProps, prevState: TNContainerViewState){
        Logger.logNotification('TNContainerView::componentDidUpdate');
        if (prevProps.entityId !== this.props.entityId){
        
        }
    }

    render(){
        var that = this;

        var cs : ClientState = ClientState.CurrentState();
        
        var sl : IllerisNinthUI.ShowLineControlView | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.ShowLineControlView ? this.Container.Children[0] as IllerisNinthUI.ShowLineControlView : null;
        var rp : IllerisNinthUI.RolePrivilegeEdit | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.RolePrivilegeEdit ? this.Container.Children[0] as IllerisNinthUI.RolePrivilegeEdit : null;
        var uredit : IllerisNinthUI.UserRolesEdit | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.UserRolesEdit ? this.Container.Children[0] as IllerisNinthUI.UserRolesEdit : null;
        var ppedit : IllerisNinthUI.ProductPriceEdit | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.ProductPriceEdit ? this.Container.Children[0] as IllerisNinthUI.ProductPriceEdit : null;
        var ppsedit : IllerisNinthUI.ProductPriceSchemeEdit | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.ProductPriceSchemeEdit ? this.Container.Children[0] as IllerisNinthUI.ProductPriceSchemeEdit : null;
        var calcnt : IllerisNinthUI.CustomerAppLicCounters | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.CustomerAppLicCounters ? this.Container.Children[0] as IllerisNinthUI.CustomerAppLicCounters : null;
        var rpedit : IllerisNinthUI.UserRolePrivilegesEdit | null = this.Container && this.Container.Children && this.Container.Children.length > 0 && this.Container.Children[0] instanceof IllerisNinthUI.UserRolePrivilegesEdit ? this.Container.Children[0] as IllerisNinthUI.UserRolePrivilegesEdit : null;

        //var sr: IllerisNinthAPI.ServiceResult |null = (this.props.getEntity() as any) as IllerisNinthAPI.ServiceResult;

        return (
            <>
            {this!.Container && this!.Container.Children && this!.Container.Children.length === 0 &&
                <div id={this.Container?.ItemID}>Container</div>
            }
            {sl &&
                <TNShopLinesControlView 
                    foreignKeyName={sl.ForeignKeyName}
                    isreadonly={this.props.isReadOnly}
                    enableDiscountEdit={true} 
                    PageInfo={this.props.PageInfo}
                    enableUnitPriceEdit={true} getEntity={this.props.getEntity} childEntityLogicalName={sl.EntityName} relationName={sl.RelationName} totalNetPropertyId='AmountNET' totalDiscountPropertyId='TotalDiscountAmount' totalTOTPropertyId='AmountTotal' totalVATPropertyId='AmountVAT'  isReadOnly={this.props.isReadOnly} baseServiceURI={sl!.BaseURI} mainEntityLogicalName={sl!.MainEntityName} mainEntityId={that.props.entityId} allowDecimalQuantities={sl!.AllowDecimalQuantities} />
            }
            {uredit &&
                <TNUserRolesEditControl 
                    tenantId={this.props.PageInfo.TenantId}
                    isReadOnly={this.props.isReadOnly}
                    ></TNUserRolesEditControl>
            }
            {rpedit &&
                <TNUserRolesPrivilegesEditControl 
                    tenantId={this.props.PageInfo.TenantId}
                    isReadOnly={this.props.isReadOnly}
                    getEntity={this.props.getEntity}
                    PageInfo={this.props.PageInfo}
                    dataKey={this.props.dataKey}
                    ></TNUserRolesPrivilegesEditControl>
            }
            {ppedit &&
                <TNProductPriceEditControl 
                    tenantId={this.props.PageInfo.TenantId}
                    isReadOnly={this.props.isReadOnly}
                    PageInfo={this.props.PageInfo}
                    getEntity={this.props.getEntity}
                    >
                </TNProductPriceEditControl>
            }
            {ppsedit &&
                <TNProductPriceSchemeEditControl 
                    tenantId={this.props.PageInfo.TenantId}
                    isReadOnly={this.props.isReadOnly}
                    PageInfo={this.props.PageInfo}
                    getEntity={this.props.getEntity}
                    >
                </TNProductPriceSchemeEditControl>
            }
            {calcnt &&
                <TNCustomerAppLicCounterEditControl
                    tenantId={this.props.PageInfo.TenantId}
                    isReadOnly={this.props.isReadOnly}
                    PageInfo={this.props.PageInfo}
                    getEntity={this.props.getEntity}
                    customerAppLicId={this.props.entityId}
                    >
                </TNCustomerAppLicCounterEditControl>
            }
            {rp &&
                <TNRolePrivilegeEditControl 
                    id={rp.ItemID}
                    privilegesType={rp.PrivilegesType}
                    isreadonly={this.props.isReadOnly}
                    roleid={this.props.entityId}
                    rolename={this.props.getEntity()?.getValue('DisplayName')}
                    getEntity={this.props.getEntity}
                    //applicationid={(this.props.getEntity() as any)?.ApplicationId ?? ''}
                    //applicationname={(this.props.getEntity() as any)?.ApplicationName ?? ''}
                    tenantUniqueName={cs?.UserProfile?.TerraNovaTenantUniqueName ?? 'core'}
                    issystemadmin={false}
                    PageInfo={this.props.PageInfo}
                    dataKey={this.props.dataKey}
                     />
            }
            </>
        );
    }

}