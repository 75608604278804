export class StringHelper{
    static alphaNumbericOnly = (str: string) : string =>{
        if (!str){
            return '';
        }
        let id : string = str;
        /*id = id.replace( /\t/ , "T");
        id = id.replace( /\n/ , "N");
        id = id.replace( /\r/ , "R");
        id = id.replace( /\b/ , "B");
        id = id.replace( /\f/ , "F");*/
        return id.replace( /[^a-zA-Z0-9]/ , "");
    }
    static logicalNameOnly = (str: string) : string =>{
        if (!str){
            return '';
        }
        let id : string = str;
        /*id = id.replace( /\t/ , "T");
        id = id.replace( /\n/ , "N");
        id = id.replace( /\r/ , "R");
        id = id.replace( /\b/ , "B");
        id = id.replace( /\f/ , "F");*/
        return id.replace( /[^a-zA-Z0-9\/]/ , "");
    }
    static displayNameOnly = (str: string) : string =>{
        if (!str){
            return '';
        }
        let id : string = str;
        /*id = id.replace( /\t/ , "T");
        id = id.replace( /\n/ , "N");
        id = id.replace( /\r/ , "R");
        id = id.replace( /\b/ , "B");
        id = id.replace( /\f/ , "F");*/
        return id.replace( /[^a-zA-Z0-9-_\s]/ , "");
    }
    static isEmpty = (str: string | null |undefined) : boolean => {
        if (!str){
            return true;
        }
        return false;
    }
}