import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, IconButton, IDropdownOption, Label, Stack, TextField } from "@fluentui/react";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import { ContextualMenuItemType, IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { nanoid } from "nanoid";
import React from "react";
import { IllerisNinthUI } from "../MetaModel/UI/Formlet";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityPropertyDefinition } from "./Entities/EntityProperty";
import { EntityViewDefinition } from "./Entities/EntityView";
import { ComplexFilterItem, ComplexFilterType, ItemFilter, ItemFilterBase, PropertyFilterItem } from "./Entities/Filter";

export interface QueryFilterBuilderProps {
    entityName : string;
    tenantId : string;
    viewId? : string;
    allProps: Array<EntityPropertyDefinition>;
    EntityView: IllerisNinthUI.GridView | null;
}

export interface QueryFilterBuilderState {
    errorMessage? : string;
    cmdBarItems : ICommandBarItemProps[];
    cmdBarFarItems : ICommandBarItemProps[];
    cmdBarOverFlowItems : ICommandBarItemProps[];
    allApps : Array<any>;
    currentView : IllerisNinthUI.GridView | null;
    currentAppName : string;
    //filters: ItemFilterBase[];
    filter: ItemFilter;
    allProps: Array<any>;
}
export class QueryFilterBuilderControl extends React.Component<QueryFilterBuilderProps, QueryFilterBuilderState> {
    constructor(props : QueryFilterBuilderProps){
        super(props);

        this.state ={
            errorMessage : '',
            cmdBarItems : new Array<ICommandBarItemProps>(),
            cmdBarFarItems : new Array<ICommandBarItemProps>(),
            cmdBarOverFlowItems : new Array<ICommandBarItemProps>(),
            allApps : new Array<any>(),
            currentView : null,
            currentAppName : '',
            allProps: props.allProps,
            //filters: (props.EntityView && props.EntityView.FilterJSON) ? JSON.parse(props.EntityView.FilterJSON) : new Array<ItemFilterBase>()
            filter: (props.EntityView && props.EntityView.FilterJSON && props.EntityView.FilterJSON.startsWith('<')) ? ItemFilter.parseXml(props.EntityView.FilterJSON) : new ItemFilter()
        }

        this.replaceItemBy.bind(this);
    }

    componentDidMount(){
        let cmdBarItems: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
        cmdBarItems.push({
            key: 'itemAddAndFilter',
            iconOnly: false,
            //iconProps: {iconName: ''},
            text:'Add AND',
            ariaLabel: 'Add AND filter',
            onClick: this.addAndFilter
        });
        cmdBarItems.push({
            key: 'itemAddOrFilter',
            iconOnly: false,
            //iconProps: {iconName: ''},
            text:'Add OR',
            ariaLabel: 'Add OR filter',
            onClick: this.addOrFilter
        });
        cmdBarItems.push({
            key: 'itemAddPropertyFilter',
            iconOnly: false,
            //iconProps: {iconName: ''},
            text:'Add Property',
            ariaLabel: 'Add Property',
            onClick: this.addPropertyFilter
        });
        cmdBarItems.push({
            key: 'refresh',
            iconOnly: true,
            iconProps: {iconName: 'Refresh'},
            ariaLabel: 'Refresh',
            onClick: this.refresh
        });
        cmdBarItems.push({
            key: 'delete',
            iconOnly: true,
            iconProps: {iconName: 'Delete'},
            ariaLabel: 'Delete',
            onClick: this.delete
        });
        cmdBarItems.push({
            key: 'save',
            iconOnly: true,
            iconProps: {iconName: 'Save'},
            ariaLabel: 'Save',
            onClick: this.save
        });
        this.setState({cmdBarItems: cmdBarItems});
    }
    private refresh = (evt?: any) : void => {
        this.forceUpdate();
    }
    private delete = (evt?: any) : void => {
        let f: ItemFilter = this.state.filter;
        f.clear();
        this.setState({filter: f});
        this.forceUpdate();
    }
    private save = (evt?: any) : void => {
        if (this.props.EntityView){
           this.props.EntityView.FilterJSON = this.state.filter.toXmlString();
        }
    }
    private addAndFilter = (evt?: any) : void => {
        let f: ItemFilter = this.state.filter;
        f.Filters.push(new ComplexFilterItem());
        this.setState({filter: f});
    }
    private addOrFilter = (evt?: any) : void => {
        let f: ItemFilter = this.state.filter;
        f.Filters.push(new ComplexFilterItem(ComplexFilterType.Or));
        this.setState({filter: f});
    }
    private addPropertyFilter = (evt?: any) : void => {
        let f: ItemFilter = this.state.filter;
        f.Filters.push(new PropertyFilterItem());
        this.setState({filter: f});
    }

    private onAddLeft= (filter: ItemFilterBase,  it: ItemFilterBase) : void => {
        if (filter instanceof ComplexFilterItem){
            let cfi : ComplexFilterItem = filter as ComplexFilterItem;
            cfi.setLeftExpression(it);

            this.forceUpdate();
        }
    }
    private onAddRight= (filter: ItemFilterBase,it: ItemFilterBase) : void => {
        if (filter instanceof ComplexFilterItem){
            let cfi : ComplexFilterItem = filter as ComplexFilterItem;
            cfi.setRightExpression(it);

            this.forceUpdate();
        }
    }
    public replaceItemBy = (it: ItemFilterBase, direction: 'left' | 'right', newItem: 'and' | 'or' | 'field') : void => {
        if (it){
            if (it instanceof ComplexFilterItem){
                let cfi : ComplexFilterItem = it as ComplexFilterItem;
                if (direction ===  'left'){
                    if (newItem === 'and'){
                        cfi.Expression1 = new ComplexFilterItem();
                    }
                    else if (newItem === 'or'){
                        cfi.Expression1 = new ComplexFilterItem(ComplexFilterType.Or);
                    }
                    else if (newItem === 'field'){
                        cfi.Expression1 = new PropertyFilterItem();
                    }
                }
                else if (direction ===  'right'){
                    if (newItem === 'and'){
                        cfi.Expression2 = new ComplexFilterItem();
                    }
                    else if (newItem === 'or'){
                        cfi.Expression2 = new ComplexFilterItem(ComplexFilterType.Or);
                    }
                    else if (newItem === 'field'){
                        cfi.Expression2 = new PropertyFilterItem();
                    }
                }
            }
        }
        let xf: ItemFilter = this.state.filter;
        this.setState({filter: xf});
        this.forceUpdate();
    }

    private deleteItemAt = (idx: number) : void => {
        let xf: ItemFilter = this.state.filter;
        xf.removeAt(idx);
        this.setState({filter: xf});
    }

    render() {
        let that = this;
        let level: number = 0;
        return (<Stack horizontalAlign='stretch' verticalFill>
            <CommandBar
              id='formletMainCommandBar'
              items={that.state.cmdBarItems}
              //overflowItems={over}
              overflowButtonProps={{ ariaLabel: 'More commands' }}
              //farItems={that.FarButtons}
              farItems={that.state.cmdBarFarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
              //onClick={this.onMainCommandBarItemsClicked}
            />
            <Stack horizontalAlign='stretch' verticalFill>
                {that.state.filter.Filters.map(function (item: ItemFilterBase, idx: any){
                    return(
                        <Stack horizontalAlign='stretch' horizontal key={nanoid()}>
                            <Stack.Item>
                            <QueryFilterItemControl allProps={that.props.allProps} key={`level_${level}_item_${idx}`} 
                                entityName={that.props.entityName} 
                                tenantId={that.props.tenantId} 
                                level={level} 
                                filter={item} 
                                addLeft={(it: ItemFilterBase) => { that.onAddLeft(item, it);}}
                                addRight={(it: ItemFilterBase) => { that.onAddRight(item, it);}}
                                replaceItemBy={that.replaceItemBy}
                                itemKey={Math.random()}
                                >
                            </QueryFilterItemControl>
                            </Stack.Item>
                            <Stack.Item align='end'>
                                <IconButton iconProps={{iconName: 'Delete'}} onClick={(ev?: any) => {that.deleteItemAt(idx);}}></IconButton>
                            </Stack.Item>
                        </Stack>)
                })

                }
            </Stack>
        </Stack>)
    }
}


export interface QueryFilterItemProps {
    entityName : string;
    tenantId : string;
    viewId? : string;
    filter?: ItemFilterBase | null;
    parentFilter?: ItemFilterBase | null;
    level: number;
    allProps: any[];
    ent?: EntityDefinition;
    addLeft: (it: ItemFilterBase) => void;
    addRight: (it: ItemFilterBase) => void;
    replaceItemBy: (it: ItemFilterBase, direction: 'left' | 'right', newItem: 'and' | 'or' | 'field') => void;
    itemKey: number;
}

export interface QueryFilterItemState {
    errorMessage? : string;
    cmdBarItems : ICommandBarItemProps[];
    cmdBarFarItems : ICommandBarItemProps[];
    cmdBarOverFlowItems : ICommandBarItemProps[];
    allApps : Array<any>;
    currentView : IllerisNinthUI.GridView | null;
    currentAppName : string;
    filter?: ItemFilterBase | null;
    level: number;
    entityProps: IComboBoxOption[];
    parentFilter?: ItemFilterBase | null;
}
export class QueryFilterItemControl extends React.Component<QueryFilterItemProps, QueryFilterItemState> {
    constructor(props : QueryFilterItemProps){
        super(props);

        this.state ={
            errorMessage : '',
            cmdBarItems : new Array<ICommandBarItemProps>(),
            cmdBarFarItems : new Array<ICommandBarItemProps>(),
            cmdBarOverFlowItems : new Array<ICommandBarItemProps>(),
            allApps : new Array<any>(),
            currentView : null,
            currentAppName : '',
            filter: props.filter,
            level: props.level,
            entityProps: new Array<IComboBoxOption>(),
            parentFilter: props.parentFilter
        }
    }

    private buildButtonMenu = () : IContextualMenuProps => {
        let that= this;
        let cf : ComplexFilterItem = that.state.filter as ComplexFilterItem;
        const menuProps: IContextualMenuProps = {
            items: [
              {
                key: 'addAndExpressionLeft',
                text: 'Add AND Expression Left',
                onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem()); that.forceUpdate();}
              },
              {
                key: 'addORExpressionLeft',
                text: 'Add OR Expression Left',
                onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem(ComplexFilterType.Or)); that.forceUpdate();}
              },
              {
                key: 'addPropertyExpressionLeft',
                text: 'Add Property Expression Left',
                onClick: (ev: any, item?: any) => { that.props.addLeft(new PropertyFilterItem()); that.forceUpdate();}
              },
              {
                  key: 'sep',
                  itemType: ContextualMenuItemType.Divider
              },
              {
                key: 'addAndExpressionRight',
                text: 'Add AND Expression Right',
                onClick: (ev: any, item?: any) => { that.props.addRight(new ComplexFilterItem()); that.forceUpdate();}
              },
              {
                key: 'addORExpressionRight',
                text: 'Add OR Expression Right',
                onClick: (ev: any, item?: any) => { that.props.addRight(new ComplexFilterItem(ComplexFilterType.Or)); that.forceUpdate();}
              },
              {
                key: 'addPropertyExpressionRight',
                text: 'Add Property Expression Right',
                onClick: (ev: any, item?: any) => { that.props.addRight(new PropertyFilterItem()); that.forceUpdate();}
              },
              {
                key: 'sep2',
                itemType: ContextualMenuItemType.Divider
              },
              {
                key: 'makeAndExpression',
                text: 'Set AND Expression',
              },
              {
                key: 'makeOrExpression',
                text: 'Set OR Expression',
              },
              {
                key: 'makeFieldExpression',
                text: 'Set Field Filter',
              },
            ],
          };
        return menuProps;
    }

    private buildFieldFilterButtonMenu = (direction: 'none' | 'left' | 'right' = 'none') : IContextualMenuProps => {
        let that= this;

        if (direction === 'none'){
            //let cf : ComplexFilterItem = that.state.filter as ComplexFilterItem;
            let fb : ItemFilterBase = that.state.parentFilter as ItemFilterBase;
            let cf: ComplexFilterItem = fb as ComplexFilterItem;
            if (cf){
                const menuProps: IContextualMenuProps = {
                    items: [
                    {
                        key: 'makeAndExpression',
                        text: 'Set AND Expression',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'and')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem()); that.forceUpdate();}
                    },
                    {
                        key: 'makeOrExpression',
                        text: 'Set OR Expression',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'or')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem(ComplexFilterType.Or)); that.forceUpdate();}
                    },
                    {
                        key: 'makeFieldExpression',
                        text: 'Set Field Filter',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'field')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new PropertyFilterItem()); that.forceUpdate();}
                    },
                    ],
                };
                return menuProps;
            }
            else{
                return { items: []};
            }
        }
        else if (direction === 'left'){
            const menuProps: IContextualMenuProps = {
                items: [
                {
                    key: 'makeAndExpression',
                    text: 'Set AND Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'and')}
                },
                {
                    key: 'makeOrExpression',
                    text: 'Set OR Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'or')}
                },
                {
                    key: 'makeFieldExpression',
                    text: 'Set Field Filter',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'field')}
                },
                ],
            };
            return menuProps;
        }
        else{
            const menuProps: IContextualMenuProps = {
                items: [
                {
                    key: 'makeAndExpression',
                    text: 'Set AND Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'and')}
                },
                {
                    key: 'makeOrExpression',
                    text: 'Set OR Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'or')}
                },
                {
                    key: 'makeFieldExpression',
                    text: 'Set Field Filter',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'field')}
                },
                ],
            };
            return menuProps;
        }
    }

    private onAddLeft= (it: ItemFilterBase) : void => {
        if (this.state.filter instanceof ComplexFilterItem){
            let cfi : ComplexFilterItem = this.state.filter as ComplexFilterItem;
            cfi.setLeftExpression(it);

            this.forceUpdate();
        }
    }
    private onAddRight= (it: ItemFilterBase) : void => {
        if (this.state.filter instanceof ComplexFilterItem){
            let cfi : ComplexFilterItem = this.state.filter as ComplexFilterItem;
            cfi.setRightExpression(it);

            this.forceUpdate();
        }
    }

    shouldComponentUpdate(nextProps: QueryFilterItemProps, nextState: QueryFilterItemState) {
        if (this.props.filter !== nextProps.filter || this.props.itemKey !== nextProps.itemKey) {
            if (this.props.filter instanceof ComplexFilterItem){
                //let cfi : ComplexFilterItem = this.props.filter as ComplexFilterItem;
                //if (cfi.)
            }
            return true
        }
        else {
            return false
        }
    }

    private replaceItemBy = (it: ItemFilterBase, direction: "left" | "right", newItem: "and" | "or" | "field") : void => {
        this.props.replaceItemBy(it, direction, newItem);
        //this.setState({filter: this.state.filter});
        //this.forceUpdate();
    }
    private replaceThisChildBy = (direction: "left" | "right", newItem: "and" | "or" | "field") : void =>{
        let cf: ComplexFilterItem = this.state.filter as ComplexFilterItem;
        if (cf){
            if (direction === 'left'){
                if (newItem === 'and'){
                    cf.Expression1 = new ComplexFilterItem();
                }
                else if (newItem === 'or'){
                    cf.Expression1 = new ComplexFilterItem(ComplexFilterType.Or);
                }
                else if (newItem === 'field'){
                    cf.Expression1 = new PropertyFilterItem();
                }
            }
            else if (direction === 'right'){
                if (newItem === 'and'){
                    cf.Expression2 = new ComplexFilterItem();
                }
                else if (newItem === 'or'){
                    cf.Expression2 = new ComplexFilterItem(ComplexFilterType.Or);
                }
                else if (newItem === 'field'){
                    cf.Expression2 = new PropertyFilterItem();
                }
            }
            this.setState({filter: cf});
            this.forceUpdate();
        }
    }
    
    render() {
        let that = this;
        
        if (that.state.filter instanceof ComplexFilterItem){
            //let af: ComplexFilterItem = that.state.filter as ComplexFilterItem;
            // return (<Stack horizontalAlign='stretch' verticalFill>
            //     <Stack horizontal horizontalAlign='stretch'>
            //         <Stack.Item style={{minWidth: '80px'}}>
            //             <DefaultButton label={af.Operator === 0 ? 'AND' : 'OR'} text={af.Operator === 0 ? 'AND' : 'OR'} style={{height: '100%'}}></DefaultButton>
            //         </Stack.Item>
            //         <Stack.Item style={{width: '100%'}}>
            //             <Stack verticalFill horizontalAlign='stretch'>
            //                 <Stack.Item>
            //                     <Stack horizontal horizontalAlign='stretch'>
            //                         <Stack.Item>
            //                             <QueryFilterItemControl itemKey={Math.random()} parentFilter={this.props.filter} replaceItemBy={(it: ItemFilterBase, direction: "left" | "right", newItem: "and" | "or" | "field") => {that.replaceThisChildBy('left', newItem);}  } level={this.props.level+1} entityName={this.props.entityName} tenantId={this.props.tenantId} filter={(that.state.filter as ComplexFilterItem).Expression1} addLeft={this.onAddLeft} addRight={this.onAddRight}    ></QueryFilterItemControl>
            //                         </Stack.Item>
            //                         <Stack.Item align='end'>
            //                             <DefaultButton
            //                                 text="Options"
            //                                 split
            //                                 splitButtonAriaLabel="Options"
            //                                 aria-roledescription="Options"
            //                                 menuProps={that.buildFieldFilterButtonMenu('left')}
            //                             />
            //                         </Stack.Item>
            //                     </Stack>
            //                 </Stack.Item>
            //                 <Stack.Item>
            //                     <Stack horizontal horizontalAlign='stretch'>
            //                         <Stack.Item>
            //                             <QueryFilterItemControl itemKey={Math.random()} parentFilter={this.props.filter} replaceItemBy={(it: ItemFilterBase, direction: "left" | "right", newItem: "and" | "or" | "field") => {that.replaceThisChildBy('right', newItem);}} level={this.props.level+1} entityName={this.props.entityName} tenantId={this.props.tenantId} filter={(that.state.filter as ComplexFilterItem).Expression2} addLeft={this.onAddLeft} addRight={this.onAddRight} ></QueryFilterItemControl>
            //                         </Stack.Item>
            //                         <Stack.Item align='end'>
            //                             <DefaultButton
            //                                 text="Options"
            //                                 split
            //                                 splitButtonAriaLabel="Options"
            //                                 aria-roledescription="Options"
            //                                 menuProps={that.buildFieldFilterButtonMenu('right')}
            //                             />
            //                         </Stack.Item>
            //                     </Stack>
            //                 </Stack.Item>
            //             </Stack>
            //         </Stack.Item>
            //         <Stack.Item align='end' style={{width: '150px'}}>
            //             <DefaultButton
            //                 text="Options"
            //                 split
            //                 splitButtonAriaLabel="Options"
            //                 aria-roledescription="Options"
            //                 menuProps={that.buildButtonMenu()}
            //             />
            //         </Stack.Item>
            //     </Stack>
            // </Stack>)
            return (<QueryFilterItemComplexFilterControl allProps={that.props.allProps} entityName={this.props.entityName} tenantId={this.props.tenantId} level={this.props.level+1} filter={that.state.filter as ComplexFilterItem} replaceItemBy={this.replaceItemBy}>

            </QueryFilterItemComplexFilterControl>);
        }
        else if (that.state.filter instanceof PropertyFilterItem) {
            return (<QueryFilterItemPropertyFilterControl allProps={this.props.allProps} entityName={this.props.entityName} tenantId={this.props.tenantId} level={this.props.level+1} filter={that.state.filter as PropertyFilterItem}>
            </QueryFilterItemPropertyFilterControl>);
        }
    }
}

export interface QueryFilterItemPropertyFilterProps {
    entityName : string;
    tenantId : string;
    viewId? : string;
    filter: PropertyFilterItem;
    level: number;
    ent?: EntityDefinition;
    allProps: EntityPropertyDefinition[];
}

export interface QueryFilterItemPropertyFilterState {
    errorMessage? : string;
    cmdBarItems : ICommandBarItemProps[];
    cmdBarFarItems : ICommandBarItemProps[];
    cmdBarOverFlowItems : ICommandBarItemProps[];
    allApps : Array<any>;
    currentView : IllerisNinthUI.GridView | null;
    currentAppName : string;
    filter: PropertyFilterItem;
    level: number;
    entityProps: IComboBoxOption[];
    operators: IComboBoxOption[];
}


export class QueryFilterItemPropertyFilterControl extends React.Component<QueryFilterItemPropertyFilterProps, QueryFilterItemPropertyFilterState> {
    constructor(props : QueryFilterItemPropertyFilterProps){
        super(props);

        let pa: IComboBoxOption[] = new Array<IComboBoxOption>();
        if (this.props.allProps){
            this.props.allProps.forEach(function(item: EntityPropertyDefinition, index: number){
                pa.push({key: item.LogicalName, text: item.DisplayName});
            })
        }
        pa = pa.sort((a, b) => a.text > b.text ? 1 : -1);

        this.state ={
            errorMessage : '',
            cmdBarItems : new Array<ICommandBarItemProps>(),
            cmdBarFarItems : new Array<ICommandBarItemProps>(),
            cmdBarOverFlowItems : new Array<ICommandBarItemProps>(),
            allApps : new Array<any>(),
            currentView : null,
            currentAppName : '',
            filter: props.filter,
            level: props.level,
            entityProps: pa,
            operators: new Array<IDropdownOption>()
        }
    }

    private buildStringOperators = () : IDropdownOption[] => {
        let res: IDropdownOption[] = new Array<IDropdownOption> ();
        res.push({key: '<', text: 'Less Then'});
        res.push({key: '<=', text: 'Less Or Equal'});
        res.push({key: '==', text: 'Equal To'});
        res.push({key: '>=', text: 'Greater Then Or Equal'});
        res.push({key: '>', text: 'Greater Then'});
        res.push({key: '<>', text: 'Not Equal To'});
        res.push({key: 'containsdata', text: 'Contains Data'});
        res.push({key: 'notcontainsdata', text: 'Does Not Contain Data'});
        return res;
    }
    private buildNumberOperators = () : IDropdownOption[] => {
        let res: IDropdownOption[] = new Array<IDropdownOption> ();
        res.push({key: '<', text: 'Less Then'});
        res.push({key: '<=', text: 'Less Or Equal'});
        res.push({key: '==', text: 'Equal To'});
        res.push({key: '>=', text: 'Greater Then Or Equal'});
        res.push({key: '>', text: 'Greater Then'});
        res.push({key: '<>', text: 'Not Equal To'});
        return res;
    }
    private buildBooleanOperators = () : IDropdownOption[] => {
        let res: IDropdownOption[] = new Array<IDropdownOption> ();
        res.push({key: '==', text: 'Equal To'});
        res.push({key: '<>', text: 'Not Equal To'});
        return res;
    }

    private setPropDataType = (propName: string) : void => {
        let prop: EntityPropertyDefinition | undefined = this.props.allProps.find(z => z.LogicalName === propName);
        if (prop){
            let dt: string = prop.DataTypeString?.toLowerCase();
            if (dt){
                let it: PropertyFilterItem = this.state.filter;
                it.PropertyName = propName;
                if (dt === 'string'){
                    this.setState({operators: this.buildStringOperators(), filter: it});
                }
                else if (dt === 'number' || dt === 'double' || dt === 'float'){
                    this.setState({operators: this.buildNumberOperators(), filter: it});
                }
                else if (dt === 'bool' || dt === 'boolean'){
                    this.setState({operators: this.buildBooleanOperators(), filter: it});
                }
                else if (dt === 'date' || dt === 'datetime'){
                    this.setState({operators: this.buildNumberOperators(), filter: it});
                }
                else{
                    this.setState({operators: this.buildBooleanOperators(), filter: it});
                }
            }
        }
    }

    private onPropNameChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let propName: string = option?.key?.toString() ?? '';
        if (propName){
            this.setPropDataType(propName);
        }
    }
    private onOperatorTypeChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
        let operatorName: string = option?.key?.toString() ?? '';
        let flt: PropertyFilterItem = this.state.filter;
        if (flt){
            flt.Operator = operatorName;
            this.setState({filter: flt});
        }
       
    }
    render() {
        let that = this;
        
        let fi: PropertyFilterItem = that.state.filter as PropertyFilterItem;
        if (fi.PropertyName && this.state.operators.length === 0){
            this.setPropDataType(fi.PropertyName);
        }

        return (<Stack horizontalAlign='stretch' verticalFill style={{marginLeft: '15px', marginRight: '15px'}}>
            <Stack horizontal horizontalAlign='stretch'>
                <Stack.Item >
                    <ComboBox options={this.state.entityProps} selectedKey={this.state.filter.PropertyName} label="Properties" style={{marginLeft: '15px'}} onChange={this.onPropNameChanged} >

                    </ComboBox>
                </Stack.Item>
                <Stack.Item>
                    <ComboBox options={this.state.operators} label="Operator" selectedKey={this.state.filter.Operator} onChange={this.onOperatorTypeChanged} style={{marginLeft: '15px', marginRight: '15px'}}>

                    </ComboBox>
                </Stack.Item>
                <Stack.Item>
                    <TextField  label="Value" value={fi?.ValueString} onChange={(ev: any, newval?: string) => { fi.ValueString = newval ?? ''; this.setState({filter: fi}); this.forceUpdate(); }}  >
                    </TextField>
                </Stack.Item>
            </Stack>
        </Stack>);
        
    }
}


export interface QueryFilterItemComplexFilterProps {
    entityName : string;
    tenantId : string;
    viewId? : string;
    filter: ComplexFilterItem;
    parentFilter?: ItemFilterBase | null;
    level: number;
    ent?: EntityDefinition;
    allProps: any[];
    replaceItemBy: (it: ItemFilterBase, direction: 'left' | 'right', newItem: 'and' | 'or' | 'field') => void;
}

export interface QueryFilterItemComplexFilterState {
    errorMessage? : string;
    cmdBarItems : ICommandBarItemProps[];
    cmdBarFarItems : ICommandBarItemProps[];
    cmdBarOverFlowItems : ICommandBarItemProps[];
    allApps : Array<any>;
    currentView : IllerisNinthUI.GridView | null;
    currentAppName : string;
    filter: ComplexFilterItem;
    level: number;
    entityProps: IComboBoxOption[];
    parentFilter?: ItemFilterBase | null;
}
export class QueryFilterItemComplexFilterControl extends React.Component<QueryFilterItemComplexFilterProps, QueryFilterItemComplexFilterState> {
    constructor(props : QueryFilterItemComplexFilterProps){
        super(props);

        this.state ={
            errorMessage : '',
            cmdBarItems : new Array<ICommandBarItemProps>(),
            cmdBarFarItems : new Array<ICommandBarItemProps>(),
            cmdBarOverFlowItems : new Array<ICommandBarItemProps>(),
            allApps : new Array<any>(),
            currentView : null,
            currentAppName : '',
            filter: props.filter,
            level: props.level,
            entityProps: new Array<IComboBoxOption>(),
            parentFilter: props.parentFilter
        }
    }


    private buildFieldFilterButtonMenu = (direction: 'none' | 'left' | 'right' = 'none') : IContextualMenuProps => {
        let that= this;

        if (direction === 'none'){
            //let cf : ComplexFilterItem = that.state.filter as ComplexFilterItem;
            let fb : ItemFilterBase = that.state.parentFilter as ItemFilterBase;
            let cf: ComplexFilterItem = fb as ComplexFilterItem;
            if (cf){
                const menuProps: IContextualMenuProps = {
                    items: [
                    {
                        key: 'makeAndExpression',
                        text: 'Set AND Expression',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'and')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem()); that.forceUpdate();}
                    },
                    {
                        key: 'makeOrExpression',
                        text: 'Set OR Expression',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'or')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new ComplexFilterItem(ComplexFilterType.Or)); that.forceUpdate();}
                    },
                    {
                        key: 'makeFieldExpression',
                        text: 'Set Field Filter',
                        onClick: (ev: any, item? : any) => {that.replaceItemBy(cf, (that.state.filter === cf.Expression1 ? 'left' : 'right'), 'field')}
                        //onClick: (ev: any, item?: any) => { that.props.addLeft(new PropertyFilterItem()); that.forceUpdate();}
                    },
                    ],
                };
                return menuProps;
            }
            else{
                return { items: []};
            }
        }
        else if (direction === 'left'){
            const menuProps: IContextualMenuProps = {
                items: [
                {
                    key: 'makeAndExpression',
                    text: 'Set AND Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'and')}
                },
                {
                    key: 'makeOrExpression',
                    text: 'Set OR Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'or')}
                },
                {
                    key: 'makeFieldExpression',
                    text: 'Set Field Filter',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('left', 'field')}
                },
                ],
            };
            return menuProps;
        }
        else{
            const menuProps: IContextualMenuProps = {
                items: [
                {
                    key: 'makeAndExpression',
                    text: 'Set AND Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'and')}
                },
                {
                    key: 'makeOrExpression',
                    text: 'Set OR Expression',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'or')}
                },
                {
                    key: 'makeFieldExpression',
                    text: 'Set Field Filter',
                    onClick: (ev: any, item? : any) => {that.replaceThisChildBy('right', 'field')}
                },
                ],
            };
            return menuProps;
        }
    }


    private replaceItemBy = (it: ItemFilterBase, direction: "left" | "right", newItem: "and" | "or" | "field") : void => {
        this.props.replaceItemBy(it, direction, newItem);
        //this.setState({filter: this.state.filter});
        //this.forceUpdate();
    }
    private replaceThisChildBy = (direction: "left" | "right", newItem: "and" | "or" | "field") : void =>{
        let cf: ComplexFilterItem = this.state.filter as ComplexFilterItem;
        if (cf){
            if (direction === 'left'){
                if (newItem === 'and'){
                    cf.Expression1 = new ComplexFilterItem();
                }
                else if (newItem === 'or'){
                    cf.Expression1 = new ComplexFilterItem(ComplexFilterType.Or);
                }
                else if (newItem === 'field'){
                    cf.Expression1 = new PropertyFilterItem();
                }
            }
            else if (direction === 'right'){
                if (newItem === 'and'){
                    cf.Expression2 = new ComplexFilterItem();
                }
                else if (newItem === 'or'){
                    cf.Expression2 = new ComplexFilterItem(ComplexFilterType.Or);
                }
                else if (newItem === 'field'){
                    cf.Expression2 = new PropertyFilterItem();
                }
            }
            this.setState({filter: cf});
            this.forceUpdate();
        }
    }

    private swapOperator = (btn?: any) : void => {
        let cfi: ComplexFilterItem = this.state.filter as ComplexFilterItem;
        if (cfi){
            if (cfi.Operator === ComplexFilterType.And){
                cfi.Operator = ComplexFilterType.Or;
            }
            else if (cfi.Operator === ComplexFilterType.Or){
                cfi.Operator = ComplexFilterType.And;
            }
            this.setState({filter: cfi});
            this.forceUpdate();
        }
    }
    
    render() {
        let that = this;
        
            return (<Stack horizontalAlign='stretch' verticalFill>
                <Stack horizontal horizontalAlign='stretch'>
                    <Stack.Item style={{minWidth: '80px'}}>
                        <DefaultButton label={that.state.filter.Operator === 0 ? 'AND' : 'OR'} text={that.state.filter.Operator === 0 ? 'AND' : 'OR'} style={{height: '100%'}} onClick={this.swapOperator}></DefaultButton>
                    </Stack.Item>
                    <Stack.Item style={{width: '100%'}}>
                        <Stack verticalFill horizontalAlign='stretch'>
                            <Stack.Item>
                                <Stack horizontal horizontalAlign='stretch'>
                                    <Stack.Item>
                                        {(that.state.filter.Expression1 instanceof ComplexFilterItem) && 
                                            <QueryFilterItemComplexFilterControl 
                                                entityName={that.props.entityName} 
                                                tenantId={that.props.tenantId}
                                                filter={that.state.filter.Expression1 as ComplexFilterItem}
                                                level={that.props.level +1}
                                                allProps={that.props.allProps}
                                                replaceItemBy={that.props.replaceItemBy}
                                            >
                                            </QueryFilterItemComplexFilterControl>
                                        }
                                        {(that.state.filter.Expression1 instanceof PropertyFilterItem) && 
                                            <QueryFilterItemPropertyFilterControl 
                                                entityName={this.props.entityName} 
                                                tenantId={this.props.tenantId} 
                                                level={this.props.level+1} 
                                                allProps={that.props.allProps}
                                                filter={that.state.filter.Expression1 as PropertyFilterItem}>
                                            </QueryFilterItemPropertyFilterControl>
                                        }
                                    </Stack.Item>
                                    <Stack.Item align='end'>
                                        <DefaultButton
                                            text="Options"
                                            split
                                            splitButtonAriaLabel="Options"
                                            aria-roledescription="Options"
                                            menuProps={that.buildFieldFilterButtonMenu('left')}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal horizontalAlign='stretch'>
                                    <Stack.Item>
                                    {(that.state.filter.Expression2 instanceof ComplexFilterItem) && 
                                            <QueryFilterItemComplexFilterControl 
                                                entityName={that.props.entityName} 
                                                tenantId={that.props.tenantId}
                                                filter={that.state.filter.Expression2 as ComplexFilterItem}
                                                level={that.props.level +1}
                                                allProps={that.props.allProps}
                                                replaceItemBy={that.props.replaceItemBy}
                                            >
                                            </QueryFilterItemComplexFilterControl>
                                    }
                                    {(that.state.filter.Expression2 instanceof PropertyFilterItem) && 
                                            <QueryFilterItemPropertyFilterControl 
                                                entityName={this.props.entityName} 
                                                tenantId={this.props.tenantId} 
                                                level={this.props.level+1} 
                                                allProps={that.props.allProps}
                                                filter={that.state.filter.Expression2 as PropertyFilterItem}>
                                            </QueryFilterItemPropertyFilterControl>
                                        }
                                    </Stack.Item>
                                    <Stack.Item align='end'>
                                        <DefaultButton
                                            text="Options"
                                            split
                                            splitButtonAriaLabel="Options"
                                            aria-roledescription="Options"
                                            menuProps={that.buildFieldFilterButtonMenu('right')}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>)
      
    }
}