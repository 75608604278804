import {  Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";
import { DesignTimeModelHelper } from "../../../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityRelationDefinition } from "../../Entities/EntityRelation";

interface EntitySelectorSurfaceEditorProps  {
    Label: string;
    TenantId: string;
    AppId: string;
    Relation: EntityRelationDefinition;
    IsReadOnly : boolean;
    onChanged? : (targetEntityId : string, targetEntityName: string) => void;
}

interface EntitySelectorSurfaceEditorState extends EntitySelectorSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  Label: string;
  IsReadOnly : boolean;
  Options : IDropdownOption[];
}

export class EntitySelectorSurfaceEditorControl extends React.Component<EntitySelectorSurfaceEditorProps, EntitySelectorSurfaceEditorState> {
    constructor(props: EntitySelectorSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        IsReadOnly : props.IsReadOnly,
        Label : props.Label,
        TenantId: props.TenantId,
        AppId: props.AppId,
        Options: new Array<IDropdownOption>(),
        Relation: props.Relation,
      }
    }
    componentDidMount() {
        this.loadData();
    }

    private loadData = () : void =>{
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      mh.loadEntitiesInModel(this.props.TenantId, this.props.AppId).then(function(res: EntityDefinition[]){
        let opts : Array<IDropdownOption> = new Array<IDropdownOption>();
        if (res){
          res.forEach(function(item: EntityDefinition, index: number){
            opts.push({key: item.Id, text: item.LogicalName});
          })
          that.setState({Options: opts});
          that.forceUpdate();
        }

      })
      .catch(function(err: any){
        that.setState({errorMessage: err.message});
      })  
    }

    shouldComponentUpdate(newProps: EntitySelectorSurfaceEditorProps, newState: EntitySelectorSurfaceEditorState){
      if (this.props.AppId !== newProps.AppId){
        return true;
      }
      else{
        return false;
      }
    }

    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      return (
        <Dropdown
            placeholder="Property"
            label={this.props.Label}
            options={this.state.Options}
            style={{width : '100%'}}
            onChange={this.onChanged}
            selectedKey={this.state.Relation?.TargetEntityId ?? ''}
            disabled={this.state.IsReadOnly}
        />
      );
    }
    private onChanged= (evt: any, option? : IDropdownOption, index? : number) =>{
        if (option){
            let it: EntityRelationDefinition = this.state.Relation;
            it.TargetEntityId = option.key.toString();
            it.TargetEntityName = option.text.toString();
            this.setState({Relation : it});
            this.forceUpdate();
            if (this.props.onChanged){
              this.props.onChanged(option.key.toString(), option.text.toString());
            }
        }
      }
  }
  