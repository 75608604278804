import React from "react";


interface PageTitleProps {
    title? : string;
}

interface PageTitleState {}

export class PageTitle extends React.Component<PageTitleProps, PageTitleState> {
    
    constructor(props : PageTitleProps){
        super(props);

        this.state = {

        }
    }

    componentDidMount(){

    }

    render() {
        var bScriptFound : boolean = false;
        var titles : HTMLCollectionOf<HTMLTitleElement> = document.getElementsByTagName('title');
        if (titles && titles.length> 0){
            var tit: HTMLTitleElement = titles[0];
            if (tit){
                tit.innerText = this.props.title? this.props.title! : '';
            }
        }
        return (
          <>
          </>)
    }
}