import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Illeris } from "./Controls/LanguageCombo";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";

export class TNLanguageCombo extends React.Component<TNEditorFactoryProps, TNEditorFactoryState> {
    private mEditor?: IllerisNinthUI.TNXLanguageCombo;
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state = {
            valueStr: ''
        }
  
    }

    private loadData = () : void => {
        this.mEditor = this.props.getEditor() as IllerisNinthUI.TNXLanguageCombo;
        if (this.mEditor && this.props.Entity){
            let valStr : string | null | undefined = this.props.Entity.getValue(this.mEditor.HSBindName);
            this.setState({valueStr: valStr});
            
        }
        else{
            this.setState({valueStr: undefined});
        }
        this.forceUpdate();
        
    }

    componentDidMount(){
        this.loadData();
    }
  
    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNEditorFactoryState) {
      if (this.props.itemId !== nextProps.itemId || this.props.isreadonly !== nextProps.isreadonly || this.props.getEditor()?.HSBindName !== this.mEditor?.HSBindName || this.props.dataKey !== nextProps.dataKey ){
        return true;
      }
      else{
        return false;
      }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNEditorFactoryState){
        if (prevProps.itemId !== this.props.itemId || this.props.isreadonly !== prevProps.isreadonly || this.props.getEditor()?.HSBindName !== this.mEditor?.HSBindName || this.props.dataKey !== prevProps.dataKey){
            this.loadData();
        }
    }
  
    render() {
        var that = this;
  
        return (
            <Illeris.Ninth.UX.LanguageEditControl 
                    isReadOnly={this.props.isreadonly} value={this.state.valueStr ?? ''} 
                    onChange={this.onChange} 
                    labelText={this.mEditor?.LabelText ?? 'xxx'}
            ></Illeris.Ninth.UX.LanguageEditControl>
        )
        
    }

    private onChange = (selKey: string) : void => {
        if (this.mEditor && this.props.Entity ){
            this.props.Entity.setValue(this.mEditor.HSBindName, selKey);
            this.setState({valueStr: selKey});
            this.forceUpdate();
        }
    }
  }