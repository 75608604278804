import { IllerisNinthUI } from "./Formlet";

export class UIElementFactory {
    public static parse = (item: HTMLElement) : IllerisNinthUI.ElementBase | null => {
        if (item.nodeName.toLowerCase() === 'tnxlanguagecombo') {
            var tc : IllerisNinthUI.TNXLanguageCombo = new IllerisNinthUI.TNXLanguageCombo();
            return tc;
        }
        else{
            return null;
        }
    }
}