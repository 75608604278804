import { DynamicEntity } from "./DynamicEntity";
import { Logger } from "./Logger";

export class ClientPluginHelper{
    private IsBound : boolean = false;
    private ApplicationName : string | null | undefined  = '';
    private EntityName : string | null | undefined = '';
    private ActionName : string | null | undefined  = '';
    private HandlerObject : any | null;
    private Entity : DynamicEntity | null = null;
    constructor(ent : DynamicEntity | null, appName: string | null | undefined, entName: string | null | undefined, actName: string | null | undefined){
        this.ApplicationName = appName;
        this.EntityName = entName;
        this.ActionName = actName;
        this.Entity = ent;
    }
    public bind = () : boolean =>{
        var windowObj = window as any;
        var that = this;
        if (typeof windowObj['TNPlugin'] !== 'undefined')
        {
            var pluginName = (this.ApplicationName ? this.ApplicationName + '_' : '') + (this.EntityName ? this.EntityName + '_' : '') + (this.ActionName ? this.ActionName  : '');
            if (typeof windowObj['TNPlugin'][pluginName] !== 'undefined'){
                this.HandlerObject = windowObj['TNPlugin'][pluginName];
                this.HandlerObject.Entity.getPropertyValue = (propName : string) : any =>{
                    if (that.Entity && that.Entity.hasProperty(propName)){
                        return that.Entity.getValue(propName);
                    }
                    else{
                        return null;
                    }
                }
                this.HandlerObject.Entity.getPKValue = () : any =>{
                    if (that.Entity ){
                        return that.Entity.getId();
                    }
                    else{
                        return null;
                    }
                }
                this.HandlerObject.Entity.getEntity = () : any =>{
                    if (that.Entity ){
                        return that.Entity.getEntity();
                    }
                    else{
                        return null;
                    }
                }
                this.IsBound = true;
                Logger.logNotification('Client Handler for ' + pluginName + ' found.');
                return true;
            }
            else{
                return false;
            }
            
        }
        else
        {
            return false;
        }
    }

    public executeOnFormLoad() : boolean{
        try{
            if (this.IsBound && this.HandlerObject){
                return this.HandlerObject['onFormLoad']() as boolean;
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onFormLoad\' : ' + error.message);
        }
        return false;
    }
    public executeOnDataLoaded(dataObj : any) : boolean{
        try{
            if (this.IsBound && this.HandlerObject){
                return this.HandlerObject['onDataLoaded'](dataObj) as boolean;
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onDataLoaded\' : ' + error.message);
        }
        return false;
    }
    public executeOnBeforeSave(dataObj : any) : boolean | null{
        try{
            if (this.IsBound && this.HandlerObject){
                var ce : CancelEventArgs = new CancelEventArgs();
                this.HandlerObject['onBeforeSave'](dataObj ? dataObj : this.Entity?.getEntity(), ce);
                return !ce.Cancel;
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onDataLoaded\' : ' + error.message);
        }
        return null;
    }
    public executeGetData = () : any =>{
        //getData
        try{
            if (this.IsBound && this.HandlerObject){
                var res : any = this.HandlerObject['getData']();
                if (this.Entity){
                    this.Entity.updateObject(res);
                }
                return res;
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onDataLoaded\' : ' + error.message);
        }
        return null;
    }
    public executeOnValueChanged(fldName: string, ctrlName: string, oldValue : any, newValue: any) : boolean | null{
        try{
            if (this.IsBound && this.HandlerObject){
                var ce : CancelEventArgs = new CancelEventArgs();
                this.HandlerObject['onValueChanged'](fldName, ctrlName,oldValue, newValue, ce);
                return !ce.Cancel;
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onValueChanged\' : ' + error.message);
        }
        return null;
    }
    public containerRendered(containerName : string) : void{
        try{
            if (this.IsBound && this.HandlerObject){
                var ce : CancelEventArgs = new CancelEventArgs();
                this.HandlerObject['renderContainer'](containerName);
            }
        }
        catch(error : any){
            throw new Error('Failed to execute client plugin \'onValueChanged\' : ' + error.message);
        }
    }
}


export class CancelEventArgs{
    public Cancel : boolean = false;
    constructor(){

    }
}