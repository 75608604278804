import { FontIcon } from "@fluentui/react";
import React, { CSSProperties } from "react";
import { IconConverter } from "./IconConverter";

export interface FluentIconProps  {
    FAClassName : string;
    cssClassName? : string;
    ariaLabel? : string;
}

interface FluentIconState extends FluentIconProps {
    FluentUIClassName : string;
}


export class FluentIcon extends React.Component<FluentIconProps, FluentIconState> {
    constructor(props: FluentIconProps) {
      super(props);
      this.state = {
          FAClassName : props.FAClassName,
          FluentUIClassName : this.getFluentIconClassName(props.FAClassName),
          cssClassName : props.cssClassName ? this.getCSSClassName(props.cssClassName) : props.FAClassName,
          ariaLabel : props.ariaLabel
      }
    }
    public shouldComponentUpdate(nextProps: FluentIconProps, nextState: FluentIconState){
        if (nextProps.FAClassName !== this.props.FAClassName || nextProps.cssClassName !== this.props.cssClassName) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: FluentIconProps, prevState: FluentIconState){
        if (prevProps.FAClassName !== this.props.FAClassName  || prevProps.cssClassName !== this.props.cssClassName){
            this.setState({FluentUIClassName: this.getFluentIconClassName(this.props.FAClassName)});
            this.forceUpdate();
        }
      }
    private getFluentIconClassName = (faClassName : string) : string =>{
        var iconName : string = IconConverter.convertToFluentUiIcon(faClassName);
        if (!iconName){
            if (faClassName && faClassName.indexOf(' ')){
                return faClassName.substr(0, faClassName.indexOf(' '));
            }
            else{
                return faClassName;
            }
        }
        return iconName;
    }
    private getCSSClassName = (faClassName : string) : string =>{
        if (faClassName && (faClassName.toLowerCase().startsWith('fal') || faClassName.toLowerCase().startsWith('far') || faClassName.toLowerCase().startsWith('fas'))) {
            faClassName = faClassName.substr(4);
        }
        var idx : number = faClassName ? faClassName.indexOf(' ') : -1;
        if (idx >= 0){
            var postFix : string = faClassName.substr(faClassName.indexOf(' ')).trim();
            if (postFix){
                postFix = postFix.toLowerCase();
                if (postFix === 'fa-2x'){
                    return 'tn-2x';
                }
                else if (postFix === 'fa-3x'){
                    return 'tn-3x';
                }
                else if (postFix === 'fa-4x'){
                    return 'tn-4x';
                }
                else if (postFix === 'fa-lg'){
                    return 'tn-2x';
                }
            }
        }
        return faClassName;
    }
    componentDidMount() {
    }



    render() {
        let fzStr : string = '';
        if (this.state.cssClassName?.toLowerCase() === 'tn-2x'){
            fzStr = '40px !important';
        }
      return (
        <FontIcon aria-label={this.state.ariaLabel??''} iconName={this.state.FluentUIClassName} className={this.state.cssClassName ?? ''} style={{fontSize : fzStr}} />

      );
    }
  }
  