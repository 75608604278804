import { IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, Stack, VirtualizedComboBox } from "@fluentui/react";
import React from "react";
import { ClientState } from "./ClientState";
import { TenantInfo } from "./designers/Entities/TenantInfo";
import { WhoAmIResponse } from "./designers/Entities/WhoAmIResponse";
import { EntityModelHelper } from "./designers/EntityModelHelper";
import { AssignToInfo, EntityPersistanceManager } from "./Helpers/EntityPersistanceManager";


interface RecordAssignControlProps {
    entityName?: string;
    entityId?: string;
    tenantId: string;
    Record: any;
    refreshRecord: (resultObj: any) => void;
}

interface RecordAssignControlState {
    entityName?: string;
    entityId?: string;
    tenantId: string;
    Record: any;
    currentOwnerId: string;
    currentTenantId: string;
    currentOrgId: string;
    currentUserId: string;
    errorMessage: string;
    isLoading: boolean;
    userRoles: string[];
    tenantOptions: IComboBoxOption[];
    orgOptions: IComboBoxOption[];
    teamOptions: IComboBoxOption[];
    userOptions: IComboBoxOption[];
}

export class RecordAssignControl extends React.Component<RecordAssignControlProps, RecordAssignControlState> {
    
    constructor(props : RecordAssignControlProps){
        super(props);

        this.state = {
            entityId: props.entityId,
            entityName: props.entityName,
            tenantId: props.tenantId,
            Record: props.Record,
            currentOwnerId: props.Record?.OwnerId ?? '',
            currentTenantId: props.Record?.OwningTenantId ?? '',
            isLoading: false,
            errorMessage: '',
            userRoles: new Array<string>(),
            tenantOptions: new Array<IComboBoxOption>(),
            orgOptions: new Array<IComboBoxOption>(),
            teamOptions: new Array<IComboBoxOption>(),
            userOptions: new Array<IComboBoxOption>(),
            currentOrgId: '',
            currentUserId: ''
        }
    }

    componentDidMount(){
        this.loadCurrentUserRoles();
    }

    private loadCurrentUserRoles = async ()  : Promise<void> =>{
        let mh: EntityPersistanceManager = new EntityPersistanceManager();
        let that = this;
        mh.whoAmI().then(function(wr: WhoAmIResponse){
            if (wr && wr.Roles){
                let rs: string[] = wr.Roles.split(';');
                that.setState({userRoles: rs});
            }
        })
        let eh: EntityModelHelper = new EntityModelHelper();
        that.setState({isLoading: true});
        eh.loadTenants().then(function(tlist: TenantInfo[]){
            let res :Array<IComboBoxOption> = new Array<IComboBoxOption>();
            if (tlist){
                tlist.forEach(function(titem: TenantInfo, idx: number){
                    res.push({key: titem.Id, text: titem.DisplayName});
                })
            }
            that.setState({tenantOptions: res, isLoading: false});
        })
    }
    private loadTenantOrgs = async (tenantId: string) : Promise<any> =>{
        this.setState({isLoading: true});
        let that = this;
        let eh: EntityModelHelper = new EntityModelHelper();
        eh.loadUtilTenantOrgs(tenantId).then(function(tlist: any[]){
            let res :Array<IComboBoxOption> = new Array<IComboBoxOption>();
            if (tlist){
                tlist.forEach(function(titem: any, idx: number){
                    res.push({key: titem.Id, text: titem.DisplayName});
                })
            }
            that.setState({orgOptions: res, isLoading: false});
        })
    }
    private loadTenantOrgUsers = async (tenantId: string, orgId: string) : Promise<any> =>{
        this.setState({isLoading: true});
        let that = this;
        let eh: EntityModelHelper = new EntityModelHelper();
        eh.loadUtilTenantOrgUsers(tenantId, orgId).then(function(tlist: any[]){
            let res :Array<IComboBoxOption> = new Array<IComboBoxOption>();
            if (tlist){
                tlist.forEach(function(titem: any, idx: number){
                    res.push({key: titem.Id, text: titem.DisplayName});
                })
            }
            that.setState({userOptions: res, isLoading: false});
        })
    }

    render() {
        let that = this;
        return (
          <Stack verticalFill horizontalAlign="stretch">
            {this.state.errorMessage && 
                <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={true}
                      onDismiss={(ev? : any) => {that.setState({errorMessage: ''})}}
                      dismissButtonAriaLabel="Close"
                  >
                  {this.state.errorMessage}
                </MessageBar>
            }
            <VirtualizedComboBox
                defaultSelectedKey={that.state.currentTenantId}
                label="Tenants"
                allowFreeform={false}
                autoComplete="on"
                options={that.state.tenantOptions}
                dropdownMaxWidth={200}
                useComboBoxAsMenuWidth
                styles={{ root: { maxWidth: '100%' } }}
                onChange={that.onTenantChanged}
            />
            <VirtualizedComboBox
                defaultSelectedKey={that.state.currentOrgId}
                label="Organizations"
                allowFreeform={false}
                autoComplete="on"
                options={that.state.orgOptions}
                dropdownMaxWidth={200}
                useComboBoxAsMenuWidth
                styles={{ root: { maxWidth: '100%' } }}
                onChange={that.onOrgChanged}
            />
            <VirtualizedComboBox
                defaultSelectedKey={that.state.currentUserId}
                label="Users"
                allowFreeform={false}
                autoComplete="on"
                options={that.state.userOptions}
                dropdownMaxWidth={200}
                useComboBoxAsMenuWidth
                styles={{ root: { maxWidth: '100%' } }}
                onChange={that.onUserChanged}
            />

            <PrimaryButton text="Save" style={{marginTop: '25px'}} onClick={this.onSave}></PrimaryButton>
          </Stack>)
    }

    private onSave = (evt?: any) : void =>{
        let that= this;
        let tenantId: string = '';
        if (!this.props.tenantId){
            let cs: ClientState = ClientState.CurrentState();
            tenantId = cs.UserProfile?.TerraNovaTenantId ?? '';
        }
        else{
            tenantId = this.props.tenantId;
        }
        if (!this.props.entityId || !this.props.entityName){
            return;
        }
        if (!this.state.currentTenantId){
            this.setState({errorMessage: 'Tenant is missing'});
            return;
        }
        if (!this.state.currentOrgId && this.state.orgOptions?.length > 0){
            this.setState({errorMessage: 'Organization is missing'});
            return;
        }
        if (!this.state.currentUserId && this.state.userOptions?.length > 0){
            this.setState({errorMessage: 'Owner is missing'});
            return;
        }
        this.setState({errorMessage: ''});

        let emh: EntityModelHelper = new EntityModelHelper();
        const xi: AssignToInfo = new AssignToInfo();
        xi.OrganizationId = this.state.currentOrgId;
        xi.TenantId = this.state.currentTenantId;
        xi.OwnerId = this.state.currentUserId;
        
        emh.assignRecordTo(tenantId, this.props.entityName!, this.props.entityId!, xi).then(function(res: any){
            that.setState({errorMessage: ''});
            that.props.refreshRecord(res);
        })
        .catch(function(error: any){
            that.setState({errorMessage: 'Failed to assign object: ' + error?.message});
        })
    }

    private onTenantChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        if (option && option.key){
            this.setState({currentTenantId: option.key.toString()});
            this.loadTenantOrgs(option.key.toString());
        }
    }
    private onOrgChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        if (option && option.key){
            this.setState({currentOrgId: option.key.toString()});
            this.loadTenantOrgUsers(this.state.currentTenantId, option.key.toString());
        }
    }
    private onUserChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void =>{
        if (option && option.key){
            this.setState({currentUserId: option.key.toString()});
        }
    }
}