import React from "react";


interface ScriptItemProps {
    scriptSource? : string;
}

interface ScriptItemState {}

export class ScriptItem extends React.Component<ScriptItemProps, ScriptItemState> {
    private mDynamicScriptId : string = 'ninthDynamicScript';
    constructor(props : ScriptItemProps){
        super(props);

        this.state = {

        }
    }

    componentDidMount(){

    }

    render() {
        // var scriptHtml = '<script id="' + this.mDynamicScriptId +  '" type="text/javascript">';
        // scriptHtml += (this.props.scriptSource? this.props.scriptSource : '');
        // scriptHtml += '</script';
        var xe : any = document.getElementById(this.mDynamicScriptId);
        if (xe){
            (xe as HTMLElement).remove();
        }
        
        var scriptNode = document.createElement("script");
        scriptNode.id = this.mDynamicScriptId;
        var an : any = document.createAttribute('type');
        an.value = 'text/javascript';
        scriptNode.setAttributeNode(an);
        //scriptNode.innerText = (this.props.scriptSource? this.props.scriptSource : '');
        if (this.props.scriptSource){
            
            try{
                //debugger;
                var tn : Text = document.createTextNode('');
                tn.nodeValue = (this.props.scriptSource? this.props.scriptSource : '');
                scriptNode.appendChild(tn);
                document.head.appendChild(scriptNode);
            }
            catch(error : any){
                console.log(error.message);
            }
        }
        return (<></>);
        // var bScriptFound : boolean = false;
        // var head : HTMLCollectionOf<HTMLHeadElement> = document.getElementsByTagName('head');
        // if (head && head.length> 0){
        //     var hd: HTMLHeadElement = head[0];
        //     if (hd.childNodes){
        //         for(var i = 0; i<hd.childNodes.length; i++){
        //             var ch : ChildNode = hd.childNodes[i];
        //             if (ch.nodeName?.toLowerCase() === 'script') {
        //                 var jse : HTMLScriptElement = ch as HTMLScriptElement;
        //                 if (jse.id === this.mDynamicScriptId){
        //                     bScriptFound = true;
        //                     jse.innerText = this.props.scriptSource ? this.props.scriptSource! : '';
                         
        //                 }
        //             }
        //             if (bScriptFound){
        //                 break;
        //             }

        //         }

        //         if (!bScriptFound){
                    
        //             var dse : HTMLScriptElement = hd.ownerDocument.createElement('script');
        //             dse.id = this.mDynamicScriptId;
        //             dse.innerText = this.props.scriptSource ? this.props.scriptSource! : '';
        //             hd.appendChild(dse);
        //         }

        //     }
        // }
        // return (
        //   <>
        //   </>)
    }
}