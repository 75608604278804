export class EntityFormResourceDefinition{
    public Id : string = '';
    public ApplicationId : string = '';
    public ApplicationName : string = '';
    public DisplayName : string = '';
    public EntityId : string = '';
    public FormId : string = '';
    public ViewName : string = '';
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;
    public IsActive : boolean = true;
    //Type of view: 0 = List, 1: Form, 3: Report, 4: Dashboard
    public ResourceName : string = '';
    public ResourceData: string = '';
    public ResourceExtension : string = '';
    public EntityName: string = '';
    public PublisherId: string = '';
    public SourceApplicationUniqueId: string = '';
    public AppResourceId? : string | null = null;
}