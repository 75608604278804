import { CommandBar, IButtonStyles, ICommandBarItemProps, IconButton, Panel, ProgressIndicator, SearchBox, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../../MetaModel/UI/Formlet";
import { DesignTimeModelHelper } from "../../../../Helpers/DesignTimeModelHelper";
import { EntityCustomPrivilegeDefinition } from "../../../Entities/EntityCustomPrivilegeDefinition";
import { EntityPrivilegeDefinition } from "../../../Entities/EntityPrivilegeDefinition";
import { SecurityRoleDefinition } from "../../../Entities/SecrityRole";
import { CustomPrivilegeSurfaceEditorControl } from "./CustomPrivilegeSurfaceEditor";
import { TNRolePrivilegeItemEditControl } from "./TNDesignerRolePrivilegeItemEditControl";

export interface TNDesignerRolePrivilegeEditProps {
    id : string;
    roleid : string;
    rolename : string;
    applicationid : string;
    applicationname : string;
    tenantUniqueName : string;
    issystemadmin : boolean;
    isreadonly: boolean;
    privilegesType : 'default' | 'custom';
}

export interface TNDesignerRolePrivilegeEditState  {
    errorMessage : string;
    issystemadmin : boolean;
    isloading : boolean;
    searchValue : string;
    cmdBarItems: ICommandBarItemProps[];
    showAddCustomPrivilegePanel: boolean;
    privEdit?: EntityCustomPrivilegeDefinition;
}


const styles = {
    button: {
      width: 16, height: 16,
      padding: 0,
    },
    icon: {
      width: 16, height: 16,
    },
  };


export class TNDesignerRolePrivilegeEditControl extends React.Component<TNDesignerRolePrivilegeEditProps, TNDesignerRolePrivilegeEditState> {
    private Privileges : Array<EntityPrivilegeDefinition> = new Array<EntityPrivilegeDefinition>();
    private CustomPrivileges2 : Array<EntityPrivilegeDefinition> = new Array<EntityPrivilegeDefinition>();
    private CustomPrivileges : Array<EntityCustomPrivilegeDefinition> = new Array<EntityCustomPrivilegeDefinition>();
    private appId : string = '';
    private appName : string = '';
    constructor(props : TNDesignerRolePrivilegeEditProps){
        super(props);
      
        this.state = {
            errorMessage : '',
            issystemadmin : this.props.issystemadmin,
            isloading : false,
            searchValue : '',
            cmdBarItems: new Array<ICommandBarItemProps>(),
            showAddCustomPrivilegePanel: false
        }

    }
    public componentDidMount(){
        var that = this;
        that.buildCommandBar();
        if (that.props.privilegesType === 'default'){
            that.loadDefaultRolePrivileges2();
            // that.loadDefaultRolePrivileges().then(function(res: any){
            //     that.forceUpdate();
            // })
        }
        else if (that.props.privilegesType === 'custom'){
            that.loadDefaultRolePrivileges2();
            //that.loadCustomRolePrivileges();
        }
    }

    private buildCommandBar = () : void => {
        let that = this;
        let items: Array<ICommandBarItemProps> = new Array<ICommandBarItemProps>();
        items.push({
            key: 'refresh',
            text: 'Refresh',
            iconOnly: true,
            iconProps: {iconName: 'Refresh'},
            onClick: (ev?: any) => {that.loadDefaultRolePrivileges2();}
        })
        if (that.props.privilegesType === 'custom'){
            items.push({
                key: 'add',
                text: 'Add Privilege',
                iconOnly: true,
                iconProps: {iconName: 'Add'},
                onClick: that.addCustomPrivilege
            })
        }

        that.setState({cmdBarItems: items})
    }
    private addCustomPrivilege = (ev?: any) : void => {
        let cp: EntityCustomPrivilegeDefinition = new EntityCustomPrivilegeDefinition();
        cp.ApplicationId = this.props.applicationid;
        cp.Level = 0;
        this.setState({privEdit: cp, showAddCustomPrivilegePanel: true});
    }

    private loadDefaultRolePrivileges2 = () : void =>{
        let that = this;
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        that.setState({isloading: true});
        mh.loadSecurityRolesPrivileges(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid)
            .then(function(res: SecurityRoleDefinition[]){
                if (res)
                    that.Privileges = new Array<EntityPrivilegeDefinition>();
                    res.forEach(function(roleDef: SecurityRoleDefinition, roleIdx: number){
                        if (roleDef && roleDef.Privileges){
                            roleDef.Privileges.forEach(function(privItem: any, privIdx: number){
                                let item: any = privItem;
                                if (item && item.Privilege && item.Privilege.EntityName && item.Privilege.IsDefault){
                                    let xp: EntityPrivilegeDefinition | undefined = that.Privileges.find(z => z.EntityName === item.Privilege.EntityName);
                                    if (!xp){
                                        xp = new EntityPrivilegeDefinition(item.Privilege.EntityId, item.Privilege.EntityName, item.Privilege.Application?.DisplayName, item.Privilege.Application?.Id);
                                        xp.EntityName = item.Privilege.EntityName;
                                        that.Privileges.push(xp);
                                    }
                                    var propName = item.Privilege.EventName + 'Level';
                                    (xp as any)[propName] = (isNaN(parseInt(item.PrivilegeLevel)) ? 0 : parseInt(item.PrivilegeLevel));
                                }
                                else if (item && item.Privilege && !item.Privilege.IsDefault){
                                    let xp: EntityCustomPrivilegeDefinition | undefined = that.CustomPrivileges.find(z => z.EventName === item.Privilege.EventName && (!item.Privilege.EntityName || item.Privilege.EntityName === z.EntityName )  );
                                    if (!xp){
                                        xp = new EntityCustomPrivilegeDefinition();
                                        xp.ApplicationId = item.Privilege.ApplicationId;
                                        xp.ApplicationName = item.Privilege.ApplicationName;
                                        xp.EntityName = item.Privilege.EntityName;
                                        xp.EventName= item.Privilege.EventName;
                                        xp.PrivilegeId= item.Privilege.Id;
                                        xp.Level= item.PrivilegeLevel;
                                        that.CustomPrivileges.push(xp);
                                    }
                                }
                            })
                            that.Privileges.sort((a, b)=> a.EntityName < b.EntityName ? -1 : 1);
                            that.CustomPrivileges.sort((a, b)=> a.EventName < b.EventName ? -1 : 1);
                            that.setState({isloading : false});
                            that.forceUpdate();
                        }
                    })

                    
                    // res.forEach(function(item: any, idx: number){
                    //     if (item && item.Privilege && item.Privilege.EntityName && item.Privilege.IsDefault){
                    //         let xp: EntityPrivilegeDefinition | undefined = that.Privileges.find(z => z.EntityName === item.Privilege.EntityName);
                    //         if (!xp){
                    //             xp = new EntityPrivilegeDefinition(item.Privilege.EntityId, item.Privilege.EntityName, item.Privilege.Application?.DisplayName, item.Privilege.Application?.Id);
                    //             xp.EntityName = item.Privilege.EntityName;
                    //             that.Privileges.push(xp);
                    //         }
                    //         var propName = item.Privilege.EventName + 'Level';
                    //         (xp as any)[propName] = isNaN(parseInt(item.Level)) ? 0 : parseInt(item.Privilege.PrivilegeLevel);
                    //     }
                    // });
                    // mh.loadAllTenantPrivileges(that.props.tenantUniqueName)
                    //     .then(function(allRes: any[]){
                    //         allRes.forEach(function(xit: any, index: number){
                    //             let xp: EntityPrivilegeDefinition | undefined = that.Privileges.find(z => z.EntityName === xit.EntityName);
                    //             if (!xp){
                    //                 xp = new EntityPrivilegeDefinition(xit.EntityId, xit.EntityName, xit?.Application?.DisplayName, xit?.Application?.Id);
                    //                 xp.EntityName = xit.EntityName;
                    //                 that.Privileges.push(xp);
                    //             }
                    //             var propName = xit.EventName + 'Level';
                    //             //(xp as any)[propName] = isNaN(parseInt(item.Level)) ? 0 : parseInt(item.Privilege.PrivilegeLevel);
                    //         });

                    //         that.Privileges.sort((a, b) => a.ApplicationName >= b.ApplicationName ? ( a.ApplicationName > b.ApplicationName || (a.ApplicationName === b.ApplicationName && a.EntityName >= b.EntityName) ? 1 : -1) : -1)
                    //         that.setState({isloading : false});
                    //         that.forceUpdate();
                    //     })
                    //     .catch(function(xerror: any){
                    //         IllerisNinthUI.NotificationManager.showError('Error loading role privileges', xerror.message);
                    //             that.setState({errorMessage : xerror.message, isloading: false});
                    //     })
                    
            })
            .catch(function(error: any){
                IllerisNinthUI.NotificationManager.showError('Error loading role privileges', error.message);
                    that.setState({errorMessage : error.message, isloading: false});
            })
    }

    // private loadDefaultRolePrivileges = () : Promise<void> =>{
    //     var that = this;
    //     if (!this.appId){
    //         return Promise.resolve();
    //     }
    //     //var uri : string = "/odata/Runtime/EntityDefinition?$filter=ApplicationId eq " + this.appId + "&$orderby=DisplayName"
    //     var uri : string = "/odata/Runtime/EntityDefinition?$orderby=DisplayName&$expand=Application"
    //     var epm : EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantUniqueName);
    //     that.setState({isloading : true});
    //     that.Privileges = new Array<EntityPrivilegeDefinition>();

    //     if (!that.props.roleid || that.props.roleid === Guid.empty()){
    //         return Promise.resolve();
    //     }
    //     return epm.executeAPIAny(uri, null, 'GET',false)
    //         .then(function (res: any){
    //             if (res && res.values) {
    //                 for (var x = 0; x < res.values.length; x++) {
    //                     if (res.values[x].DisplayName) {
    //                         that.Privileges.push( new EntityPrivilegeDefinition(res.values[x].Id,res.values[x].DisplayName, res.values[x]['Application'] ? res.values[x]['Application']['DisplayName']?.toString() : that.appName , res.values[x].ApplicationId));
    //                     }
    //                 }
    //             }

    //             return epm.executeAPIAny("/odata/Runtime/SecurityRolePrivilegeDefinition?$filter=RoleId eq " + that.props.roleid + "&$expand=Privilege", null, 'GET', false)
    //             .then(function (response : any) {
    //                 if (response && response.values) {
    //                     for (var x = 0; x < response.values.length; x++) {
    //                         var it = response.values[x];
    //                         if (it.Privilege.EntityName) {
    //                             var xp : EntityPrivilegeDefinition |null | undefined = that.Privileges.find(z => z.EntityName === it.Privilege.EntityName);
    //                             if (xp){
    //                                 var propName = it.Privilege.EventName + 'Level';
    //                                 (xp as any)[propName] = isNaN(parseInt(it.Level)) ? 0 : parseInt(it.Level);
    //                             }
    //                         }
    //                     }
    //                 }

    //                 that.Privileges.sort((a, b) => a.ApplicationName >= b.ApplicationName ? ( a.ApplicationName > b.ApplicationName || (a.ApplicationName === b.ApplicationName && a.EntityName >= b.EntityName) ? 1 : -1) : -1)
    //                 that.setState({isloading : false});

    //             })
    //             .catch(function (error) {
    //                 IllerisNinthUI.NotificationManager.showError('Error loading role privileges', error.message);
    //                 that.setState({errorMessage : error.message, isloading: false});
    //             })
    //             .then(function () {
    //             });

    //         })
    //         .catch(function(err: any){
    //             IllerisNinthUI.NotificationManager.showError('Error loading role privileges', err.message);
    //             that.setState({errorMessage : err.message, isloading: false});
    //         })
    // }

    private loadCustomRolePrivileges = () : void =>{

        let that = this;
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        that.setState({isloading: true});
        mh.loadSecurityRolesCustomPrivileges(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid)
            .then(function(res: EntityCustomPrivilegeDefinition[]){
                if (res){
                    that.CustomPrivileges = new Array<EntityCustomPrivilegeDefinition>();
                    res.forEach(function(roleDef: EntityCustomPrivilegeDefinition, roleIdx: number){
                        that.CustomPrivileges.push(roleDef);
                    })
                }
                that.setState({isloading : false});
                that.forceUpdate();
            })
            .catch(function(error: any){
                IllerisNinthUI.NotificationManager.showError('Error loading role privileges', error.message);
                that.setState({errorMessage : error.message, isloading: false});
            })
       
    }

    private onClickEntityName = (e : any) : void =>{
        var targ: any = e.currentTarget;
        if (!this.props.isreadonly && targ && targ.dataset && targ.dataset.entityname){
            var en: string = targ.dataset.entityname;
            //var enid: string = targ.dataset.entityid;
            var ep : EntityPrivilegeDefinition |null | undefined = this.Privileges.find(z => z.EntityName === en);
            if (e.type === 'click') {
                e.preventDefault();
                if (ep){
                    ep.levelNext(this.props.issystemadmin);
                    //this.updatePrivileges(ep, en, enid);
                    this.updatePrivileges(ep);
                    this.forceUpdate();
                }
              } else if (e.type === 'contextmenu') {
                e.preventDefault();
                if (ep){
                    ep.levelPrevious(this.props.issystemadmin);
                    //this.updatePrivileges(ep, en, enid);
                    this.updatePrivileges(ep);
                    this.forceUpdate();
                }
              }
        }
    }
    //private updatePrivileges = ( ep : EntityPrivilegeDefinition, entityName: string, entityId : string) : void =>{
    private updatePrivileges = ( ep : EntityPrivilegeDefinition) : void =>{
        let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
        mh.updateRoleDefinitionSecurity(this.props.tenantUniqueName, this.props.applicationid, this.props.roleid, ep)
        //for(var i = 0; i< 10; i++){
            
            //PrivilegeHelper.setRolePrivilegeLevel(ep, i,ep.getPrivilegeName(i),entityName, entityId, this.props.roleid, this.props.rolename, this.props.tenantUniqueName );
        //}
        //
    }

    public render(){
        var that = this;
        //var idStr : string = 'tnOwerEditRow';
        var groupedList : any = {};
        var itemList : Array<string> = new Array<string>();
        if (this.props.privilegesType === 'default'){
            for(var z = 0; z<this.Privileges.length; z++){
                var ep : EntityPrivilegeDefinition = this.Privileges[z];
                if (!groupedList[ep.ApplicationName]){
                    groupedList[ep.ApplicationName] = new Array<EntityPrivilegeDefinition>();
                    itemList.push(ep.ApplicationName);
                }
                if (!this.state.searchValue){
                    groupedList[ep.ApplicationName].push(ep);
                }
                else if (ep.EntityName?.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1){
                    groupedList[ep.ApplicationName].push(ep);
                }
            }
        }
        else {
            for(var tz = 0; tz<this.CustomPrivileges.length; tz++){
                var ecp : EntityCustomPrivilegeDefinition = this.CustomPrivileges[tz];
                if (!groupedList[ecp.ApplicationName]){
                    groupedList[ecp.ApplicationName] = new Array<EntityCustomPrivilegeDefinition>();
                    itemList.push(ecp.ApplicationName);
                }
                //groupedList[ecp.ApplicationName].push(ecp);
                if (!this.state.searchValue){
                    groupedList[ecp.ApplicationName].push(ecp);
                }
                else if (ecp.EntityName?.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1){
                    groupedList[ecp.ApplicationName].push(ecp);
                }
            }
        }

        const btnStyle : IButtonStyles = {
            root: {
                width: '16px',
                height: '16px',
                marginRight: '15px'
            },
            icon: {
                width: '16px',
                height: '16px',
            }

        }

        return (
            <Stack verticalFill horizontalAlign='stretch' style={{marginTop: '20px'}}>
                <CommandBar
                        items={this.state.cmdBarItems}
                        overflowButtonProps={{ ariaLabel: 'More commands' }}
                        //farItems={this.CmdBarFarItems}
                        
                        ariaLabel="Use left and right arrow keys to navigate between commands"
                        className='tn-flexgrid-commandbar'
                    />
            {this.state.isloading &&
                <ProgressIndicator label="Loading Privileges..." description="" />
            }
            <SearchBox placeholder="Search" onSearch={newValue => this.setState({searchValue : newValue?.toString() ?? ''}) } onClear={ (ev?: any) => {that.setState({searchValue: ''}); that.forceUpdate(); }}  />
            {this.props.privilegesType === 'default' &&
            
            <div className="ms-Grid" dir="ltr" style={{marginTop: '15px'}} id={this.props.id} >
                <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm2">Entity Name</div>
                            <div className="ms-Grid-col ms-sm1">Read</div>
                            <div className="ms-Grid-col ms-sm1">Create</div>
                            <div className="ms-Grid-col ms-sm1">Update</div>
                            <div className="ms-Grid-col ms-sm1">Delete</div>
                            <div className="ms-Grid-col ms-sm1">Assign</div>
                            <div className="ms-Grid-col ms-sm1">Share</div>
                            <div className="ms-Grid-col ms-sm1">Export</div>
                            <div className="ms-Grid-col ms-sm1">Import</div>
                            <div className="ms-Grid-col ms-sm1">Activate</div>
                            <div className="ms-Grid-col ms-sm1">Deactivate</div>
                </div>

                {
                itemList.map((item: string, index : number) => {
                    return (
                        <>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12" style={{textAlign: 'center', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                                    {item}  
                                </div>
                            </div>
                            
                            {
                                groupedList[item].map((groupitem: EntityPrivilegeDefinition, idx: number) => {
                                    return (<div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm2" style={{cursor: 'pointer'}} data-entityname={groupitem.EntityName} data-entityid={groupitem.Id} onClick={this.onClickEntityName}>
                                        <Stack horizontal horizontalAlign="stretch">
                                            <Stack.Item align="start">
                                                <IconButton iconProps={{iconName: 'CaretRight'}}   styles={btnStyle} onClick={this.onClickEntityName} data-entityname={groupitem.EntityName} data-entityid={groupitem.Id} ></IconButton>
                                            </Stack.Item>
                                            <Stack.Item>
                                            {groupitem.EntityName}
                                            </Stack.Item>
                                            
                                        </Stack>    
                                            
                                            
                                            
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={0}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={1}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={2}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName}
                                                privtype={'default'}
                                                entityId={groupitem.Id}  
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={3}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={4}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={5}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={6}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={7}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName}
                                                privtype={'default'} 
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={8}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'default'}
                                                entityId={groupitem.Id} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                priv={groupitem}
                                                itemIndex={9}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                    </div>)
                                })
                            }
                        </>
                    )
                })

                }
                {/* <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">A</div>
                    <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">B</div>
                </div> */}
            </div>
            }
            {this.props.privilegesType === 'custom' &&
                <div className="ms-Grid" dir="ltr" style={{marginTop: '15px'}}>
                    <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4">Entity Name</div>
                            <div className="ms-Grid-col ms-sm4">Privilege Name</div>
                            <div className="ms-Grid-col ms-sm4">Privilege Level</div>
                    </div>
                    {
                       itemList.map((item: string, index : number) => {
                        return (
                            <>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12" style={{textAlign: 'center', borderTop: '1px solid black', borderBottom: '1px solid black'}}>{item}</div>
                                </div>
                                {
                                    groupedList[item].map((groupitem: EntityCustomPrivilegeDefinition, idx: number) => {
                                        return (<div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm4" style={{cursor: 'pointer'}} data-entityname={groupitem.EntityName} data-entityid={groupitem.PrivilegeId} onClick={this.onClickEntityName}>{groupitem.EntityName}</div>
                                        <div className="ms-Grid-col ms-sm4">
                                        {groupitem.EventName}
                                        </div>
                                        <div className="ms-Grid-col ms-sm4">
                                            <TNRolePrivilegeItemEditControl roleid={this.props.roleid} 
                                                rolename={this.props.rolename} 
                                                entityName={groupitem.EntityName} 
                                                privtype={'custom'}
                                                entityId={groupitem.PrivilegeId} 
                                                applicationid={that.props.applicationid} 
                                                tenantUniqueName={this.props.tenantUniqueName}
                                                customPriv={groupitem}
                                                itemIndex={0}
                                                isreadonly={this.props.isreadonly}
                                                issystemadmin={this.props.issystemadmin}
                                                />
                                        </div>
                                        </div>)
                                    })
                                }
                            </>

                        );
                       })
                    }
                </div>
            }
            {that.state.showAddCustomPrivilegePanel && 
                <Panel isOpen={that.state.showAddCustomPrivilegePanel} 
                    headerText="Create/Edit Custom Privilege"
                    onDismiss={(ev? : any) => {that.setState({showAddCustomPrivilegePanel: false}); that.forceUpdate();}}
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel="Close"
                >
                    <CustomPrivilegeSurfaceEditorControl customPriv={this.state.privEdit!} tenantId={this.props.tenantUniqueName} appId={this.props.applicationid} isReadOnly={this.props.isreadonly} saveAndClose={this.saveAndClose}></CustomPrivilegeSurfaceEditorControl>
                </Panel>
                    
                
            }
            </Stack>
        );
    }

    private saveAndClose = (priv?: EntityCustomPrivilegeDefinition) : void => {
        let that = this;
        if (priv){
            let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
            mh.updatePrivilege(this.props.tenantUniqueName, this.props.applicationid, priv)
                .then(function(res: EntityCustomPrivilegeDefinition){
                    that.setState({showAddCustomPrivilegePanel: false});
                })
                .catch(function(err: any){
                    that.setState({errorMessage: err?.message});
                });
        }
    }
}
