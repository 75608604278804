import { DefaultButton, IStackTokens, Panel, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "./MetaModel/UI/Formlet";


export interface TNGridViewColumnEditorPanelProps {
    GridView? : IllerisNinthUI.GridView | null;
    IsOpen : boolean;
    setIsOpen? : (bIsOpen : boolean) => void;
}

export interface TNGridViewColumnEditorPanelState extends TNGridViewColumnEditorPanelProps {
    errorMessage? : string;

}

const tokens: IStackTokens = { childrenGap: 5 };

export class GridViewColumnEditorPanelControl extends React.Component<TNGridViewColumnEditorPanelProps, TNGridViewColumnEditorPanelState> {
    constructor(props: TNGridViewColumnEditorPanelProps) {
      super(props);
      this.state = {
        errorMessage : '',
        GridView : props.GridView,
        IsOpen: props.IsOpen
      }
  
    }
  
    componentDidMount() {
    }
    shouldComponentUpdate(nextProps: TNGridViewColumnEditorPanelProps, nextState: TNGridViewColumnEditorPanelState) {
        if (nextProps.IsOpen !== this.props.IsOpen){
            return true;
        }
        else{
            return false;
        }
        // if (nextProps.valStr !== this.props.valStr || nextProps.entityId !== this.props.entityId || nextProps.entityLogicalName !== this.props.entityLogicalName || nextProps.hsBindName !== this.props.hsBindName){
        //     debugger;
        //     return true;
        // }
        // else{
        //     return false;
        // }
    }
    componentDidUpdate(prevProps : TNGridViewColumnEditorPanelProps, prevState: TNGridViewColumnEditorPanelState){
        if (this.props.IsOpen !== prevProps.IsOpen){

        }
    }

    private dismissPanel = (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) : void =>{
        this.setState({IsOpen : false});
        if (this.props.setIsOpen){
            this.props.setIsOpen(false);
        }
    }

    private onHideColumnIndex = (ev : any) : void =>{
        var idx: number = Number.parseInt(ev.currentTarget.dataset.colidx);
        if (!isNaN(idx) && this.props.GridView && this.props.GridView.Columns){
            this.props.GridView.Columns[idx].IsVisible = false;
            this.forceUpdate();
        }
    }
    private onShowColumnIndex = (ev : any) : void =>{
        var idx: number = Number.parseInt(ev.currentTarget.dataset.colidx);
        if (!isNaN(idx) && this.props.GridView && this.props.GridView.Columns){
            this.props.GridView.Columns[idx].IsVisible = true;
            this.forceUpdate();
        }
    }
    render() {
        var that = this;
      return (
        <Panel
            headerText="Grid View Columns"
            isOpen={this.state.IsOpen}
            onDismiss={this.dismissPanel}
            closeButtonAriaLabel="Close"
        >
            <Stack verticalFill horizontalAlign='stretch' tokens={tokens}>
                <h3>Visible Columns</h3>
                {this.state.GridView && this.state.GridView.Columns && 
                    this.state.GridView.Columns.map(function(item: IllerisNinthUI.GridColumn, index: number){
                        if (item.IsVisible){
                            return (<Stack horizontal horizontalAlign='stretch'>
                                <DefaultButton iconProps={{iconName : 'PasswordField'}} data-colidx={index} style={{width: '180px'}} onClick={that.onHideColumnIndex} text={item.HeaderText} ></DefaultButton>
                            </Stack>)
                        }
                        else{
                            return <></>
                        }
                    })
                    
                }
                <h3 style={{marginTop:'25px'}}>Hidden Columns</h3>
                {this.state.GridView && this.state.GridView.Columns && 
                    this.state.GridView.Columns.map(function(item: IllerisNinthUI.GridColumn, index: number){
                        if (!item.IsVisible){
                            return (<Stack horizontal horizontalAlign='stretch'>
                                <DefaultButton iconProps={{iconName : 'PasswordField'}} data-colidx={index} style={{width: '180px'}} onClick={that.onShowColumnIndex} text={item.HeaderText}></DefaultButton>
                            </Stack>)
                        }
                        else{
                            return <></>
                        }
                    })
                    
                }
            </Stack>
        </Panel>

      );
    }
  }
  