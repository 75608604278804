
export class EntityPropertyDefinition{
    public EntityId : string = '';
    public Id : string = '';
    public DisplayName : string = '';
    public LogicalName : string = '';
    public DataType : number = 0;
    //public DataType : string = '';
    public DataTypeString : PropertyDataType = 'string';
    public TextFormat : number = 0;
    public TextFormatText : string = '';
    public Level : number = 0;
    public MaxLength? : number;
    public IsRequired : boolean = false;
    public Status? : number;
    public StatusReason? : number;
    public DeleteState? : number;
    public CreateDate? : Date;
    public LastModifiedDate? : Date;

    static parseDataType = (val : number) : PropertyDataType =>{
        if (val === 0){
            return 'string';
        }
        else if (val === 1){
            return 'number';
        }
        else if (val === 2){
            return 'double';
        }
        else if (val === 3){
            return 'datetime';
        }
        else if (val === 4){
            return 'guid';
        }
        else if (val === 5){
            return 'byte[]';
        }
        else if (val === 6){
            return 'text';
        }
        else if (val === 7){
            return 'choice';
        }
        else if (val === 8){
            return 'relation';
        }
        else if (val === 9){
            return 'image';
        }
        else if (val === 10){
            return 'boolean';
        }
        else if (val === 100){
            return 'timestamp';
        }
        else{
            return 'string';
        }
    }
    static parseTextFormat = (val : number) : PropertyTextFormatType => {
        if (val === 0){
            return 'text';
        }
        else if (val === 1){
            return 'email';
        }
        else if (val === 2){
            return 'url';
        }
        else if (val === 3){
            return 'phone';
        }
        else if (val === 4){
            return 'multiline';
        }
        else if (val === 5){
            return 'html';
        }
        else if (val === 6){
            return 'json';
        }
        else{
            return 'text';
        }
    }
    public static getOwnershipLevel = (prop: EntityPropertyDefinition) : ItemOwnershipLevel => {
        return prop.Level as ItemOwnershipLevel;
    }
    public setOwnershipLevel = (prop: EntityPropertyDefinition, lev: ItemOwnershipLevel) : void => {
        prop.Level = lev as number;
    }
}

export type PropertyDataType = 'string' | 'number' | 'datetime' | 'double' | 'byte[]' | 'guid' | 'text' | 'choice' | 'relation' | 'timestamp' | 'image' | 'boolean';
export type PropertyTextFormatType = 'text' | 'email' | 'url' | 'phone' | 'multiline' | 'html' | 'json';

export enum ItemOwnershipLevel
{
    System = 0,
    Platform = 10,
    ISV = 100,
    Extension = 110,
    EndUser = 1000
}