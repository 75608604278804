import { deleteDB, IDBPDatabase, IDBPObjectStore, IDBPTransaction, openDB } from "idb";
import { nanoid } from "nanoid";
import { Logger } from "../Logger";

const mClientDbRequest = indexedDB.open('terranova', 1);
// mClientDbRequest.onupgradeneeded = () => {
//     const database = mClientDbRequest.result
//     database.createObjectStore('identity', {autoIncrement: true, keyPath: 'Id'})
//     database.createObjectStore('tenant', {autoIncrement: true, keyPath: 'Id'})
//     database.createObjectStore('organization', {autoIncrement: true, keyPath: 'Id'})
// }
mClientDbRequest.onerror = function(event: any) {
    // Do something with request.errorCode!
    Logger.logError(`DB ERROR Code: ${event?.target?.errorCode}`);
    Logger.logError(`DB ERROR : ${JSON.stringify(event)}`);
};
mClientDbRequest.onsuccess = function(event: Event) {
    // Do something with request.result!
};

class ClientRepository {
    private RepositoryNames : string[];
    private RepositoryCache : any;
    private RepositoryVersion : number = 1;

    constructor(){
        this.RepositoryNames = new Array<string>();
        this.RepositoryCache = {};
    }   

    public containsRepository = (name: string) : boolean => {
        return this.RepositoryNames.includes(name);
    }

    // public getRepository = (name: string) : IDBOpenDBRequest => {
    //     if (!this.containsRepository(name)){
    //         const cdb = indexedDB.open(`terranova_${name}`, 1);
    //         cdb.onerror = function(event: any) {
    //             // Do something with request.errorCode!
    //             Logger.logError(`DB '${name}' ERROR Code: ${event?.target?.errorCode}`);
    //             Logger.logError(`DB '${name}' ERROR : ${JSON.stringify(event)}`);
    //         };
    //         this.RepositoryNames.push(name);
    //         this.RepositoryCache[name] = cdb;
    //         return cdb;
    //     }
    //     else{
    //         return this.RepositoryCache[name] as IDBOpenDBRequest;
    //     }
    // }

    public deleteDb = async (name: string) : Promise<void> => {
        return deleteDB(`terranova_${name}`)
            .then(function(res: void){
                return Promise.resolve(res);
            })
            .catch(function(err: any){
                return Promise.reject(err);
            })
    }

    public getRepository = async (name: string, storeNames: string[] | null) : Promise<IDBPDatabase<unknown>> => {
        if (!this.containsRepository(name)){
            const cdb = await openDB(`terranova_${name}`, 1, {
                upgrade(db) {
                    db.createObjectStore('cacheindex', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('indentity', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('tenant', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('organization', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('entitydefinition', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('entityformdefinition', {autoIncrement: true, keyPath: 'Id'});
                    db.createObjectStore('entityviewdefinition', {autoIncrement: true, keyPath: 'Id'});
                    if (storeNames){
                        storeNames.forEach(function(sname: string, idx: number){
                            if (!db.objectStoreNames.contains(sname)){
                                db.createObjectStore(sname, {autoIncrement: true, keyPath: 'Id'});
                            }
                        });
                    }
                }}
            );
            cdb.onerror = function(event: any) {
                // Do something with request.errorCode!
                Logger.logError(`DB '${name}' ERROR Code: ${event?.target?.errorCode}`);
                Logger.logError(`DB '${name}' ERROR : ${JSON.stringify(event)}`);
            };
            this.RepositoryNames.push(name);
            this.RepositoryCache[name] = cdb;
            return Promise.resolve(cdb);
        }
        else{
            return Promise.resolve(this.RepositoryCache[name] as IDBPDatabase<unknown>);
        }
    }

    public getStoreItemTimeStamp = (itemName: string) : Promise<CacheRepositoryItemTimeStamp | null> => {
        return this.getRepository('cacheindex', null)
            .then(function(openRes: IDBPDatabase<unknown>){
                let tx : IDBPTransaction<unknown, ['cacheindex'], "readwrite">  = openRes.transaction('cacheindex', 'readwrite');
                if (tx){
                    const store : IDBPObjectStore<unknown, ['cacheindex'], 'cacheindex', "readwrite"> = tx.objectStore('cacheindex');
                    return store.getAll().then(function (res: any[]){
                        if (res && res.length > 0 && res.some(z => z.EntityName === itemName)){
                            let it: CacheRepositoryItemTimeStamp = res.find(z => z.EntityName === itemName) as CacheRepositoryItemTimeStamp;
                            if (it){
                                return Promise.resolve(it);
                            }
                        }
                        return Promise.resolve(null);
                    })
                }
                else{
                    return Promise.resolve(null);
                }
            }
        )
    }

    public setStoreItemTimeStamp = (itemName: string) : Promise<CacheRepositoryItemTimeStamp | null> => {
        return this.getRepository('cacheindex', null)
            .then(function(openRes: IDBPDatabase<unknown>){
                let tx : IDBPTransaction<unknown, ['cacheindex'], "readwrite">  = openRes.transaction('cacheindex', 'readwrite');
                if (tx){
                    const store : IDBPObjectStore<unknown, ['cacheindex'], 'cacheindex', "readwrite"> = tx.objectStore('cacheindex');
                    return store.getAll().then(function (res: any[]){
                        if (res && res.length > 0 && res.some(z => z.EntityName === itemName)){
                            let it: CacheRepositoryItemTimeStamp = res.find(z => z.EntityName === itemName) as CacheRepositoryItemTimeStamp;
                            it.LastModifiedDate = new Date();
                            return store.put(it)
                                .then(function(pres: IDBValidKey){
                                    return Promise.resolve(it);
                                })
                        }
                        return Promise.resolve(null);
                    })
                }
                else{
                    return Promise.resolve(null);
                }
            }
        )
    }
}

const mCurrentRepository: ClientRepository = new ClientRepository();

    

export class ClientLookupCache {
    private mDbName: string = '';
    private mDbVersion: number = 1;
    constructor(){
        this.mDbName = 'terranova';
        this.mDbVersion = 1;
        // See: https://www.w3.org/TR/IndexedDB/
    }

    public deleteDb = async (name: string) : Promise<void> => {
        return mCurrentRepository.deleteDb(name);
    }

    public addData = (tenantId: string, itemKey: string, items: any[]) : Promise<boolean> => {
        return mCurrentRepository.getRepository(tenantId, [itemKey?.toLowerCase()])
            .then(function(openRes: IDBPDatabase<unknown>){
                // if (!openRes.objectStoreNames.contains(itemKey)){
                //     openRes.createObjectStore(itemKey, {autoIncrement: true, keyPath: 'Id'});
                // }
                let tx : IDBPTransaction<unknown, [string], "readwrite">  = openRes.transaction(itemKey?.toLowerCase(), 'readwrite');
                if (tx){
                    const store : IDBPObjectStore<unknown, [string], string, "readwrite"> = tx.objectStore(itemKey?.toLowerCase());
                    if (items){
                        items.forEach(function(it: any, idx: number){
                            store.put(it);
                        })
                    }
                    tx.oncomplete = function() {
                        // All requests have succeeded and the transaction has committed.
                    };
                    return mCurrentRepository.setStoreItemTimeStamp(itemKey?.toLowerCase())
                        .then(function(tsRes: CacheRepositoryItemTimeStamp | null){
                            return Promise.resolve(true);
                        })

                    

                }
                else{
                    return Promise.resolve(false);
                }
            })
    }

    public getData = async (tenantId: string,itemKey: string) : Promise<any[]> => {
        return mCurrentRepository.getStoreItemTimeStamp(itemKey?.toLowerCase())
            .then(function(tsRes: CacheRepositoryItemTimeStamp | null){
                let yesterDay: Date = new Date(Date.now() - (24 * 1000 * 60 * 60));
                if (tsRes && tsRes.LastModifiedDate < yesterDay){
                    return Promise.resolve(new Array<any>());
                }

                return mCurrentRepository.getRepository(tenantId, [itemKey?.toLowerCase()])
                    .then(function(openRes: IDBPDatabase<unknown>){
                        if (!openRes.objectStoreNames.contains(itemKey?.toLowerCase())){
                            try{
                                openRes.createObjectStore(itemKey?.toLowerCase(), {autoIncrement: true, keyPath: 'Id'});
                            }
                            catch(err: any){
                                return Promise.reject(err);
                            }
                        }
                        let tx : IDBPTransaction<unknown, [string], "readwrite">  = openRes.transaction(itemKey?.toLowerCase(), 'readwrite');
                        if (tx){
                            const store : IDBPObjectStore<unknown, [string], string, "readwrite"> = tx.objectStore(itemKey?.toLowerCase());
                            return store.getAll().then(function (res: any[]){
                                return Promise.resolve(res);
                            })
                        }
                        else{
                            return Promise.resolve(new Array<any>());
                        }
                    })
            })
        
    }
    
}

export class CacheRepositoryItemTimeStamp {
    public Id: string = nanoid();
    public EntityName: string = '';
    public LastModifiedDate: Date = new Date();

    constructor(entName: string){
        this.EntityName = entName;
    }
}