export class ProductVolumePrice{
    public MinVolume: number = 0.0;
    public MaxVolume: number = 0.0;
    public Amount?: number;
    public DiscountPercentage?: number;

}


export class ProductPeriod{
    public MinMonths: number = 1;
    public MaxMonths: number = 1;
    public VolumePrices: ProductVolumePrice[];
    constructor(){
        this.VolumePrices = new Array<ProductVolumePrice>();
    }

}



export class ProductRecurringPrice{
    public RecurrencePeriod: string = 'M';
    public RecurrenceValue: number = 3;
    public Name: string = '';
    public Description: string = '';
    public UniqueCode: string = '';
    public Order: number = 0;
    public FixedPricePart?: number;
    public Volumes: ProductVolumePrice[] = new Array<ProductVolumePrice>();

}

export class ProductPriceMatrix {
    public PriceModel: number = 0;
    public Periods: ProductPeriod[];
    public Volumes: ProductVolumePrice[];
    public RecurringPrices: ProductRecurringPrice[];

    constructor(){
        this.Periods = new Array<ProductPeriod>();
        this.Volumes = new Array<ProductVolumePrice>();
        this.RecurringPrices = new Array<ProductRecurringPrice>();
    }
}


export class ProductPriceScheme {
    public Id: string = '';
    public DisplayName: string = '';
    public ValidFrom?: Date;
    public ValidUntil?: Date;
    public Order: number = 0;
    public Status: number = 0;
    public StatusReason: number = 0;
    public StatusName: string = '';
    public StatusReasonName: string = '';
    public Type: number = 0; // 0 = default
    public Product?: any;
    public ProductId: string = '';
    public RecurringPrices: ProductRecurringPrice[];
    public VolumePrices: ProductVolumePrice[];
    public RecurringPriceData: string= '';
    public VolumePriceData: string= '';
    public SchemeType: number = 0;
    public LicensingType: number=  0;
    public PriceModel : number = 0;
    constructor(){
        this.RecurringPrices = new Array<ProductRecurringPrice>();
        this.VolumePrices = new Array<ProductVolumePrice>();
    }
}

export class ProductPriceData{
    public Id: string = '';
    public DisplayName: string = '';
    public Product?: any;
    public ProductId: string = '';
    public SchemeType: number = 0;
    public LicensingType: number=  0;
    public PriceModel : number = 0;
    public PriceSchemes : ProductPriceScheme[];
    constructor(){
        this.PriceSchemes = new Array<ProductPriceScheme>();
    }
}