import { IContextualMenuItem, Stack } from "@fluentui/react";
import React from "react";
import { NavigationInfo} from "./NavigationInfo";
import { TenantInfo } from "./designers/Entities/TenantInfo";
import { SystemApplicationInfo } from "./designers/Entities/SystemApplicationInfo";
import { EntityDefinition } from "./designers/Entities/EntityDefinition";
import { EntityViewDefinition } from "./designers/Entities/EntityView";
import { EntityFormDefinition, EntityFormDefinition2 } from "./designers/Entities/EntityForm";
import { EntityModelHelper } from "./designers/EntityModelHelper";
import { TNDesignTimeEntityViewDesignerControl } from "./designers/DesignSurfaces/DesignTimeEntityViewDesigner";
import { TNDesignTimeEntityFormDesignerControl } from "./designers/DesignSurfaces/DesignTimeEntityFormDesigner";

interface ItemDesignerPageProps {
  tenantInfo? : TenantInfo;
  appInfo : SystemApplicationInfo;
  entInfo? : EntityDefinition;
  surface : 'view' | 'form';
  id? : string;
  view? : EntityViewDefinition;
  form? : EntityFormDefinition2;
  tenantId?: string;
  appName? : string;
  close: () => void;
  //entityName? : string;
}

interface ItemDesignerPageState extends ItemDesignerPageProps {
  StateKey : number;
  errorMessage? : string;
}

export class ItemDesignerPage extends React.Component<ItemDesignerPageProps, ItemDesignerPageState> {
  constructor(props: ItemDesignerPageProps) {
    super(props);
    this.state = {
      appInfo : props.appInfo,
      tenantInfo : props.tenantInfo,
      entInfo : props.entInfo,
      view: props.view,
      form: props.form,
      surface: props.surface,
      id : props.id,
      StateKey : 0,
      close: props.close
    }

    this.onToggleExpandClicked.bind(this);
    this.onPageRedirectRequested.bind(this);
  }

  componentDidMount() {
    //TODO : load tenant, entity, app
    var that = this;
    let eh : EntityModelHelper = new EntityModelHelper();
    if (!this.props.tenantInfo){
      eh.loadTenants().then(function(res: Array<TenantInfo>){
        let ti : TenantInfo | undefined = res.find(z => z.Id === that.props.tenantId || z.UniqueName === that.props.tenantId);
        if (ti){
          that.setState({tenantInfo : ti, StateKey : Math.random()});
          that.forceUpdate();
        }
      })
    }
    if (!this.props.appInfo){
      eh.loadSolutionsInTenant(this.props.tenantId).then(function(res: Array<SystemApplicationInfo>){
        let ap : SystemApplicationInfo | undefined = res.find(z => z.DisplayName === that.props.appName);
        if (ap){
          that.setState({appInfo : ap, StateKey : Math.random()});
          that.forceUpdate();

          if (!that.props.entInfo && (that.props.tenantId || that.props.tenantInfo)){
            eh.loadEntitiesInModel(that.props.tenantId ?? that.props.tenantInfo!.Id, ap.Id).then(function (ares: Array<EntityDefinition>){
              let ent : EntityDefinition | undefined = ares.find(z => z.DisplayName === that.props.entInfo?.LogicalName);
              if (ent){
                that.setState({entInfo : ent, StateKey: Math.random()});
                that.forceUpdate();
              }
            });
          }
        }
      })
    }
    else if (that.props.appInfo && !that.props.entInfo ){
      eh.loadEntitiesInModel(that.props.tenantId ?? that.props.tenantInfo!.Id, that.props.appInfo.Id).then(function (ares: Array<EntityDefinition>){
        let ent : EntityDefinition | undefined = ares.find(z => z.DisplayName === that.props.entInfo?.LogicalName);
        if (ent){
          that.setState({entInfo : ent, StateKey: Math.random()});
          that.forceUpdate();
        }
      });
    }
  }
  private onToggleExpandClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) : boolean | void => {
  }

  private onPageRedirectRequested = (info : NavigationInfo) : void =>{
   
  }
  private onCloseRequested = () : void => {
    this.props.close();
  }
  closeMessageBar = () => {
    this.setState({ errorMessage: '' });
  };
  render() {
    return (
        <Stack verticalFill horizontalAlign='stretch' className='body-panel'>
            {this.state.surface === 'view'  && this.state.entInfo && this.state.tenantInfo && this.state.StateKey > 0 && 
              <TNDesignTimeEntityViewDesignerControl 
                entInfo={this.state.entInfo} 
                tenantId={this.state.tenantInfo.Id} 
                viewItem={this.props.view}
                app={this.props.appInfo!}
              >
              </TNDesignTimeEntityViewDesignerControl>
            }
            {this.state.surface === 'form' && this.state.entInfo && this.state.tenantInfo && this.state.appInfo && this.state.StateKey > 0 && 
              <TNDesignTimeEntityFormDesignerControl 
                entInfo={this.state.entInfo} 
                tenantInfo={this.state.tenantInfo} 
                appInfo={this.state.appInfo} 
                formInfo={this.props.form} 
                close={this.onCloseRequested}
                >

              </TNDesignTimeEntityFormDesignerControl>
            }
        </Stack>
    );
  }
}
