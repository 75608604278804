import { AssignToInfo, EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityPropertyDefinition } from "./Entities/EntityProperty";
import { EntityRelationDefinition } from "./Entities/EntityRelation";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";

export class EntityModelHelper {


    public loadTenants = async () : Promise<Array<TenantInfo>> => {
        let uri : string = 'api/v1.0/terranova/metamodel/tenants';
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<TenantInfo> = new Array<TenantInfo>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        let ti : TenantInfo = new TenantInfo();
                        ti.Id = item.Id;
                        ti.DisplayName = item.DisplayName;
                        ti.UniqueName = item.UniqueName;
                        ti.IsActive = item.Status === 1;
                        titems.push(ti);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadUtilTenants = async () : Promise<Array<TenantInfo>> => {
        let uri : string = 'api/v2.0/terranova/xutil/tenants';
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<TenantInfo> = new Array<TenantInfo>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        let ti : TenantInfo = new TenantInfo();
                        ti.Id = item.Id;
                        ti.DisplayName = item.DisplayName;
                        ti.UniqueName = item.UniqueName;
                        ti.IsActive = item.Status === 1;
                        titems.push(ti);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadUtilTenantOrgs = async (tenantId: string) : Promise<Array<any>> => {
        let uri : string = `api/v2.0/terranova/xutil/${tenantId}/organizations`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<any> = new Array<any>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        titems.push(item);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadUtilTenantOrgUsers = async (tenantId: string, orgId: string) : Promise<Array<any>> => {
        let uri : string = `api/v2.0/terranova/xutil/${tenantId}/organizations/${orgId}/users`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<any> = new Array<any>();
                if (res && Array.isArray(res)){
                    res.forEach(function(item : any, idx: number){
                        titems.push(item);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }
    public loadSolutionsInTenant = async (tenantId? : string) : Promise<Array<SystemApplicationInfo>> => {
        let uri : string = `api/v1.0/terranova/metamodel/${tenantId}/apps`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<SystemApplicationInfo> = new Array<SystemApplicationInfo>();
                if (res && res.Values &&  Array.isArray(res.Values)){
                    res.Values.forEach(function(item : any, idx: number){
                        let ti : SystemApplicationInfo = new SystemApplicationInfo();
                        ti.Id = item.Id;
                        ti.DisplayName = item.DisplayName;
                        ti.AppCode = item.AppCode;
                        ti.ImageCSSClass = item.ImageCSSClass;
                        ti.Order= item.Order;
                        ti.ApplicationId= item.ApplicationId;
                        ti.TileName= item.TileName;
                        ti.IsActive = item.Status === 1;
                        titems.push(ti);
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }

    public loadEntitiesInModel = async (tenantId: string, appId : string) : Promise<Array<EntityDefinition>> => {
        let uri: string = `/api/v1.0/terranova/metamodel/${tenantId}/entities`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                let titems : Array<EntityDefinition> = new Array<EntityDefinition>();
                if (res && res.Values && Array.isArray(res.Values)){
                    res.Values.forEach(function(item : any, idx: number){
                        let it : EntityDefinition = new EntityDefinition();
                        it.Id = item.Id;
                        it.DisplayName = item.DisplayName;
                        it.ApplicationId= item.ApplicationId;
                        it.CreateDate= item.CreateDate;
                        it.ImageCSSClass= item.ImageCSSClass;
                        it.LastModifiedDate= item.LastModifiedDate;
                        it.LogicalName= item.LogicalName;
                        it.IsActive = item.Status === 1;
                        //if (it.ApplicationId === appId){
                            titems.push(it);
                        //}
                    });
                    return titems;
                }
                else{
                    return titems;
                }
            });
    }

    public loadEntityProperties = async (tenantId : string, entName : string | null = null) : Promise<Array<EntityPropertyDefinition>> =>{
        var viewUri = 'api/v1.0/terranova/metamodel/' + tenantId + '/' + (entName) + '/properties';
        var res : Array<EntityPropertyDefinition> = new Array<EntityPropertyDefinition>();

        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        return epm.executeAPI(viewUri, null, 'GET', false)
            .then(function(result: any){
                if (result && result.Values) {
                    result.Values.forEach(function(item : EntityPropertyDefinition, index : number){
                        res.push(item);
                    });
                    
                }
                return res;
            })
    }

    public loadEntityRelations = async (tenantId : string, entName : string | null = null) : Promise<Array<EntityRelationDefinition>> => {
        let uri: string = `/api/v1.0/terranova/metamodel/${tenantId}/${entName}/relations`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        let items : Array<EntityRelationDefinition> = new Array<EntityRelationDefinition>();
        return emp.executeAPIAny(uri, null, 'GET', false)
            .then(function(res : any){
                
                if (res && res.Values && Array.isArray(res.Values)){
                    res.Values.forEach(function(item : any, idx: number){
                        let it : EntityRelationDefinition = new EntityRelationDefinition();
                        it.Id = item.Id;
                        it.DisplayName = item.DisplayName;
                        it.EntityId= item.EntityId;
                        it.DeleteState= item.DeleteState;
                        it.Status= item.Status;
                        it.StatusReason= item.StatusReason;
                        it.TargetEntityId= item.TargetEntityId;
                        it.TargetEntityName = item.TargetEntityName;
                        it.CreateDate = item.CreateDate;
                        it.LastModifiedDate = item.LastModifiedDate;
                        items.push(it);    
                    });
                }
                return items;
            });
    }

    public assignRecordTo = async (tenantId: string, entName: string, entId: string, ui: AssignToInfo) : Promise<any> => {
        //"{tenantid}/{modelid}/{entityName}/{entityId:Guid}/assign"
        let uri: string = `/api/v2.0/terranova/xdata/${tenantId}/${entName}/${entId}/assign`;
        let emp : EntityPersistanceManager = new EntityPersistanceManager();
        return emp.executeAPIAny(uri, ui, 'POST', true)
            .then(function(res : any){
                return Promise.resolve(res.Value);
            });
    }
}