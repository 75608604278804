import { IconButton, Panel, Stack } from "@fluentui/react";
import React from "react";
import { SecurityRolePrivilegesSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/Security/SecurityRolePrivilegesSurfaceEditor";
import { SystemAppSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/SystemAppSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { SecurityRoleDefinition } from "./Entities/SecrityRole";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";
import { EntityDetailsViewControl } from "./EntityDetailsView";
import { SolutionContentControlEx } from "./SolutionContentControlEx";
import { TenantSolutionPickerControl } from "./TenantSolutionPicker";
import { NavigationBar } from "./Entities/NavigationBar";
import { NavBarItemsDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/NavBarItemsDefinitionSurfaceEditor";
import { AppResourcesViewControl } from "./AppResourcesView";

interface MainSolutionDesignerPageProps {
    //tenantid : string;
   //Tenant: TenantInfo;
}

interface MainSolutionDesignerPageState extends MainSolutionDesignerPageProps {
  StateKey : number;
  errorMessage? : string;
  currentSolutionName : string;
  currentEntity? : EntityDefinition | null;
  currentRole? : SecurityRoleDefinition | null;
  currentNavBar: NavigationBar | null;
  currentApplication? : SystemApplicationInfo;
  currentTenant? : TenantInfo;
  showAppEditPanel: boolean;
  showAppResourcesList: boolean;
  Tenant?: TenantInfo;
}

export class MainSolutionDesignerPage extends React.Component<MainSolutionDesignerPageProps, MainSolutionDesignerPageState> {
  constructor(props: MainSolutionDesignerPageProps) {
    super(props);
    this.state = {
      //tenantid : props.tenantid,
      currentEntity : null,
      currentNavBar: null,
      currentSolutionName : '',
      StateKey : 0,
      showAppEditPanel: false,
      showAppResourcesList: false,
      //Tenant: props.Tenant,
      Tenant: undefined,
    }

  }
  private setApplicationInfo = (appInfo : SystemApplicationInfo, tenantInfo : TenantInfo) : void =>{
    this.setState({currentApplication : appInfo, currentTenant : tenantInfo});
  }
  private showEntityDetails = (ent: EntityDefinition) : void => {
    debugger;
    this.setState({currentEntity : ent});
    this.forceUpdate();
  }
  private showRoleDetails = (role: SecurityRoleDefinition) : void => {
    this.setState({currentEntity : null, currentRole: role});
    this.forceUpdate();
  }
  private showNavBarDetails = (bar: NavigationBar) : void => {
    this.setState({currentEntity : null, currentNavBar: bar});
    this.forceUpdate();
  }
  private showAppResources = () : void => {
    this.setState({showAppResourcesList : true});
    this.forceUpdate();
  }
  
  componentDidMount() {
     
  }
  // private onEditEntityDetails = (ent: EntityDefinition) : void => {
  //   this.setState({s: true, currentEntity: ent});
  //   this.forceUpdate();
  // }
  closeMessageBar = () => {
    this.setState({ errorMessage: '' });
  };
  private showAppEditSideBar = () : void =>{
    this.setState({showAppEditPanel: true});
  }
  private dismissAppEditSideBar = () : void =>{
    this.setState({showAppEditPanel: false});
  }
  private closeAndSave = () : void =>{
    let that = this;
    let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
    eh.updateAppIntenant(this.state.Tenant?.Id ?? '', this.state.currentApplication!).then(function (res: SystemApplicationInfo){
      debugger;
      that.setState({currentApplication : res, showAppEditPanel: false, currentRole: null, currentEntity: null});
    })
    .catch(function (err: any){
      that.setState({errorMessage : err.message, showAppEditPanel: false, currentRole: null, currentEntity: null});
    })
  }
  private closeAndSaveNavBar = (bClose: boolean) : void =>{
    if (!this.state.currentNavBar){
      return;
    }
    let that = this;
    let eh : DesignTimeModelHelper = new DesignTimeModelHelper();
    eh.updateNavBar(this.state.Tenant?.Id ?? '', this.state.currentApplication!.Id, this.state.currentNavBar).then(function (res: NavigationBar){
      debugger;
      that.setState({currentNavBar : null, currentRole: null, currentEntity: null});
    })
    .catch(function (err: any){
      that.setState({errorMessage : err.message, currentRole: null, currentEntity: null});
    })
  }
  private onGoHome = (ev?: any) : void => {
    window.location.href = '/system/design';
  }

  private clearDetailsWindow = () : void => {
    this.setState({currentNavBar: null, currentEntity: null, currentRole : null, showAppResourcesList: false});
  }

  render() {
    let that = this;
    return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designer-page'>
            {!this.state.currentTenant &&
              <TenantSolutionPickerControl setApplication={this.setApplicationInfo} />
            }
            {this.state.currentTenant && this.state.currentApplication &&
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12" style={{textAlign: 'center'}} >
                        <h2>Application Content : {this.state.currentApplication?.DisplayName}
                          <IconButton iconProps={{iconName: 'Settings'}} title="Settings" ariaLabel="Settings" style={{marginLeft: '35px'}} onClick={this.showAppEditSideBar} />
                          <IconButton iconProps={{iconName: 'Home'}} title="Home" ariaLabel="Home" style={{marginLeft: '35px'}} onClick={this.onGoHome} />
                        </h2>
                        
        
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3">
                      <SolutionContentControlEx app={this.state.currentApplication} 
                              tenantId={this.state.currentTenant.Id!} 
                              showEntityDetails={this.showEntityDetails} 
                              showRoleDetails={this.showRoleDetails}
                              showNavBarDetails={this.showNavBarDetails}
                              showAppResources={this.showAppResources}
                              clear={this.clearDetailsWindow}
                      ></SolutionContentControlEx></div>
                    <div className="ms-Grid-col ms-sm9">
                      {this.state.currentEntity && this.state.currentTenant &&
                        <EntityDetailsViewControl 
                            ent={this.state.currentEntity} 
                            tenantInfo={this.state.currentTenant} 
                            app={this.state.currentApplication!} 
                            appName={this.state.currentApplication.AppCode} 
                           // showEntityDetails={that.onEditEntityDetails}
                        />
                      }
                      {this.state.currentRole && this.state.currentTenant &&
                        <SecurityRolePrivilegesSurfaceEditorControl 
                          role={this.state.currentRole} 
                          tenantId={this.state.currentTenant.Id} 
                          app={this.state.currentApplication!} 
                          isReadOnly={false}
                          closeAndSave={this.closeAndSave}
                          />
                      }
                      {this.state.currentNavBar && this.state.currentTenant &&
                        <NavBarItemsDefinitionSurfaceEditorControl 
                          navBar={this.state.currentNavBar} 
                          tenantId={this.state.currentTenant.Id} 
                          app={this.state.currentApplication!} 
                          isReadOnly={false}
                          save={this.closeAndSaveNavBar}
                          />
                      }
                      {this.state.showAppResourcesList &&
                        <AppResourcesViewControl 
                          app={this.state.currentApplication!} 
                          tenantInfo={this.state.currentTenant}
                          />
                      }
                    </div>
                </div>
              </div>
            }
            {this.state.showAppEditPanel && this.state.Tenant && 
              <Panel
                isOpen={this.state.showAppEditPanel}
                onDismiss={that.dismissAppEditSideBar}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Application"
              >
                <SystemAppSurfaceEditorControl 
                    app={this.state.currentApplication!} 
                    isReadOnly={false} 
                    TenantInfo={this.state.Tenant!}
                    closeAndSave={that.closeAndSave} />
              </Panel>
              }

          </Stack>
      );
    }
  }
