import { DefaultButton, DetailsList, DetailsListLayoutMode, FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IContextualMenuItem, IContextualMenuProps, Link, mergeStyleSets, MessageBar, MessageBarType, Modal, Panel, ProgressIndicator, SelectionMode, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import React from "react";
import { ItemDesignerPage } from "../ItemDesignerPage";
import { EntityFormDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityFormDefinitionSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityFormDefinition, EntityFormDefinition2 } from "./Entities/EntityForm";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";
import { TenantInfo } from "./Entities/TenantInfo";

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

interface EntityFormsViewControlProps {
    ent: EntityDefinition;
    appName : string;
    tenantInfo : TenantInfo;
    app: SystemApplicationInfo;
}

interface EntityFormsViewControlState extends EntityFormsViewControlProps {
  StateKey : number;
  errorMessage? : string;
  allForms : Array<EntityFormDefinition2>;
  columns : IColumn[];
  isbusy : boolean;
  showAddEditFormPanel: boolean;
  newForm?: EntityFormDefinition2;
  showEntityViewDesigner: boolean;
  currentEditableForm?: EntityFormDefinition2 | null;
}

export class EntityFormsViewControl extends React.Component<EntityFormsViewControlProps, EntityFormsViewControlState> {
    private RowMenu : IContextualMenuProps;
    private RowHeaderMenu : IContextualMenuProps;

    constructor(props: EntityFormsViewControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        ent : props.ent,
        tenantInfo : props.tenantInfo,
        allForms : new Array<EntityFormDefinition2>(),
        columns : new Array<IColumn>(),
        isbusy : false,
        appName : props.appName,
        showAddEditFormPanel: false,
        showEntityViewDesigner: false,
        app: props.app,
      }
  
      this.RowMenu = {
        items: [
          {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
          },
          {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
          },
        ],
      }
      this.RowHeaderMenu = {
        items: [
          {
            key: 'add',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => { this.addNewForm();},
          },
          {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => { this.forceUpdate();},
          },
        ],
      }
    }
  
    componentDidMount() {
      var that = this;
      this.loadViews().then(function(res: any){
        that.forceUpdate();
      });
      this.buildColumns();
    }
    private onShowFormDesigner = (vw : EntityFormDefinition2) : void =>{
      this.setState({currentEditableForm: vw, showEntityViewDesigner: true});
      this.forceUpdate();
    }
    private buildColumns = () : void =>{
        let cols : IColumn[] = new Array<IColumn>();
        cols.push(
          {
            key: 'editicon',
            name: 'Edit',
            ariaLabel: 'Edit',
            isIconOnly: true,
            fieldName: 'Id',
            minWidth: 32,
            maxWidth: 32,
            isRowHeader: true,
            isResizable: false,
            data: 'string',
            isPadded: true,
            onRender: (item: EntityFormDefinition2) => {
              return <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => {this.onShowFormDesigner(item);}}></IconButton>;
            },
          });
        cols.push(
            {
              key: 'coldisplayname',
              name: 'Display Name',
              ariaLabel: 'Display Name',
              isIconOnly: false,
              fieldName: 'DisplayName',
              minWidth: 80,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              // onRender: (item: EntityFormDefinition) => {
              //   return (<div style={{display: 'flex', flexDirection:'row'}}>
              //             <div>
              //               <Link>{item.DisplayName}</Link>
              //             </div>
              //             <div style={{display: 'flex', marginLeft: 'auto'}}>
              //             <CommandBarButton
              //               iconProps={{iconName : 'GripperDotsVertical'}}
              //               text=''
              //               // Set split=true to render a SplitButton instead of a regular button with a menu
              //               // split={true}
              //               menuProps={that.RowMenu}
              //             />
              //             </div>
              //           </div>)
              // },
              onRender: (item: EntityFormDefinition2) => {
                return <Link onClick={() => this.onEditForm(item)}>{item.DisplayName}</Link>;
              },
            });
        cols.push(
          {
            key: 'colViewType',
            name: 'View Type',
            ariaLabel: 'View Type Name',
            isIconOnly: false,
            fieldName: 'ViewDataTypeName',
            minWidth: 80,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this._onColumnClick,
          });
          cols.push(
            {
              key: 'colcreatedate',
              name: 'Create Date',
              ariaLabel: 'Create Date',
              isIconOnly: false,
              fieldName: 'CreateDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: EntityFormDefinition) => {
                return <span>{dayjs(item.CreateDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        cols.push(
            {
              key: 'colmoddate',
              name: 'Modified Date',
              ariaLabel: 'Modified Date',
              isIconOnly: false,
              fieldName: 'LastModifiedDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: EntityFormDefinition) => {
                return <span>{dayjs(item.LastModifiedDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        /*cols.push(
          {
            key: 'colbtns',
            name: 'Buttons',
            ariaLabel: 'Buttons',
            isIconOnly: false,
            //iconName: 'GripperDotsVertical',
            minWidth: 60,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'buttons',
            isPadded: true,
            onRender: (item: EntityFormDefinition) => {
              return (
                <CommandBarButton
                    iconProps={{iconName : 'GripperDotsVertical'}}
                    text=''
                    // Set split=true to render a SplitButton instead of a regular button with a menu
                    // split={true}
                    menuProps={that.RowMenu}
                />
              );
            },
            onRenderHeader: (item : IDetailsColumnProps | undefined) => {
              return (
                <CommandBarButton
                    iconProps={{iconName : 'GripperDotsVertical'}}
                    text=''
                    // Set split=true to render a SplitButton instead of a regular button with a menu
                    // split={true}
                    menuProps={that.RowHeaderMenu}
                />
              );
            },
            
          }); 
          cols.push(
            {
              key: 'colbtns2',
              name: 'Buttons2',
              ariaLabel: 'Buttons',
              isIconOnly: false,
              //iconName: 'GripperDotsVertical',
              minWidth: 60,
              maxWidth: 60,
              isRowHeader: true,
              isResizable: true,
              data: 'buttons',
              isPadded: true,
              onRender: (item: EntityFormDefinition) => {
                return (
                  <CommandBarButton
                      iconProps={{iconName : 'MoreVertical'}}
                      text=''
                      // Set split=true to render a SplitButton instead of a regular button with a menu
                      // split={true}
                      menuProps={that.RowMenu}
                  />
                );
              },
              onRenderHeader: (item : IDetailsColumnProps | undefined) => {
                return (
                  <>
                  <IconButton iconProps={{iconName: 'Add'}} title="Add" ariaLabel="Add" style={{cursor: 'pointer'}} onClick={ (ev: any) => this.addNewForm()} />
                  <IconButton iconProps={{iconName: 'Refresh'}} title="Refresh" ariaLabel="Refresh" onClick={ (ev: any) => this.forceUpdate()} style={{cursor: 'pointer'}} />
                  </>
                );
              },
              
            }); */
        this.setState({columns : cols});
    }

    private onEditForm = (item: EntityFormDefinition2) : void =>{
      this.setState({newForm: item, showAddEditFormPanel: true});
      this.forceUpdate();
    }
    private addNewForm = () : void => {
      let tenantId : string = this.props.tenantInfo.UniqueName;
      let appname : string = this.props.appName;
      let entName : string = this.props.ent.DisplayName;
      let uri : string = `/system/designer/${tenantId}/${appname}/${entName}/form`;
      window.open(uri, '_blanc');
    }

    private loadViews = async () : Promise<void> => {
      let mh: DesignTimeModelHelper = new DesignTimeModelHelper();
      var that = this;
      that.setState({isbusy : true});
      mh.loadEntityForms(that.props.tenantInfo?.Id, that.props.ent?.ApplicationId, that.props.ent.Id)
          .then(function(res : EntityFormDefinition2[]){
              if (res){
                res.forEach(function(xt: EntityFormDefinition2, index: number){
                  if (xt.ViewDataType === 0){
                    xt.ViewDataTypeName = 'List';
                  }
                  else if (xt.ViewDataType === 1){
                    xt.ViewDataTypeName = 'Form';
                  }
                  else if (xt.ViewDataType === 2){
                    xt.ViewDataTypeName = 'Report';
                  }
                  else if (xt.ViewDataType === 3){
                    xt.ViewDataTypeName = 'Dashboard';
                  }
                })
              }
              that.setState({isbusy : false, allForms: res, errorMessage: '', showAddEditFormPanel: false, newForm: undefined});
              that.forceUpdate();
          });
  }
    private _getKey = (item: any, index?: number): string => {
        return item.key;
    }
    private _onItemInvoked(item: any): void {
        alert(`Item invoked: ${item.name}`);
    }
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.state.allForms, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
          columns: newColumns,
          allForms: newItems,
        });
      
    }
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }    
    
    public shouldComponentUpdate = (nextProps: EntityFormsViewControlProps, nextState: EntityFormsViewControlState) : boolean =>{
        if (nextProps.tenantInfo.Id !== this.props.tenantInfo.Id || (nextProps.ent && this.props.ent && nextProps.ent.Id !== this.props.ent.Id )){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : EntityFormsViewControlProps, prevState: EntityFormsViewControlState){
        var that = this;
        if (prevProps.ent && this.props.ent && prevProps.ent.Id !== this.props.ent.Id){
            this.loadViews().then(function(res: any){
                that.forceUpdate();
              });
        }
    }

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-designer-propertiesview'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              {that.state.errorMessage &&
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  onDismiss={(ev?: any) => {that.setState({errorMessage: ''})}}
                  dismissButtonAriaLabel="Close"
                >
                 {that.state.errorMessage}
                </MessageBar>
              }
              <DetailsList
                items={this.state.allForms}
                compact={true}
                columns={this.state.columns}
                selectionMode={SelectionMode.none}
                getKey={this._getKey}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                //selection={this._selection}
                //selectionPreservedOnEmptyClick={true}
                onItemInvoked={this._onItemInvoked}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
            {this.state.showAddEditFormPanel  &&
              <Panel
                isOpen={this.state.showAddEditFormPanel}
                onDismiss={(ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => { that.setState({showAddEditFormPanel: false}); that.forceUpdate();} }
                
                closeButtonAriaLabel="Close"
                headerText="Add/Edit Form"
              >
                <EntityFormDefinitionSurfaceEditorControl ent={that.state.ent}  isReadOnly={false}  TenantId={that.props.tenantInfo?.Id} vw={that.state.newForm!} closeAndSave={that.saveAndCloseAddEntityForm}   />
              </Panel>
              }

              <Stack horizontal horizontalAlign='stretch'>
                <DefaultButton label='Add Form' text='Add Form' iconProps={{iconName: 'Add'}} onClick={this.onAddView} style={{width: '180px', marginTop: '20px'}} ></DefaultButton>
                <DefaultButton label='Refresh' text='Refresh' iconProps={{iconName: 'Refresh'}} onClick={(event: any) => that.refresh()} style={{width: '180px', marginTop: '20px', marginLeft: '25px'}} ></DefaultButton>
              </Stack>
              <Modal
              titleAriaId={'Edit Entity Form'}
              isOpen={this.state.showEntityViewDesigner}
              onDismiss={(ev?: any) => {that.setState({showEntityViewDesigner: false}); that.forceUpdate(); }}
              isBlocking={false}
              containerClassName={contentStyles.container}
              layerProps={{ styles: { root: { zIndex: 0 } } }}
              overlay={{ styles: { root: { zIndex: 0 } } }}
            >
              <div className={contentStyles.header}>
                <span id=''>Edit Entity View</span>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={{ iconName: 'Cancel' }}
                  ariaLabel="Close popup modal"
                  onClick={(ev?: any) => {that.setState({showEntityViewDesigner: false}); that.forceUpdate(); }}
                />
              </div>
              <div className={contentStyles.body}>
                <Stack verticalFill horizontalAlign="stretch">
                        <ItemDesignerPage surface='form' 
                            form={this.state.currentEditableForm!} 
                            appInfo={this.props.app} 
                            entInfo={this.props.ent}
                            tenantId={this.props.tenantInfo.Id}
                            close={() => {that.setState({showEntityViewDesigner: false}); that.forceUpdate();}}
                        ></ItemDesignerPage>
                      </Stack>
                </div>
            </Modal>
          </Stack>
      );
    }
    private refresh = () : void => {
      var that = this;
      this.loadViews().then(function(res: any){
        that.forceUpdate();
      });
    }
    private onAddView = (evt: any) => {
      let frm : EntityFormDefinition2 = new EntityFormDefinition2();
      frm.EntityId  = this.props.ent.Id;
      frm.EntityName  = this.props.ent.LogicalName;
      frm.ApplicationId= this.props.ent.ApplicationId;
      this.setState({newForm: frm, showAddEditFormPanel: true});
      this.forceUpdate();
    }
    private saveAndCloseAddEntityForm = () : void => {
      let that = this;
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (this.state.newForm && !this.state.newForm.Id){
        mh.createNewForm(this.props.tenantInfo?.Id, this.props.ent.ApplicationId, this.props.ent, this.state.newForm!).then(function(res: EntityFormDefinition2){
          let allProps : EntityFormDefinition2[] = that.state.allForms;
          allProps.push(res);
          that.setState({errorMessage: '', newForm: res, showAddEditFormPanel: false, allForms: allProps});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
      else{
        mh.updateEntityForm(this.props.tenantInfo?.Id, this.props.ent.ApplicationId, this.props.ent, this.state.newForm!).then(function(res: EntityFormDefinition2){
          /*let allProps : EntityFormDefinition2[] = that.state.allForms;
          //allProps.push(res);
          that.setState({errorMessage: '', newForm: res, showAddEditFormPanel: false, allForms: allProps});
          that.forceUpdate();*/
          that.refresh();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
    }
  }
  