import { DynamicEntity } from "../DynamicEntity";
import { EntityInfo, PageInfo } from "../PageInfo";
import { IllerisNinthAPI } from "../ServiceResult";
import { FormletView } from "./Editors/FormletEditors";

export interface IFormletContext {
    getPageInfo: () => PageInfo;
    getEntityInfo: () => EntityInfo;
    getEntity: () => DynamicEntity | undefined | null;
    getServiceResult: () => IllerisNinthAPI.ServiceResult  | undefined | null;

    addNew : () => void;
    editItem : (entName: string, entId: string, appName: string) => Promise<boolean>;
    removeItem : (entName: string, entId: string, appName: string) => Promise<boolean>;
}


export class FormletContext implements IFormletContext {
    private mView: FormletView;
    constructor(vw: FormletView){
        this.mView = vw;
    }

    public getPageInfo = () : PageInfo  => {
        return this.mView.props.PageInfo;
    }
    public getEntityInfo = () : EntityInfo  => {
        return this.mView.EntityInfo;
    }
    public getEntity = () : DynamicEntity | undefined | null  => {
        return this.mView.state.Entity;
    }
    public getServiceResult = () : IllerisNinthAPI.ServiceResult | undefined | null  => {
        return this.mView.state.ServiceResult;
    }

    public addNew = () : void  => {
        
    }
    public editItem = async (entName: string, entId: string, appName: string) : Promise<boolean>  => {
        return Promise.resolve(false);
    }
    public removeItem = async (entName: string, entId: string, appName: string) : Promise<boolean>  => {
        return this.mView.commandDeleteEntity(entName, entId, appName);
    }
}