import { SilentRequest } from "@azure/msal-browser";
import { ClientState } from "./ClientState";
import { IllerisNinthUI } from "./MetaModel/UI/Formlet";

import { Logger } from "./Logger";
import { MSALHelper } from "./Helpers/MSALHelper";
import { SessionStorageManager } from "./StorageManager";

export class XView{
    public EntityName : string = '';
    public ActionName : string = '';
    public ViewHtmlData : string = '';
    public ViewJSData : string = '';
    public Page? : IllerisNinthUI.TNPage;
    constructor(){
        this.EntityName = '';
    }

    public static getPage = (vw : XView | null | undefined) : IllerisNinthUI.TNPage | null =>{
        if (!vw)
            return null;
        var pg : IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
        pg.parseText(vw.ViewHtmlData);
        return pg;
    }
}
export class XPublicView{
    public ViewName : string = '';
    public ViewHtmlData : string = '';
    public ViewJSData : string = '';
    public ViewCSSInclude : string = '';
    public Page? : IllerisNinthUI.TNPage;
    constructor(){
        this.ViewName = '';
    }

    public static getPage = (vw : XView | null | undefined) : IllerisNinthUI.TNPage | null =>{
        if (!vw)
            return null;
        var pg : IllerisNinthUI.TNPage = new IllerisNinthUI.TNPage();
        pg.parseText(vw.ViewHtmlData);
        return pg;
    }
}

export class ViewManager{
    public Views : Array<XView> = new Array<XView>();
    public PublicViews : Array<XPublicView> = new Array<XPublicView>();
    public ApplicationName : string = '';
    public TenantName : string = '';
    constructor(appName : string, tenantName : string | null = null){
        this.ApplicationName = appName;
        if (tenantName){
            this.TenantName = tenantName;
        }
    }

    public clear = () : void => {
        SessionStorageManager.deleteStartingWith('__TN__APPVIEWS__');
    }
    public clearPublic = () : void => {
        SessionStorageManager.deleteStartingWith('__TN__PUBLICVIEWS__');
    }
    public load = async () : Promise<Array<XView>> =>{
        var that = this;
        var uri = '/api/v2.0/terranova/xview/views/' + this.TenantName + '/' + this.ApplicationName;
        var cs: ClientState = ClientState.CurrentState();
        if (!cs.IsAuthenticated)
            return this.Views;

        if (SessionStorageManager.containsItem('__TN__APPVIEWS__' + that.TenantName + '__' + that.ApplicationName + '__')){
            var its : Array<XView> | undefined = SessionStorageManager.getItemAsObject<Array<XView>>('__TN__APPVIEWS__'+ that.TenantName + '__' + that.ApplicationName + '__');
            if (its){
                that.Views = its;
            }
            return Promise.resolve(that.Views);
        }
        else{
            var cr : SilentRequest = MSALHelper.getDefaultScope();
            var mh : MSALHelper = new MSALHelper();
            return mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
                that.Views = res as Array<XView>;
                SessionStorageManager.setItem('__TN__APPVIEWS__'+ that.TenantName + '__' + that.ApplicationName + '__', JSON.stringify(that.Views));
                return that.Views;
    
            }).catch(function(error: any){
                Logger.logError('Failed to load views : ' + error.message);
                return Promise.reject(error);
            })
        }
        
    }
    public loadPublic = async () : Promise<Array<XPublicView>> =>{
        var that = this;
        var uri = '/api/v2.0/terranova/public/publicviews/' + this.TenantName + '/' + this.ApplicationName;

        var mh : MSALHelper = new MSALHelper();
        //debugger;

        if (SessionStorageManager.containsItem('__TN__PUBLICVIEWS__'+ that.TenantName + '__' + that.ApplicationName + '__')){
            let items : Array<XPublicView> | undefined = SessionStorageManager.getItemAsObject<Array<XPublicView>>('__TN__PUBLICVIEWS__'+ that.TenantName + '__' + that.ApplicationName + '__');
            if (items){
                that.PublicViews = items;
            }
            return Promise.resolve(that.PublicViews);
        }
        else{
            return mh.execAnonymousApiCallGet(uri).then(function(res : any){
                //debugger;
                that.PublicViews = res as Array<XPublicView>;
                SessionStorageManager.setItem('__TN__PUBLICVIEWS__'+ that.TenantName + '__' + that.ApplicationName + '__', JSON.stringify(that.PublicViews));
                return that.PublicViews;
    
            }).catch(function(error: any){
                Logger.logError('Failed to load views : ' + error.message);
                return Promise.reject(error);
            })
        }
        


        
    }

}