export class Logger {
    constructor() {

    }

    static logError(error: string): void {
        console.log('Ninth::ERROR - ' + new Date().toString() + ' - ' + error);
    }
    static logNotification(msg: string): void {
        console.log('Ninth::INFO - ' + new Date().toString() + ' - ' + msg);
    }
    static logNotificationObject(msg: any): void {
        console.log('Ninth::INFO - '  + new Date().toString() + ' - ' );
        console.log(msg);
    }
    static logNotificationJSON(obj: object): void {
        console.log('Ninth::INFO - ' + new Date().toString() + ' - ' );
        console.log(JSON.stringify(obj));
    }
    static logErrorObject(error: any): void{
        console.log('Ninth::ERROR - ' + new Date().toString() + ' - ' );
        console.log(error);
    }
    static logErrorJSON(error: any): void {
        console.log('Ninth::ERROR - ' + new Date().toString() + ' - ' + JSON.stringify(error));
    }
}