import {  CommandBar, IButtonProps, ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { takeRight } from "lodash";
import Notiflix from "notiflix";
import React from "react";
import { ProductPriceMatrix, ProductVolumePrice } from "../../designers/Entities/ProductPricing";

interface TNProductVolumePriceViewControlProps {
    PriceMatrix: ProductPriceMatrix;
    Price?: ProductVolumePrice;
    isReadOnly: boolean;
    isEmpty: boolean;
}

interface TNProductVolumePriceViewControlState extends TNProductVolumePriceViewControlProps {
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class TNProductVolumePriceViewControl extends React.Component<TNProductVolumePriceViewControlProps, TNProductVolumePriceViewControlState> {
    constructor(props: TNProductVolumePriceViewControlProps) {
      super(props);
      this.state = {
        isbusy : false,
        errorMessage : '',
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        isReadOnly : props.isReadOnly,
        notificationMessage: '',
        PriceMatrix: props.PriceMatrix,
        Price: props.Price,
        isEmpty: props.isEmpty
      }
  
    }
    componentDidMount() {
        this.buildCommandBar();
    }

    private buildCommandBar = () : void => {
      let mitems: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
      mitems.push({
          key: 'refresh',
          title: 'Refresh',
          iconOnly: true,
          iconProps: {iconName: 'Refresh'},
          onClick: this.onRefreshClicked,
      });
      mitems.push({
        key: 'add',
        title: 'Add',
        iconOnly: true,
        iconProps: {iconName: 'Add'},
        onClick: this.onAddClicked,
      });
      this.setState({cmdBarItems: mitems});
    }
    private onRefreshClicked = (ev?: any) : void => {
      this.forceUpdate();
    }
    private onAddClicked = (ev?: any) : void => {
        // let vp: ProductVolumePrice = new ProductVolumePrice();
        // this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
        // this.forceUpdate();
    }
      
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' style={{width: '100%', height: '30px'}}>
          {that.props.isEmpty &&
            <Stack horizontalAlign="stretch" horizontal style={{width: '100%', borderBottom: '1px solid black', marginBottom: '8px'}}>
              <Stack.Item align="start" style={{width: '70%'}}>
                Quantity
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                Unit Price
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                Discount Pct.
              </Stack.Item>
            </Stack>
          }
          {!that.props.isEmpty && that.state.Price &&
            <Stack horizontalAlign="stretch" horizontal style={{width: '100%'}}>
              <Stack.Item align="start" style={{width: '70%'}}>
                From {that.state.Price.MinVolume} to {that.state.Price.MaxVolume}
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                {that.state.Price.Amount?.toFixed(2)}
              </Stack.Item>
              <Stack.Item align="end" style={{width: '15%'}}>
                {that.state.Price.DiscountPercentage?.toFixed(2)}
              </Stack.Item>
            </Stack>
        
          }
        </Stack>
      );
    }

    
  }
  