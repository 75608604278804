import { ComboBox, CommandBar, getTheme, IComboBoxOption, ICommandBarItemProps, IconButton, Label, mergeStyleSets, MessageBar, MessageBarType, Panel, Stack} from "@fluentui/react";
import React from "react";
import { ProductPeriod, ProductPriceMatrix, ProductVolumePrice } from "../../designers/Entities/ProductPricing";
import { DynamicEntity } from "../../DynamicEntity";
import { PageInfo } from "../../PageInfo";
import { TNProductDurationPriceViewControl } from "./TNProductDurationPriceViewControl";
import { TNProductPeriodSurfaceEditorControl } from "./TNProductPeriodSurfaceEdit";
import { TNProductVolumePriceSurfaceEditorControl } from "./TNProductVolumePriceSurfaceEdit";
import { TNProductVolumePriceViewControl } from "./TNProductVolumePriceViewControl";

export interface TNProductPriceMatrixEditControlProps {
    tenantId: string;
    productId: string;
    PageInfo: PageInfo;
    Product: any;
    PriceMatrix: ProductPriceMatrix;
    isReadOnly: boolean;
    getEntity : () => DynamicEntity | null | undefined;
    updatePriceData: () => void;
}

export interface TNProductPriceMatrixEditControlState  {
    Product: any;
    errorMessage?: string;
    notificationMessage?: string;
    PriceMatrix: ProductPriceMatrix;
    PriceType: 'product' | 'subscription';
    barItems: ICommandBarItemProps[];
    editProductVolumePrice?: ProductVolumePrice;
    showProductVolumePriceEdit: boolean;
    editPeriod?: ProductPeriod;
    showProductPeriodEdit: boolean;
    priceModelOptions: IComboBoxOption[];
}

const theme = getTheme();
const classNames = mergeStyleSets({
  wrapper: {
    height: '40vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: '100%',
    border: '1px solid ' + theme.palette.neutralLight,
  },
  sticky: {
    color: theme.palette.neutralDark,
    padding: '5px 20px 5px 10px',
    fontSize: '13px',
    borderTop: '1px solid ' + theme.palette.black,
    borderBottom: '1px solid ' + theme.palette.black,
  },
  textContent: {
    padding: '15px 10px',
  },
});
  
export class TNProductPriceMatrixEditControl extends React.Component<TNProductPriceMatrixEditControlProps, TNProductPriceMatrixEditControlState> {
      constructor(props : TNProductPriceMatrixEditControlProps){
          super(props);
          
          let prodType: number = (this.props.Product && this.props.Product.UnitOfMeasure && this.props.Product.UnitOfMeasure) ? this.props.Product.UnitOfMeasure: 0;
          let po: IComboBoxOption[] = new Array<IComboBoxOption>();
          po.push({key: 0, text: 'Volume Tiered'});
          po.push({key: 10, text: 'Graduated Tiered'});

          this.state = {
            PriceType: (prodType === 2 ? 'subscription' : 'product'),
            Product: props.Product,
            PriceMatrix: props.PriceMatrix,
            barItems: new Array<ICommandBarItemProps>(),
            showProductVolumePriceEdit: false,
            showProductPeriodEdit: false,
            priceModelOptions: po
          }
      }

      
      private loadProduct = async (productId?: string) : Promise<void> => {
        let prodEnt : DynamicEntity | null | undefined = this.props.getEntity();
          if (prodEnt){
              let pm: ProductPriceMatrix = new ProductPriceMatrix();
              let pd: string = prodEnt.getValue('PriceData') ?? '';
              if (prodEnt && pd){
                 pm = JSON.parse(pd) as ProductPriceMatrix;
              }
              this.setState({Product: prodEnt.getEntity(), PriceMatrix: pm, PriceType: (prodEnt.getValue('UnitOfMeasure') === 2 ? 'subscription' : 'product')});
              this.forceUpdate();
          }
        //   let that = this;
        //   let epm: EntityPersistanceManager = new EntityPersistanceManager(this.props.tenantId);
        //   epm.getById(this.props.PageInfo.EntityName, this.props.productId)
        //       .then(function(res: IllerisNinthAPI.ServiceResult){
                
        //         let pd: string = res.Value.PriceData ?? '';
        //         let pm: ProductPriceMatrix = new ProductPriceMatrix();
        //         if (pd){
        //             pm = JSON.parse(pd) as ProductPriceMatrix;
        //         }


        //         that.setState({Product: res.Value, PriceMatrix: pm, PriceType: (res.Value?.UnitOfMeasure === 2 ? 'subscription' : 'product')});
        //         that.forceUpdate();
        //       })
        //       .catch(function(err: any){
        //           that.setState({errorMessage: err?.message});
        //       })
      }

      private buildCommandBar = () : void => {
          let mitems: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
          mitems.push({
              key: 'refresh',
              title: 'Refresh',
              iconOnly: true,
              iconProps: {iconName: 'Refresh'},
              onClick: this.onRefreshClicked,
          });
          mitems.push({
            key: 'add',
            title: 'Add',
            iconOnly: true,
            iconProps: {iconName: 'Add'},
            onClick: this.onAddClicked,
        });
        // mitems.push({
        //     key: 'save',
        //     title: 'Save',
        //     iconOnly: true,
        //     iconProps: {iconName: 'Save'},
        //     onClick: this.onSaveClicked,
        // });
        this.setState({barItems: mitems});
      }
      private onRefreshClicked = (ev?: any) : void => {
          this.forceUpdate();
      }
    //   private onSaveClicked = (ev?: any) : void => {
    //     let pm : ProductPriceMatrix = this.state.PriceMatrix;
    //     if (pm){
    //         let pmStr : string = JSON.stringify(pm);
    //         this.state.Product.PriceData = pmStr;
    //     }
    //     this.forceUpdate();
    //   }
      private onAddClicked = (ev?: any) : void => {
          if (this.state.PriceType === 'product'){
            let vp: ProductVolumePrice = new ProductVolumePrice();
            this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
            this.forceUpdate();
          }
          else if (this.state.PriceType === 'subscription'){
            let vp: ProductPeriod = new ProductPeriod();
            this.setState({editPeriod: vp, showProductPeriodEdit: true});
            this.forceUpdate();
          }
      }

      public componentDidMount(){
          this.loadProduct();
          this.buildCommandBar();
      }

      public shouldComponentUpdate(nextProps: TNProductPriceMatrixEditControlProps, nextState: TNProductPriceMatrixEditControlState){
        if (nextProps.productId !== this.props.productId) {
            return true;
        }
        else{
            return false;
        }
      }
      public componentDidUpdate(prevProps: TNProductPriceMatrixEditControlProps, prevState: TNProductPriceMatrixEditControlState){
        if (prevProps.productId !== this.props.productId){
            this.loadProduct();
        }
      }

      private updateAndCloseVolumePriceEdit = (price: ProductVolumePrice) : void => {
        let pm : ProductPriceMatrix = this.state.PriceMatrix;
        if (pm && !pm.Volumes.includes(price)){
            pm.Volumes.push(price);
        }
        this.props.updatePriceData();
        this.setState({PriceMatrix: pm, showProductVolumePriceEdit: false});
        this.forceUpdate();
      }
      private deleteVolumePriceItem = (price: ProductVolumePrice) : void => {
        let pm : ProductPriceMatrix = this.state.PriceMatrix;
        if (pm && pm.Volumes.includes(price)){
            pm.Volumes.splice(pm.Volumes.indexOf(price), 1);
        }
        this.props.updatePriceData();
        this.setState({PriceMatrix: pm, showProductVolumePriceEdit: false});
        this.forceUpdate();
      }
      private updateAndClosePeriodEdit = (per: ProductPeriod) : void => {
        let pm : ProductPriceMatrix = this.state.PriceMatrix;
        if (pm && !pm.Periods.includes(per)){
            pm.Periods.push(per);
        }
        this.props.updatePriceData();
        this.setState({PriceMatrix: pm, showProductPeriodEdit: false});
        this.forceUpdate();
      }
      private deletePeriodItem = (per: ProductPeriod) : void => {
        let pm : ProductPriceMatrix = this.state.PriceMatrix;
        if (pm && !pm.Periods.includes(per)){
            pm.Periods.splice(pm.Periods.indexOf(per), 1);
        }
        this.props.updatePriceData();
        this.setState({PriceMatrix: pm, showProductVolumePriceEdit: false});
        this.forceUpdate();
      }
      private editVolumePrice = (price:  ProductVolumePrice) : void => {
        let vp: ProductVolumePrice = price;
        this.setState({editProductVolumePrice: vp, showProductVolumePriceEdit: true});
        this.forceUpdate();
      }
      private editPeriod = (per: ProductPeriod) : void => {
        let vp: ProductPeriod = per;
        this.setState({editPeriod: vp, showProductPeriodEdit: true});
        this.forceUpdate();
      }
      public updatePriceModel = (ev?: any, option?: IComboBoxOption | undefined |null,  index?: number | undefined, value? : string | undefined) : void => {
        let pm : ProductPriceMatrix = this.state.PriceMatrix;
        if (pm){
            pm.PriceModel = option?.key as number ?? 0;
        }
        this.setState({PriceMatrix: pm});
        this.props.updatePriceData();
        this.forceUpdate();
      }
      public render(){
          var that = this;
         
          return (
              <Stack verticalFill horizontalAlign='stretch' style={{width: '100%'}}> 

                    <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                        <Stack.Item style={{width: '70%'}} align="start">
                            <Label>{that.state.PriceType === 'product' ? 'Product Quantity Pricing Lines' : 'Product Duration Pricing Lines'}</Label>
                        </Stack.Item>
                        <Stack.Item style={{width: '30%'}} align="end">
                            <CommandBar
                                items={this.state.barItems}
                                overflowButtonProps={{ ariaLabel: "More commands" }}
                                ariaLabel="Use left and right arrow keys to navigate between commands"
                            />        
                        </Stack.Item>
                    </Stack>
                <ComboBox options={this.state.priceModelOptions} 
                          selectedKey={this.state.PriceMatrix.PriceModel} 
                          label="Price Model" 
                          style={{maxWidth: '300px', marginBottom: '25px'}} 
                          onChange={that.updatePriceModel}
                ></ComboBox>
                  {that.state.PriceType === 'product' &&
                    <Stack verticalFill horizontalAlign='stretch' style={{width: '100%'}}>
                       
                        
                        {this.state.errorMessage && 
                            <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                    dismissButtonAriaLabel="Close"
                                >
                                {this.state.errorMessage}
                            </MessageBar>
                        }
                        {this.state.notificationMessage && 
                            <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                    dismissButtonAriaLabel="Close"
                                >
                                {this.state.notificationMessage}
                            </MessageBar>
                        }
                        
                        
                        {this.state.PriceMatrix && this.state.PriceMatrix.Volumes &&
                            <>
                            <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                                <Stack.Item style={{width: '90%'}}>
                                    <TNProductVolumePriceViewControl 
                                            isEmpty={true} 
                                            key={-1} 
                                            isReadOnly={that.props.isReadOnly} 
                                            PriceMatrix={that.state.PriceMatrix} 
                                    ></TNProductVolumePriceViewControl>
                                </Stack.Item>
                            </Stack>
                            
                            {this.state.PriceMatrix.Volumes.map(function(item: ProductVolumePrice, index: number){
                                return (
                                    <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                                        <Stack.Item style={{width:'90%'}}>
                                            <TNProductVolumePriceViewControl isEmpty={false} key={index} isReadOnly={that.props.isReadOnly} PriceMatrix={that.state.PriceMatrix} Price={item}
                                            ></TNProductVolumePriceViewControl>
                                        </Stack.Item>
                                        <Stack.Item style={{width:'10%'}}>
                                            <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => {that.editVolumePrice(item);}} />
                                        </Stack.Item>
                                    </Stack>);
                            })
                            }
                            </>
                        }
                        
                    </Stack>
                  }
                  {that.state.PriceType === 'subscription' &&
                    <Stack verticalFill horizontalAlign='stretch' style={{width: '100%'}}>
                        
                        {this.state.errorMessage && 
                            <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                                    dismissButtonAriaLabel="Close"
                                >
                                {this.state.errorMessage}
                            </MessageBar>
                        }
                        {this.state.notificationMessage && 
                            <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                                    dismissButtonAriaLabel="Close"
                                >
                                {this.state.notificationMessage}
                            </MessageBar>
                        }
                        {this.state.PriceMatrix && this.state.PriceMatrix.Periods &&
                            <>
                            <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                                <Stack.Item style={{width: '90%'}}>
                                    <TNProductDurationPriceViewControl 
                                        isEmpty={true} 
                                        key={-1} 
                                        isReadOnly={that.props.isReadOnly} 
                                        PriceMatrix={that.state.PriceMatrix} 
                                        updatePriceData={that.props.updatePriceData}
                                    ></TNProductDurationPriceViewControl>
                                </Stack.Item>
                            </Stack>
                            
                            {this.state.PriceMatrix.Periods.map(function(item: ProductPeriod, index: number){
                                return (
                                    <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
                                        <Stack.Item style={{width:'90%'}}>
                                            <TNProductDurationPriceViewControl 
                                                isEmpty={false} 
                                                key={index} 
                                                isReadOnly={that.props.isReadOnly} 
                                                PriceMatrix={that.state.PriceMatrix} 
                                                Period={item}
                                                updatePriceData={that.props.updatePriceData}
                                            ></TNProductDurationPriceViewControl>
                                        </Stack.Item>
                                        <Stack.Item style={{width:'10%'}}>
                                            <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => {that.editPeriod(item);}} />
                                        </Stack.Item>
                                    </Stack>);
                            })
                            }
                            </>
                        }
                        
                    </Stack>
                  }
                  {that.state.showProductVolumePriceEdit && that.state.editProductVolumePrice &&
                    <Panel
                            headerText="Edit Volume Price"
                            isOpen={this.state.showProductVolumePriceEdit}
                            onDismiss={(ev?: any) => {that.setState({showProductVolumePriceEdit: false}); that.forceUpdate(); }}
                            closeButtonAriaLabel="Close"
                        >
                            <TNProductVolumePriceSurfaceEditorControl 
                                Price={this.state.editProductVolumePrice!}
                                PriceMatrix={this.state.PriceMatrix}
                                isReadOnly={this.props.isReadOnly}
                                updateAndClose={this.updateAndCloseVolumePriceEdit}
                                deleteItem={this.deleteVolumePriceItem}
                            >

                            </TNProductVolumePriceSurfaceEditorControl>
                        </Panel>
                    }   
                    {that.state.showProductPeriodEdit && that.state.editPeriod &&
                    <Panel
                            headerText="Edit Period"
                            isOpen={this.state.showProductPeriodEdit}
                            onDismiss={(ev?: any) => {that.setState({showProductPeriodEdit: false}); that.forceUpdate(); }}
                            closeButtonAriaLabel="Close"
                        >
                            <TNProductPeriodSurfaceEditorControl 
                                Period={this.state.editPeriod!}
                                PriceMatrix={this.state.PriceMatrix}
                                isReadOnly={this.props.isReadOnly}
                                updateAndClose={this.updateAndClosePeriodEdit}
                                deleteItem={this.deletePeriodItem}
                            >

                            </TNProductPeriodSurfaceEditorControl>
                        </Panel>
                    }   
              </Stack>
          );
      }
  }