import { SilentRequest } from "@azure/msal-browser";
//import { appendFileSync } from "node:fs";
import { DynamicEntity } from "./DynamicEntity";
import { MSALHelper } from "./Helpers/MSALHelper";

export namespace IllerisNinthAPI{

    export interface EntitySecurityPrivilege {
        EntityAppCode?: any;
        EntityName: string;
        PrivilegeLevel: number;
        AllowCreate: boolean;
        AllowUpdate: boolean;
        AllowDelete: boolean;
        AllowActivate: boolean;
        AllowDeactivate: boolean;
        AllowView: boolean;
        AllowViewMinimal: boolean;
        AllowShare: boolean;
        AllowAssign: boolean;
        AllowImport: boolean;
        AllowExport: boolean;
        Privileges: string[];
        HiddenProperties: any[];
        HiddenRelations: any[];
    }

    export interface SecurityDescriptor {
        Items: EntitySecurityPrivilege[];
    }

    export interface ProtectionScheme {
        Id: string;
        Nr: number;
        ValStr?: any;
        Text: string;
    }

    export interface ApplicationLicenseType {
        Id: string;
        Nr: number;
        ValStr?: any;
        Text: string;
    }

    export interface Technology {
        Id: string;
        Nr: number;
        ValStr?: any;
        Text: string;
    }

    // export interface Pairs {
    //     ProtectionSchemes: ProtectionScheme[];
    //     ApplicationLicenseTypes: ApplicationLicenseType[];
    //     Technologies: Technology[];
    // }

    export interface OptionSets {
        Pairs: any;
    }

    export interface ValidationResult {
        IsOk: boolean;
        Errors: any[];
    }

    export interface Button {
        CommandName: string;
        Type: number;
        TypeStr: string;
        CommandText: string;
        ImageCSSClass: string;
        IsVisible: boolean;
        OnClickJScriptCode?: any;
        OnClickHandler?: any;
        ActionURI?: any;
        VisibleOnStateCodes: string;
        RedirectURI : string | undefined;
        Order? : number;
    }

    export interface ActionBar {
        IsAuthenticated: boolean;
        EntityName: string;
        ViewTypes: number;
        Buttons: Button[];
    }

    export interface ServiceResult {
        Type: string;
        EntityName: string;
        Value?: any;
        Values?: null | undefined | Array<any> ;
        SecurityDescriptor: SecurityDescriptor;
        UserRoles: string[];
        ErrorMessages: string[];
        OptionSets: OptionSets;
        HttpResultCode: number;
        RedirectURI?: any;
        ValidationResult: ValidationResult;
        ActionBar: ActionBar;
        LoadedTimeStamp : Date;
    }

    export type HttpMethodType = 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT';

    export class SecurityDescriptorHelper {
        private ServiceResult : ServiceResult | undefined;
        private IsValid : boolean = true;
        constructor(sr? : ServiceResult) {
            if (sr){
                this.ServiceResult = sr;
            }
            if (sr && typeof sr.Type !== 'undefined' && sr.Type.toLowerCase() === 'serviceresult')
                this.IsValid = true;
            else
                this.IsValid = false;
        }
    
        public getEntityPrivileges(entName : string) : EntitySecurityPrivilege | null {
            if (!this.IsValid)
                return null;
    
            if (!this.ServiceResult || !this.ServiceResult.SecurityDescriptor || !this.ServiceResult.SecurityDescriptor.Items)
                return null;
    
            var ep : EntitySecurityPrivilege | null =  null;
            for (var x = 0; x < this.ServiceResult.SecurityDescriptor.Items.length; x++) {
                var it = this.ServiceResult.SecurityDescriptor.Items[x];
                if (it && it.EntityName && it.EntityName.toLowerCase() === entName.toLowerCase()) {
                    ep = it;
                }
            }
            return ep;
        }
    
        public isAuthorized(entName : string | undefined, actName : string) : boolean {
            if (!entName)
                return false;
            if (!this.IsValid)
                return false;
            //debugger;
            if (!this.ServiceResult || !this.ServiceResult.SecurityDescriptor || !this.ServiceResult.SecurityDescriptor.Items)
                return false;
    
            var ep : EntitySecurityPrivilege | null =  null;
            for (var x = 0; x < this.ServiceResult.SecurityDescriptor.Items.length; x++) {
                var it = this.ServiceResult.SecurityDescriptor.Items[x];
                if (it && it.EntityName && it.EntityName.toLowerCase() === entName.toLowerCase()) {
                    ep = it;
                }
            }
            if (!ep)
                return false;
    
            var sd = ep;
            var privName = 'ep_' + entName + '_' + actName;
            if (typeof sd.Privileges !== 'undefined') {
                for (var z = 0; z < sd.Privileges.length; z++) {
                    if (sd.Privileges[z]?.toLowerCase() === privName.toLowerCase())
                        return true;
                }
                return true;
            }
            else {
                return false;
            }
        }
    }

    export interface IEntityStateCode {
        StateCode : string;
        StatusCode : string;
    }
    export class EntityStateCodeHelper{
        private StateCodes : Array<IEntityStateCode> = new Array<IEntityStateCode>();
        constructor(){
            this.StateCodes =  new Array<IEntityStateCode>();
        }
        public clearStateCodes = () : void =>{
            this.StateCodes = new Array<IEntityStateCode>(); 
        }
        public addStateCodeString(str : string){
            if (str){
                var parts: string[] = str.split(';');
                for(var i = 0; i<parts.length; i++){
                    var it = parts[i];
                    if (it.startsWith(':') && it.length > 1){
                        this.StateCodes.push({ StateCode : '', StatusCode : it.substr(1)});
                    }
                    else if (it.endsWith(':')&& it.length > 1){
                        this.StateCodes.push({ StateCode : it.substr(0, it.length-1), StatusCode : ''});
                    }
                    else{
                        var ps = it.split(':');
                        if (ps.length === 2){
                            this.StateCodes.push({ StateCode : ps[0], StatusCode :ps[1]});
                        }
                        else{
                            this.StateCodes.push({ StateCode : ps[0], StatusCode :''});
                        }
                    }
                }
            }
        }
        public entityMatchesStateCodes(ent : DynamicEntity | null | undefined) : boolean{
            if (!ent){
                return true;
            }
            if (!this.StateCodes || this.StateCodes.length === 0){
                return true;
            }
            if (ent.hasProperty('Status') && ent.hasProperty('StatusReason')){
                var sc = ent.getValue('Status')?.toString();
                var sr = ent.getValue('StatusReason')?.toString();

                for(var i = 0; i<this.StateCodes.length; i++){
                    if (((this.StateCodes[i].StateCode === sc || this.StateCodes[i].StateCode === '')) && ((this.StateCodes[i].StatusCode === sr) || (this.StateCodes[i].StatusCode === ''))){
                        return true;
                    }
                }
            }
            return false;
        }
    }


    export class ClientAPI{

        public invokeAPI = async (apiURI: string, method : HttpMethodType, data : any) : Promise<any> => {
            var cr : SilentRequest = MSALHelper.getDefaultScope();
            var mh : MSALHelper = new MSALHelper();
            var dataJSONString : string | null = '';
            if (data === null || typeof data === 'undefined'){
                dataJSONString = null;
            }
            else if (typeof data === 'string' || data instanceof String){
                dataJSONString = data?.toString();
            }
            else{
                dataJSONString = JSON.stringify(data);
            }
            if (method === 'POST'){
                return mh.execApiCallPost(cr.scopes, apiURI, dataJSONString, 'application/json').then(function(res : any){
                    //debugger;
                    return res;

                }).catch(function(error: any){
                    return Promise.reject(error);
                })
            }
            else if (method === 'GET'){
                return mh.execApiCallGet(cr.scopes, apiURI).then(function(res : any){
                    //debugger;
                    return res;

                }).catch(function(error: any){
                    return Promise.reject(error);
                })
            }
            else if (method === 'PATCH'){
                return mh.execApiCallPatch(cr.scopes, apiURI, dataJSONString, 'application/json').then(function(res : any){
                    //debugger;
                    return res;

                }).catch(function(error: any){
                    return Promise.reject(error);
                })
            }
            else if (method === 'PUT'){
                return mh.execApiCallPut(cr.scopes, apiURI, dataJSONString, 'application/json').then(function(res : any){
                    //debugger;
                    return res;

                }).catch(function(error: any){
                    return Promise.reject(error);
                })
            }
            else if (method === 'DELETE'){
                return mh.execApiCallDelete(cr.scopes, apiURI, dataJSONString, 'application/json').then(function(res : any){
                    //debugger;
                    return res;

                }).catch(function(error: any){
                    return Promise.reject(error);
                })
            }
            else{
                return Promise.reject('The Method type ' + method + ' was not supported.');
            }
        }
    }
}

