import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";

interface EntityDefinitionSurfaceEditorProps {
    ent: EntityDefinition;
    app: SystemApplicationInfo;
    isReadOnly : boolean;
    closeAndSave : () => void;
}

interface EntityDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ent: EntityDefinition;
  app: SystemApplicationInfo;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class EntityDefinitionSurfaceEditorControl extends React.Component<EntityDefinitionSurfaceEditorProps, EntityDefinitionSurfaceEditorState> {
    constructor(props: EntityDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        ent: props.ent,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >{this.state.app.Id ? 'Edit Entity' : 'Create New Entity'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <TextField label='Entity Name' value={this.state.ent.DisplayName} readOnly={(this.state.isReadOnly || !StringHelper.isEmpty(this.state.ent.Id))} onChange={that.onEntityNameChanged} ></TextField>
            <TextField label='Entity Name Plural' value={this.state.ent.DisplayNamePlural} readOnly={(this.state.isReadOnly)} onChange={that.onEntityNamePluralChanged} ></TextField>
            <TextField label='Logical Name' value={this.state.ent.LogicalName} readOnly={true}  ></TextField>
            <TextField label='Image CSS Class' value={this.state.ent.ImageCSSClass} readOnly={this.state.isReadOnly} onChange={that.onImageCSSClassChanged}></TextField>
            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={false} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.ent && !this.state.app.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      if (this.state.ent && !this.state.ent.LogicalName){
        this.setState({errorMessage : 'The Logical Name is required'});
        return;
      }
      if (this.state.ent && !this.state.ent.ImageCSSClass){
        this.setState({errorMessage : 'The Image CSS class is required'});
        return;
      }
      /*if (this.state.ent && this.state.app && this.state.ent.LogicalName){
        var len : number = this.state.app.en
        this.setState({errorMessage : 'The Tile Name class is required'});
        return;
      }*/
      this.props.closeAndSave();
    }

    private onEntityNameChanged = (evt: any, newValue? : string) =>{
        var it : EntityDefinition = this.state.ent;
        it.DisplayName = StringHelper.displayNameOnly(newValue ?? '');
        it.LogicalName = this.props.app.AppCode + '_' + StringHelper.alphaNumbericOnly(newValue ?? '');
        this.setState({ent : it});
        this.forceUpdate();
    }
    private onEntityNamePluralChanged = (evt: any, newValue? : string) =>{
      var it : EntityDefinition = this.state.ent;
      it.DisplayNamePlural = StringHelper.displayNameOnly(newValue ?? '');
      this.setState({ent : it});
      this.forceUpdate();
  }

    private onImageCSSClassChanged = (evt: any, newValue? : string) =>{
      var it : EntityDefinition = this.state.ent;
      it.ImageCSSClass = newValue ?? '';
      this.setState({ent : it});
      this.forceUpdate();
    }

    
  }
  