
import {  IButtonProps, ICommandBarItemProps, Stack, TextField, Toggle } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { SurfaceEditorBase } from "../SurfaceBase";

interface TabItemSurfaceEditorProps extends SurfaceEditorBase {
    Tab : IllerisNinthUI.TNTab;
    tabIndex : number;
    Form: IllerisNinthUI.Formlet;
    getEntity : () => EntityDefinition;
}

interface TabItemSurfaceEditorState extends TabItemSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class TabItemSurfaceEditorControl extends React.Component<TabItemSurfaceEditorProps, TabItemSurfaceEditorState> {
    constructor(props: TabItemSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Form : props.Form,
        getEntity : props.getEntity,
        isReadOnly : props.isReadOnly,
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >Tab Properties</div>
            <TextField label='Item Id' value={this.state.Tab.ItemID} readOnly={true} ></TextField>
            <TextField label='Title' value={this.state.Tab.Title} readOnly={this.state.isReadOnly} onChange={this.onTitleChanged}  ></TextField>
            <Toggle label='Show Title' checked={this.state.Tab.IsTitleVisible} disabled={this.state.isReadOnly} onChange={this.onIsTitleVisibleChanged} ></Toggle>
        </Stack>
      );
    }

    private onTitleChanged = (evt: any, newValue? : string) =>{
        var tn : IllerisNinthUI.TNTab = this.state.Tab;
        tn.Title = newValue ?? '';
        this.setState({Tab : tn});
    }

    private onIsTitleVisibleChanged = (evt: any, checked? : boolean) =>{
        var tn : IllerisNinthUI.TNTab = this.state.Tab;
        tn.IsTitleVisible = checked ?? false;
        this.setState({Tab : tn});
    }
  }
  