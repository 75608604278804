import { ICommandBarItemProps, IconButton, IContextualMenuItem, IContextualMenuProps, Stack } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { DesignerHelper } from "./DesignerHelper";
//import { DesignerHelper } from "./DesignerHelper";
import { RowSurfaceControl } from "./RowSurface";
import { SurfaceBase } from "./SurfaceBase";

interface SectionSurfaceProps extends SurfaceBase {
    Tab : IllerisNinthUI.TNTab;
    sectionIndex : number;
    Section: IllerisNinthUI.TNSection;
    renderKey: number;
}

interface SectionSurfaceState extends SectionSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
}

//const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class SectionSurfaceControl extends React.Component<SectionSurfaceProps, SectionSurfaceState> {
    constructor(props: SectionSurfaceProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        sectionIndex : props.sectionIndex,
        Section : props.Section,
        updateParent : props.updateParent,
        getEntity : props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        renderKey: props.renderKey,
        appInfo : props.appInfo
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : SectionSurfaceProps, prevState: SectionSurfaceState){
      if (prevProps.renderKey !== this.props.renderKey){
        return true;
      }
      else{
        return false;
      }
    }
    private editCurrentSection = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
      var sectionIdxStr : string = item?.data?.toString() ?? '';
      if (sectionIdxStr){
          var secIdx : number  = Number.parseInt(sectionIdxStr);
          if (!isNaN(secIdx)){
              var tab : IllerisNinthUI.TNTab = this.state.Tab;
              if (tab && this.props.showProperties){
                  this.props.showProperties(tab.Sections[secIdx]);
              }
          }
          debugger;
      }
      debugger;
    }
    private clearCurrentSectionRows = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
      var sectionIdxStr : string = item?.data?.toString() ?? '';
      if (sectionIdxStr){
          var secIdx : number  = Number.parseInt(sectionIdxStr);
          if (!isNaN(secIdx)){
              var tab : IllerisNinthUI.TNTab = this.state.Tab;
              let sect: IllerisNinthUI.TNSection = tab.Sections[secIdx];
              if (sect && sect.Rows && sect.Rows.length > 0){
                  sect.Rows = new Array<IllerisNinthUI.FormletRow>();
                  this.setState({Section: sect});
              }
          }
          debugger;
      }
      debugger;
    }
    private addRowToCurrentSection = (evt : any, item?: IContextualMenuItem | undefined) : void =>{
      var sectionIdxStr : string = item?.data?.toString() ?? '';
      if (sectionIdxStr){
          var secIdx : number  = Number.parseInt(sectionIdxStr);
          if (!isNaN(secIdx)){
              var tab : IllerisNinthUI.TNTab = this.state.Tab;
              let sect: IllerisNinthUI.TNSection = tab.Sections[secIdx];
              if (sect && sect.Rows){
                let row: IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
                sect.Rows.push(row);
                this.setState({Section: sect});
              }
          }
          debugger;
      }
      debugger;
    }
    private buildCommandbar = () : void =>{
      let that = this;
      let cm : IContextualMenuProps = this.state.ctxMenuItems;
      cm.items = new Array<IContextualMenuItem>();
        cm.items.push({
          key: 'editsection',
          text: 'Edit Properties',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'Edit'},
          data : this.props.sectionIndex,
          onClick: this.editCurrentSection.bind(that)
        });
        cm.items.push({
          key: 'clearRows',
          text: 'Clear Rows',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'DeleteTable'},
          data : this.props.sectionIndex,
          onClick: this.clearCurrentSectionRows.bind(that)
        });
        cm.items.push({
          key: 'addRow',
          text: 'Add Row',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
          iconProps: { iconName: 'InsertRowsBelow'},
          data : this.props.sectionIndex,
          onClick: this.addRowToCurrentSection.bind(that)
        });
      this.setState({ctxMenuItems : cm});
    }


    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
        var that = this;
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
             <div className="tn-section-control" >
                <div className="tn-section-control-header">
                    {this.state.Section.Title}  
                    <IconButton
                        menuProps={this.state.ctxMenuItems}
                        iconProps={{iconName : 'Info'}}
                        title="Info"
                        ariaLabel="Info"
                        disabled={false}
                        checked={false}
                    />        
                </div>
                <div className="tn-section-control-body">
                    <div className="ms-Grid" dir="ltr" style={{width: '100%'}}>
                        {this.state.Section.Rows.map(function(item: IllerisNinthUI.FormletRow, index: number){
                            return (<RowSurfaceControl Section={that.state.Section} 
                                                        rowIndex={index} 
                                                        Row={item} 
                                                        renderKey={that.props.renderKey}
                                                        updateParent={that.updateParent} 
                                                        getEntity={that.props.getEntity}
                                                        showProperties={that.props.showProperties}
                                                        appInfo={that.props.appInfo}
                                                        >
                                                        
                                                        </RowSurfaceControl>)
                        })}
                    </div>
                </div>
            </div>
        </Stack>
        
      );
    }
  }
  