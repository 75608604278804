/* eslint-disable no-loop-func */
import { SilentRequest } from "@azure/msal-browser";
import { ICommandBarItemProps, IContextualMenuItem,  MessageBar, MessageBarType, Panel, Pivot, PivotItem,  ProgressIndicator, Separator, Stack, ThemeSettingName} from "@fluentui/react";
import React, { CSSProperties } from "react";
import Swal, { SweetAlertResult } from "sweetalert2";
import { ClientPluginHelper } from "../../ClientPluginHelper";
import { ClientState } from "../../ClientState";
import { Controller } from "../../Controller";
import { ButtonItem } from "../ButtonItemList";
import { FormletActionBarControl } from "../FormletActionBar";
import { TNOwnerEditControl } from "../TNOwnerEdit";
import { EntityDefinition } from "../../designers/Entities/EntityDefinition";
import { DynamicEntity } from "../../DynamicEntity";
import { EntityPersistanceManager } from "../../Helpers/EntityPersistanceManager";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { Guid } from "../../Guid";
import { Logger } from "../../Logger";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { EntityInfo, PageInfo } from "../../PageInfo";
import { RecordAssignControl } from "../../RecordAssignControl";
import { IllerisNinthAPI } from "../../ServiceResult";
import { SessionStorageManager, SessionStorageManagerKeyManager } from "../../StorageManager";
import { TNFlexGridView } from "./TNFlexGridView";
import { TNRecordSecurityInfoEditControl } from "../TNRecordSecurityInfoEdit";
import { TNFormletHeaderControl } from "../FormletHeaderControl";
import { TNInputBoxView } from "./TNInputBoxView";
import { TNContainerView } from "./TNContainerView";
import { nanoid } from "nanoid";
import { takeRightWhile } from "lodash";
import { TNLanguageCombo } from "./TNLanguageCombo";
import { TNSelectEditor } from "./TNSelectEditor";
import { TNLookupEditor } from "./TNLookupEditor";
import { TNDocumentEditor } from "./TNDocumentEditor";
import { TNImageEditor } from "./TNImageEditor";
import { TNSearchLookupEditor } from "./TNSearchLookupEditor";
import { FormletContext, IFormletContext } from "../FormletContext";

export interface UIDataItemEditorProps{
  //Entity : DynamicEntity | null | undefined;
  //ServiceResult : IllerisNinthAPI.ServiceResult | null | undefined;
  //applicationName?: string;
  
  invokeCommand : (cmdName : string) => boolean;
  isreadonly : boolean;

  FormletContext: IFormletContext;
}


interface UIItemEditorProps {
  index : number;
  //itemId?: string;
  dataKey: string;
  //entityName?: string;
  //applicationName?: string;
  //getEntity : () => DynamicEntity | null | undefined;
  updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
  isreadonly : boolean;
  //PageInfo: PageInfo;
  
  FormletContext: IFormletContext;
}


interface FormletProps {
  index : number;
  getFormlet : (idx : number) => IllerisNinthUI.Formlet | null;
  //Entity : DynamicEntity | null;
  entityName? : string;
  itemId? : string;
  applicationName? : string;
  actionName? : string;
  PageInfo: PageInfo;
}

interface FormletState  {
  width: number;
  errorMessage? :string;
  isLoading : boolean;
  loadingMessage : string;
  cmdBarFarItems : ICommandBarItemProps[] | undefined;
  mainIcon : string;
  showAssignPanel: boolean;
  ServiceResult? : IllerisNinthAPI.ServiceResult | null;
  NavBarRenderKey: string;
  dataKey: string;

  Entity? : DynamicEntity | null;
}

export class FormletView extends React.Component<FormletProps, FormletState> {
    private Formlet? : IllerisNinthUI.Formlet | null;
    //private Entity : DynamicEntity | null;
    private FormletActionButtons : Array<IllerisNinthUI.ActionButton> = new Array<IllerisNinthUI.ActionButton>();
    private ClientPluginHelper? : ClientPluginHelper;
    private selectedRows : Array<any>;
    private mContext: FormletContext;
    
    public EntityInfo : EntityInfo;
    constructor(props : FormletProps){
        super(props);

        let ic : string = '';
        let cs : ClientState = ClientState.CurrentState();
        if (SessionStorageManager.containsItem(SessionStorageManagerKeyManager.getTenantEntityListKey(cs.UserProfile?.TerraNovaTenantUniqueName)))
        {
            let xds : Array<EntityDefinition> | undefined | null = JSON.parse(SessionStorageManager.getItem((SessionStorageManagerKeyManager.getTenantEntityListKey(cs.UserProfile?.TerraNovaTenantUniqueName))) ?? '') as Array<EntityDefinition> | undefined | null;
            if (xds)
            {
              let arr : EntityDefinition[] = xds as EntityDefinition[];
              for(var t = 0; t<arr.length; t++){
                if (arr[t].LogicalName?.toLowerCase() === this.props?.entityName?.toLowerCase()){
                  ic = arr[t].ImageCSSClass;   
                }
              }
            }
        }

        this.state = {
          width : window.innerWidth,
          isLoading : false,
          cmdBarFarItems : new Array<ICommandBarItemProps>(),
          mainIcon : ic,
          loadingMessage : 'Data is loading',
          showAssignPanel: false,
          NavBarRenderKey: nanoid(),
          dataKey: nanoid()
        }

        this.mContext = new FormletContext(this);
        this.mContext.addNew = this.internalCreateNewEntity.bind(this);
        //this.mContext.removeItem = this.internalDeleteEntity();

        this.EntityInfo = {
          EntityName: this.props.entityName ?? 'unknown',
          EntityId: this.props.itemId ?? '',
          ApplicationName: this.props.applicationName ?? 'core'
        }

        this.Formlet = props.getFormlet(this.props.index);
        //this.Entity = null;
        this.selectedRows= new Array<any>();

        this.getTab.bind(this);
        this.getRow.bind(this);

        /*this.ClientPluginHelper = new ClientPluginHelper(this.Entity, this.props.applicationName, this.props.entityName, this.props.actionName)
        this.ClientPluginHelper.bind();*/

        this.updateFormlet.bind(this);

        this.onActionBarButtonClicked.bind(this);
        this.executeCommandBarAction.bind(this);
    }

    public shouldComponentUpdate(nextProps: FormletProps, nextState: FormletState){
      if (nextProps.itemId !== this.props.itemId || 
          nextState.dataKey !== this.state.dataKey || 
          this.state.Entity?.VersionKey !== nextState.Entity?.VersionKey ||
          this.state.isLoading !== nextState.isLoading
          ) {
          return true;
      }
      else{
          return false;
      }
    }
    public componentDidUpdate(prevProps: FormletProps, prevState: FormletState){
      if (prevProps.itemId !== this.props.itemId || prevState.dataKey !== this.state.dataKey || this.state.Entity?.VersionKey !== prevState.Entity?.VersionKey) {
        if (this.state.Entity){
          this.ClientPluginHelper = new ClientPluginHelper(this.state.Entity, this.props.applicationName, this.props.entityName, this.props.actionName)
          this.ClientPluginHelper.bind();
        }
        
      }
    }

    private invokeViewDesigner = (entityName: string |null | undefined = null) : void =>{
      var cs : ClientState = ClientState.CurrentState();
      if (!entityName){
        entityName = this.props.entityName;
      }
      debugger;
      if (cs && cs.UserProfile){
        var editUri = '/system/designer/' + cs.UserProfile?.TerraNovaTenantUniqueName +'/account/view?appname=' + this.props.applicationName;
        Controller.showPopup(editUri, 1024,768);
      }
    }

    private getTab = (idx: number) : IllerisNinthUI.TNTab | null =>{
      if (this.Formlet && this.Formlet.Tabs && idx >= 0 && idx < this.Formlet.Tabs.length)
        return this.Formlet.Tabs[idx];
      else
        return null;
    }
    private getRow = (idx: number) : IllerisNinthUI.FormletRow | null =>{
      if (this.Formlet && this.Formlet.Rows && idx >= 0 && idx < this.Formlet.Rows.length)
        return this.Formlet.Rows[idx];
      else
        return null;
    }

    private notifyContainerRendered = (containerId: string) : void =>{
      if (this.ClientPluginHelper){
        this.ClientPluginHelper.containerRendered(containerId);
      }
    }


    componentDidMount(){
      var that = this;
      window.addEventListener('resize', this.updateDimensions);
      if (!this.Formlet){
          this.Formlet = this.props.getFormlet(this.props.index);
      }
      //if (!this.Entity){
      if (!this.state.Entity){
        this.internalRefreshEntity();
      }

      // Client API Binding
      var windowObj = window as any;
      if (typeof windowObj['TNPlugin'] !== 'undefined'){
        Logger.logNotification('TNPlugin hook found.');
      }
      else{
        Logger.logNotification('TNPlugin hook NOT found.');
      }
      try{
        //debugger;
        if (that.ClientPluginHelper){
          that.ClientPluginHelper.executeOnFormLoad();
        }
      }
      catch(error : any){
        this.setState({errorMessage : error.message});
      }

      // for creating a new entity
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('action') && urlParams.get('action')?.toLowerCase() === 'create') {
        that.internalCreateNewEntity();
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }
  
    private updateDimensions = () => {
      this.setState({ width: window.innerWidth});
    };

    private padNumberToString(num : number , size :number) : string {
      var res = num.toString();
      while (res.length < size) res = "0" + res;
      return res;
    }

    private executeCommandBarAction = (barItem: ButtonItem) : void => {
      Logger.logNotification('Clicked CommandBar button ' + barItem.CommandName)
      debugger;
      try{
        var key : string = barItem.CommandName;

        var xb : IllerisNinthUI.ActionButton | undefined | null = barItem.ActionButton;

        if (key === 'back'){
          Controller.GoBack();
          return;
        }
        else if (key === 'create' || key === 'add'){
          
          if (xb){
            this.internalExecuteAction(xb);
          } 
          else{
            this.internalCreateNewEntity();
          }
        }
        else if (key === 'delete' || key === 'remove'){
          this.internalDeleteEntity();
        }
        else if (key === 'activate'){
          this.internalActivateEntity();
        }
        else if (key === 'deactivate'){
          this.internalDeactivateEntity();
        }
        else if (key === 'refresh'){
          this.internalRefreshEntity();
        }
        else if (key === 'assign'){
          this.internalAssignEntity();
        }
        else if (key === 'save' || key=== 'saveandclose'){
          this.internalSaveEntity(key, key === 'saveandclose', xb);
        }
        else{
          //XXX
          if (barItem.ActionButton){
            this.executeActionButtonCommand(barItem.ActionButton);
          }
          else if (barItem.Button){
            this.executeButtonCommand(barItem.Button);
          }
        }
      }
      catch(error : any){
        this.setState({errorMessage : error.message});
      }
    }

    private renderDocumentAction = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
      if (!this.state.Entity || !this.state.Entity.getId() || !item || !this.state.Entity.LogicalEntityName){
        return;
      }
      var uri = '/api/v2.0/terranova/xdocgen/generate/core/' + this.state.Entity.LogicalEntityName + '/' + this.state.Entity.getId() + '/' + item?.key;
      var epm : EntityPersistanceManager = new EntityPersistanceManager();
      epm.executeAPI(uri, this.state.Entity,'GET', false)
        .then(function(res : IllerisNinthAPI.ServiceResult | Error){
          var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
          if (sr && sr.Type && sr.Type === 'WebServiceResult'){
            if (sr && ((sr.ErrorMessages && sr.ErrorMessages.length === 0) || (!sr.ErrorMessages))){
              var dres : any = sr;
              var fnm : string = '';
              var mimeType : string = '';
              if (dres.FileName)
                  fnm = dres.FileName;
              if (dres.FileMimeType)
                  mimeType = dres.FileMimeType;
              var element = document.createElement('a');
              element.setAttribute('href', 'data:' + mimeType + ';base64,' + dres.Value);
              element.setAttribute('download', fnm);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            }
            else if (sr && sr.ErrorMessages && sr.ErrorMessages.length !== 0){
              var str : string = sr.ErrorMessages.join('.');
              IllerisNinthUI.NotificationManager.showError('Failed to render document', str);
            }
          }
          else{
            IllerisNinthUI.NotificationManager.showError('Failed to render document', 'Invalid Return Type');
          }
        })
        .catch(function(err){
          IllerisNinthUI.NotificationManager.showError('Failed to render document', err.message);
        })
    }

    private onRenderDocumentAction = (itemKey: string) : boolean | void =>{
      //debugger;
      if (!this.state.Entity || !this.state.Entity.getId() || !this.state.Entity.LogicalEntityName || !itemKey){
        return;
      }
      var uri = '/api/v2.0/terranova/xdocgen/generate/core/' + this.state.Entity.LogicalEntityName + '/' + this.state.Entity.getId() + '/' + itemKey;
      var epm : EntityPersistanceManager = new EntityPersistanceManager();
      epm.executeAPI(uri, this.state.Entity,'GET', false)
        .then(function(res : IllerisNinthAPI.ServiceResult | Error){
          var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
          if (sr && sr.Type && sr.Type === 'WebServiceResult'){
            if (sr && ((sr.ErrorMessages && sr.ErrorMessages.length === 0) || (!sr.ErrorMessages))){
              var dres : any = sr;
              var fnm : string = '';
              var mimeType : string = '';
              if (dres.FileName)
                  fnm = dres.FileName;
              if (dres.FileMimeType)
                  mimeType = dres.FileMimeType;
              var element = document.createElement('a');
              element.setAttribute('href', 'data:' + mimeType + ';base64,' + dres.Value);
              element.setAttribute('download', fnm);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            }
            else if (sr && sr.ErrorMessages && sr.ErrorMessages.length !== 0){
              var str : string = sr.ErrorMessages.join('.');
              IllerisNinthUI.NotificationManager.showError('Failed to render document', str);
            }
          }
          else{
            IllerisNinthUI.NotificationManager.showError('Failed to render document', 'Invalid Return Type');
          }
        })
        .catch(function(err){
          IllerisNinthUI.NotificationManager.showError('Failed to render document', err.message);
        })
    }
  
    // private bindReportsButton = (menuItem: ICommandBarItemProps, btn : IllerisNinthUI.ActionButton | IllerisNinthAPI.Button ) : void =>{
    //   var that = this;
    //   var entityName : string = this.Entity ? this.Entity.LogicalEntityName : '';
    //   if (entityName){
    //     var epm : EntityPersistanceManager = new EntityPersistanceManager();
    //     epm.getMany('documenttemplate','$filter=EntityName = "' + entityName + '"&$orderby=DisplayName')
    //       .then(function(res : IllerisNinthAPI.ServiceResult | Error){
    //           var err : Error = res as Error;
    //           if (err && typeof (err as any)['Value'] === 'undefined'){
    //             IllerisNinthUI.NotificationManager.showError('Failed to load document templaes', err.message);
    //           }
    //           else{
    //             var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
    //             if (sr  && sr.Values) {
                  
    //               var smenuItems : Array<IContextualMenuItem> = new Array<IContextualMenuItem>();
    //               for (var j = 0; j < sr.Values.length; j++) {
    //                   var sitem : IContextualMenuItem  = {
    //                     key : sr.Values[j].Id,
    //                     text: sr.Values[j].DisplayName,
    //                     title : sr.Values[j].DisplayName,
    //                     iconProps: { iconName : (sr.Values[j].DocumentType === 1?'WordDocument' : 'PDF') },
    //                     ariaLabel : sr.Values[j].DisplayName,
    //                     onClick: that.renderDocumentAction 
    //                   }
    //                   smenuItems.push(sitem);
    //               }
    //               menuItem.subMenuProps = {
    //                 items : smenuItems  
    //               }
    //             }
    //             var items : ICommandBarItemProps[] | undefined = that.state.cmdBarFarItems;
    //             that.setState({cmdBarFarItems : items})
    //           }
    //       })
    //       .catch(function(err){
    //         IllerisNinthUI.NotificationManager.showError('Failed to load document templates', err.message);
    //       })
    //   }
    // }

    private updateURI = (uri: string) : string =>{
      if (this.selectedRows && this.selectedRows.length > 0){
        return uri.replace('{id}', this.selectedRows[0].Id).replace('{Id}', this.selectedRows[0].Id);
      }
      return uri;
    }

    private executeActionButtonCommand = (ab : IllerisNinthUI.ActionButton) : void =>{
      var that = this;
      if (ab.ActionURI && this.state.Entity){
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        that.setState({isLoading: true, loadingMessage : 'Processing...'});
        epm.executeAPI(this.updateURI(ab.ActionURI), this.state.Entity, 'POST', false).then(function(res: IllerisNinthAPI.ServiceResult){
          that.setState({isLoading : false, loadingMessage : 'Data is loading...'})
          that.updateFormletState(res);
          //that.rebuildCommandBar(res);
          that.setState({ServiceResult: res, NavBarRenderKey: nanoid()});
        })
        .catch(function(err : any){
          that.setState({isLoading: false, loadingMessage : 'Data is loading...'});
          var xr : IllerisNinthAPI.ServiceResult = err as IllerisNinthAPI.ServiceResult;
          if (xr){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', xr.ErrorMessages.join('\n'));
          }
          else{
            IllerisNinthUI.NotificationManager.showError('Command execution failed', err.message);
          }
        })
      }
      else if (ab.OnClickHandler){
        if (typeof window[ab.OnClickHandler] === 'function'){
          try{
            (window as any)[ab.OnClickHandler]();
          }
          catch(error : any){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', error.message);
          }
        }
      }
      else if (ab.OnClickJScriptCode){
        if (typeof window[ab.OnClickJScriptCode] === 'function'){
          try{
            (window as any)[ab.OnClickJScriptCode]();
          }
          catch(error : any){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', error.message);
          }
        }
      }
      else if (ab.RedirectURI){
        var uri = ab.RedirectURI.replace('{id', this.state.Entity?.getId() ?? Guid.empty());
        Controller.RedirectToURI(uri, this.props.PageInfo);
      }
    }
    private executeButtonCommand = (ab : IllerisNinthAPI.Button) : void =>{
      var that = this;
      if (ab.ActionURI && this.state.Entity){
        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        that.setState({isLoading: true});
        epm.executeAPI(this.updateURI(ab.ActionURI), this.state.Entity, 'POST', false).then(function(res: IllerisNinthAPI.ServiceResult){
          that.setState({isLoading: false});
          that.updateFormletState(res);
          //that.rebuildCommandBar(res);
          if (res.RedirectURI){
            Controller.RedirectToURI(res.RedirectURI, that.props.PageInfo);
          }
          else{
            that.setState({ServiceResult: res, NavBarRenderKey: nanoid()});
          }
        })
        .catch(function(err : any){
          that.setState({isLoading: false});
          var xr : IllerisNinthAPI.ServiceResult = err as IllerisNinthAPI.ServiceResult;
          if (xr){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', xr.ErrorMessages.join('\n'));
          }
          else{
            IllerisNinthUI.NotificationManager.showError('Command execution failed', err.message);
          }
        })
      }
      else if (ab.OnClickHandler){
        if (typeof window[ab.OnClickHandler] === 'function'){
          try{
            (window as any)[ab.OnClickHandler]();
          }
          catch(err : any){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', err.message);
          }
        }
      }
      else if (ab.OnClickJScriptCode){
        if (typeof window[ab.OnClickJScriptCode] === 'function'){
          try{
            (window as any)[ab.OnClickJScriptCode]();
          }
          catch(err : any){
            IllerisNinthUI.NotificationManager.showError('Command execution failed', err.message);
          }
        }
      }
      else if (ab.RedirectURI){
        var uri = ab.RedirectURI.replace('{id', this.state.Entity?.getId() ?? Guid.empty());
        Controller.RedirectToURI(uri, this.props.PageInfo);
      }
    }

    private updateFormlet = (sr : IllerisNinthAPI.ServiceResult) : void => {
      if (sr && this.Formlet && sr.EntityName === this.Formlet.DataSource.EntityName){
        //this.rebuildCommandBar(sr);
        this.setState({ServiceResult: sr, NavBarRenderKey: nanoid()});
        this.forceUpdate();
      }
    }

    private loadEntity = async () : Promise<void> =>{
      var that = this;
      debugger;
      if (this.Formlet && this.Formlet.DataSource && this.Formlet.DataSource.EntityName && this.props.itemId){
          this.setState({isLoading : true});
          this.loadEntityById(this.props.itemId).then(function (res : IllerisNinthAPI.ServiceResult | null){
            //that.setState({ServiceResult: res});
            //that.ServiceResult = res;
            if (res && res?.Value){
              //that.Entity = new DynamicEntity(res.Value, res.EntityName);
              that.updateFormletState(res);
              //that.rebuildCommandBar(res);
              that.setState({ServiceResult: res, NavBarRenderKey: nanoid(), dataKey: nanoid()});
            }

            try{
              //debugger;
              if (that.ClientPluginHelper){
                that.ClientPluginHelper.executeOnDataLoaded(res && res.Value? res.Value : null);
              }
            }
            catch(error : any){
              that.setState({errorMessage : error.message});
            }

            that.setState({isLoading : false, ServiceResult: res});
            that.forceUpdate();
            return res;
          }).catch(function(error : any){
            that.setState({isLoading : false});
            return Promise.reject(error);
          });

      }
      //this.rebuildCommandBar();
      //that.setState({ServiceResult: res});
      
    }

    private onActionBarButtonClicked = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item?: IContextualMenuItem | undefined) : boolean | void =>{
      Logger.logNotification('Clicked ActionBar button ' + item?.key)
      debugger;
      try{
      var key : string = item?.key? item!.key!.toLowerCase() : '';

      var xb : IllerisNinthUI.ActionButton | undefined = this.FormletActionButtons.find( z => z.CommandName === key);

      if (key === 'back'){
        Controller.GoBack();
        return;
      }
      else if (key === 'create' || key === 'add'){
        
        if (xb){
          this.internalExecuteAction(xb);
        } 
        else{
          this.internalCreateNewEntity();
        }
      }
      else if (key === 'delete' || key === 'remove'){

      }
      else if (key === 'activate'){
        this.internalActivateEntity();
      }
      else if (key === 'deactivate'){
        this.internalDeactivateEntity();
      }
      else if (key === 'refresh'){
        this.internalRefreshEntity();
      }
      else if (key === 'assign'){
        this.internalAssignEntity();
      }
      else if (key === 'save' || key=== 'saveandclose'){
        this.internalSaveEntity(key, key === 'saveandclose', xb);
      }
    }
    catch(error : any){
      this.setState({errorMessage : error.message});
    }
    }

    private internalAssignEntity = () : void => {
      this.setState({showAssignPanel: true});
    }

    private internalCreateNewEntity = () : void =>{

        var that = this;
        //var verb = 'GET';

        if (!that.Formlet || !that.Formlet?.DataSource){
          IllerisNinthUI.NotificationManager.showError('Fatal Error', 'Formlet or Formlet.DataSource are NULL.');
          return;
       }

        if (!that.Formlet.DataSource?.CreateURI){
          IllerisNinthUI.NotificationManager.showError('Fatal Error', 'Cannot create entity. The service URI is not defined.');
          return;
        }

        var actURi = that.Formlet.DataSource.CreateURI + window.location.search;
        actURi = that.innerPrepareURI(actURi, that.state.Entity);

        var cr : SilentRequest = MSALHelper.getDefaultScope();
        var mh : MSALHelper = new MSALHelper();
        //debugger;
        that.setState({isLoading : true});
        mh.execApiCallGet(cr.scopes, actURi).then(function(res : any){
          that.setState({isLoading : false});
            var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
            if (sr){
              sr.LoadedTimeStamp = new Date();
            }

            // Set defaults
            let searchUri: string = window.location.search?.replace('?action=create&', '');
            let parts: string[] = searchUri?.split('&');
            if (parts){
              parts.forEach(function(item: string, index: number){
                let propParts: string[] = item.split('=');
                if (propParts && propParts.length === 2){
                  if (sr.Value){
                    (sr.Value as any)[propParts[0]] = propParts[1];
                  }
                }
              })
            }

            //that.innerShowErrors(res, 'Failed to create entity');
            that.updateFormletState(sr);

        }).catch(function(error: any){
          IllerisNinthUI.NotificationManager.showError('Fatal Error', error.message);
        })

        /*var that = this;
        debugger;

        if (!this.Formlet){
           IllerisNinthUI.NotificationManager.showError('Fatal Error', 'Formlet is NULL.');
           return;
        }

        var uri = this.Formlet!.DataSource.CreateURI;

        if (this.Formlet!.DataSource.EntityPKNameIsLastURISegment) {
            var cururi = window.location.href;
            if (cururi.indexOf('?') !== -1)
                cururi = cururi.substring(0, cururi.indexOf('?'));
            cururi = cururi.substring(0, cururi.lastIndexOf('/')) + '/';
            var part = window.location.href;
            if (part.indexOf('?') !== -1)
                part = part.substring(0, part.indexOf('?'));
            part = part.replace(cururi, '');
            part = part.replace(/\#$/, '');
            var re = new RegExp('{' + that.FKFieldName + '}', 'g');
            uri = uri.replace(re, part);
        }

        var number_regex = /{[a-zA-Z0-9_-]+}/g;
        var editLink = uri;

        var matches = [];
        var match;
        while ((match = number_regex.exec(editLink)) !== null) {
            matches.push(match[0]);
        }
        var cnt = matches.length;
        var i = 0;
        var urlParams = new URLSearchParams(window.location.search);
        for (i = 0; i < cnt; i++) {
            var it = matches[i];
            var propit = it.substring(1, it.length - 1);

            if (urlParams.has(propit)) {
                editLink = editLink.replace(it, urlParams.get(propit)?? '');
            }
        }
        uri = editLink;


        if (uri) {
            window.location.href = uri;
        }
        else {
            //console.log("ERROR: cannot add item to grid when rowAddURI was not set.");
            IllerisNinthUI.NotificationManager.showError('Fatal Error', 'cannot add item to grid when rowAddURI was not set.');
        }*/
    }

    // private updateViewFromModel = () : void =>{
    //   this.forceUpdate();
      
    // }
    private internalUpdateActionBarAfterModelUpdate = () : void =>{

    }

    private internalCloseEntity = (ab : IllerisNinthUI.ActionButton | null | undefined) : void =>{
      var gbUri : string |null = this.getURIBackURI();
      if (!gbUri){
        if (ab){
          if (ab.RedirectURI){
            Controller.RedirectToURI(ab.RedirectURI, this.props.PageInfo);
          }
        }
        if (window.location.href.toLowerCase().indexOf('/edit/') !== -1){
          var pos : number = window.location.href.toLowerCase().indexOf('/edit/');
          var subStr :string = window.location.href.toLowerCase().substr(0, pos);
          Controller.RedirectToURI(subStr, this.props.PageInfo);
        }
      }
      else{
        Controller.RedirectToURI(gbUri, this.props.PageInfo);
      }
    }

    private internalRefreshEntity = () : void => {
      var that = this;
      try{
        that.setState({showAssignPanel: false});
        that.loadEntity();
      }
      catch(error : any){
        that.setState({errorMessage : 'FAILED to load entity : ' + error.message});
      }
    }

    private internalExecuteAction = (ab : IllerisNinthUI.ActionButton)  : void => {
        if (!ab){
          return;
        }

        if (ab.ActionURI){

        }
        else if (ab.RedirectURI){
          Controller.RedirectToURI(ab.RedirectURI, this.props.PageInfo);
        }
    }
    private hasErrors = (dataObj : any) : boolean =>{
      if (dataObj && dataObj.ValidationResult){
        var sr : IllerisNinthAPI.ServiceResult = dataObj as  IllerisNinthAPI.ServiceResult;
        if (sr){
          if (sr.ValidationResult && (!sr.ValidationResult.IsOk || (sr.ValidationResult.Errors && sr.ValidationResult.Errors.length > 0)) ){
            return true;
          }
        }
      }
      return false;
    }
    private innerShowErrors = (dataObj : any, errorMsg : string) : boolean =>{
      if (dataObj && dataObj.ValidationResult){
        var sr : IllerisNinthAPI.ServiceResult = dataObj as  IllerisNinthAPI.ServiceResult;
        if (sr){
          if (sr.ValidationResult && (!sr.ValidationResult.IsOk || (sr.ValidationResult.Errors && sr.ValidationResult.Errors.length > 0)) ){
            var msg : string = '';
            for(var i =0; i < sr.ValidationResult.Errors.length; i++){
              msg += sr.ValidationResult.Errors[i]?.Message;
            }
            this.setState({errorMessage : msg});
            return true;
          }
        }
      }
      else if (dataObj && dataObj.ErrorMessages){
        var xsr : IllerisNinthAPI.ServiceResult = dataObj as  IllerisNinthAPI.ServiceResult;
        if (xsr){
          if (xsr.ErrorMessages && xsr.ErrorMessages.length > 0 ){
            var emsg : string = '';
            for(var z =0; z < xsr.ErrorMessages.length; z++){
              emsg += xsr.ErrorMessages[z];
            }
            this.setState({errorMessage : emsg});
            return true;
          }
        }
      }
      this.setState({errorMessage : ''});
      return false;
    }

    private getURIBackURI = () : string | null =>{
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('backuri'))
            return urlParams.get('backuri');
        else
            return null;
    }

    private innerPrepareURI = (uriStr : string, prepObj : any) : string => {
      if (uriStr !== null && uriStr.toLowerCase() === 'window.location.href')
          return window.location.href;

      var that = this;
      var editLink : string | null = uriStr;
      var number_regex = /{[a-zA-Z0-9_-]+}/g;
      //var row = this.Entity?.getEntity();
      var urlParams = new URLSearchParams(window.location.search);


      var matches = [];
      var match;
      while ((match = number_regex.exec(editLink)) !== null) {
          matches.push(match[0]);
      }
      var cnt = matches.length;
      var i = 0;
      for (i = 0; i < cnt; i++) {
          var it = matches[i];
          var propit = it.substring(1, it.length - 1);

          if (prepObj !== null && typeof prepObj !== 'undefined') {
              if (typeof prepObj[propit] !== 'undefined') {
                  var re = new RegExp(it, 'g');
                  editLink = editLink.replace(re, prepObj[propit]);
              }
          }
          else if (typeof that.state.Entity?.getEntity() !== 'undefined' && that.state.Entity?.getEntity()) {
              if (typeof that.state.Entity?.getEntity().Type !== 'undefined' && that.state.Entity?.getEntity().Type?.toLowerCase() === 'serviceresult') {
                  if (that.state.Entity?.getEntity().Value !== null) {
                      if (typeof that.state.Entity?.getEntity().Value[propit] !== 'undefined') {
                          var re2 = new RegExp(it, 'g');
                          editLink = editLink.replace(re2, that.state.Entity?.getEntity().Value[propit]);
                      }
                  }
                  
              }
          }
          if (urlParams.has(propit)) {
              var re3 = new RegExp(it, 'g');
              editLink = editLink.replace(re3, urlParams.get(propit)?? '');
          }
          //TODO : entity PK from URI
      }
      return editLink;
  }

  private internalSetValidationReport = (res: IllerisNinthAPI.ServiceResult) : void =>{
    debugger;
    var bIsUpdated : boolean = false;
    if (this.Formlet){
      var editors : Array<any> =  this.Formlet!.findEditors();
      if (editors){
        for (var x = 0; x<editors.length; x++){
          if (typeof editors[x].clearError === 'function'){
            editors[x].clearError();
          }
          if (res.ValidationResult && res.ValidationResult.Errors){
            var msgs : Array<any> = res.ValidationResult.Errors.find( z=> z.PropertyName === editors[x].HSBindName);
            var totMsg : string = '';
            if (msgs && ! Array.isArray(msgs)){
              totMsg = (msgs as any).Message;
            }
            else if (msgs && msgs.length > 0){
              for(var t = 0; t<msgs.length; t++){
                totMsg += msgs[t].Message;
              }
            }
            if (totMsg){
              editors[x].setError(totMsg);
              bIsUpdated = true;
            }
          }
        }
      }
    }
    if (bIsUpdated){
      this.forceUpdate();
    }
  }

  private updateFormletState = (resultObj : IllerisNinthAPI.ServiceResult) =>{
    let that = this;
    var sr : IllerisNinthAPI.ServiceResult = resultObj as IllerisNinthAPI.ServiceResult;
    if (sr){
      this.setState({ServiceResult: sr});
      //this.ServiceResult = sr;
      if (sr.Value && (!sr.Values || (Array.isArray(sr.Values) && sr.Values.length === 0))){
        if (!that.state.Entity){
          let et: DynamicEntity = new DynamicEntity(sr.Value, sr.EntityName);
          this.setState({Entity: et});
          //this.state.Entity = new DynamicEntity(sr.Value, sr.EntityName);
        }
        else{
          let et2: DynamicEntity = that.state.Entity!;
          et2.updateObject(sr.Value);
          this.setState({Entity: et2});
          //this.state.Entity?.updateObject(sr.Value)
        }
        if (this.state.Entity && this.Formlet && this.state.Entity.getValue('Status') === 0){
          this.Formlet.IsReadOnly = true;
        }
        else if (this.state.Entity && this.Formlet && this.state.Entity.getValue('Status') === 1){
          this.Formlet.IsReadOnly = false;
        }
      }
      else if (!sr.Value && sr.Values){
        this.state.Entity?.updateObject(sr.Values)
      }
      else{
        this.state.Entity?.updateObject(null)
      }
    }
    else{
      this.state.Entity?.updateObject(resultObj);
    }
    //this.updateViewFromModel();
    this.internalUpdateActionBarAfterModelUpdate();
    //this.rebuildCommandBar(sr);
    this.setState({ServiceResult: sr, NavBarRenderKey: nanoid()});
    this.forceUpdate();
  }

  private internalSaveEntity = (cmdName : string, doClose : boolean = false, xb : IllerisNinthUI.ActionButton | undefined | null) : void => {
    debugger;
    var that = this;

    Logger.logNotification('Starting save...');
    if (!this.state.Entity || !this.FormletActionButtons || !this.Formlet){
      throw new Error('The Entity is Null, or the form was not parsed correctly. Cannot Save.');
    }
    // Client API Binding
    var canSave : boolean = true;
    var windowObj = window as any;
    if (typeof windowObj['TNPlugin'] !== 'undefined'){
      Logger.logNotification('TNPlugin hook found.');
    }
    else{
      Logger.logNotification('TNPlugin hook NOT found.');
    }
    try{
      //debugger;
      var dataObj : any = that.ClientPluginHelper?.executeGetData();
      if (dataObj){
        this.state.Entity.updateObject(dataObj);
      }
      else{
        Logger.logError('Invalid return object from plugin: NULL object is returned. Ignoring output.');
      }
      canSave = that.ClientPluginHelper?.executeOnBeforeSave(null) ?? true;
    }
    catch(error : any){
      this.setState({errorMessage : error.message});
    }
    if (!canSave){
      return;
    }

    var epm : EntityPersistanceManager = new EntityPersistanceManager();
    epm.saveEntity(this.state.Entity)
      .then(function(result : IllerisNinthAPI.ServiceResult){
        if (that.hasErrors(result) && !that.innerShowErrors(result, "Error Saving Entity")) {
        }

        if ((result && result.ValidationResult && result.ValidationResult.IsOk) || (result && !result.ValidationResult)) {
            if (that.Formlet!.EnableNotifications){
                IllerisNinthUI.NotificationManager.showSuccess('Record saved', 'Save');
            }
        }
        that.updateFormletState(result);
        that.internalUpdateActionBarAfterModelUpdate();
        if (doClose){
          that.internalCloseEntity(xb);
        }
      })
      .catch(function(err: any){
        var cx : IllerisNinthAPI.ServiceResult = err as IllerisNinthAPI.ServiceResult;
        if (cx){
          if (that.hasErrors(cx) && !that.innerShowErrors(cx, "Error Saving Entity")) {
          }
        }
        else{
          IllerisNinthUI.NotificationManager.showError('Failed to save', err.message);
        }
      })

  }
  
    private internalActivateEntity = () : void => {
      //debugger;
      var that = this;
      if (!this.state.Entity || !this.FormletActionButtons){
        throw new Error('The Entity is Null, or the form was not parsed correctly. Cannot activate.');
      }
        
      //var verb : string = 'POST';
      var objId : string | null = this.state.Entity!.getId();
      if (!objId){
        throw new Error('Cannot activate this entity. You must save it first.');
      }
      var actURi = this.Formlet!.DataSource.ActivateURI.replace('{' + this.state.Entity.PrimaryKeyName + '}', objId);

      if (!actURi){
          throw new Error('Cannot activate entity. The service URI is not defined.');
      }
      Swal.fire({
        title: 'Are you sure you want to activate this record?',
        icon: 'question',
        showCancelButton: true,
        //confirmButtonColor: '#3085d6',
        //cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {

          var epm : EntityPersistanceManager = new EntityPersistanceManager();
          epm.activateDeactivateEntity(that.state.Entity!, true).then(function (res : IllerisNinthAPI.ServiceResult){
            Logger.logNotification('Entity activated.');
            that.state.Entity?.updateObject(res.Value);

            that.updateFormletState(res);
            that.enableFormEdit(true);
            //that.rebuildCommandBar();
            that.setState({ServiceResult: res});
          })
          .catch(function(err){
            var msg : string = 'Failed to activate entity : ' + err.message;
            Logger.logError(msg);
            that.setState({errorMessage : msg});
          })


          // var capi : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
          // capi.invokeAPI(actURi, 'POST', null).then(function(res){
          //   Logger.logNotification('Entity activated.');
          //   that.enableFormEdit(true);
          //   that.rebuildCommandBar();
          // })
          // .catch(function(err){
          //   var msg : string = 'Failed to activate entity : ' + err.message;
          //   Logger.logError(msg);
          //   that.setState({errorMessage : msg});
          // })
        }
      })
  }

  private enableFormEdit = (bAllowEdit : boolean) : void =>{
    //TODO
  }


  private internalDeactivateEntity = () : void => {
      //debugger;
      var that = this;
      if (!this.state.Entity || !this.FormletActionButtons){
        throw new Error('The Entity is Null, or the form was not parsed correctly. Cannot activate.');
      }
        
      //var verb : string = 'POST';
      var objId : string | null = this.state.Entity!.getId();
      if (!objId){
        throw new Error('Cannot activate this entity. You must save it first.');
      }
      var deActURi = this.Formlet!.DataSource.DeactivateURI.replace('{' + this.state.Entity.PrimaryKeyName + '}', objId);

      if (!deActURi){
          throw new Error('Cannot deactivate entity. The service URI is not defined.');
      }

      Swal.fire({
        title: 'Are you sure you want to deactivate this record?',
        icon: 'question',
        showCancelButton: true,
        //confirmButtonColor: '#3085d6',
        //cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {

          var epm : EntityPersistanceManager = new EntityPersistanceManager();
          epm.activateDeactivateEntity(that.state.Entity!, false).then(function (res : IllerisNinthAPI.ServiceResult){
            Logger.logNotification('Entity deactivated.');
            that.state.Entity?.updateObject(res.Value);

            that.updateFormletState(res);
            that.enableFormEdit(true);
            //that.rebuildCommandBar();
            that.setState({ServiceResult: res});
          })
          .catch(function(err){
            var msg : string = 'Failed to deactivate entity : ' + err.message;
            Logger.logError(msg);
            that.setState({errorMessage : msg});
          })

          // var capi : IllerisNinthAPI.ClientAPI = new IllerisNinthAPI.ClientAPI();
          // capi.invokeAPI(deActURi, 'POST', null).then(function(res){
          //   Logger.logNotification('Entity deactivated.');
          //   that.enableFormEdit(false);
          //   that.rebuildCommandBar();
          // })
          // .catch(function(err){
          //   var msg : string = 'Failed to deactivate entity : ' + err.message;
          //   Logger.logError(msg);
          //   that.setState({errorMessage : msg});
          // })
        }
      })
  }

  public commandDeleteEntity = async (entName: string, entId: string, appName: string) : Promise<boolean> =>{
    debugger;
    var that = this;
    if (!entName || !entId || !appName){
      throw new Error('The Entity is Null, or the form was not parsed correctly. Cannot activate.');
    }
      
    var objId : string | null = entId;
    if (!objId){
      throw new Error('Cannot delete this entity. You must save it first.');
    }

    await Swal.fire({
      title: 'Are you sure you want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {

        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        epm.deleteEntityById(entName, entId, appName).then(function (res : IllerisNinthAPI.ServiceResult){
          Logger.logNotification('Entity deleted.');
          
          //that.internalCloseEntity(null);
          return Promise.resolve(true);
        })
        .catch(function(err){
          if (err && err.ErrorMessages){
            let errmsg: string = 'Failed to delete entity: ' + (err.ErrorMessages as string[]).join();
            Logger.logError(errmsg);
            return Promise.reject(err);
          }
          else{
            var msg : string = 'Failed to delete entity : ' + err.message;
            Logger.logError(msg);
            return Promise.reject(err);
          }
          
        })
      }
    });
    return Promise.resolve(true);
  }

  private internalDeleteEntity = () : void => {
    //debugger;
    var that = this;
    if (!this.state.Entity || !this.FormletActionButtons){
      throw new Error('The Entity is Null, or the form was not parsed correctly. Cannot activate.');
    }
      
    var objId : string | null = this.state.Entity!.getId();
    if (!objId){
      throw new Error('Cannot delete this entity. You must save it first.');
    }

    Swal.fire({
      title: 'Are you sure you want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {

        var epm : EntityPersistanceManager = new EntityPersistanceManager();
        epm.deleteEntity(that.state.Entity!).then(function (res : IllerisNinthAPI.ServiceResult){
          Logger.logNotification('Entity deleted.');
          
          that.internalCloseEntity(null);
        })
        .catch(function(err){
          if (err && err.ErrorMessages){
            let errmsg: string = 'Failed to delete entity: ' + (err.ErrorMessages as string[]).join();
            Logger.logError(errmsg);
            that.setState({errorMessage : errmsg});
          }
          else{
            var msg : string = 'Failed to delete entity : ' + err.message;
            Logger.logError(msg);
            that.setState({errorMessage : msg});
          }
          
        })
      }
    })
  }
  private setSelection = (sel : Array<any>) : void =>{
    this.selectedRows = sel;
  }

    private loadEntityById = async (itemId : string) : Promise<IllerisNinthAPI.ServiceResult | null> =>{
      var that = this;
      if (!that.Formlet)
        return Promise.reject('Formlet was not defined.');

      var uri: string = that.Formlet.DataSource.LoadURI.replace('{' + that.Formlet.DataSource.EntityPKName + '}', itemId);

      var cr : SilentRequest = MSALHelper.getDefaultScope();
      var mh : MSALHelper = new MSALHelper();
      //debugger;
      return mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
          console.log(JSON.stringify(res));
          var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
          if (sr){
            sr.LoadedTimeStamp = new Date();
          }
          if (sr.ErrorMessages !== null && sr.ErrorMessages.length !== 0){
            return Promise.reject(sr.ErrorMessages.join('.'));
          }
          
          //that.Entity = new DynamicEntity(sr.Value);
          //return Promise.resolve(new DynamicEntity(sr.Value, sr.EntityName));
          return Promise.resolve(sr);

      }).catch(function(error: any){
        return Promise.reject(error.message);
      })
  }

    private raiseError = (errorMessage : string) : void =>{

    }
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };

    private addRowFromGridEventHandler = () : void =>{
      
      if (this.Formlet && this.Formlet.ActionButtons){
        var cmdAdd = this.Formlet.ActionButtons.find(z => z.CommandName === "add");
        if (cmdAdd){
          //this.internalExecuteAction(cmdAdd);
          if (cmdAdd.RedirectURI){
            Controller.RedirectToURI(cmdAdd.RedirectURI, this.props.PageInfo);
          }
        }
      }
    }

    private invokeCommand = (cmdName : string) : boolean =>{
      return false;
    }

    // public onMainCommandBarItemsClicked(ev?: any, item?: any): void {
    //   alert('hi');
    // }

    render() {
        var that = this;
        if (!this.Formlet){
          this.Formlet = this.props.getFormlet(this.props.index);
        }

        var tabIdx : number = 0;
        var rowIdx : number = 0;
        var width : number = this.state.width;
        Logger.logNotification('Window width : ' + width);


    
        return (
          <Stack horizontalAlign='stretch'  className='tn-card' id={this.Formlet?.ItemID}>
            <Stack verticalFill horizontalAlign='stretch'>
              <Stack horizontal horizontalAlign='stretch' className='tn-card-header clearfix'>
                <Stack.Item className="tn-card-header-text">
                  <TNFormletHeaderControl EntityLogicalName={this.props.entityName ?? ''} Title={this.Formlet?.Title ?? (this.props.entityName ?? '')} PageInfo={this.props.PageInfo} isReadOnly={true}  ></TNFormletHeaderControl>
                </Stack.Item>
                <Stack.Item  className="tn-card-header-menu">
                  <FormletActionBarControl 
                      Formlet={this.Formlet} 
                      Entity={this.state.Entity} 
                      ServiceResult={this.state.ServiceResult} 
                      isReadOnly={false} 
                      executeAction={this.executeCommandBarAction}
                      renderDocumentAction={this.onRenderDocumentAction}
                      IsLoading={this.state.isLoading}
                      PageInfo={this.props.PageInfo}
                      RenderKey={this.state.NavBarRenderKey}
                  />
                </Stack.Item>
              </Stack>
              {this.state && this.state.isLoading &&
                <MessageBar messageBarType={MessageBarType.warning}>
                  <ProgressIndicator label={this.state.loadingMessage} />
                </MessageBar>
              }
              {this.state && !this.state.isLoading &&
                <Separator />
              }
              <Stack verticalFill horizontalAlign='stretch' className='tn-card-body-stack' >
                {this.state.errorMessage && 
                    <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                            onDismiss={() => this.closeMessageBar()}
                            dismissButtonAriaLabel="Close"
                        >
                        {this.state.errorMessage}
                    </MessageBar>
                }
              <Stack verticalFill horizontalAlign='stretch' className='tn-card-body'>
                {that.Formlet && that.Formlet.Tabs && that.Formlet.Tabs.length === 1 && !that.Formlet.Tabs[0].IsTitleVisible &&
                <Stack verticalFill horizontalAlign='stretch' className='tn-tab-container' >
                  <TNTabView 
                              //getServiceResult={() => (this.state.ServiceResult? this.state.ServiceResult : null) } 
                             //Entity={this.state.Entity}
                             isreadonly={this.Formlet?.IsReadOnly ?? false}
                             //ServiceResult={this.state.ServiceResult}
                             invokeCommand={this.invokeCommand}
                              getTab={this.getTab} 
                              onAddRowFromGridEventHandler={this.addRowFromGridEventHandler}
                              invokeViewDesigner={this.invokeViewDesigner}
                              setSelection={this.setSelection}
                              //PageInfo={this.props.PageInfo}
                              index={0} 
                              dataKey={this.state.dataKey}
                              FormletContext={this.mContext}
                              //getEntity={() => this.state.Entity} 
                              //entityName={this.props.entityName} 
                              //applicationName={this.props.applicationName} 
                              //itemId={this.props.itemId} 
                              notifyContainerRendered={this.notifyContainerRendered} updateParenFormlet={this.updateFormlet}    ></TNTabView>
                  <TNOwnerEditControl Entity={this.state.Entity} isReadOnly={this.Formlet?.IsReadOnly ?? true}  />
                  <TNRecordSecurityInfoEditControl Formlet={this.Formlet} Entity={this.state.Entity} isReadOnly={this.Formlet?.IsReadOnly ?? true} tenantId={this.props.PageInfo.TenantId}  ></TNRecordSecurityInfoEditControl>
                </Stack>
                }
                {that.Formlet && that.Formlet.Tabs && (that.Formlet.Tabs.length > 1 || (that.Formlet.Tabs.length === 1 && that.Formlet.Tabs[0].IsTitleVisible) ) &&
                <Stack verticalFill horizontalAlign='stretch' className='tn-tab-container' >
                <Pivot aria-label={that.Formlet?.Title} className='tn-tab-container' >
                  {that.Formlet.Tabs.map((tab, tabidx) => {
                      return (<PivotItem key={tabidx+1}  className='tn-tab-item' headerText={tab.IsTitleVisible? tab.Title : ''}  headerButtonProps={{'data-order': tabIdx+1, 'data-title': tab.Title, disabled: !tab.IsTitleVisible}} >
                        <TNTabView  FormletContext={this.mContext} invokeViewDesigner={this.invokeViewDesigner}  isreadonly={this.Formlet?.IsReadOnly ?? false} invokeCommand={this.invokeCommand} getTab={this.getTab} index={tabIdx++} notifyContainerRendered={this.notifyContainerRendered} updateParenFormlet={this.updateFormlet} onAddRowFromGridEventHandler={this.addRowFromGridEventHandler} setSelection={this.setSelection} dataKey={that.state.dataKey}   ></TNTabView> </PivotItem>);
                      })
                  }
                </Pivot>
                <TNOwnerEditControl Entity={this.state.Entity} isReadOnly={this.Formlet?.IsReadOnly ?? true}  />
                <TNRecordSecurityInfoEditControl Formlet={this.Formlet} Entity={this.state.Entity} isReadOnly={this.Formlet?.IsReadOnly ?? true} tenantId={this.props.PageInfo.TenantId}  ></TNRecordSecurityInfoEditControl>
                </Stack>
                }
                {that && that.Formlet && that.Formlet.Rows && that.Formlet.Rows.length > 0 && false &&
                  that.Formlet!.Rows.map((row, localrowidx) => {
                      return (<TNRowView updateParenFormlet={this.updateFormlet} 
                                          //Entity={this.state.Entity}
                                          //ServiceResult={this.state.ServiceResult}
                                          invokeCommand={this.invokeCommand}
                                          isreadonly={this.Formlet?.IsReadOnly ?? false}
                                          key={localrowidx} 
                                          //getServiceResult={() => (this.state.ServiceResult ? this.state.ServiceResult : null)} 
                                          getRow={this.getRow} 
                                          dataKey={this.state.dataKey}
                                          index={rowIdx++} 
                                          //getEntity={() => this.state.Entity}  
                                          //entityName={this.props.entityName} 
                                          //applicationName={this.props.applicationName} 
                                          //itemId={this.props.itemId} 
                                          notifyContainerRendered={this.notifyContainerRendered}  
                                          onAddRowFromGridEventHandler={this.addRowFromGridEventHandler}
                                          invokeViewDesigner={this.invokeViewDesigner}
                                          setSelection={this.setSelection}
                                         //PageInfo={that.props.PageInfo}
                                          FormletContext={this.mContext}
                                          >
                                              
                              </TNRowView >);
                      })
                  
                }
              </Stack>
              </Stack>
            </Stack>
            <Panel isOpen={that.state.showAssignPanel} 
                headerText="Assign Record"
                onDismiss={(ev? : any) => {that.setState({showAssignPanel: false}); that.forceUpdate();}}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
              <RecordAssignControl
                entityId={that.props.itemId}
                entityName={that.props.entityName}
                tenantId=''
                Record={null}
                refreshRecord={(resultObj: any) => {that.internalRefreshEntity()}}
              >

              </RecordAssignControl>
            </Panel>

            {/* <Stack verticalFill className='tn-card ms-depth-8' horizontalAlign='stretch' >
              <Stack horizontal horizontalAlign='stretch' style={hdrStyle} className='tn-card-header'>
                  {this.Formlet?.DataSource?.EntityImageCSSClass &&
                    <FAIcon iconClassName={this.Formlet?.DataSource?.EntityImageCSSClass} ></FAIcon>
                  }
                  <h4>{this.Formlet?.Title}</h4>
              </Stack>
              <hr></hr>
            </Stack> */}
            </Stack>
        );
      }
}


export interface TNTabProps extends UIItemEditorProps, UIDataItemEditorProps {
  getTab : (index : number) => IllerisNinthUI.TNTab | undefined | null;
  //getServiceResult : () => IllerisNinthAPI.ServiceResult | null;
  notifyContainerRendered : (containerId: string) => void;
  updateParenFormlet : (sr : IllerisNinthAPI.ServiceResult) => void;
  onAddRowFromGridEventHandler : () => void;
  invokeViewDesigner : () => void;
  setSelection : (sel : Array<any>) => void;
  //PageInfo: PageInfo;
}

export interface TNTabState {
  
}


export class TNTabView extends React.Component<TNTabProps, TNTabState> {
  private Tab? : IllerisNinthUI.TNTab | null;
  constructor(props : TNTabProps){
      super(props);
      this.Tab = this.props.getTab(this.props.index);

      this.getSection.bind(this);

      this.state = {
        //dataKey: props.dataKey,
      }
  }
  public shouldComponentUpdate(nextProps: TNTabProps, nextState: TNTabState){
    if (nextProps.dataKey !== this.props.dataKey || this.props.isreadonly !== nextProps.isreadonly) {
        return true;
    }
    else{
        return false;
    }
  }
  private getSection = (idx: number) : IllerisNinthUI.TNSection | null =>{
    if (this.Tab && this.Tab.Sections && idx >= 0 && idx < this.Tab.Sections.length)
      return this.Tab.Sections[idx];
    else
      return null;
  }

  render() {
      var that = this;
      if (!this.Tab){
        this.Tab = this.props.getTab(this.props.index);
      }
      //var idx : number = 0;
  
      return (
        <Stack horizontalAlign='stretch'  className='tn-tab' id={this.Tab?.ItemID}>
         {that.Tab  && that.Tab.Sections  &&
          that.Tab.Sections.map((sect, idx) => {
            return (<TNSectionView key={idx} rowIdx={idx} 
                      //={this.props.Entity}
                      isreadonly={this.props.isreadonly}
                      invokeCommand={this.props.invokeCommand}
                      //ServiceResult={this.props.ServiceResult}
                      updateParenFormlet={this.props.updateParenFormlet}  
                      onAddRowFromGridEventHandler={this.props.onAddRowFromGridEventHandler}
                      invokeViewDesigner={this.props.invokeViewDesigner}
                      setSelection={this.props.setSelection}
                      //PageInfo={that.props.PageInfo}
                      //getServiceResult={this.props.getServiceResult} 
                      getSection={this.getSection} 
                      //getEntity={this.props.getEntity} 
                      index={idx++} 
                      dataKey={this.props.dataKey}
                      //entityName={this.props.entityName} 
                     // applicationName={this.props.applicationName} 
                      //itemId={this.props.itemId} 
                      FormletContext={this.props.FormletContext}
                      notifyContainerRendered={this.props.notifyContainerRendered} ></TNSectionView >);
          })
         } 
        </Stack>
      );
    }
}

export interface TNSectionViewProps  extends UIItemEditorProps, UIDataItemEditorProps {
  getSection : (index : number) => IllerisNinthUI.TNSection | undefined | null;
  //getServiceResult : () => IllerisNinthAPI.ServiceResult | null;
  notifyContainerRendered : (containerId: string) => void;
  updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
  rowIdx : number;
  onAddRowFromGridEventHandler : () => void;
  invokeViewDesigner : () => void;
  setSelection : (sel : Array<any>) => void;
}

export interface TNSectionViewState extends TNSectionViewProps {

}


export class TNSectionView extends React.Component<TNSectionViewProps, TNSectionViewState> {
  private Section? : IllerisNinthUI.TNSection | null;
  constructor(props : TNSectionViewProps){
      super(props);
      this.Section = this.props.getSection(this.props.index);

      this.getRow.bind(this);
  }
  public shouldComponentUpdate(nextProps: TNSectionViewProps, nextState: TNSectionViewState){
    if (nextProps.dataKey !== this.props.dataKey || this.props.isreadonly !== nextProps.isreadonly) {
        return true;
    }
    else{
        return false;
    }
  }
  private getRow = (idx: number) : IllerisNinthUI.FormletRow | null  =>{
    if (this.Section && this.Section.Rows && idx >= 0 && idx < this.Section.Rows.length)
      return this.Section.Rows[idx];
    else
      return null;
  }

  render() {
      var that = this;
      if (!this.Section){
        this.Section = this.props.getSection(this.props.index);
      }
      var idx = 0;
      return (
        <Stack horizontalAlign='stretch'  className='tn-section' id={this.Section?.ItemID}>
          {(this.props.rowIdx > 0 || (this.Section && this.Section.IsTitleVisible && this.props.rowIdx === 0)) &&
          <Separator alignContent="start">{this.Section && this.Section.IsTitleVisible? this.Section.Title : ''}</Separator>
          }
          <div className='ms-Grid ' dir='ltr'>
         {that.Section && that.Section.Rows &&
          that.Section.Rows.map((row, rowidx) => {
            return (<TNRowView updateParenFormlet={this.props.updateParenFormlet}  
                      //Entity={this.props.Entity}
                      isreadonly={this.props.isreadonly}
                      //ServiceResult={this.props.ServiceResult}
                      invokeCommand={this.props.invokeCommand}
                      key={rowidx} 
                      dataKey={this.props.dataKey}
                      //PageInfo={that.props.PageInfo}
                      onAddRowFromGridEventHandler={this.props.onAddRowFromGridEventHandler}
                      invokeViewDesigner={this.props.invokeViewDesigner}
                      setSelection={this.props.setSelection}
                      FormletContext={this.props.FormletContext}
                      //getServiceResult={this.props.getServiceResult} 
                      getRow={this.getRow} 
                      index={idx++} 
                      //getEntity={this.props.getEntity} 
                      //entityName={this.props.entityName} 
                      //applicationName={this.props.applicationName} 
                      //itemId={this.props.itemId} 
                      notifyContainerRendered={this.props.notifyContainerRendered} ></TNRowView >);
          })
         } 
         </div>
        </Stack>
      );
    }
}

export interface TNRowViewProps extends UIItemEditorProps, UIDataItemEditorProps  {
  getRow : (index : number) => IllerisNinthUI.FormletRow | undefined | null;
  //getServiceResult : () => IllerisNinthAPI.ServiceResult | null;
  notifyContainerRendered : (containerId: string) => void;
  updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
  onAddRowFromGridEventHandler : () => void;
  invokeViewDesigner : () => void;
  setSelection : (sel : Array<any>) => void;
}

export interface TNRowViewState extends TNRowViewProps {
  
}


export class TNRowView extends React.Component<TNRowViewProps, TNRowViewState> {
  private Row? : IllerisNinthUI.FormletRow | null;
  constructor(props : TNRowViewProps){
      super(props);
      this.Row = this.props.getRow(this.props.index);

      this.getColumn.bind(this);
  }

  private getColumn = (idx: number) : IllerisNinthUI.FormletColumn | null =>{
    if (this.Row && this.Row.Columns && idx >= 0 && idx < this.Row.Columns.length)
      return this.Row.Columns[idx];
    else
      return null;
  }

  private getGridFromRowItems = () : IllerisNinthUI.FlexGrid | null => {
    if (this.Row && this.Row.Items){
      for(var i = 0; i<this.Row.Items.length; i++){
        if (this.Row.Items[i] instanceof IllerisNinthUI.FlexGrid)
          return this.Row.Items[i] as IllerisNinthUI.FlexGrid;
      }
    }
    return null;
  }
  public shouldComponentUpdate(nextProps: TNRowViewProps, nextState: TNRowViewState){
    if (nextProps.dataKey !== this.props.dataKey || this.props.isreadonly !== nextProps.isreadonly) {
        return true;
    }
    else{
        return false;
    }
  }
  render() {
      var that = this;
      if (!this.Row){
        this.Row = this.props.getRow(this.props.index);
      }
      let idx: number = 0;
      let divId: string = '__tn-row_' + this.Row?.ItemID + '_' + Math.random().toString();
      return (
        <>
          <div className="ms-Grid-row tn-row" data-rowid={divId} /*id={nanoid()}*/>
            {that.Row && that.Row.Columns &&
              that.Row.Columns.map((cols, colidx) => {
                return (
                  <TNColumnView updateParenFormlet={this.props.updateParenFormlet}  
                                key={colidx} 
                                isreadonly={this.props.isreadonly}
                                //Entity={this.props.Entity}
                                //ServiceResult={this.props.ServiceResult}
                                invokeCommand={this.props.invokeCommand}
                               /// getServiceResult={this.props.getServiceResult} 
                                getColumn={this.getColumn} 
                                index={idx++} 
                                dataKey={this.props.dataKey}
                                //PageInfo={that.props.PageInfo}
                                //getEntity={this.props.getEntity} 
                                //entityName={this.props.entityName} 
                                //applicationName={this.props.applicationName} 
                                //itemId={this.props.itemId} 
                                notifyContainerRendered={this.props.notifyContainerRendered}
                                onAddRowFromGridEventHandler={this.props.onAddRowFromGridEventHandler}
                                invokeViewDesigner={this.props.invokeViewDesigner}
                                setSelection={this.props.setSelection}
                                FormletContext={this.props.FormletContext}
                                  >

                  </TNColumnView >);
              })
            } 
         </div>
         {that.Row && that.Row.Items && that.Row.Items.map((item, itemidx) => {
              return (
                <div className='ms-Grid-row tn-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxl12">
                        <TNItemView key={itemidx} 
                                    index={itemidx}
                                    //Entity={this.props.Entity}
                                    isreadonly={this.props.isreadonly}
                                    //ServiceResult={this.props.ServiceResult}
                                    invokeCommand={this.props.invokeCommand}
                                    updateParenFormlet={this.props.updateParenFormlet}  
                                    onAddRowFromGridEventHandler={this.props.onAddRowFromGridEventHandler}
                                    getGrid={that.getGridFromRowItems} 
                                    invokeViewDesigner={this.props.invokeViewDesigner}
                                    setSelection={this.props.setSelection}
                                    //PageInfo={that.props.PageInfo}
                                    dataKey={that.props.dataKey}
                                    FormletContext={this.props.FormletContext}
                                    getEditor={item instanceof IllerisNinthUI.TNEditorBase? () => item : () => null}  
                                    //getEntity={this.props.getEntity} 
                                    getContainer={item instanceof IllerisNinthUI.TNContainer ? () => item : () => null} 
                                    //itemId={this.props.itemId} 
                                    //getServiceResult={this.props.getServiceResult} 
                                    notifyContainerRendered={this.props.notifyContainerRendered}>
                                    
                        </TNItemView>
                    </div>
                </div>);
            })
          }

        </>
      );
    }
}


export interface TNColumnViewProps extends UIItemEditorProps, UIDataItemEditorProps {
  getColumn : (index : number) => IllerisNinthUI.FormletColumn | undefined | null;
  //getServiceResult : () => IllerisNinthAPI.ServiceResult | null;
  notifyContainerRendered : (containerId: string) => void;
  updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
  onAddRowFromGridEventHandler : () => void;
  invokeViewDesigner : () => void;
  setSelection : (sel : Array<any>) => void;
}

export interface TNColumnViewState extends TNColumnViewProps {

}


export class TNColumnView extends React.Component<TNColumnViewProps, TNColumnViewState> {
  private Column? : IllerisNinthUI.FormletColumn | null;
  constructor(props : TNColumnViewProps){
      super(props);

      this.Column = this.props.getColumn(this.props.index);

      this.getGrid.bind(this);
  }

  private getGrid = () : IllerisNinthUI.FlexGrid | null => {
    if (this.Column && this.Column.Items){
      for(var i = 0; i<this.Column.Items.length; i++){
        if (this.Column.Items[i] instanceof IllerisNinthUI.FlexGrid)
          return this.Column.Items[i] as IllerisNinthUI.FlexGrid;
      }
    }
    return null;
  }

  private getEntity = () : DynamicEntity | null | undefined => {
    return this.props.FormletContext.getEntity();
  }
  public shouldComponentUpdate(nextProps: TNColumnViewProps, nextState: TNColumnViewState){
    if (nextProps.dataKey !== this.props.dataKey || this.props.isreadonly !== nextProps.isreadonly) {
        return true;
    }
    else{
        return false;
    }
  }
  render() {
      var that = this;
      if (!this.Column){
        this.Column = this.props.getColumn(this.props.index);
      }
      
      var sz : number = (this.Column ? this.Column.Width : 12);
      if (!isNaN(sz) && sz > 12){
        sz = 12;
      }
      var sizeStr = 'ms-Grid-col ms-sm' + sz + ' ms-md' + sz + ' ms-lg' + sz + ' ms-xl' + sz + ' ms-xxl' + sz + ' ms-xxl' + sz + ' tn-column';
      //const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
      const colstyles : CSSProperties = { width : (100.0*sz/12).toFixed(2) + '%'}

      return (
        <>
          <div className={sizeStr} style={colstyles} id={this.Column?.ItemID}>
            {that.Column && that.Column.Items &&
              that.Column.Items.map((item, itemidx) => {
                return (<TNItemView updateParenFormlet={this.props.updateParenFormlet}  
                  index={itemidx}
                  //Entity={this.props.Entity}
                  //PageInfo={that.props.PageInfo}
                  isreadonly={this.props.isreadonly}
                  //ServiceResult={this.props.ServiceResult}
                  invokeViewDesigner={this.props.invokeViewDesigner}
                  invokeCommand={this.props.invokeCommand}
                  onAddRowFromGridEventHandler={this.props.onAddRowFromGridEventHandler}
                  //getServiceResult={this.props.getServiceResult} 
                  ViewType={item instanceof IllerisNinthUI.FlexGrid ? 'grid' : (item instanceof IllerisNinthUI.TNContainer ? 'container': 'editor')} 
                  getGrid={this.getGrid}  
                  getEditor={item instanceof IllerisNinthUI.TNEditorBase ? () => item : () => null}
                  getContainer={item instanceof IllerisNinthUI.TNContainer ? () => item : () => null}
                  //getEntity={this.getEntity}
                  key={itemidx}
                  dataKey={this.props.dataKey}
                  //entityName={this.props.entityName} applicationName={this.props.applicationName} itemId={this.props.itemId}
                  notifyContainerRendered={this.props.notifyContainerRendered}
                  setSelection={this.props.setSelection}
                  FormletContext={this.props.FormletContext}
                ></TNItemView >);
              })
            } 
         </div>
         
        </>
      );
    }
}

export type ItemViewType = 'editor' | 'grid' | 'container';
export interface TNItemViewProps extends UIDataItemEditorProps, UIItemEditorProps {
  itemId? : string;
  entityName? : string;
  applicationName? : string;
  ViewType? : ItemViewType ;
  getGrid: () => IllerisNinthUI.FlexGrid | null;
  getEditor: () => IllerisNinthUI.TNEditorBase | null;
  getContainer: () => IllerisNinthUI.TNContainer | null;
  //getEntity : () => DynamicEntity |null | undefined;
  //getServiceResult : () => IllerisNinthAPI.ServiceResult | null;
  notifyContainerRendered : (containerId: string) => void;
  updateParenFormlet : (sr: IllerisNinthAPI.ServiceResult) => void;
  onAddRowFromGridEventHandler : () => void;
  invokeViewDesigner : () => void;
  setSelection : (sel : Array<any>) => void;
}

export interface TNItemViewState  {
  EntityLastModifDate : Date;
}



export class TNItemView extends React.Component<TNItemViewProps, TNItemViewState> {
  private ViewType : ItemViewType;
  constructor(props : TNItemViewProps){
      super(props);
      this.state = {
        EntityLastModifDate : props.FormletContext.getEntity() ? props.FormletContext.getEntity()!.LastModifiedDate : new Date()
      }
      this.ViewType = props.ViewType ? props.ViewType : 'editor';

      this.getGrid.bind(this);
      this.getEditor.bind(this);
      this.getContainer.bind(this);
  }
  private getGrid = () : IllerisNinthUI.FlexGrid | null => {
    return this.props.getGrid();
  }
  private getEditor = () : IllerisNinthUI.TNEditorBase | null => {
    return this.props.getEditor();
  }
  private getEntity = () : DynamicEntity | null | undefined => {
    return this.props.FormletContext.getEntity();
  }
  private getContainer = () : IllerisNinthUI.TNContainer | null => {
    return this.props.getContainer();
  }
  private getServiceResult = () : IllerisNinthAPI.ServiceResult | null | undefined => {
    return this.props.FormletContext.getServiceResult();
  }

  shouldComponentUpdate(nextProps: TNItemViewProps, nextState: TNItemViewState) {
    Logger.logNotification('TNItemView::shouldComponentUpdate');

    if (this.state.EntityLastModifDate !== nextProps.FormletContext.getEntity()?.LastModifiedDate || this.props.dataKey !== nextProps.dataKey){
      return true;
    }
    else{
      return false;
    }
}
componentDidUpdate(prevProps : TNItemViewProps, prevState: TNItemViewState){
    Logger.logNotification('TNItemView::componentDidUpdate');
    if (prevProps.FormletContext.getEntity()?.LastModifiedDate !== this.props.FormletContext.getEntity()?.LastModifiedDate || this.props.dataKey !== prevProps.dataKey){
    }
}

private onAddRowFromGridEventHandler = () : void =>{
  this.props.onAddRowFromGridEventHandler();
}

  render() {
      var that = this;

      var entHSBindName : string | undefined | null = '';
      if (that.ViewType === 'editor'){
        //var ent : DynamicEntity | null = this.getEntity();
        if (this.props.FormletContext.getEntity()){
          entHSBindName = this.getEditor()?.HSBindName;
        }
      }
      return (
        <>
          {this.getEditor() instanceof IllerisNinthUI.TNXLanguageCombo &&
            <TNLanguageCombo isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey}  
                             FormletContext={this.props.FormletContext}   
                             />
          }
          {this.getEditor() instanceof IllerisNinthUI.TNSelect &&
            <TNSelectEditor isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey} 
                             FormletContext={this.props.FormletContext}    
                             />
          }
          {this.getEditor() instanceof IllerisNinthUI.TNLookup &&
            <TNLookupEditor isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey}    
                             FormletContext={this.props.FormletContext} 
                             />
          }
          {this.getEditor() instanceof IllerisNinthUI.TNDocumentInput &&
            <TNDocumentEditor isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey}
                             FormletContext={this.props.FormletContext}     
                             />
          }
          {this.getEditor() instanceof IllerisNinthUI.TNImageInput &&
            <TNImageEditor isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey}    
                             FormletContext={this.props.FormletContext} 
                             />
          }
          {this.getEditor() instanceof IllerisNinthUI.TNSearchLookup &&
            <TNSearchLookupEditor isreadonly={this.props.isreadonly} 
                             PageInfo={this.props.FormletContext.getPageInfo()} 
                             getEditor={this.props.getEditor} 
                             Entity={this.props.FormletContext.getEntity()} 
                             ServiceResult={this.props.FormletContext.getServiceResult()} 
                             invokeCommand={this.props.invokeCommand} 
                             itemId={this.props.itemId} 
                             dataKey={this.props.dataKey}     
                             FormletContext={this.props.FormletContext}
                             />
          }
          {this.ViewType === 'editor' && !(this.getEditor() instanceof IllerisNinthUI.TNXLanguageCombo) && !(this.getEditor() instanceof IllerisNinthUI.TNSelect) && !(this.getEditor() instanceof IllerisNinthUI.TNLookup) && !(this.getEditor() instanceof IllerisNinthUI.TNSearchLookup) && !(this.getEditor() instanceof IllerisNinthUI.TNImageInput) && !(this.getEditor() instanceof IllerisNinthUI.TNDocumentInput) &&
            <TNInputBoxView 
                  PageInfo={that.props.FormletContext.getPageInfo()} 
                  isreadonly={this.props.isreadonly}  
                  invokeCommand={this.props.invokeCommand} 
                  itemId={this.props.itemId} 
                  getEditor={this.getEditor} 
                  Entity={this.props.FormletContext.getEntity()} 
                  ServiceResult={this.props.FormletContext.getServiceResult()}
                  dataKey={this.props.dataKey}  
                  FormletContext={this.props.FormletContext}
                    />
          }
          {this.ViewType === 'grid' &&
            <TNFlexGridView 
                  updateParenFormlet={this.props.updateParenFormlet} 
                  isreadonly={this.props.isreadonly}
                  getGrid={this.getGrid} 
                  itemId={this.props.itemId} 
                  addRowEventHandler={this.onAddRowFromGridEventHandler}
                  invokeViewDesigner={this.props.invokeViewDesigner}
                  setSelection={this.props.setSelection}
                  PageInfo={that.props.FormletContext.getPageInfo()}
                  applicationName={this.props.applicationName}
                  dataKey={this.props.dataKey}  
                  FormletContext={this.props.FormletContext}
            />
          }
          {this.ViewType === 'container' &&
            <TNContainerView 
                getContainer={this.getContainer} 
                itemId={this.props.itemId} 
                notifyContainerRendered={this.props.notifyContainerRendered}
                entityId={that.props.FormletContext.getEntity()?.getId() ?? ''}   
                isReadOnly={this.getEditor()?.IsReadOnly || this.props.isreadonly} 
                getEntity={this.getEntity}
                dataKey={this.props.dataKey}
                PageInfo={this.props.FormletContext.getPageInfo()}
                FormletContext={this.props.FormletContext}
            />
          }
        </>
      );
    }
}