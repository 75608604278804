import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Config from "./config.json";
import reportWebVitals from './reportWebVitals';
//import * as serviceWorker from './serviceWorker';


//import { MsalProvider } from "@azure/msal-react";
//import { Configuration } from "@azure/msal-browser";

import { Providers } from '@microsoft/mgt-element';
import { MsalProvider } from '@microsoft/mgt-msal-provider';
import { initializeIcons } from '@fluentui/react';

// HELP : https://docs.microsoft.com/en-us/graph/toolkit/get-started/add-aad-app-registration

initializeIcons(/* optional base url */);


Providers.globalProvider = new MsalProvider({
  //clientId: '9a650983-9a10-42c7-bf93-4b1eb6397a65'
  clientId: Config.CLIENTID
});


// const configuration: Configuration = {
//   auth : {
//     clientId : '9a650983-9a10-42c7-bf93-4b1eb6397a65'
//   }
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//serviceWorker.unregister();

