import {  Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import React from "react";
import { EntityFormDefinition2 } from "../../Entities/EntityForm";


interface EntityFormTypeEditorProps {
    TenantId: string;
    vw: EntityFormDefinition2;
    isReadOnly : boolean;
}

interface EntityFormTypeEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  vw: EntityFormDefinition2;
  isReadOnly : boolean;
  options: IDropdownOption[];
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};

export class EntityFormTypeEditorControl extends React.Component<EntityFormTypeEditorProps, EntityFormTypeEditorState> {
    constructor(props: EntityFormTypeEditorProps) {
      super(props);

      let opts: IDropdownOption[] = new Array<IDropdownOption>();
      opts.push({key: 0, text: 'List View'});
      opts.push({key: 1, text: 'Create/Edit'});
      //opts.push({key: '1-1', text: '1-1'});
      //opts.push({key: 'N-N', text: 'N-N'});


      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        vw: props.vw,
        options: opts,
      }
  
    }
    componentDidMount() {
    }

    render() {
      var that = this;
  
      return (
        <Dropdown
          placeholder="Select an option"
          label="View Type"
          options={that.state.options}
          selectedKey={that.state.vw.ViewDataType}
          styles={dropdownStyles}
          onChange={this.onRelationTypeChanged}
          disabled={this.state.isReadOnly}
        />
      );
    }

    private onRelationTypeChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>{
      var it : EntityFormDefinition2 = this.state.vw;
      it.ViewDataType = option?.key as number ?? 0;
      if (it.ViewDataType === 0){
        it.ViewDataTypeName = 'List';
      }
      else if (it.ViewDataType === 1){
        it.ViewDataTypeName = 'Form';
      }
      this.setState({vw: it});
      this.forceUpdate();
    }

  }
