import { ContextualMenuItemType, IButtonProps,ICommandBarItemProps, IContextualMenuItem, IContextualMenuProps, ThemeGenerator } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { ControlSurfaceControl } from "./ControlSurface";
import { DesignerHelper } from "./DesignerHelper";
import { SurfaceBase } from "./SurfaceBase";

interface ColumnSurfaceProps extends SurfaceBase{
    Row : IllerisNinthUI.FormletRow;
    colIdx : number;
    rowIndex : number;
    Column: IllerisNinthUI.FormletColumn;
    renderKey: number;
}

interface ColumnSurfaceState extends ColumnSurfaceProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  ctxMenuItems : IContextualMenuProps;
}

export class ColumnSurfaceControl extends React.Component<ColumnSurfaceProps, ColumnSurfaceState> {
    constructor(props: ColumnSurfaceProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Row : props.Row,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        colIdx : props.colIdx,
        rowIndex : props.rowIndex,
        Column : props.Column,
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        renderKey: props.renderKey,
        appInfo : props.appInfo
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }
    componentDidUpdate(prevProps : ColumnSurfaceProps, prevState: ColumnSurfaceState){
      if (prevProps.renderKey !== this.props.renderKey){
        return true;
      }
      else{
        return false;
      }
    }
    // private removeRow = (evt : any) : void =>{
    //     var sect : IllerisNinthUI.TNSection = this.state.Section;
    //     var rowIndex :number = this.state.colIdx;
    //     sect.Rows.splice(rowIndex, 1);
    //     this.props.updateParent();
    // }
    // private addRow = (evt : any) : void =>{
    //     var sect : IllerisNinthUI.TNSection = this.state.Section;
    //     var rowIdx :number = this.state.colIdx;
    //     var newRow : IllerisNinthUI.FormletRow = DesignerHelper.CreateNewRow();
    //     sect.Rows.splice(rowIdx+1,0, newRow);
    //     this.props.updateParent();
    // }
    private buildCommandbar = () : void =>{
        let that = this;
        let items : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        
        let farItems : ICommandBarItemProps[] = new Array<ICommandBarItemProps>();

        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();
        // cm.items.push({
        //   key: 'removerow',
        //   text : 'Remove This Row',
        //   iconProps : {iconName : 'Delete'},
        //   onClick: this.removeRow.bind(that)
        // });
        // cm.items.push({
        //   key: 'addrow',
        //   text: 'Add Row',
        //   cacheKey: 'myCacheKey', // changing this key will invalidate this item's cleartabshe
        //   iconProps: { iconName: 'Add'},
        //   onClick: this.addRow.bind(that)
        // });
        // cm.items.push({
        //     key: 'separator1',
        //     itemType: ContextualMenuItemType.Divider,
        //   });

        this.setState({cmdBarItems : items, cmdBarFarItems: farItems, ctxMenuItems : cm});
    }


    // private onDragOver = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         //var dataObj : DesignSurfaceDragInfo = (item.dataTransfer.getData('text/plain')) as DesignSurfaceDragInfo;
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     item.dataTransfer.dropEffect = 'move';
    //                     return;
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    //     //console.log('onDragOver');
    // }
    // private onDragDrop = (item?: any, event?: DragEvent) : void =>{
    //     if (item && item.dataTransfer){
    //         var dataObjStr : string | undefined = (item.dataTransfer.getData('application/json'))?.toString();
    //         if (dataObjStr){
    //             var dataObj : DesignSurfaceDragInfo | undefined = JSON.parse(dataObjStr) as DesignSurfaceDragInfo;
    //             if (dataObj){
    //                 if (dataObj.DragItemType && dataObj.DragItemType === 'control'){
    //                     if (dataObj.ItemName === 'tabcontrol'){
    //                         let tc : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
    //                         var frm : IllerisNinthUI.Formlet = this.state.Form;
    //                         if (!frm.Tabs){
    //                             frm.Tabs = new Array<IllerisNinthUI.TNTab>();
    //                         }
    //                         frm.Tabs.push(tc);
    //                         this.forceUpdate();
    //                     }
    //                 }
    //             }
                
    //         }
    //         item.dataTransfer.dropEffect = 'none';
    //     }
    // }
    private updateParent = () : void =>{
      this.forceUpdate();
    }
    render() {
      let that = this;
      var widthStr : string = 'ms-Grid-col ms-sm' + this.state.Column.Width.toString() + ' tn-designer-rowsurface-col';
      return (
        <div className={widthStr}>
            <ControlSurfaceControl Row={this.props.Row} 
                                   Column={this.props.Column} 
                                   rowIdx={-1} 
                                   renderKey={that.state.renderKey}
                                   colIdx={this.props.colIdx} 
                                   updateParent={this.updateParent} 
                                   getEntity={this.props.getEntity} 
                                   showProperties={this.props.showProperties}
                                   appInfo={this.props.appInfo}
                                   >
                                   
            </ControlSurfaceControl>
        </div>
      );
    }
  }
  