import React from "react";
import { IllerisNinthUI } from "./MetaModel/UI/Formlet";
import { FormletView } from "./Controls/Editors/FormletEditors";
import { Logger } from "./Logger";
import { PageInfo } from "./PageInfo";
import { PageTitle } from "./PageTitle";
import { XView } from "./ViewManager";

interface EntityViewProps {
    //View? : XView | null;
    getViewHandler : () => XView | null | undefined;
    entityName? : string;
    action : string;
    applicationName : string;
    itemId? : string;
    PageInfo: PageInfo;
}

interface EntityViewState extends EntityViewProps {
  
}

export class EntityView extends React.Component<EntityViewProps, EntityViewState> {
    private View? : XView | null | undefined;
    private Page? : IllerisNinthUI.TNPage | null;
    constructor(props : EntityViewProps){
        super(props);

        this.View = props.getViewHandler();
        this.Page = XView.getPage(this.View);
        
        this.getFormlet.bind(this);
    }

    private getFormlet = (idx : number) : IllerisNinthUI.Formlet | null  =>{
      if (this.Page && idx >= 0 && idx < this.Page?.Forms.length){
        return this.Page.Forms[idx];
      }
      return null;
    }

    render() {
        var that = this;
        var pageTitle : string = '';
        if (!this.View || !this.Page || this.View.EntityName !== this.props.entityName || this.View.ActionName !== this.props.action){
          //debugger;

          this.View = this.props.getViewHandler();
          this.Page = XView.getPage(this.View);
        }

        if (this.View){
          pageTitle = (this.View.EntityName) + (this.View.ActionName ? this.View!.ActionName : '');
        }

        var idx : number =0;
        Logger.logNotification('Rendering View: ');
        Logger.logNotification(JSON.stringify(this.View? this.View : ''));

        //var cs: ClientState = ClientState.CurrentState();
        Logger.logNotification('Logging EntityView for Page ' + this.Page?.Name);
        return (
          <>
          <PageTitle title={pageTitle} ></PageTitle>
          {that.Page && that.Page.Forms.map((frm, itemidx) => {
                    return(<div className="ms-Grid-row" key={'row' + itemidx}> <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxl12">
                      <FormletView key={itemidx} index={idx} 
                          getFormlet={this.getFormlet}  
                          entityName={this.props.entityName} 
                          applicationName={this.props.applicationName} 
                          actionName={this.props.action} 
                          itemId={this.props.itemId}   
                          PageInfo={this.props.PageInfo}
                      /></div></div>);
                    //return(<Stack horizontalAlign='stretch' horizontal ><FormletView index={idx} getFormlet={this.getFormlet}  key={frm.ItemID} /></Stack>);
                })
            }
          </>
        );
      }
}