import { IllerisNinthUI } from "../../MetaModel/UI/Formlet"
import { Guid } from "../../Guid";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";
import { nanoid } from 'nanoid'
import { EntityRelationDefinition } from "../Entities/EntityRelation";
import { EntityDefinition } from "../Entities/EntityDefinition";

export class DesignerHelper{
    static CreateNewForm = (entName : string) : IllerisNinthUI.Formlet =>{
        let frm: IllerisNinthUI.Formlet = new IllerisNinthUI.Formlet();
        frm.Title = entName;
        frm.ItemID = nanoid();
        frm.Tabs = new Array<IllerisNinthUI.TNTab>();
        let tbgen : IllerisNinthUI.TNTab = new IllerisNinthUI.TNTab();
        tbgen.Title = 'General';
        tbgen.ItemID = nanoid();
        let sect : IllerisNinthUI.TNSection = new IllerisNinthUI.TNSection();
        sect.Title = 'General';
        sect.ItemID= nanoid();
        tbgen.Sections.push(sect);
        let row : IllerisNinthUI.FormletRow = new IllerisNinthUI.FormletRow();
        row.ItemID = nanoid();
        let col : IllerisNinthUI.FormletColumn = new IllerisNinthUI.FormletColumn();
        col.ItemID = nanoid();
        col.Width= 11;
        row.Columns.push(col);
        sect.Rows.push(row);
        frm.Tabs.push(tbgen);

        return frm;
    }

    static CreateNewSection = (title : string ) : IllerisNinthUI.TNSection =>{
        let sect : IllerisNinthUI.TNSection = new IllerisNinthUI.TNSection();
        sect.Title = title;
        sect.ItemID = nanoid();
        let row : IllerisNinthUI.FormletRow = new IllerisNinthUI.FormletRow();
        row.ItemID = nanoid();
        let col : IllerisNinthUI.FormletColumn = new IllerisNinthUI.FormletColumn();
        col.ItemID = nanoid();
        col.Width= 11;
        row.Columns.push(col);
        sect.Rows.push(row);
        return sect;
    }
    static CreateNewRow = () : IllerisNinthUI.FormletRow =>{
        let row : IllerisNinthUI.FormletRow = new IllerisNinthUI.FormletRow();
        row.ItemID = nanoid();
        let col : IllerisNinthUI.FormletColumn = new IllerisNinthUI.FormletColumn();
        col.ItemID = nanoid();
        col.Width= 11;
        row.Columns.push(col);
        return row;
    }
    static CreateNewColumn = (width : number) : IllerisNinthUI.FormletColumn =>{
        let col : IllerisNinthUI.FormletColumn = new IllerisNinthUI.FormletColumn();
        col.ItemID = nanoid();
        col.Width= width;
        return col;
    }

    static cloneRow = (row : IllerisNinthUI.FormletRow) : IllerisNinthUI.FormletRow =>{
        var newRow : IllerisNinthUI.FormletRow = new IllerisNinthUI.FormletRow();
        newRow.ItemID = nanoid();
        newRow.Width = row.Width;
        newRow.Columns = new Array<IllerisNinthUI.FormletColumn>();
        for(var x = 0; x<row.Columns.length; x++){
            var col : IllerisNinthUI.FormletColumn = new IllerisNinthUI.FormletRow();
            col.ItemID = nanoid();
            col.Width = row.Columns[x].Width;
            col.CSSClass = row.Columns[x].CSSClass;
            col.IsReadOnly = row.Columns[x].IsReadOnly;
            col.IsVisible = row.Columns[x].IsVisible;
            newRow.Columns.push(col);
        }
        return newRow;
    }

    static buildControl = (ctrlName: string)  : IllerisNinthUI.ElementBase | null=>{
        if (ctrlName && ctrlName.toLowerCase() === 'container'){
            var c : IllerisNinthUI.TNContainer = new IllerisNinthUI.TNContainer();
            c.ItemID = Guid.newGuid();
            return c;
        }
        return null;
    }

    static buildGrid = (ctrlName: string, srcEnt: EntityDefinition | undefined,  erd: EntityRelationDefinition | undefined | null = undefined, appName : string | undefined)  : IllerisNinthUI.FlexGrid=>{
        var c : IllerisNinthUI.FlexGrid = new IllerisNinthUI.FlexGrid();
        c.Name = ctrlName;
        c.ItemID = Guid.newGuid();
        c.PKFieldName = 'Id';
        
        
        c.EnableSelect= true;
        c.Paging.EnablePaging = true;
        c.Paging.PageSize = 25;
        c.DataSource.DataSourcePropertyName= 'Values';
        c.MinRows = 10;
        c.AutoLoad = true;

        if (erd){
            c.EntityName = erd?.TargetEntityName;
            c.EntityPKNameIsLastURISegment = true;

            c.AddEvent.ShowAddButton = true;
            c.AddEvent.RowAddURI = `/view/${appName ?? 'core'}/${erd.TargetEntityName}/Edit?action=create&${srcEnt?.LogicalName}Id={Id}&backuri=/view/${appName ?? 'core'}/${srcEnt?.LogicalName}/edit/{Id}`;

            c.EditEvent.ShowEditButton = true;
            c.EditEvent.RowEditURI = `/view/${appName ?? 'core'}/${erd.TargetEntityName}/edit/{Id}`;

            c.DeleteEvent.ShowDeleteButton = true;
            c.DeleteEvent.RowDeleteURI = `//view/${appName ?? 'core'}/${erd.TargetEntityName}/edit/{Id}`;

            c.DataSource.LoadURI = `/api/v2.0/terranova/xdata/core/${erd.TargetEntityName}?$filter=${srcEnt?.LogicalName}Id == "{Id}" `;
            
        }

        return c;

    }

    static buildEditor = (prop : EntityPropertyDefinition) : IllerisNinthUI.TNEditorBase | null=>{
        if (prop.DataTypeString?.toLowerCase() === 'string'){
            var txtEdit : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            txtEdit.HSBindName = prop.DisplayName;
            txtEdit.LabelText = prop.DisplayName;
            txtEdit.Name = prop.DisplayName;
            txtEdit.RowCount = 1;
            txtEdit.Type = 'text';
            txtEdit.ItemID = Guid.newGuid();
            return txtEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'timestamp'){
            var dtEdit : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            dtEdit.HSBindName = prop.DisplayName;
            dtEdit.LabelText = prop.DisplayName;
            dtEdit.Name = prop.DisplayName;
            dtEdit.RowCount = 1;
            dtEdit.Type = 'date';
            dtEdit.ItemID = Guid.newGuid();
            return dtEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'datetime'){
            var dtEdit2 : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            dtEdit2.HSBindName = prop.DisplayName;
            dtEdit2.LabelText = prop.DisplayName;
            dtEdit2.Name = prop.DisplayName;
            dtEdit2.RowCount = 1;
            dtEdit2.Type = 'date';
            dtEdit2.ItemID = Guid.newGuid();
            return dtEdit2;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'image'){
            var imgEdit : IllerisNinthUI.TNImageInput = new IllerisNinthUI.TNImageInput();
            imgEdit.HSBindName = prop.DisplayName;
            imgEdit.LabelText = prop.DisplayName;
            imgEdit.Name = prop.DisplayName;
            imgEdit.Height = 128;
            imgEdit.Width = 128;
            imgEdit.ItemID = Guid.newGuid();
            return imgEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'double'){
            var dblEdit : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            dblEdit.HSBindName = prop.DisplayName;
            dblEdit.LabelText = prop.DisplayName;
            dblEdit.Name = prop.DisplayName;
            dblEdit.RowCount = 1;
            dblEdit.ItemID = Guid.newGuid();
            return dblEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'byte[]'){
            var fileEdit : IllerisNinthUI.TNDocumentInput = new IllerisNinthUI.TNDocumentInput();
            fileEdit.HSBindName = prop.DisplayName;
            fileEdit.LabelText = prop.DisplayName;
            fileEdit.Name = prop.DisplayName;
            fileEdit.Height = 128;
            fileEdit.Width = 128;
            fileEdit.ItemID = Guid.newGuid();
            return fileEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'boolean'){
            var boolEdit : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            boolEdit.HSBindName = prop.DisplayName;
            boolEdit.LabelText = prop.DisplayName;
            boolEdit.Name = prop.DisplayName;
            boolEdit.RowCount = 1;
            boolEdit.Type = 'checkbox';
            boolEdit.ToggleOnText = 'Yes';
            boolEdit.ToggleOffText = 'No';
            boolEdit.ItemID = Guid.newGuid();
            return boolEdit;
        }
        else if (prop.DataTypeString?.toLowerCase() === 'number'){
            var numEdit : IllerisNinthUI.TNInputBox = new IllerisNinthUI.TNInputBox();
            numEdit.HSBindName = prop.DisplayName;
            numEdit.LabelText = prop.DisplayName;
            numEdit.Name = prop.DisplayName;
            numEdit.RowCount = 1;
            numEdit.Type = 'number'
            numEdit.ItemID = Guid.newGuid();
            return numEdit;
        }
        return null;
    }
}