import {  DefaultButton, Dropdown, IDropdownOption, Stack, TextField } from "@fluentui/react";
import React from "react";
import { DesignTimeModelHelper } from "../../../../Helpers/DesignTimeModelHelper";
import { AuthorProfile } from "../../../Entities/AuthorProfile";
import { EntityCustomPrivilegeDefinition } from "../../../Entities/EntityCustomPrivilegeDefinition";
import { EntityDefinition } from "../../../Entities/EntityDefinition";
import { EntityPrivilegeDefinition } from "../../../Entities/EntityPrivilegeDefinition";

interface CustomPrivilegeSurfaceEditorProps  {
    isReadOnly : boolean;
    tenantId: string;
    appId: string;
    customPriv: EntityCustomPrivilegeDefinition;
    saveAndClose: (obj? : EntityCustomPrivilegeDefinition) => void;
}

interface CustomPrivilegeSurfaceEditorState extends CustomPrivilegeSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  items: IDropdownOption[];
}


export class CustomPrivilegeSurfaceEditorControl extends React.Component<CustomPrivilegeSurfaceEditorProps, CustomPrivilegeSurfaceEditorState> {
    constructor(props: CustomPrivilegeSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly : props.isReadOnly,
        appId: props.appId,
        tenantId: props.tenantId,
        items: new Array<IDropdownOption>(),
        saveAndClose: props.saveAndClose,
        customPriv: props.customPriv
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
        this.loadEntities();
    }

    private loadEntities = () : void => {
      let dh: DesignTimeModelHelper = new DesignTimeModelHelper();
      let that = this;
      dh.loadEntitiesInModel(this.props.tenantId, this.props.appId)
        .then(function(res: Array<EntityDefinition>){
          let items: IDropdownOption[] = new Array<IDropdownOption>();
          items.push({key: '', text: 'Not Entity Bound'});
          if (res){
            res.forEach(function(item: EntityDefinition, index: number){
              items.push({key: item.LogicalName, text: item.DisplayName });
            })
          }
          that.setState({items: items, errorMessage: ''});
          that.forceUpdate();
        })
        .catch(function(err:any){
          that.setState({errorMessage: err.message});
        })
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
      
      return (
        <Stack verticalFill horizontalAlign="stretch">
          <Dropdown label={'Entity Name'} disabled={that.props.isReadOnly} options={that.state.items} selectedKey={that.state.customPriv.EntityName} style={{width: '100%'}} onChange={that.setEntityName} ></Dropdown>
          <TextField label="Event Name" disabled={that.props.isReadOnly} value={that.state.customPriv.EventName} style={{width: '100%'}} onChange={that.setEventName} />

          <DefaultButton text="Save" style={{marginTop: '25px'}} onClick={this.onSave} ></DefaultButton>
        </Stack>
         
      );
    }
    private onSave = (ev?: any) : void => {
      if (!this.state.customPriv.EventName){
        this.setState({errorMessage: 'You must choose an event name.'});
        return;
      }
      this.setState({errorMessage: ''});
      this.props.saveAndClose(this.state.customPriv);
    }

    private setEntityName = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) : void =>{
      let that = this;
      that.state.customPriv.EntityName = option?.key.toString() ?? '';
      that.forceUpdate();
    }
    private setEventName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) : void =>{
      let that = this;
      that.state.customPriv.EventName = newValue ?? '';
      that.forceUpdate();
    }
  }
  