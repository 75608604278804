import { IContextualMenuItem, IContextualMenuProps, Label, Stack, TextField } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../Entities/EntityProperty";

interface DummyContainerControlProps {
    Container : IllerisNinthUI.TNContainer;
    updateParent : () => void;
    getEntity : () => EntityDefinition;
    showEditorProperties: (ed : IllerisNinthUI.ElementBase, prop? : EntityPropertyDefinition) => void;
}

interface DummyContainerControlState extends DummyContainerControlProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  ctxMenuItems : IContextualMenuProps;
}

export class DummyContainerControlControl extends React.Component<DummyContainerControlProps, DummyContainerControlState> {
    constructor(props: DummyContainerControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        updateParent : props.updateParent,
        getEntity: props.getEntity,
        ctxMenuItems : { items : new Array<IContextualMenuItem>()},
        Container: props.Container,
        showEditorProperties : props.showEditorProperties
      }
  
    }
  
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
        let cm : IContextualMenuProps = this.state.ctxMenuItems;
        cm.items = new Array<IContextualMenuItem>();

        this.setState({ctxMenuItems : cm});
    }

    private onDoubleClickEditor = (evt : any ) : void =>{
        debugger;
        this.props.showEditorProperties(this.state.Container, undefined);
    }
    
    render() {
      return (
        <Stack horizontalAlign='stretch' verticalFill className='tn-designer-dummyeditor'  style={{width: '100%', minHeight: '50px', verticalAlign:'middle', marginTop: '15px'}}>
          <Stack.Item align="start">
            <Label onDoubleClick={this.onDoubleClickEditor} style={{width: '100%'}}>Custom Control PlaceHolder</Label>
          </Stack.Item>
        </Stack>
      );
    }
}
  