import { DefaultButton, DetailsList, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IModalProps, Link, mergeStyleSets, Modal, Panel, PrimaryButton, ProgressIndicator, SelectionMode, Stack } from "@fluentui/react";
import { getItemStyles } from "@fluentui/react/lib/components/ContextualMenu/ContextualMenu.classNames";
import dayjs from "dayjs";
import React from "react";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { ItemDesignerPage } from "../ItemDesignerPage";
import { EntityViewDefinitionSurfaceEditorControl } from "./DesignSurfaces/SurfaceEditors/EntityViewDefinitionSurfaceEditor";
import { DesignTimeModelHelper } from "../Helpers/DesignTimeModelHelper";
import { EntityDefinition } from "./Entities/EntityDefinition";
import { EntityViewDefinition } from "./Entities/EntityView";
import { SystemApplicationInfo } from "./Entities/SystemApplicationInfo";

interface EntityViewsViewControlProps {
    ent: EntityDefinition;
    tenantId : string;
    app: SystemApplicationInfo;
}

interface EntityViewsViewControlState extends EntityViewsViewControlProps {
  StateKey : number;
  errorMessage? : string;
  allViews : Array<EntityViewDefinition>;
  columns : IColumn[];
  isbusy : boolean;
  newView?: EntityViewDefinition;
  showAddEditViewPanel: boolean;
  showEntityViewDesigner: boolean;
  currentEditableView?: EntityViewDefinition | null;
  
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


export class EntityViewsViewControl extends React.Component<EntityViewsViewControlProps, EntityViewsViewControlState> {
    constructor(props: EntityViewsViewControlProps) {
      super(props);
      this.state = {
        StateKey : 0,
        ent : props.ent,
        tenantId : props.tenantId,
        allViews : new Array<EntityViewDefinition>(),
        columns : new Array<IColumn>(),
        isbusy : false,
        showAddEditViewPanel: false,
        showEntityViewDesigner: false,
        app: props.app
      }
  
    }
  
    componentDidMount() {
      var that = this;
      this.loadViews().then(function(res: any){
        that.forceUpdate();
      });
      this.buildColumns();
    }
    private onEditView = (vw : EntityViewDefinition) : void =>{
      this.setState({newView: vw, showAddEditViewPanel: true});
      this.forceUpdate();
    }
    private onShowViewDesigner = (vw : EntityViewDefinition) : void =>{
      this.setState({currentEditableView: vw, showEntityViewDesigner: true});
      this.forceUpdate();
    }
    private buildColumns = () : void =>{
        let cols : IColumn[] = new Array<IColumn>();
        cols.push(
          {
            key: 'editicon',
            name: 'Edit',
            ariaLabel: 'Edit',
            isIconOnly: true,
            fieldName: 'Id',
            minWidth: 32,
            maxWidth: 32,
            isRowHeader: true,
            isResizable: false,
            data: 'string',
            isPadded: true,
            onRender: (item: EntityViewDefinition) => {
              return <IconButton iconProps={{iconName: 'Edit'}} onClick={(ev?: any) => {this.onShowViewDesigner(item);}}></IconButton>;
            },
          });
        cols.push(
            {
              key: 'coldisplayname',
              name: 'Display Name',
              ariaLabel: 'Display Name',
              isIconOnly: false,
              fieldName: 'DisplayName',
              minWidth: 80,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              onRender: (item: EntityViewDefinition) => {
                return <Link onClick={() => this.onEditView(item)}>{item.DisplayName}</Link>;
              },
            });
        cols.push(
            {
              key: 'colcreatedate',
              name: 'Create Date',
              ariaLabel: 'Create Date',
              isIconOnly: false,
              fieldName: 'CreateDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: EntityViewDefinition) => {
                return <span>{dayjs(item.CreateDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        cols.push(
            {
              key: 'colmoddate',
              name: 'Modified Date',
              ariaLabel: 'Modified Date',
              isIconOnly: false,
              fieldName: 'LastModifiedDate',
              minWidth: 120,
              maxWidth: 180,
              isRowHeader: true,
              isResizable: true,
              data: 'datetime',
              isPadded: true,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onRender: (item: EntityViewDefinition) => {
                return <span>{dayjs(item.LastModifiedDate).format('DD/MM/YYYY HH:mm:ss')}</span>;
              },
              onColumnClick: this._onColumnClick,
            }); 
        this.setState({columns : cols});
    }

    private loadViews = async () : Promise<void> => {
        //let uri : string = '/api/v2.0/terranova/xdata/core/runtime/entityviewdefinition?$filter=EntityName == "' + this.props.ent.DisplayName + '"&$orderby=Order ASC, DisplayName ASC';
        //let uri: string = `/api/v1.0/terranova/metamodel/${this.props.tenantId}/${this.props.ent.Id}/properties`;
        //let emp : EntityPersistanceManager = new EntityPersistanceManager();
        let dh: DesignTimeModelHelper = new DesignTimeModelHelper()
        var that = this;
        that.setState({isbusy : true});
        dh.loadEntityViews(this.props.tenantId,this.props.ent.ApplicationId, this.props.ent.Id)
          .then(function(res: Array<EntityViewDefinition>){
            that.setState({isbusy : false});
            that.setState({allViews : res});
            that.forceUpdate();
          })

        // emp.executeAPIAny(uri, null, 'GET', false)
        //     .then(function(res : any){
        //         debugger;
        //         let items : Array<EntityViewDefinition> = new Array<EntityViewDefinition>();
        //         if (res && res.Values && Array.isArray(res.Values)){
        //             res.Values.forEach(function(item : any, idx: number){
        //                 let it : EntityViewDefinition = new EntityViewDefinition();
        //                 it.Id = item.Id;
        //                 it.DisplayName = item.DisplayName;
        //                 it.ApplicationId = item.ApplicationId;
        //                 it.ViewName= item.ViewName;
        //                 it.ViewXml= item.ViewData;
        //                 it.Status= item.Status;
        //                 it.StatusReason= item.StatusReason;
        //                 it.CreateDate = item.CreateDate;
        //                 it.LastModifiedDate = item.LastModifiedDate;
        //                 items.push(it);    
        //             });
        //             that.setState({isbusy : false});
        //             that.setState({allViews : items});
        //             that.forceUpdate();
        //         }
        //     });
    }
    private _getKey = (item: any, index?: number): string => {
        return item.key;
    }
    private _onItemInvoked(item: any): void {
        alert(`Item invoked: ${item.name}`);
    }
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
          if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
          } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
          }
        });
        const newItems = this._copyAndSort(this.state.allViews, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
          columns: newColumns,
          allViews: newItems,
        });
      
    }
    private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }    
    
    public shouldComponentUpdate = (nextProps: EntityViewsViewControlProps, nextState: EntityViewsViewControlState) : boolean =>{
        if (nextProps.tenantId !== this.props.tenantId || (nextProps.ent && this.props.ent && nextProps.ent.Id !== this.props.ent.Id )){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : EntityViewsViewControlProps, prevState: EntityViewsViewControlState){
        var that = this;
        if (prevProps.ent && this.props.ent && prevProps.ent.Id !== this.props.ent.Id){
            this.loadViews().then(function(res: any){
                that.forceUpdate();
              });
        }
    }
    private onAddView = (evt: any) => {
      let vw : EntityViewDefinition = new EntityViewDefinition();
      vw.EntityId = this.props.ent.Id;
      vw.ApplicationId = this.props.ent.ApplicationId;
      this.setState({newView: vw, showAddEditViewPanel: true});
      this.forceUpdate();
    }

    render() {
      var that = this;
  
      return (
          <Stack verticalFill horizontalAlign='stretch' className='tn-designer-propertiesview'>
              {this.state.isbusy &&
                <ProgressIndicator label="Loading..." description="" />
              }
              <DetailsList
              items={this.state.allViews}
              compact={true}
              columns={this.state.columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              //selection={this._selection}
              //selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
            />
            {this.state.showAddEditViewPanel  &&
              <Panel
                isOpen={this.state.showAddEditViewPanel}
                onDismiss={(ev: any) => {that.setState({showAddEditViewPanel: false}); that.forceUpdate();}}
                
                closeButtonAriaLabel="Close"
                headerText="Add/Edit View"
              >
                <EntityViewDefinitionSurfaceEditorControl TenantId={this.props.tenantId} ent={this.state.ent!} vw={this.state.newView!} isReadOnly={false} closeAndSave={that.saveAndCloseAddEntityView}  />
              </Panel>
              }
            <DefaultButton label='Add View' text='Add View' iconProps={{iconName: 'Add'}} onClick={this.onAddView} style={{width: '180px', marginTop: '20px'}} ></DefaultButton>
            <Modal
              titleAriaId={'Edit Entity View'}
              isOpen={this.state.showEntityViewDesigner}
              onDismiss={(ev?: any) => {that.setState({showEntityViewDesigner: false}); that.forceUpdate(); }}
              isBlocking={false}
              containerClassName={contentStyles.container}
              layerProps={{ styles: { root: { zIndex: 0 } } }}
              overlay={{ styles: { root: { zIndex: 0 } } }}
            >
              <div className={contentStyles.header}>
                <span id=''>Edit Entity View</span>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={{ iconName: 'Cancel' }}
                  ariaLabel="Close popup modal"
                  onClick={(ev?: any) => {that.setState({showEntityViewDesigner: false}); that.forceUpdate(); }}
                />
              </div>
              <div className={contentStyles.body}>
                <Stack verticalFill horizontalAlign="stretch">
                        <ItemDesignerPage surface='view' 
                            view={this.state.currentEditableView!} 
                            appInfo={this.props.app} 
                            entInfo={this.props.ent}
                            tenantId={this.props.tenantId}
                            close={() => {that.setState({showEntityViewDesigner: false}); that.forceUpdate();}}
                        ></ItemDesignerPage>
                      </Stack>
                </div>
            </Modal>
          </Stack>
      );
    }

    private saveAndCloseAddEntityView = () : void => {
      let that = this;
      let mh : DesignTimeModelHelper = new DesignTimeModelHelper();
      if (this.state.newView && !this.state.newView.Id){
        let vw : EntityViewDefinition = this.state.newView!;
        vw.EntityId = this.state.ent.Id;
        mh.createNewView(this.props.tenantId, this.props.ent.ApplicationId, this.props.ent, vw).then(function(res: EntityViewDefinition){
          let allViews : EntityViewDefinition[] = that.state.allViews;
          allViews.push(res);
          that.setState({errorMessage: '', newView: res, showAddEditViewPanel: false, allViews: allViews});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
      else{
        let vw : EntityViewDefinition = this.state.newView!;
        vw.EntityId = this.state.ent.Id;
        mh.updateEntityView(this.props.tenantId, this.props.ent.ApplicationId, this.props.ent, vw).then(function(res: EntityViewDefinition){
          let allViews : EntityViewDefinition[] = that.state.allViews;
          allViews.push(res);
          that.setState({errorMessage: '', newView: res, showAddEditViewPanel: false, allViews: allViews});
          that.forceUpdate();
        })
        .catch(function(err: any){
          that.setState({errorMessage: err.message});
        })
      }
    }
  }
  