import * as msal from "@azure/msal-browser";
import axios from "axios";
import { ClientState, UserProfile } from "../ClientState";
import Config from "../config.json";

const msalConfig = {
  auth: {
    //clientId: "9a650983-9a10-42c7-bf93-4b1eb6397a65",
    clientId: Config.CLIENTID,
    authority: "https://login.microsoftonline.com/common/",
    //redirectUri: "http://localhost:3000",
    redirectUri: Config.REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: msal.LogLevel,
        message: any,
        containsPii: any
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ["User.Read"],
  prompt: 'select_account'
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
/*const tokenRequest = {
  scopes: ["User.Read", "Mail.Read"],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};*/

const myMSALObj = new msal.PublicClientApplication(msalConfig);

let username = "";

/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
myMSALObj
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error(error);
  });

function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = myMSALObj.getAllAccounts();

  if (currentAccounts.length === 0) {
    return;
  } else if (currentAccounts.length > 1) {
    // Add your account choosing logic here
    console.warn("Multiple accounts detected.");
  } else if (currentAccounts.length === 1) {
    username = currentAccounts[0].username;
    //showWelcomeMessage(username);
  }
}

function handleResponse(response: msal.AuthenticationResult | null) {
  //debugger;
  if (response !== null && response.account !== null) {
    username = response!.account.username;
    
    var st : ClientState = ClientState.CurrentState();
    st.IsAuthenticated = !response.accessToken;
    if (!st.UserProfile){
        st.UserProfile = new UserProfile();
    }
    st.UserProfile.DisplayName = response.account?.name ? response.account?.name : '';
    st.UserProfile.UserId = response.account?.localAccountId ? response.account?.localAccountId : '';
    st.UserProfile.EmailAddress = response.account?.username ? response.account?.username : '';
    st.UserProfile.MicrosoftTenantId = response.account?.tenantId ? response.account?.tenantId : '';
    st.UserProfile.LoginName = response.account?.username ? response.account?.username : '';
    st.UserProfile.ValidUntil = response.expiresOn;
    if (st.UserProfile.UserId)
        st.IsAuthenticated = true;
    else
        st.IsAuthenticated = false;
    ClientState.SaveState(st);
    window.location.reload();

  } else {
    selectAccount();

  }
}

async function signInRedirect() : Promise<void> {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  
  return myMSALObj.loginRedirect(loginRequest);
}

async function signInPopup() : Promise<msal.AuthenticationResult | null> {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  return myMSALObj
    .loginPopup(loginRequest)
    .then(function(response: msal.AuthenticationResult | null){
      if (response !== null && response.account !== null) {
        username = response!.account.username;
        
        var st : ClientState = ClientState.CurrentState();
        st.IsAuthenticated = !response.accessToken;
        if (!st.UserProfile){
            st.UserProfile = new UserProfile();
        }
        st.UserProfile.DisplayName = response.account?.name ? response.account?.name : '';
        st.UserProfile.UserId = response.account?.localAccountId ? response.account?.localAccountId : '';
        st.UserProfile.EmailAddress = response.account?.username ? response.account?.username : '';
        st.UserProfile.MicrosoftTenantId = response.account?.tenantId ? response.account?.tenantId : '';
        st.UserProfile.LoginName = response.account?.username ? response.account?.username : '';
        st.UserProfile.ValidUntil = response.expiresOn;
        if (st.UserProfile.UserId)
            st.IsAuthenticated = true;
        else
            st.IsAuthenticated = false;
        ClientState.SaveState(st);
        return response;
    
      } else {
        selectAccount();
        return response;
      }
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
    //return null;
}

async function signOut() : Promise<void> {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  // Choose which account to logout from by passing a username.
  var acc: msal.AccountInfo | null = myMSALObj.getAccountByUsername(username);
  var logoutRequest: msal.EndSessionRequest = {
    account: acc ? acc : undefined,
  };

  return myMSALObj.logout(logoutRequest);
}

function getTokenRedirect(request: msal.SilentRequest) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  //debugger;
  var cs = ClientState.CurrentState();
  var acc: msal.AccountInfo | null = myMSALObj.getAccountByUsername(cs && cs.IsAuthenticated && cs.UserProfile ? cs.UserProfile.LoginName : username);
  request.account = acc ? acc : undefined;

  return myMSALObj.acquireTokenSilent(request).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using redirect"
    );
    if (error instanceof msal.InteractionRequiredAuthError || error instanceof msal.BrowserAuthError) {
      // fallback to interaction when silent call fails
      var nr : msal.RedirectRequest = {
        scopes : request.scopes,
        account : request.account,
        redirectUri : request.redirectUri,
        prompt : 'select_account' 
      }
      return myMSALObj.acquireTokenRedirect(nr);
    } else {
      console.warn(error);
    }
  });
}

function getTokenPopup(request: msal.SilentRequest) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  //debugger;
  var acc: msal.AccountInfo | null = myMSALObj.getAccountByUsername(username);
  request.account = acc ? acc : undefined;

  return myMSALObj.acquireTokenSilent(request).catch((error) => {
    console.warn("silent token acquisition fails. acquiring token using popup");
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return myMSALObj
        .acquireTokenPopup(request)
        .then((tokenResponse) => {
          console.log(tokenResponse);
          return tokenResponse;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.warn(error);
    }
  });
}

/*
function seeProfile() {
    getTokenRedirect(loginRequest)
        .then(response => {
            callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
        }).catch(error => {
            console.error(error);
        });
}

function readMail() {
    getTokenRedirect(tokenRequest)
        .then(response => {
            callMSGraph(graphConfig.graphMailEndpoint, response.accessToken, updateUI);
        }).catch(error => {
            console.error(error);
        });
}*/

export class MSALHelper {
  private mBaseURI : string;
  constructor() {
    if (Config.SERVER_URI){
      this.mBaseURI = Config.SERVER_URI;
    }
    else{
      this.mBaseURI = 'https://localhost:44326/';
    }
  }

  public static getDefaultScope = () : msal.SilentRequest => {
    var callRequest : msal.SilentRequest = {
      //scopes: ["api://f5534f60-6b19-4ab2-945d-2e54f23f5c40/access.server"],
      scopes: [Config.API_SCOPE],
      forceRefresh : false
    };
    return callRequest;
  }

  public signInRedirect = async () : Promise<void> => {
    return signInRedirect();
  }
  public signInPopup = async () : Promise<msal.AuthenticationResult |null> => {
    return signInPopup();
  }

  public signOut = async ()  : Promise<void> => {
    return signOut();
  }
  private logOffState(){
    var st : ClientState = ClientState.CurrentState();
    if (!st.UserProfile){
        st.UserProfile = new UserProfile();
    }
    st.IsAuthenticated = false;
    ClientState.SaveState(st);
  }
  private persistState(res : msal.AuthenticationResult){
    var st : ClientState = ClientState.CurrentState();
    st.IsAuthenticated = !res.accessToken;
    if (!st.UserProfile){
        st.UserProfile = new UserProfile();
    }
    st.UserProfile.DisplayName = res.account?.name ? res.account?.name : '';
    st.UserProfile.UserId = res.account?.localAccountId ? res.account?.localAccountId : '';
    st.UserProfile.EmailAddress = res.account?.username ? res.account?.username : '';
    st.UserProfile.MicrosoftTenantId = res.account?.tenantId ? res.account?.tenantId : '';
    st.UserProfile.LoginName = res.account?.username ? res.account?.username : '';
    st.UserProfile.ValidUntil = res.expiresOn;
    if (st.UserProfile.UserId)
        st.IsAuthenticated = true;
    else
        st.IsAuthenticated = false;
    ClientState.SaveState(st);
  }

  public execAnonymousApiCallGet(uri : string) : Promise<any> {
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);

    return axios.get(fullUrl).then(function(res: any){
      if (res && res.data){
          return res.data;
      }
      return null;
    }).catch(function(error: any){
        //Logger.logError('Failed to load views : ' + error.message);
        return Promise.reject(error);
    })
  }

  public execApiCallGet(callScopes: string[], uri: string) : Promise<any> {
    var that = this;
    var req: msal.SilentRequest = {
      scopes: callScopes,
      forceRefresh: false,
      resourceRequestUri: uri,
    };
    //debugger;
    try{
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);
        req.resourceRequestUri = fullUrl;
        return getTokenRedirect(req)
        .then(function (res: void | msal.AuthenticationResult | undefined) {
          //debugger;
          if (!res) {
            return Promise.reject('Authentication Failed');
          }
          that.persistState(res);

          return fetch(fullUrl, {
            mode: "cors",
            credentials: "include",
            headers: {
              Authorization: `Bearer  ${res.accessToken}`,
              //'Content-Type': 'application/x-www-form-urlencoded' ,
              "Content-Type": "application/json",
              "Origin": Config.ORIGIN,
              //'Access-Control-Allow-Origin' : '*',
              //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
          })
            .then((response) => response.json())
            .then((data) => {
              //debugger;
              //alert(JSON.stringify(data));
              return data;
            })
            .catch((error : any) =>{
              console.log(error);
              return Promise.reject(error);
            });

          //return Promise.reject('NULL value returned');
        })
        .catch((error : any) => {
            //that.logOffState();
          //debugger;
          console.error(error);
          return Promise.reject(error);
        });
      
    }
    catch(error){
      console.log(error);
      return Promise.reject(error);
    }

    
  }

  public execApiCallPost(callScopes: string[], uri: string, data: any, contentType: string = 'application/json') : Promise<any> {
    var that = this;
    var req: msal.SilentRequest = {
      scopes: callScopes,
      forceRefresh: false,
      resourceRequestUri: uri,
    };
    //debugger;
    try{
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);
        req.resourceRequestUri = fullUrl;
        return getTokenRedirect(req)
        .then(function (res: void | msal.AuthenticationResult | undefined) {
          //debugger;
          if (!res) {
            return Promise.reject('Authentication Failed');
          }
          that.persistState(res);

          return fetch(fullUrl, {
            method: 'POST',
            mode: "cors",
            credentials: "include",
            headers: {
              Authorization: `Bearer  ${res.accessToken}`,
              //'Content-Type': 'application/x-www-form-urlencoded' ,
              "Content-Type": contentType,
              "Origin": Config.ORIGIN,
              //'Access-Control-Allow-Origin' : '*',
              //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            body : data
          })
            .then((response) => response.json())
            .then((data) => {
              //debugger;
              //alert(JSON.stringify(data));
              return data;
            })
            .catch((error : any) =>{
              console.log(error);
              return Promise.reject(error);
            });

          //return Promise.reject('NULL value returned');
        })
        .catch((error : any) => {
            //that.logOffState();
          //debugger;
          console.error(error);
          return Promise.reject(error);
        });
      
    }
    catch(error){
      console.log(error);
      return Promise.reject(error);
    }

    
  }

  public execApiCallPatch(callScopes: string[], uri: string, data: any, contentType: string = 'application/json') : Promise<any> {
    var that = this;
    var req: msal.SilentRequest = {
      scopes: callScopes,
      forceRefresh: false,
      resourceRequestUri: uri,
    };
    //debugger;
    try{
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);
        req.resourceRequestUri = fullUrl;
        return getTokenRedirect(req)
        .then(function (res: void | msal.AuthenticationResult | undefined) {
          //debugger;
          if (!res) {
            return Promise.reject('Authentication Failed');
          }
          that.persistState(res);

          return fetch(fullUrl, {
            method: 'PATCH',
            mode: "cors",
            credentials: "include",
            headers: {
              Authorization: `Bearer  ${res.accessToken}`,
              //'Content-Type': 'application/x-www-form-urlencoded' ,
              "Content-Type": contentType,
              "Origin": Config.ORIGIN,
              //'Access-Control-Allow-Origin' : '*',
              //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            body : data
          })
            .then((response) => response.json())
            .then((data) => {
              //debugger;
              //alert(JSON.stringify(data));
              return data;
            })
            .catch((error : any) =>{
              console.log(error);
              return Promise.reject(error);
            });

          //return Promise.reject('NULL value returned');
        })
        .catch((error : any) => {
            //that.logOffState();
          //debugger;
          console.error(error);
          return Promise.reject(error);
        });
      
    }
    catch(error){
      console.log(error);
      return Promise.reject(error);
    }

    
  }
  public execApiCallPut(callScopes: string[], uri: string, data: any, contentType: string = 'application/json') : Promise<any> {
    var that = this;
    var req: msal.SilentRequest = {
      scopes: callScopes,
      forceRefresh: false,
      resourceRequestUri: uri,
    };
    //debugger;
    try{
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);
        req.resourceRequestUri = fullUrl;
        return getTokenRedirect(req)
        .then(function (res: void | msal.AuthenticationResult | undefined) {
          //debugger;
          if (!res) {
            return Promise.reject('Authentication Failed');
          }
          that.persistState(res);

          return fetch(fullUrl, {
            method: 'PUT',
            mode: "cors",
            credentials: "include",
            headers: {
              Authorization: `Bearer  ${res.accessToken}`,
              //'Content-Type': 'application/x-www-form-urlencoded' ,
              "Content-Type": contentType,
              "Origin": Config.ORIGIN,
              //'Access-Control-Allow-Origin' : '*',
              //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            body : data
          })
            .then((response) => response.json())
            .then((data) => {
              //debugger;
              //alert(JSON.stringify(data));
              return data;
            })
            .catch((error : any) =>{
              console.log(error);
              return Promise.reject(error);
            });

          //return Promise.reject('NULL value returned');
        })
        .catch((error : any) => {
            //that.logOffState();
          //debugger;
          console.error(error);
          return Promise.reject(error);
        });
      
    }
    catch(error){
      console.log(error);
      return Promise.reject(error);
    }

    
  }
  public execApiCallDelete(callScopes: string[], uri: string, data: any, contentType: string = 'application/json') : Promise<any> {
    var that = this;
    var req: msal.SilentRequest = {
      scopes: callScopes,
      forceRefresh: false,
      resourceRequestUri: uri,
    };
    //debugger;
    try{
      var fullUrl : string = uri;
      if (!fullUrl.toLowerCase().startsWith('http'))
        fullUrl = this.mBaseURI + (uri.length > 0 && uri[0] === '/' ? uri.substr(1) : uri);
        req.resourceRequestUri = fullUrl;
        return getTokenRedirect(req)
        .then(function (res: void | msal.AuthenticationResult | undefined) {
          //debugger;
          if (!res) {
            return Promise.reject('Authentication Failed');
          }
          that.persistState(res);

          return fetch(fullUrl, {
            method: 'DELETE',
            mode: "cors",
            credentials: "include",
            headers: {
              Authorization: `Bearer  ${res.accessToken}`,
              //'Content-Type': 'application/x-www-form-urlencoded' ,
              "Content-Type": contentType,
              "Origin": Config.ORIGIN,
              //'Access-Control-Allow-Origin' : '*',
              //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            //body : data
          })
            .then((response) => response.json())
            .then((data) => {
              //debugger;
              //alert(JSON.stringify(data));
              return data;
            })
            .catch((error : any) =>{
              console.log(error);
              return Promise.reject(error);
            });

          //return Promise.reject('NULL value returned');
        })
        .catch((error : any) => {
            //that.logOffState();
          //debugger;
          console.error(error);
          return Promise.reject(error);
        });
      
    }
    catch(error){
      console.log(error);
      return Promise.reject(error);
    }

    
  }
}
