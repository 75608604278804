//Navigation/AppDataV2

import { SilentRequest } from "@azure/msal-browser";
import { Label, MessageBar, MessageBarType, ProgressIndicator, Stack } from "@fluentui/react";
import React from "react";
import { ClientState } from "./ClientState";
import { EntityDefinition } from "./designers/Entities/EntityDefinition";
import { EntityModelHelper } from "./designers/EntityModelHelper";
import { FAIcon } from "./FAIcon";
import { MSALHelper } from "./Helpers/MSALHelper";
import { SessionStorageManager, SessionStorageManagerKeyManager } from "./StorageManager";

  
export interface AppTileItem {
    Version: string;
    AppCode: string;
    HashValue?: any;
    TileName: string;
    Order: number;
    ApplicationId: string;
    IsActive: boolean;
    IsHidden: boolean;
    IsPublic: boolean;
    AppLevel: number;
    AppLevelName: string;
    IsLoaded: boolean;
    AuthorName: string;
    AuthorUri: string;
    ImageCSSClass: string;
    EntryPoint: string;
    LastInstalledVersion: string;
    Id: string;
    DisplayName: string;
    CreateDate: Date;
    LastModifiedDate: Date;
    CreatedBy: string;
    LastModifiedBy: string;
    DeleteState: number;
    Status: number;
    StatusReason: number;
    StatusName?: any;
    StatusReasonName?: any;
    RecordHash?: any;
    RowVersion: number[];
    SourceId?: any;
    OwnerId?: any;
    OwningTeamId?: any;
    OwningOrganizationId: string;
    OwningTenantId: string;
}



  interface AppTileGridProps {
      className? : string;
  }
  
  interface AppTileGridState {
    errorMessage? : string;
    isloading : boolean;
    isauthenticated : boolean;
    isLoaded: boolean;
  }

  interface AppTileProps {
    className? : string;
    Item? : AppTileItem;
    TileIndex : number;
    onClicked : (tileItem : AppTileItem) => void;
}

interface AppTileState {}
  
  export class AppTileGrid extends React.Component<AppTileGridProps, AppTileGridState> {
    private mTiles : Array<AppTileItem>;
    private mLoadCount: number = 0;
    constructor(props: AppTileGridProps) {
      super(props);
      this.state = {
        errorMessage : '',
        isloading : false,
        isauthenticated: false,
        isLoaded: false
      }
      this.mTiles = new Array<AppTileItem>();

      this.closeMessageBar.bind(this);
    }

    private loadData = () : void => {
      var that = this;
      var uri : string = '/api/v2.0/terranova/xnav/tiles';
      var cs: ClientState = ClientState.CurrentState();
      if (!cs.IsAuthenticated)
          return;
      
      var cr : SilentRequest = MSALHelper.getDefaultScope();
      var mh : MSALHelper = new MSALHelper();

      try{
        that.setState({isloading: true, isauthenticated : cs.IsAuthenticated});
        mh.execApiCallGet(cr.scopes, uri).then(function(res : any){
          that.setState({isloading: false});
            if (Array.isArray(res)){
                for(var i = 0; i<res.length; i++){
                    var it : AppTileItem = res[i] as AppTileItem;
                    that.mTiles.push(it);
                }
                that.setState({isLoaded: true, errorMessage: ''});
                that.forceUpdate();
            }
        }).catch(function(error){
          console.error(error);
          that.setState({errorMessage : error.message, isloading : false});

          if (that.mLoadCount < 3){
            that.mLoadCount += 1;
            that.loadData();
          }
        })
      }
      catch(error : any){
        debugger;
        that.setState({errorMessage : error?.message ?? 'unknown error'});
        if (that.mLoadCount < 3){
          that.mLoadCount += 1;
          that.loadData();
        }
      }
    }
  
    componentDidMount() {
      this.loadData();
    }

    public onTileButtonClicked = (tileItem : AppTileItem) : void => {
      var cs: ClientState = ClientState.CurrentState();
      if (tileItem && cs)
          //alert(tileItem.AppCode);
          if (cs && cs.UserProfile){

            // Load entities in model
            let emh : EntityModelHelper = new EntityModelHelper();
            emh.loadEntitiesInModel(cs.UserProfile!.TerraNovaTenantUniqueName, tileItem.AppCode).then(function(entRes: Array<EntityDefinition>){
              SessionStorageManager.setItem(SessionStorageManagerKeyManager.getTenantEntityListKey(cs.UserProfile!.TerraNovaTenantUniqueName), JSON.stringify(entRes));
              window.location.href = '/cview/' + cs.UserProfile!.TerraNovaTenantUniqueName + '/' + tileItem.AppCode;
            })
          }
      else
          alert('??');
    }
  
    closeMessageBar = () => {
      this.setState({ errorMessage: '' });
    };
    render() {
      var that = this;
      //var cs: ClientState = ClientState.CurrentState();
      var tileCnt = 1;
      this.onTileButtonClicked = this.onTileButtonClicked.bind(this);
  
      return (
          
          <Stack horizontalAlign="stretch" verticalAlign="stretch" horizontal className="AppTileGrid" wrap={true} style={{marginTop: '25px'}}>
            {this.state.errorMessage &&
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={() => this.closeMessageBar()}
                dismissButtonAriaLabel="Close"
                className='appTileGridMessageBar'
              >
                {that.state.errorMessage}
              </MessageBar>
            }
          {this.state.isloading &&
            <Stack horizontal horizontalAlign="stretch" style={{width: '100%'}}>
              <ProgressIndicator label="Loading..." styles={{root: {width: '100%'}}}/>
            </Stack>
          }
          {that.mTiles.length === 0 && !that.state.errorMessage && that.state.isauthenticated &&
            <p style={{marginTop:'100px'}}>
              {that.state.isLoaded && 
                <span>It looks like you do not have access to any apps. You must either register your organization, or ask access by your company administrator.</span>
              }
              {!that.state.isLoaded && 
                <span>Please wait while we're loading your apps.</span>
              }
            </p>
          }
              {that.mTiles.length !== 0 && that.mTiles.map(card => {
                      return <ModernAppTile Item={card} key={card.ApplicationId} TileIndex={tileCnt++} onClicked={this.onTileButtonClicked} />;
                  })
              }
          </Stack>
        
      );
    }
  }
  

export class AppTile extends React.Component<AppTileProps, AppTileState> {
  private mTile? : AppTileItem;
  private mTileIdx : number;
  constructor(props: AppTileProps) {
    super(props);
    this.mTile = props.Item;
    this.mTileIdx = props.TileIndex;
    this.onTileClick = this.onTileClick.bind(this);
  }

  componentDidMount() {}

  public onTileClick(ev?: any): void {
      if (this.mTile)
          this.props.onClicked(this.mTile!);
      else{
          console.log('ERROR: Clicked tile without bound application.');
      }
  }

  render() {
    var that = this;
    //var cs: ClientState = ClientState.CurrentState();

    var tileClassName = 'tile tile' + this.mTileIdx.toString();
    

    return (
        <a href="#" className="tileLink" onClick={this.onTileClick} style={{cursor: 'pointer !important'}}>
        <Stack verticalAlign="stretch" horizontalAlign="stretch" className={tileClassName} style={{cursor: 'pointer'}}>
            <Label className="tilecaption tilecaption-text" style={{cursor: 'pointer'}}>{that.mTile?.DisplayName}</Label>
            <FAIcon iconClassName={that.mTile?.ImageCSSClass} iconColorScheme='color-white' iconSize='xxxl' ></FAIcon>
        </Stack>
        </a>
    );
  }
}
  

interface ModernAppTileProps {
  className? : string;
  Item? : AppTileItem;
  TileIndex : number;
  onClicked : (tileItem : AppTileItem) => void;
}

interface ModernAppTileState {}

export class ModernAppTile extends React.Component<ModernAppTileProps, ModernAppTileState> {
  private mTile? : AppTileItem;
  private mTileIdx : number;
  constructor(props: AppTileProps) {
    super(props);
    this.mTile = props.Item;
    this.mTileIdx = props.TileIndex;
    this.onTileClick = this.onTileClick.bind(this);
  }

  componentDidMount() {}

  public onTileClick(ev?: any): void {
      if (this.mTile)
          this.props.onClicked(this.mTile!);
      else{
          console.log('ERROR: Clicked tile without bound application.');
      }
  }

  render() {
    var that = this;
    //var cs: ClientState = ClientState.CurrentState();

    var tileClassName = 'tile tile' + this.mTileIdx.toString();
    

    return (
        <a href="#" className="tileLink" onClick={this.onTileClick} style={{cursor: 'pointer !important'}}>
        <Stack verticalAlign="stretch" horizontalAlign="stretch" className={tileClassName + ' ms-depth-64'} style={{cursor: 'pointer'}}>
            <Label className="tilecaption tilecaption-text" style={{cursor: 'pointer'}}>{that.mTile?.DisplayName}</Label>
            <FAIcon iconClassName={that.mTile?.ImageCSSClass} iconColorScheme='color-white' iconSize='xxxl' ></FAIcon>
        </Stack>
        </a>
    );
  }
}