import {  CommandBar, DefaultButton, ICommandBarItemProps, IconButton, MessageBar, MessageBarType, Panel, Stack, TextField } from "@fluentui/react";
import React from "react";
import { SideBarData, SideBarGroup, SideBarItem, SideBarTab } from "../../../SideBar";
import { NavigationBar } from "../../Entities/NavigationBar";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";

interface NavBarItemsDefinitionSurfaceEditorProps {
    navBar: NavigationBar;
    app: SystemApplicationInfo;
    tenantId: string;
    isReadOnly : boolean;
    save : (bClose: boolean) => void;
}

interface NavBarItemsDefinitionSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  notificationMessage?: string;
  isbusy : boolean;
  busyMessage: string;
  navBar: NavigationBar;
  app: SystemApplicationInfo;
  tenantId: string;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  searchEntOrPrivilegeName: string;
  showEditTabPanel? : boolean;
  editTab?: SideBarTab;
  showEditGroupPanel? : boolean;
  editGroup?: SideBarGroup;
  showEditItemPanel? : boolean;
  editItem?: SideBarItem;
}

export class NavBarItemsDefinitionSurfaceEditorControl extends React.Component<NavBarItemsDefinitionSurfaceEditorProps,NavBarItemsDefinitionSurfaceEditorState> {
    private mSideBar: SideBarData | undefined;
    constructor(props:NavBarItemsDefinitionSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        navBar: props.navBar,
        tenantId: props.tenantId,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        busyMessage: '',
        searchEntOrPrivilegeName: '',
      }
      this.initNavBar();
    }
    private initNavBar = () : void => {
      if (!this.mSideBar){
        if (this.props.navBar && this.props.navBar.NavBarData){
          try{
            this.mSideBar = JSON.parse(this.props.navBar.NavBarData) as SideBarData;
            if (!this.mSideBar.Tabs){
              this.mSideBar.Tabs = new Array<SideBarTab>();
            }
          }
          catch(err: any){
            this.mSideBar = {
              Tabs: new Array<SideBarTab>()
            }
          }
        }
        else{
          this.mSideBar = {
            Tabs: new Array<SideBarTab>()
          }
        }
      }
    }
    componentDidMount() {
      this.initNavBar();
      this.initBarItems(true);
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }

    private onSave = (ev?: any) : void => {
      if (this.mSideBar && this.mSideBar.Tabs && this.mSideBar.Tabs.length > 0){
        let str: string = JSON.stringify(this.mSideBar);
        this.props.navBar.NavBarData = str;
        this.props.save(false);
      }
    }
    private initBarItems = (bSetState: boolean): Array<ICommandBarItemProps> => {
        let that = this;
        let res: ICommandBarItemProps[] = new Array<ICommandBarItemProps>();
        res.push({
            key: "add",
            text: "Add Tab",
            ariaLabel: "Add Tab",
            iconOnly: false,
            iconProps: { iconName: "Add" },
            onClick: (ev? :any) => {that.onAddTab();}
            });
        res.push({
          key: "save",
          text: "Save",
          ariaLabel: "Save",
          iconOnly: false,
          iconProps: { iconName: "Save" },
          onClick: that.onSave
        });
        if (bSetState){
          that.setState({cmdBarItems: res});
        }
        return res;
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='' >
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            {that.state.notificationMessage &&
              <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={(ev?: any) => {that.setState({notificationMessage : ''})}}
                dismissButtonAriaLabel="Close"
              >
                {that.state.notificationMessage}
              </MessageBar>
            }
            <CommandBar
              items={that.state.cmdBarItems}
              //farItems={that.mFarItems}
              //farItems={that.state.cmdBarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
              className="header-navbar-panel-navbar"
            />
            <p>Construct the Navigation Bar by adding Tabs, groups and items.</p>
            <Stack horizontalAlign="stretch" horizontal tokens={{childrenGap: 25}} >
              <Stack.Item style={{width: '50%'}} >
                <Stack verticalFill  horizontalAlign='stretch' tokens={{childrenGap: 25}}>
                {this.mSideBar?.Tabs.map(function(it: SideBarTab, idx: number){
                  return (
                    <Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: 25}}>
                      <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: '15'}}>
                        <Stack.Item style={{width:'70%'}}>
                          <DefaultButton text={it.Name} iconProps={{ iconName: it.ImageCSSClassName}} label={it.Name} onClick={(ev?: any) => {that.onEditTab(it); }} style={{width: '100%'}} />
                        </Stack.Item>
                        <Stack.Item style={{width:'30%'}}>
                          <IconButton iconProps={{iconName: 'Delete'}}  onClick={(ev?: any) => {that.onDeleteTab(it); }} />
                          <IconButton iconProps={{iconName: 'Edit'}}  onClick={(ev?: any) => {that.onEditTab(it); }} />
                          <IconButton iconProps={{iconName: 'Add'}}  onClick={(ev?: any) => {that.onAddGroupToTab(it); }} />
                        </Stack.Item>
                      </Stack>
                      {it.Groups && it.Groups.map(function(grpItem: SideBarGroup, grpIdx: number){
                        return (
                          <Stack verticalFill horizontalAlign="stretch" tokens={{childrenGap: '15'}}>
                            <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: '15'}} style={{marginLeft: '20px'}}>
                              <Stack.Item style={{width:'70%'}}>
                                <DefaultButton text={grpItem.Name} iconProps={{ iconName: grpItem.ImageCSSClassName}} label={grpItem.Name} onClick={(ev?: any) => {that.onEditGroup(grpItem); }} style={{width: '100%'}} />
                              </Stack.Item>
                              <Stack.Item style={{width:'30%'}}>
                                <IconButton iconProps={{iconName: 'Delete'}}  onClick={(ev?: any) => {that.onDeleteGroup(it,grpItem); }} />
                                <IconButton iconProps={{iconName: 'Edit'}}  onClick={(ev?: any) => {that.onEditGroup(grpItem); }} />
                                <IconButton iconProps={{iconName: 'Add'}}  onClick={(ev?: any) => {that.onAddItemToGroup(grpItem); }} />
                              </Stack.Item>
                            </Stack>
                            {grpItem.Items && grpItem.Items.map(function(item: SideBarItem, itemIdx: number){
                              return (
                              <Stack horizontal horizontalAlign="stretch" tokens={{childrenGap: '15'}} style={{marginLeft: '20px'}}>
                                <Stack.Item style={{width:'80%'}}>
                                  <DefaultButton text={item.Name} iconProps={{ iconName: item.ImageCSSClassName}} label={item.Name} onClick={(ev?: any) => {that.onEditItem(item); }} style={{width: '100%'}} />
                                </Stack.Item>
                                <Stack.Item style={{width:'20%'}}>
                                  <IconButton iconProps={{iconName: 'Delete'}}  onClick={(ev?: any) => {that.onDeleteItem(grpItem, item); }} />
                                  <IconButton iconProps={{iconName: 'Edit'}}  onClick={(ev?: any) => {that.onEditItem(item); }} />
                                </Stack.Item>
                              </Stack>)
                            })
                            }
                          </Stack>
                        )
                      })
                      }
                    </Stack>
                  );
                })
                }
                <DefaultButton text="Add Tab" iconProps={{iconName: 'Add'}} label={'Add tab'} onClick={(ev?: any) => {that.onAddTab(); }} style={{height: '40px', minHeight: '40px'}} />
                </Stack>
              </Stack.Item>
              <Stack.Item style={{width: '50%'}}>
              </Stack.Item>
            </Stack>
            {this.state.showEditTabPanel && 
              <Panel
                isOpen={this.state.showEditTabPanel}
                onDismiss={(ev?: any) => {that.setState({showEditTabPanel: false})}}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Tab"
              >
                <Stack verticalFill horizontalAlign='stretch' tokens={{childrenGap: 25}}>
                  <TextField label="Tab Name" value={that.state.editTab?.Name} onChange={(ev?: any, newVal?: string) => { that.state.editTab!.Name = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="Image" value={that.state.editTab?.ImageCSSClassName} onChange={(ev?: any, newVal?: string) => { that.state.editTab!.ImageCSSClassName = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <DefaultButton text="Close" label="Close" onClick={(ev?: any) => {that.setState({showEditTabPanel: false}); that.forceUpdate();}}></DefaultButton>
                </Stack>
              </Panel>
              }
              {this.state.showEditGroupPanel && 
              <Panel
                isOpen={this.state.showEditGroupPanel}
                onDismiss={(ev?: any) => {that.setState({showEditGroupPanel: false})}}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Group"
              >
                <Stack verticalFill horizontalAlign='stretch' tokens={{childrenGap: 25}}>
                  <TextField label="Tab Name" value={that.state.editGroup?.Name} onChange={(ev?: any, newVal?: string) => { that.state.editGroup!.Name = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="Image" value={that.state.editGroup?.ImageCSSClassName} onChange={(ev?: any, newVal?: string) => { that.state.editGroup!.ImageCSSClassName = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <DefaultButton text="Close" label="Close" onClick={(ev?: any) => {that.setState({showEditGroupPanel: false}); that.forceUpdate();}}></DefaultButton>
                </Stack>
              </Panel>
              }
              {this.state.showEditItemPanel && 
              <Panel
                isOpen={this.state.showEditItemPanel}
                onDismiss={(ev?: any) => {that.setState({showEditItemPanel: false})}}
                
                closeButtonAriaLabel="Close"
                headerText="Edit Group"
              >
                <Stack verticalFill horizontalAlign='stretch' tokens={{childrenGap: 25}}>
                  <TextField label="Tab Name" value={that.state.editItem?.Name} onChange={(ev?: any, newVal?: string) => { that.state.editItem!.Name = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="Image" value={that.state.editItem?.ImageCSSClassName} onChange={(ev?: any, newVal?: string) => { that.state.editItem!.ImageCSSClassName = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="URI" value={that.state.editItem?.URI} onChange={(ev?: any, newVal?: string) => { that.state.editItem!.URI = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="Entity Name" value={that.state.editItem?.EntityName} onChange={(ev?: any, newVal?: string) => { that.state.editItem!.EntityName = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <TextField label="Action" value={that.state.editItem?.Action} onChange={(ev?: any, newVal?: string) => { that.state.editItem!.Action = newVal ?? ''; that.forceUpdate();}}></TextField>
                  <DefaultButton text="Close" label="Close" onClick={(ev?: any) => {that.setState({showEditItemPanel: false}); that.forceUpdate();}}></DefaultButton>
                </Stack>
              </Panel>
              }
        </Stack>
      );
    }

    private onDeleteItem = (grp: SideBarGroup, item: SideBarItem) : void => {
      let idx: number | undefined = grp.Items.indexOf(item);
      if (idx && (idx > -1)){
        grp.Items.splice(idx, 1);
        this.forceUpdate();
      }
    }
    private onEditItem = (item: SideBarItem) : void => {
      this.setState({editItem: item, showEditItemPanel: true});
    }

    private onAddItemToGroup = (grp: SideBarGroup) : void => {
      if (!grp.Items){
        grp.Items = new Array<SideBarItem>();
      }
      grp.Items.push({Name: `Item ${grp.Items.length + 1}`, ImageCSSClassName: '', Action: '', EntityName: '', URI: '', Key: ''});
      this.forceUpdate();
    }

    private onDeleteGroup = (tab: SideBarTab, grp: SideBarGroup) : void => {
      let idx: number | undefined = tab.Groups.indexOf(grp);
      if (idx && (idx > -1)){
        tab.Groups.splice(idx, 1);
        this.forceUpdate();
      }
    }
    private onEditGroup = (grp: SideBarGroup) : void => {
      this.setState({editGroup: grp, showEditGroupPanel: true});
    }
    private onAddGroupToTab = (tab: SideBarTab) : void => {
      if (!tab.Groups){
        tab.Groups = new Array<SideBarGroup>();
      }
      tab.Groups.push({Name: `Tag Group ${tab.Groups.length + 1}`, ImageCSSClassName: '', Items: new Array<SideBarItem>()});
      this.forceUpdate();
    }

    private onEditTab = (tab: SideBarTab) : void => {
      this.setState({editTab: tab, showEditTabPanel: true});
    }
    private onDeleteTab = (tab: SideBarTab) : void => {
      if (!this.mSideBar){
        this.initNavBar();
      }
      let idx: number | undefined = this.mSideBar?.Tabs.indexOf(tab);
      if (idx && (idx > -1)){
        this.mSideBar?.Tabs.splice(idx, 1);
        this.forceUpdate();
      }
    }
    private onAddTab = () : void => {
      debugger;
      if (!this.mSideBar){
        this.initNavBar();
      }
      this.mSideBar?.Tabs.push( {Name: `New Tab ${this.mSideBar.Tabs.length + 1}`, ImageCSSClassName: '', Groups: new Array<SideBarGroup>() });
      this.forceUpdate();
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.navBar && !this.state.app.DisplayName){
        this.setState({errorMessage : 'The DisplayName is required'});
        return;
      }
      /*if (this.state.ent && this.state.app && this.state.ent.LogicalName){
        var len : number = this.state.app.en
        this.setState({errorMessage : 'The Tile Name class is required'});
        return;
      }*/
      this.props.save(true);
    }
    
  }
  