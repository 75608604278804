import {  ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { TenantInfo } from "../../Entities/TenantInfo";
import { SurfaceEditorBase } from "../SurfaceBase";
import { AuthorProfilesEditorControl } from "./AuthorProfilesEditor";

interface SystemAppSurfaceEditorProps {
    app : SystemApplicationInfo;
    isReadOnly : boolean;
    //tenantId: string;
    closeAndSave : () => void;
    TenantInfo: TenantInfo;
}

interface SystemAppSurfaceEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  app : SystemApplicationInfo;
  isReadOnly : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

export class SystemAppSurfaceEditorControl extends React.Component<SystemAppSurfaceEditorProps, SystemAppSurfaceEditorState> {
    constructor(props: SystemAppSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        app: props.app,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
      }
  
    }
    componentDidMount() {
    }

    private updateParent = () : void =>{
        this.forceUpdate();
    }

    private resetError = (evt : any) : void => {
      this.setState({errorMessage : ''});
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >{this.state.app.Id ? 'Edit Application' : 'Create New Application'}</div>
            {that.state.errorMessage &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={this.resetError}
                dismissButtonAriaLabel="Close"
              >
                {that.state.errorMessage}
              </MessageBar>
            }
            <TextField label='Application Code' value={this.state.app.AppCode} readOnly={this.state.isReadOnly} onChange={that.onAppCodeChanged} ></TextField>
            <TextField label='Display Name' value={this.state.app.DisplayName} readOnly={this.state.isReadOnly} onChange={that.onAppDisplayNameChanged} ></TextField>
            <AuthorProfilesEditorControl Entity={that.state.app} PropertyName={'PublisherId'} isReadOnly={this.state.isReadOnly || (this.state.app.Id !== null && this.state.app.Id !== undefined)} tenantId={that.props.TenantInfo?.Id} ></AuthorProfilesEditorControl>
            <TextField label='Image CSS Class' value={this.state.app.ImageCSSClass} readOnly={this.state.isReadOnly} onChange={that.onImageCSSClassChanged}></TextField>
            <TextField label='Tile Name' value={this.state.app.TileName} readOnly={this.state.isReadOnly} onChange={that.onTileNameChanged}></TextField>
            <TextField label='Order' value={this.state.app.Order?.toString() ?? ''} readOnly={this.state.isReadOnly} type='number' onChange={that.onOrderChanged}></TextField>
            <PrimaryButton text="Save" onClick={this.closeAndSave} allowDisabledFocus disabled={false} checked={false} style={{marginTop: '25px'}} />
        </Stack>
      );
    }

    private closeAndSave = (evt: any) =>{
      if (this.state.app && !this.state.app.AppCode){
        this.setState({errorMessage : 'The AppCode is required'});
        return;
      }
      if (this.state.app && !this.state.app.DisplayName){
        this.setState({errorMessage : 'The Display Name is required'});
        return;
      }
      if (this.state.app && !this.state.app.PublisherId){
        this.setState({errorMessage : 'The Author is required'});
        return;
      }
      if (this.state.app && !this.state.app.ImageCSSClass){
        this.setState({errorMessage : 'The Image CSS class is required'});
        return;
      }
      if (this.state.app && !this.state.app.TileName){
        this.setState({errorMessage : 'The Tile Name class is required'});
        return;
      }
      this.props.closeAndSave();
    }

    private onAppCodeChanged = (evt: any, newValue? : string) =>{
        var it : SystemApplicationInfo = this.state.app;
        it.AppCode = StringHelper.alphaNumbericOnly(newValue ?? '');
        this.setState({app : it});
        this.forceUpdate();
    }

    private onAppDisplayNameChanged = (evt: any, newValue? : string) =>{
      var it : SystemApplicationInfo = this.state.app;
      it.DisplayName = newValue ?? '';
      this.setState({app : it});
      this.forceUpdate();
    }

    private onImageCSSClassChanged = (evt: any, newValue? : string) =>{
      var it : SystemApplicationInfo = this.state.app;
      it.ImageCSSClass = newValue ?? '';
      this.setState({app : it});
      this.forceUpdate();
    }

    private onTileNameChanged = (evt: any, newValue? : string) =>{
      var it : SystemApplicationInfo = this.state.app;
      it.TileName = newValue ?? '';
      this.setState({app : it});
      this.forceUpdate();
    }

    private onOrderChanged = (evt: any, newValue? : string) =>{
      var it : SystemApplicationInfo = this.state.app;
      it.Order = Number.parseInt(newValue ?? '1000');
      this.setState({app : it});
      this.forceUpdate();
    }
  }
  