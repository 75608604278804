import { DatePicker, DayOfWeek, Dropdown, IDatePickerStrings, IDropdownOption, TooltipHost } from "@fluentui/react";
import { isDate } from "lodash";
import React from "react";
//import { ClientState } from "../ClientState";
import { DynamicEntity } from "../DynamicEntity";
import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { IllerisNinthAPI } from "../ServiceResult";

export interface TNOwnerEditProps {
    Entity : DynamicEntity | null | undefined;
    isReadOnly: boolean;
}

export interface TNOwnerEditState  {
    createdByUser : string| null;
    modifiedByUser : string| null;
    createDate : Date | undefined;
    modifiedDate : Date | undefined;
}

const DayPickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    monthPickerHeaderAriaLabel: '{0}, select to change the year',
    yearPickerHeaderAriaLabel: '{0}, select to change the month',
  };
  
  export class TNOwnerEditControl extends React.Component<TNOwnerEditProps, TNOwnerEditState> {
      private mUserList : Array<any> = new Array<any>();
      private mUserDropDownItems : Array<IDropdownOption> = new Array<IDropdownOption>();
      constructor(props : TNOwnerEditProps){
          super(props);
          
          var ct : Date | undefined; 
          var xt : Date | null = props.Entity?.getValue("CreateDate");
          if (isDate(xt)){
              ct = xt;
          }

          var mt : Date | undefined;
          xt = props.Entity?.getValue("LastModifiedDate");
          if (isDate(xt)) {
              mt = xt;
          }

          this.state = {
            createDate : ct ,
            modifiedDate : mt,
            createdByUser : props.Entity?.getValue("CreatedBy"),
            modifiedByUser : props.Entity?.getValue("LastModifiedBy")
          }

      }
      public componentDidMount(){
          var that = this;
          var emp : EntityPersistanceManager = new EntityPersistanceManager();
          //var cs : ClientState = ClientState.CurrentState();
          emp.getMany("Identity").then(function (res : any) {
            var sr : IllerisNinthAPI.ServiceResult = res as IllerisNinthAPI.ServiceResult;
            if (sr && sr.Values){
                that.mUserList = new Array<any>();
                that.mUserDropDownItems = new Array<IDropdownOption>();
                for(var x = 0; x<sr.Values.length; x++){
                    that.mUserList.push(sr.Values[x]);
                    that.mUserDropDownItems.push({key : sr.Values[x].Id, text :sr.Values[x].DisplayName});
                }
                that.mUserDropDownItems = that.mUserDropDownItems.sort((a, b) => (a.text < b.text ? -1 : 1));
                that.forceUpdate();
            }
            else if (res instanceof Error){

            }
          })
          .catch(function(error: any){

          })
      }

      public shouldComponentUpdate(nextProps: TNOwnerEditProps, nextState: TNOwnerEditState){
        if (nextProps?.Entity?.getValue("CreatedBy") !== this.state.createdByUser || nextProps?.Entity?.getValue("LastModifiedBy") !== this.state.modifiedByUser){

            var ct : Date | undefined = nextProps.Entity?.getValue("CreateDate") ? new Date(nextProps.Entity?.getValue("CreateDate")) : undefined;
            var mt : Date | undefined = nextProps.Entity?.getValue("LastModifiedDate") ? new Date(nextProps.Entity?.getValue("LastModifiedDate")) : undefined;
            if (ct && mt){
                this.setState({createdByUser : nextProps.Entity?.getValue("CreatedBy"), modifiedByUser : nextProps.Entity?.getValue("LastModifiedBy"), createDate : ct, modifiedDate: mt})
            }
            else{
                this.setState({createdByUser : nextProps.Entity?.getValue("CreatedBy"), modifiedByUser : nextProps.Entity?.getValue("LastModifiedBy")})
            }

            return true;
        }
        else{
            return false;
        }
      }
    //   public componentDidUpdate(prevProps : TNOwnerEditProps, prevState: TNOwnerEditState){
    //     //if (prevProps?.Entity?.getValue("CreatedBy") !== this.state.createdByUser || prevProps?.Entity?.getValue("LastModifiedBy") !== this.state.modifiedByUser || prevProps?.Entity?.getValue("LastModifiedDate") !== this.state.modifiedDate){
    //         if (prevProps?.Entity?.getValue("CreatedBy") !== this.state.createdByUser || prevProps?.Entity?.getValue("LastModifiedBy") !== this.state.modifiedByUser){
    //         var ct : Date | undefined = this.props.Entity?.getValue("CreateDate") ? new Date(this.props.Entity?.getValue("CreateDate")) : undefined;
    //         var mt : Date | undefined = this.props.Entity?.getValue("LastModifiedDate") ? new Date(this.props.Entity?.getValue("LastModifiedDate")) : undefined;
    //         if (ct && mt){
    //             this.setState({createdByUser : this.props.Entity?.getValue("CreatedBy"), modifiedByUser : this.props.Entity?.getValue("LastModifiedBy"), createDate : ct, modifiedDate: mt})
    //         }
    //         else{
    //             this.setState({createdByUser : this.props.Entity?.getValue("CreatedBy"), modifiedByUser : this.props.Entity?.getValue("LastModifiedBy")})
    //         }
    //     }
    //   }

      public render(){
          var that = this;
          var idStr : string = 'tnOwerEditRow';
          //var sizeStr = 'ms-Grid-col ms-sm3 ms-md3 ms-lg ms-xl3 ms-xxl3 ms-xxl3 tn-column';
          var sizeStr = 'ms-Grid-col ms-sm6 ms-md6 ms-lg6  ms-xl3';
          var sizeStrB = 'ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl3 ms-hiddenLgDown';
          

          return (
              <>
              {that.props && that.props.Entity &&
                <div className="ms-Grid tn-ownergrid">
                    <div className="ms-Grid-row tn-row" id={idStr}>
                        <div className={sizeStrB} >
                        <TooltipHost
                            content={this.props.Entity?.getValueAsDateString("CreateDate") ?? undefined}
                            // This id is used on the tooltip itself, not the host
                            // (so an element with this id only exists when the tooltip is shown)
                            calloutProps={{ gapSpace: 0 }}
                            
                        >
                            <DatePicker
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                //placeholder="Create Date"
                                ariaLabel="Create Date"
                                label="Create Date"
                                value={this.props.Entity?.getValueAsDate("CreateDate") ?? undefined}
                                disabled={this.props.isReadOnly || true}
                            />
                        </TooltipHost>
                            
                        </div>
                        <div className={sizeStrB} >
                            <Dropdown
                                placeholder="Created By"
                                label="Created By"
                                options={this.mUserDropDownItems}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.createdByUser}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                        </div>
                        <div className={sizeStr} >
                            <TooltipHost
                                content={this.props.Entity?.getValueAsDateString("LastModifiedDate") ?? undefined}
                                // This id is used on the tooltip itself, not the host
                                // (so an element with this id only exists when the tooltip is shown)
                                calloutProps={{ gapSpace: 0 }}
                                
                            >
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DayPickerStrings}
                                    //placeholder="Modified Date"
                                    ariaLabel="Modified Date"
                                    label="Modified Date"
                                    value={this.props.Entity?.getValueAsDate("LastModifiedDate") ?? undefined}
                                    disabled={this.props.isReadOnly || true}
                                />
                            </TooltipHost>
                        </div>
                        <div className={sizeStr} >
                            <Dropdown
                                placeholder="Modified By"
                                label="Modified By"
                                options={this.mUserDropDownItems}
                                styles={{dropdown : {width: '100%'}}}
                                selectedKey={this.state.modifiedByUser}
                                disabled={this.props.isReadOnly || true}
                                style={{border: '0px'}}
                            />
                        </div>
                    </div>
                </div>
              }
              {this && !this.props.Entity &&
                <></>
              }
              </>
          );
      }
  }