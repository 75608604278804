import {  Dropdown, ICommandBarItemProps, IDropdownOption, IDropdownStyles, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import React from "react";
import { StringHelper } from "../../../Helpers/StringHelper";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityRelationDefinition } from "../../Entities/EntityRelation";
import { SystemApplicationInfo } from "../../Entities/SystemApplicationInfo";
import { EntitySelectorSurfaceEditorControl } from "./EntitySelectorSurfaceEditor";

interface EntityRelationTypeEditorProps {
    TenantId: string;
    rel: EntityRelationDefinition;
    isReadOnly : boolean;
}

interface EntityRelationTypeEditorState  {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  rel: EntityRelationDefinition;
  isReadOnly : boolean;
  options: IDropdownOption[];
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: '100%' },
};

export class EntityRelationTypeEditorControl extends React.Component<EntityRelationTypeEditorProps, EntityRelationTypeEditorState> {
    constructor(props: EntityRelationTypeEditorProps) {
      super(props);

      let opts: IDropdownOption[] = new Array<IDropdownOption>();
      opts.push({key: '1-N', text: '1-N'});
      opts.push({key: 'N-1', text: 'N-1'});
      opts.push({key: '1-1', text: '1-1'});
      opts.push({key: 'N-N', text: 'N-N'});


      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        isReadOnly: props.isReadOnly,
        rel: props.rel,
        options: opts,
      }
  
    }
    componentDidMount() {
    }

    render() {
      var that = this;
  
      return (
        <Dropdown
          placeholder="Select an option"
          label="Relation Type"
          options={that.state.options}
          selectedKey={that.state.rel.RelationType}
          styles={dropdownStyles}
          onChange={this.onRelationTypeChanged}
          disabled={this.state.isReadOnly}
        />
      );
    }

    private onRelationTypeChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>{
      var it : EntityRelationDefinition = this.state.rel;
      it.RelationType = option?.key?.toString() ?? '1-N';
      if (it.RelationType !== '1-N' && it.RelationType !== 'N-1' && it.RelationType !== 'N-N' && it.RelationType !== '1-1' ){
        it.RelationType = '1-N';
      }
      this.setState({rel : it});
      this.forceUpdate();
    }

  }
