import {  IButtonProps, ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { takeRight } from "lodash";
import Notiflix from "notiflix";
import React from "react";
import { ClientState } from "../../ClientState";
import { ProductPriceMatrix, ProductVolumePrice } from "../../designers/Entities/ProductPricing";

interface TNProductVolumePriceSurfaceEditorProps {
    PriceMatrix?: ProductPriceMatrix;
    Price: ProductVolumePrice;
    isReadOnly: boolean;
    updateAndClose: (price: ProductVolumePrice) => void;
    deleteItem: (price: ProductVolumePrice) => void;
}

interface TNProductVolumePriceSurfaceEditorState extends TNProductVolumePriceSurfaceEditorProps {
  errorMessage? : string;
  notificationMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
  
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class TNProductVolumePriceSurfaceEditorControl extends React.Component<TNProductVolumePriceSurfaceEditorProps, TNProductVolumePriceSurfaceEditorState> {
    constructor(props: TNProductVolumePriceSurfaceEditorProps) {
      super(props);
      this.state = {
        isbusy : false,
        errorMessage : '',
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        isReadOnly : props.isReadOnly,
        notificationMessage: '',
        PriceMatrix: props.PriceMatrix,
        Price: props.Price,
        updateAndClose: props.updateAndClose,
        deleteItem: props.deleteItem
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }


    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            {this.state.errorMessage && 
                <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        onDismiss={(ev? : any) => {that.setState({errorMessage: ''}); that.forceUpdate();}}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.errorMessage}
                </MessageBar>
            }
            {this.state.notificationMessage && 
                <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={true}
                        onDismiss={(ev? : any) => {that.setState({notificationMessage: ''}); that.forceUpdate();}}
                        dismissButtonAriaLabel="Close"
                    >
                    {this.state.notificationMessage}
                </MessageBar>
            }
            <div className='tn-editor-properties-title' >Volume Price Details</div>
            <TextField label='Minimum Quantity' value={this.state.Price.MinVolume.toString()} readOnly={this.props.isReadOnly} type="number" onChange={this.onMinVolumeChanged} ></TextField>
            <TextField label='Maximum Quantity' value={this.state.Price.MaxVolume.toString()} readOnly={this.state.isReadOnly} type="number" onChange={this.onMaxVolumeChanged}  ></TextField>
            <TextField label='Unit Price' value={this.state.Price.Amount?.toString()} readOnly={this.state.isReadOnly} type="number" step={0.01} onChange={this.onAmountChanged} suffix={ClientState.CurrentState()?.UserProfile?.DefaultCurrency} ></TextField>
            <TextField label='Discount Percentage' value={this.state.Price.DiscountPercentage?.toString()} type="number" step={0.01} readOnly={this.state.isReadOnly} onChange={this.onDiscountPctChanged} suffix="%" ></TextField>
            <PrimaryButton text="Update" iconProps={{iconName: 'Save'}} onClick={this.onUpdate} style={{marginTop: '25px'}}></PrimaryButton>
            <PrimaryButton text="Delete" iconProps={{iconName: 'Delete'}} onClick={this.onDelete} style={{marginTop: '25px'}}></PrimaryButton>
        </Stack>
      );
    }

    private onMinVolumeChanged = (evt: any, newValue? : string) =>{
        var it: ProductVolumePrice = this.state.Price;
        it.MinVolume = newValue ? Number.parseFloat(newValue) : 1;
        this.setState({Price: it});
        this.forceUpdate();
    }

    private onMaxVolumeChanged = (evt: any, newValue? : string) =>{
      var it: ProductVolumePrice = this.state.Price;
      it.MaxVolume = newValue ? Number.parseFloat(newValue) : 1;
      this.setState({Price: it});
      this.forceUpdate();
    }
    private onAmountChanged = (evt: any, newValue? : string) =>{
      var it: ProductVolumePrice = this.state.Price;
      it.Amount = newValue ? Number.parseFloat(newValue) : 0;
      this.setState({Price: it});
      this.forceUpdate();
    }
    private onDiscountPctChanged = (evt: any, newValue? : string) =>{
      var it: ProductVolumePrice = this.state.Price;
      it.DiscountPercentage = newValue ? Number.parseFloat(newValue) : undefined;
      if (it.DiscountPercentage && (it.DiscountPercentage < 0 || it.DiscountPercentage > 100)){
        it.DiscountPercentage = undefined;
      }
      this.setState({Price: it});
      this.forceUpdate();
    }
    private onUpdate = (ev? : any) : void => {
      var it: ProductVolumePrice = this.state.Price;
      if (it.MaxVolume < it.MinVolume || it.MaxVolume === 0){
        this.setState({errorMessage: 'The maximum volume must be larger then the minimum volume, and larger then 0.'});
        return;
      }
      if (it.DiscountPercentage && (it.DiscountPercentage < 0 || it.DiscountPercentage > 100)){
        this.setState({errorMessage: 'The discount percentage must be between 0 and 100'});
        return;
      }
      this.props.updateAndClose(it);
    }
    private onDelete = (ev? : any) : void => {
      let that = this;
      Notiflix.Confirm.show('Confirm delete',
                'Are you sure you want to remove this price?','Yes','No',
                function(){ 
                    that.props.deleteItem(that.state.Price);
                },function(){
                    
                });
    }
  }
  