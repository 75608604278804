import {  ComboBox, IButtonProps, IComboBox, IComboBoxOption, ICommandBarItemProps, IDropdownOption, Stack, TextField, Toggle } from "@fluentui/react";
import React from "react";
import { IllerisNinthUI } from "../../../MetaModel/UI/Formlet";
import { EntityDefinition } from "../../Entities/EntityDefinition";
import { EntityPropertyDefinition } from "../../Entities/EntityProperty";
import { SurfaceEditorBase } from "../SurfaceBase";

interface EditorControlSurfaceEditorProps extends SurfaceEditorBase {
    Form : IllerisNinthUI.Formlet;
    Tab?: IllerisNinthUI.TNTab | null;
    Section? : IllerisNinthUI.TNSection | null;
    Editor : IllerisNinthUI.TNEditorBase;
    tabIndex : number;
    getEntity : () => EntityDefinition;
    isReadOnly : boolean;
    Property: EntityPropertyDefinition | null | undefined;
}

interface EditorControlSurfaceEditorState extends EditorControlSurfaceEditorProps {
  StateKey : number;
  errorMessage? : string;
  isbusy : boolean;
  cmdBarItems : ICommandBarItemProps[];
  cmdBarFarItems : ICommandBarItemProps[];
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export class EditorControlSurfaceEditorControl extends React.Component<EditorControlSurfaceEditorProps, EditorControlSurfaceEditorState> {
    constructor(props: EditorControlSurfaceEditorProps) {
      super(props);
      this.state = {
        StateKey : 0,
        isbusy : false,
        errorMessage : '',
        Tab : props.Tab,
        Form : props.Form,
        cmdBarItems : new Array<ICommandBarItemProps>(),
        cmdBarFarItems : new Array<ICommandBarItemProps>(),
        tabIndex : props.tabIndex,
        Section : props.Section,
        getEntity : props.getEntity,
        isReadOnly : props.isReadOnly,
        Editor: props.Editor,
        Property: props.Property,
      }
  
    }
    componentDidMount() {
        this.buildCommandbar();
    }

    private buildCommandbar = () : void =>{
       
    }

    private getTextInputEditorTypes = () : IDropdownOption[] => {
      //export type EditorType = 'text' | 'date' | 'email' | 'checkbox' | 'password' | 'color' | 'month' | 'number' | 'time' | 'url' | 'week'| 'phone' ;
      let res: IDropdownOption[] = new Array<IDropdownOption>();
      res.push({key: 'text', text: 'Text'});
      res.push({key: 'date', text: 'Date'});
      res.push({key: 'email', text: 'Email'});
      res.push({key: 'checkbox', text: 'Switch'});
      res.push({key: 'password', text: 'Password'});
      res.push({key: 'color', text: 'Color'});
      res.push({key: 'month', text: 'Month'});
      res.push({key: 'number', text: 'Number'});
      res.push({key: 'url', text: 'URL'});
      res.push({key: 'week', text: 'Week Number'});
      res.push({key: 'phone', text: 'Phone Number'});
      return res;
    }
    
    private updateParent = () : void =>{
        this.forceUpdate();
    }
    
    render() {
      var that = this;
  /*
  public ItemID : string = '';
        public CSSClass : string = '';
        public LabelText : string = '';
        public Name : string = '';
        public IsReadOnly : boolean = false;
        public HSBindName : string = '';
        public LabelCSSClass : string = '';
        public EditBlockCSSClass : string ='';
        public EditorCSSClass : string ='';
        public ErrorMessage : string | null = null;
  */
      return (
        <Stack verticalFill horizontalAlign='stretch' className='tn-designersurface-form' >
            <div className='tn-editor-properties-title' >Editor Properties</div>
            {this.state.Editor instanceof IllerisNinthUI.TNInputBox &&
              <>
                <TextField label='Item Id' value={this.state.Editor.ItemID} readOnly={true} ></TextField>
                <TextField label='Label Text' value={this.state.Editor.LabelText} readOnly={this.state.isReadOnly} onChange={this.onLabelTextChanged}  ></TextField>
                <TextField label='Label CSS Class' value={this.state.Editor.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='Placeholder' value={this.state.Editor.PlaceHolder} readOnly={this.state.isReadOnly} onChange={this.onPlaceHolderChanged}  ></TextField>
                <TextField label='Prepend Text' value={this.state.Editor.PrePendText} readOnly={this.state.isReadOnly} onChange={this.onPrependTextChanged}  ></TextField>
                <TextField label='Prepend Image Class' value={this.state.Editor.PrePendCSSImageClass} readOnly={this.state.isReadOnly} onChange={this.onPrependImageChanged}  ></TextField>
                <TextField label='Postpend Text' value={this.state.Editor.PostPendText} readOnly={this.state.isReadOnly} onChange={this.onPostpendTextChanged}  ></TextField>
                <TextField label='Postpend Image Class' value={this.state.Editor.PostPendCSSImageClass} readOnly={this.state.isReadOnly} onChange={this.onPostpendImageChanged}  ></TextField>
                <TextField label='Row Count' value={this.state.Editor.RowCount.toString()} readOnly={this.state.isReadOnly} onChange={this.onRowCountChanged} type="number"  ></TextField>
                <ComboBox label='Input Type' selectedKey={this.state.Editor.Type} disabled={this.state.isReadOnly} onChange={this.onInputTypeChanged} options={this.getTextInputEditorTypes()}  ></ComboBox>
              </>
            }
            {this.state.Editor instanceof IllerisNinthUI.TNXLanguageCombo &&
              <>
                <TextField label='Item Id' value={this.state.Editor.ItemID} readOnly={true} ></TextField>
                <TextField label='Label Text' value={this.state.Editor.LabelText} readOnly={this.state.isReadOnly} onChange={this.onLabelTextChanged}  ></TextField>
                <TextField label='Label CSS Class' value={this.state.Editor.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='Placeholder' value={this.state.Editor.PlaceHolder} readOnly={this.state.isReadOnly} onChange={this.onPlaceHolderChanged}  ></TextField>
              </>
            }
            {this.state.Editor instanceof IllerisNinthUI.TNSelect &&
              <>
                <TextField label='Item Id' value={this.state.Editor.ItemID} readOnly={true} ></TextField>
                <TextField label='Label Text' value={this.state.Editor.LabelText} readOnly={this.state.isReadOnly} onChange={this.onLabelTextChanged}  ></TextField>
                <TextField label='Label CSS Class' value={this.state.Editor.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='Placeholder' value={this.state.Editor.PlaceHolder} readOnly={this.state.isReadOnly} onChange={this.onPlaceHolderChanged}  ></TextField>
                <TextField label='Data Source URI' value={this.state.Editor.DataSource.HSDataSourceURI} readOnly={this.state.isReadOnly} onChange={this.onHSDatasourceChanged}  ></TextField>
                <TextField label='Data Source Text Property' value={this.state.Editor.DataSource.HSDataSourceTextProperty} readOnly={this.state.isReadOnly} onChange={this.onHSDatasourceTextChanged}  ></TextField>
                <TextField label='Data Source Value Property' value={this.state.Editor.DataSource.HSDataSourceValueProperty} readOnly={this.state.isReadOnly} onChange={this.onHSDatasourceValueChanged}  ></TextField>
                <TextField label='Data Source Order By' value={this.state.Editor.DataSource.HSOrderBy} readOnly={this.state.isReadOnly} onChange={this.onHSDatasourceOrderbyChanged}  ></TextField>
              </>
            }
            {this.state.Editor instanceof IllerisNinthUI.TNLookup &&
              <>
                <TextField label='Item Id' value={this.state.Editor.ItemID} readOnly={true} ></TextField>
                <TextField label='Label Text' value={this.state.Editor.LabelText} readOnly={this.state.isReadOnly} onChange={this.onLabelTextChanged}  ></TextField>
                <TextField label='Label CSS Class' value={this.state.Editor.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='Placeholder' value={this.state.Editor.PlaceHolder} readOnly={this.state.isReadOnly} onChange={this.onPlaceHolderChanged}  ></TextField>
                <TextField label='Target Entity' value={this.state.Editor.TargetEntity} readOnly={this.state.isReadOnly} onChange={this.onTargetEntityChanged}  ></TextField>
                <TextField label='Display Field' value={this.state.Editor.DisplayField} readOnly={this.state.isReadOnly} onChange={this.onDisplayFieldChanged}  ></TextField>
                <TextField label='Value Field' value={this.state.Editor.ValueField} readOnly={this.state.isReadOnly} onChange={this.onValueFieldChanged}  ></TextField>
                <TextField label='Search Fields' value={this.state.Editor.SearchFields} readOnly={this.state.isReadOnly} onChange={this.onSearchFieldsChanged}  ></TextField>
              </>
            }
            {this.state.Editor instanceof IllerisNinthUI.TNDocumentInput &&
              <>
                <TextField label='Item Id' value={this.state.Editor.ItemID} readOnly={true} ></TextField>
                <TextField label='Label Text' value={this.state.Editor.LabelText} readOnly={this.state.isReadOnly} onChange={this.onLabelTextChanged}  ></TextField>
                <TextField label='Label CSS Class' value={this.state.Editor.LabelCSSClass} readOnly={this.state.isReadOnly} onChange={this.onLabelCSSClassChanged}  ></TextField>
                <TextField label='Placeholder' value={this.state.Editor.PlaceHolder} readOnly={this.state.isReadOnly} onChange={this.onPlaceHolderChanged}  ></TextField>
                <TextField label='Accepted Files' value={this.state.Editor.AcceptedFiles} readOnly={this.state.isReadOnly} onChange={this.onAcceptedFilesChanged}  ></TextField>
                <TextField label='Max File Size' type="number" value={this.state.Editor.MaxFileSize?.toString() ?? '0'} readOnly={this.state.isReadOnly} onChange={this.onMaxFileSizeChanged}  ></TextField>
              </>
            }
        </Stack>
      );
    }
    private onAcceptedFilesChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNDocumentInput = this.state.Editor as IllerisNinthUI.TNDocumentInput;
      x.AcceptedFiles = newValue ?? '';
      this.setState({Editor: x});
    }
    private onMaxFileSizeChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNDocumentInput = this.state.Editor as IllerisNinthUI.TNDocumentInput;
      x.MaxFileSize = Number.parseInt(newValue ?? '0') ?? 0;
      this.setState({Editor: x});
    }
    private onSearchFieldsChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNLookup = this.state.Editor as IllerisNinthUI.TNLookup;
      x.SearchFields = newValue ?? '';
      this.setState({Editor: x});
    }
    private onTargetEntityChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNLookup = this.state.Editor as IllerisNinthUI.TNLookup;
      x.TargetEntity = newValue ?? '';
      this.setState({Editor: x});
    }
    private onDisplayFieldChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNLookup = this.state.Editor as IllerisNinthUI.TNLookup;
      x.DisplayField = newValue ?? '';
      this.setState({Editor: x});
    }
    private onValueFieldChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNLookup = this.state.Editor as IllerisNinthUI.TNLookup;
      x.ValueField = newValue ?? '';
      this.setState({Editor: x});
    }
    private onHSDatasourceChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNSelect = this.state.Editor as IllerisNinthUI.TNSelect;
      x.DataSource.HSDataSourceURI = newValue ?? '';
      this.setState({Editor: x});
    }
    private onHSDatasourceTextChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNSelect = this.state.Editor as IllerisNinthUI.TNSelect;
      x.DataSource.HSDataSourceTextProperty = newValue ?? '';
      this.setState({Editor: x});
    }
    private onHSDatasourceValueChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNSelect = this.state.Editor as IllerisNinthUI.TNSelect;
      x.DataSource.HSDataSourceURI = newValue ?? '';
      this.setState({Editor: x});
    }
    private onHSDatasourceOrderbyChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNSelect = this.state.Editor as IllerisNinthUI.TNSelect;
      x.DataSource.HSOrderBy = newValue ?? '';
      this.setState({Editor: x});
    }
    private onLabelTextChanged = (evt: any, newValue? : string) =>{
        let x : IllerisNinthUI.TNEditorBase = this.state.Editor;
        x.LabelText = newValue ?? '';
        this.setState({Editor: x});
    }
    private onLabelCSSClassChanged = (evt: any, newValue? : string) =>{
      let x : IllerisNinthUI.TNEditorBase = this.state.Editor;
      x.LabelCSSClass = newValue ?? '';
      this.setState({Editor: x});
    }
    private onPlaceHolderChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.PlaceHolder = newValue ?? '';
        this.setState({Editor: x});
      }
    }
    private onPrependTextChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.PrePendText = newValue ?? '';
        this.setState({Editor: x});
      }
    }
    private onPrependImageChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.PrePendCSSImageClass = newValue ?? '';
        this.setState({Editor: x});
      }
    }
    private onPostpendTextChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.PostPendText = newValue ?? '';
        this.setState({Editor: x});
      }
    }
    private onPostpendImageChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.PostPendCSSImageClass = newValue ?? '';
        this.setState({Editor: x});
      }
    }
    private onRowCountChanged = (evt: any, newValue? : string) =>{
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.RowCount = (newValue ? Number.parseInt(newValue) : 1);
        this.setState({Editor: x});
      }
    }
    private onInputTypeChanged = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) : void => {
      if (this.state.Editor instanceof IllerisNinthUI.TNInputBox){
        let x : IllerisNinthUI.TNInputBox = this.state.Editor as IllerisNinthUI.TNInputBox;
        x.Type = (option? option.key.toString() : 'text') as IllerisNinthUI.EditorType;
        this.setState({Editor: x});
      }
    }
  }
  