/* eslint-disable no-loop-func */
import { IComboBox, IComboBoxOption, VirtualizedComboBox } from "@fluentui/react/lib/components/ComboBox";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/components/Dropdown";
import React from "react";
import { DynamicEntity } from "../../DynamicEntity";
import { IllerisNinthUI } from "../../MetaModel/UI/Formlet";
import { IllerisNinthAPI } from "../../ServiceResult";
import { UIDataItemEditorProps } from "./FormletEditors";
import { Logger } from "../../Logger";
import { PageInfo } from "../../PageInfo";
import { SilentRequest } from "@azure/msal-browser";
import { MSALHelper } from "../../Helpers/MSALHelper";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack } from "@fluentui/react/lib/components/Stack";
import { TextField } from "@fluentui/react/lib/components/TextField";
import { IconButton } from "@fluentui/react/lib/components/Button";
import { DatePicker } from "@fluentui/react/lib/components/DatePicker";
import { DayOfWeek } from "@fluentui/react/lib/DateTimeUtilities";
import { SpinButton } from "@fluentui/react/lib/components/SpinButton";
import { Position } from "@fluentui/react/lib/utilities/positioning/positioning.types";
import { Toggle } from "@fluentui/react/lib/components/Toggle";
import { Spinner } from "@fluentui/react/lib/components/Spinner";
import { TNAdvandedLookup } from "../AdvancedLookup";
import { TNDocumentInfo, TNDocumentInputEditor } from "../../TNDocumentEditor";
import { TNImageInfo, TNImageInputEditor } from "../../TNImageEditor";
import { TNEditorFactoryProps, TNEditorFactoryState } from "./EditorFactory";


 
  export class TNSearchLookupEditor extends React.Component<TNEditorFactoryProps, TNEditorFactoryState> {
      private Editor : IllerisNinthUI.TNEditorBase | null= null;
      private InputEditor : IllerisNinthUI.TNInputBox | null;
      private mSearchLookupEditor : IllerisNinthUI.TNSearchLookup | null = null;
      private Entity : DynamicEntity | null | undefined;
      //private DropdownOptions : Array<IDropdownOption> = new Array<IDropdownOption>();
      private ComboBoxOptions : Array<IComboBoxOption> = new Array<IComboBoxOption>();
      private LastValueString : string | undefined;
      private LastServiceResultLoadedTimeStamp : Date | undefined;

      private mPropertyName : string = '';
    constructor(props : TNEditorFactoryProps){
        super(props);
        this.state ={
            valueStr : '',
            loadingMessage: 'Loading...'
        }

        this.mSearchLookupEditor = props.getEditor() as IllerisNinthUI.TNSearchLookup;

        this.Editor = null;
        this.InputEditor = null;
        this.Entity = null;
        this.onTextInputChanged = this.onTextInputChanged.bind(this);
        this.onToggleInputChanged = this.onToggleInputChanged.bind(this);
    }
    
    componentDidMount(){
    }

    shouldComponentUpdate(nextProps: TNEditorFactoryProps, nextState: TNEditorFactoryState) {
        //Logger.logNotification('TNInputBoxView::shouldComponentUpdate');
        if (this.Entity?.getValue(this.Editor?.HSBindName) !== nextProps.Entity?.getValue(nextProps.getEditor()?.HSBindName)
            || this.props.dataKey !== nextProps.dataKey
        ){
            return true;
        }
        else{
            return false;
        }
    }
    componentDidUpdate(prevProps : TNEditorFactoryProps, prevState: TNEditorFactoryState){
        var prevEnt : DynamicEntity | null | undefined = prevProps.Entity;
        var thisEnt : DynamicEntity | null | undefined = this.props.Entity;
        if (this.props.getEditor()?.HSBindName !== prevProps.getEditor()?.HSBindName || prevEnt?.getValue(prevProps.getEditor()?.HSBindName ? prevProps.getEditor()?.HSBindName : '') !== thisEnt?.getValue(this.props.getEditor()?.HSBindName ? this.props.getEditor()?.HSBindName : '')){
            
        }
        
    }


    
    private handlePropertyChangedEvent = (event : any) : void => { // (1)
        //alert("Hello from " + event.target.tagName); // Hello from H1
        if (event && event.detail && event.detail.PropertyName && (event.detail.PropertyName === this.mPropertyName || event.detail.PropertyName === '*') ){
            this.forceUpdate();
        }
    }

    private updateValue = (propName : string, val : any)  : void =>{
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity){
            this.Entity.setValue(propName, val);
        }
    }

    componentWillUnmount(){
        document.removeEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
    }

    render() {
        var that = this;

        if (this.props.getEditor()){
            document.addEventListener("EntityPropertyChanged", this.handlePropertyChangedEvent);
        }
        
        const controlClass = mergeStyleSets({
            control: {
              margin: '0 0 15px 0',
              maxWidth: '100%',
            },
          });
          //var valStr : string = (this.Entity && this.Entity.getValue(this.Editor!.HSBindName) ? this.Entity.getValue(this.Editor!.HSBindName) : '');

          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: '100%' },
          };

        return (
            <>
                    <Stack horizontalAlign='stretch' horizontal>
                        
                        <TNAdvandedLookup
                            labelText={this.mSearchLookupEditor?.LabelText ?? ''}
                            isReadOnly={this.mSearchLookupEditor?.IsReadOnly || this.props.isreadonly}
                            Entity={this.Entity}
                            hsbindPropertyName={this.mSearchLookupEditor?.HSBindName ?? ''}
                            searchColumns={this.mSearchLookupEditor?.SearchFields ?? ''}
                            displayColumns={this.mSearchLookupEditor?.DisplayColumns ?? ''}
                            displayColumnNames={this.mSearchLookupEditor?.DisplayColumnHeaders ?? ''}
                            targetEntity={this.mSearchLookupEditor?.TargetEntity ?? ''}
                            textProperty={this.mSearchLookupEditor?.DisplayField ?? ''}
                            valueProperty={this.mSearchLookupEditor?.ValueField ?? ''}
                            searchHeaderText='Find Organizations'
                            valueFieldDataType={this.mSearchLookupEditor?.ValueFieldDataType ?? ''}
                            
                            //onChange={onChange}
                        />
                        {this.state.loadingMessage &&
                            <Spinner label="" style={{ marginTop: '25px', marginRight:'10px', marginLeft: '10px'}} />
                        }
                    </Stack>
            </>
        );
      }
    
      
      private onTextInputChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue : string | undefined) : void =>{
        console.log('input changed to value : ' + newValue);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, newValue);
        }
        this.setState({valueStr : newValue});
      }
      private onToggleInputChanged = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) : void =>{
        console.log('toggle changed to value : ' + checked);
        if (!this.Entity){
            this.Entity = this.props.Entity;
        }
        if (this.Entity && this.Editor){
            this.Entity.setValue(this.Editor!.HSBindName, checked);
        }
        this.setState({valueStr : checked ? 'true' : 'false'});
      }

  }