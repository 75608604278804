import { EntityPersistanceManager } from "../Helpers/EntityPersistanceManager";
import { IllerisNinthAPI } from "../ServiceResult";
import { ClientLookupCache } from "./ClientLookupCache"

export class ClientDataManager {
    private mCache: ClientLookupCache = new ClientLookupCache();
    private mEpm: EntityPersistanceManager;
    private mTenantName: string;
    constructor(tenantName: string){
        this.mTenantName = tenantName;
        this.mEpm = new EntityPersistanceManager(tenantName);
    }

    public getMany = async (entityName: string, bUseCache: boolean = false) : Promise<any[]> => {
        let that = this;
        let res: any[] = new Array<any>();
        if (bUseCache){
            res = await this.mCache.getData(this.mTenantName, entityName);
        }    
        if (!res ||res.length === 0){
            return this.mEpm.getMany(entityName)
                .then(function(resx: IllerisNinthAPI.ServiceResult | Error){
                    if ((resx as any).Values || (resx as any).Value){
                        res = (resx as any).Values as any[];
                        if (bUseCache){
                            that.mCache.addData(that.mTenantName, entityName, res);
                        }

                        return Promise.resolve(res);
                    }
                    else{
                        return Promise.reject(resx as Error);
                    }
                })
                .catch(function(err: Error){
                    return Promise.reject(err);
                })
        }
        else{
            return Promise.resolve(res);
        }
    }

    public clearDb = async (tenantId: string) : Promise<void> => {
        return this.mCache.deleteDb(tenantId);
    }
}